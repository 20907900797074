define("adept-iq/pods/components/iq-widgets/activity-log-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/activity-log-widget/config", "adept-iq/utils/focus_column_widget_row"], function (_exports, _component, _config, _focus_column_widget_row) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['activity-log-widget'],
    editModal: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    // need to sub-class this in specific widget
    config: _config.default,
    defaultIncludes: INCLUDES,

    init() {
      this._super(...arguments);

      this.get('editmodalcontext.widgetModelData').forEach(record => {
        this.get('records').pushObject(record);
      });
      let me = this;
      Ember.run.later(function () {
        (0, _focus_column_widget_row.default)(me);
      }, 50);
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({});
      this.set('singleActions', []);
    },

    actions: {
      closeWidget(event) {
        this._super(...arguments);
      }

    }
  });

  _exports.default = _default;
});