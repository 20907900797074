define("adept-iq/adapters/break-type", ["exports", "adept-iq/adapters/-ss-schedulingService"], function (_exports, _ssSchedulingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    pathForType: function ()
    /* type*/
    {
      return 'break-type';
    }
  });

  _exports.default = _default;
});