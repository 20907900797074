define("adept-iq/pods/components/side-drawer/box-widget-settings/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COLUMNS = {
    routeColumns: {
      status: {
        id: 'status',
        config: {
          id: 'status',
          index: 5,
          type: 'text',
          label: 'Status',
          valuePath: 'drivedStatus',
          editable: true,
          hidden: false,
          format: 'HH:mm A',
          defaultWidth: 75
        },
        filterTypeId: 'stringEq',
        filterValues: ['Scheduled']
      }
    },
    tripColumns: {
      status: {
        id: 'status',
        config: {
          id: 'status',
          index: 5,
          type: 'text',
          label: 'Status',
          valuePath: 'drivedStatus',
          editable: true,
          hidden: false,
          defaultWidth: 75
        },
        filterTypeId: null,
        filterValues: []
      }
    },
    vehicleColumns: {
      status: {
        id: 'status',
        config: {
          id: 'status',
          index: 5,
          type: 'text',
          label: 'Status',
          valuePath: 'drivedStatus',
          editable: true,
          hidden: false,
          defaultWidth: 75
        },
        filterTypeId: null,
        filterValues: []
      }
    },
    driverColumns: {
      status: {
        id: 'status',
        config: {
          id: 'status',
          index: 5,
          type: 'text',
          label: 'Status',
          valuePath: 'drivedStatus',
          editable: true,
          hidden: false,
          defaultWidth: 75
        },
        filterTypeId: null,
        filterValues: []
      }
    }
  };
  const BOX_COLOR = {
    orange: 2,
    red: 4
  };
  var _default = [{
    name: 'Trips',
    modelName: 'trip',
    columns: COLUMNS.tripColumns,
    displayOptions: {
      selected: {
        id: 'bookingType',
        name: 'Trip Type',
        label: 'Trip Type',
        valueKey: 'bookingType',
        valueCategories: [{
          label: 'ASAP',
          value: 'asap'
        }, {
          label: 'Prebook',
          value: 'prebook'
        }, {
          label: 'Street Hire',
          value: 'street_hire'
        }],
        metricOption: {
          id: 'count',
          name: 'Count'
        },
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      },
      options: [{
        id: 'bookingType',
        name: 'Trip Type',
        label: 'Trip Type',
        valueKey: 'bookingType',
        valueCategories: [{
          label: 'ASAP',
          value: 'asap'
        }, {
          label: 'Prebook',
          value: 'prebook'
        }, {
          label: 'Street Hire',
          value: 'street_hire'
        }],
        metricOption: {
          id: 'count',
          name: 'Count'
        },
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      }, {
        id: 'driverStatus',
        name: 'Trip Status',
        label: 'Trip Status',
        valueKey: 'driverStatus',
        valueCategories: [{
          label: 'Completed',
          value: 'completed'
        }, {
          label: 'Cancelled',
          value: 'cancelled'
        }, {
          label: 'NoShow',
          value: 'noShow'
        }],
        metricOption: {
          id: 'count',
          name: 'Count'
        },
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      }, {
        id: 'paymentype',
        name: 'Payment Type',
        label: 'Payment Type',
        valueKey: 'paymentype',
        valueCategories: [{
          label: 'Cash',
          value: 'CASH'
        }, {
          label: 'Account',
          value: 'ACCOUNT'
        }, {
          label: 'Credit',
          value: 'CREDIT'
        }],
        metricOption: {
          id: 'count',
          name: 'Count'
        },
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      }, {
        id: 'tripType',
        name: 'Source',
        label: 'Source',
        valueKey: 'tripType',
        valueCategories: [{
          label: 'Call',
          value: 'callAgent'
        }, {
          label: 'App',
          value: 'riderApp'
        }, {
          label: 'Web',
          value: 'webApp'
        }, {
          label: 'Kiosk',
          value: 'kiosk'
        }, {
          label: 'IVR',
          value: 'IVR'
        }],
        metricOption: {
          id: 'count',
          name: 'Count'
        },
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      }]
    },
    title: '',
    boxColor: BOX_COLOR
  }, {
    name: 'Taxis',
    modelName: 'vehicle',
    columns: COLUMNS.vehicleColumns,
    displayOptions: {
      selected: {
        id: 'drivedStatus',
        name: 'Status',
        label: 'Status',
        valueKey: 'drivedStatus',
        valueCategory: {
          label: 'Available Taxis',
          value: 'free'
        },
        valueCategories: [{
          label: 'Available Taxis',
          value: 'free'
        }, {
          label: 'On Break',
          value: 'onBreak'
        }, {
          label: 'On Hold',
          value: 'onHold'
        }, {
          label: 'Off',
          value: 'off'
        }, {
          label: 'STC',
          value: 'STC'
        }, {
          label: 'Emergency',
          value: 'emergency'
        }, {
          label: 'Occupied',
          value: 'occupied'
        }, {
          label: 'Missing',
          value: 'missing'
        }],
        metricOption: {
          id: 'count',
          name: 'Count'
        },
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      },
      options: [{
        id: 'drivedStatus',
        name: 'Status',
        label: 'Status',
        valueKey: 'drivedStatus',
        valueCategory: {
          label: 'Available Taxis',
          value: 'free'
        },
        valueCategories: [{
          label: 'Available Taxis',
          value: 'free'
        }, {
          label: 'On Break',
          value: 'onBreak'
        }, {
          label: 'On Hold',
          value: 'onHold'
        }, {
          label: 'Off',
          value: 'off'
        }, {
          label: 'Occupied',
          value: 'occupied'
        }, {
          label: 'Missing',
          value: 'missing'
        }],
        metricOption: {
          id: 'count',
          name: 'Count'
        },
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      }]
    },
    title: '',
    boxColor: BOX_COLOR
  }, {
    name: 'Drivers',
    modelName: 'driver',
    columns: COLUMNS.driverColumns,
    displayOptions: {
      selected: {
        id: 'drivedStatus',
        name: 'Status',
        label: 'Status',
        valueKey: 'drivedStatus',
        valueCategory: {
          label: 'Available Drivers',
          value: 'active'
        },
        valueCategories: [{
          label: 'Available Drivers',
          value: 'active'
        }, {
          label: 'Working',
          value: 'working'
        }, {
          label: 'Off',
          value: 'signedOff'
        }, {
          label: 'On Break',
          value: 'onBreak'
        }, {
          label: 'Temp Off',
          value: 'tempOff'
        }, {
          label: 'Emergency',
          value: 'inEmergency'
        }],
        metricOption: {
          id: 'count',
          name: 'Count'
        },
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      },
      options: [{
        id: 'drivedStatus',
        name: 'Status',
        label: 'Status',
        valueKey: 'drivedStatus',
        valueCategory: {
          label: 'Available Drivers',
          value: 'active'
        },
        valueCategories: [{
          label: 'Available Drivers',
          value: 'active'
        }, {
          label: 'Working',
          value: 'working'
        }, {
          label: 'Off',
          value: 'signedOff'
        }, {
          label: 'On Break',
          value: 'onBreak'
        }, {
          label: 'Temp Off',
          value: 'tempOff'
        }, {
          label: 'Emergency',
          value: 'inEmergency'
        }],
        metricOption: {
          id: 'count',
          name: 'Count'
        },
        metricOptions: [{
          id: 'count',
          name: 'Count'
        }]
      }]
    },
    title: '',
    boxColor: BOX_COLOR
  }];
  _exports.default = _default;
});