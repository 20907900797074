define("adept-iq/config/widget-groups", ["exports", "adept-iq/config/widget-types"], function (_exports, _widgetTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 'widgets-1',
    title: 'Resources',
    widgetTypes: [_widgetTypes.passengersWidgetType, _widgetTypes.driversWidgetType, _widgetTypes.vehiclesWidgetType, _widgetTypes.accountsWidgetType, _widgetTypes.tripWidgetType]
  }, {
    id: 'widgets-2',
    title: 'Work',
    widgetTypes: [_widgetTypes.mapWidgetType, //messagesWidgetType,
    //alertsWidgetType,
    _widgetTypes.tripsWidgetType, _widgetTypes.stopsWidgetType, _widgetTypes.cliWidgetType, _widgetTypes.zoneWidgetType, _widgetTypes.standWidgetType //subscriptionsWidgetType
    ]
  }, {
    id: 'metrics',
    title: 'Metrics & Graphics',
    widgetTypes: [_widgetTypes.metricsWidgetType, _widgetTypes.gaugeWidgetType, _widgetTypes.boxWidgetType, _widgetTypes.donutChartWidgetType, _widgetTypes.barChartWidgetType]
  }
  /*,
  {
  id: 'config',
  title: 'Configuration',
  widgetTypes: [
    settingsWidgetType,
    usersWidgetType
  ]
  },
  {
  id: 'reports',
  title: 'Reports',
  widgetTypes: [
    reportWidgetType,
    reportWidgetType1,
    reportWidgetType2,
    reportWidgetType3,
    reportWidgetType4
  ]
  }*/
  ];
  _exports.default = _default;
});