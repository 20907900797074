define("adept-iq/pods/components/iq-widgets/map-widget/data-joins/marker", ["exports", "adept-iq/pods/components/iq-widgets/map-widget/data-joins/base", "adept-iq/pods/components/iq-widgets/map-widget/config/marker", "tomtom", "lodash"], function (_exports, _base, _marker, _tomtom, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    enter(node) {
      const lat = node.get('lat');
      const lng = node.get('lng');
      if (Ember.isNone(lat) || Ember.isNone(lng)) return;
      this.createMarkerFor(node);
    },

    update(node) {
      const lat = node.get('lat');
      const lng = node.get('lng');
      const marker = node.get('marker');
      node.get('style');

      if (Ember.isNone(lat) || Ember.isNone(lng)) {
        if (marker) {
          this.removeMarkerFor(node);
        }

        return;
      }

      if (!marker) {
        this.createMarkerFor(node);
      }

      this.updateMarkerFor(node);
    },

    exit(node) {
      if (node.get('marker')) {
        this.removeMarkerFor(node);
      }
    },

    createMarkerFor(node) {
      const label = node.get('label');
      const record = node.get('record');
      const isMCG = node.get('isMCG');
      const MCGTrips = node.get('MCGTrips');
      const MCGVehicles = node.get('MCGVehicles');
      const MCGLabel = node.get('MCGLabel');
      let parentModelId;
      let parentModelName;

      if (record.constructor.modelName === 'stop') {
        parentModelId = record.get('trip.id');
        parentModelName = 'iqTrip';
      }

      if (record.constructor.modelName === 'vehicle') {
        parentModelId = record.get('id');
        parentModelName = 'iqVehicle';
      }

      const style = node.get('style');
      const defaultOptions = _marker.default[style] || {};
      const nodeOptions = node.get('options') || {};

      const options = _lodash.default.merge(defaultOptions, nodeOptions, {
        label,
        opacity: node.get('opacity'),
        // for active context clicks
        isActive: node.get('isActive'),
        isTraversal: node.get('isTraversal'),
        modelName: record.constructor.modelName,
        modelId: record.get('id'),
        MCGLabel: MCGLabel,
        parentModelId: parentModelId,
        parentModelName: parentModelName
      });

      const lat = node.get('lat');
      const lng = node.get('lng');

      const marker = _tomtom.default.L.marker([lat, lng], options);

      marker.bindPopup(label, {
        closeButton: false
      });
      marker.setZIndexOffset(1000);
      this.bindMouseEvents(marker);
      node.set('marker', marker);
      const map = this.get('map');

      if (!isMCG) {
        marker.addTo(map);
      } else {
        if (record.constructor.modelName === 'vehicle') {
          MCGVehicles.addLayer(marker);
        } else {
          MCGTrips.addLayer(marker);
        } // map.addLayer(this.get("MCGTrips"));

      }

      let angle = node.get('record').heading;

      if (record.constructor.modelName === 'vehicle') {
        angle /= 2;
        marker.setRotationAngle(angle);
      }

      marker.setRotationAngle(angle); //marker.on("mouseover",function(){$('.leaflet-mouse-marker').css('cursor','crosshair');});
      //marker.on("mouseout",function(){$('.leaflet-mouse-marker').css('cursor','');});

      /* marker.bindTooltip(caption,
         {permanent: true, direction:"center", opacity:0.5 , offset : tomtom.L.point(-20,0)}
       ).openTooltip();*/

      return marker;
    },

    updateMarkerFor(node) {
      const marker = node.get('marker');
      if (!marker) return;
      const lat = node.get('lat');
      const lng = node.get('lng');
      const opacity = node.get('opacity');
      const label = node.get('label');
      const record = node.get('record');
      const isMCG = node.get('isMCG');
      const MCGTrips = node.get('MCGTrips');
      const MCGVehicles = node.get('MCGVehicles');
      const markerStyle = this._getLayerStyleSuffix(record) + record.get('statusValue'); //this._getOTPstyleSuffix(record, type.id);

      const statusOption = _marker.default[markerStyle] || {}; ////console.log('!(parseFloat(marker._latlng.lat) === parseFloat(lat)) ',(statusOption.icon.options.iconUrl !== statusOption.icon.options.iconURL));
      //if(statusOption.icon.options.iconUrl !== statusOption.icon.options.iconURL) {

      marker.setIcon(statusOption.icon); //}
      // update marker position and angle directly

      let angle = node.get('record').heading; //angle /= 2;

      if (record.constructor.modelName === 'vehicle') {
        angle /= 2;
        marker.setRotationAngle(angle);
      }

      marker.setZIndexOffset(1000); ////console.log('!(parseFloat(marker._latlng.lat) === parseFloat(lat)) ',!(parseFloat(marker._latlng.lat) === parseFloat(lat)));
      // if(!(parseFloat(marker._latlng.lat) === parseFloat(lat)))  {

      if (record.constructor.modelName === 'vehicle') {
        marker.setLatLng([lat, lng]);
      }

      if (isMCG) {
        if (record.constructor.modelName === 'vehicle') {
          MCGVehicles.refreshClusters(marker);
        } else {
          MCGTrips.refreshClusters(marker);
        }
      } // }


      marker.on("mouseover", function () {
        Ember.$('.leaflet-mouse-marker').css('cursor', 'crosshair');
      });
      marker.on("mouseout", function () {
        Ember.$('.leaflet-mouse-marker').css('cursor', '');
      });
      marker.bindPopup(label, {
        closeButton: false
      });
      this.bindMouseEvents(marker);
      marker.setOpacity(opacity);

      marker._popup.setContent(label);

      marker.setTooltipContent(label);
    },

    removeMarkerFor(node) {
      //console.log('inside removeMarkerFor',node);
      const marker = node.get('marker');
      const record = node.get('record');
      const MCGTrips = node.get('MCGTrips');
      const MCGVehicles = node.get('MCGVehicles');

      if (marker) {
        if (record.constructor.modelName === 'vehicle') {
          MCGVehicles.removeLayer(marker);
        } else {
          MCGTrips.removeLayer(marker);
        }

        marker.remove();
        node.set('marker', null);
      }
    },

    _getLayerStyleSuffix(record) {
      let suffix = '';

      if (record.constructor.modelName === 'vehicle') {
        suffix = 'vehicle';
      }

      return suffix;
    }

  });

  _exports.default = _default;
});