define("adept-iq/pods/components/iq-widgets/fixed-price-trip-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/fixed-price-trip-widget/config", "adept-iq/utils/populate-fixed-price", "adept-iq/utils/check-address-overwrite", "adept-iq/utils/focus_column_widget_row", "adept-iq/utils/check-value"], function (_exports, _component, _config, _populateFixedPrice, _checkAddressOverwrite, _focus_column_widget_row, _checkValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['fixed-price-trip-widget'],
    editModal: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    riderAddresses: null,
    // need to sub-class this in specific widget
    config: _config.default,
    defaultIncludes: INCLUDES,

    init() {
      this._super(...arguments);

      let recordId = 1; //////console.log(('fixed-price-trip-widget widgetModelData ',this.get('widgetModelData'));

      this.get('widgetModelData').forEach(record => {
        record.id = "" + recordId + "";
        recordId++;
        console.log('widgetModelData ', record);

        if (!Ember.isBlank(record.pickup) && !Ember.isBlank(record.pickup.address)) {
          try {
            record.pickupFreeFormAddress = (0, _checkValue.default)(record.pickup.address.streetNr) + " " + (0, _checkValue.default)(record.pickup.address.streetName);
            record.pickupCity = (0, _checkValue.default)(record.pickup.address.region);
          } catch (e) {
            Ember.set(record, 'pickupFreeFormAddress', (0, _checkValue.default)(record.pickup.address.streetNr) + " " + (0, _checkValue.default)(record.pickup.address.streetName));
            Ember.set(record, 'pickupCity', (0, _checkValue.default)(record.pickup.address.region));
          }
        } else {
          try {
            record.pickupFreeFormAddress = "";
            record.pickupCity = "";
          } catch (e) {
            Ember.set(record, 'pickupFreeFormAddress', "");
            Ember.set(record, 'pickupCity', "");
          }
        }

        if (!Ember.isBlank(record.dropoff) && !Ember.isBlank(record.dropoff.address)) {
          try {
            record.dropoffFreeFormAddress = (0, _checkValue.default)(record.dropoff.address.streetNr) + " " + (0, _checkValue.default)(record.dropoff.address.streetName);
            record.dropoffCity = (0, _checkValue.default)(record.dropoff.address.region);
          } catch (e) {
            Ember.set(record, 'dropoffFreeFormAddress', (0, _checkValue.default)(record.dropoff.address.streetNr) + " " + (0, _checkValue.default)(record.dropoff.address.streetName));
            Ember.set(record, 'dropoffCity', (0, _checkValue.default)(record.dropoff.address.region));
          }

          try {
            Ember.set(record, 'tripAmount', parseFloat(record.price.amount.toFixed(2)));
          } catch (e) {
            console.log(e);
          }
        } else {
          try {
            record.dropoffFreeFormAddress = "";
            record.dropoffCity = "";
          } catch (e) {
            Ember.set(record, 'dropoffFreeFormAddress', "");
            Ember.set(record, 'dropoffCity', "");
          }
        }

        this.get('records').pushObject(record);
      });
      let me = this;
      Ember.run.later(function () {
        (0, _focus_column_widget_row.default)(me);
      }, 10);
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({});
      this.set('singleActions', []);
    },

    actions: {
      onRowRightClick(row, event) {},

      onRowClick(row, event) {
        let tripData = this.get('currentRecord');
        let fixedPriceTrip = row.get('record');
        this.closeWidget();
        let overwriteAddress = true;
        let overwriteAddressPop = (0, _checkAddressOverwrite.default)(tripData.stopPoints[0].address, fixedPriceTrip.pickup.address);

        if (overwriteAddressPop) {
          overwriteAddress = confirm('Do you want to overwrite Address');
        }

        if (overwriteAddress) {
          (0, _populateFixedPrice.default)(tripData, fixedPriceTrip, this.get('geocode'), this.get('store'));
        }
      }

    }
  });

  _exports.default = _default;
});