define("adept-iq/services/schedule-generation", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: derive from agency
  const trip_WINDOW_DAYS = 1;
  const TIME_ZONE = 'America/Los_Angeles';

  var _default = Ember.Service.extend({
    activeContext: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    isRunning: false,

    init() {
      this._super(...arguments);
    },

    task: (0, _emberConcurrency.task)(function* () {
      return yield this.performTask();
    }).drop(),

    performTask() {
      let schedules;
      const dates = this.getScheduleDates();
      this.set('isRunning', true);
      return this.fetchDSSchedulesForDates(dates).then(_schedules => {
        schedules = _schedules;
        return this.confirmScheduleDeletion(schedules);
      }).then(() => {
        const scheduleDeletions = Ember.RSVP.all(schedules.map(schedule => {
          return schedule.destroyRecord();
        }));
        return Ember.RSVP.hash({
          ssSchedules: this.fetchOrCreateSSSchedulesForDates(dates),
          scheduleDeletions
        });
      }).then(({
        ssSchedules
      }) => {
        return Ember.RSVP.all(ssSchedules.map(ssSchedule => {
          return ssSchedule.importtrips().then(() => {
            return ssSchedule.regenerateSchedule();
          }).then(() => {
            return ssSchedule.exportSchedule();
          });
        }));
      }).then(() => {
        // no promise to return here; refresh extends well beyond task
        this.get('activeContext').refreshAll();
        this.set('isRunning', false);
      }).catch(err => {
        this.set('isRunning', false);
        if (!err || !err.message) return;
        this.get('errorMessage').pushError({
          title: 'Error generating schedule',
          detail: err.message
        });
      });
    },

    getScheduleDates() {
      const endDate = this.get('workspace.endDate');

      const tomorrow = _moment.default.tz(TIME_ZONE).startOf('day').add(1, 'day');

      const maxDate = tomorrow.clone().add(trip_WINDOW_DAYS - 1, 'day');
      const maxTime = Math.min(endDate.valueOf(), maxDate.valueOf()); // regenerate all days after today and within end time and trip window

      const dates = [];
      let date = tomorrow;

      while (date.valueOf() <= maxTime) {
        dates.push(date);
        date = date.clone().add(1, 'day');
      }

      return dates;
    },

    fetchOrCreateSSSchedulesForDates(dates) {
      const ssSchedulesQuery = this.get('activeContext').queryServer('ss-schedule');
      return ssSchedulesQuery.then(ssSchedules => {
        return Ember.RSVP.all(dates.map(date => {
          const dateStart = _moment.default.tz(date, TIME_ZONE).startOf('day');

          const dateEnd = _moment.default.tz(date, TIME_ZONE).endOf('day');

          let ssSchedule = ssSchedules.find(currssSchedule => {
            const scheduleStart = _moment.default.tz(currssSchedule.get('start'), TIME_ZONE);

            return scheduleStart.startOf('day').valueOf() === dateStart.valueOf();
          });

          if (ssSchedule) {
            return Ember.RSVP.resolve(ssSchedule);
          } // ensure name is unique


          const baseName = dateStart.format('YYYY-MM-DD');
          let name = baseName;

          for (let i = 1;; i++) {
            if (!ssSchedules.findBy('name', name)) break;
            name = `${baseName} (${i})`;
          }

          ssSchedule = this.get('store').createRecord('ss-schedule', {
            start: dateStart.toDate(),
            end: dateEnd.toDate(),
            name
          });
          return ssSchedule.save();
        }));
      });
    },

    fetchDSSchedulesForDates(dates) {
      // TODO: add node to filter for future-only and ditch days altogether
      const schedulesQuery = this.get('activeContext').queryServer('schedule');
      return schedulesQuery.then(schedules => {
        return dates.reduce((arr, date) => {
          const dateStart = _moment.default.tz(date, TIME_ZONE).startOf('day');

          const schedule = schedules.find(currSchedule => {
            const scheduleStart = _moment.default.tz(currSchedule.get('startTime'), TIME_ZONE);

            return scheduleStart.startOf('day').valueOf() === dateStart.valueOf();
          });

          if (schedule) {
            arr.push(schedule);
          }

          return arr;
        }, []);
      });
    },

    confirmScheduleDeletion(schedules) {
      if (Ember.isEmpty(schedules)) return Ember.RSVP.resolve();
      const tooltip = this.get('tooltip');
      this.set('isRunning', false);
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('tooltip').setProperties({
          tip: 'Regenerating the schedule may result in loss of data. Proceed?',
          primaryActionText: 'Confirm',
          secondaryActionText: 'Cancel',
          primaryAction: () => {
            tooltip.reset();
            this.set('isRunning', true);
            resolve();
          },
          secondaryAction: () => {
            tooltip.reset();
            reject();
          }
        });
      });
    }

  });

  _exports.default = _default;
});