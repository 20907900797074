define("adept-iq/pods/components/iq-widgets/edit-settings-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/edit-settings-widget',
    rowComponent: 'table/rows/action-row',
    modelName: 'system-config-type',
    title: 'Settings',
    defaultSortId: 'name',
    defaultSortAsc: true,
    columns: [{
      id: 'type',
      index: 0,
      type: 'boolean',
      label: 'Type',
      valuePath: 'type',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 140
    }, {
      id: 'description',
      index: 1,
      type: 'text',
      label: 'Description',
      valuePath: 'description',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 260
    }]
  };
  _exports.default = _default;
});