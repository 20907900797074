define("adept-iq/pods/components/iq-widgets/passengers-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: 'Add Passenger',
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'riderId',
        type: 'text',
        label: 'ID',
        valuePath: 'riderId',
        editable: false
      }]
    }, {
      title: 'Editable',
      fields: [{
        id: 'firstName',
        type: 'text',
        label: 'First Name',
        valuePath: 'firstName'
      }, {
        id: 'lastName',
        type: 'text',
        label: 'Last Name',
        valuePath: 'lastName'
      }, {
        id: 'dateOfBirth',
        type: 'date',
        label: 'Date Of Birth',
        valuePath: 'dateOfBirth'
      }, {
        id: 'notes',
        type: 'text',
        label: 'Notes',
        valuePath: 'notes'
      }, {
        id: 'fullPhoneNumber',
        type: 'text',
        label: 'Phone Number',
        valuePath: 'fullPhoneNumber'
      }]
    }, {
      title: 'Primary Addresses',
      fields: [{
        id: 'primaryAddresses',
        type: 'places',
        label: '',
        valuePath: 'primaryAddresses',
        modelName: 'rms-address',
        isMultiType: true,
        extra: {
          aliasPath: 'alias',
          notesPath: 'notes',
          streetNrPath: 'streetNr',
          streetAddressPath: 'streetAddress',
          localityPath: 'locality',
          regionPath: 'region',
          subRegionPath: 'subRegion',
          postalCodePath: 'postalCode',
          countryPath: 'country',
          defaultOptionsPath: 'callerTrips'
        }
      }]
    }, {
      title: 'Favorite Addresses',
      fields: [{
        id: 'favoriteAddresses',
        type: 'places',
        label: '',
        valuePath: 'favoriteAddresses',
        modelName: 'rms-address',
        isMultiType: true,
        extra: {
          aliasPath: 'alias',
          notesPath: 'notes',
          streetNrPath: 'streetNr',
          streetAddressPath: 'streetAddress',
          localityPath: 'locality',
          regionPath: 'region',
          subRegionPath: 'subRegion',
          postalCodePath: 'postalCode',
          countryPath: 'country'
        }
      }]
    }, {
      title: 'Travel Needs',
      fields: [{
        id: 'travelNeeds',
        type: 'travel-needs',
        label: '',
        valuePath: 'travelNeeds',
        modelName: 'rms-rider-travel-need',
        isMultiType: true,
        extra: {
          travelNeedTypeModelName: 'rms-travel-need-type',
          passengerTypeModelName: 'rms-passenger-type',
          countPath: 'count',
          travelNeedTypePath: 'travelNeedType',
          passengerTypePath: 'passengerType'
        }
      }]
    }, {
      title: 'Eligibilities',
      fields: [{
        id: 'eligibilities',
        type: 'eligibilities',
        label: '',
        valuePath: 'eligibilities',
        modelName: 'rms-rider-eligibility',
        isMultiType: true,
        extra: {
          eligibilityTypeModelName: 'rms-eligibility-type',
          fromPath: 'from',
          toPath: 'to',
          eligibilityTypePath: 'eligibilityType'
        }
      }]
    }]
  };
  _exports.default = _default;
});