define("adept-iq/pods/components/iq-widgets/map-widget/data-joins/circle", ["exports", "adept-iq/pods/components/iq-widgets/map-widget/data-joins/base", "adept-iq/pods/components/iq-widgets/map-widget/config/circle", "lodash", "tomtom"], function (_exports, _base, _circle, _lodash, _tomtom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    enter(node) {
      const points = node.get('points');
      if (Ember.isEmpty(points)) return;
      this.createCircleFor(node);
    },

    update(node) {
      const points = node.get('points');
      const circle = node.get('circle');

      if (Ember.isEmpty(points)) {
        if (circle) {
          this.removeCircleFor(node);
        }

        return;
      }

      if (!circle) {
        this.createCircleFor(node);
      }

      this.updateCircleFor(node);
    },

    exit(node) {
      if (node.get('circle')) {
        this.removeCircleFor(node);
      }
    },

    createCircleFor(node) {
      const label = node.get('label');
      const record = node.get('record');
      let style = node.get('style');
      const parentLabel = node.get('parentLabel');

      if (record.get('style') !== undefined && record.get('style')) {
        style = record.get('style');
      }

      const defaultOptions = _circle.default[style] || {};
      const nodeOptions = node.get('options') || {};
      const modelName = record.constructor.modelName;

      const options = _lodash.default.merge(defaultOptions, nodeOptions, {
        label,
        opacity: node.get('opacity'),
        // for active context clicks
        isActive: node.get('isActive'),
        isTraversal: node.get('isTraversal'),
        modelId: record.get('id'),
        modelName
      });

      const points = node.get('points');
      const radius = node.get('radius');

      const circle = _tomtom.default.L.circle([points.lat, points.lng], radius, options);

      circle.setStyle(style);
      this.bindMouseEvents(circle);
      node.set('circle', circle);
      const map = this.get('map');
      circle.addTo(map);
      const parentLabelFormatted = '<table  style="position:relative;"><tr></tr><tr>' + '<td rowspan="2" style="font-weight: bold; font-size: 2em;text-align: center;opacity:0.4;">' + parentLabel + '</td><td rowspan="2">&nbsp;</td><<td rowspan="2">' + label + '</td></tr></table>'; //circle.bindPopup(label, { closeButton: false });

      circle.bindTooltip(parentLabelFormatted, {
        permanent: true,
        direction: "center",
        opacity: 0.4
      }).openTooltip();
    },

    updateCircleFor(node) {
      const opacity = node.get('opacity');
      const circle = node.get('circle');

      if (circle) {
        const label = node.get('label');
        const parentLabel = node.get('parentLabel');
        let style = node.get('style');
        const record = node.get('record');

        if (record.get('style') !== undefined && record.get('style')) {
          style = record.get('style');
        }

        const defaultOptions = _circle.default[style] || {};
        const nodeOptions = node.get('options') || {};
        const modelName = record.constructor.modelName;

        let options = _lodash.default.merge(defaultOptions, nodeOptions, {
          label,
          opacity: opacity,
          // for active context clicks
          isActive: node.get('isActive'),
          isTraversal: node.get('isTraversal'),
          modelId: record.get('id'),
          modelName
        });

        circle.setStyle(options);
        this.bindMouseEvents(circle);
        const parentLabelFormatted = '<table  style="position:relative;"><tr></tr><tr>' + '<td rowspan="2" style="font-weight: bold; font-size: 2em;text-align: center;">' + parentLabel + '</td><td rowspan="2">&nbsp;</td><td rowspan="2">' + label + '</td></tr></table>';
        circle.setTooltipContent(parentLabelFormatted);
      }
    },

    removeCircleFor(node) {
      const circle = node.get('circle');

      if (circle) {
        circle.remove();
        node.set('circle', null);
      }
    }

  });

  _exports.default = _default;
});