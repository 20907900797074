define("adept-iq/pods/components/iq-widgets/users-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/users-widget',
    rowComponent: 'table/rows/action-row',
    modelName: 'sso-user',
    title: 'Users',
    columns: [{
      id: 'email',
      index: 0,
      type: 'text',
      label: 'ID',
      valuePath: 'email',
      editable: false,
      hidden: false
    }, {
      id: 'displayName',
      index: 1,
      type: 'text',
      label: 'Name',
      valuePath: 'displayName',
      editable: false,
      hidden: false,
      defaultWidth: 45
    }, {
      id: 'userRoleRolenames',
      index: 2,
      type: 'text',
      label: 'Role Name',
      valuePath: 'UserRoleRolenames.firstObject.displayName',
      editable: false,
      hidden: false
    }, {
      id: 'status',
      index: 3,
      type: 'text',
      label: 'Status',
      valuePath: 'calculatedStatus',
      editable: false,
      hidden: false,
      defaultWidth: 70
    }]
  };
  _exports.default = _default;
});