define("adept-iq/pods/components/iq-widgets/edit-trip-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/trip-form-widget/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RESIZE_CURSOR_OFFSET = -2;
  const MAX_WIDTH = 800;
  const RESIZE_DEBOUNCE_TIME = 250;

  var _default = _component.default.extend({
    classNames: ['edit-trip-form-widget'],
    actions: {
      resizeModal(position) {
        let windowWidth = Ember.$(window).width(); // Resize cursor offset ensures cursor is centered on handle.

        let modalWidth = windowWidth - position.x + RESIZE_CURSOR_OFFSET;
        Ember.run.scheduleOnce('afterRender', () => {
          if (windowWidth - modalWidth >= MAX_WIDTH) {
            Ember.$('.edit-widget-modal').css('right', `${windowWidth - MAX_WIDTH}px`);
          } else {
            Ember.$('.edit-widget-modal').css('right', `${modalWidth}px`);
          }
        });
      },

      closeWidget() {
        this.toggleProperty('editmodalcontext.showWidgetModal');
      }

    }
  });

  _exports.default = _default;
});