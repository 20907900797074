define("adept-iq/services/error-message-avlm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    messages: null,
    warnings: null,
    infos: null,
    message: Ember.computed('messages.[]', function () {
      if (Ember.isBlank(this.get('messages'))) {
        return;
      }

      return this.get('messages').firstObject;
    }),
    warning: Ember.computed('warnings.[]', function () {
      if (Ember.isBlank(this.get('warnings'))) {
        return;
      }

      return this.get('warnings').firstObject;
    }),
    info: Ember.computed('infos.[]', function () {
      if (Ember.isBlank(this.get('infos'))) {
        return;
      }

      return this.get('infos').firstObject;
    }),

    pushError(error) {
      this.get('messages').pushObject(error);
    },

    shiftError() {
      this.get('messages').shiftObject();
    },

    pushWarning(warning) {
      this.get('warnings').pushObject(warning);
    },

    shiftWarning() {
      this.get('warnings').shiftObject();
    },

    pushInfo(info) {
      this.get('infos').pushObject(info);
    },

    shiftInfo() {
      this.get('infos').shiftObject();
    },

    flushMessageQueues() {
      this.get('messages').clear();
      this.get('warnings').clear();
      this.get('infos').clear();
    },

    init() {
      this.set('messages', []);
      this.set('warnings', []);
      this.set('infos', []);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});