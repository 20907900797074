define("adept-iq/models/cs-config-category", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    depth: (0, _attr.default)('number'),
    parent: (0, _relationships.belongsTo)('cs-config-category', {
      inverse: 'children'
    }),
    children: (0, _relationships.hasMany)('cs-config-category', {
      inverse: 'parent'
    }),
    configItems: (0, _relationships.hasMany)('cs-config-item'),
    categoryChain: Ember.computed('parent.categoryChain', function () {
      let categoryChain = [];
      const parent = this.get('parent');

      if (parent && parent.get('id')) {
        categoryChain = parent.get('categoryChain');
      }

      categoryChain.push(this);
      return categoryChain;
    })
  });

  _exports.default = _default;
});