define("adept-iq/pods/components/side-drawer/donut-chart-settings/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COLUMNS = {
    routeColumns: {
      status: {
        id: 'status',
        config: {
          id: 'status',
          index: 5,
          type: 'text',
          label: 'Status',
          valuePath: 'drivedStatus',
          editable: true,
          hidden: false,
          format: 'HH:mm A',
          defaultWidth: 75
        },
        filterTypeId: 'stringEq',
        filterValues: ['Scheduled']
      }
    },
    tripColumns: {
      status: {
        id: 'status',
        config: {
          id: 'status',
          index: 5,
          type: 'text',
          label: 'Status',
          valuePath: 'drivedStatus',
          editable: true,
          hidden: false,
          defaultWidth: 75
        },
        filterTypeId: null,
        filterValues: []
      }
    },
    stopColumns: {
      type: {
        id: 'type',
        config: {
          id: 'type',
          type: 'text',
          label: 'Type',
          valuePath: 'stop.type'
        },
        filterTypeId: 'stringNotEq',
        filterValues: ['route-start']
      }
    },
    vehicleColumns: {
      status: {
        id: 'status',
        config: {
          id: 'status',
          index: 5,
          type: 'text',
          label: 'Status',
          valuePath: 'drivedStatus',
          editable: true,
          hidden: false,
          defaultWidth: 75
        },
        filterTypeId: null,
        filterValues: []
      }
    },
    driverColumns: {
      status: {
        id: 'status',
        config: {
          id: 'status',
          index: 5,
          type: 'text',
          label: 'Status',
          valuePath: 'drivedStatus',
          editable: true,
          hidden: false,
          defaultWidth: 75
        },
        filterTypeId: null,
        filterValues: []
      }
    }
  };
  const VisualOptions = [{
    id: 'value',
    name: 'Show Value',
    isChecked: true
  }, {
    id: 'percentage',
    name: 'Show Percentage',
    isChecked: false
  }, {
    id: 'total',
    name: 'Show Total',
    isChecked: true
  }];
  const LegendPlacement = {
    selected: {
      name: 'Display On Right',
      position: 'right'
    },
    options: [{
      name: 'Display On Right',
      position: 'right'
    }, {
      name: 'Display On Left',
      position: 'left'
    }, {
      name: 'Display On Top',
      position: 'top'
    }, {
      name: 'Display On Bottom',
      position: 'bottom'
    }]
  };
  const SortOrder = {
    selected: {
      name: 'Ascending',
      sortAsc: true
    },
    options: [{
      name: 'Ascending',
      sortAsc: true
    }, {
      name: 'Descending',
      sortAsc: false
    }]
  };
  var _default = [{
    name: 'Trips',
    modelName: 'trip',
    columns: COLUMNS.tripColumns,
    displayOptions: [{
      id: 'slicedBy',
      name: 'Sliced By',
      selected: {
        id: 'source',
        name: 'Source',
        valueKey: 'tripType',
        valueCategories: [{
          label: 'Call',
          value: 'callAgent'
        }, {
          label: 'App',
          value: 'riderApp'
        }, {
          label: 'Web',
          value: 'webApp'
        }, {
          label: 'Kiosk',
          value: 'kiosk'
        }, {
          label: 'IVR',
          value: 'IVR'
        }]
      },
      options: [{
        id: 'status',
        name: 'Trip Status',
        valueKey: 'drivedStatus',
        valueCategories: [{
          label: 'Completed',
          value: 'completed'
        }, {
          label: 'Cancelled',
          value: 'cancelled'
        }, {
          label: 'No Show',
          value: 'NoShow'
        }]
      }, {
        id: 'bookingType',
        name: 'Trip Type',
        label: 'Trip Type',
        valueKey: 'bookingType',
        valueCategories: [{
          label: 'ASAP',
          value: 'asap'
        }, {
          label: 'Prebook',
          value: 'prebook'
        }, {
          label: 'Street Hire',
          value: 'street_hire'
        }]
      }, {
        id: 'source',
        name: 'Source',
        valueKey: 'tripType',
        valueCategories: [{
          label: 'Call',
          value: 'callAgent'
        }, {
          label: 'App',
          value: 'riderApp'
        }, {
          label: 'Web',
          value: 'webApp'
        }, {
          label: 'Kiosk',
          value: 'kiosk'
        }, {
          label: 'IVR',
          value: 'IVR'
        }]
      }, {
        id: 'paymentype',
        name: 'Payment Type',
        valueKey: 'paymentype',
        valueCategories: [{
          label: 'Cash',
          value: 'CASH'
        }, {
          label: 'Account',
          value: 'ACCOUNT'
        }, {
          label: 'Credit',
          value: 'CREDIT'
        }]
      }]
    }],
    title: '',
    visualOptions: VisualOptions,
    sortOrder: SortOrder,
    legendPlacement: LegendPlacement
  }, {
    name: 'Taxis',
    modelName: 'vehicle',
    columns: COLUMNS.vehicleColumns,
    displayOptions: [{
      id: 'slicedBy',
      name: 'Sliced By',
      selected: {
        id: 'status',
        name: 'Status',
        valueKey: 'drivedStatus',
        valueCategories: [{
          label: 'Free',
          value: 'free'
        }, {
          label: 'On Break',
          value: 'onBreak'
        }, {
          label: 'On Hold',
          value: 'onHold'
        }, {
          label: 'Off',
          value: 'off'
        }, {
          label: 'STC',
          value: 'STC'
        }, {
          label: 'Emergency',
          value: 'emergency'
        }, {
          label: 'Occupied',
          value: 'occupied'
        }, {
          label: 'Missing',
          value: 'missing'
        }]
      },
      options: [{
        id: 'status',
        name: 'Status',
        valueKey: 'drivedStatus',
        valueCategories: [{
          label: 'Free',
          value: 'free'
        }, {
          label: 'On Break',
          value: 'onBreak'
        }, {
          label: 'On Hold',
          value: 'onHold'
        }, {
          label: 'Off',
          value: 'off'
        }, {
          label: 'Occupied',
          value: 'occupied'
        }, {
          label: 'Missing',
          value: 'missing'
        }]
      }]
    }],
    title: '',
    visualOptions: VisualOptions,
    sortOrder: SortOrder,
    legendPlacement: LegendPlacement
  }, {
    name: 'Drivers',
    modelName: 'driver',
    columns: COLUMNS.driverColumns,
    displayOptions: [{
      id: 'slicedBy',
      name: 'Sliced By',
      selected: {
        id: 'status',
        name: 'Status',
        valueKey: 'drivedStatus',
        valueCategories: [{
          label: 'Active',
          value: 'active'
        }, {
          label: 'Working',
          value: 'working'
        }, {
          label: 'Off',
          value: 'signedOff'
        }, {
          label: 'On Break',
          value: 'onBreak'
        }, {
          label: 'Temp Off',
          value: 'tempOff'
        }, {
          label: 'Emergency',
          value: 'inEmergency'
        }]
      },
      options: [{
        id: 'status',
        name: 'Status',
        valueKey: 'drivedStatus',
        valueCategories: [{
          label: 'Active',
          value: 'active'
        }, {
          label: 'Working',
          value: 'working'
        }, {
          label: 'Off',
          value: 'signedOff'
        }, {
          label: 'On Break',
          value: 'onBreak'
        }, {
          label: 'Temp Off',
          value: 'tempOff'
        }, {
          label: 'Emergency',
          value: 'inEmergency'
        }]
      }]
    }],
    title: '',
    visualOptions: VisualOptions,
    sortOrder: SortOrder,
    legendPlacement: LegendPlacement
  }];
  _exports.default = _default;
});