define("adept-iq/pods/components/form-components/specialtext/component", ["exports", "adept-iq/utils/check-value"], function (_exports, _checkValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-specialtext'],
    value: null,
    disabled: false,
    movePath: Ember.computed.readOnly('extra.movePath'),
    moveSperator: Ember.computed.readOnly('extra.moveSperator'),
    actions: {
      moveData() {
        let record = this.get('record');
        record.set('driverNotes', (0, _checkValue.default)(record.get('driverNotes')) + this.get('moveSperator') + (0, _checkValue.default)(record.get('predefinedNotes.driverNotes')));
        record.set('predefinedNotes.driverNotes', '');
      }

    }
  });

  _exports.default = _default;
});