define("adept-iq/models/stop", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "adept-iq/mixins/otp-label", "adept-iq/mixins/status-value"], function (_exports, _model, _attr, _relationships, _otpLabel, _statusValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_statusValue.default, {
    driverNotes: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    tripId: (0, _attr.default)(),
    orderId: (0, _attr.default)(),
    calloutType: (0, _attr.default)('string'),
    invoiceRefNum: (0, _attr.default)('string'),
    fare: (0, _attr.default)(),
    stopStatus: (0, _attr.default)('string'),
    passengerName: (0, _attr.default)('string'),
    notes: (0, _attr.default)('string'),
    place: (0, _relationships.belongsTo)('place'),
    lat: Ember.computed.alias('place.lat'),
    lng: Ember.computed.alias('place.lng'),
    drivedStatus: Ember.computed.alias('trip.drivedStatus'),
    iqStop: (0, _relationships.belongsTo)('iq-stop', {
      inverse: 'stop'
    }),
    // Ember-Data needs explicit inverses
    picktrip: (0, _relationships.belongsTo)('trip', {
      inverse: 'pick'
    }),
    droptrip: (0, _relationships.belongsTo)('trip', {
      inverse: 'drop'
    }),
    // use this to get the trip (e.g. from stops widget)
    trip: Ember.computed('picktrip.content', 'droptrip.content', function () {
      return this.get('picktrip.content') || this.get('droptrip.content');
    }),
    onboardCount: (0, _attr.default)('number'),
    zone: (0, _attr.default)()
  });

  _exports.default = _default;
});