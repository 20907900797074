define("adept-iq/serializers/dashboard", ["exports", "adept-iq/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(modelClass, resourceHash) {
      if (!resourceHash.attributes.category) {
        resourceHash.attributes.category = this._extractCategory(resourceHash.id);
      }

      return this._super(modelClass, resourceHash);
    },

    _extractCategory(id) {
      return id.split('/')[0];
    }

  });

  _exports.default = _default;
});