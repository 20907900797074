define("adept-iq/models/avlm-route-exec-event", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    trackingId: (0, _attr.default)('string'),
    routeId: (0, _attr.default)('string'),
    routeVersion: (0, _attr.default)('string'),
    content: (0, _attr.default)('object'),
    // TODO: map these to `avlm-driver`, `avlm-vehicle` associations
    driver: (0, _attr.default)('object'),
    vehicle: (0, _attr.default)('object')
  });

  _exports.default = _default;
});