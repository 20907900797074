define("adept-iq/helpers/popupmodelpath", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.popupmodelpath = popupmodelpath;
  _exports.default = void 0;

  function popupmodelpath(widgetName) {
    const s = 'iq-widgets/' + widgetName;
    return s;
  }

  var _default = Ember.Helper.helper(popupmodelpath);

  _exports.default = _default;
});