define("adept-iq/models/tm-passenger-event", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/passenger-event.js */
  var _default = _model.default.extend({
    type: (0, _attr.default)('string'),
    passengerId: (0, _attr.default)('string'),
    time: (0, _attr.default)('string'),
    anchor: (0, _attr.default)('boolean'),
    driverNote: (0, _attr.default)('string'),
    pickupWindow: (0, _relationships.belongsTo)('tm-pickup-window'),
    stopPoint: (0, _relationships.belongsTo)('tm-stop-point')
  });

  _exports.default = _default;
});