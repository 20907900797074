define("adept-iq/services/riderprofile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    riderProfileData() {
      let riderProfileData = {
        "data": [{
          "type": "rider-profile",
          "id": "",
          "attributes": {
            "provider": {
              "type": "dedicated",
              "id": "",
              "name": "Yellow Cabs Of Our City"
            },
            "name": "",
            "contactLastName": "",
            "contactFirstName": "",
            "contactMiddleName": "",
            "phone1": {
              "number": "",
              "areaCode": "",
              "extension": "",
              "type": "fixed"
            },
            "phone2": {
              "number": "",
              "areaCode": "",
              "extension": "",
              "type": "fixed"
            },
            "email": "",
            "countryCode": "",
            "notes": {
              "driver": "",
              "dispatcher": ""
            },
            "callout": 0,
            "account": {
              "customerId": "",
              "accountId": ""
            },
            "requiredAttributes": [{
              "type": "",
              "cnt": 0
            }],
            "boardingOverhead": {
              "loadTime": 8,
              "unloadTime": 5
            },
            "defaultFavoritePaymentId": 1,
            "favoritePayments": [{
              "id": 1,
              "currencyCode": "CAD",
              "tip": {
                "percent": 0,
                "minValue": 0
              },
              "creditCardId": ""
            }],
            "defaultFavoriteTripId": 1,
            "favoriteTrips": [{
              "priceType": "normal",
              "price": {
                "name": "",
                "detail": "",
                "amount": 0
              },
              "customerReference": "",
              "tripId": "",
              "pickup": {
                "id": 1,
                "name": "work",
                "address": {
                  "formattedAddress": "",
                  "streetName": "",
                  "streetNr": "",
                  "unitNr": "",
                  "region": "",
                  "postCode": "",
                  "city": "",
                  "state": "",
                  "country": "",
                  "description": "",
                  "coord": {
                    "lat": 0,
                    "lng": 0
                  }
                },
                "noteForDriver": ""
              },
              "dropoff": {
                "id": 1,
                "name": "work",
                "address": {
                  "formattedAddress": "",
                  "streetName": "",
                  "streetNr": "",
                  "unitNr": "",
                  "region": "",
                  "postCode": "",
                  "city": "",
                  "state": "",
                  "country": "",
                  "description": "",
                  "coord": {
                    "lat": 0,
                    "lng": 0
                  }
                },
                "noteForDriver": ""
              }
            }],
            "eligibilities": [{
              "id": "",
              "type": "",
              "from": "2019-05-01T23:27:19.484Z",
              "to": "2019-05-01T23:27:19.484Z"
            }],
            "SSN": "",
            "dob": "2019-05-01T23:27:19.484Z",
            "locale": "",
            "externalId": "",
            "status": "active"
          }
        }]
      };
      return riderProfileData;
    }

  });

  _exports.default = _default;
});