define("adept-iq/models/iq-stop", ["exports", "ember-data/model", "ember-data/relationships", "adept-iq/mixins/otp-label", "adept-iq/mixins/status-value", "lodash"], function (_exports, _model, _relationships, _otpLabel, _statusValue, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    sample
  } = _lodash.default;

  var _default = _model.default.extend(_otpLabel.default, _statusValue.default, {
    stop: (0, _relationships.belongsTo)('stop', {
      inverse: 'iqStop'
    }),
    lat: Ember.computed.alias('stop.place.lat'),
    lng: Ember.computed.alias('stop.place.lng'),
    otp: Ember.computed('', function () {
      return sample(['O', 'L', 'D', 'E']);
    }),
    otpValue: Ember.computed('', function () {
      return sample(['O', 'L', 'D', 'E']);
    }),
    status: Ember.computed.alias('stop.trip.iqTrip.status')
  });

  _exports.default = _default;
});