define("adept-iq/pods/components/iq-widgets/map-widget/config/arrow", ["exports", "adept-iq/config/icon-paths", "tomtom"], function (_exports, _iconPaths, _tomtom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    tripArrowPlanned: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.tripArrow.ontime,
        iconSize: [20, 20],
        iconAnchor: [20, 10]
      }),
      rotationAngle: null,
      rotationOrigin: 'center right',
      color: '#555e63'
    },
    tripArrowPlannedDanger: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.tripArrow.danger,
        iconSize: [20, 20],
        iconAnchor: [20, 10]
      }),
      rotationAngle: null,
      rotationOrigin: 'center right',
      color: '#f09240'
    },
    tripArrowPlannedLate: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.tripArrow.late,
        iconSize: [20, 20],
        iconAnchor: [20, 10]
      }),
      rotationAngle: null,
      rotationOrigin: 'center right',
      color: '#d13730'
    },
    tripArrowPlannedOffset: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.tripArrowOffset.ontime,
        iconSize: [35, 20],
        iconAnchor: [35, 10]
      }),
      rotationAngle: null,
      rotationOrigin: 'center right',
      color: '#555e63'
    },
    tripArrowPlannedOffsetLate: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.tripArrowOffset.late,
        iconSize: [35, 20],
        iconAnchor: [35, 10]
      }),
      rotationAngle: null,
      rotationOrigin: 'center right',
      color: '#d13730'
    },
    tripArrowPlannedOffsetDanger: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.tripArrowOffset.danger,
        iconSize: [35, 20],
        iconAnchor: [35, 10]
      }),
      rotationAngle: null,
      rotationOrigin: 'center right',
      color: '#f09240'
    }
  };
  _exports.default = _default;
});