define("adept-iq/pods/components/side-drawer/system-config/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A+0FxKRU",
    "block": "{\"symbols\":[\"drawer\"],\"statements\":[[4,\"generic-widgets/side-drawer\",null,[[\"class\"],[\"system-config-side-drawer\"]],{\"statements\":[[0,\"\\n  \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"header\"]],\"expected `drawer.header` to be a contextual component but found a string. Did you mean `(component drawer.header)`? ('adept-iq/pods/components/side-drawer/system-config/template.hbs' @ L5:C4) \"],null]],[[\"title\",\"canSave\",\"canRefresh\",\"onShrinkClick\",\"onRefreshClick\",\"onSaveClick\",\"onCloseClick\"],[[23,[\"title\"]],[27,\"and\",[[27,\"not\",[[23,[\"isProcessing\"]]],null],[23,[\"isModified\"]]],null],[27,\"not\",[[23,[\"isProcessing\"]]],null],[27,\"action\",[[22,0,[]],[23,[\"onShrinkClick\"]]],null],[27,\"action\",[[22,0,[]],\"onRefreshClick\"],null],[27,\"action\",[[22,0,[]],\"onSaveClick\"],null],[27,\"action\",[[22,0,[]],[23,[\"onCloseClick\"]]],null]]]],false],[0,\"\\n\\n\"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"body\"]],\"expected `drawer.body` to be a contextual component but found a string. Did you mean `(component drawer.body)`? ('adept-iq/pods/components/side-drawer/system-config/template.hbs' @ L14:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"generic-widgets/tree-widget\",null,[[\"rootNodes\",\"isLoading\"],[[23,[\"rootNodes\"]],[23,[\"isProcessing\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/side-drawer/system-config/template.hbs"
    }
  });

  _exports.default = _default;
});