define("adept-iq/models/tm-text-message", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    subject: attr(),
    body: attr(),
    sender: attr(),
    recipients: attr(),
    deliveryTime: attr(),
    conversation: attr(),
    driver: attr(),
    vehicle: attr()
  });

  _exports.default = _default;
});