define("adept-iq/pods/components/iq-widgets/routes-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Section 1',
      fields: [{
        id: 'id',
        type: 'uuid',
        label: 'ID',
        valuePath: 'id',
        editable: false
      }]
    }, {
      title: 'Section 2',
      fields: [{
        id: 'name',
        type: 'text',
        label: 'Name',
        valuePath: 'name'
      }, {
        id: 'date',
        type: 'dateflatpickr',
        label: 'Date',
        valuePath: 'routeDate',
        format: 'hh:mm A'
      }, {
        id: 'start',
        type: 'timeflatpickr',
        label: 'Start',
        valuePath: 'plannedStartTime',
        format: 'hh:mm A'
      }, {
        id: 'end',
        type: 'timeflatpickr',
        label: 'End',
        valuePath: 'plannedEndTime',
        format: 'hh:mm A'
      }, {
        id: 'vehicle',
        type: 'searchable-enum',
        label: 'Vehicle',
        valuePath: 'vehicleName',
        extra: {
          optionModelName: 'vehicle',
          optionSearchPath: 'name',
          optionSearchEnabled: true,
          optionIncludes: 'vehicleRoutes',
          optionTempRecordSelected: 'selectedVehicle',
          optionFilterFunction: 'isVehicleAvailable'
        }
      }, {
        id: 'driver',
        type: 'searchable-enum',
        label: 'Driver',
        valuePath: 'driverExternalId',
        extra: {
          optionModelName: 'driver',
          optionSearchPath: 'externalId',
          optionSearchEnabled: true,
          optionIncludes: 'driverShifts',
          optionTempRecordSelected: 'selectedDriver',
          optionFilterFunction: 'isDriverAvailable'
        }
      }]
    }]
  };
  _exports.default = _default;
});