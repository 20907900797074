define("adept-iq/services/system-config", ["exports", "ember-concurrency", "lodash", "adept-iq/classes/tree-nodes/category", "adept-iq/classes/tree-nodes/object", "adept-iq/classes/tree-nodes/value"], function (_exports, _emberConcurrency, _lodash, _category, _object, _value) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ROOT_CATEGORY = 'config';
  const logger = {
    warn: console.warn // eslint-disable-line no-console

  };

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    rootCategory: null,
    rootNodes: null,
    isLoading: Ember.computed.readOnly('fetchTask.isRunning'),
    isSaving: Ember.computed.readOnly('saveTask.isRunning'),
    isProcessing: Ember.computed.or('isLoading', 'isSaving'),
    isModified: Ember.computed('rootNodes.@each.isTreeModified', function () {
      const rootNodes = this.get('rootNodes');
      if (Ember.isEmpty(rootNodes)) return false;
      return rootNodes.any(node => node.get('isTreeModified'));
    }),
    isOutdated: Ember.computed('rootNodes.@each.isTreeOutdated', function () {
      const rootNodes = this.get('rootNodes');
      if (Ember.isEmpty(rootNodes)) return false;
      return rootNodes.any(node => node.get('isTreeOutdated'));
    }),
    isDirty: Ember.computed.or('isModified', 'isOutdated'),

    init() {
      this._super(...arguments);

      this.refresh();
    },

    refresh() {
      return this.get('fetchTask').perform();
    },

    save() {
      if (!this.get('isModified')) {
        /*eslint-disable */
        throw 'cannot save unmodified config tree';
        /*eslint-enable */
      }

      return this.get('saveTask').perform();
    },

    fetchTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store'); // this loads all associated categories as a side-effect

      yield store.query('cs-config-item', {
        category: ROOT_CATEGORY
      });
      const rootCategory = store.peekRecord('cs-config-category', ROOT_CATEGORY);
      this.set('rootCategory', rootCategory);
      const rootNodes = rootCategory.get('children').sortBy('name').map(category => {
        return this.buildCategoryTree(category);
      });
      this.set('rootNodes', rootNodes);
    }).drop(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(500);
      const modifiedNodes = [];
      const rootNodes = this.get('rootNodes'); // make sure we duplicate array!

      const queue = [...rootNodes]; // BFS search means we can prune at unmodified & object nodes

      while (queue.length > 0) {
        const node = queue.shift();
        /*eslint-disable */

        if (!node.get('isTreeModified')) continue;
        /*eslint-enable */

        if (node.get('isObject') || node.get('isValue')) {
          modifiedNodes.push(node);
        } else {
          const children = node.get('children');
          queue.push(...children);
        }
      }

      const promises = modifiedNodes.map(node => {
        let value;

        if (node.get('isValue')) {
          value = node.get('value');
        }

        if (node.get('isObject')) {
          value = node.get('children').reduce((obj, child) => {
            const key = child.get('label');
            const childValue = child.get('value');
            obj[key] = childValue;
            return obj;
          }, {});
        }

        const configItem = node.get('configItem');
        configItem.set('value', value);
        return configItem.save().then(() => {
          if (node.get('isValue')) {
            node.set('originalValue', value);
          }

          if (node.get('isObject')) {
            node.get('children').forEach(child => {
              child.set('originalValue', child.get('value'));
            });
          }
        });
      });
      return yield Ember.RSVP.all(promises);
    }).drop(),

    buildCategoryTree(category, parent) {
      const node = _category.default.create({
        label: category.get('name'),
        type: 'category',
        depth: parent ? parent.get('depth') + 1 : 0,
        parent
      });

      const children = [];
      category.get('children').sortBy('name').forEach(childCategory => {
        const child = this.buildCategoryTree(childCategory, node);
        children.push(child);
      });
      category.get('configItems').forEach(configItem => {
        const child = this.buildItemTree(configItem, node);
        children.push(child);
      });
      node.set('children', children);
      return node;
    },

    buildItemTree(configItem, parent) {
      const name = configItem.get('name');
      const value = configItem.get('value');
      const specifiedType = this.specifiedTypeFor(configItem.get('type'));
      const inferredType = this.inferredTypeFor(value);
      let type = this.nodeTypeFor(specifiedType) || this.nodeTypeFor(inferredType) || 'default';

      if (inferredType === 'object' && type !== 'object') {
        logger.warn(`config-item ${name} has object value but specified type ${type}`);
        type = 'object';
      }

      const depth = parent ? parent.get('depth') + 1 : 0;
      const node = this.treeNodeClassFor(type).create({
        configItem,
        type,
        specifiedType,
        inferredType,
        label: name,
        value: _lodash.default.cloneDeep(value),
        originalValue: _lodash.default.cloneDeep(value),
        depth,
        parent
      });

      if (type === 'object') {
        // handle JSON
        const children = Object.entries(value).map(([key, val]) => {
          const inferredChildType = this.inferredTypeFor(val);
          const childType = this.nodeTypeFor(inferredChildType) || 'default';
          return this.treeNodeClassFor(childType).create({
            label: key,
            type: childType,
            value: _lodash.default.cloneDeep(val),
            originalValue: _lodash.default.cloneDeep(val),
            depth: depth + 1
          });
        }).sortBy('label');
        node.set('children', children);
      }

      return node;
    },

    specifiedTypeFor(typeString) {
      return Ember.isPresent(typeString) ? typeString.toLowerCase() : null;
    },

    inferredTypeFor(value) {
      return Ember.isPresent(value) ? Ember.typeOf(value) : null;
    },

    nodeTypeFor(type) {
      switch (type) {
        case 'int':
        case 'float':
        case 'number':
          return 'number';

        case 'char':
        case 'string':
          return 'string';

        case 'json':
        case 'object':
          return 'object';

        case 'boolean':
        case 'true/false':
          return 'boolean';

        case 'color':
          return 'color';

        default:
          return null;
      }
    },

    treeNodeClassFor(type) {
      switch (type) {
        case 'category':
          return _category.default;

        case 'object':
          return _object.default;

        default:
          return _value.default;
      }
    }

  });

  _exports.default = _default;
});