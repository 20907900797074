define("adept-iq/pods/components/form-components/radio/component", ["exports", "moment", "adept-iq/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-radio'],
    editModal: Ember.inject.service(),
    options: Ember.computed.alias('extra.options'),
    value: null,
    tempValue: null,
    disabled: false,

    init() {
      this._super(...arguments);

      if (Ember.isBlank(this.get('value'))) {
        this.set('tempValue', 'none');
      } else {
        this.set('tempValue', this.get('value'));
      }
    },

    actions: {
      valueChanged(value) {
        if (value === 'none') {
          this.set(`record.${this.get('valuePath')}`, "");
        } else {
          this.set(`record.${this.get('valuePath')}`, value.trim());
        }
      }

    }
  });

  _exports.default = _default;
});