define("adept-iq/models/sso-role", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    roleName: attr('string'),
    displayName: attr('string'),
    status: attr('string')
  });

  _exports.default = _default;
});