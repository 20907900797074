define("adept-iq/utils/populate-call-line", ["exports", "adept-iq/utils/check-address-flags", "adept-iq/utils/populate-address", "adept-iq/utils/check-value", "lodash"], function (_exports, _checkAddressFlags, _populateAddress, _checkValue, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = populateCallLine;

  function populateCallLineData(tripData, callLineData, geocode, store) {
    console.log('callLineData ', callLineData);
    tripData.set('passengers.0.firstName', callLineData.name);

    if (!Ember.isEmpty(callLineData.favoriteTrips) && !Ember.isBlank(callLineData.favoriteTrips[0].pickup) && !Ember.isBlank(callLineData.favoriteTrips[0].pickup.address)) {
      (0, _populateAddress.default)(tripData.stopPoints[0].address, callLineData.favoriteTrips[0].pickup.address, geocode, store, 'pickup', tripData, true);

      try {
        if (!Ember.isBlank(callLineData.favoriteTrips[0].notes)) {
          Ember.set(tripData, 'editmodelcontext.predefinedDriverNotes.address', (0, _checkValue.default)(callLineData.favoriteTrips[0].notes.driver));
          Ember.set(tripData, 'editmodelcontext.predefinedOperatorNotes.address', (0, _checkValue.default)(callLineData.favoriteTrips[0].notes.dispatcher));
        } else {
          Ember.set(tripData, 'editmodelcontext.predefinedDriverNotes.address', '');
          Ember.set(tripData, 'editmodelcontext.predefinedOperatorNotes.address', '');
        }
      } catch (e) {}
    }

    if (!Ember.isEmpty(callLineData.favoriteTrips) && !Ember.isBlank(callLineData.favoriteTrips[0].dropoff) && !Ember.isBlank(callLineData.favoriteTrips[0].dropoff.address)) {
      (0, _populateAddress.default)(tripData.stopPoints[1].address, callLineData.favoriteTrips[0].dropoff.address, geocode, store, 'dropoff', null, false);
    }

    let callLineAccount = callLineData.account.accountId;

    if (!Ember.isBlank(callLineAccount) && !Ember.isBlank(callLineAccount.account)) {
      tripData.acctCardNr = callLineAccount.account.accountId;
      tripData.set('acctCardNr', callLineAccount.account.accountId); //tripData.set('editmodelcontext.accountTrips', callLineAccount.get('favoritePickups'));
      //tripData.set('editmodelcontext.accountFixedPriceTrips', callLineAccount.get('fixedPriceTrips'));
    }
    /* else {
     populateAddress(tripData.stopPoints[1].address,
                     null,
                     geocode,
                     store,
                     'dropoff',
                     null);
    }*/


    tripData.set('acctCardNr', callLineData.account.accountId);
    let options = store.peekAll('tm-common-operational-attribute');
    let requiredAttributes = callLineData.requiredAttributes;
    let cliAttributes = options.filter(function (o1) {
      // if match found return false
      return _lodash.default.findIndex(requiredAttributes, {
        'type': o1.displayName
      }) === -1 ? false : true;
    }); //////console.log(('callLineData',callLineData);

    let cliDriverNotes = callLineData.notes.driver;
    let cliOperatorNotes = callLineData.notes.dispatcher;
    Ember.set(tripData, 'editmodelcontext.attributes.cli', cliAttributes);
    Ember.set(tripData, 'driverNotes', (0, _checkValue.default)(tripData.get('driverNotes')) + " " + cliDriverNotes);
    Ember.set(tripData, 'operatorNotes', (0, _checkValue.default)(tripData.get('operatorNotes')) + " " + cliOperatorNotes);
    (0, _checkAddressFlags.default)(tripData);
  }

  function clearTripData(tripData, geocode, store) {
    try {
      tripData.set('prices.0.fixed', 0);
    } catch (e) {}

    try {
      tripData.set('price.fixed', 0);
    } catch (e) {}

    (0, _populateAddress.default)(tripData.stopPoints[0].address, null, geocode, store, 'pickup', tripData, true);
    (0, _populateAddress.default)(tripData.stopPoints[1].address, null, geocode, store, 'dropoff', null, false);
    geocode.deactivateGeocode();
  }

  function populateCallLine(tripRecord, fixedPriceTripData, geocode, store) {
    if (fixedPriceTripData !== null) {
      clearTripData(tripRecord, geocode, store);
      populateCallLineData(tripRecord, fixedPriceTripData, geocode, store);
    } else {
      clearTripData(tripRecord, geocode, store);
    }

    return;
  }
});