define("adept-iq/config/active-context-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.activeContextGraph = _exports.activeContextNodes = _exports.iqStopNode = _exports.iqTripNode = _exports.iqRiderNode = _exports.iqVehicleNode = _exports.iqDriverNode = _exports.tmDriverNode = _exports.tmCannedMessageNode = _exports.ssoUserNode = _exports.standsNode = _exports.zonesNode = _exports.vehicleStateNode = _exports.vehicleNode = _exports.driverNode = _exports.accountNode = _exports.subscriptionNode = _exports.callLineNode = _exports.riderNode = _exports.stopNode = _exports.tripNode = void 0;
  const tripNode = {
    id: 'trip',
    modelName: 'trip',
    links: [{
      type: 'belongsTo',
      nodeId: 'iq-trip',
      path: 'iqTrip'
    }, {
      // this is a hack for streaming; better to go through `legs`
      type: 'hasMany',
      nodeId: 'stop',
      path: 'stops'
    }, {
      type: 'belongsTo',
      nodeId: 'rider',
      path: 'rider'
    }, {
      type: 'belongsTo',
      nodeId: 'account',
      path: 'account'
    }, {
      type: 'belongsTo',
      nodeId: 'vehicle-state',
      path: 'vehicleState'
    }],
    isActive: true,
    leftTimeConstraint: 'requestedTime',
    rightTimeConstraint: 'requestedTime'
  };
  _exports.tripNode = tripNode;
  const stopNode = {
    id: 'stop',
    modelName: 'stop',
    links: [{
      type: 'belongsTo',
      nodeId: 'iq-stop',
      path: 'iqStop'
    }, {
      // this is a hack for streaming; better to go through `segment`
      type: 'belongsTo',
      nodeId: 'trip',
      path: 'trip'
    }],
    isActive: true,
    leftTimeConstraint: 'trip.requestedTime',
    rightTimeConstraint: 'trip.requestedTime'
  };
  _exports.stopNode = stopNode;
  const riderNode = {
    id: 'rider',
    modelName: 'rider',
    links: [{
      type: 'belongsTo',
      nodeId: 'iq-rider',
      path: 'iqRider'
    }, {
      type: 'hasMany',
      nodeId: 'trip',
      path: 'trips'
    }, {
      type: 'hasMany',
      nodeId: 'call-line',
      path: 'callLines'
    }],
    isActive: true
  };
  _exports.riderNode = riderNode;
  const callLineNode = {
    id: 'call-line',
    modelName: 'call-line',
    links: [{
      type: 'belongsTo',
      nodeId: 'account',
      path: 'account'
    }],
    isActive: false
  };
  _exports.callLineNode = callLineNode;
  const subscriptionNode = {
    id: 'subscription',
    modelName: 'subscription'
  };
  _exports.subscriptionNode = subscriptionNode;
  const accountNode = {
    id: 'account',
    modelName: 'account',
    links: [{
      type: 'hasMany',
      nodeId: 'trip',
      path: 'trips'
    }, {
      type: 'hasMany',
      nodeId: 'call-line',
      path: 'callLines'
    }],
    isActive: true
  };
  _exports.accountNode = accountNode;
  const driverNode = {
    id: 'driver',
    modelName: 'driver',
    links: [{
      type: 'belongsTo',
      nodeId: 'iq-driver',
      path: 'iqDriver'
    }, {
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'vehicle'
    }],
    isActive: true
  };
  _exports.driverNode = driverNode;
  const vehicleNode = {
    id: 'vehicle',
    modelName: 'vehicle',
    links: [{
      type: 'belongsTo',
      nodeId: 'iq-vehicle',
      path: 'iqVehicle'
    }, {
      type: 'belongsTo',
      nodeId: 'driver',
      path: 'driver'
    }, {
      type: 'belongsTo',
      nodeId: 'vehicle-state',
      path: 'vehicleState'
    }],
    isActive: true
  };
  _exports.vehicleNode = vehicleNode;
  const vehicleStateNode = {
    id: 'vehicle-state',
    modelName: 'vehicle-state',
    links: [{
      type: 'belongsTo',
      nodeId: 'driver',
      path: 'driver'
    }, {
      type: 'belongsTo',
      nodeId: 'trip',
      path: 'trip'
    }],
    isActive: true
  };
  _exports.vehicleStateNode = vehicleStateNode;
  const zonesNode = {
    id: 'zone',
    modelName: 'zone',
    links: [{
      type: 'hasMany',
      nodeId: 'trip',
      path: 'trips'
    }, {
      type: 'hasMany',
      nodeId: 'vehicle',
      path: 'vehicles'
    }],
    isActive: true
  };
  _exports.zonesNode = zonesNode;
  const standsNode = {
    id: 'stand',
    modelName: 'stand',
    links: [],
    isActive: true
  };
  _exports.standsNode = standsNode;
  const ssoUserNode = {
    id: 'sso-user',
    modelName: 'sso-user'
  };
  _exports.ssoUserNode = ssoUserNode;
  const tmCannedMessageNode = {
    id: 'tm-canned-message',
    modelName: 'tm-canned-message',
    links: [{
      type: 'belongsTo',
      nodeId: 'tm-driver',
      path: 'driver'
    }],
    isActive: true,
    leftTimeConstraint: 'createdTime',
    rightTimeConstraint: 'createdTime'
  };
  _exports.tmCannedMessageNode = tmCannedMessageNode;
  const tmDriverNode = {
    id: 'tm-driver',
    modelName: 'tm-driver',
    links: [{
      type: 'hasMany',
      nodeId: 'tm-canned-message',
      path: 'cannedMessages'
    }, {
      type: 'belongsTo',
      nodeId: 'iq-driver',
      path: 'iqDriver'
    }],
    isActive: true
  };
  _exports.tmDriverNode = tmDriverNode;
  const iqDriverNode = {
    id: 'iq-driver',
    modelName: 'iq-driver',
    links: [{
      // TODO: change to `belongsTo` when data is fixed
      type: 'hasMany',
      nodeId: 'tm-driver',
      path: 'tmDrivers'
    }, {
      type: 'belongsTo',
      nodeId: 'driver',
      path: 'driver'
    }],
    isActive: true
  };
  _exports.iqDriverNode = iqDriverNode;
  const iqVehicleNode = {
    id: 'iq-vehicle',
    modelName: 'iq-vehicle',
    links: [{
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'vehicle'
    }],
    isActive: true
  };
  _exports.iqVehicleNode = iqVehicleNode;
  const iqRiderNode = {
    id: 'iq-rider',
    modelName: 'iq-rider',
    links: [{
      type: 'belongsTo',
      nodeId: 'rider',
      path: 'rider'
    }],
    isActive: true
  };
  _exports.iqRiderNode = iqRiderNode;
  const iqTripNode = {
    id: 'iq-trip',
    modelName: 'iq-trip',
    links: [{
      type: 'belongsTo',
      nodeId: 'trip',
      path: 'trip'
    }, {
      type: 'belongsTo',
      nodeId: 'driver',
      path: 'driver'
    }, {
      type: 'belongsTo',
      nodeId: 'iq-vehicle',
      path: 'iqVehicle'
    }],
    isActive: true,
    leftTimeConstraint: 'trip.requestedTime',
    rightTimeConstraint: 'trip.requestedTime'
  };
  _exports.iqTripNode = iqTripNode;
  const iqStopNode = {
    id: 'iq-stop',
    modelName: 'iq-stop',
    links: [{
      type: 'belongsTo',
      nodeId: 'stop',
      path: 'stop'
    }],
    isActive: true,
    leftTimeConstraint: 'stop.trip.requestedTime',
    rightTimeConstraint: 'stop.trip.requestedTime'
  };
  _exports.iqStopNode = iqStopNode;
  const activeContextNodes = [// trip management graph
  tmCannedMessageNode, tmDriverNode, // sign-on
  ssoUserNode, // unification nodes
  iqTripNode, iqStopNode, iqDriverNode, iqVehicleNode, iqRiderNode, // trip service graph
  tripNode, stopNode, riderNode, callLineNode, subscriptionNode, accountNode, driverNode, vehicleNode, vehicleStateNode, zonesNode, standsNode];
  _exports.activeContextNodes = activeContextNodes;
  const activeContextGraph = activeContextNodes.reduce((obj, node) => {
    obj[node.id] = node;
    return obj;
  }, {});
  _exports.activeContextGraph = activeContextGraph;
  var _default = activeContextGraph;
  _exports.default = _default;
});