define("adept-iq/models/leg-travel-need", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  const LegTravelNeed = Model.extend({
    count: attr('number'),
    leg: belongsTo('leg'),
    travelNeedType: belongsTo('travel-need-type'),
    passengerType: belongsTo('passenger-type'),
    isClient: Ember.computed.equal('passengerType.name', 'client'),
    isPca: Ember.computed.equal('passengerType.name', 'pca'),
    isCompanion: Ember.computed.equal('passengerType.name', 'companion'),
    basicTravelNeedType: Ember.computed('travelNeedType', function () {
      const name = Ember.get(this, 'travelNeedType.name');
      return name === 'ambulatory' || name === 'wideAmbulatory' || name === 'wheelchair' || name === 'wideWheelchair';
    }),
    isEquipment: Ember.computed('travelNeedType', function () {
      return !this.get('travelNeedType.isServiceAnimal') && !this.get('passengerType.isClient');
    }),
    isExtraPassenger: Ember.computed('travelNeedType', 'passengerType', function () {
      return this.get('travelNeedType.isServiceAnimal') || this.get('passengerType.isPca') || this.get('passengerType.isCompanion');
    })
  });
  var _default = LegTravelNeed;
  _exports.default = _default;
});