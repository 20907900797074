define("adept-iq/pods/components/generic-widgets/map-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "tomtom", "adept-iq/config/environment"], function (_exports, _component, _tomtom, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['map-widget'],
    layoutName: 'components/generic-widgets/map-widget',
    classNameBindings: ['testMapWidget'],
    testMapWidget: false,
    lat: 0,
    lng: 0,
    zoom: 12,
    map: null,
    // tomtom will only accept an element ID, so create a unique one
    mapElementId: Ember.computed('elementId', function () {
      const elementId = this.get('elementId');
      return `${elementId}-map`;
    }),

    didInsertElement() {
      this._super(...arguments);

      const mapElementId = this.get('mapElementId');
      const lat = this.get('lat');
      const lng = this.get('lng');
      const zoom = this.get('zoom');
      Ember.run.scheduleOnce('afterRender', () => {
        const map = _tomtom.default.map(mapElementId, {
          zoom,
          center: [lat, lng],
          key: _environment.default.tomtom.key,
          source: _environment.default.tomtom.source,
          basePath: _environment.default.tomtom.basePath,
          styleUrlMapping: _environment.default.tomtom.styleUrlMapping
        });

        this.set('map', map);
        this.didInitializeMap(map);
      });
    },

    // overload in sub-class
    didInitializeMap()
    /* map */
    {},

    willDestroyElement() {
      this.get('map').remove();
      this.set('map', null);

      this._super(...arguments);
    },

    onWidgetResize() {
      this._super(...arguments);

      const map = this.get('map');
      if (!map) return;
      Ember.run.scheduleOnce('afterRender', () => {
        map.invalidateSize();
      });
    },

    onLatLngOrZoomChange: Ember.observer('lat', 'lng', 'zoom', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateMapView');
    }),

    updateMapView() {
      const map = this.get('map');
      const lat = this.get('lat');
      const lng = this.get('lng');
      const zoom = this.get('zoom');
      if (!map || !lat || !lng || !zoom) return;
      map.setView([lat, lng], zoom, {
        animate: false
      });
    }

  });

  _exports.default = _default;
});