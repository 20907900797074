define("adept-iq/pods/components/form-components/add-new-message/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    modelName: 'avlm-canned-message-template',
    sections: [{
      title: 'Message content',
      fields: [{
        id: 'title',
        type: 'enum',
        label: 'Title',
        valuePath: 'messageNr',
        cellDesc: 'title',
        editable: true,
        hidden: false,
        extra: {
          allowMultiple: false
        }
      }, {
        id: 'content',
        type: 'text',
        label: 'Content',
        valuePath: 'body',
        cellDesc: 'content',
        editable: false,
        hidden: false
      }]
    }]
  };
  _exports.default = _default;
});