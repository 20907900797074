define("adept-iq/services/edit-modal", ["exports", "adept-iq/utils/populate-new-trip", "adept-iq/utils/populate-account", "ember-concurrency", "lodash", "adept-iq/utils/save-records", "ember-inflector"], function (_exports, _populateNewTrip, _populateAccount, _emberConcurrency, _lodash, _saveRecords, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    first,
    last,
    isUndefined
  } = _lodash.default;
  const UNDO_DEBOUNCE_MS = 500;

  var _default = Ember.Service.extend({
    router: Ember.inject.service('router'),
    store: Ember.inject.service(),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    editableRecords: [],
    editComponent: null,
    saveRecordFunction: null,
    isLinkMode: false,
    isSearching: false,
    hasApplied: false,
    filter: '',
    undoHistory: null,
    isLastUndoStateCommitted: true,
    errors: null,
    showTripFormPopup: true,
    showActivityLog: false,
    editComponentAction: null,

    init() {
      this._super(...arguments);

      this.set('editableRecords', []);
      this.set('undoHistory', []);
      this.set('errors', []);
    },

    isOpen: Ember.computed.equal('router.currentRouteName', 'dashboard.modals.edit-form'),
    isDirty: Ember.computed('editableRecords.{@each.hasDirtyAttributes,@each.isForceDirty}', function () {
      const records = this.get('editableRecords');
      return records.any(record => {
        return record.get('hasDirtyAttributes') || record.get('isForceDirty');
      });
    }),
    canUndo: Ember.computed('undoHistory.lastObject.[]', function () {
      return Ember.isPresent(this.get('undoHistory.lastObject'));
    }),
    commitLastUndoState: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(UNDO_DEBOUNCE_MS);
      this.set('isLastUndoStateCommitted', true);
    }).restartable(),

    getLastUndoState() {
      let lastUndoState = this.get('undoHistory.lastObject');
      const isLastUndoStateCommitted = this.get('isLastUndoStateCommitted');

      if (isLastUndoStateCommitted) {
        lastUndoState = this.get('undoHistory').pushObject([]);
        this.set('isLastUndoStateCommitted', false);
      }

      return lastUndoState;
    },

    setLastUndoState(record, valuePath) {
      const lastUndoState = this.getLastUndoState();
      let recordChanges = lastUndoState.find(changes => changes.record === record);

      if (Ember.isBlank(recordChanges)) {
        recordChanges = lastUndoState.pushObject({
          record: record,
          properties: {}
        });
      }

      if (Ember.isBlank(Ember.get(recordChanges, `properties.${valuePath}`))) {
        Ember.set(recordChanges, `properties.${valuePath}`, Ember.get(record, valuePath));
      }

      this.get('commitLastUndoState').perform();
    },

    open(editComponent, models, saveRecordFunction) {
      this.set('editComponent', editComponent);
      this.set('editableRecords', models);
      this.set('saveRecordFunction', saveRecordFunction);
      const router = this.get('router');
      router.transitionTo('dashboard.modals.edit-form');
    },

    close() {
      this.reset();
      const router = this.get('router');
      router.transitionTo('dashboard');
    },

    setRecordValue(record, valuePath, value, options) {
      const {
        hasMany,
        belongsTo
      } = record.constructor.relationshipNames;
      const isHasMany = hasMany.includes(valuePath);
      const isBelongsTo = belongsTo.includes(first(valuePath.split('.')));

      if (isHasMany) {
        this.setLastUndoState(options.record, options.valuePath);
        options.record.set(options.valuePath, value); // Link Mode does not support hasMany records.

        return;
      }

      if (isBelongsTo) {
        /*eslint-disable */
        record = Ember.get(record, first(valuePath.split('.'))).content;
        valuePath = last(valuePath.split('.'));
        /*eslint-enable */
      }

      if (!Ember.isNone(options) && options.useOptionRecord) {
        this.setLastUndoState(options.record, options.valuePath);
        options.record.set(options.valuePath, value);
      } else {
        this.setLastUndoState(record, valuePath);
        record.set(valuePath, value);
      } // this.setLastUndoState(record, valuePath);
      // record.set(valuePath, value);


      if (this.get('isLinkMode')) {
        const records = this.get('editableRecords');
        /*eslint-disable */

        records.forEach(record => {
          if (isBelongsTo) {
            record = Ember.get(record, first(valuePath.split('.'))).content;
          }
          /*eslint-enable */
          // valuePath may be set to belongsTo model valuePath if isBelongsTo.


          this.setLastUndoState(record, valuePath);
          record.set(valuePath, value);
        });
      }
    },

    pushRecordValue(record, valuePath, modelNameDefault) {
      const inflector = new _emberInflector.default(_emberInflector.default.defaultRules);
      const modelName = !isUndefined(modelNameDefault) ? modelNameDefault : inflector.singularize(valuePath);
      const newRecord = this.get('store').createRecord(modelName);
      record.set('isForceDirty', true);
      Ember.get(record, valuePath).pushObject(newRecord);
      return newRecord;
    },

    pullRecordValue(record, valuePath, removeRecord) {
      record.set('isForceDirty', true);
      Ember.get(record, valuePath).removeObject(removeRecord);
    },

    undo() {
      const lastUndoState = this.get('undoHistory').popObject();
      lastUndoState.forEach(({
        record,
        properties
      }) => {
        record.setProperties(properties);
      });
      this.set('isLastUndoStateCommitted', true);
    },

    undoAll() {
      const undoHistory = this.get('undoHistory');

      while (Ember.isPresent(undoHistory)) this.undo();
    },

    toggleLinkMode() {
      this.toggleProperty('isLinkMode');
    },

    toggleSearchMode() {
      this.toggleProperty('isSearching');

      if (this.get('isSearching') === false) {
        this.set('filter', '');
      }
    },

    apply() {
      // TODO: Integrate against impact API.
      this.setProperties({
        hasApplied: true,
        undoHistory: [],
        isLastUndoStateCommitted: true
      });
    },

    commit(editComponentAction) {
      const records = this.get('editableRecords');
      const saveRecordFunction = this.get('saveRecordFunction');
      this.set('editComponentAction', editComponentAction);
      this.set('errors', []);

      if (!isUndefined(saveRecordFunction) && saveRecordFunction !== null) {
        saveRecordFunction(records, editComponentAction).catch(e => {
          this.get('errors').pushObject(e.message);
        });
      } else {
        (0, _saveRecords.default)(records).catch(e => {
          this.get('errors').pushObject(e.errors.firstObject.detail);
        });
      }

      this.reset();
    },

    commitOnly() {
      const records = this.get('editableRecords');
      const saveRecordFunction = this.get('saveRecordFunction');
      this.set('errors', []);

      if (!isUndefined(saveRecordFunction) && saveRecordFunction !== null) {
        saveRecordFunction(records).catch(e => {
          this.get('errors').pushObject(e.message);
        });
      } else {
        (0, _saveRecords.default)(records).catch(e => {
          this.get('errors').pushObject(e.errors.firstObject.detail);
        });
      }
    },

    cancel() {
      this.set('hasApplied', false);
    },

    reset() {
      this.undoAll();
      this.setProperties({
        isLinkMode: false,
        isSearching: false,
        hasApplied: false,
        filter: '',
        errors: []
      });
    }

  });

  _exports.default = _default;
});