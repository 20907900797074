define("adept-iq/pods/components/form-components/searchable-enum-model/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8dMuubgM",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"power-select-with-create\",null,[[\"options\",\"searchEnabled\",\"search\",\"onfocus\",\"selected\",\"id\",\"triggerId\",\"onkeydown\",\"onchange\",\"oncreate\"],[[23,[\"options\"]],[23,[\"optionSearchEnabled\"]],[27,\"perform\",[[23,[\"search\"]]],null],[27,\"action\",[[22,0,[]],\"onFocus\"],null],[23,[\"selected\"]],[27,\"readonly\",[[23,[\"triggerId\"]]],null],[27,\"readonly\",[[23,[\"triggerId\"]]],null],[27,\"action\",[[22,0,[]],\"handleKeydown\"],null],[27,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],\"onSelected\"],null]],null],[27,\"action\",[[22,0,[]],\"createNew\"],null]]],{\"statements\":[[0,\"  \"],[1,[27,\"get\",[[22,1,[]],[23,[\"optionSearchPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/searchable-enum-model/template.hbs"
    }
  });

  _exports.default = _default;
});