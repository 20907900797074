define("adept-iq/pods/components/iq-widgets/driver-breaks-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        type: 'text',
        label: 'ID',
        valuePath: 'id',
        editable: false
      }]
    }, {
      title: 'Editable',
      fields: [{
        id: 'type',
        type: 'searchable-enum',
        label: 'Type',
        valuePath: 'breakType',
        editable: true,
        extra: {
          optionModelName: 'break-type',
          optionSearchPath: 'name',
          optionSelectedPath: 'firstObject',
          optionIncludes: '',
          optionSearchEnabled: false
        }
      }, {
        id: 'place',
        type: 'locations',
        label: '',
        valuePath: 'places',
        modelName: 'place',
        isMultiType: true,
        extra: {
          aliasPath: 'alias',
          notesPath: 'notes',
          streetNrPath: 'streetNr',
          streetNamePath: 'streetAddress',
          localityPath: 'locality',
          regionPath: 'region',
          subRegionPath: 'subRegion',
          postalCodePath: 'postalCode',
          countryPath: 'country',
          defaultOptionsPath: 'route.garages',
          useRecordWithId: true,
          useOptionRecord: true
        }
      }, {
        id: 'promisedStart',
        type: 'datetimeflatpickr',
        label: 'Break Start',
        valuePath: 'promisedStart'
      }, {
        id: 'promisedEnd',
        type: 'datetimeflatpickr',
        label: 'Break End',
        valuePath: 'promisedEnd'
      }]
    }]
  };
  _exports.default = _default;
});