define("adept-iq/pods/components/generic-widgets/form-widget/component", ["exports", "lodash", "adept-iq/config/key-maps", "ember-keyboard-shortcuts"], function (_exports, _lodash, _keyMaps, _emberKeyboardShortcuts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-widget'],
    layoutName: 'components/generic-widgets/form-widget',
    editModal: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    editableRecords: Ember.computed.alias('editModal.editableRecords'),
    keyboardShortcutsList: null,
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    keyboardShortcuts: {
      'shift+esc': {
        action: 'onApplyClick',
        // action to trigger
        global: true,
        scoped: true,
        preventDefault: true
      },
      esc: {
        action: 'onApplyClearClick',
        // action to trigger
        global: true,
        scoped: true,
        preventDefault: true
      },
      'Shift+!': {
        action: 'onClearClick',
        // action to trigger
        global: true,
        scoped: true,
        preventDefault: true
      },
      'Shift+@': {
        action: 'onCopyClick',
        // action to trigger
        global: true,
        scoped: true,
        preventDefault: true
      },
      'Shift+-': {
        action: 'onCancelClick',
        // action to trigger
        global: true,
        scoped: true,
        preventDefault: true
      },
      'Shift+#': {
        action: 'onCloseClick',
        // action to trigger
        global: true,
        scoped: true,
        preventDefault: true
      },
      'Shift+&': {
        action: "onSearchClick",
        global: true,
        scoped: true,
        preventDefault: true
      },
      'Shift++': {
        action: "onNewClick",
        global: true,
        scoped: true,
        preventDefault: true
      },
      'Shift+*': {
        action: "onActivitLogs",
        global: true,
        scoped: true,
        preventDefault: true
      }
    },
    editableSections: null,
    service: null,
    formType: Ember.computed('title', function () {
      if (this.get('title') !== '') {
        return this.get('title').split(' ')[1];
      }

      return '';
    }),
    title: Ember.computed('editModal.editComponent', function () {
      const editComponent = this.get('editModal.editComponent');

      try {
        switch (editComponent.split(/[-/]+/)[2]) {
          case 'new':
            return 'Add Trip';

          case 'edit':
            return 'Edit Trip';

          case 'cancel':
            return 'Cancel Trip';

          case 'search':
            return 'Search Trip';

          case 'assign':
            return 'Assign Trip to Route';

          case 'passengers':
            if (this.get('editableRecords')[0].id) {
              return 'Edit Passenger';
            }

            return 'Add Passenger';

          case 'vehicles':
            if (this.get('editableRecords')[0].id) {
              return 'Edit Vehicle';
            }

            return 'Add Vehicle';

          case 'drivers':
            if (this.get('editableRecords')[0].id) {
              return 'Edit Driver';
            }

            return 'Add Driver';

          case 'routes':
            return 'Add Route';

          default:
            return '';
        }
      } catch (e) {
        return 'Add Trip';
      }
    }),
    readOnlyFields: Ember.computed('editableRecords.[]', function () {
      const editableSections = this.get('editableSections');
      const readOnlyFields = editableSections.find(x => x.title === 'Read Only');
      let readOnlyItems = {};

      if (!Ember.isNone(readOnlyFields)) {
        for (let i = 0; i < readOnlyFields.fields.length; i++) {
          const o = readOnlyFields.fields[i];
          readOnlyItems[o.id] = o;
        }
      }

      return readOnlyItems;
    }),

    init() {
      this._super(...arguments); //TODO fof FIX Trip Widget


      if (Ember.isBlank(this.get('editableRecords'))) {
        this.set('editableRecords', []);
        this.set('editModal.editModal', false);
      }

      (true && !(Ember.isArray(this.get('editableSections'))) && Ember.assert('{{generic-widgets/form-widget}} requires extending components to provide an `editableSections` array', Ember.isArray(this.get('editableSections'))));
      (true && !(Ember.isPresent(this.get('service'))) && Ember.assert('{{generic-widgets/form-widget}} requires extending components to provide a `service` service', Ember.isPresent(this.get('service'))));
      (true && !(Ember.isArray(this.get('editableRecords'))) && Ember.assert('{{generic-widgets/form-widget}} requires an `editableRecords` array', Ember.isArray(this.get('editableRecords'))));
    },

    didUpdateAttrs() {},

    didRender() {
      this._super(...arguments);

      this.highlightaccessKeys();
    },

    didInsertElement() {
      this._super(...arguments);

      let me = this; // auto-focus on first input control

      const el = this.$('input,.ember-power-select-trigger'); //console.log('el ',el);

      if (el && el[0]) {
        el[0].focus();
      } //console.log('KeyMapConfig ',KeyMapConfigs,' formType ',this.get('formType'));


      let keyMapConfig = _keyMaps.default['Default'];
      const editComponent = this.get('editModal.editComponent');

      try {
        if (Ember.isPresent(_keyMaps.default[editComponent.split(/[-/]+/)[2]])) {
          keyMapConfig = _keyMaps.default[editComponent.split(/[-/]+/)[2]];
        } else if (Ember.isPresent(_keyMaps.default[this.get('formType')])) {
          keyMapConfig = _keyMaps.default[this.get('formType')];
        }
      } catch (e) {
        keyMapConfig = _keyMaps.default['Trip'];
      } //console.log('keyMapConfig ',keyMapConfig);


      let keyboardShortcuts = [];
      let keyboardShortcutsList = [];

      _lodash.default.forEach(keyMapConfig, function (keyMap) {
        //console.log(keyMap);
        if (!Ember.isBlank(keyMap.accessKey)) {
          let accesskey = keyMap.accessKey;
          keyboardShortcutsList.push(keyMap);
          /*let accessKeyConfig = {
            [accesskey] : {
              action: keyMap.action, // action to trigger
              global: true,
              preventDefault: true
            }
          };*/

          /*if(isBlank(me.get('keyboardShortcuts'))) {
            me.set('keyboardShortcuts',[]);
          }*/

          keyboardShortcuts.push({
            [accesskey]: {
              action: keyMap.action,
              // action to trigger
              global: true,
              preventDefault: true
            }
          });
        }
      }); //console.log('this.get(keyboardShortcuts) ', keyboardShortcuts);


      this.set('keyboardShortcutsList', keyboardShortcutsList); //console.log('keyboardShortcutsList ',this.get('keyboardShortcutsList'));

      let keyboardShortcutsStr = JSON.stringify(keyboardShortcuts, null, '\t');
      keyboardShortcutsStr = keyboardShortcutsStr.substr(1, keyboardShortcutsStr.length - 2);
      keyboardShortcutsStr = keyboardShortcutsStr.replace('"', ''); //console.log('keyboardShortcutsStr ',keyboardShortcutsStr);
      //this.set('keyboardShortcuts',keyboardShortcutsStr);
      // install keyboard listener

      this._onKeyDown = event => {
        Ember.run.schedule('actions', this, 'onKeyDown', event);
      };

      document.addEventListener('keydown', this._onKeyDown);
      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },

    willDestroyElement() {
      try {
        document.removeEventListener('keydown', this._onKeyDown);
        (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
      } catch (e) {}
    },

    filteredSections: Ember.computed('editableSections', 'service.filter', function () {
      const sections = Ember.copy(this.get('editableSections'), true);
      const filter = this.get('service.filter');
      return sections.map(section => {
        /*eslint-disable */
        Ember.set(section, 'fields', section.fields.filter(field => {
          return new RegExp(`^${filter}`, 'i').test(field.label);
        }));
        /*eslint-enable */

        return section;
      }).filter(section => section.fields.length > 0);
    }),

    onKeyDown(event) {
      // can check to see if focused element is in this component:
      // let el = this.$()[0];
      // if (!el.contains(document.activeElement)) return;
      if (event.key === 'Enter') {
        return this.onEnter();
      }

      if (event.key === 'z' && (event.metaKey || event.ctrlKey)) {
        this.get('service').undo();
      }

      if (event.key === 'F7') {
        this.onClearClick();
      }
    },

    highlightaccessKeys() {
      const sc = document.querySelectorAll('.cell-label,.cell-header'); //console.log('sc',sc);

      for (let i = 0; i < sc.length; i++) {
        let txt = sc[i].innerHTML;
        let accessKey = sc[i].accessKey;

        if (accessKey && txt) {
          const pos = txt.toLowerCase().indexOf(accessKey.toLowerCase());

          if (pos != -1) {
            sc[i].innerHTML = txt.substring(0, pos) + '<u>' + txt.charAt(pos) + '</u>' + txt.substring(pos + 1);
          }
        }
      }
    },

    onEnter() {
      /*//console.log('document',document.activeElement);
      if (document.activeElement.contains('ember-power-select-trigger') ||
          document.activeElement.contains('ember-power-select-search-input')) {
        return;
      }
      if (!document.activeElement) {
        this.get('service').apply();
        return;
      }
       // buttons handle enter button on their own
      if (document.activeElement.tagName === 'BUTTON') {
        return;
      }
       const { classList } = document.activeElement;
       // power selects handle enter button on their own
      if (classList.contains('ember-power-select-trigger')) {
        return;
      }
       // enter toggles an on/off switch
      if (classList.contains('onoffswitch-switch')) {
        document.activeElement.parentElement.click();
        return;
      }
       this.get('service').apply();*/
    },

    actions: {
      onCellValueChange(record, valuePath, value, options) {
        //console.log('valuePath',valuePath)
        if (valuePath === 'account' || valuePath === 'subscriptions') {
          record.set('isForceDirty', true);
          record.set(valuePath, value);
        } else {
          this.get('service').setRecordValue(record, valuePath, value, options);
        }
      },

      onCellValueAppend(record, valuePath, modelName) {
        return this.get('service').pushRecordValue(record, valuePath, modelName);
      },

      onCellValueRemove(record, valuePath, modelName, options) {
        this.get('service').pullRecordValue(record, valuePath, options.record);
      },

      onCloseClick() {
        this.get('service').close();
      },

      onUndoClick() {
        this.get('service').undo();
      },

      onLinkToggle() {
        this.get('service').toggleLinkMode();
      },

      onSearchToggle() {
        this.get('service').toggleSearchMode();
      },

      onApplyClick() {
        this.get('service').apply();
      },

      onApplyClearClick() {//this.get('service').apply();
      },

      onClearClick() {
        this.get('service').apply();
      },

      onSearchClick() {
        console.log('onSearchClick');
      },

      onNewClick() {
        console.log('onNewClick');
      },

      onCopyClick() {
        this.get('service').apply();
      },

      onItemButtonClick()
      /*item*/
      {
        /*eslint-disable */
        alert('Clicked button');
        /*eslint-enable */
      }

    }
  });

  _exports.default = _default;
});