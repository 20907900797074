define("adept-iq/pods/components/form-components/savecli/component", ["exports", "adept-iq/utils/check-value"], function (_exports, _checkValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-savecli'],
    editmodalcontext: Ember.inject.service(),
    widgetModelData: null,
    riderprofile: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    editModal: Ember.inject.service(),
    isAccountPickups: false,
    isAccountFixedPrice: false,

    init() {
      this._super(...arguments);
    },

    prepareRiderData() {},

    validateRecords(tripData) {
      let valid = true;
      this.set('editModal.errors', []);

      if (Ember.isBlank(tripData.passengers[0].phone1.number)) {
        valid = false;
        this.get('editModal.errors').pushObject('Phone Number should be there');
      }

      if (Ember.isBlank(tripData.passengers[0].firstName)) {
        valid = false;
        this.get('editModal.errors').pushObject('Client Name should be there');
      }

      let latlng = "";

      if (tripData.stopPoints[0].address.coord.lat !== undefined && tripData.stopPoints[0].address.coord.lng !== undefined && tripData.stopPoints[0].address.coord.lat !== 0 && tripData.stopPoints[0].address.coord.lng !== 0 && tripData.stopPoints[0].address.coord.lat !== 181 && tripData.stopPoints[0].address.coord.lng !== 181 && tripData.stopPoints[0].address.coord.lat !== -181 && tripData.stopPoints[0].address.coord.lng !== -181) {
        latlng = `${tripData.stopPoints[0].address.coord.lat}/${tripData.stopPoints[0].address.coord.lng}`;
      }

      if (Ember.isEmpty(latlng)) {
        valid = false;
        this.get('editModal.errors').pushObject('Valid Pickup address should be there');
      }

      if (Ember.isBlank(tripData.acctCardNr)) {
        valid = false;
        this.get('editModal.errors').pushObject('Account should be there');
      }

      return valid;
    },

    saveRiderRecordFunction(records) {
      this.set('editModal.errors', []);
      return new Promise((resolve, reject) => {
        records.forEach(record => {
          record.save().then(() => {
            record.set('isForceDirty', false);
            this.get('errorMessage').pushInfo({
              detail: `CLI successfully saved`
            });
          }).catch(function (error) {
            reject(error);
          });
        });
      });
    },

    actions: {
      saveCliInfo() {
        let tripData = this.get('record');

        if (this.validateRecords(tripData)) {
          let riderProfileJSON = this.get('riderprofile').riderProfileData();
          let riderData = riderProfileJSON.data[0].attributes;
          let rider = null;
          let requiredAttributes = [];

          if (!Ember.isEmpty(this.get('record').attributes)) {
            this.get('record').attributes.forEach(function (item) {
              requiredAttributes.push({
                "type": item.type,
                "cnt": 0
              });
            });
          }

          if (Ember.isEmpty(this.get('record').get('editmodelcontext.rider'))) {
            rider = this.get('store').createRecord('tm-rider-profile');
            rider.set('name', tripData.passengers[0].firstName);
            rider.set('contactFirstName', tripData.passengers[0].firstName);
            rider.set('phone1', tripData.passengers[0].phone1);
            rider.set('requiredAttributes', requiredAttributes); //////console.log(('tripData ',tripData);

            rider.set('account', riderData.account);
            rider.set('account.accountId', tripData.acctCardNr); //console.log('rider',rider );
            //console.log('rider.favoritePickups',rider.favoritePickups );

            if (!Ember.isEmpty(riderData.favoriteTrips)) {
              rider.set('favoriteTrips', []);
              let newFavoriteTrips = riderData.favoriteTrips[0]; //newFavoritePickup.address = tripData.stopPoints[0].address;

              newFavoriteTrips.pickup.address.formattedAddress = tripData.stopPoints[0].address.tomtomFormattedAddress.address.freeformAddress;
              newFavoriteTrips.pickup.address.unitNr = (0, _checkValue.default)(tripData.stopPoints[0].address.unitNr);
              newFavoriteTrips.pickup.address.streetName = (0, _checkValue.default)(tripData.stopPoints[0].address.streetName);
              newFavoriteTrips.pickup.address.streetNr = (0, _checkValue.default)(tripData.stopPoints[0].address.streetNr);
              newFavoriteTrips.pickup.address.postCode = (0, _checkValue.default)(tripData.stopPoints[0].address.postalCode);
              newFavoriteTrips.pickup.address.region = (0, _checkValue.default)(tripData.stopPoints[0].address.region);
              newFavoriteTrips.pickup.address.state = (0, _checkValue.default)(tripData.stopPoints[0].address.state);
              newFavoriteTrips.pickup.address.description = (0, _checkValue.default)(tripData.stopPoints[0].address.description);
              newFavoriteTrips.pickup.address.coord = tripData.stopPoints[0].address.coord;
              newFavoriteTrips.dropoff.address.formattedAddress = tripData.stopPoints[1].address.tomtomFormattedAddress.address.freeformAddress;
              newFavoriteTrips.dropoff.address.unitNr = (0, _checkValue.default)(tripData.stopPoints[1].address.unitNr);
              newFavoriteTrips.dropoff.address.streetName = (0, _checkValue.default)(tripData.stopPoints[1].address.streetName);
              newFavoriteTrips.dropoff.address.streetNr = (0, _checkValue.default)(tripData.stopPoints[1].address.streetNr);
              newFavoriteTrips.dropoff.address.postCode = (0, _checkValue.default)(tripData.stopPoints[1].address.postalCode);
              newFavoriteTrips.dropoff.address.region = (0, _checkValue.default)(tripData.stopPoints[1].address.region);
              newFavoriteTrips.dropoff.address.state = (0, _checkValue.default)(tripData.stopPoints[1].address.state);
              newFavoriteTrips.dropoff.address.description = (0, _checkValue.default)(tripData.stopPoints[1].address.description);
              newFavoriteTrips.dropoff.address.coord = tripData.stopPoints[1].address.coord; //console.log('newFavoritePickup',newFavoritePickup);

              rider.get('favoriteTrips').push(newFavoriteTrips);
            } else {
              let favoritePickup = riderData.favoriteTrips[0];
              favoritePickup.index = rider.favoriteTrips.length + 1;
              favoritePickup.address = tripData.stopPoints[0].address;
              rider.favoritePickups.push(favoritePickup);
            } //console.log('rider ',rider);

          } else {
            rider = this.get('record').get('editmodelcontext.rider');
          }

          this.saveRiderRecordFunction([rider]);
        }
      }

    }
  });

  _exports.default = _default;
});