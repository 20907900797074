define("adept-iq/helpers/titlecase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.titlecase = titlecase;
  _exports.default = void 0;

  function titlecase(str) {
    const s = str[0].replace(/\w\S*/g, str[0].charAt(0).toUpperCase() + str[0].substr(1).toLowerCase());
    return s;
  }

  var _default = Ember.Helper.helper(titlecase);

  _exports.default = _default;
});