define("adept-iq/pods/components/form-components/search-locations/component", ["exports", "tomtom", "adept-iq/config/environment", "ember-concurrency", "adept-iq/utils/zone-validation", "adept-iq/utils/check-address-overwrite", "adept-iq/utils/check-address-flags", "lodash", "adept-iq/utils/check-value", "ember-keyboard-shortcuts"], function (_exports, _tomtom, _environment, _emberConcurrency, _zoneValidation, _checkAddressOverwrite, _checkAddressFlags, _lodash, _checkValue, _emberKeyboardShortcuts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-locations'],
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    value: null,
    disabled: false,
    addressOptions: null,
    store: Ember.inject.service(),
    keyboardShortcuts: null,
    editmodalcontext: Ember.inject.service(),
    // addresses: computed.readOnly('value'),
    addressTypePath: Ember.computed.readOnly('extra.addressTypePath'),
    aliasPath: Ember.computed.readOnly('extra.aliasPath'),
    descriptionPath: Ember.computed.readOnly('extra.descriptionPath'),
    unitPath: Ember.computed.readOnly('extra.unitPath'),
    notesPath: Ember.computed.readOnly('extra.notesPath'),
    streetNrPath: Ember.computed.readOnly('extra.streetNrPath'),
    streetNamePath: Ember.computed.readOnly('extra.streetNamePath'),
    localityPath: Ember.computed.readOnly('extra.localityPath'),
    regionPath: Ember.computed.readOnly('extra.regionPath'),
    subRegionPath: Ember.computed.readOnly('extra.subRegionPath'),
    postalCodePath: Ember.computed.readOnly('extra.postalCodePath'),
    countryPath: Ember.computed.readOnly('extra.countryPath'),
    defaultOptionsPath: Ember.computed.readOnly('extra.defaultOptionsPath'),
    useOptionRecord: Ember.computed.readOnly('extra.useOptionRecord'),
    useRecordWithId: Ember.computed.readOnly('extra.useRecordWithId'),
    pickup: Ember.computed.readOnly('extra.pickup'),
    dropoff: Ember.computed.readOnly('extra.dropoff'),
    swipeAddressAccessKey: Ember.computed.readOnly('extra.swipeAddressAccessKey'),
    locationsPath: 'address.latlng',
    geoNodePath: 'address.geoNode',
    altPickupAccesskey: null,
    altDropoffAccesskey: null,
    altSwipeAddressKey: null,
    //locationsPath: 'latlng',
    //geoNodePath: 'geoNode',
    addressRecord: null,
    selected: null,

    geocodeAddressChange() {
      console.log('geocodeAddressChange');
      const record = this.get('addressRecord');
      console.log('geocodeAddressChange record ', record);
      const tomtomAddress = this.get('geocode.tomtomAddress');
      console.log('geocodeAddressChange tomtomAddress record', record);

      if (!Ember.isEmpty(tomtomAddress)) {
        this.convertTTAddressToModel(record, tomtomAddress, true);
        this.disableAddressSearchOnMap(record, record.passengerEvents[0].type);
        this.removeObserver('geocode.tomtomAddress', this.geocodeAddressChange);
      }
    },

    validateAddresses(addressRecord) {
      this.set('editModal.errors', []);

      if (addressRecord.address.coord.lat === 0 || addressRecord.address.coord.lng === 0) {
        this.get('editModal.errors').pushObject('Address does not have lat/lng. Please select position on the map.');
      } else {
        if (Ember.isBlank(addressRecord.address.streetNr)) {
          this.get('editModal.errors').pushObject(addressRecord.passengerEvents[0].type + ' does not have street number');
        }

        (0, _checkAddressFlags.default)(this.get('record'));
      }
    },

    init() {
      this._super(...arguments);

      let addresses = this.get('value');
      this.set('addresses', addresses);
      this.set('addressOptions', []);
      const defaultOptionsPath = this.get('defaultOptionsPath');

      if (this.get('record') !== null && this.get('record') !== undefined && !Ember.isNone(defaultOptionsPath) && this.get('record').get(defaultOptionsPath) !== null && this.get('record').get(defaultOptionsPath) !== undefined) {
        // this.set('aliasOptions',this.get('record').get(defaultOptionsPath));
        // this.set('addressOptions', this.get('record').get(defaultOptionsPath));
        this.get('record').get(defaultOptionsPath).forEach(address => {
          this.get('addressOptions').push(address);
        });
        addresses.forEach(address => {
          this.get('addressOptions').push(address);
        });
      }

      addresses.forEach(address => {
        this.get('geocode').addressMapPopulate(address);
        /*let altaccesskey = null;
          //console.log('address.address.addressType ',address.address.addressType);
           if(address.address.addressType === 'pickup') {
            altaccesskey = 'alt+p';
          } else {
            altaccesskey = 'alt+e';
          }
           if(altaccesskey) {
            let accessKeyConfig = {
              [altaccesskey] : {
                action: 'handleFocus', // action to trigger
                global: true,
                preventDefault: true
              }
            };
            if(isBlank(this.get('keyboardShortcuts'))) {
              this.set('keyboardShortcuts',[]);
            }
            this.get('keyboardShortcuts').push(accessKeyConfig);
          }*/
      });
      let altPickupAccesskey = 'alt+' + this.get('pickup.accessKey');
      let altDropoffAccesskey = 'alt+' + this.get('dropoff.accessKey');
      let altSwipeAddressKey = 'alt+' + this.get('swipeAddressAccessKey');
      this.set('altPickupAccesskey', altPickupAccesskey);
      this.set('altDropoffAccesskey', altDropoffAccesskey);
      this.set('altSwipeAddressKey', altSwipeAddressKey);
      let accessKeyConfig = {
        [altSwipeAddressKey]: {
          action: 'onSwapAddress',
          // action to trigger
          global: true,
          preventDefault: true
        },
        [altPickupAccesskey]: {
          action: 'handlePickupFocus',
          // action to trigger
          global: true,
          preventDefault: true
        },
        [altDropoffAccesskey]: {
          action: 'handleDropoffFocus',
          // action to trigger
          global: true,
          preventDefault: true
        }
      };
      this.set('keyboardShortcuts', accessKeyConfig);
    },

    didInsertElement() {
      //console.log('keyboardShortcuts', this.get('keyboardShortcuts'));
      this._super(...arguments);

      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    },

    addressSearchResults(results) {
      const emberResults = [];
      let defaultOptions = this.get('record').get(this.get('defaultOptionsPath'));
      results.forEach(result => {
        if (result.type === 'POI' || result.type === 'Point Address') {
          emberResults.push(Ember.Object.create(result));
        }
      });

      if (defaultOptions) {
        defaultOptions.forEach(option => {
          emberResults.push(option);
        });
      }

      this.set('addressOptions', emberResults);
    },

    searchAddress: (0, _emberConcurrency.task)(function* (term) {
      yield _tomtom.default.fuzzySearch().key(_environment.default.tomtom.search.searchKey).query(term).countrySet(_environment.default.tomtom.search.countrySet).typeahead(true).center(_environment.default.tomtom.search.center).radius(_environment.default.tomtom.search.radius).callback(this.addressSearchResults.bind(this)).go();
    }),
    convertTTAddressToModel: function (record, ttAddress, checkOverwrite) {
      this.set('record.isForceDirty', true);
      let overwriteAddress = true;
      let overwriteAddressPop = (0, _checkAddressOverwrite.default)(record.address, ttAddress.address);

      if (overwriteAddressPop && checkOverwrite) {
        overwriteAddress = confirm('Do you want to overwrite Address');
      }

      if (overwriteAddress) {
        this.set('record.isForceDirty', true);
        console.log('convertTTAddressToModel ', record);
        console.log('convertTTAddressToModel ttAddress', ttAddress);

        try {
          record.address.streetNr = ttAddress.address.streetNumber;
          record.address.streetName = ttAddress.address.streetName || ttAddress.address.municipality;
          record.address.region = ttAddress.address.municipalitySubdivision;
          record.address.state = ttAddress.address.countrySubdivisionName;
          record.address.postCode = ttAddress.address.postalCode;
          record.address.description = ttAddress.poi.name; //record.address.country = ttAddress.address.country;
        } catch (e) {}

        try {
          Ember.set(record.address.tomtomFormattedAddress.address, 'freeformAddress', (0, _checkValue.default)(record.address.streetNr) + " " + (0, _checkValue.default)(record.address.streetName) + " " + (0, _checkValue.default)(record.address.region));
        } catch (e) {
          console.log('e', e);

          try {
            record.address.tomtomFormattedAddress = {
              address: {
                freeformAddress: (0, _checkValue.default)(record.address.streetNr) + " " + (0, _checkValue.default)(record.address.streetName) + " " + (0, _checkValue.default)(record.address.region)
              }
            };
          } catch (e) {
            console.log('e1 ', e);
          }
        }

        try {
          Ember.set(record.address, 'formattedAddress', (0, _checkValue.default)(ttAddress.freeformAddress));
        } catch (e) {
          record.address.formattedAddress = (0, _checkValue.default)(ttAddress.freeformAddress);
        }
      }
    },
    clearAddressModel: function (record) {
      Ember.set(record.address, 'unitNr', '');
      record.address.streetNr = '';
      record.address.streetName = '';
      record.address.region = '';
      record.address.state = '';
      record.address.postCode = '';
      Ember.set(record.address, 'description', '');

      try {
        Ember.set(record.address.tomtomFormattedAddress.address, 'freeformAddress', '');
      } catch (e) {
        console.log('e', e);

        try {
          record.address.tomtomFormattedAddress = {
            address: {
              freeformAddress: ''
            }
          };
        } catch (e) {
          console.log('e1 ', e);
        }
      } //record.address.country = '';


      record.address.coord.lat = 0;
      record.address.coord.lng = 0;
    },
    populateAddressModel: function (record, searchText) {
      Ember.set(record.address, 'unitNr', '');
      record.address.streetNr = '';
      record.address.streetName = searchText;
      record.address.region = '';
      record.address.state = '';
      record.address.postCode = '';

      try {
        Ember.set(record.address.tomtomFormattedAddress.address, 'freeformAddress', searchText);
      } catch (e) {
        console.log('e', e);

        try {
          record.address.tomtomFormattedAddress = {
            address: {
              freeformAddress: searchText
            }
          };
        } catch (e) {
          console.log('e1 ', e);
        }
      }

      Ember.set(record.address, 'description', ''); //record.address.country = '';

      record.address.coord.lat = 0;
      record.address.coord.lng = 0;
    },

    enableAddressSearchOnMap(record, type) {
      Ember.$('html,body,.tomtom-map').addClass('custom-cursor');
      this.get('geocode').set('disabledFindLocationForRecord', true);
      this.get('geocode').activateGeocode(true, record, type, false);
      this.set('addressRecord', record);
      this.addObserver('geocode.tomtomAddress', this.geocodeAddressChange);
    },

    disableAddressSearchOnMap(record, type) {
      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
      this.get('geocode').activateGeocode(false, record, type);
      this.set('addressRecord', null);
      this.removeObserver('geocode.tomtomAddress', this.geocodeAddressChange);
      this.get('geocode').set('disabledFindLocationForRecord', false);
    },

    enableAddressPOIOnMap(record, type) {
      Ember.$('html,body,.tomtom-map').addClass('custom-cursor');
      console.log('record.address.description', record.address.description);
      this.get('geocode').set('activePOIDescription', record.address.description); //this.get('geocode').set('disabledFindLocationForRecord',true);

      this.get('geocode').activatePOIGeocode(true, record, type, false);
      this.set('addressRecord', record);
      this.addObserver('geocode.tomtomAddress', this.geocodeAddressChange);
    },

    disableAddressPOIOnMap(record, type) {
      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
      this.get('geocode').set('activePOIDescription', '');
      this.get('geocode').activatePOIGeocode(false, record, type);
      this.set('addressRecord', null);
      /*this.removeObserver('geocode.tomtomAddress', this.geocodeAddressChange);
      this.get('geocode').set('disabledFindLocationForRecord', false);*/
    },

    actions: {
      onInput(record, valuePath, value) {
        //////console.log(('record',record , ' valuePath',valuePath);
        Ember.set(record, valuePath, value);
        this.set('record.isForceDirty', true);
      },

      onFocus(select, e) {
        Ember.$('.ember-power-select-search-input').focus();
        e.stopPropagation();
      },

      onClose(record, valuePath, dropdown, e) {
        if (valuePath) {
          this.input = document.querySelector('.ember-power-select-trigger[id=' + valuePath + ']');

          if (this.input) {
            Ember.run.next(this.input, 'focus');
          }
        }
      },

      onAddressSelected(record, valuePath, value) {
        if (this.get('useRecordWithId') && !Ember.isNone(value.get('id'))) {
          this.set('record.place', value);
          this.set('addresses', [value]);
        } else {
          if (!(0, _zoneValidation.insideZone)([value.get('position.lat'), value.get('position.lon')])) {
            this.get('errorMessage').pushError({
              detail: 'Invalid Zone'
            });
            return;
          }

          this.convertTTAddressToModel(record, value, false);
        }
      },

      onAliasAddressSelected(record, valuePath, value) {
        this.convertTTAliasAddressToModel(record, value);
      },

      onPickOnMap(record) {
        const activeGeocode = this.get('geocode.activeGeocode');

        if (activeGeocode) {
          this.disableAddressSearchOnMap(record, record.passengerEvents[0].type);
        } else {
          this.enableAddressSearchOnMap(record, record.passengerEvents[0].type);
        }
      },

      onPOIOnMap(record) {
        const activePOIGeocode = this.get('geocode.activePOIGeocode');

        if (activePOIGeocode) {
          this.disableAddressPOIOnMap(record, 'poi');
        } else {
          this.enableAddressPOIOnMap(record, 'poi');
        }
      },

      onAddNewAddress(record, valuePath, modelName) {
        const newAddress = this.get('editModal').pushRecordValue(record, valuePath, modelName);
        this.get('editModal').pushRecordValue(newAddress, 'locations', 'rms-location');
      },

      onRemoveAddress(record, valuePath, value) {
        let options = {
          record,
          valuePath
        };
        this.get('onRemove')(value, options);
      },

      handleKeydown(record, valuePath, dropdown, e) {
        const key = e.keyCode;

        if (key == 34 || key == 27 || key == 92 || key == 91 || key == 93 || key == 94 || key == 62 || key == 60 || key == 123 || key == 124 || key == 125 || key == 16 || key == 39) {
          return;
        }

        var inp = String.fromCharCode(e.keyCode);

        if (/[a-zA-Z0-9-_ ]/.test(inp)) {
          console.log(' dropdown.searchText ', dropdown.searchText);
          this.populateAddressModel(record, dropdown.searchText); // address has been forced without geocodes
          // this.set('record.isForceDirty', true);
          //    this.clearAddressModel(record);

          this.set('record.isForceDirty', true);
          this.set('addressRecord', record);
          (0, _checkAddressFlags.default)(this.get('record'));
        }

        if (e.keyCode === 13) {
          this.clearAddressModel(record);
          this.set('record.isForceDirty', true);
          this.set('addressRecord', record);
          (0, _checkAddressFlags.default)(this.get('record'));
        }
      },

      onSwapAddress() {
        let record = this.get('record');

        let pickAddress = _lodash.default.cloneDeep(record.stopPoints[0]);

        let dropAddress = _lodash.default.cloneDeep(record.stopPoints[1]);

        this.set('record.stopPoints.1.address', _lodash.default.cloneDeep(pickAddress.address));
        this.set('record.stopPoints.0.address', _lodash.default.cloneDeep(dropAddress.address));
        this.get('geocode').deactivateGeocode();
        let pickAddressResult = [];
        pickAddressResult.push(dropAddress);
        this.get('geocode').set('markerType', 'pickup');
        this.get('geocode').set('currentRecord', record.stopPoints[0]);
        this.get('geocode').addressMapPopulate(record.stopPoints[0]);
        let dropAddressResult = [];
        dropAddressResult.push(pickAddress);
        this.get('geocode').set('markerType', 'dropoff');
        this.get('geocode').set('currentRecord', record.stopPoints[1].address);
        this.get('geocode').addressMapPopulate(record.stopPoints[1]);
      },

      handlePickupFocus() {
        this.input = document.querySelector(`.ember-power-select-trigger[id=pickup]`);

        if (this.input) {
          Ember.run.next(this.input, 'focus');
        }
      },

      handleDropoffFocus() {
        this.input = document.querySelector(`.ember-power-select-trigger[id=dropoff]`);

        if (this.input) {
          Ember.run.next(this.input, 'focus');
        }
      },

      handlePOIKeydown(record, valuePath, type, index, event) {
        console.log('handlePOIKeydown ', record);

        if (event.keyCode !== 13) {
          return;
        }

        this.set('editmodalcontext.showWidgetModal', false);
        this.set('editmodalcontext.modelWidgetPath', '');
        this.get('geocode').set('activePOIFRecord', record);
        this.get('geocode').set('activePOIDescription', record.address.description);
        this.get('geocode').set('activePOIIndex', index);
        this.get('geocode').set('markerType', type); //Ankur Need to enable if allow marker click
        // this.set('addressRecord', record);
        // this.addObserver('geocode.tomtomAddress', this.geocodeAddressChange);
        //this.get('geocode').set('disabledFindLocationForRecord',true);

        this.get('geocode').findPOIForRecord(null);
      }

    }
  });

  _exports.default = _default;
});