define("adept-iq/config/api-urls", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.API_SUB_URL = _exports.API = void 0;
  const API = {
    avlmService: {
      host: _environment.default.API.avlmService.host
    },
    avlmtripService: {
      host: _environment.default.API.avlmtripService.host
    },
    tripService: {
      host: _environment.default.API.tripService.host
    },
    configService: {
      host: _environment.default.API.configService.host
    },
    dispatchService: {
      host: _environment.default.API.dispatchService.host
    },
    geocodeService: {
      host: _environment.default.API.geocodeService.host
    },
    riderManagementService: {
      host: _environment.default.API.riderManagementService.host
    },
    schedulingService: {
      host: _environment.default.API.schedulingService.host
    },
    ssoService: {
      host: _environment.default.API.ssoService.host,
      useMirage: false
    },
    streamingService: {
      host: _environment.default.API.streamingService.host
    },
    tripManagementService: {
      host: _environment.default.API.tripManagementService.host
    },
    tomtom: {
      host: _environment.default.API.tomtomhost
    },
    awsAmazonConnect: {
      host: _environment.default.API.awsAmazonConnect.host
    }
  };
  _exports.API = API;
  const API_SUB_URL = {
    accountSearchPath: _environment.default.API_SUB_URL.accountSearchPath,
    phoneSearchPath: _environment.default.API_SUB_URL.phoneSearchPath,
    poiSearchPath: _environment.default.API_SUB_URL.poiSearchPath
  };
  _exports.API_SUB_URL = API_SUB_URL;
});