define("adept-iq/adapters/address-lookup", ["exports", "adept-iq/adapters/-tm-tripManagement"], function (_exports, _tmTripManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tmTripManagement.default.extend({
    urlForFindRecord(id, modelName, snapshot) {
      console.log('inside urlForFindRecord ');

      let url = this._super(...arguments);

      let query = Ember.get(snapshot, 'options.query');

      if (query) {
        url += '?' + query;
      }

      return url;
    },

    urlForQuery: function (query, modelName) {
      let url = this._super(...arguments);

      console.log('urlForQuery ', url);
      console.log('urlForQuery query ', query); //let query = Ember.get(snapshot, 'options.query');

      if (query) {
        url += '?' + query.searchURL;
      }

      return url;
    }
  });

  _exports.default = _default;
});