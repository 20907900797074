define("adept-iq/serializers/call-line", ["exports", "adept-iq/serializers/-dispatch"], function (_exports, _dispatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({});

  _exports.default = _default;
});