define("adept-iq/pods/components/iq-widgets/map-widget/data-joins/shape", ["exports", "adept-iq/pods/components/iq-widgets/map-widget/data-joins/base", "adept-iq/pods/components/iq-widgets/map-widget/config/circle", "adept-iq/pods/components/iq-widgets/map-widget/config/polygon", "lodash", "tomtom"], function (_exports, _base, _circle, _polygon, _lodash, _tomtom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    enter(node) {
      const points = node.get('points');
      if (Ember.isEmpty(points)) return;
      this.createShapeFor(node);
    },

    update(node) {
      const points = node.get('points');
      const shape = node.get('shape');

      if (Ember.isEmpty(points)) {
        if (shape) {
          this.removeShapeFor(node);
        }

        return;
      }

      if (!shape) {
        this.createShapeFor(node);
      }

      this.updateShapeFor(node);
    },

    exit(node) {
      if (node.get('shape')) {
        this.removeShapeFor(node);
      }
    },

    createShapeFor(node) {
      console.log('inside createShapeFor ');
      let label = node.get('label');
      const record = node.get('record');
      let style = node.get('style');
      const parentLabel = node.get('parentLabel');
      let points = node.get('points');
      const radius = node.get('radius');
      const noOfPoints = node.get('noOfPoints');

      if (record.get('style') !== undefined && record.get('style')) {
        style = record.get('style');
      }

      let defaultOptions;
      const nodeOptions = node.get('options') || {};
      const modelName = record.constructor.modelName;
      let shape;
      label = '';
      let parentLabelFormatted = '<table  style="position:relative;"><tr></tr><tr>' + '<td rowspan="2" style="font-weight: bold; font-size: 2em;text-align: center;">' + parentLabel + '</td><td rowspan="2">&nbsp;</td><td rowspan="2">' + label + '</td></tr></table>';

      if (noOfPoints === 1) {
        defaultOptions = _circle.default[style] || {};

        let options = _lodash.default.merge(defaultOptions, nodeOptions, {
          label,
          opacity: node.get('opacity'),
          // for active context clicks
          isActive: node.get('isActive'),
          isTraversal: node.get('isTraversal'),
          modelId: record.get('id'),
          modelName
        });

        shape = _tomtom.default.L.circle([points.lat, points.lng], radius, options);
        parentLabelFormatted = '<table  style="position:relative;"><tr></tr><tr>' + '<td rowspan="2" style="font-weight: bold; font-size: 0.7em;text-align: center;">' + parentLabel + '</td><td rowspan="2">&nbsp;</td><td rowspan="2">' + label + '</td></tr></table>';
      } else {
        defaultOptions = _polygon.default[style] || {};
        points = [node.get('points')];

        let options = _lodash.default.merge(defaultOptions, nodeOptions, {
          label,
          opacity: node.get('opacity'),
          // for active context clicks
          isActive: node.get('isActive'),
          isTraversal: node.get('isTraversal'),
          modelId: record.get('id'),
          modelName
        });

        shape = _tomtom.default.L.polygon(points, options);
      }

      shape.setStyle(style);
      this.bindMouseEvents(shape);
      node.set('shape', shape);
      const map = this.get('map');
      shape.addTo(map); //shape.bindPopup(label, { closeButton: false });

      shape.bindTooltip(parentLabelFormatted, {
        permanent: true,
        direction: "center",
        opacity: 0.4
      }).openTooltip();
    },

    updateShapeFor(node) {
      const opacity = node.get('opacity');
      const shape = node.get('shape');

      if (shape) {
        let label = node.get('label');
        const parentLabel = node.get('parentLabel');
        let style = node.get('style');
        const record = node.get('record');
        const noOfPoints = node.get('noOfPoints');

        if (record.get('style') !== undefined && record.get('style')) {
          style = record.get('style');
        }

        label = '';
        let parentLabelFormatted = '<table  style="position:relative;"><tr></tr><tr>' + '<td rowspan="2" style="font-weight: bold; font-size: 2em;text-align: center;">' + parentLabel + '</td><td rowspan="2">&nbsp;</td><td rowspan="2">' + label + '</td></tr></table>';
        let defaultOptions;

        if (noOfPoints === 1) {
          defaultOptions = _circle.default[style] || {};
          parentLabelFormatted = '<table  style="position:relative;"><tr></tr><tr>' + '<td rowspan="2" style="font-weight: bold; font-size: 0.7em;text-align: center;">' + parentLabel + '</td><td rowspan="2">&nbsp;</td><td rowspan="2">' + label + '</td></tr></table>';
        } else {
          defaultOptions = _polygon.default[style] || {};
        }

        const nodeOptions = node.get('options') || {};
        const modelName = record.constructor.modelName;

        const options = _lodash.default.merge(defaultOptions, nodeOptions, {
          label,
          opacity: opacity,
          // for active context clicks
          isActive: node.get('isActive'),
          isTraversal: node.get('isTraversal'),
          modelId: record.get('id'),
          modelName
        });

        shape.setStyle(options);
        this.bindMouseEvents(shape);
        shape.setTooltipContent(parentLabelFormatted);
      }
    },

    removeShapeFor(node) {
      const shape = node.get('shape');

      if (shape) {
        shape.remove();
        node.set('shape', null);
      }
    }

  });

  _exports.default = _default;
});