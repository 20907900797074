define("adept-iq/pods/components/form-components/button-model/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-places'],
    editmodalcontext: Ember.inject.service(),
    widgetModelData: null,
    currentRecord: null,
    geocode: Ember.inject.service(),
    isButtonEnabledPath: Ember.computed.readOnly('extra.isButtonEnabledPath'),
    widgetModelDataPath: Ember.computed.readOnly('extra.widgetModelDataPath'),
    modelWidgetPath: Ember.computed.readOnly('extra.modelWidgetPath'),
    label: Ember.computed.readOnly('extra.label'),
    accessKey: Ember.computed.readOnly('extra.accessKey'),
    isButtonEnabled: false,

    init() {
      this._super(...arguments);

      let record = this.currentRecord;
      this.set('isButtonEnabled', record.get(this.get('isButtonEnabledPath')));
    },

    actions: {
      populateDataList() {
        Ember.run(() => {
          this.set('widgetModelData', this.get('currentRecord').get(this.get('widgetModelDataPath')));
          this.set('currentRecord', this.get('currentRecord'));
          this.set('editmodalcontext.modelWidgetPath', this.get('modelWidgetPath'));
          this.set('editmodalcontext.showWidgetModal', false);
          this.set('editmodalcontext.showWidgetModal', true);
        });
      }

    }
  });

  _exports.default = _default;
});