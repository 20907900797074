define("adept-iq/models/iq-driver", ["exports", "ember-data", "ember-data/relationships", "ember-data/attr"], function (_exports, _emberData, _relationships, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    driver: (0, _relationships.belongsTo)('driver', {
      inverse: 'iqDriver'
    }),
    status: Ember.computed.alias('driver.status'),
    statusValue: Ember.computed.alias('driver.statusValue')
  });

  _exports.default = _default;
});