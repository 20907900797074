define("adept-iq/pods/components/side-drawer/add-new-message/component", ["exports", "adept-iq/pods/components/side-drawer/add-new-message/config"], function (_exports, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    editModal: Ember.inject.service(),
    title: 'Send Message',
    sections: _config.default.sections,
    templates: null,
    selectedTemplate: null,
    messageText: null,
    draftMessages: Ember.computed.alias('workspace.topOptions.draftMessages'),

    didInsertElement() {},

    validateRecords(draftMessages) {
      let valid = true;
      this.set('editModal.errors', []);
      console.log('isBlank ', Ember.isBlank(this.get('messageText')));

      if (Ember.isBlank(this.get('messageText'))) {
        valid = false;
        this.get('errorMessage').pushError({
          detail: `Message should be there`
        });
      }

      return valid;
    },

    sendMessage() {
      let draftMessages = this.get('draftMessages');

      if (this.validateRecords(draftMessages)) {
        draftMessages.forEach(message => {
          message.set('body', this.get('messageText'));
          message.set('subject', this.get('messageText'));
        });
        const me = this;
        return new Promise((resolve, reject) => {
          draftMessages.forEach(message => {
            message.save().then(() => {
              me.get('errorMessage').pushInfo({
                detail: `Message successfully send`
              });
            }).catch(function () {
              return [];
            });
          });
        });
      }
    },

    cancelMessage() {
      this.get('tooltip').reset();
    },

    actions: {
      onInput(value) {
        this.set(`messageText`, value);
      },

      onSendMessageButtonClick() {
        this.sendMessage();
        /*let tooltip = this.get('tooltip');
         tooltip.setProperties({
          tip: 'Send new message?',
          primaryActionText: 'Confirm',
          secondaryActionText: 'Cancel',
          primaryAction: () => {
            tooltip.reset();
            this.sendMessage();
          },
          secondaryAction: () => {
            toolbar.reset();
            this.cancelMessage();
          }
        });*/
      },

      onTemplateSelect(template) {
        this.set('selectedTemplate', template);
      },

      onCloseButtonClick() {
        this.get('workspace').popState();
      }

    }
  });

  _exports.default = _default;
});