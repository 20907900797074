define("adept-iq/pods/components/dashboard-save-as/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_NAME = 'Untitled';

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['dashboard-save-as'],
    name: null,
    isLoading: Ember.computed.oneWay('findUnusedNameTask.isRunning'),
    isRunning: Ember.computed.or('saveTask.isRunning', 'cancelTask.isRunning'),

    didInsertElement() {
      this._super(...arguments);

      this.get('findUnusedNameTask').perform().then(name => {
        this.set('name', name);
        Ember.run.schedule('afterRender', () => {
          this.$('.workspace-name-input').focus();
        });
      });

      this._onKeyDown = event => {
        Ember.run.schedule('afterRender', this, 'onKeyDown', event);
      };

      this.$().on('keydown', this._onKeyDown);
    },

    willRemoveElement() {
      this.$().off('keydown', this._onKeyDown);
      this._onKeyDown = null;

      this._super(...arguments);
    },

    findUnusedNameTask: (0, _emberConcurrency.task)(function* () {
      let baseName = this.get('workspace.dashboard.name');
      let version = 0;

      if (Ember.isPresent(baseName)) {
        // extract base name and version from names like "My Dashboard (3)"
        const regEx = /^(.*)\s+\((\d+)\)\s*$/;
        const matches = regEx.exec(baseName);

        if (matches) {
          baseName = matches[1];
          version = parseInt(matches[2], 10) + 1;
        }
      } else {
        baseName = DEFAULT_NAME;
      }

      const dashboards = yield this.get('store').findAll('dashboard');
      let name = version === 0 ? baseName : `${baseName} (${version})`;

      while (dashboards.findBy('name', name)) {
        version += 1;
        name = `${baseName} (${version})`;
      }

      return name;
    }).drop(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      // clone persisted changes
      const dashboardModel = this.get('workspace.dashboardModel');
      const options = dashboardModel.getOptionsForClone();
      const clone = this.get('store').createRecord('dashboard', options); // clone unsaved changes

      const dashboardInstance = this.get('workspace.dashboardInstance');
      clone.commitInstance(dashboardInstance); // update name using value in input box

      clone.set('name', this.get('name'));

      try {
        yield clone.save();
        yield this.get('workspace').popState('saveWorkspaceAs');
        yield this.get('router').transitionTo('dashboard', clone);
      } catch (e) {
        clone.rollbackAttributes();
      }
    }).drop(),
    cancelTask: (0, _emberConcurrency.task)(function* () {
      // modal 'X' button can be clicked at any time, even during load
      this.get('findUnusedNameTask').cancelAll();
      this.get('saveTask').cancelAll();
      yield this.get('workspace').popState('saveWorkspaceAs');
    }).drop(),

    onKeyDown(event) {
      switch (event.key) {
        case 'Enter':
          {
            event.preventDefault();
            return this.get('saveTask').perform();
          }

        default:
          return null;
      }
    }

  });

  _exports.default = _default;
});