define("adept-iq/utils/populate-search-trip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = populateNewTrip;

  function populateNewTripData(tripTemplate, store) {
    let defaultCardNr = '';
    let tripData = tripTemplate.tripData().data.attributes;
    let newtrip = store.createRecord('tm-booking', {
      passengers: tripData.passengers,
      prices: tripData.prices,
      relatedIds: tripData.relatedIds,
      requestedProvider: tripData.requestedProvider,
      source: tripData.source,
      status: tripData.status,
      stopPoints: tripData.stopPoints,
      trackingId: tripData.trackingId,
      acctCardNr: defaultCardNr,
      manualAttributes: [],
      // Helper property after this.
      editmodelcontext: {
        accountTrips: [],
        accountFixedPriceTrips: [],
        callerTrips: [],
        assigns: [],
        swapAddressDisabled: true,
        saveCLIDisabled: true,
        driverNotes: '',
        operatorNotes: '',
        attributes: {
          account: [],
          cli: [],
          address: [],
          area: []
        },
        predefinedDriverNotes: {
          account: '',
          cli: '',
          address: '',
          area: '',
          building: '',
          attribute: ''
        },
        predefinedOperatorNotes: {
          account: '',
          cli: '',
          address: '',
          area: '',
          building: '',
          attribute: ''
        },
        editAttributes: {
          account: [],
          cli: [],
          building: [],
          area: [],
          region: [],
          editTrip: []
        }
      }
    }); //newtrip.stopPoints[0].passengerEvents[0].time = ;

    newtrip.stopPoints[0].address.tomtomFormattedAddress = {
      address: {
        freeformAddress: ''
      }
    };
    newtrip.stopPoints[1].address.tomtomFormattedAddress = {
      address: {
        freeformAddress: ''
      }
    }; //let subscriptions = this.createSubscriptionRecord1();
    //trip.set('subscriptions',subscriptions);
    //////console.log(('newtrip ',newtrip);

    return newtrip;
  }

  function populateNewTrip(tripTemplate, store) {
    return populateNewTripData(tripTemplate, store);
  }
});