define("adept-iq/models/vehicle", ["exports", "ember-data", "adept-iq/mixins/otp-label", "adept-iq/mixins/status-value"], function (_exports, _emberData, _otpLabel, _statusValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    hasMany,
    attr
  } = _emberData.default;

  var _default = Model.extend(_statusValue.default, {
    avl: belongsTo('avl', {
      inverse: 'vehicle'
    }),
    vehicleState: belongsTo('vehicle-state', {
      inverse: 'vehicle'
    }),
    name: attr('string'),
    plateNr: attr('string'),
    mobileId: attr('number'),
    xmin: attr('string'),
    serviceStatus: attr('string'),
    lastGpsPing: attr('date'),
    mileage: attr('string'),
    schedule: belongsTo(),
    provider: belongsTo(),
    callsign: attr('string'),
    vehicleCode: attr('string'),
    odo: attr('string'),
    registration: attr('string'),
    vehicleModel: attr('string'),
    description: attr('string'),
    year: attr('string'),
    color: attr('string'),
    make: attr('string'),
    attributes: attr(),
    attributesList: Ember.computed('attributes.[]', function () {
      if (!Ember.isEmpty(this.get('attributes'))) {
        return Array.prototype.map.call(this.get('attributes'), attribute => attribute.type).toString();
      } else {
        return [];
      }
    }),
    hardwareId: attr('string'),
    companyName: attr('string'),
    suspensionType: attr('string'),
    // eslint-disable-next-line ember/use-brace-expansion
    driver: Ember.computed('vehicleState.content.driver', 'drivedStatus', function () {
      let vehicleState = this.get('vehicleState.content');

      if (vehicleState) {
        if (this.get('drivedStatus') === 'off') {
          return null;
        }

        return vehicleState.get('driver');
      }

      return null;
    }),
    standId: attr('string'),
    standQueuePos: Ember.computed.alias('vehicleState.content.standQueuePos'),
    zoneQueuePos: Ember.computed.alias('vehicleState.content.zoneQueuePos'),
    QPos: attr(),
    status: attr('string'),
    currentAVLLocation: Ember.computed.alias('avl'),
    lat: Ember.computed.alias('currentAVLLocation.lat'),
    lng: Ember.computed.alias('currentAVLLocation.lng'),
    timestamp: Ember.computed.alias('currentAVLLocation.timestamp'),
    heading: Ember.computed.alias('currentAVLLocation.heading'),
    speed: Ember.computed.alias('currentAVLLocation.speed'),
    sortedAvls: Ember.computed('avl', function () {
      return this.get('avl');
    }),
    iqVehicle: belongsTo('iq-vehicle', {
      inverse: 'vehicle'
    }),
    lastKnownCoord: Ember.computed.collect('lat', 'lng'),
    point: Ember.computed.collect('lat', 'lng'),
    suspensionFlag: Ember.computed('suspensionType', function () {
      if (this.get('suspensionType')) {
        return true;
      }

      return false;
    }),
    drivedStatus: Ember.computed('vehicleState.content.status', function () {
      if (this.get('vehicleState')) {
        let vehicleState = this.get('vehicleState.content');

        if (vehicleState) {
          return vehicleState.get('status');
        }
      }

      return this.get('status');
    }),
    zone: Ember.computed('vehicleState.content.zone', function () {
      let vehicleState = this.get('vehicleState.content');

      if (vehicleState) {
        return vehicleState.get('zone');
      }
    }),
    stand: Ember.computed('vehicleState.content.stand', function () {
      let vehicleState = this.get('vehicleState.content');

      if (vehicleState) {
        return vehicleState.get('stand');
      }
    }),
    trips: hasMany('trip'),
    GPS: Ember.computed('lat', 'lng', function () {
      if (!Ember.isBlank(this.get('lat')) && !Ember.isBlank(this.get('lng')) && !isNaN(this.get('lat')) && !isNaN(this.get('lng')) && this.get('lat') !== 0 && this.get('lng') !== 0 && this.get('lat') !== 181 && this.get('lng') !== 181 && this.get('lat') !== -181 && this.get('lng') !== -181) {
        return 'Valid';
      } else {
        return 'Invalid';
      }
    }),
    GPSTimestamp: Ember.computed.alias('currentAVLLocation.timestamp')
  });

  _exports.default = _default;
});