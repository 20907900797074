define("adept-iq/serializers/tm-text-message", ["exports", "adept-iq/serializers/-tm-tripManagement"], function (_exports, _tmTripManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tmTripManagement.default.extend({
    serialize()
    /* snapshot, options */
    {
      let json = this._super(...arguments);

      console.log('booking json ', json);

      try {
        delete json.data.attributes.driver;
      } catch (e) {}

      try {
        delete json.data.attributes.vehicle;
      } catch (e) {}

      try {
        delete json.data.attributes.vehicleId;
      } catch (e) {}

      return json;
    }

  });

  _exports.default = _default;
});