define("adept-iq/pods/components/iq-widgets/drivers-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/drivers-widget',
    rowComponent: 'table/rows/driver-status-formatted-row',
    modelName: 'driver',
    defaultSortId: 'badgeNr',
    defaultSortAsc: false,
    accessKey: '6',
    title: 'Drivers',
    columns: [{
      id: 'badgeNr',
      index: 0,
      type: 'number',
      label: 'Badge Nr',
      valuePath: 'badgeNr',
      searchable: true,
      editable: false,
      hidden: false,
      defaultWidth: 50
    }, {
      id: 'name',
      type: 'text',
      label: 'Name',
      valuePath: 'name',
      searchable: true,
      editable: false,
      hidden: false,
      defaultWidth: 60
    }, {
      id: 'status',
      type: 'text',
      label: 'Status',
      valuePath: 'drivedStatus',
      searchable: true,
      editable: false,
      hidden: false,
      highlightable: true,
      defaultWidth: 50
    }, {
      id: 'Callsign',
      type: 'text',
      label: 'Callsign',
      valuePath: 'vehicle.callsign',
      searchable: true,
      cellDesc: 'Cab#',
      editable: false,
      hidden: false
    }, {
      id: 'attributes',
      type: 'text',
      label: 'Attributes',
      valuePath: 'attributesList',
      searchable: true,
      editable: false,
      hidden: false,
      defaultWidth: 100
    }, {
      id: 'tripId',
      type: 'number',
      label: 'Trip ID',
      valuePath: 'trip.id',
      searchable: true,
      editable: false,
      hidden: false,
      defaultWidth: 100
    }, {
      id: 'suspensionFlag',
      type: 'boolean',
      label: 'Suspended',
      valuePath: 'suspensionFlag',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'active',
      type: 'boolean',
      label: 'Active',
      valuePath: 'active',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 50
    }]
  };
  _exports.default = _default;
});