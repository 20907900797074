define("adept-iq/models/trip", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "adept-iq/mixins/otp-label", "adept-iq/mixins/status-value"], function (_exports, _model, _attr, _relationships, _otpLabel, _statusValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_statusValue.default, {
    status: (0, _attr.default)('string'),
    planState: (0, _attr.default)('number'),
    requestedTime: (0, _attr.default)('date'),
    requestTime: (0, _attr.default)('date'),
    promiseTime: (0, _attr.default)('date'),
    anchor: (0, _attr.default)('string'),
    tripExecEvent: (0, _relationships.belongsTo)('trip-exec-event', {
      inverse: 'trip'
    }),
    vehicleState: (0, _relationships.belongsTo)('vehicle-state'),
    iqTrip: (0, _relationships.belongsTo)('iq-trip', {
      inverse: 'trip'
    }),
    //TODO
    //fareType: belongsTo('fare-type'),
    pick: (0, _relationships.belongsTo)('stop', {
      inverse: 'picktrip'
    }),
    drop: (0, _relationships.belongsTo)('stop', {
      inverse: 'droptrip'
    }),
    // this is created for AC; should go through legs
    rider: (0, _relationships.belongsTo)('rider'),
    legs: (0, _relationships.hasMany)('leg'),
    originlocations: (0, _relationships.hasMany)('location'),
    destinationlocations: (0, _relationships.hasMany)('location'),
    stops: Ember.computed.collect('pick', 'drop'),
    legTravelNeeds: Ember.computed.alias('leg.legTravelNeeds'),
    extraPassengers: Ember.computed.alias('rider.extraPassengers'),
    equipments: Ember.computed.alias('rider.equipments'),
    pickAddress: Ember.computed.alias('pick.place.streetAddress'),
    account: (0, _relationships.belongsTo)('account'),
    zone: (0, _relationships.belongsTo)('zone'),
    location: Ember.computed('pick.location', 'drop.location', function () {
      let location = [];
      const start = [this.get('pick.location.lat'), this.get('pick.location.lng')];
      if (Ember.isPresent(start[0]) && Ember.isPresent(start[1])) location.push(start);
      const end = [this.get('drop.location.lat'), this.get('drop.location.lng')];
      if (Ember.isPresent(end[0]) && Ember.isPresent(end[1])) location.push(end);
      return location;
    }),
    polyline: Ember.computed('pick.location.{lat,lng}', 'drop.location.{lat,lng}', function () {
      const pickLat = this.get('pick.location.lat');
      const pickLng = this.get('pick.location.lng');
      const dropLat = this.get('drop.location.lat');
      const dropLng = this.get('drop.location.lng');

      if ([pickLat, pickLng, dropLat, dropLng].any(Ember.isNone)) {
        return null;
      }

      return [[pickLat, pickLng], [dropLat, dropLng]];
    }),
    lat: Ember.computed('pick.location.{lat,lng}', function () {
      const pickLat = this.get('pick.place.lat');
      return pickLat;
    }),
    lng: Ember.computed('pick.location.{lat,lng}', function () {
      const pickLng = this.get('pick.place.lng');
      return pickLng;
    }),
    tripType: (0, _attr.default)('string'),
    bookingType: (0, _attr.default)('string'),
    operatorNotes: (0, _attr.default)('string'),
    driverNotes: (0, _attr.default)('string'),
    riderName: (0, _attr.default)('string'),
    riderPhoneNr: (0, _attr.default)('string'),
    tripStatus: (0, _attr.default)('string'),
    paymentType: (0, _attr.default)('string'),
    drivedStatus: Ember.computed('tripStatus', 'tripExecEvent.content.status', function () {
      let tripExecEvent = this.get('tripExecEvent.content');

      if (this.get('tripStatus') === 'completed' || this.get('tripStatus') === 'cancelled') {
        return this.get('tripStatus');
      }

      if (tripExecEvent) {
        return tripExecEvent.get('status');
      }

      return this.get('tripStatus');
    }),
    driver: Ember.computed('tripExecEvent.content.driver', function () {
      let tripExecEvent = this.get('tripExecEvent.content');

      if (tripExecEvent) {
        return tripExecEvent.get('driver');
      }

      return null;
    }),
    vehicle: Ember.computed('tripExecEvent.content.vehicle', function () {
      let tripExecEvent = this.get('tripExecEvent.content');

      if (tripExecEvent) {
        return tripExecEvent.get('vehicle');
      }

      return null;
    }),
    fare: (0, _attr.default)('number'),
    statusReason: (0, _attr.default)('string'),
    statusNote: (0, _attr.default)('string'),
    tripTime: (0, _attr.default)('date'),
    jobPriority: (0, _attr.default)('number'),
    dispatchMethod: (0, _attr.default)('string'),
    companyName: (0, _attr.default)('string'),
    noShowReasonCode: (0, _attr.default)('string'),
    auctionTime: (0, _attr.default)('date'),
    cancelReason: (0, _attr.default)('string'),
    callbackTime: (0, _attr.default)('date'),
    attributes: (0, _attr.default)('string')
  });

  _exports.default = _default;
});