define("adept-iq/pods/components/side-drawer/filter-settings/component", ["exports", "adept-iq/config/filter-types"], function (_exports, _filterTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    tagName: '',
    title: 'Filter Settings',
    filterTypes: _filterTypes.filterTypes,
    columns: Ember.computed.readOnly('workspace.topOptions.columns'),
    widget: Ember.computed.readOnly('workspace.topOptions.widget'),
    dataColumns: Ember.computed('columns.[]', function () {
      const columns = this.get('columns');
      return columns.slice(1, columns.length - 1);
    }),

    reOrderFilterSettings(columns) {
      this.get('widget').mergeState({
        columns
      });
      const tableColumns = this.get('columns'); // checkbox and spacer are always in the same location

      tableColumns[0].sortOrder = 0;
      tableColumns[tableColumns.length - 1].sortOrder = tableColumns.length - 1;
      tableColumns.forEach(column => {
        if (columns[column.id]) {
          column.sortOrder = columns[column.id].index + 1;
        }
      });
      tableColumns.sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      });
      this.notifyPropertyChange('columns');
    },

    actions: {
      onReorderItems(tableColumns) {
        const columns = tableColumns.reduce((obj, column, index) => {
          const id = column.id;
          obj[id] = {
            index
          };
          return obj;
        }, {});
        Ember.run(() => this.reOrderFilterSettings(columns));
      },

      onClickVisibilityCheckbox(column, event) {
        const id = column.get('id');
        const columns = {};
        columns[id] = {
          hidden: !event.target.checked
        };
        this.get('widget').mergeState({
          columns
        });
      },

      onSelectFilterType(column, filterType) {
        const id = column.get('id');
        const columns = {};
        columns[id] = {
          filterTypeId: filterType.id
        };
        this.get('widget').mergeState({
          columns
        }); // @TODO: focus on filter value input
      },

      onFilterValueChange(column, index, event) {
        let filterValues = column.get('filterValues') || [];
        filterValues = filterValues.slice();
        filterValues[index] = event.target.value;
        Ember.set(column, 'filterValues', filterValues);
        const id = column.get('id');
        const columns = {};
        columns[id] = {
          filterValues
        }; // this.get('widget').mergeState({ columns } , false);
      },

      handleKeydown(column, index, event) {
        //console.log('handleKeydown ',event);
        if (event.keyCode !== 13) {
          return;
        }

        let filterValues = column.get('filterValues') || [];
        filterValues = filterValues.slice();
        filterValues[index] = event.target.value;
        Ember.set(column, 'filterValues', filterValues);
        const id = column.get('id');
        const columns = {};
        columns[id] = {
          filterValues
        };
        this.get('widget').mergeState({
          columns
        });
        this.get('workspace').popState();
      },

      clearFilters() {
        this.get('activeContext').set('serverFilteringEnabled', false);
        this.get('activeContext').set('serverFilteringChecked', false);
        this.get('activeContext').set('serverLoadedRecord', []);
        const columns = this.get('columns').reduce((obj, column) => {
          obj[column.id] = {
            filterTypeId: 'null',
            // this is actually the id of the null filter
            filterValues: null
          };
          return obj;
        }, {});
        this.get('widget').mergeState({
          columns
        });
      }

    }
  });

  _exports.default = _default;
});