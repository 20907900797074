define("adept-iq/pods/components/form-components/savecli/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5PRspfpA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"table\"],[9],[0,\"\\n  \"],[7,\"tr\"],[9],[0,\"\\n    \"],[7,\"td\"],[9],[0,\"\\n      \"],[7,\"table\"],[9],[0,\"\\n          \"],[7,\"tr\"],[9],[0,\"\\n            \"],[7,\"td\"],[9],[0,\"\\n                \"],[7,\"button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"saveCliInfo\",[23,[\"record\"]]],null]],[12,\"disabled\",[23,[\"record\",\"editmodelcontext\",\"saveCLIDisabled\"]]],[12,\"title\",[28,[\"Save CLI  \",[21,\"altSwipeAddressKey\"]]]],[11,\"class\",\"multitype-button btn btn-outline-secondary\"],[9],[0,\"\\n                   \"],[7,\"i\"],[11,\"class\",\"fa fa-phone-square\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/savecli/template.hbs"
    }
  });

  _exports.default = _default;
});