define("adept-iq/pods/components/iq-widgets/metrics-column-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "ember-light-table"], function (_exports, _component, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const fromPixels = x => parseInt(x.split('px')[0]);

  var _default = _component.default.extend({
    classNames: ['analytics-widget', 'metrics-column-widget'],
    table: null,
    dataType: null,
    dataTypes: Ember.computed('', function () {
      return ["Schedules", "Routes", "Trips", "Stops", "Passengers", "Vehicles", "Users", "Drivers"];
    }),
    model: Ember.computed(function () {
      return [{
        'sample': "Captain",
        'firstName': "Banana",
        'lastName': "Sundae"
      }, {
        'sample': "Mr.",
        'firstName': "Icecream",
        'lastName': "Sandwich"
      }];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'Label name',
        valuePath: 'sample',
        width: '100px',
        sortable: false,
        cellComponent: 'table/cells/base-cell',
        resizable: true
      }, {
        label: 'First Name',
        valuePath: 'firstName',
        width: '150px',
        cellComponent: 'table/cells/base-cell',
        resizable: true
      }, {
        label: 'Last Name',
        valuePath: 'lastName',
        width: '150px',
        cellComponent: 'table/cells/base-cell',
        resizable: true
      }, {
        id: 'spacer',
        width: '100%',
        sortable: false,
        resizable: false
      }];
    }),

    init() {
      this._super(...arguments);

      let table = new _emberLightTable.default(this.get('columns'), this.get('model'), {
        enableSync: false
      });
      this.set('table', table);
    },

    actions: {
      chooseDataType(chosenType) {
        this.set('dataType', chosenType);
      },

      onColumnResize(column, pixels) {
        let id = column.get('id');
        let columns = {};
        columns[id] = {
          width: fromPixels(pixels)
        };
        this.get('widget').mergeState({
          columns
        });
        Ember.run.scheduleOnce('afterRender', this, 'resizeBody');
      },

      onFilterButtonClick() {
        let workspace = this.get('workspace');
        let topState = workspace.get('topState');

        while (topState === 'metricsColumnWidget') {
          workspace.popState();
          topState = workspace.get('topState');
        }

        let displayName = "Metrics Column Analytics";
        workspace.pushState('metricsColumnWidget', {
          displayName
        });
      }

    }
  });

  _exports.default = _default;
});