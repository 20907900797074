define("adept-iq/pods/components/form-components/number/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NUMBER_FORMAT = /^\d*\.*\d*$/;

  var _default = Ember.Component.extend({
    classNames: ['form-components-number'],
    value: null,
    disabled: false,
    decimal: null,
    actions: {
      onInput(value) {
        // Reset input value to prior value unless changed value matches format.
        if (!NUMBER_FORMAT.test(value)) {
          return this.notifyPropertyChange('value');
        }

        console.log('decimal ', this.get('decimal'));
        /*if(!isBlank(this.get('decimal'))) {
           this.get('onChange')(parseFloat(value).toFixed(this.get('decimal')));
          
        } else {*/

        this.get('onChange')(value); //}  

        return value;
      }

    }
  });

  _exports.default = _default;
});