define("adept-iq/topic-transforms/driver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(store, payload
  /*, logger*/
  ) {
    return payload;
  }
});