define("adept-iq/pods/components/iq-widgets/driver-breaks-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/driver-breaks-form-widget/config", "moment"], function (_exports, _component, _config, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    errorMessage: Ember.inject.service(),

    validateRecords() {
      let valid = true;
      let records = this.get('editableRecords');
      this.set('editModal.errors', []);
      records.forEach(record => {
        let promisedStartMoment = (0, _moment.default)(record.get('promisedStart'));
        let promisedEndMoment = (0, _moment.default)(record.get('promisedEnd'));

        if (Ember.isNone(record.get('breakType.id'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Must select a break type.');
        }

        if (promisedStartMoment.isSame(promisedEndMoment)) {
          valid = false;
          this.get('editModal.errors').pushObject('Break Start and Break End cannot be the same.');
        }
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        let lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          let records = this.get('editableRecords');
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(({
            record,
            properties
          }) => {
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      onApplyClick() {
        if (this.validateRecords()) {
          this.get('service').apply();
        }
      }

    }
  });

  _exports.default = _default;
});