define("adept-iq/serializers/rider-info", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    JSONAPISerializer
  } = _emberData.default;

  var _default = JSONAPISerializer.extend({
    keyForAttribute: function (attr) {
      return attr;
    },

    keyForRelationship(key) {
      return key;
    }

  });

  _exports.default = _default;
});