define("adept-iq/utils/populate-fixed-price", ["exports", "adept-iq/utils/check-address-flags", "adept-iq/utils/populate-address", "adept-iq/utils/check-address-overwrite", "adept-iq/utils/check-value", "lodash"], function (_exports, _checkAddressFlags, _populateAddress, _checkAddressOverwrite, _checkValue, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = populateFixedPrice;

  function populateFixedPriceData(tripData, fixedPriceTripData, geocode, store) {
    //console.log('tripData ',tripData.stopPoints[0].address);
    //console.log('pickAddress.pickAddress.coord.lat ',fixedPriceTripData.get('pickAddress.coord.lat'));
    try {
      tripData.set('fixedAmount', parseFloat(fixedPriceTripData.price.amount.toFixed(2)));
    } catch (e) {
      console.log(e);
    }

    let overwriteAddress = true;
    let overwriteAddressPop = (0, _checkAddressOverwrite.default)(tripData.stopPoints[0].address, fixedPriceTripData.pickup.address);

    if (overwriteAddressPop) {
      overwriteAddress = confirm('Do you want to overwrite Address');
    }

    if (overwriteAddress) {
      (0, _populateAddress.default)(tripData.stopPoints[0].address, fixedPriceTripData.pickup.address, geocode, store, 'pickup', tripData, true);

      try {
        if (!Ember.isBlank(fixedPriceTripData.notes)) {
          Ember.set(tripData, 'editmodelcontext.predefinedDriverNotes.address', (0, _checkValue.default)(fixedPriceTripData.notes.driver));
          Ember.set(tripData, 'editmodelcontext.predefinedOperatorNotes.address', (0, _checkValue.default)(fixedPriceTripData.notes.dispatcher));
        } else {
          Ember.set(tripData, 'editmodelcontext.predefinedDriverNotes.address', '');
          Ember.set(tripData, 'editmodelcontext.predefinedOperatorNotes.address', '');
        }
      } catch (e) {}
    }

    (0, _populateAddress.default)(tripData.stopPoints[1].address, fixedPriceTripData.dropoff.address, geocode, store, 'dropoff', tripData, false);
    (0, _checkAddressFlags.default)(tripData);
  }

  function clearTripData(tripData, geocode, store) {
    try {
      tripData.set('prices.0.fixed', '');
    } catch (e) {
      console.log(e);
    }

    try {
      tripData.set('price.fixed', '');
    } catch (e) {
      console.log(e);
    }

    (0, _populateAddress.default)(tripData.stopPoints[0].address, null, geocode, store, 'pickup', tripData, true);
    (0, _populateAddress.default)(tripData.stopPoints[1].address, null, geocode, store, 'dropoff', null, false);
    geocode.deactivateGeocode();
  }

  function populateFixedPrice(tripRecord, fixedPriceTripData, geocode, store) {
    if (fixedPriceTripData !== null) {
      clearTripData(tripRecord, geocode, store);
      populateFixedPriceData(tripRecord, fixedPriceTripData, geocode, store);
    } else {
      clearTripData(tripRecord, geocode, store);
    }

    return;
  }
});