define("adept-iq/pods/components/ember-cli-tableau/component", ["exports", "adept-iq/pods/components/templates/components/tableau-view"], function (_exports, _tableauView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _tableauView.default,
    classNames: ['tableau-view'],
    _resizeRetryCount: 0,
    _config: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    }),
    _server: Ember.computed('_config', function () {
      let server = this.get('_config.tableau.server');

      if (server && server.endsWith('/')) {
        server = server.slice(0, -1);
      }

      return server;
    }),
    _options: Ember.computed('options', function () {
      const options = {
        onFirstVizSizeKnown: e => {
          Ember.run.later(() => {
            this._resize(this.$().width(), this.$().height());
          }, 2000);

          if (this.get('onFirstVizSizeKnown')) {
            this.get('onFirstVizSizeKnown')(e);
          }
        },
        onFirstInteractive: e => {
          this._addEvents();

          if (this.get('onFirstInteractive')) {
            this.get('onFirstInteractive')(e);
          }
        }
      };
      Ember.merge(options, this.get('options') || {});
      return options;
    }),

    _resize(width, height) {
      const viz = this.get('_vizInstance');
      const retryCount = this.get('_resizeRetryCount');

      if (!viz || retryCount >= 2) {
        return;
      }

      try {
        viz.setFrameSize(width, height);

        try {
          const sheet = viz.getWorkbook().getActiveSheet();
          sheet.changeSizeAsync({
            "behavior": "EXACTLY",
            "maxSize": {
              "height": height - 80,
              "width": width
            }
          }).then(viz.setFrameSize(width, height));
        } catch (e) {//console.error(e);
        }
      } catch (e) {
        this.set('_resizeRetryCount', retryCount + 1);
        Ember.run.later(() => {
          this._resize(width, height);
        }, 1500);
      }
    },

    _addEvents() {
      const viz = this.get('_vizInstance');

      if (!viz) {
        return;
      }

      viz.addEventListener('tabSwitch', () => {
        Ember.run.later(() => {
          this._resize(this.$().width(), this.$().height());
        }, 500);
      });
    },

    _dispose() {
      const vizInstance = this.get('_vizInstance');

      if (vizInstance) {
        vizInstance.dispose();
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this._dispose();

      const viewPath = this.get('viewPath') || `${this.get('workbook')}/${this.get('view')}`;
      const sitePath = this.get('sitePath');
      let url = `${this.get('_server')}/${sitePath}/views/${viewPath}`;

      if (this.get('token')) {
        url = `${this.get('_server')}/trusted/${this.get('token')}/views/${viewPath}`;
      }

      this.set('_vizInstance', new tableau.Viz(this.get('element'), url, this.get('_options')));
    },

    willDestroyElement() {
      this._super(...arguments);

      this._dispose();
    }

  });

  _exports.default = _default;
});