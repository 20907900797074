define("adept-iq/models/tm-rider-profile", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/coord.js */
  var _default = _model.default.extend({
    provider: (0, _attr.default)(),
    name: (0, _attr.default)('string'),
    contactLastName: (0, _attr.default)('string'),
    contactFirstName: (0, _attr.default)('string'),
    contactMiddleName: (0, _attr.default)('string'),
    phone1: (0, _attr.default)(),
    phone2: (0, _attr.default)(),
    email: (0, _attr.default)('string'),
    countryCode: (0, _attr.default)('string'),
    notes: (0, _attr.default)(),
    account: (0, _attr.default)(),
    requiredAttributes: (0, _attr.default)(),
    boardingOverhead: (0, _attr.default)(),
    defaultFavoritePaymentId: (0, _attr.default)(),
    favoritePayments: (0, _attr.default)(),
    defaultFavoriteTripId: (0, _attr.default)(),
    favoriteTrips: (0, _attr.default)(),
    eligibilities: (0, _attr.default)(),
    SSN: (0, _attr.default)('string'),
    dob: (0, _attr.default)(),
    locale: (0, _attr.default)('string'),
    externalId: (0, _attr.default)('string'),
    status: (0, _attr.default)('string')
  });

  _exports.default = _default;
});