define("adept-iq/utils/check-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = checkValue;

  function checkValue(value) {
    if (Ember.isBlank(value)) {
      return "";
    } else {
      return value.trim();
    }
  }
});