define("adept-iq/models/tm-config-parameter-value", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import { belongsTo, hasMany } from 'ember-data/relationships';
  var _default = _model.default.extend({
    provider: (0, _attr.default)(),
    value: (0, _attr.default)(),
    configParameter: (0, _attr.default)()
  });

  _exports.default = _default;
});