define("adept-iq/pods/forgot-password/route", ["exports", "adept-iq/config/api-urls"], function (_exports, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),

    setupController(controller
    /*, model*/
    ) {
      // can we pass in the user's email ?
      controller.set('data', {});
    },

    actions: {
      cancel() {
        this.transitionTo('login');
      },

      reset(email) {
        const ajax = this.get('ajax');
        const data = {
          email: email
        };
        const message = 'Reset Password Failed';
        const successMessage = `Password Reset Email has been sent to ${email}`;
        return ajax.request(_apiUrls.API.ssoService.host + '/passwordResetToken', {
          dataType: 'text',
          contentType: 'application/json',
          method: 'POST',
          data: data
        }).then(() => {
          /*eslint-disable */
          this.set('controller.data.errors', undefined);
          /*eslint-enable */

          return this.set('controller.data.success', successMessage);
        }).catch(e => {
          /*eslint-disable */
          console.log(e);
          this.set('controller.data.success', undefined);
          /*eslint-enable */

          return this.set('controller.data.errors', [message]);
        });
      }

    }
  });

  _exports.default = _default;
});