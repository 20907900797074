define("adept-iq/pods/components/form-components/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dz6bCgiA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"multiLine\"]],true],null]],null,{\"statements\":[[0,\"  \"],[7,\"textarea\"],[11,\"class\",\"form-control\"],[12,\"value\",[27,\"readonly\",[[23,[\"value\"]]],null]],[12,\"accesskey\",[21,\"accessKey\"]],[12,\"maxlength\",[27,\"readonly\",[[23,[\"maxlength\"]]],null]],[12,\"disabled\",[27,\"readonly\",[[23,[\"disabled\"]]],null]],[12,\"oninput\",[27,\"action\",[[22,0,[]],\"onInput\"],[[\"value\"],[\"target.value\"]]]],[9],[10],[0,\"\"]],\"parameters\":[]},{\"statements\":[[0,\"   \"],[7,\"input\"],[11,\"class\",\"form-control\"],[12,\"value\",[27,\"readonly\",[[23,[\"value\"]]],null]],[12,\"accesskey\",[21,\"accessKey\"]],[12,\"maxlength\",[27,\"readonly\",[[23,[\"maxlength\"]]],null]],[12,\"disabled\",[27,\"readonly\",[[23,[\"disabled\"]]],null]],[12,\"oninput\",[27,\"action\",[[22,0,[]],\"onInput\"],[[\"value\"],[\"target.value\"]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/text/template.hbs"
    }
  });

  _exports.default = _default;
});