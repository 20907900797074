define("adept-iq/models/address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    alias: attr('string', {
      defaultValue: ''
    }),
    description: attr('string', {
      defaultValue: ''
    }),
    streetNr: attr('string'),
    streetName: attr('string'),
    city: attr('string'),
    locality: attr('string'),
    region: attr('string'),
    subRegion: attr('string'),
    postOfficeBoxNumber: attr('string'),
    postalCode: attr('string'),
    country: attr('string'),
    notes: attr('string'),
    locations: hasMany('location'),
    favoritePick: belongsTo('favorite-pickup', {
      inverse: 'pickAddress'
    }),
    fixedPricePick: belongsTo('fixed-price-trip'),
    fixedPriceDrop: belongsTo('fixed-price-trip'),
    favoriteTripPick: belongsTo('favorite-trip'),
    favoriteTripDrop: belongsTo('favorite-trip'),
    callLineAddress: belongsTo('call-line', {
      inverse: 'address'
    }),
    lat: Ember.computed.alias('coord.lat'),
    lng: Ember.computed.alias('coord.lng'),
    coord: attr(),
    geoNode: Ember.computed.alias('coord.geoNode', {
      defaultValue: 0
    }),
    latlng: Ember.computed('coord', function () {
      return `${this.get('coord.lat')}/${this.get('coord.lng')}`;
    }),
    location: Ember.computed('locations', function () {
      return Ember.get(this, 'locations').objectAt(0);
    }),
    tomtomFormattedAddress: Ember.computed('streetNr', 'streetName', 'region', function () {
      let address = null;

      if (this.get('streetName') !== undefined) {
        address = {
          address: {
            freeformAddress: `${this.get('streetNr')} ${this.get('streetName')} ${this.get('region')}`
          }
        };
      }

      return address;
    }),
    address: Ember.computed('alias', 'streetNr', 'streetName', 'region', function () {
      let address = null;

      if (this.get('streetName') !== undefined) {
        address = {
          freeformAddress: `${this.get('alias')} ${this.get('streetNr')} ${this.get('streetName')} ${this.get('region')}`
        };
      }

      return address;
    }),
    fullAddress: Ember.computed('streetNr', 'streetName', 'locality', 'region', 'postalCode', 'alias', function () {
      const alias = Ember.get(this, 'alias');
      const streetNr = Ember.get(this, 'streetNr');
      const streetAddress = Ember.get(this, 'streetName');
      const locality = Ember.get(this, 'region');
      const region = Ember.get(this, 'region');
      const postalCode = Ember.get(this, 'postalCode');
      const address = [];

      if (alias) {
        address.push(alias + ',');
      }

      address.push(streetNr);
      address.push(streetAddress);
      const result = [address.join(' ')];
      result.push(locality);
      result.push(region);
      result.push(postalCode);
      return result.join(', ');
    }).readOnly()
  });

  _exports.default = _default;
});