define("adept-iq/pods/components/side-drawer/map-legend-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['map-legend-filter'],
    mapContext: Ember.inject.service(),
    isRoute: Ember.computed('layer', function () {
      return this.get('layer.id') === 'routes';
    }),
    layer: null,
    actions: {
      setVisibility(type, event) {
        this.get('mapContext').setLayerTypeVisibility(this.get('layer.id'), type.id, event.target.checked);
      }

    }
  });

  _exports.default = _default;
});