define("adept-iq/classes/polylines/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    animationTimer: null,
    id: null,
    record: null,
    points: null,
    opacity: 1.0,
    // these will usually be over-ridden by layer
    // see `app/pods/components/iq-widgets/map-widget/config/polyline.js`
    type: null,
    style: null,
    label: null,
    isActive: false,
    otpStatus: null,
    decorator: null,
    // these will be applied _after_ style defaults
    options: null
  });

  _exports.default = _default;
});