define("adept-iq/services/error-message", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    informationalTimeoutSeconds: 3000,
    informationalBackgroundColor: '#2874CB',
    informationalFontColor: 'white',
    warningTimeoutSeconds: 3000,
    warningBackgroundColor: '#f09240',
    warningFontColor: 'white',
    errorTimeoutSeconds: 3000,
    errorBackgroundColor: '#f05454',
    errorFontColor: 'white',
    emergencyTimeoutSeconds: 10000,
    emergencyReDisplaySeconds: 60000,
    emergencyBackgroundColor: '#f05454',
    emergencyFontColor: 'white',
    topMessage: Ember.computed('flashMessages.queue.[]', function () {
      const flashObject = this.get('flashMessages').peekFirst();

      if (flashObject) {
        // this is to start the timeout
        flashObject.set('sticky', false);
        flashObject.init();
      }

      return flashObject;
    }),

    setNotificationParameter(categoryName, configName, value) {
      const parameterName = _lodash.default.camelCase(`${categoryName} ${configName}`);

      this.set(parameterName, value);
    },

    generateEmergencyMessage(alert) {
      let message = '';
      const vehicleName = alert.get('vehicle.name');
      const driverName = alert.get('driver.name');
      const routeName = alert.get('iqRoute.dsRoute.name');

      if (routeName && vehicleName && driverName) {
        message = `Emergency message received from Driver ${driverName} on route ${routeName} in Vehicle ${vehicleName}`;
      } else if (vehicleName && driverName) {
        message = `Emergency message received from Driver ${driverName} in Vehicle ${vehicleName}`;
      } else if (vehicleName) {
        message = `Emergency message received from Vehicle ${vehicleName}`;
      } else if (driverName) {
        message = `Emergency message received from Driver ${driverName}`;
      } else {
        message = 'Emergency message received';
      }

      return message;
    },

    monitorForEmergency() {
      Ember.run.later(this, function () {
        const alerts = this.store.peekAll('alert');
        alerts.forEach(alert => {
          if (!alert.emergencyActive && alert.category === 'Emergency' && alert.state === 'active') {
            const message = this.generateEmergencyMessage(alert);
            this.pushEmergency({
              detail: message
            }, alert);
            alert.set('emergencyActive', true);
          }
        });
        this.monitorForEmergency();
      }, this.emergencyReDisplaySeconds);
    },

    init() {
      this._super(...arguments);

      this.store.query('cs-config-item', {
        category: 'config-Notification_Banners'
      }).then(configs => {
        configs.forEach(config => {
          const categoryName = config.get('configCategory.name');
          const configName = config.get('name');
          let value = config.get('value');

          if (config.type === 'Int') {
            const intValue = parseInt(value, 10) * 1000; // convert to milliseconds

            value = intValue <= 0 ? 10000 : intValue;
          }

          this.setNotificationParameter(categoryName, configName, value);
        });
      });
      this.monitorForEmergency();
    },

    pushEmergency(emergency, alert) {
      this.get('flashMessages').add({
        message: emergency.detail,
        type: 'emrgency',
        timeout: this.emergencyTimeoutSeconds,
        priority: 600,
        sticky: true,
        style: `background-color: ${this.emergencyBackgroundColor}; color: ${this.emergencyFontColor};`,

        onDestroy() {
          if (alert) {
            alert.set('emergencyActive', false);
          }
        }

      });
    },

    pushError(error) {
      this.get('flashMessages').danger(error.detail, {
        timeout: this.errorTimeoutSeconds,
        priority: 400,
        sticky: true,
        style: `background-color: ${this.errorBackgroundColor}; color: ${this.errorFontColor};`
      });
    },

    pushWarning(warning) {
      this.get('flashMessages').warning(warning.detail, {
        timeout: this.warningTimeoutSeconds,
        priority: 300,
        sticky: true,
        style: `background-color: ${this.warningBackgroundColor}; color: ${this.warningFontColor};`
      });
    },

    pushInfo(info) {
      this.get('flashMessages').info(info.detail, {
        timeout: this.informationalTimeoutSeconds,
        priority: 200,
        sticky: true,
        style: `background-color: ${this.informationalBackgroundColor}; color: ${this.informationalFontColor};`
      });
    },

    flushMessageQueues() {
      this.get('flashMessages').clearMessages();
    }

  });

  _exports.default = _default;
});