define("adept-iq/pods/components/form-components/radio/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cpBy1xSb",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"table\"],[11,\"class\",\"mt-3 radio-table\"],[9],[0,\"\\n    \"],[7,\"tr\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"      \"],[7,\"td\"],[9],[0,\"\\n\"],[4,\"radio-button\",null,[[\"value\",\"groupValue\",\"classNames\",\"changed\"],[[22,1,[]],[23,[\"tempValue\"]],\"radio-button-input\",\"valueChanged\"]],{\"statements\":[],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n       \"],[7,\"td\"],[9],[0,\"\\n          \"],[7,\"label\"],[11,\"class\",\"radio-button-1-label\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n        \"],[10],[0,\"\\n       \\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/radio/template.hbs"
    }
  });

  _exports.default = _default;
});