define("adept-iq/pods/components/iq-widgets/message-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/message-widget',
    rowComponent: 'table/rows/action-row',
    modelName: 'tm-canned-message',
    title: 'Messages',
    defaultSortId: 'name',
    defaultSortAsc: true,
    columns: [{
      index: 4,
      id: 'date',
      type: 'text',
      label: 'Date',
      valuePath: 'createdTime',
      editable: false,
      hidden: false,
      options: ['Received', 'Sent', 'Sending', 'Failed'],
      allowMultiple: false,
      defaultWidth: 60
    }, {
      id: 'time',
      index: 3,
      type: 'text',
      label: 'Time',
      valuePath: 'createdTime',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'status',
      index: 2,
      type: 'string',
      label: 'Status',
      valuePath: 'status',
      editable: false,
      hidden: false,
      allowMultiple: false
    }, {
      id: 'message',
      index: 5,
      type: 'text',
      label: 'Message',
      valuePath: 'body',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 145
    }, {
      id: 'vehicleId',
      index: 9,
      type: 'text',
      label: 'Vehicle Id',
      valuePath: 'vehicleId',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 40
    }, {
      id: 'lastName',
      index: 7,
      type: 'text',
      label: 'Driver Last Name',
      valuePath: 'driver.lastName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'firstName',
      index: 8,
      type: 'text',
      label: 'Driver First Name',
      valuePath: 'driver.firstName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'driverId',
      index: 6,
      type: 'uuid',
      label: 'Driver ID',
      valuePath: 'driver.id',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 40
    }, {
      id: 'routeName',
      index: 10,
      type: 'text',
      label: 'Route Name',
      valuePath: 'route.name',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 40
    }, {
      index: 1,
      id: 'read',
      type: 'text',
      label: 'Read',
      valuePath: 'readString',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 70
    }]
  };
  _exports.default = _default;
});