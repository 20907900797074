define("adept-iq/pods/maximized/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    maximizer: Ember.inject.service(),
    actions: {
      didTransition()
      /* transition */
      {
        const widget = this.get('maximizer.maximizedWidget');

        if (widget === null) {
          this.transitionTo('index');
        }
      }

    }
  });

  _exports.default = _default;
});