define("adept-iq/services/textmessage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    textMessageData() {
      let textMessageData = {
        "data": [{
          "type": "textMessage",
          "id": "string",
          "attributes": {
            "subject": "string",
            "body": "string",
            "sender": {
              "type": "dispatcher",
              "id": "string"
            },
            "recipients": [{
              "type": "dispatcher",
              "id": "string",
              "deliveredAt": ""
            }],
            "deliveryTime": "",
            "conversation": {
              "type": "onTrip",
              "id": "string"
            }
          }
        }]
      };
      return textMessageData;
    },

    tripScheduleData() {
      let tripScheduleData = {
        "data": {
          "id": "",
          "type": "trip",
          "attributes": {
            "destination": "dispatch"
          }
        }
      };
      return tripScheduleData;
    }

  });

  _exports.default = _default;
});