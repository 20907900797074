define("adept-iq/models/rider-eligibility", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    from: attr('string'),
    to: attr('string'),
    rider: belongsTo('rider'),
    eligibilityType: belongsTo('rms-eligibility-type'),
    formattedFrom: Ember.computed('from', function () {
      const momentFrom = (0, _moment.default)(Ember.get(this, 'from'));
      return momentFrom.format('LL');
    }),
    formattedTo: Ember.computed('to', function () {
      const momentFrom = (0, _moment.default)(Ember.get(this, 'to'));
      return momentFrom.format('LL');
    })
  });

  _exports.default = _default;
});