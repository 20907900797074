define("adept-iq/models/alert", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    generatedAt: attr('date'),
    alert: attr('string'),
    category: attr('string'),
    vehicles: attr('vehicle', {
      inverse: null
    }),
    drivers: attr('driver', {
      inverse: null
    }),
    routes: attr('route', {
      inverse: null
    })
  });

  _exports.default = _default;
});