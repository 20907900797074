define("adept-iq/models/tm-related-id", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/agenda.js */
  var _default = _model.default.extend({
    periodType: (0, _attr.default)('string'),
    items: (0, _relationships.hasMany)('item'),
    subscription: (0, _relationships.belongsTo)('tm-subscription')
  });

  _exports.default = _default;
});