define("adept-iq/serializers/cs-config-item", ["exports", "adept-iq/serializers/-cs-configService"], function (_exports, _csConfigService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const JSON_REGEX = /^\s*[{[]/;
  const OPTIONAL_ATTRS = ['description', 'unit', 'type'];
  const CATEGORY_DELIMITER = '-';
  const BOOLEAN_TYPE = 'Boolean';
  const TRUE_STRING = 'Yes';
  const FALSE_STRING = 'No';
  const logger = {
    warn: console.warn // eslint-disable-line no-console

  };

  var _default = _csConfigService.default.extend({
    attrs: {
      // do not list `type` or `value` here; these are need in `serialize()`
      category: {
        serialize: false
      },
      configCategory: {
        serialize: false
      }
    },

    serialize()
    /* snapshot, options */
    {
      let serialized = this._super(...arguments);

      OPTIONAL_ATTRS.forEach(attr => {
        if (Ember.isEmpty(serialized.data.attributes[attr])) {
          delete serialized.data.attributes[attr];
        }
      });
      let {
        type,
        value
      } = serialized.data.attributes;

      if (type === BOOLEAN_TYPE) {
        serialized.data.attributes.value = value ? TRUE_STRING : FALSE_STRING;
      } else if (Ember.typeOf(value) !== 'string') {
        // values must always be strings
        serialized.data.attributes.value = JSON.stringify(value);
      } // don't need this anymore


      delete serialized.data.attributes.type; // API doesn't accept relationships hash at all

      delete serialized.data.relationships;
      return serialized;
    },

    normalizeArrayResponse()
    /* store, primaryModelClass, payload, id, requestType */
    {
      let normalized = this._super(...arguments);

      normalized.included = normalized.included || [];
      normalized.data.forEach(itemPayload => {
        let categoryPayloads = this._buildCategoryPayloads(itemPayload); // only add new payloads


        categoryPayloads.forEach(categoryPayload => {
          if (normalized.included.findBy('id', categoryPayload.id)) return;
          normalized.included.push(categoryPayload);
        });
        itemPayload.relationships = itemPayload.relationships || {};
        itemPayload.relationships.configCategory = {
          data: {
            id: categoryPayloads[categoryPayloads.length - 1].id,
            type: 'cs-config-category'
          }
        };
      });
      return normalized;
    },

    normalizeSingleResponse()
    /* store, primaryModelClass, payload, id, requestType */
    {
      let normalized = this._super(...arguments);

      normalized.included = normalized.included || [];

      let categoryPayloads = this._buildCategoryPayloads(normalized.data); // only add new payloads


      categoryPayloads.forEach(categoryPayload => {
        if (normalized.included.findBy('id', categoryPayload.id)) return;
        normalized.included.push(categoryPayload);
      });
      normalized.data.relationships = normalized.data.relationships || {};
      normalized.data.relationships.configCategory = {
        data: {
          id: categoryPayloads[categoryPayloads.length - 1].id,
          type: 'cs-config-category'
        }
      };
      return normalized;
    },

    normalize() {
      let normalized = this._super(...arguments); // extract category and name from id


      let {
        id
      } = normalized.data;
      let segments = id.split('/');
      let name = segments[segments.length - 1];
      let category = segments.slice(0, segments.length - 1).join('/');
      normalized.data.attributes.name = name;
      normalized.data.attributes.category = category;
      let {
        type,
        value
      } = normalized.data.attributes;

      if (Ember.typeOf(value) === 'string' && JSON_REGEX.test(value)) {
        try {
          normalized.data.attributes.value = JSON.parse(value);
        } catch (err) {
          logger.warn(`failed to parse JSON-like value`, value);
        }
      } // booleans are encoded as "Yes" or "No"


      if (type === BOOLEAN_TYPE) {
        normalized.data.attributes.value = value === TRUE_STRING;
      }

      return normalized;
    },

    _buildCategoryPayloads(payload) {
      let segments = payload.attributes.category.split(CATEGORY_DELIMITER);
      return segments.map((segment, i) => {
        let payload = {
          id: segments.slice(0, i + 1).join(CATEGORY_DELIMITER),
          type: 'cs-config-category',
          attributes: {
            name: segment,
            depth: i
          },
          relationships: {}
        };

        if (i > 0) {
          payload.relationships.parent = {
            data: {
              id: segments.slice(0, i).join(CATEGORY_DELIMITER),
              type: 'cs-config-category'
            }
          };
        }

        return payload;
      });
    }

  });

  _exports.default = _default;
});