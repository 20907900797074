define("adept-iq/pods/components/iq-widgets/report-widget1/component", ["exports", "adept-iq/config/environment", "lodash"], function (_exports, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['report-widget']
  });

  _exports.default = _default;
});