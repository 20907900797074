define("adept-iq/pods/components/generic-widgets/column-widget/utils/compute-ordered-ids", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * Returns a sorted list of ids from a partially indexed collection.
   *
   * @param {Array} items
   * @param {string} idKey
   * @param {string} indexKey
   */
  function _default(items, idKey = 'id', indexKey = 'index') {
    const orderedIds = [];
    items.forEach(item => {
      const id = item[idKey];
      const index = item[indexKey]; // no index specified; pass

      if (!Ember.isPresent(index)) return;

      if (!Number.isInteger(index)) {
        /*eslint-disable */
        throw `item with id='${id}' has invalid index '${index}'`;
        /*eslint-enable */
      }

      if (Ember.isPresent(orderedIds[index])) {
        /*eslint-disable */
        throw `item with id='${id}' has duplicate index '${index}'`;
        /*eslint-enable */
      }

      orderedIds[index] = id;
    }); // interleave other items

    let i = 0;
    items.reject(item => orderedIds.includes(item[idKey])).forEach(item => {
      while (Ember.isPresent(orderedIds[i])) i++;

      orderedIds[i] = item[idKey];
    }); // remove any spaces that are left

    return orderedIds.compact();
  }
});