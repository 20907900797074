define("adept-iq/initializers/unknownproperty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* appInstance */
  {
    Ember.ObjectProxy.reopen({
      unknownProperty(key) {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }

        let content = this.content;

        if (content) {
          return Ember.get(content, key);
        }
      },

      willWatchProperty()
      /*key*/
      {
        if (this.isDestroying || this.isDestroyed || this.content != null && (this.content.isDestroying || this.content.isDestroyed)) {
          return;
        }

        return this._super(arguments);
      }

    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});