define("adept-iq/pods/components/side-drawer/add-widget/component", ["exports", "adept-iq/config/widget-groups", "adept-iq/utils/widget-placement", "lodash"], function (_exports, _widgetGroups, _widgetPlacement, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: '',
    title: 'Add Widgets',
    widgetSections: null,

    init() {
      this._super(...arguments);

      this.configureWidgetsOnPermission();
    },

    configureWidgetsOnPermission() {
      this.set('widgetSections', _lodash.default.cloneDeep(_widgetGroups.default)); //console.log('widgetGroups ',widgetGroups);

      let widgetSections = this.get('widgetSections');
      const configPermissions = this.get('workspace.configPermissions');
      widgetSections.forEach(widgetSection => {
        const widgetTypesAllowed = []; //console.log('widgetSection.widgetTypes ',widgetSection.widgetTypes);

        widgetSection.widgetTypes.forEach(widgetType => {
          const configPermission = configPermissions[widgetType.id]; //TODO when permission implemented
          //if (!isNone(configPermission) && configPermission.show) {

          widgetTypesAllowed.push(widgetType); //}
        });
        widgetSection.widgetTypes.splice(0, widgetSection.widgetTypes.length);
        widgetTypesAllowed.forEach(widgetType => {
          widgetSection.widgetTypes.push(widgetType);
        });

        if (widgetSection.widgetTypes.length <= 0) {
          widgetSections = _lodash.default.reject(widgetSections, function (currWidgetSection) {
            return currWidgetSection.id === widgetSection.id;
          });
          this.set('widgetSections', widgetSections);
        }
      });
    },

    actions: {
      addWidget(widgetType) {
        // TODO: move this logic into dashboard model after grid settings have
        // have been migrated
        const dashboard = this.get('workspace.dashboard');
        const gridWidth = this.get('workspace.gridWidth');
        const dashboardModel = this.get('workspace.dashboardModel');
        const {
          minWidth,
          minHeight,
          defaultWidth,
          defaultHeight
        } = widgetType;
        const locations = (0, _widgetPlacement.computeWidgetPlacements)(dashboard, gridWidth, minWidth, minHeight); // might want to rank locations rather than taking the first available

        const {
          x,
          y,
          maxWidth,
          maxHeight
        } = locations[0];
        dashboard.addWidget({
          typeId: widgetType.id,
          width: Math.min(defaultWidth, maxWidth),
          height: Math.min(defaultHeight, maxHeight),
          x,
          y
        }); // adding a widget will be committed to the dashboard Model that way for maximizing or minimizing the widget will be kept

        dashboardModel.commitInstance(dashboard);
      },

      onShrinkClick() {
        this.onShrinkClick();
      },

      onCloseClick() {
        this.onCloseClick();
      }

    }
  });

  _exports.default = _default;
});