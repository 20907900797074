define("adept-iq/pods/components/generic-widgets/column-widget/classes/data-join", ["exports", "adept-iq/utils/data-join", "adept-iq/pods/components/generic-widgets/column-widget/classes/checkbox-row"], function (_exports, _dataJoin, _checkboxRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // if only making a few modificatons, it's faster to do them individually;
  // at some point, its faster to replace all in one go
  const FULL_REPLACE_THRESHOLD = 0;

  var _default = _dataJoin.default.extend({
    table: null,
    activeContext: null,

    initialize(record) {
      const row = new _checkboxRow.default(record);
      row.set('activeContext', this.get('activeContext'));
      return row;
    },

    join()
    /* collection */
    {
      this._super(...arguments);

      const rows = this.get('nodes');
      const tableRows = this.get('table.rows');

      if (rows.length === 0) {
        tableRows.clear();
        return;
      }

      if (tableRows.length === 0) {
        tableRows.pushObjects(rows);
        return;
      }

      const rowIds = rows.mapBy('id');
      const tableRowIds = tableRows.mapBy('id');

      const isSubSequence = this._isSubSequence(rowIds, tableRowIds);

      const isSuperSequence = this._isSubSequence(tableRowIds, rowIds);

      const sizeDiff = Math.abs(tableRows.length, rows.length); // these optimizations make single insertions and deletions faster

      if (isSubSequence && isSuperSequence) {
        // rows sets are equal; nothing to do
        return;
      } else if (isSubSequence && sizeDiff < FULL_REPLACE_THRESHOLD) {
        rows.forEach((row, i) => {
          while (tableRows[i].get('id') !== row.get('id')) {
            tableRows.removeAt(i);
          }
        });
      } else if (isSuperSequence && sizeDiff < FULL_REPLACE_THRESHOLD) {
        rows.forEach((row, i) => {
          if (i >= tableRows.length || tableRows[i].get('id') !== row.get('id')) {
            tableRows.insertAt(i, row);
          }
        });
      } else {
        // update by full replacement
        tableRows.clear();
        tableRows.pushObjects(rows);
      }
    },

    exit(row) {
      row.setProperties({
        isSelected: false,
        isLastChecked: false
      });
    },

    release(row) {
      row.destroy();
    },

    _isSubSequence(arr1, arr2) {
      let i = 0;
      let j = 0;

      while (i < arr1.length && j < arr2.length) {
        const a = arr1[i];
        const b = arr2[j];

        if (a === b) {
          i += 1;
          j += 1;
        } else {
          j += 1;
        }
      }

      return i === arr1.length;
    }

  });

  _exports.default = _default;
});