define("adept-iq/pods/components/iq-widgets/drivers-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/drivers-widget/config", "adept-iq/utils/populate-new-text-message", "lodash"], function (_exports, _component, _config, _populateNewTextMessage, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['drivers-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    textmessage: Ember.inject.service(),
    editComponent: 'iq-widgets/drivers-form-widget',
    // need to sub-class this in specific widget
    config: _config.default,
    defaultIncludes: INCLUDES,

    // TODO: saveRecordFunction shall be moved to a service later on
    // This save is specific to saving drivers and all related models.
    saveRecordFunction(records) {
      function saveDeleteAllObjects(objects) {
        const deleteObjects = _lodash.default.difference(objects.content.canonicalState, objects.content.currentState); // delete objects that have been removed from the array


        return Promise.all(deleteObjects.map(deleteObject => {
          deleteObject._record.deleteRecord();

          return deleteObject.save();
        })).then(() => {
          return Promise.all(objects.map(object => object.save()));
        });
      }

      this.set('editModal.errors', []);
      return new Promise((resolve, reject) => {
        records.forEach(record => {
          record.save().then(() => {
            saveDeleteAllObjects(record.get('availability')).then(() => {
              record.set('isForceDirty', false);
              this.get('activeContext').refreshTableContent(this.get('modelName'));
            });
          }).catch(e => {
            if (e.errors[0].status === '400' || e.errors[0].status === '409') {
              this.get('editModal.errors').pushObject('Invalid Driver Id');
            } else {
              reject(e);
            }
          });
        });
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({});
      this.set('singleActions', [{
        name: 'Send Message',
        action: driver => {
          this.sendMessageToDrivers([driver]);
        }
      }]);
      this.set('bulkActions', [{
        name: 'Send Message',
        action: drivers => {
          this.sendMessageToDrivers(drivers);
        }
      }]);
    },

    sendMessageToDrivers(drivers) {
      let me = this;
      console.log('textMessage', me.get('textmessage'), 'this textmessage ', this.get('textmessage'));
      let draftMessages = drivers.map(driver => {
        let newTestMessage = (0, _populateNewTextMessage.default)(me.get('textmessage'), this.get('store'), null, driver, null);
        return newTestMessage;
      });
      this.get('workspace').pushState('addNewMessage', {
        draftMessages
      });
    }

  });

  _exports.default = _default;
});