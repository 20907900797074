define("adept-iq/models/tm-booking", ["exports", "ember-data/model", "ember-data/attr", "ember-data", "ember-data/relationships", "adept-iq/mixins/trip-predefined-notes", "adept-iq/mixins/trip-attributes"], function (_exports, _model, _attr, _emberData, _relationships, _tripPredefinedNotes, _tripAttributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const WOM_OPTIONS = {
    first: 'first',
    second: 'second',
    third: 'third',
    fourth: 'fourth',
    last: 'last'
  };

  var _default = _model.default.extend(_emberData.default.EmbeddedRecordsMixin, _tripPredefinedNotes.default, _tripAttributes.default, {
    /*trackingId: attr('string'),
    externaltripId: attr('string'),
    tripId: attr('string'),
    routeId: attr('string'),
    requestedProvider: belongsTo('tm-requested-provider', { async: false, embedded: 'always'}),
    source: belongsTo('source', { async: false, embedded: 'always'}),
    status: attr('string'),
    passengers: hasMany('tm-passenger', { async: false, embedded: 'always'}),
    stopPoints: hasMany('tm-stop-point', { async: false, embedded: 'always'}),
    price: belongsTo('tm-price', { async: false, embedded: 'always'}),
    prices: hasMany('tm-price', { async: false, embedded: 'always'}),
    relatedIds: hasMany('tm-elated-id', { async: false, embedded: 'always'}),
    subscription: belongsTo('tm-subscription', { async: false, embedded: 'always'})*/
    trackingId: (0, _attr.default)('string'),
    tripId: (0, _attr.default)('string'),
    routeId: (0, _attr.default)('string'),
    requestedProvider: (0, _attr.default)(),
    source: (0, _attr.default)(),
    status: (0, _attr.default)('string'),
    passengers: (0, _attr.default)(),
    stopPoints: (0, _attr.default)(),
    price: (0, _attr.default)(),
    prices: (0, _attr.default)(),
    relatedIds: (0, _attr.default)(),
    subscriptions: (0, _attr.default)(),
    subscription: (0, _relationships.belongsTo)('subscription'),
    passengerType: (0, _attr.default)(),
    dispatchType: (0, _attr.default)(),
    tripType: (0, _attr.default)(),
    //Supporting models
    account: (0, _attr.default)(),
    driverNotes: (0, _attr.default)(),
    operatorNotes: (0, _attr.default)(),
    acctCardNr: (0, _attr.default)(),
    editmodelcontext: (0, _attr.default)({
      serialize: false
    }),
    routeExec: (0, _attr.default)(),
    customerReference: (0, _attr.default)(),
    fixedAmount: (0, _attr.default)(),
    isAccountPickups: Ember.computed('editmodelcontext.accountTrips', function () {
      if (!Ember.isEmpty(this.get('editmodelcontext.accountTrips'))) {
        const length = this.get('editmodelcontext.accountTrips').length;

        if (length >= 1) {
          return false;
        }

        return true;
      } else {
        return true;
      }
    }),
    isAccountFixedPriceTrips: Ember.computed('editmodelcontext.accountFixedPriceTrips', function () {
      if (!Ember.isEmpty(this.get('editmodelcontext.accountFixedPriceTrips'))) {
        const length = this.get('editmodelcontext.accountFixedPriceTrips').length;

        if (length >= 1) {
          return false;
        }

        return true;
      } else {
        return true;
      }
    }),
    selectedDOWs: Ember.computed('subscriptions.firstObject', function () {
      const selectedDOWArray = [];
      if (this.get('subscriptions.firstObject.sunday')) selectedDOWArray.push('sunday');
      if (this.get('subscriptions.firstObject.monday')) selectedDOWArray.push('monday');
      if (this.get('subscriptions.firstObject.tuesday')) selectedDOWArray.push('tuesday');
      if (this.get('subscriptions.firstObject.wednesday')) selectedDOWArray.push('wednesday');
      if (this.get('subscriptions.firstObject.thursday')) selectedDOWArray.push('thursday');
      if (this.get('subscriptions.firstObject.friday')) selectedDOWArray.push('friday');
      if (this.get('subscriptions.firstObject.saturday')) selectedDOWArray.push('saturday');
      return selectedDOWArray;
    }),
    isEveryDay: Ember.computed('subscriptions.firstObject.', function () {
      return this.get('subscriptions.firstObject.sunday') && this.get('subscriptions.firstObject.monday') && this.get('subscriptions.firstObject.tuesday') && this.get('subscriptions.firstObject.wednesday') && this.get('subscriptions.firstObject.thursday') && this.get('subscriptions.firstObject.friday') && this.get('subscriptions.firstObject.saturday');
    }),
    selectedWOM: Ember.computed('weekOfMonth', {
      get()
      /* key */
      {
        const weekOfMonth = this.get('weekOfMonth');

        switch (weekOfMonth) {
          case -1:
            return WOM_OPTIONS.last;

          case 1:
            return WOM_OPTIONS.first;

          case 2:
            return WOM_OPTIONS.second;

          case 3:
            return WOM_OPTIONS.third;

          case 4:
            return WOM_OPTIONS.fourth;

          default:
            return null;
        }
      },

      set(_, value) {
        let weekOfMonth = null;

        switch (value) {
          case WOM_OPTIONS.last:
            weekOfMonth = -1;
            break;

          case WOM_OPTIONS.first:
            weekOfMonth = 1;
            break;

          case WOM_OPTIONS.second:
            weekOfMonth = 2;
            break;

          case WOM_OPTIONS.third:
            weekOfMonth = 3;
            break;

          case WOM_OPTIONS.fourth:
            weekOfMonth = 4;
            break;

          default:
            weekOfMonth = null;
        }

        this.set('weekOfMonth', weekOfMonth);
        return value;
      }

    }),
    subscriptionStartDate: Ember.computed('subscription.startDate', {
      get()
      /* key */
      {
        return this.get('subscription.startDate');
      },

      set(_, value) {
        this.set('subscription.startDate', value);
        return value;
      }

    }),
    subscriptionEndDate: Ember.computed('subscription.endDate', {
      get()
      /* key */
      {
        return this.get('subscription.endDate');
      },

      set(_, value) {
        this.set('subscription.endDate', value);
        return value;
      }

    }),
    subscriptionMaxOccurences: Ember.computed('subscription.maximumOccurrences', {
      get()
      /* key */
      {
        return this.get('subscription.maximumOccurrences');
      },

      set(_, value) {
        this.set('subscription.maximumOccurrences', value);
        return value;
      }

    }),
    exclusionStartDate: Ember.computed('subscription.exclusions.firstObject.startDate', {
      get()
      /* key */
      {
        return this.get('subscription.exclusions.firstObject.startDate');
      },

      set(_, value) {
        this.set('subscription.exclusions.firstObject.startDate', value);
        return value;
      }

    }),
    exclusionEndDate: Ember.computed('subscription.exclusions.firstObject.endDate', {
      get()
      /* key */
      {
        return this.get('subscription.exclusions.firstObject.endDate');
      },

      set(_, value) {
        this.set('subscription.exclusions.firstObject.endDate', value);
        return value;
      }

    })
  });

  _exports.default = _default;
});