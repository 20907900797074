define("adept-iq/pods/components/iq-widgets/passengers-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/passengers-widget',
    rowComponent: 'table/rows/otp-formatted-row',
    modelName: 'rider',
    defaultSortId: 'fullPhoneNumber',
    defaultSortAsc: false,
    accessKey: 'p',
    title: 'Passengers',
    columns: [{
      id: 'id',
      index: 0,
      type: 'text',
      label: 'Id',
      valuePath: 'id',
      editable: false,
      hidden: false
    }, {
      id: 'fullPhoneNumber',
      index: 1,
      type: 'text',
      label: 'Phone Number',
      valuePath: 'phoneNr',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 90
    }, {
      id: 'name',
      index: 2,
      type: 'text',
      label: 'Name',
      valuePath: 'name',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'account',
      index: 3,
      type: 'enum',
      label: 'Account',
      valuePath: 'formattedAccount',
      cellDesc: 'Account',
      editable: false,
      hidden: false
    }, {
      id: 'formattedPickupAddresses',
      index: 4,
      type: 'enum-address',
      label: 'Addresses',
      valuePath: 'formattedPickupAddresses',
      cellDesc: 'Addresses',
      searchable: true,
      editable: false,
      hidden: false,
      defaultWidth: 120
    }, {
      id: 'driverNotes',
      index: 5,
      type: 'text',
      label: 'Driver Notes',
      valuePath: 'driverNotes',
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'operatorNotes',
      index: 6,
      type: 'text',
      label: 'Operator Notes',
      valuePath: 'operatorNotes',
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'email',
      index: 7,
      type: 'text',
      label: 'EMail',
      valuePath: 'email',
      editable: false,
      hidden: true,
      defaultWidth: 60
    }]
  };
  _exports.default = _default;
});