define("adept-iq/models/location", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    alias: attr('string', {
      defaultValue: ''
    }),
    description: attr('string'),
    streetNr: attr('string'),
    streetName: attr('string'),
    room: attr('string'),
    city: attr('string'),
    county: attr('string'),
    state: attr('string'),
    postalCode: attr('string'),
    country: attr('string'),
    countryAbbreviation: attr('string'),
    notes: attr('string'),
    address: attr('nested'),
    coordinates: attr('nested'),
    pointOfInterest: attr('nested'),
    geoNode: Ember.computed.alias('coord.geoNode', {
      defaultValue: 0
    }),
    locality: attr('string'),
    region: attr('string'),
    subRegion: attr('string'),
    postOfficeBoxNumber: attr('string'),
    type: attr('string'),
    stop: hasMany('stop'),
    legOrigin: belongsTo('leg', {
      inverse: 'origin'
    }),
    legDestination: belongsTo('leg', {
      inverse: 'destination'
    }),
    lat: Ember.computed.alias('coord.lat'),
    lng: Ember.computed.alias('coord.lng'),
    coord: attr(),
    latlng: Ember.computed('coord', function () {
      if (this.get('coord.lat') !== undefined && this.get('coord.lng') !== undefined) {
        return `${this.get('coord.lat')}/${this.get('coord.lng')}`;
      }

      return '';
    }),
    addresses: hasMany('address'),
    tomtomFormattedAddress: Ember.computed('streetNr', 'streetName', 'locality', function () {
      let address = null;

      if (this.get('streetName') !== undefined) {
        address = {
          address: {
            freeformAddress: `${this.get('streetNr')} ${this.get('streetName')} ${this.get('region')}`
          }
        };
      }

      return address;
    }),
    fullAddress: Ember.computed('address', 'pointOfInterest', function () {
      const alias = Ember.get(this, 'pointOfInterest.alias');
      const address = Ember.get(this, 'address');
      const streetAddress = [];

      if (address) {
        if (alias) {
          streetAddress.push(alias + ',');
        }

        streetAddress.push(address.streetNr);
        streetAddress.push(address.streetName);
        const result = [streetAddress.join(' ')];
        result.push(address.region); // result.push(address.county);

        result.push(address.stateAbbreviation);
        result.push(address.postalCode);
        return result.join(', ');
      }

      return '';
    }).readOnly(),
    streetAddress: Ember.computed('streetNr', 'streetName', 'alias', function () {
      const alias = Ember.get(this, 'alias');
      const streetAddress = [];

      if (alias) {
        streetAddress.push(alias + ',');
      }

      if (this.get('streetNr')) {
        streetAddress.push(this.get('streetNr') + ',');
      }

      if (this.get('streetName')) {
        streetAddress.push(this.get('streetName') + ',');
      }

      const result = [streetAddress.join(' ')];
      return result;
    })
  });

  _exports.default = _default;
});