define("adept-iq/serializers/-dispatch", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    JSONAPISerializer
  } = _emberData.default;

  var _default = JSONAPISerializer.extend({
    keyForAttribute(key) {
      return key;
    },

    keyForRelationship(key) {
      return key;
    }

  });

  _exports.default = _default;
});