define("adept-iq/models/tm-common-operational-attribute", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import { belongsTo, hasMany } from 'ember-data/relationships';
  var _default = _model.default.extend({
    provider: (0, _attr.default)(),
    name: (0, _attr.default)(),
    description: (0, _attr.default)(),
    type: (0, _attr.default)(),
    displayName: (0, _attr.default)(),
    datum: (0, _attr.default)(),
    notes: (0, _attr.default)()
  });

  _exports.default = _default;
});