define("adept-iq/pods/components/iq-widgets/map-widget/config/polyline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    solid: {
      dashArray: null,
      color: '#555e63',
      opacity: 1
    },
    solidLate: {
      dashArray: null,
      // matches 'text-danger' in CSS
      color: '#d13730',
      opacity: 1
    },
    solidDanger: {
      dashArray: null,
      color: '#f09240',
      opacity: 1
    },
    dotted: {
      dashArray: '0 6',
      color: '#555e63',
      opacity: 1
    },
    dottedLate: {
      dashArray: '0 6',
      color: '#d13730',
      opacity: 1
    },
    dottedDanger: {
      dashArray: '0 6',
      color: '#f09240',
      opacity: 1
    }
  };
  _exports.default = _default;
});