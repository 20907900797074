define("adept-iq/models/favorite-pickup", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    amount: attr(),
    referenceCode: attr(),
    pickAddress: (0, _relationships.belongsTo)('address', {
      inverse: 'favoritePick'
    })
  });

  _exports.default = _default;
});