define("adept-iq/models/driver", ["exports", "ember-data", "adept-iq/mixins/otp-label", "adept-iq/mixins/status-value"], function (_exports, _emberData, _otpLabel, _statusValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    hasMany,
    attr
  } = _emberData.default;

  var _default = Model.extend(_statusValue.default, {
    badgeNr: attr('number'),
    name: attr('string'),
    firstName: attr('string'),
    middleName: attr('string'),
    lastName: attr('string'),
    attributes: attr(),
    travelTimeAdjustment: attr('number'),
    suspensionType: attr('string'),
    signOnDTM: attr('date'),
    signOffDTM: attr('date'),
    phoneNumber: attr('string'),
    active: attr('boolean'),
    vehicle: Ember.computed('driverState.content.vehicle', 'drivedStatus', function () {
      let driverState = this.get('driverState.content');

      if (driverState) {
        if (this.get('driverStatus') === 'Sign Off') {
          return null;
        }

        return driverState.get('vehicle');
      }

      return null;
    }),
    attributesList: Ember.computed('attributes.[]', function () {
      if (!Ember.isEmpty(this.get('attributes'))) {
        return Array.prototype.map.call(this.get('attributes'), attribute => attribute.type).toString();
      } else {
        return [];
      }
    }),
    suspensionFlag: Ember.computed('suspensionType', function () {
      if (this.get('suspensionType')) {
        return true;
      }

      return false;
    }),
    schedule: belongsTo(),
    provider: belongsTo(),
    driverState: belongsTo('driver-state', {
      inverse: 'driver'
    }),
    status: attr('string'),
    trip: belongsTo('trip'),
    drivedStatus: Ember.computed('status', 'driverState.content.status', function () {
      let driverState = this.get('driverState.content');

      if (driverState) {
        return driverState.get('status');
      }

      return this.get('status');
    })
  });

  _exports.default = _default;
});