define("adept-iq/models/tm-driver", ["exports", "ember-data", "ember-data/relationships", "ember-data/attr"], function (_exports, _emberData, _relationships, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    status: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    middleName: (0, _attr.default)('string'),
    badgeNr: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    // these are non-JSON-API compliant object attrs that will need to be
    // normalized before consumption
    provider: (0, _attr.default)('object'),
    phone1: (0, _attr.default)('object'),
    phone2: (0, _attr.default)('object'),
    address: (0, _attr.default)('object'),
    attributes: (0, _attr.default)('object'),
    restrictions: (0, _attr.default)('object'),
    licenses: (0, _attr.default)('object'),
    iqDriver: (0, _relationships.belongsTo)('iq-driver', {
      inverse: 'tmDrivers'
    }),
    cannedMessages: (0, _relationships.hasMany)('tm-canned-message')
  });

  _exports.default = _default;
});