define("adept-iq/mixins/zone-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    label: Ember.computed('zonestat', 'name', function () {
      ////console.log('zone-label ');
      let zonestat = this.get('zonestat');
      let label = ''; ////console.log('zonestat asap ',zonestat.get('asap'));

      if (zonestat) {
        label = '<table  style="position:relative;"><tr></tr><tr>' + '<td rowspan="2" style="font-weight: bold; font-size: 2em;text-align: center;opacity:0.4;">' + this.get('name') + '</td>' +
        /* '<td><img src="/zones-icons/icon_asap.png"/></td>'+
         '<td>'+zonestat.get('asap')+'</td>'+
         '</tr><tr>'+
         '<td><img src="/zones-icons/icon_auction.png"/></td>'+
         '<td>'+zonestat.get('auction')+'</td> '+*/
        '</tr></table>';
      } else {
        label = '<table  style="position:relative;"><tr></tr><tr>' + '<td rowspan="2" style="font-weight: bold; font-size: 2em;text-align: center;opacity:0.4;">' + this.get('name') + '</td>' +
        /*'<td><img src="/zones-icons/icon_asap.png"/></td>'+
        '<td>0</td>'+
        '</tr><tr>'+
        '<td><img src="/zones-icons/icon_auction.png"/></td>'+
        '<td>0</td>'+*/
        '</tr></table>';
      }

      return label;
    })
  });

  _exports.default = _default;
});