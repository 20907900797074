define("adept-iq/pods/components/iq-widgets/edit-settings-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/edit-settings-form-widget/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    subsections: false,

    validateRecords() {
      let valid = true;
      this.set('editModal.errors', []);
      return valid;
    },

    sideDrawerTitle: Ember.computed('editableRecords', 'editableSections', function () {
      const sections = Ember.copy(this.get('editableSections'), true);
      const target = this.get('editableRecords')[0];
      const displaySection = sections.find(f => {
        const title = f.title;
        const type = target.get('type');
        return title === type;
      });
      return displaySection.title;
    }),
    subheading: Ember.computed('editableRecords', 'editableSections', function () {
      const sections = Ember.copy(this.get('editableSections'), true);
      const target = this.get('editableRecords')[0];
      const displaySection = sections.find(f => {
        const title = f.title;
        const type = target.get('type');
        return title === type;
      });
      return displaySection.description;
    }),
    filteredSections: Ember.computed('editableRecords', 'editableSections', function () {
      const sections = Ember.copy(this.get('editableSections'), true);
      const target = this.get('editableRecords')[0];
      const displaySection = sections.find(f => {
        const title = f.title;
        const type = target.get('type');
        return title === type;
      });

      if (displaySection) {
        if (displaySection.subsections) {
          this.set('subsections', true);
        }

        if (displaySection.description) {
          this.set('subheading', displaySection.description);
        }

        if (displaySection.title) {
          this.set('title', displaySection.title);
        }
      }

      return displaySection.subsections || [displaySection];
    }),
    // TODO: WHEN YOU CLICK ADD, ADD ANOTHER THING TO AN ARRAY ENTRY ON THE FORM
    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      addItem() {},

      onCellValueAppend(record, valuePath, modelName) {
        // todo: this should add a new eligibility, but where?
        return this.get('service').pushRecordValue(record, valuePath, modelName);
      },

      onUndoClick() {
        let lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          let records = this.get('editableRecords');
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(({
            record,
            properties
          }) => {
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      onApplyClick() {
        if (this.validateRecords()) {
          this.get('service').apply();
        }
      }

    }
  });

  _exports.default = _default;
});