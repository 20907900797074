define("adept-iq/services/booking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    tripData() {
      let tripData = {
        "data": {
          "type": "trip",
          "attributes": {
            "trackingId": "12",
            "requestedProvider": {
              "id": "2",
              "type": "dedicated"
            },
            "status": "active",
            "source": {
              "type": "webApp",
              "id": "56e"
            },
            "passengers": [{
              "id": "1",
              "name": "",
              "type": "client",
              "lastName": "",
              "firstName": "",
              "middleName": "",
              "callout": "call",
              "phone1": {
                "number": "",
                "areaCode": "",
                "extension": "",
                "type": "fixed"
              },
              "phone2": {
                "number": "",
                "areaCode": "",
                "extension": "",
                "type": "fixed"
              },
              "email": "",
              "bookingNote": "",
              "requiredAttributes": []
            }],
            "stopPoints": [{
              "index": 1,
              "address": {
                "formattedAddress": "",
                "streetName": "",
                "streetNr": "",
                "unitNr": null,
                "region": "",
                "postCode": "",
                "city": "",
                "state": "",
                "description": "",
                "coord": {
                  "lat": 0,
                  "lng": 0
                }
              },
              "directions": "",
              "passengerEvents": [{
                "type": "pickup",
                "passengerId": "1",
                "time": "",
                "anchor": true,
                "driverNote": "",
                "pickupWindow": {
                  "start": "",
                  "end": ""
                }
              }]
            }, {
              "index": 2,
              "address": {
                "formattedAddress": "",
                "streetName": "",
                "streetNr": "",
                "unitNr": null,
                "region": "",
                "postCode": "",
                "city": "",
                "state": "",
                "description": "",
                "coord": {
                  "lat": 0,
                  "lng": 0
                }
              },
              "directions": "",
              "passengerEvents": [{
                "type": "dropoff",
                "passengerId": "1",
                "time": "",
                "anchor": true,
                "driverNote": "",
                "pickupWindow": {
                  "start": "",
                  "end": ""
                }
              }]
            }],
            "prices": [{
              "payerIdentifier": "CASH",
              "currencyCode": "CAD",
              "fixed": 0,
              "estimate": 0,
              "tip": {
                "percent": 0,
                "minValue": 0
              }
            }],
            "customerReference": "string",
            "relatedIds": []
          }
        }
      };
      return tripData;
    },

    tripScheduleData() {
      let tripScheduleData = {
        "data": {
          "id": "",
          "type": "trip",
          "attributes": {
            "destination": "dispatch"
          }
        }
      };
      return tripScheduleData;
    }

  });

  _exports.default = _default;
});