define("adept-iq/serializers/tm-driver", ["exports", "adept-iq/serializers/-tm-tripManagement", "adept-iq/mixins/iq-model-identification"], function (_exports, _tmTripManagement, _iqModelIdentification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tmTripManagement.default.extend({
    iqModelName: 'iq-driver',
    iqModelForeignKey: 'badgeNr',
    iqModelRelationship: 'iqDriver',
    // TODO: change to 'belongsTo' when data is fixed
    iqModelRelationshipType: 'hasMany'
  });

  _exports.default = _default;
});