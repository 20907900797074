define("adept-iq/config/icon-paths", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    stops: {
      future: '/stops-icons/stop_unfinished.png',
      futureLate: '/stops-icons/stop_added_red.png',
      futureInDanger: '/stops-icons/stop_added_orange.png',
      next: '/stops-icons/stop_next.png',
      nextLate: '/stops-icons/stop_next_red.png',
      nextInDanger: '/stops-icons/stop_next_orange.png',
      completed: '/stops-icons/stop_completed.png',
      booked: '/stops-icons/stop_booked.png',
      active: '/stops-icons/stop_active.png',
      dispatch: '/stops-icons/stop_dispatch.png',
      underdispatch: '/stops-icons/stop_dispatch.png',
      arrive: '/stops-icons/stop_arrived.png',
      accept: '/stops-icons/stop_accepted.png',
      noshow: '/stops-icons/stop_noshow.png',
      load: '/stops-icons/stop_load.png',
      inservice: '/stops-icons/stop_load.png',
      depart: '/stops-icons/stop_load.png',
      work: '/stops-icons/stop_load.png',
      offered: '/stops-icons/stop_offered.png',
      break: '/stops-icons/break_next_fixed.png',
      cancelled: '/stops-icons/stop_cancelled.png',
      onhold: '/stops-icons/stop_onhold.png',
      scrub: '/stops-icons/stop_scrub.png',
      altered: '/stops-icons/stop_altered.png'
    },
    vehicles: {
      vehicle: '/vehicles-icons/car_icon.png',
      onbreak: '/vehicles-icons/vehicle-break.png',
      atWork: '/vehicles-icons/vehicle-work.png',
      accept: '/vehicles-icons/vehicle-accepted.png',
      booked: '/vehicles-icons/vehicle-busy.png',
      load: '/vehicles-icons/vehicle-busy.png',
      occupied: '/vehicles-icons/vehicle-busy.png',
      stc: '/vehicles-icons/vehicle-stc.png',
      ud: '/vehicles-icons/vehicle-busy.png',
      offered: '/vehicles-icons/vehicle-busy.png',
      arrive: '/vehicles-icons/vehicle-arrive.png',
      off: '/vehicles-icons/vehicle-off.png',
      tempoff: '/vehicles-icons/vehicle-tempoff.png',
      onhold: '/vehicles-icons/vehicle-onhold.png',
      free: '/vehicles-icons/vehicle-free.png',
      empty: '/vehicles-icons/vehicle-free.png',
      checking: '/vehicles-icons/vehicle-checking.png',
      unexplained: '/vehicles-icons/vehicle-unexplained.png',
      unknown: '/vehicles-icons/vehicle-unknown.png',
      emergency: '/vehicles-icons/vehicle-emergency.png'
    },
    zones: {
      normal: '/zones-icons/zone-line-normal.png',
      medium: '/zones-icons/zone-line-medium.png',
      high: '/zones-icons/zone-line-high.png',
      asap: '/zones-icons/people.png',
      freeVehicles: '/zones-icons/vehicle.png'
    },
    tripArrow: {
      ontime: '/arrows-icons/arrow.svg',
      danger: '/arrows-icons/arrow-danger.svg',
      late: '/arrows-icons/arrow-late.svg'
    },
    tripArrowOffset: {
      ontime: '/arrows-icons/arrow_offset.svg',
      danger: '/arrows-icons/arrow_offset-danger.svg',
      late: '/arrows-icons/arrow_offset-late.svg'
    },
    routes: {
      planned: '/lines-icons/line-planned.png',
      plannedSlack: '/lines-icons/line-planned-slack.png',
      performed: '/lines-icons/line-performed.png',
      performedSlack: '/lines-icons/line-performed-slack.png'
    },
    trips: {
      planned: '/lines-icons/line-planned.png',
      performed: '/lines-icons/line-performed.png'
    }
  };
  _exports.default = _default;
});