define("adept-iq/pods/components/form-components/bs-subscriptions/component", ["exports", "moment", "adept-iq/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DAILY_OPTIONS = {
    everyDay: 'Every Day',
    weekDay: 'Every WeekDay'
  };
  const FREQUENCY_OPTIONS = {
    daily: 'daily',
    weekly: 'weekly',
    monthly: 'monthly'
  };
  const END_OPTIONS = {
    noEnd: {
      type: 'noEnd',
      label: 'No end date'
    },
    endAfter: {
      type: 'endAfter',
      label: 'After number of occurences'
    },
    endBy: {
      type: 'endBy',
      label: 'By'
    }
  };
  const WOM_OPTIONS = {
    first: 'first',
    second: 'second',
    third: 'third',
    fourth: 'fourth',
    last: 'last'
  };
  const MONTHLY_TYPE_OPTIONS = {
    dayOfMonth: 'dayOfMonth',
    weekOfMonth: 'weekOfMonth'
  };
  const DAY_OF_WEEK_OPTIONS = {
    sunday: 'sunday',
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday'
  };

  var _default = Ember.Component.extend({
    classNames: ['form-components-subscriptions'],
    editModal: Ember.inject.service(),
    value: null,
    disabled: false,
    dayOfWeeks: null,
    recurring: false,
    subscriptions: Ember.computed.readOnly('value'),
    recurringPath: Ember.computed.readOnly('extra.recurringPath'),
    dailyConfigPath: Ember.computed.readOnly('extra.dailyConfigPath'),
    availabilityType: Ember.computed.readOnly('valuePath'),
    startTimePath: Ember.computed.readOnly('extra.startTimePath'),
    endTimePath: Ember.computed.readOnly('extra.endTimePath'),
    shiftStartPath: Ember.computed.readOnly('extra.shiftStartPath'),
    shiftEndPath: Ember.computed.readOnly('extra.shiftEndPath'),
    sundayPath: Ember.computed.readOnly('extra.sundayPath'),
    mondayPath: Ember.computed.readOnly('extra.mondayPath'),
    tuesdayPath: Ember.computed.readOnly('extra.tuesdayPath'),
    wednesdayPath: Ember.computed.readOnly('extra.wednesdayPath'),
    thursdayPath: Ember.computed.readOnly('extra.thursdayPath'),
    fridayPath: Ember.computed.readOnly('extra.fridayPath'),
    saturdayPath: Ember.computed.readOnly('extra.saturdayPath'),
    startDatePath: Ember.computed.readOnly('extra.startDateTimePath'),
    endDatePath: Ember.computed.readOnly('extra.endDateTimePath'),
    exStartDatePath: Ember.computed.readOnly('extra.excludeStartDateTimePath'),
    exEndDatePath: Ember.computed.readOnly('extra.excludeEndDateTimePath'),
    selectedWOMPath: Ember.computed.readOnly('extra.selectedWOMPath'),
    maxOccurencesPath: Ember.computed.readOnly('extra.maxOccurencesPath'),
    separationCountPath: Ember.computed.readOnly('extra.separationCountPath'),
    dayOfMonthPath: Ember.computed.readOnly('extra.dayOfMonthPath'),
    useOptionRecord: Ember.computed.readOnly('extra.useOptionRecord'),

    init() {
      this._super(...arguments);

      Ember.run(() => {
        let recurring = this.get(`record.${this.get('valuePath')}.firstObject.${this.get('recurringPath')}`);
        this.set('dayOfWeeks', [DAY_OF_WEEK_OPTIONS.sunday, DAY_OF_WEEK_OPTIONS.monday, DAY_OF_WEEK_OPTIONS.tuesday, DAY_OF_WEEK_OPTIONS.wednesday, DAY_OF_WEEK_OPTIONS.thursday, DAY_OF_WEEK_OPTIONS.friday, DAY_OF_WEEK_OPTIONS.saturday]);
        this.set('dateTimePlaceHolder', _environment.default.dateTimeFlatPickr);

        if (recurring) {
          this.set('recurring', true);
        } else {
          this.set('recurring', false);
        }

        this.set('frequencyOptions', [FREQUENCY_OPTIONS.daily, FREQUENCY_OPTIONS.weekly, FREQUENCY_OPTIONS.monthly]);
        this.set(this.get('dailyConfigPath'), FREQUENCY_OPTIONS.daily);
        this.set('dailyDaysOptions', [DAILY_OPTIONS.everyDay, DAILY_OPTIONS.weekDay]);
        this.subscriptions.forEach(subscription => {
          //console.log('subscription',subscription);
          // setup daily days
          if (subscription.isEveryDay) {
            subscription.dailyDaysdailyDays = DAILY_OPTIONS.everyDay;
          } else {
            subscription.dailyDays = DAILY_OPTIONS.weekDay;
          } // setup exclude range dates


          subscription.excludeRangeDate = [subscription.exclusionStartDate, subscription.exclusionEndDate]; // setup end options

          subscription.endOptions = [END_OPTIONS.noEnd, END_OPTIONS.endAfter, END_OPTIONS.endBy]; // setup what is the end option selected

          if (!Ember.isNone(subscription.subscriptionEndDate)) {
            subscription.endSelected = END_OPTIONS.endBy;
          } else if (!Ember.isNone(subscription.subscriptionMaxOccurences)) {
            subscription.endSelected = END_OPTIONS.endAfter;
          } else {
            subscription.endSelected = END_OPTIONS.noEnd;
          } // setup monthlyType


          if (!Ember.isNone(subscription.dayOfMonth)) {
            subscription.monthlyType = MONTHLY_TYPE_OPTIONS.dayOfMonth;
          } else {
            subscription.monthlyType = MONTHLY_TYPE_OPTIONS.weekOfMonth;
          }
        });
        this.set('weekOfMonthOptions', [WOM_OPTIONS.first, WOM_OPTIONS.second, WOM_OPTIONS.third, WOM_OPTIONS.fourth, WOM_OPTIONS.last]);
        this.set('datePlaceHolder', _environment.default.dateTimeFormat.dateMoment);
        this.set('timePlaceHolder', _environment.default.dateTimeFormat.timeMoment);
        this.set('dateFormat', _environment.default.dateTimeFormat.dateFlatPickr);
      });
    },

    frequencyOptionChange(record) {
      const frequency = record.dailyConfigPath;

      if (frequency === FREQUENCY_OPTIONS.daily || frequency === FREQUENCY_OPTIONS.weekly) {
        record.set(this.get('separationCountPath'), 1);
      } else if (frequency === FREQUENCY_OPTIONS.monthly) {
        let daysOfWeeks = this.get('dayOfWeeks');
        daysOfWeeks.forEach(day => {
          record.set(day, false);
        });
      }
    },

    actions: {
      onInput(record, valuePath, monthlyType, value) {
        let useOptionRecord = this.get('useOptionRecord');
        let options = {
          record,
          valuePath,
          useOptionRecord
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(value, options);
        record.set('monthlyType', monthlyType);
      },

      onChangeDate(record, valuePath, value) {
        let useOptionRecord = this.get('useOptionRecord');
        let options = {
          record,
          valuePath,
          useOptionRecord
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(value[0], options);
      },

      onChangeRangeDate(record, startPath, endPath, value) {
        let useOptionRecord = this.get('useOptionRecord');

        if (value.length === 2) {
          let options = {
            record,
            valuePath: startPath,
            useOptionRecord
          };
          this.get('onChange')(value[0], options);
          options = {
            record,
            valuePath: endPath,
            useOptionRecord
          };
          this.get('onChange')(value[1], options);
          this.set('record.isForceDirty', true);
        }
      },

      onClose(currentValue, stringValue, datePicker) {
        const currentValueMoment = (0, _moment.default)(currentValue[0]);
        const newValueMoment = (0, _moment.default)(stringValue);

        if (!newValueMoment._isValid) {
          this.set('editModal.errors', ['Date entered is invalid.']);
        }

        if (datePicker.config.allowInput && datePicker._input.value && newValueMoment._isValid && !currentValueMoment.isSame(newValueMoment)) {
          // date format is for database so should not be from config
          datePicker.setDate(newValueMoment.format('YYYY-MM-DD'), true);
        }
      },

      onChangeTime(record, valuePath, value) {
        let useOptionRecord = this.get('useOptionRecord');
        const momentTime = (0, _moment.default)(value[0]);
        let options = {
          record,
          valuePath,
          useOptionRecord
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(momentTime.format('HH:mm:ss'), options);
      },

      onCheckboxChange(record, valuePath) {
        let useOptionRecord = this.get('useOptionRecord');
        let switchState = Ember.get(record, valuePath);
        let options = {
          record,
          valuePath,
          useOptionRecord
        };
        switchState = !switchState;
        this.set('record.isForceDirty', true);
        this.get('onChange')(switchState, options);
      },

      onDOWChange(record, values) {
        let daysOfWeeks = this.get('dayOfWeeks');
        daysOfWeeks.forEach(day => {
          record.set(day, false);
        });

        switch (values) {
          case DAY_OF_WEEK_OPTIONS.sunday:
            record.set('sunday', true);
            break;

          case DAY_OF_WEEK_OPTIONS.monday:
            record.set('monday', true);
            break;

          case DAY_OF_WEEK_OPTIONS.tuesday:
            record.set('tuesday', true);
            break;

          case DAY_OF_WEEK_OPTIONS.wednesday:
            record.set('wednesday', true);
            break;

          case DAY_OF_WEEK_OPTIONS.thursday:
            record.set('thursday', true);
            break;

          case DAY_OF_WEEK_OPTIONS.friday:
            record.set('friday', true);
            break;

          case DAY_OF_WEEK_OPTIONS.saturday:
            record.set('saturday', true);
        }

        this.set('record.isForceDirty', true);
      },

      onAddNewAvailability() {
        this.get('onCreate')();
      },

      // onRecurringChange(record, value){
      //   record.set('recurring',value);
      //   this.set('recurring',value);
      // },
      onFrequencyChange(record, value) {
        let useOptionRecord = this.get('useOptionRecord');
        this.set('frequency', value);
        let valuePath = this.get('dailyConfigPath');
        let options = {
          record,
          valuePath,
          useOptionRecord
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(value, options);
        this.frequencyOptionChange(record);
      },

      onDailyOptionsChange(record, value) {
        record.set('dailyDays', value);
        this.frequencyOptionChange(record);
      },

      onEndOptionChange(record, value) {
        let useOptionRecord = this.get('useOptionRecord');
        record.set('endSelected', value);

        if (value === END_OPTIONS.endBy) {
          // null out max occurences
          let valuePath = this.get('maxOccurencesPath');
          let options = {
            record,
            valuePath,
            useOptionRecord
          };
          this.get('onChange')(null, options);
        } else if (value === END_OPTIONS.endAfter) {
          // null out end date
          let valuePath = this.get('endDatePath');
          let options = {
            record,
            valuePath,
            useOptionRecord
          };
          this.get('onChange')(null, options);
        } else {
          // no end option
          let valuePath = this.get('endDatePath');
          let options = {
            record,
            valuePath,
            useOptionRecord
          }; // null out end date

          this.get('onChange')(null, options); // null out max occurences

          valuePath = this.get('maxOccurencesPath');
          options = {
            record,
            valuePath,
            useOptionRecord
          };
          this.get('onChange')(null, options);
          this.set('record.isForceDirty', true);
        }

        this.set('record.isForceDirty', true);
      },

      onWOMChange(record, value) {
        let useOptionRecord = this.get('useOptionRecord');
        let valuePath = this.get('selectedWOMPath');
        let options = {
          record,
          valuePath,
          useOptionRecord
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(value, options);
      },

      onEnableSubscriptionChange(record) {
        this.toggleProperty('recurring'); //record.set(this.get('recurringPath'), this.get('recurring'));

        Ember.set(record, this.get('recurringPath'), this.get('recurring')); //console.log('recurringPath ',get(record,this.get('recurringPath')));

        let yesterdayDate = (0, _moment.default)().subtract(1, 'days');
        Ember.set(record, this.get('endDatePath'), yesterdayDate.toDate()); //console.log('endDatePath ',  get(record,this.get('endDatePath')));
      },

      onMonthlyTypeChange(record, value) {
        let useOptionRecord = this.get('useOptionRecord');

        if (value === MONTHLY_TYPE_OPTIONS.dayOfMonth) {
          let valuePath = this.get('selectedWOMPath');
          let options = {
            record,
            valuePath,
            useOptionRecord
          };
          this.get('onChange')(null, options);
          record.set(this.get('dayOfMonthPath'), 1);
        } else {
          let valuePath = this.get('dayOfMonthPath');
          let options = {
            record,
            valuePath,
            useOptionRecord
          };
          this.get('onChange')(null, options);
          record.set(this.get('selectedWOMPath'), WOM_OPTIONS.first);
        }

        this.set('record.isForceDirty', true);
      }

    }
  });

  _exports.default = _default;
});