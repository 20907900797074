define("adept-iq/models/trip-exec-event", ["exports", "ember-data", "adept-iq/mixins/otp-label", "adept-iq/mixins/status-value"], function (_exports, _emberData, _otpLabel, _statusValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    status: attr('string'),
    driverId: attr(),
    vehicleId: attr(),
    driver: belongsTo('driver'),
    vehicle: belongsTo('vehicle'),
    trip: belongsTo('trip', {
      inverse: 'tripExecEvent'
    }),
    iqTrip: belongsTo('iq-trip', {
      inverse: 'tripExecEvent'
    })
  });

  _exports.default = _default;
});