define("adept-iq/config/server-relationships", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.serverRelationships = void 0;
  // this is deprecated; used for generating RQL for active context queries
  const serverRelationships = {
    route: [{
      type: 'hasMany',
      modelName: 'trip',
      path: 'clusters.trips'
    }, {
      type: 'hasMany',
      modelName: 'stop',
      path: 'clusters.trips.stops'
    }],
    trip: [{
      type: 'belongsTo',
      modelName: 'route',
      path: 'cluster.route'
    }, {
      type: 'hasMany',
      modelName: 'stop',
      path: 'stops'
    }],
    stop: [{
      type: 'belongsTo',
      modelName: 'route',
      path: 'trip.cluster.route'
    }, {
      type: 'belongsTo',
      modelName: 'trip',
      path: 'trip'
    }],
    schedule: [],
    'rms-rider': [],
    'ss-driver': [],
    'ss-schedule': [],
    'ss-vehicle': [],
    'leg': [],
    'sso-user': [],
    'subscription': [],
    'account': [],
    'zone': []
  };
  _exports.serverRelationships = serverRelationships;
});