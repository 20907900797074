define("adept-iq/utils/filter-functions", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateIncludesIFn = _exports.addressIncludesIFn = _exports.stringIncludesIFn = _exports.stringNeIFn = _exports.stringEqIFn = _exports.dateStrictlyBetweenFn = _exports.dateBetweenFn = _exports.dateGteFn = _exports.dateGtFn = _exports.dateLteFn = _exports.dateLtFn = _exports.dateNeFn = _exports.dateStrEqFn = _exports.dateEqFn = _exports.strictlyBetweenFn = _exports.betweenFn = _exports.gteFn = _exports.gtFn = _exports.lteFn = _exports.ltFn = _exports.includesFn = _exports.neFn = _exports.eqFn = _exports.emptyFn = _exports.nullFn = void 0;

  const nullFn = () => true;

  _exports.nullFn = nullFn;
  const emptyFn = Ember.isBlank;
  _exports.emptyFn = emptyFn;

  const eqFn = (a, [b]) => a === b;

  _exports.eqFn = eqFn;

  const neFn = (a, [b]) => a !== b;

  _exports.neFn = neFn;

  const includesFn = (a, [b]) => {
    if (!a || !a.includes || !b) {
      return false;
    }

    return a.includes(b);
  };

  _exports.includesFn = includesFn;

  const ltFn = (a, [b]) => a < b;

  _exports.ltFn = ltFn;

  const lteFn = (a, [b]) => a <= b;

  _exports.lteFn = lteFn;

  const gtFn = (a, [b]) => a > b;

  _exports.gtFn = gtFn;

  const gteFn = (a, [b]) => a >= b;

  _exports.gteFn = gteFn;

  const betweenFn = (a, [b, c]) => b <= a && a <= c;

  _exports.betweenFn = betweenFn;

  const strictlyBetweenFn = (a, [b, c]) => b < a && a < c;

  _exports.strictlyBetweenFn = strictlyBetweenFn;

  const dateEqFn = (a, [b]) => {
    const dateB = new Date(b);

    if (Ember.typeOf(a) !== 'date' || Ember.typeOf(b) !== 'date') {
      return false;
    }

    return a && b && a.getTime() === dateB.getTime();
  };

  _exports.dateEqFn = dateEqFn;

  const dateStrEqFn = (a, [b], format) => {
    if (Ember.isBlank(a) || Ember.isBlank(b)) {
      return false;
    }

    if (Ember.typeOf(a) !== 'date') {
      return false;
    }

    const aStr = (0, _moment.default)(a).format(format);
    return includesFn(aStr, [b]);
  };

  _exports.dateStrEqFn = dateStrEqFn;

  const dateNeFn = (a, [b]) => a && b && a.getTime() !== b.getTime();

  _exports.dateNeFn = dateNeFn;

  const dateLtFn = (a, [b]) => a && b && a.getTime() < b.getTime();

  _exports.dateLtFn = dateLtFn;

  const dateLteFn = (a, [b]) => a && b && a.getTime() <= b.getTime();

  _exports.dateLteFn = dateLteFn;

  const dateGtFn = (a, [b]) => a && b && a.getTime() > b.getTime();

  _exports.dateGtFn = dateGtFn;

  const dateGteFn = (a, [b]) => a && b && a.getTime() >= b.getTime();

  _exports.dateGteFn = dateGteFn;

  const dateBetweenFn = (t, [t0, t1]) => {
    return dateGteFn(t, [t0]) && dateLteFn(t, [t1]);
  };

  _exports.dateBetweenFn = dateBetweenFn;

  const dateStrictlyBetweenFn = (t, [t0, t1]) => {
    return dateGtFn(t, [t0]) && dateLtFn(t, [t1]);
  }; // case-insensitive string functions


  _exports.dateStrictlyBetweenFn = dateStrictlyBetweenFn;

  const stringEqIFn = (a, [b]) => {
    if (Ember.typeOf(a) !== 'string' || Ember.typeOf(b) !== 'string') {
      return false;
    }

    return eqFn(a.toLowerCase(), [b.toLowerCase()]);
  };

  _exports.stringEqIFn = stringEqIFn;

  const stringNeIFn = (a, [b]) => {
    if (Ember.typeOf(a) !== 'string' || Ember.typeOf(b) !== 'string') {
      return false;
    }

    return neFn(a.toLowerCase(), [b.toLowerCase()]);
  };

  _exports.stringNeIFn = stringNeIFn;

  const stringIncludesIFn = (a, [b]) => {
    if (Ember.typeOf(a) !== 'string' || Ember.typeOf(b) !== 'string') {
      return false;
    }

    return includesFn(a.toLowerCase(), [b.toLowerCase()]);
  };

  _exports.stringIncludesIFn = stringIncludesIFn;

  const addressIncludesIFn = (a, [b]) => {
    if (typeof a !== 'object' || typeof b !== 'string') {
      return false;
    }

    return a.values.some(obj => includesFn(obj.value.toLowerCase(), [b.toLowerCase()]));
  };

  _exports.addressIncludesIFn = addressIncludesIFn;

  const dateIncludesIFn = (a, [b]) => {
    if (typeof a !== 'object' || typeof b !== 'string') {
      return false;
    }

    const header = a.header || '';
    return a.values.some(obj => includesFn(obj.value.toLowerCase(), [b.toLowerCase()])) || header.includes(b.toLowerCase());
  };

  _exports.dateIncludesIFn = dateIncludesIFn;
});