define("adept-iq/models/subscription", ["exports", "ember-data", "ember-data/relationships", "lodash"], function (_exports, _emberData, _relationships, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    sample
  } = _lodash.default;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  function formatTextExtension(className, value) {
    return {
      className: className,
      value: value
    };
  }

  var _default = Model.extend({
    requestTime: attr('date'),
    anchor: attr('string'),
    purpose: attr('string'),
    origin: belongsTo('bs-location'),
    destination: belongsTo('bs-location'),
    fare: attr('number'),
    originNotes: attr('string'),
    destinationNotes: attr('string'),
    startDate: attr('date', {
      defaultValue: null
    }),
    endDate: attr('date', {
      defaultValue: null
    }),
    maximumOccurrences: attr('number'),
    remainingOccurrences: 0,
    rider: belongsTo('bs-rider'),
    subscriptionTravelNeeds: (0, _relationships.hasMany)('subscription-travel-need'),
    fareType: belongsTo('fare-type'),
    exclusions: (0, _relationships.hasMany)('subscription-exclusion'),
    recurrencePatterns: (0, _relationships.hasMany)('subscription-recurrence-pattern'),
    serviceWindowName: attr('string'),
    serviceWindow: belongsTo('service-window'),
    status: Ember.computed('', function () {
      return sample(['Paused', 'Active']);
    }),
    origins: Ember.computed('origin', function () {
      return [this.get('origin.content')];
    }),
    destinations: Ember.computed('destination', function () {
      return [this.get('destination.content')];
    }),
    equipments: Ember.computed.filterBy('subscriptionTravelNeeds', 'isEquipment', true),
    extraPassengers: Ember.computed.filterBy('subscriptionTravelNeeds', 'isExtraPassenger', true),
    formattedRecurrence: Ember.computed('recurrencePatterns', function () {
      let formattedObject = {
        header: null,
        values: []
      };
      let recurrencePatterns = this.get('recurrencePatterns.firstObject');
      const selectedDOWArray = [];

      if (recurrencePatterns.get('sunday')) {
        selectedDOWArray.push(formatTextExtension('valueItem', `Monday`));
      }

      if (recurrencePatterns.get('monday')) {
        selectedDOWArray.push(formatTextExtension('valueItem', `Tuesday`));
      }

      if (recurrencePatterns.get('tuesday')) {
        selectedDOWArray.push(formatTextExtension('valueItem', `Wednesday`));
      }

      if (recurrencePatterns.get('wednesday')) {
        selectedDOWArray.push(formatTextExtension('valueItem', `Thursday`));
      }

      if (recurrencePatterns.get('thursday')) {
        selectedDOWArray.push(formatTextExtension('valueItem', `Friday`));
      }

      if (recurrencePatterns.get('friday')) {
        selectedDOWArray.push(formatTextExtension('valueItem', `Saturday`));
      }

      if (recurrencePatterns.get('saturday')) {
        selectedDOWArray.push(formatTextExtension('valueItem', `Sunday`));
      }

      formattedObject.values = selectedDOWArray;
      return formattedObject;
    })
  });

  _exports.default = _default;
});