define("adept-iq/pods/components/iq-widgets/subscriptions-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/subscriptions-form-widget/config", "moment"], function (_exports, _component, _config, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      let records = this.get('editableRecords');
      records.forEach(record => {
        if (record.get('exclusions').length === 0) {
          let exclusionRecord = this.get('store').createRecord('subscription-exclusion');
          exclusionRecord.set('subscription', record);
          record.get('exclusions').pushObject(exclusionRecord);
        }
      });
    },

    validateRecords() {
      let valid = true;
      let records = this.get('editableRecords');
      this.set('editModal.errors', []);
      records.forEach(record => {
        const requestTime = (0, _moment.default)(record.get('requestTime'));
        let recurrencePatterns = record.recurrencePatterns;

        if (Ember.isNone(requestTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Date should be there');
        }

        recurrencePatterns.forEach(recurrencePattern => {
          if (recurrencePattern.type === 'monthly') {
            if (Ember.isNone(recurrencePattern.weekOfMonth) && Ember.isNone(recurrencePattern.dayOfMonth)) {
              valid = false;
              this.get('editModal.errors').pushObject('Day of month and week of month cannot be both blank.');
            } else if (!Ember.isNone(recurrencePattern.weekOfMonth) && recurrencePattern.selectedDOWs.length < 1) {
              valid = false;
              this.get('editModal.errors').pushObject('Select day of the week.');
            }
          }
        });
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        let lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          let records = this.get('editableRecords');
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(({
            record,
            properties
          }) => {
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      onApplyClick() {
        if (this.validateRecords()) {
          this.get('service').apply();
        }
      }

    }
  });

  _exports.default = _default;
});