define("adept-iq/classes/markers/stop", ["exports", "adept-iq/classes/markers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    style: 'pick',
    label: 'Stop',
    isActive: true,
    isTraversal: false,
    isMCG: true,
    iqStop: Ember.computed.alias('record'),
    lat: Ember.computed.alias('iqStop.lat'),
    lng: Ember.computed.alias('iqStop.lng'),
    status: Ember.computed.alias('iqStop.drivedStatus')
  });

  _exports.default = _default;
});