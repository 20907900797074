define("adept-iq/pods/components/generic-widgets/column-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "ember-light-table", "moment", "adept-iq/pods/components/generic-widgets/column-widget/classes/data-join", "adept-iq/pods/components/generic-widgets/column-widget/classes/filter-column", "adept-iq/pods/components/generic-widgets/column-widget/utils/compute-ordered-ids", "ember-concurrency", "papaparse", "adept-iq/utils/focus_column_widget_row", "ember-keyboard-shortcuts", "lodash"], function (_exports, _component, _emberLightTable, _moment, _dataJoin, _filterColumn, _computeOrderedIds, _emberConcurrency, _papaparse, _focus_column_widget_row, _emberKeyboardShortcuts, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_COLUMN_WIDTH = 100;
  const MIN_COLUMN_WIDTH = 50;
  const CHECKBOX_COLUMN_WIDTH = 30; // number of records to load at a time while scrolling

  const PAGINATION_LIMIT = 50; // time to wait between refreshing adjacent widgets

  const STAGGERING_INTERVAL = 150;

  const toPixels = x => `${x}px`;

  const fromPixels = x => parseInt(x.split('px')[0], 10);

  var _default = _component.default.extend({
    workspace: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    stopsReorder: Ember.inject.service(),
    store: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    classNames: ['column-widget'],
    classNameBindings: ['disabled'],
    // overload this with a static config object
    config: null,
    limit: PAGINATION_LIMIT,
    offset: 0,
    paginationEnabled: true,
    tableActions: null,
    allowedTableActions: null,
    singleActions: null,
    bulkActions: null,
    records: null,
    searchText: '',
    isSearchEnabled: false,
    isSearchAllowed: false,
    isFilterAllowed: false,
    isCellEditAllowed: false,
    table: null,
    dataJoin: null,
    lastRowClicked: false,
    inlineRowMenu: null,
    inlineRowMenuPosition: null,
    keyboardShortcuts: null,
    accessKey: null,
    sortId: Ember.computed.alias('widget.state.sortId'),
    sortAsc: Ember.computed.alias('widget.state.sortAsc'),
    tileSize: Ember.computed.alias('workspace.tileSize'),
    tileSpacing: Ember.computed.alias('workspace.tileSpacing'),
    isLoading: Ember.computed.alias('fetchDataTask.isRunning'),
    isError: Ember.computed.alias('fetchDataTask.last.isError'),
    useBulkActions: Ember.computed.gt('checkedRowsCount', 1),
    isRowInCheckedRows: Ember.computed('lastRowClicked', 'table.rows', function () {
      const lastRowClicked = this.get('lastRowClicked');
      const checkedRows = this.get('table.rows').filterBy('isChecked');

      if (!lastRowClicked) {
        return false;
      }

      return checkedRows.some(row => row.get('content.id') === lastRowClicked.get('id'));
    }),
    showBulkActions: Ember.computed.and('useBulkActions', 'isRowInCheckedRows'),
    showSingleActions: Ember.computed.equal('showBulkActions', false),
    checkedRowsCount: Ember.computed('table.rows.@each.isChecked', function () {
      return this.get('table.rows').filterBy('isChecked').length;
    }),
    isFiltered: Ember.computed('table.columns.@each.{isFiltered}', function () {
      return this.get('table.columns').isAny('isFiltered');
    }),
    tableColumns: Ember.computed('config.columns', function () {
      let tableColumns = [{
        id: 'checkbox',
        valuePath: 'checked',
        cellComponent: 'table/cells/check-box',
        component: 'table/columns/check-box',
        sortable: false,
        width: toPixels(CHECKBOX_COLUMN_WIDTH)
      }];

      if (this.get('config.checkBoxColumn') !== undefined && !this.get('config.checkBoxColumn')) {
        tableColumns = [];
      }

      const columnConfigs = this.get('config.columns');
      const orderedColumnIds = (0, _computeOrderedIds.default)(columnConfigs);
      orderedColumnIds.forEach(id => {
        const config = columnConfigs.findBy('id', id);
        const tableColumn = {
          id: id,
          component: 'table/columns/filter-capable',
          label: config.label,
          cellDesc: config.cellDesc,
          valuePath: `record.${config.valuePath}`,
          valuePaths: config.valuePaths,
          hidden: config.hidden || config.unAvailable,
          resizable: true,
          minResizeWidth: MIN_COLUMN_WIDTH,
          width: toPixels(config.defaultWidth || DEFAULT_COLUMN_WIDTH),
          config
        };

        if (config.editable && this.isCellEditAllowed) {
          if (config.type === 'enum') {
            tableColumn.cellComponent = 'table/cells/user-editable-enum';
            tableColumn.options = config.options;
            tableColumn.allowMultiple = config.allowMultiple;
          } else {
            tableColumn.cellComponent = 'table/cells/user-editable';
          }
        } else if (config.type.includes('enum')) {
          tableColumn.cellComponent = 'table/cells/text-extension';
        } else {
          tableColumn.cellComponent = 'table/cells/base-cell';
        }

        if (config.highlightable) {
          tableColumn.cellClassNames = ['highlightable'];
        }

        if (['date', 'datetime', 'time', 'datetimeflatpickr'].includes(config.type) && config.format) {
          tableColumn.format = value => {
            return Ember.isEmpty(value) ? value : (0, _moment.default)(value).format(config.format);
          };

          switch (config.type) {
            case 'date':
            case 'datetimeflatpickr':
            case 'datetime':
              tableColumn.parse = value => {
                return (0, _moment.default)(value, config.format).toDate();
              };

              break;

            case 'time':
              tableColumn.parse = (timeString, originalValue) => {
                const date = (0, _moment.default)(originalValue).format('YYYY-MM-DD');
                const newValue = (0, _moment.default)(`${date} ${timeString}`, 'YYYY-MM-DD hh:mm A').toDate();
                return newValue;
              };

              break;

            default:
              break;
          }
        }

        tableColumns.push(tableColumn);
      }); // fills the rest of the space with an empty column

      tableColumns.push({
        id: 'spacer',
        width: '100%',
        sortable: false,
        isResizable: false
      });
      return tableColumns;
    }),
    canLoadMore: Ember.computed('paginationEnabled', 'offset', 'limit', 'records.length', function () {
      if (!this.get('paginationEnabled')) {
        return false;
      }

      const offset = this.get('offset');
      const limit = this.get('limit');
      const recordsLength = this.get('records.length') || 0;
      return offset + limit < recordsLength;
    }),

    init() {
      this._super(...arguments);

      const table = new _emberLightTable.default([], [], {
        enableSync: false
      });

      const dataJoin = _dataJoin.default.create({
        table,
        activeContext: this.get('activeContext')
      });

      this.setProperties({
        table,
        dataJoin,
        records: [],
        tableActions: [{
          id: 'print',
          name: 'Download',
          action: () => {
            this.exportData();
          }
        }]
      });

      if (!Ember.isEmpty(this.get('config.addAccessKey')) && !Ember.isEmpty(this.get('config.accessKey'))) {
        const keyboardShortcuts = {
          [this.get('config.addAccessKey')]: {
            action: 'createNew',
            // action to trigger
            global: true,
            preventDefault: true
          },
          ['ctrl+' + this.get('config.accessKey')]: {
            action: 'focusFirstItem',
            // action to trigger
            global: true,
            preventDefault: true
          }
        };
        this.set('keyboardShortcuts', keyboardShortcuts);
        this.set('accessKey', 'ctrl+' + this.get('config.accessKey'));
      } else if (!Ember.isEmpty(this.get('config.accessKey'))) {
        const keyboardShortcuts = {
          ['ctrl+' + this.get('config.accessKey')]: {
            action: 'focusFirstItem',
            // action to trigger
            global: true,
            preventDefault: true
          }
        };
        this.set('keyboardShortcuts', keyboardShortcuts);
        this.set('accessKey', 'ctrl+' + this.get('config.accessKey'));
      }

      this.set('allowedTableActions', this.get('tableActions'));
      this.configureSearchFilterPermission();

      if (!this.get('config.isFormControls')) {
        this.set('widget.models', _lodash.default.castArray(this.get('config.modelName')));
      }
    },

    configureSearchFilterPermission() {
      const configPermissions = this.get('workspace.configPermissions');
      const widgetTypeId = this.get('widget.typeId');

      if (!Ember.isNone(configPermissions) && !Ember.isNone(configPermissions[widgetTypeId])) {
        const configPermission = configPermissions[widgetTypeId];

        if (!Ember.isNone(configPermission.searchAllowed)) {
          this.set('isSearchAllowed', configPermission.searchAllowed);
        }

        if (!Ember.isNone(configPermission.filterAllowed)) {
          this.set('isFilterAllowed', configPermission.filterAllowed);
        }

        if (!Ember.isNone(configPermission.cellEditAllowed)) {
          this.set('isCellEditAllowed', configPermission.cellEditAllowed);
        }
      }
    },

    exportData() {
      const table = this.get('table');
      const columns = table.get('columns').rejectBy('hidden');
      const rows = table.get('rows');
      const fields = columns.reduce((arr, column) => {
        const id = column.get('id');

        if (['checkbox', 'spacer'].includes(id)) {
          return arr;
        }

        const label = column.get('label');
        arr.push(label);
        return arr;
      }, []);
      const data = rows.map(row => {
        return columns.reduce((arr, column) => {
          const config = column.get('config');
          const id = column.get('id');

          if (['checkbox', 'spacer'].includes(id)) {
            return arr;
          }

          const valuePath = column.get('valuePath');
          let value = row.get(valuePath);

          if (config.type.includes('enum') && Ember.isPresent(value)) {
            let values = null;

            if (Ember.isPresent(value.values)) {
              values = value.values.map(obj => obj.value);
              value = values.join(';');
            }
          }

          arr.push(value);
          return arr;
        }, []);
      });

      const csv = _papaparse.default.unparse({
        fields,
        data
      }, {
        quotes: true,
        quoteChar: '"',
        escapeChar: '"'
      });

      const link = document.createElement('a');
      const timestamp = (0, _moment.default)().format('YYYY-MM-DD_HH-MM-SS');
      const {
        title
      } = this.get('config');
      const filename = `${timestamp}_${title}.csv`;
      const blob = new Blob([csv], {
        type: 'text/csv;charset=utf-8;'
      });
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    didInsertElement() {
      this._super(...arguments);

      this._onKeyDown = event => {
        Ember.run.schedule('actions', this, 'onKeyDown', event);
      };

      this.$().on('keydown', this._onKeyDown);

      this._onMouseDown = event => {
        Ember.run.schedule('actions', this, 'onMouseDown', event);
      };

      this.$().on('mousedown', this._onMouseDown);

      this._onDoubleClick = event => {
        Ember.run.schedule('actions', this, 'onDoubleClick', event);
      };

      this.$().on('dblclick', this._onDoubleClick);

      if (!this.get('config')) {
        // eslint-disable-next-line
        throw 'static config property not found';
      }

      if (!Ember.isEmpty(this.get('keyboardShortcuts'))) {
        (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
      }

      this.onTableColumnsChange();
      this.onWidgetStateChange();
      this.onRecordsChange();
      Ember.run.scheduleOnce('afterRender', this, 'resizeBody');
      Ember.run.scheduleOnce('afterRender', this, 'refreshData');
    },

    willDestroyElement() {
      if (!Ember.isEmpty(this.get('keyboardShortcuts'))) {
        (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
      }

      this.$().off('keydown', this._onKeyDown);
      this.$().off('mousedown', this._onMouseDown);
      this.get('dataJoin').clear();

      this._super(...arguments);
    },

    setTableColumns() {
      const table = this.get('table');
      const tableColumns = this.get('tableColumns');
      table.set('columns', tableColumns.map(col => new _filterColumn.default(col)));
    },

    updateTableColumns() {
      const state = this.get('widget.state') || {};
      const config = this.get('config') || {};
      const columns = this.get('table.columns');
      let shouldResize = false;
      let scrollLeft;
      const body = this.$('.column-widget-body');

      if (body) {
        scrollLeft = this.$('.column-widget-body').scrollLeft();
      }

      if (columns) {
        columns.forEach(column => {
          const sortId = state.sortId || config.defaultSortId;

          if (Ember.isPresent(sortId) && sortId === column.get('id')) {
            let sortAsc;

            if (!Ember.isEmpty(state) && Ember.isPresent(state.sortAsc)) {
              sortAsc = state.sortAsc;
            } else if (Ember.isPresent(config.defaultSortAsc)) {
              sortAsc = config.defaultSortAsc;
            } else {
              sortAsc = true;
            }

            column.set('sorted', true);
            column.set('ascending', sortAsc);
          } else {
            column.set('sorted', false);
          }
        });
      }

      const columnsHash = state.columns || {};
      Object.entries(columnsHash).forEach(([id, columnState]) => {
        const column = columns.findBy('id', id);

        if (!column) {
          return;
        }

        if (!Ember.isEmpty(state) && Ember.isPresent(columnState.hidden)) {
          column.set('hidden', columnState.hidden);
          shouldResize = true;
        }

        if (Ember.isPresent(columnState.index)) {
          // +/- 1 accounts for checkbox column
          const oldIndex = columns.indexOf(column) - 1;

          if (columnState.index !== oldIndex) {
            const swapColumn = columns.objectAt(1 + columnState.index);
            columns.replace(1 + columnState.index, 1, [column]);
            columns.replace(1 + oldIndex, 1, [swapColumn]);
            shouldResize = true;
          }
        }

        const wasFiltered = column.get('isFiltered');
        ['filterTypeId', 'filterValues'].forEach(prop => {
          // allow null value to overwrite
          if (!columnState.hasOwnProperty(prop)) {
            return;
          }

          column.set(prop, columnState[prop]);
        });

        if (wasFiltered || column.get('isFiltered')) {
          Ember.run.scheduleOnce('afterRender', this, 'refreshData');
        }

        if (Ember.isPresent(columnState.width)) {
          column.set('width', toPixels(columnState.width));
        }
      });

      if (shouldResize) {
        // resize at end of _next_ loop so that all changes are applied
        Ember.run.next(() => {
          Ember.run.scheduleOnce('afterRender', this, 'resizeBody');

          if (Ember.isPresent(scrollLeft)) {
            // wait an additional loop before restoring scroll position
            Ember.run.next(() => {
              Ember.run.scheduleOnce('afterRender', this, 'setScrollLeft', scrollLeft);
            });
          }
        });
      }
    },

    resizeBody() {
      const table = this.$('.lt-head-wrap table');
      const widgetBody = this.$('.column-widget-body');

      if (!table && !widgetBody) {
        return;
      }

      const tableWidth = table ? table.width() : -Infinity;
      const widgetWidth = widgetBody ? widgetBody.width() : -Infinity;
      const width = Math.max(tableWidth, widgetWidth);
      this.$('.ember-light-table').width(width);
    },

    refreshData() {
      this.get('fetchDataTask').perform().then(() => {
        if (this.get('activeContext.refreshInProgress')) {
          this.set('activeContext.refreshInProgress', false);
        }
      }).catch(err => {
        if (this.get('activeContext.refreshInProgress')) {
          this.set('activeContext.refreshInProgress', false);
        }

        if (!(0, _emberConcurrency.didCancel)(err)) {
          throw err;
        } // @TODO: log error

      });
    },

    clearData() {
      this.set('records', []);
      this.set('offset', 0);
    },

    fetchMoreData() {
      if (!this.get('canLoadMore')) {
        return;
      }

      const limit = this.get('limit');
      let offset = this.get('offset');
      offset += limit;
      this.set('offset', offset);
    },

    setScrollLeft(scrollLeft) {
      if (!this.$('.column-widet-body')) {
        return;
      }

      this.$('.column-widget-body').scrollLeft(scrollLeft);
    },

    getWidgetIndex() {
      if (this.get('maximizer.maximizedWidget') || !Ember.isPresent(this.element.closest('.grid-stack'))) {
        return null;
      }

      const gridStack = this.element.closest('.grid-stack');
      const gridStackItems = Array.from(gridStack.children).sort((a, b) => {
        const yDiff = parseInt(a.getAttribute('data-gs-y'), 10) - parseInt(b.getAttribute('data-gs-y'), 10);

        if (yDiff === 0) {
          return parseInt(a.getAttribute('data-gs-x'), 10) - parseInt(b.getAttribute('data-gs-x'), 10);
        }

        return yDiff;
      });
      const gridStackItem = this.element.closest('.grid-stack-item');
      return gridStackItems.indexOf(gridStackItem);
    },

    doReSort() {
      Ember.run.debounce(this, 'reSort', 300);
    },

    reSort() {
      this.updateTableColumns();
      this.refreshData();
    },

    fetchDataTask: (0, _emberConcurrency.task)(function* () {
      const records = yield this.fetchDataQuery();
      yield (0, _emberConcurrency.timeout)(STAGGERING_INTERVAL * this.getWidgetIndex());
      this.set('records', records);
      this.set('offset', 0);
    }).restartable(),
    onRecordsChange: Ember.observer('records', 'offset', function () {
      const limit = this.get('limit');
      const offset = this.get('offset');
      const records = this.get('records').slice(0, offset + limit);
      this.get('dataJoin').join(records);
      Ember.run.scheduleOnce('afterRender', this, 'resizeBody');
    }),
    onTableColumnsChange: Ember.observer('tableColumns', function () {
      Ember.run.scheduleOnce('afterRender', this, 'setTableColumns');
      Ember.run.scheduleOnce('afterRender', this, 'updateTableColumns');
    }),
    onWidthChange: Ember.observer('width', function () {
      Ember.run.scheduleOnce('afterRender', this, 'resizeBody');
    }),
    onWidgetStateChange: Ember.observer('widget.state', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateTableColumns');
    }),
    onTableActionsChange: Ember.observer('tableActions.[]', function () {
      this.reConfigureTableActions();
    }),

    reConfigureTableActions() {
      const configPermissions = this.get('workspace.configPermissions');
      const actions = []; //TODO when permission implemented

      this.get('tableActions').forEach(currAction => actions.push(currAction));
      this.set('allowedTableActions', actions);
      /*if (!isNone(configPermissions)) {
        const configPermission = configPermissions[this.get('widget.typeId')];
         if (!isNone(configPermission) && !isNone(this.get('tableActions'))) {
          this.get('tableActions').forEach((currAction) =>
            if (!isNone(configPermission.tableActions) &&
              (configPermission.tableActions.includes(currAction.id) || configPermission.tableActions.includes('all'))) {
              actions.push(currAction);
            }
          });
           this.set('allowedTableActions', actions);
        }
      }*/
    },

    onKeyDown(event) {
      const ltCell = event.target.closest('.lt-cell'); //////console.log(('onKeyDown ',event);
      // need to exempt checkboxes to allow right arrow from checkbox col

      const targetIsControl = event.target.tagName === 'INPUT' && event.target.type !== 'checkbox';

      switch (event.key) {
        case 'Escape':
          {
            const targetIsSearchBox = event.target.classList.contains('column-widget-search-box');

            if (targetIsSearchBox) {
              event.preventDefault();
              this.set('isSearchEnabled', false);
              this.$('.column-widget-search-button').focus();
            }

            if (this.get('config.isFormControls')) {
              event.preventDefault();
              this.closeWidget();
              event.stopPropagation();
            }

            break;
          }

        case 'ArrowLeft':
          {
            if (!ltCell || targetIsControl) {
              return;
            }

            event.preventDefault();
            const previousCell = ltCell.previousElementSibling;

            if (!previousCell) {
              return;
            }

            const focusElement = previousCell.querySelector('.can-focus') || previousCell.firstElementChild;

            if (focusElement) {
              focusElement.focus();
            }

            break;
          }

        case 'ArrowRight':
          {
            if (!ltCell || targetIsControl) {
              return;
            }

            event.preventDefault();
            const nextCell = ltCell.nextElementSibling;

            if (!nextCell) {
              return;
            }

            const focusElement = nextCell.querySelector('.can-focus') || nextCell.firstElementChild;

            if (focusElement) {
              focusElement.focus();
            }

            break;
          }

        case 'ArrowDown':
          {
            if (!ltCell) {
              return;
            }

            event.preventDefault();
            const row = ltCell.closest('.lt-row'); // an HTMLCollection isn't really an array

            const rowChildren = [].slice.call(row.children);
            const index = rowChildren.indexOf(ltCell);
            const nextRow = row.nextElementSibling;

            if (nextRow && nextRow.tagName !== 'OCCLUDED-CONTENT') {
              // move table selection down one row
              const tableRows = this.get('table.rows');
              tableRows.setEach('selected', false);
              const recordId = nextRow.getAttribute('data-record-id');
              const nextTableRow = tableRows.findBy('content.id', recordId);
              nextTableRow.set('selected', true); // move browser focus to same column in next row

              const nextLtCell = nextRow.children[index];
              const focusElement = nextLtCell.querySelector('.can-focus') || nextLtCell.firstElementChild;

              if (focusElement) {
                focusElement.focus();
              }
            }

            break;
          }

        case 'ArrowUp':
          {
            if (!ltCell) {
              return;
            }

            event.preventDefault();
            const row = ltCell.closest('.lt-row'); // an HTMLCollection isn't really an array

            const rowChildren = [].slice.call(row.children);
            const index = rowChildren.indexOf(ltCell);
            const previousRow = row.previousElementSibling;

            if (previousRow && previousRow.tagName !== 'OCCLUDED-CONTENT') {
              // move table selection down one row
              const tableRows = this.get('table.rows');
              tableRows.setEach('selected', false);
              const recordId = previousRow.getAttribute('data-record-id');
              const previousTableRow = tableRows.findBy('content.id', recordId);
              previousTableRow.set('selected', true); // move browser focus to same column in previous row

              const previousLtCell = previousRow.children[index];
              const focusElement = previousLtCell.querySelector('.can-focus') || previousLtCell.firstElementChild;

              if (focusElement) {
                event.preventDefault();
                focusElement.focus();
              }
            }

            break;
          }

        case ' ':
        case 'Enter':
          {
            const isColumn = event.target.classList.contains('table-base-column'); //console.log('isColumn',isColumn);
            //if (isColumn) {

            event.preventDefault();
            this.$(event.target).trigger('click'); // }

            break;
          }

        default:
          break;
      }
    },

    onMouseDown(event) {
      if (!this.get('stopsReorder').reorderInProgress) {
        event.preventDefault();
      }

      if (event.target.tagName === 'INPUT' && event.target.type === 'checkbox') {
        // allow user to check another row without moving selection
        return;
      }

      const ltCell = event.target.closest('.lt-cell');

      if (ltCell) {
        const row = ltCell.closest('.lt-row');
        const recordId = row.getAttribute('data-record-id');
        const tableRows = this.get('table.rows');
        const clickedRow = tableRows.findBy('content.id', recordId);
        tableRows.setEach('selected', false);
        clickedRow.set('selected', true);
        const focusElement = ltCell.querySelector('.can-focus') || ltCell.firstElementChild;

        if (focusElement) {
          focusElement.focus();
        }
      }
    },

    onDoubleClick(event) {
      event.preventDefault();

      if (event.target.tagName === 'INPUT' && event.target.type === 'checkbox') {
        // allow user to check another row without moving selection
        return;
      }

      const ltCell = event.target.closest('.lt-cell');

      if (ltCell) {
        const row = ltCell.closest('.lt-row');
        const index = Array.from(row.children).indexOf(ltCell);
        const recordId = row.getAttribute('data-record-id');
        const tableRows = this.get('table.rows');
        const clickedColumn = this.get('table.columns')[index];
        const clickedRow = tableRows.findBy('content.id', recordId);
        tableRows.setEach('selected', false);
        const scheduleLocked = clickedRow.get('record.scheduleLocked');

        if (!scheduleLocked || Ember.isNone(scheduleLocked)) {
          clickedRow.set('isEditing', true);
          clickedColumn.set('isEditing', true);
        }
      }
    },

    reConfigureSingleActions() {
      const configPermissions = this.get('workspace.configPermissions');
      const singleActions = [];
      const bulkActions = [];
      this.get('singleActions').forEach(currAction => {
        singleActions.push(currAction);
      });
      this.set('singleActions', singleActions);

      if (!Ember.isEmpty(this.get('bulkActions'))) {
        this.get('bulkActions').forEach(currAction => {
          bulkActions.push(currAction);
        });
        this.set('bulkActions', bulkActions);
      } //TODO when implment permission

      /*if (!isNone(configPermissions)) {
        const configPermission = configPermissions[this.get('widget.typeId')];
         if (!isNone(configPermission) && !isNone(this.get('singleActions'))) {
          this.get('singleActions').forEach((currAction) => {
            if (!isNone(configPermission.singleActions) &&
              (configPermission.singleActions.includes(currAction.id) || configPermission.singleActions.includes('all'))) {
              singleActions.push(currAction);
            }
          });
           this.set('singleActions', singleActions);
        }
         if (!isNone(configPermission) && !isNone(this.get('bulkActions'))) {
          this.get('bulkActions').forEach((currAction) => {
            if (!isNone(configPermission.bulkActions) &&
              (configPermission.bulkActions.includes(currAction.id) || configPermission.bulkActions.includes('all'))) {
              bulkActions.push(currAction);
            }
          });
           this.set('bulkActions', bulkActions);
        }
      }*/

    },

    closeWidget() {
      this.toggleProperty('editmodalcontext.showWidgetModal');

      if (this.get('focusFieldId')) {
        const el = Ember.$('#' + this.get('focusFieldId'));

        if (el && el[0]) {
          Ember.run.next(el[0], 'focus');
        }
      }
    },

    actions: {
      onSearchTextChange(searchText) {
        const previousSearchText = this.get('searchText');

        if (searchText === previousSearchText) {
          return;
        }

        this.set('searchText', searchText);
        Ember.run.debounce(this, 'refreshData', 250);
      },

      onColumnClick(column) {
        if (!column.sorted) {
          return;
        }

        try {
          this.get('widget').mergeState({
            sortId: column.get('id'),
            sortAsc: column.get('ascending')
          });
        } catch (e) {}

        Ember.run.scheduleOnce('afterRender', this, 'refreshData');
      },

      onColumnResize(column, pixels) {
        const scrollLeft = this.$('.column-widget-body').scrollLeft();
        const id = column.get('id');
        const columns = {};
        columns[id] = {
          width: fromPixels(pixels)
        };

        try {
          this.get('widget').mergeState({
            columns
          });
        } catch (e) {}

        Ember.run.scheduleOnce('afterRender', this, 'resizeBody');
        Ember.run.next(() => {
          Ember.run.scheduleOnce('afterRender', this, 'setScrollLeft', scrollLeft);
        });
      },

      onSearchButtonClick() {
        this.send('onSearchTextChange', '');
        this.toggleProperty('isSearchEnabled');
        Ember.run.schedule('afterRender', () => {
          if (this.get('isSearchEnabled')) {
            this.$('.column-widget-search-box').focus();
          }
        });
      },

      onRemoveSearchClick() {
        this.send('onSearchTextChange', '');
        this.toggleProperty('isSearchEnabled');
        this.$('.column-widget-search-button').focus();
      },

      onHeaderDoubleClick() {
        const maximizer = this.get('maximizer');

        if (maximizer.maximizedWidget === this.get('widget')) {
          maximizer.minimize();
          return;
        }

        maximizer.maximize(this.get('widget'));
      },

      onExitMaximizedClick() {
        const maximizer = this.get('maximizer');
        maximizer.minimize();
      },

      onFilterButtonClick() {
        const columns = [];
        const workspace = this.get('workspace');
        const widget = this.get('widget');
        const config = this.get('config');
        const displayName = `${config.title} Filters`; // close sidebar if _this_ widget's filter is currently open
        // @TODO: define and use unique keys rather than `displayName` for this

        if (displayName === workspace.get('topStateDisplayName')) {
          workspace.popState();
          return;
        }

        let topState = workspace.get('topState');

        while (topState === 'filterColumnWidget') {
          workspace.popState();
          topState = workspace.get('topState');
        }

        this.get('table.columns').forEach(column => {
          const columnConfig = column.config;

          if (columnConfig) {
            if (!columnConfig.unAvailable) {
              columns.push(column);
            }
          } else {
            columns.push(column);
          }
        });
        workspace.pushState('filterColumnWidget', {
          columns,
          widget,
          displayName
        });
      },

      setInlineRowMenu(dropdown) {
        this.set('inlineRowMenu', dropdown);
      },

      onRowClick(row, event) {},

      onRowRightClick(row, event) {
        this.set('inlineRowMenuPosition', function () {
          return {
            style: {
              top: event.clientY,
              left: event.clientX
            }
          };
        });
        this.set('lastRowClicked', row.get('record'));

        if (!Ember.isNone(this.get('dynamicSingleActions'))) {
          this.set('singleActions', this.get('dynamicSingleActions'));
        } else {
          this.set('dynamicSingleActions', this.get('singleActions'));
        } // remove right click action if schedule record is locked


        if (row.get('record.scheduleLocked')) {
          const singleActions = [];
          this.get('singleActions').forEach(action => {
            if (!action.disableOnlocked) {
              singleActions.push(action);
            }
          });
          this.set('singleActions', singleActions);
        }

        if (!Ember.isNone(this.get('singleActions'))) {
          this.reConfigureSingleActions();
        }

        this.get('inlineRowMenu').actions.open();
      },

      onScrolledToBottom() {
        -Ember.run.scheduleOnce('actions', this, 'fetchMoreData');
      },

      onScroll() {
        const scrollHeight = this.$('.column-widget-body')[0].scrollHeight;
        const scrollTop = this.$('.column-widget-body')[0].scrollTop;
        const offsetHeight = this.$('.column-widget-body')[0].offsetHeight; // scrollHeight is the total height value for scrollable content
        // scrollTop is the current vertical scroll location
        // offsetHeight is the height of the column-widget-body div element
        // 100 is a buffer for getting close to the bottom of the scrollable content

        if (scrollTop + offsetHeight + 100 >= scrollHeight) {
          Ember.run.scheduleOnce('actions', this, 'fetchMoreData');
        }
      },

      onTableActionClick(action, dropdown) {
        dropdown.actions.close();

        if (action.action) {
          action.action();
        }
      },

      onSingleRowAction(action) {
        const model = this.get('lastRowClicked');
        this.get('inlineRowMenu').actions.close();

        if (action.action) {
          action.action(model);
        }
      },

      onBulkRowAction(action) {
        const checkedRows = this.get('table.rows').filterBy('isChecked');
        const models = [];
        checkedRows.forEach(row => {
          models.push(row.get('record'));
        });
        this.get('inlineRowMenu').actions.close();

        if (action.action) {
          action.action(models);
        }
      },

      closeWidget() {
        this.closeWidget();
      },

      createNew() {
        console.log('create new');
      },

      onClearButtonClick() {
        this.get('activeContext').clearCheckItemsByModelName(this.get('config.modelName'));
      },

      focusFirstItem() {
        (0, _focus_column_widget_row.default)(this);
      }

    }
  });

  _exports.default = _default;
});