define("adept-iq/models/poi-address-search", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    type: attr(),
    id: attr(),
    score: attr(),
    dist: attr(),
    info: attr(),
    poi: attr,
    address: attr(),
    position: attr(),
    viewport: attr(),
    entryPoints: attr(),
    name: attr(),
    freeformAddress: attr(),
    municipalitySubdivision: attr()
  });

  _exports.default = _default;
});