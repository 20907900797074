define("adept-iq/pods/components/iq-widgets/new-trip-fixform-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        type: 'text',
        label: 'ID',
        valuePath: 'id',
        editable: false
      }, {
        id: 'lastId',
        type: 'text',
        label: 'ID',
        valuePath: 'lastId',
        editable: false
      }]
    }, {
      title: 'Client Detail',
      fields: [{
        id: 'Tel',
        type: 'phone-model',
        label: 'Tel',
        accessKey: 't',
        valuePath: 'passengers.0.phone1.number',
        extra: {
          optionModelName: 'rider-profile',
          optionSearchPath: 'attributes/phone1/number',
          optionSearchEnabled: false,
          optionWidgetModel: 'callLines',
          optionModelWidgetPath: 'rider-profile-widget'
        }
      }, {
        id: 'Name',
        type: 'text',
        label: 'Name',
        accessKey: 'N',
        valuePath: 'passengers.0.firstName',
        editable: true
      }]
    }, {
      title: 'Trip Details',
      fields: [{
        id: 'addresses',
        type: 'locations',
        label: '',
        valuePath: 'stopPoints',
        modelName: 'tm-address',
        isMultiType: true,
        extra: {
          addressTypePath: 'passengerEvents.0.type',
          descriptionPath: 'address.description',
          unitPath: 'address.unitNr',
          streetNrPath: 'address.streetNr',
          streetNamePath: 'address.streetName',
          localityPath: 'address.region',
          regionPath: 'address.county',
          subRegionPath: 'address.state',
          postalCodePath: 'address.postalCode',
          countryPath: 'address.country',
          defaultOptionsPath: 'callerTrips',
          pickup: {
            label: 'Pickup',
            accessKey: 'p'
          },
          dropoff: {
            label: 'Destination',
            accessKey: 'e'
          },
          swipeAddressAccessKey: 'w'
        }
      }, {
        id: 'requestTime',
        type: 'datetimeflatpickr',
        label: 'Date Time',
        accessKey: 'm',
        valuePath: 'stopPoints.0.passengerEvents.0.time',
        editable: true,
        hidden: false,
        format: 'YYYY-MM-DD hh:mm A',
        defaultWidth: 50
      }]
    }, {
      title: 'Account',
      align: 'right',
      fields: [{
        id: 'Account',
        type: 'account-model',
        label: 'Account',
        valuePath: 'acctCardNr',
        accessKey: 'c',
        extra: {
          parentValuePath: 'account',
          optionModelName: 'rider-profile',
          optionSearchPath: 'attributes/account/accountId',
          optionSearchEnabled: false,
          optionWidgetModel: 'callLines',
          optionModelWidgetPath: 'rider-profile-widget'
        }
      },
      /*{
        id: 'accountPickupTrips',
        type: 'buttonwithpopup',
        label: 'Account Pickups',
        accessKey: 'k',
        extra: {
          isButtonEnabledPath: 'isAccountPickups',
          widgetModelDataPath: 'editmodelcontext.accountTrips',
          modelWidgetPath:  'address-widget'
        }
      },
      {
        id: 'accountFixedPriceTrips',
        type: 'buttonwithpopup',
        label: 'Fixed Prices',
        accessKey: 'x',
        extra: {
          isButtonEnabledPath: 'isAccountFixedPriceTrips',
          widgetModelDataPath: 'editmodelcontext.accountFixedPriceTrips',
          modelWidgetPath:  'fixed-price-trip-widget'
        }
      },*/
      {
        id: 'reference',
        type: 'text',
        accessKey: 'R',
        label: 'Reference#',
        valuePath: 'reference',
        editable: true
      }, {
        id: 'fare',
        type: 'number',
        label: 'Fixed Price',
        accessKey: 'F',
        valuePath: 'prices.0.fixed',
        editable: true
      }]
    }, {
      title: 'Travel Needs',
      align: 'right',
      fields: [{
        id: 'attributes',
        type: 'multiselect',
        label: '',
        accessKey: 'a',
        valuePath: 'attributes',
        cellDesc: 'attributes',
        hidden: false,
        isMultiType: true,
        extra: {
          additionalValuePath: 'manualAttributes',
          optionModelName: 'tm-common-operational-attribute',
          optionSearchPath: 'name'
        }
      }]
    }, {
      title: 'Services',
      align: 'right',
      fields: [
      /*{
      id: 'taxis',
      type: 'enum',
      label: 'Taxis',
      valuePath: 'taxis',
      cellDesc: 'taxis',
      accessKey: 'i',
      editable: true,
      hidden: false,
      extra: {
      options: [1, 2, 3, 4, 5],
      optionSearchEnabled: false
      },
      allowMultiple: false
      },
      {
      id: 'noOfpassenger',
      type: 'enum',
      label: 'Passenger',
      valuePath: 'noOfpassenger',
      cellDesc: 'noOfpassenger',
      accessKey: 'g',
      editable: true,
      hidden: false,
      extra: {
      options: [1, 2, 3, 4, 5],
      optionSearchEnabled: false
      },
      allowMultiple: false
      },*/
      {
        id: 'callout',
        type: 'radio',
        label: 'Reminder',
        valuePath: 'passengers.0.callout',
        hidden: false,
        extra: {
          options: ["call", "sms"]
        }
      }]
    }, {
      title: 'Notes',
      fields: [{
        id: 'driverNotes',
        type: 'text',
        label: 'Driver',
        multiLine: true,
        accessKey: 'r',
        valuePath: 'driverNotes',
        placeholder: 'Driver Notes',
        editable: true
      }, {
        id: 'driverNotesAutoPopulate',
        type: 'text',
        label: ' -',
        valuePath: 'predefinedNotes.driverNotes',
        editable: false
      }, {
        id: 'operatorNotes',
        type: 'text',
        label: 'Operator',
        multiLine: true,
        accessKey: 'o',
        valuePath: 'operatorNotes',
        editable: true
      }, {
        id: 'operatorNotesAutoPopulate',
        type: 'text',
        label: '- ',
        valuePath: 'predefinedNotes.operatorNotes',
        editable: false
      }]
    } //TODO - enabled when do subscription

    /*, {
      title: 'Subscription',
      fields: [{
        id: 'subscriptions',
        type: 'subscriptions',
        label: '',
        valuePath: 'subscriptions.firstObject.recurrencePatterns',
        modelName: 'subscription-recurence-pattern',
        isMultiType: true,
        extra: {
           recurringPath: 'recurring',
          dailyConfigPath: 'type',
          selectedWOMPath: 'selectedWOM',
          startDateTimePath: 'subscriptionStartDate',
          endDateTimePath: 'subscriptionEndDate',
          sundayPath: 'sunday',
          mondayPath: 'monday',
          tuesdayPath: 'tuesday',
          wednesdayPath: 'wednesday',
          thursdayPath: 'thursday',
          fridayPath: 'friday',
          saturdayPath: 'saturday',
          excludeStartDateTimePath: 'exclusionStartDate',
          excludeEndDateTimePath: 'exclusionEndDate',
          maxOccurencesPath: 'subscriptionMaxOccurences',
          separationCountPath: 'separationCount',
          dayOfMonthPath: 'dayOfMonth',
          useOptionRecord: true
        }
      }]
    }*/
    ]
  };
  _exports.default = _default;
});