define("adept-iq/models/segment", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    promiseTime: (0, _attr.default)('date'),
    anchor: (0, _attr.default)('string'),
    fare: (0, _attr.default)('number'),
    travelMode: (0, _attr.default)('string'),
    pick: (0, _relationships.belongsTo)('stop'),
    drop: (0, _relationships.belongsTo)('stop'),
    fareType: (0, _relationships.belongsTo)('fare-type'),
    leg: (0, _relationships.belongsTo)('leg'),
    stops: Ember.computed.collect('pick', 'drop')
  });

  _exports.default = _default;
});