define("adept-iq/pods/components/iq-widgets/search-trip-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Trip',
      align: 'right',
      fields: [{
        id: 'Tel',
        type: 'text',
        label: 'Trip ID',
        accessKey: 't',
        valuePath: 'tripId'
      }]
    }, {
      title: 'Client Detail',
      fields: [{
        id: 'Tel',
        type: 'text',
        label: 'Tel',
        accessKey: 't',
        valuePath: 'passengers.0.phone1.number'
      }, {
        id: 'Name',
        type: 'text',
        label: 'Name',
        accessKey: 'N',
        valuePath: 'passengers.0.firstName',
        editable: true
      }]
    }, {
      title: 'Trip Details',
      fields: [{
        id: 'Tel',
        type: 'text',
        label: 'Street Nr',
        accessKey: 't',
        valuePath: 'stopPoints.0.address.streetNr'
      }, {
        id: 'Tel',
        type: 'text',
        label: 'Street Name',
        accessKey: 't',
        valuePath: 'stopPoints.0.address.streetName'
      }]
    }, {
      title: 'Account',
      align: 'right',
      fields: [{
        id: 'Account',
        type: 'text',
        label: 'Account',
        valuePath: 'acctCardNr',
        accessKey: 'a'
      }]
    }]
  };
  _exports.default = _default;
});