define("adept-iq/pods/components/iq-widgets/trips-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/trips-widget/config", "adept-iq/utils/find-row-record", "adept-iq/utils/populate-new-trip", "adept-iq/utils/populate-copy-trip", "adept-iq/utils/populate-edit-trip", "adept-iq/utils/populate-account", "adept-iq/utils/populate-new-text-message", "adept-iq/config/environment", "moment", "adept-iq/config/api-urls"], function (_exports, _component, _config, _findRowRecord, _populateNewTrip, _populateCopyTrip, _populateEditTrip, _populateAccount, _populateNewTextMessage, _environment, _moment, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['trips-widget'],
    dragCoordinator: Ember.inject.service(),
    store: Ember.inject.service(),
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    trip: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    searchservice: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    session: Ember.inject.service(),
    routeexecevent: Ember.inject.service(),
    textmessage: Ember.inject.service(),
    geocode: Ember.inject.service(),
    config: _config.default,
    defaultIncludes: INCLUDES,
    accountSearchPath: _apiUrls.API_SUB_URL.accountSearchPath,
    noShowComponent: 'iq-widgets/noshow-event-form-widget',
    editTripComponent: 'iq-widgets/edit-trip-form-widget',
    newTripComponent: 'iq-widgets/new-trip-form-widget',
    cancelTripComponent: 'iq-widgets/cancel-type-form-widget',
    assignTrip2RouteComponent: 'iq-widgets/assign-trip-2-route-widget',
    activityLogComponent: 'iq-widgets/activity-log-widget',

    init() {
      this._super(...arguments);

      this.get('editModal').set('showActivityLog', false); // if(!ENV.APP.fullMode) {

      /*let newtrip =  populateNewTrip(this.get('trip'),
                       this.get('store'));
          populateAccount(newtrip,
                         null,
                         this.get('geocode'),
                         this.get('store'),
                         this.get('editmodalcontext'),
                         this.get('searchservice'),
                         this.get('accountSearchPath'),
                         newtrip.acctCardNr,
                         true);
          //this.get('records').pushObject(newtrip);
         //this.set('editModal.editableRecords',[]);
         //this.get('editModal.editableRecords').pushObject(newtrip);
         const newTripComponent = this.get('newTripComponent');
         this.get('editModal').open(newTripComponent, [newtrip], this.get('saveTripRecordFunction').bind(this));
       //}*/
    },

    willDestroyElement() {
      document.removeEventListener('keydown', this._onKeyDown);
    },

    saveTripRecordFunction(records, editComponentAction) {
      console.log(' saveTripRecordFunction ', editComponentAction);
      const me = this;
      return new Ember.RSVP.Promise((resolve, reject) => {
        let record = records.firstObject;
        record.save().then(() => {
          console.log('after saveTripRecordFunction ');
          const tripId = record.get('id');
          me.get('errorMessage').pushInfo({
            detail: `Trip '${tripId}' successfully saved`
          });
          record.set('lastId', record.get('id'));
          record.set('isForceDirty', false);

          if (!Ember.isBlank(editComponentAction) && editComponentAction === 'onApplyClick') {
            Ember.run(() => {
              let recordRefresh = [];
              let newtrip = (0, _populateEditTrip.default)(me.get('trip'), record, me.get('store'), me.get('geocode'));
              (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, false);
              recordRefresh.push(newtrip);
              let newTripComponent = me.get('editSwitchTripComponent');

              if (this.get('editModal.editComponent') !== null && this.get('editModal.editComponent') === this.get('editSwitchTripComponent')) {
                newTripComponent = me.get('editTripComponent');
              }

              me.get('editModal').open(newTripComponent, recordRefresh, me.get('saveTripRecordFunction').bind(me));
            });
          } else if (!Ember.isBlank(editComponentAction) && editComponentAction === 'onApplyClearClick') {
            Ember.run(() => {
              $('html,body,.tomtom-map').removeClass('custom-cursor');
              me.get('geocode').activateGeocode(false);
              me.get('geocode').deactivateGeocode();
              let recordRefresh = [];
              let newtrip = (0, _populateNewTrip.default)(me.get('trip'), me.get('store'));
              (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, false);
              newtrip.set('lastId', record.get('lastId'));
              recordRefresh.push(newtrip); //me.set('editableRecords',recordRefresh);
              //me.set('records',recordRefresh);

              let newTripComponent = me.get('newSwitchTripComponent');

              if (me.get('editModal.editComponent') !== null && me.get('editModal.editComponent') === me.get('newSwitchTripComponent')) {
                newTripComponent = me.get('newTripComponent');
              }

              me.get('editModal').open(newTripComponent, recordRefresh, me.get('saveTripRecordFunction').bind(me));
            });
          }
        }).catch(function (error) {
          console.log(error);
          reject(error);
        });
      });
    },

    saveCancelRecordFunction(records) {
      let me = this;
      return new Ember.RSVP.Promise((resolve, reject) => {
        records.forEach(record => {
          let tripId = record.id;
          let session = this.get('session');
          return this.get('ajax').patch(_apiUrls.API.tripManagementService.host + '/booking/' + record.id, {
            method: 'PATCH',
            //return this.get('ajax').delete(ENV.API.avlmBookingService.host+'/tripAssignmentAPI/taapi/booking/'+record.id, {
            //method: 'DELETE',  
            contentType: "application/json",
            dataType: "text",
            headers: {
              "Authorization": `Bearer ${session.data.authenticated.token}`
            },
            data: {
              "data": {
                "type": "booking",
                "id": record.id,
                "attributes": {
                  "status": "cancelled"
                }
              }
            }
          }).then(response => {
            record.set('isForceDirty', false);
            me.get('errorMessage').pushInfo({
              detail: `Trip '${tripId}' successfully cancelled`
            });
            let newtrip = (0, _populateNewTrip.default)(me.get('trip'), me.get('store'));
            (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, true); //me.get('records').pushObject(newtrip);
            //this.set('editModal.editableRecords',[]);
            //this.get('editModal.editableRecords').pushObject(newtrip);

            const newTripComponent = me.get('newTripComponent');
            me.get('editModal').open(newTripComponent, [newtrip], this.get('saveTripRecordFunction').bind(me));
          }).catch(function (error) {
            me.set('editModal.errors', []);

            if (error.payload && error.payload.message) {
              me.get('editModal.errors').pushObject(error.payload.message);
            }

            reject(error);
          });
        });
      });
    },

    sendMessageAssignTrip2RouteFunction(record, status, dsTripId) {
      let assignedDriver = record.get('assignRoute.assignedDriver');
      let assignedVehicle = record.get('assignRoute.assignedVehicle');
      let message = ` Trip ${status} for ${record.get('trip.rider.firstName')} ${record.get('trip.rider.firstName')},
                Pickup: ${record.get('trip.pick.location.streetAddress')} , ${record.get('trip.requestTime')} ,
                Dropoff: ${record.get('trip.drop.location.streetAddress')} `;
      let draftMessage = this.get('store').createRecord('tm-canned-message', {
        driverId: assignedDriver.get('externalId'),
        vehicleId: assignedVehicle.get('name'),
        routeId: `${record.get('assignRoute.id')}`,
        replyToMsgId: `${dsTripId}`,
        body: message
      });
      return draftMessage.save();
    },

    sendMessageTripMovefromRouteFunction(record, status, dsTripId) {
      let assignedDriver = record.get('dsTrip.cluster.route.assignedDriver');
      let assignedVehicle = record.get('dsTrip.cluster.route.assignedVehicle');
      let message = ` Trip ${status} for ${record.get('trip.rider.firstName')} ${record.get('trip.rider.firstName')},
                Pickup: ${record.get('trip.pick.location.streetAddress')} , ${record.get('trip.requestTime')} ,
                Dropoff: ${record.get('trip.drop.location.streetAddress')} `;
      let draftMessage = this.get('store').createRecord('tm-canned-message', {
        driverId: assignedDriver.get('externalId'),
        vehicleId: assignedVehicle.get('name'),
        routeId: `${record.get('dsTrip.cluster.route.id')}`,
        replyToMsgId: `${dsTripId}`,
        body: message
      });
      return draftMessage.save();
    },

    sendMessagesToVehicles(record, status) {
      let assignedDriver = record.get('dsTrip.cluster.route.assignedDriver');
      let assignedVehicle = record.get('dsTrip.cluster.route.assignedVehicle');
      let message = ` ${status} action performed by dispatch for ${record.get('trip.rider.firstName')} ${record.get('trip.rider.firstName')},
                Pick-up: ${record.get('trip.pick.location.streetAddress')} , ${record.get('trip.requestTime')} `;
      let draftMessage = this.get('store').createRecord('tm-canned-message', {
        // TODO: should this be `driver.externalId`?
        driverId: assignedDriver.get('externalId'),
        vehicleId: assignedVehicle.get('name'),
        replyToMsgId: `${record.get('id')}`,
        body: message // TODO: how do we get the current route ID for a vehicle?
        // routeId: driver.get('routeId')

      });
      return draftMessage.save();
    },

    saveAssignTrip2RouteFunction(records) {
      const me = this;
      let tripId = '';
      let dsTripId = '';
      let updateRecord = null;
      return new Ember.RSVP.Promise((resolve, reject) => {
        records.forEach(record => {
          tripId = record.get('id');
          updateRecord = record;
          const tripScheduleData = this.get('trip').tripScheduleData();
          tripScheduleData.data.id = tripId; // external id is BS trip id

          tripScheduleData.included = []; // a work around because service rejects this request without it.

          const session = this.get('session');
          const json = JSON.stringify(tripScheduleData);
          return this.get('ajax').put(_apiUrls.API.tripService.host + '/trip/' + tripId + '/export/', {
            method: 'PUT',
            contentType: "application/json",
            headers: {
              "Authorization": `Bearer ${session.data.authenticated.token}`
            },
            data: json
          }).then(response => {
            record.set('isForceDirty', false);
            dsTripId = response.data[0].id;
            return this.get('ajax').put(_apiUrls.API.dispatchService.host + `/route/` + record.get('assignRoute.id') + '/tripTransfer/' + dsTripId, {
              method: 'PUT',
              contentType: "application/json",
              headers: {
                "Authorization": `Bearer ${session.data.authenticated.token}`
              }
            }).then(response => {
              record.set('isForceDirty', false);
              me.get('activeContext').refreshTableContent('trip');
              me.get('errorMessage').pushInfo({
                detail: `Trip '${tripId}' successfully transferred to Route '${record.get('assignRoute.name')}'`
              });
              me.sendMessageAssignTrip2RouteFunction(record, 'Added', dsTripId);
            }).catch(function (error) {
              updateRecord.set('isForceDirty', false);
              me.get('activeContext').refreshTableContent('trip');
              me.get('errorMessage').pushInfo({
                detail: `Trip '${tripId}' successfully transferred to Route '${record.get('assignRoute.name')}'`
              });
              me.sendMessageAssignTrip2RouteFunction(record, 'Added', dsTripId); //reject(error);
            });
          }).catch(function (error) {
            reject(error);
          });
        });
      });
    },

    saveMoveTrip2RouteFunction(records) {
      const me = this;
      let tripId = '';
      let dsTripId = '';
      return new Ember.RSVP.Promise((resolve, reject) => {
        const session = this.get('session');
        records.forEach(record => {
          tripId = record.get('id');
          dsTripId = record.get('dsTrip.id');
          return this.get('ajax').put(_apiUrls.API.dispatchService.host + `/route/` + record.get('assignRoute.id') + '/tripTransfer/' + dsTripId, {
            method: 'PUT',
            contentType: "application/json",
            headers: {
              "Authorization": `Bearer ${session.data.authenticated.token}`
            }
          }).then(response => {
            record.set('isForceDirty', false);
            me.get('activeContext').refreshTableContent('trip');
            me.get('errorMessage').pushInfo({
              detail: `Trip '${tripId}' successfully transferred to Route '${record.get('assignRoute.name')}'`
            });
            me.sendMessageTripMovefromRouteFunction(record, 'Remove', dsTripId);
            me.sendMessageAssignTrip2RouteFunction(record, 'Added', dsTripId);
          }).catch(function (error) {
            reject(error);
            me.sendMessageTripMovefromRouteFunction(record, 'Remove', dsTripId);
            me.sendMessageAssignTrip2RouteFunction(record, 'Added', dsTripId);
          });
        });
      });
    },

    saveNoShowRecordFunction(records) {
      const me = this;
      return new Ember.RSVP.Promise((resolve, reject) => {
        records.forEach(record => {
          const noShowData = this.get('routeexecevent').noShowData();
          const prepareNoShowData = this.get('routeexecevent').prepareTripNoShowData(noShowData, record);
          const json = JSON.stringify(prepareNoShowData);
          const session = this.get('session');
          return this.get('ajax').post(_apiUrls.API.avlmService.host + '/production/route-exec-event', {
            method: 'POST',
            contentType: "application/json",
            headers: {
              "Authorization": `Bearer ${session.data.authenticated.token}`
            },
            data: json
          }).then(response => {
            record.set('isForceDirty', false);
            record.set('id', response.data.id);
            me.get('activeContext').refreshTableContent('route');
            me.sendMessagesToVehicles(record, 'No Show');
          }).catch(function (error) {
            reject(error);
          });
        });
      });
    },

    createSubscriptionRecord1() {
      const currentDate = new Date();
      const subscriptions = [];
      const exclusion = this.get('store').createRecord('subscription-exclusion');
      const recurrencePattern = this.get('store').createRecord('subscription-recurrence-pattern', {
        type: 'daily',
        recurring: false
      });
      const subscription = this.get('store').createRecord('subscription', {
        requestTime: currentDate,
        exclusions: [exclusion],
        recurrencePatterns: [recurrencePattern]
      });
      subscriptions.push(subscription);
      return subscriptions;
    },

    createSubscriptionRecord() {
      const currentDate = new Date();
      const subscriptions = [];
      const subscription = {
        start: null,
        end: null,
        dailyDays: "",
        dailyDaysdailyDays: "",
        excludeRangeDate: [],
        endOptions: [],
        endSelected: "",
        monthlyType: "",
        agenda: {
          periodType: "weekly",
          items: [{
            type: "Day",
            time: "2019-02-08T02:48:17.859Z"
          }]
        },
        exceptions: [{
          exceptionDate: null,
          time: null
        }],
        exclusions: [null],
        type: 'daily',
        separationCount: 1,
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        dayOfMonth: null,
        weekOfMonth: null,
        monthOfYear: null,
        everyweekday: '',
        pattern: '',
        recurring: false
      };
      subscriptions.push(subscription);
      return subscriptions;
    },

    actions: {
      createNew() {
        const newtrip = (0, _populateNewTrip.default)(this.get('trip'), this.get('store'));
        (0, _populateAccount.default)(newtrip, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), newtrip.acctCardNr, true); //this.get('records').pushObject(newtrip);
        //this.createSubscriptionRecord();
        //this.set('editModal.editableRecords',[]);
        //this.get('editModal.editableRecords').pushObject(newtrip);

        const newTripComponent = this.get('newTripComponent');
        this.get('editModal').open(newTripComponent, [newtrip], this.get('saveTripRecordFunction').bind(this));
      }

    },

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({
        name: 'Add Trip',
        accessKey: this.get('config.addAccessKey'),
        action: function () {
          let newtrip = (0, _populateNewTrip.default)(this.get('trip'), this.get('store'));
          (0, _populateAccount.default)(newtrip, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), newtrip.acctCardNr, true); //this.get('records').pushObject(newtrip);
          //this.set('editModal.editableRecords',[]);
          //this.get('editModal.editableRecords').pushObject(newtrip);

          const newTripComponent = this.get('newTripComponent');
          this.get('editModal').open(newTripComponent, [newtrip], this.get('saveTripRecordFunction').bind(this));
        }.bind(this)
      }); //TODO

      this.set('singleActions', [{
        name: 'Edit',
        action: model => {
          const trip = model;
          this.store.findRecord('tm-booking', trip.get('id'), {
            reload: true
          }).then(updatetrip => {
            console.log('updatetrip ', updatetrip);
            updatetrip = (0, _populateEditTrip.default)(this.get('trip'), updatetrip, this.get('store'), this.get('geocode'));
            (0, _populateAccount.default)(updatetrip, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), updatetrip.acctCardNr, false); // this.get('records').pushObject(updatetrip);
            //this.set('editModal.editableRecords',[]);
            //this.set('editModal.editableRecords').push(updatetrip);

            const editTripComponent = this.get('editTripComponent');
            this.get('editModal').open(editTripComponent, [updatetrip], this.get('saveTripRecordFunction').bind(this));
          });
        }
      }, {
        name: 'Cancel Trip',
        action: model => {
          //TODO TMAPI
          //let cancelTypes = this.get('store').peekAll('cancel-type');
          //model.set('cancelType',cancelTypes.firstObject);
          model.set('timestamp', new Date()); //this.get('records').pushObject(model);
          //TODO for fix new trip screen
          //this.set('editModal.editableRecords',[]);
          //this.get('editModal.editableRecords').pushObject(model);

          let cancelTripComponent = this.get('cancelTripComponent');
          this.get('editModal').open(cancelTripComponent, [model], this.get('saveCancelRecordFunction').bind(this));
        }
      }, {
        name: 'Copy Trip',
        action: model => {
          //TODO TMAPI
          const trip = model;
          this.store.findRecord('tm-booking', trip.get('id'), {
            reload: true
          }).then(updatetrip => {
            const copytrip = (0, _populateCopyTrip.default)(this.get('trip'), updatetrip, this.get('store'), this.get('geocode'));
            (0, _populateAccount.default)(copytrip, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), copytrip.acctCardNr, false); //this.get('records').pushObject(copytrip);
            //this.set('editModal.editableRecords',[]);
            //this.get('editModal.editableRecords').pushObject(copytrip);

            const editTripComponent = this.get('editTripComponent');
            this.get('editModal').open(editTripComponent, [copytrip], this.get('saveTripRecordFunction').bind(this));
          });
        }
      }, {
        name: 'View Activity Log',
        action: model => {
          const trip = model;
          const me = this;
          this.get('store').query('tm-activity-log', {
            filter: {
              tripId: trip.get('id')
            }
          }).then(function (activityLogs) {
            Ember.run(() => {
              console.log('activityLogs ', activityLogs.get('content'));
              me.get('records').pushObject(activityLogs);
              me.set('editmodalcontext.modelWidgetPath', 'activity-log-widget');
              me.set('editmodalcontext.showWidgetModal', false);
              me.set('editModal.showActivityLog', true);
              me.set('editmodalcontext.widgetModelData', activityLogs);
              const activityLogComponent = me.get('activityLogComponent');
              me.get('editModal').open(activityLogComponent, [activityLogs], null);
            });
          });
        }
      }, {
        name: 'Send Message',
        action: trip => {
          this.sendMessageToTrips([trip]);
        }
      }, {
        name: 'Add Log Entry',
        action: () =>
        /* models */
        {}
      }, {
        name: 'Re Dispatch',
        action: () =>
        /* models */
        {//TODO TMAPI

          /*model.set('assignRoute',null);
          let assignTrip2RouteComponent = this.get('assignTrip2RouteComponent');
          this.get('editModal').open(assignTrip2RouteComponent, [model], this.get('saveAssignTrip2RouteFunction').bind(this));*/
        }
      }, {
        name: 'Forward',
        action: model => {//TODO TMAPI

          /*let noShowReasonCodes = this.get('store').peekAll('no-show-reason-code');
          model.set('noShowReason',noShowReasonCodes.firstObject);
          model.set('timestamp',new Date());
           let noShowComponent = this.get('noShowComponent');
          this.get('editModal').open(noShowComponent, [model], this.get('saveNoShowRecordFunction').bind(this));*/
        }
      }]);
      this.set('bulkActions', [{
        name: 'Send Message',
        action: trips => {
          this.sendMessageToTrips(trips);
        }
      }]);
    },

    sendMessageToTrips(trips) {
      let me = this;
      let draftMessages = trips.map(trip => {
        let newTestMessage = (0, _populateNewTextMessage.default)(me.get('textmessage'), this.get('store'), null, null, trip);
        return newTestMessage;
      });
      this.get('workspace').pushState('addNewMessage', {
        draftMessages
      });
    },

    rowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),
    oneDayTimeline: Ember.computed('workspace.{startDate,endDate}', function () {
      const startDate = (0, _moment.default)(this.get('workspace.startDate')).format('YYYY-MMM-D');
      const endDate = (0, _moment.default)(this.get('workspace.endDate')).format('YYYY-MMM-D');
      return startDate === endDate;
    }),

    dragOver(event) {
      event.preventDefault();
      if (this.get('dragCoordinator.widgetType') !== 'routes') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drop onto widget's other elements.

      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).addClass('drag-target');
    },

    dragLeave(event) {
      event.preventDefault();
      const rowElement = (0, _findRowRecord.findRowElement)(event.target);
      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).removeClass('drag-target');
    },

    drop(event) {
      event.preventDefault();
      event.stopPropagation();
      if (this.get('dragCoordinator.widgetType') !== 'routes') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target);
      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).removeClass('drag-target');
      const sourceRowRecord = this.get('dragCoordinator').getSourceRow(event.dataTransfer.getData('text'));
      if (Ember.isBlank(sourceRowRecord)) return;
      let targetRowRecord = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('rowGroup'));
      this.assignCluster(targetRowRecord, sourceRowRecord);
    },

    assignCluster(tripRow, routeRow) {
      const tripRecord = tripRow.get('record');
      const routeRecord = routeRow.get('record');

      if (routeRecord.constructor.modelName !== 'route') {
        return;
      }

      let cluster = routeRecord.get('clusters').firstObject;

      if (Ember.isBlank(cluster)) {
        cluster = this.get('store').createRecord('cluster', {
          route: routeRecord
        });
        tripRecord.set('cluster', cluster);
        cluster.save();
      } else {
        tripRecord.set('cluster', cluster);
        tripRecord.save();
      }
    },

    //TODO
    singleActions: Ember.computed('table.rows.@each.{selected,status}', function () {
      const scheduleTripAction = this.get('scheduleTripAction');
      const noShowTripAction = this.get('noShowTripAction');
      const cancelTripAction = this.get('cancelTripAction');
      const editTripAction = this.get('editTripAction');
      const moveTripAction = this.get('moveTripAction');
      const waitlistTripAction = this.get('waitlistTripAction');
      const viewActivityLogAction = this.get('viewActivityLogAction');
      let row = this.get('table.rows');
      if (!row) return [];

      switch (row.get('status')) {
        case 'Scheduled':
          {
            return [moveTripAction, waitlistTripAction, noShowTripAction, cancelTripAction, editTripAction, viewActivityLogAction];
          }

        case 'WaitList':
          {
            return [scheduleTripAction, cancelTripAction, editTripAction, viewActivityLogAction];
          }

        default:
          {
            return [viewActivityLogAction];
          }
      }
    })
  });

  _exports.default = _default;
});