define("adept-iq/pods/components/side-drawer/map-label-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qFzQSuww",
    "block": "{\"symbols\":[\"group\",\"label\"],\"statements\":[[7,\"table\"],[11,\"class\",\"filter-settings-table map-filter-settings\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"th\"],[11,\"class\",\"header-title\"],[11,\"colspan\",\"3\"],[9],[0,\"\\n      \"],[7,\"h4\"],[9],[0,\"Labels\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"th\"],[11,\"class\",\"reorder-column\"],[9],[10],[0,\"\\n    \"],[7,\"th\"],[11,\"class\",\"visibility-column\"],[9],[7,\"span\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"toggleCheckBoxes\"],null]],[12,\"class\",[27,\"if\",[[23,[\"isEyeOpen\"]],\"fa fa-eye\",\"fa fa-eye-slash\"],null]],[9],[10],[10],[0,\"\\n    \"],[7,\"th\"],[11,\"class\",\"label-column\"],[9],[0,\"Label\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"sortable-group\",null,[[\"tagName\",\"onChange\"],[\"tbody\",\"onReorderItems\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,[\"sortedLabels\"]]],null,{\"statements\":[[4,\"sortable-item\",null,[[\"tagName\",\"model\",\"group\",\"handle\"],[\"tr\",[22,2,[]],[22,1,[]],\".handle\"]],{\"statements\":[[0,\"\\n        \"],[7,\"td\"],[11,\"class\",\"reorder-column handle\"],[9],[0,\"\\n          \"],[7,\"img\"],[11,\"src\",\"/handles.png\"],[11,\"class\",\"reorder-handles\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"td\"],[11,\"class\",\"visibility-column\"],[9],[0,\"\\n          \"],[1,[27,\"input\",null,[[\"type\",\"checked\",\"change\"],[\"checkbox\",[27,\"readonly\",[[22,2,[\"isVisible\"]]],null],[27,\"action\",[[22,0,[]],\"setVisibility\",[22,2,[]]],null]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"td\"],[11,\"class\",\"label-column\"],[9],[0,\"\\n          \"],[1,[22,2,[\"label\"]],false],[0,\"\\n        \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/side-drawer/map-label-settings/template.hbs"
    }
  });

  _exports.default = _default;
});