define("adept-iq/utils/populate-account", ["exports", "adept-iq/utils/check-address-flags", "adept-iq/utils/populate-address", "adept-iq/utils/populate-fixed-price", "adept-iq/utils/check-address-overwrite", "adept-iq/utils/check-value", "lodash"], function (_exports, _checkAddressFlags, _populateAddress, _populateFixedPrice, _checkAddressOverwrite, _checkValue, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = populateAccount;

  function populateAccountData(tripData, accountData, geocode, store, editmodalcontext, accountSearchValue, overWrite, phoneNumber) {
    // console.log(('accountSearchValue ',accountSearchValue);
    console.log('accountData ', accountData);
    let favoriteTrips = accountData.attributes.favoriteTrips;
    Ember.set(tripData, 'acctCardNr', accountSearchValue);
    Ember.set(tripData, 'account', accountData); // Ember.set(tripData,'passengers.0.firstName',accountData.attributes.contactFirstName);

    if (!Ember.isEmpty(favoriteTrips)) {
      Ember.set(tripData, 'editmodelcontext.accountTrips', favoriteTrips.filterBy('priceType', 'normal'));
      Ember.set(tripData, 'editmodelcontext.accountFixedPriceTrips', favoriteTrips.filterBy('priceType', 'fixedPrice'));
      Ember.set(editmodalcontext, 'showWidgetModal', false);
    } else {
      Ember.set(tripData, 'editmodelcontext.accountTrips', []);
      Ember.set(tripData, 'editmodelcontext.accountFixedPriceTrips', []);
      Ember.set(editmodalcontext, 'showWidgetModal', false);
    }

    if (overWrite) {
      let accountDriverNotes = accountData.attributes.notes.driver;
      let accountOperatorNotes = accountData.attributes.notes.dispatcher;
      Ember.set(tripData, 'editmodelcontext.predefinedDriverNotes.account', accountDriverNotes);
      Ember.set(tripData, 'editmodelcontext.predefinedOperatorNotes.account', accountOperatorNotes);

      if (!Ember.isBlank(phoneNumber)) {
        Ember.set(tripData, 'passengers.0.phone1.number', phoneNumber);
      } else {
        if (!Ember.isEmpty(accountData.attributes.phone1) && !Ember.isBlank(accountData.attributes.phone1.number)) {
          if (!Ember.isBlank(tripData.get('passengers.0.phone1.number')) && tripData.get('passengers.0.phone1.number') !== accountData.attributes.phone1.number) {
            let overwritePhonePop = true;
            overwritePhonePop = confirm('Do you want to Phone Number ');

            if (overwritePhonePop) {
              Ember.set(tripData, 'passengers.0.phone1.number', accountData.attributes.phone1.number);
            }
          } else {
            Ember.set(tripData, 'passengers.0.phone1.number', accountData.attributes.phone1.number);
          }
        }
      }

      let options = store.peekAll('tm-common-operational-attribute');
      let requiredAttributes = accountData.attributes.requiredAttributes;
      let accountAttributes = options.filter(function (o1) {
        // if match found return false
        return _lodash.default.findIndex(requiredAttributes, {
          'type': o1.type
        }) === -1 ? false : true;
      });
      Ember.set(tripData, 'editmodelcontext.attributes.account', accountAttributes);
      let defaultFavoriteTrip = null;

      if (!Ember.isBlank(accountData.attributes.defaultFavoriteTripId) && accountData.attributes.defaultFavoriteTripId !== 0) {
        let defaultFavoriteTripFilter = favoriteTrips.filterBy('tripId', "" + accountData.attributes.defaultFavoriteTripId);
        defaultFavoriteTrip = defaultFavoriteTripFilter[0];
      }

      if (!Ember.isBlank(defaultFavoriteTrip) && defaultFavoriteTrip.priceType === 'normal') {
        let addressData = defaultFavoriteTrip.pickup.address;
        let overwriteAddress = true;
        let overwriteAddressPop = (0, _checkAddressOverwrite.default)(tripData.stopPoints[0].address, addressData);

        if (overwriteAddressPop) {
          overwriteAddress = confirm('Do you want to overwrite Address');
        }

        if (overwriteAddress) {
          (0, _populateAddress.default)(tripData.stopPoints[0].address, addressData, geocode, store, 'pickup', tripData);

          try {
            if (!Ember.isBlank(defaultFavoriteTrip.notes)) {
              Ember.set(tripData, 'editmodelcontext.predefinedDriverNotes.address', (0, _checkValue.default)(defaultFavoriteTrip.notes.driver));
              Ember.set(tripData, 'editmodelcontext.predefinedOperatorNotes.address', (0, _checkValue.default)(defaultFavoriteTrip.notes.dispatcher));
            } else {
              Ember.set(tripData, 'editmodelcontext.predefinedDriverNotes.address', '');
              Ember.set(tripData, 'editmodelcontext.predefinedOperatorNotes.address', '');
            }
          } catch (e) {}

          (0, _checkAddressFlags.default)(tripData);
        }
      } else {
        if (!Ember.isBlank(defaultFavoriteTrip)) {
          (0, _populateFixedPrice.default)(tripData, defaultFavoriteTrip, geocode, store);
        }
      }
    }
  }

  function populateAccount(tripRecord, accountData, geocode, store, editmodalcontext, searchservice, accountSearchPath, accountSearchValue, overWrite, phoneNumber) {
    if (!Ember.isEmpty(accountData)) {
      populateAccountData(tripRecord, accountData, geocode, store, editmodalcontext, accountSearchValue, overWrite, phoneNumber);
      return accountData;
    } else {
      searchservice.getRiderProfile(accountSearchPath, accountSearchValue).then(result => {
        if (!Ember.isEmpty(result)) {
          let accountData = result.firstObject;
          populateAccountData(tripRecord, accountData, geocode, store, editmodalcontext, accountSearchValue, overWrite, phoneNumber);
          return accountData;
        }
      });
    }

    return;
  }
});