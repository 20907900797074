define("adept-iq/serializers/eligibility-type", ["exports", "adept-iq/serializers/-dispatch"], function (_exports, _dispatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    normalizeResponse: function (store, primaryModelClass, payload) {
      let eligibilityTypes = [];
      payload.map(configItem => {
        let data = {
          attributes: {
            name: configItem.name
          },
          id: configItem.name,
          type: 'eligibility-type'
        };
        eligibilityTypes.push(data);
      });
      return {
        data: eligibilityTypes
      };
    }
  });

  _exports.default = _default;
});