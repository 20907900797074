define("adept-iq/pods/components/dashboard-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Uuyefl8",
    "block": "{\"symbols\":[\"section\",\"dashboard\"],\"statements\":[[4,\"secondary-window\",null,[[\"title\",\"onXButtonClick\",\"isLoading\"],[\"Open Workspace\",[27,\"action\",[[22,0,[]],\"onXButtonClick\"],null],[23,[\"isLoading\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,[\"sections\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"dashboard-picker-section\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"dashboard-picker-section-title\"],[9],[0,\"\\n        \"],[1,[22,1,[\"title\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n\"],[4,\"each\",[[22,1,[\"dashboards\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[12,\"class\",[28,[\"dashboard-picker-item \",[27,\"if\",[[22,2,[\"isDirty\"]],\"is-dirty\",\"is-clean\"],null],\" \",[27,\"if\",[[27,\"eq\",[[22,2,[]],[23,[\"workspace\",\"dashboardModel\"]]],null],\"is-open\"],null]]]],[9],[0,\"\\n          \"],[1,[22,2,[\"name\"]],false],[0,\"\\n\\n          \"],[7,\"div\"],[11,\"class\",\"spacer\"],[9],[10],[0,\"\\n\\n\"],[4,\"if\",[[27,\"not-eq\",[[22,2,[]],[23,[\"workspace\",\"dashboardModel\"]]],null]],null,{\"statements\":[[0,\"            \"],[7,\"button\"],[11,\"class\",\"btn-primary data-test-open-btn\"],[12,\"onclick\",[27,\"perform\",[[23,[\"transitionTask\"]],[22,2,[]]],null]],[9],[0,\"\\n\\n              Open\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"button\"],[11,\"class\",\"btn-primary data-test-reload-btn open-last-saved\"],[12,\"onclick\",[27,\"perform\",[[23,[\"transitionTask\"]],[22,2,[]]],null]],[9],[0,\"\\n\\n              Open Last Saved\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/dashboard-picker/template.hbs"
    }
  });

  _exports.default = _default;
});