define("adept-iq/mixins/zone-style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    status: Ember.computed('asap', function () {
      const jobCount = this.get('asap');

      if (jobCount) {
        let freeVehicle = 0;

        if (!Ember.isBlank(this.get('freeVehicle'))) {
          freeVehicle = this.get('freeVehicle');
        }

        let stcVehicle = 0;

        if (!Ember.isBlank(this.get('stcVehicle'))) {
          stcVehicle = this.get('stcVehicle');
        }

        const totalAvailableVehicle = freeVehicle + stcVehicle;

        if (jobCount > totalAvailableVehicle) {
          return 'high';
        } else if (jobCount > freeVehicle) {
          return 'medium';
        } else {
          return 'normal';
        }
      } else {
        return 'normal';
      }
    }),
    style: Ember.computed.alias('status')
  });

  _exports.default = _default;
});