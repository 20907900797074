define("adept-iq/pods/components/iq-widgets/alert-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/alert-widget/config", "moment", "adept-iq/utils/range"], function (_exports, _component, _config, _moment, _range) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    config: _config.default,
    sortId: 'generatedAt',
    sortAsc: false,

    /*
      Sample alerts:
        'late':
          "Route <route number> running <OTP> min <behind / ahead of> plan: <number of trips> trips with late ETAs."
        'danger':
          "Route <route number> running <OTP> min <behind / ahead of> plan: <number of trips> trips with ETAs in danger. "
        'info':
          "<number> unread messages"
          "<driver name> not signed in to route <route number> on time"
          "<driver name> not back from break on time"
          "<number> trips not scheduled"
          ...
         Alert model:
          generatedAt: attr('date'),
          alert: attr('string'),
          category: attr('string'),
          vehicles: hasMany('vehicle'),
          drivers: hasMany('driver'),
          routes: hasMany('route'),
    */
    fakeData: Ember.computed('', function () {
      let store = this.get('store');
      let vehicles = this.get('vehicleRecords');
      let routes = this.get('routeRecords');
      let drivers = this.get('driverRecords');
      const arr = (0, _range.default)(12);
      const category = ['late', 'danger', 'info'];
      const descriptionsAndRecords = {
        'late': alertData => {
          alertData.otp = 1; // outside of service window is otp >= 1

          let routeRecord = routes.next().value;

          if (routeRecord) {
            alertData.routes = [routeRecord];
            let b = Math.floor(Math.random() * 20) + 10;
            let c = Math.floor(Math.random() * 10) + 1;
            let sign = Math.random() > 0.5 ? 'behind' : 'ahead of';
            alertData.alert = `Route ${routeRecord.get('name')}
                                running ${b}
                                min ${sign}
                                plan: ${c} trips with late ETAs.`;
          }
        },
        'danger': alertData => {
          alertData.otp = Math.random() / 5 + 0.8; // danger service window 0.8 < otp < 1.0

          let routeRecord = routes.next().value;

          if (routeRecord) {
            alertData.routes = [routeRecord];
            let b = Math.floor(Math.random() * 20) + 10;
            let c = Math.floor(Math.random() * 10) + 1;
            let sign = Math.random() > 0.5 ? 'behind' : 'ahead of';
            alertData.alert = `Route ${routeRecord.get('name')}
                              running ${b}
                              min ${sign}
                              plan: ${c} trips with ETAs in danger.`;
          }
        },
        'info': alertData => {
          alertData.otp = Math.random() / 5 * 4; // safe service window 0.0 < otp < 0.8

          let c = Math.floor(Math.random() * 10) + 1;
          let alerts = [alertData => {
            alertData.alert = `${c} unread messages`;
          }, alertData => {
            let vehicleRecord = vehicles.next().value;
            alertData.vehicles = [vehicleRecord];
            alertData.alert = `Vehicle ${vehicleRecord.get('name')} communication lost`;
          }, alertData => {
            alertData.vehicles = [vehicles.next().value, vehicles.next().value, vehicles.next().value];
            alertData.alert = `3 vehicles have no drivers:
                                ${alertData.vehicles[0].get('name')},
                                ${alertData.vehicles[1].get('name')},
                                ${alertData.vehicles[2].get('name')},
                                ...`;
          }, alertData => {
            alertData.drivers = [drivers.next().value, drivers.next().value, drivers.next().value];
            alertData.alert = `3 drivers have no vehicles:
                                ${alertData.drivers[0].get('driverId')},
                                ${alertData.drivers[1].get('driverId')},
                                ${alertData.drivers[2].get('driverId')},
                                ...`;
          }];
          alerts[Math.floor(Math.random() * alerts.length)](alertData);
        }
      };
      return arr.map(() => {
        let alertData = {},
            cat = category[Math.floor(Math.random() * category.length)];
        let a = Math.floor(Math.random() * -20);
        alertData.category = cat;
        descriptionsAndRecords[cat](alertData);
        alertData.generatedAt = (0, _moment.default)().add(a, 'm').format('LT');
        alertData.id = Math.floor(Math.random() * 1000000000);
        let alert = store.createRecord('alert', alertData);
        return alert;
      });
    }),
    recordArrayIterator: function* (recordArray, startIndex) {
      while (startIndex < recordArray.length) {
        yield recordArray.objectAtContent(startIndex++);
      }
    },

    // overrides `generic-widgets/column-widget/component`
    fetchDataQuery() {
      // in lieu of actual API
      console.warn('ALERT-WIDGET fetchDataQuery'); // eslint-disable-line no-console

      return new Promise((resolve, reject) => {
        let store = this.get('store');
        let promises = [store.findAll('vehicle'), store.findAll('route'), store.findAll('ss-driver')];
        Promise.all(promises).then(data => {
          this.set('vehicleRecords', this.recordArrayIterator(data[0], 0));
          this.set('routeRecords', this.recordArrayIterator(data[1], 0));
          this.set('driverRecords', this.recordArrayIterator(data[2], 0));
          resolve(this.sortRowData(this.get('fakeData')));
        }).catch(e => reject(e));
      });
    },

    // TODO: consider putting this in the `alert` model, as a CP.
    categorize(otp) {//wait for real data
    },

    sortRowData(rows) {
      let sign = this.get('sortAsc') ? 1 : -1;
      return rows.sort((a, b) => {
        let categoryA = this.categorize(a.get('otp'));
        let categoryB = this.categorize(b.get('otp'));

        if (categoryA !== categoryB) {
          return categoryB - categoryA;
        } else {
          switch (this.get('sortId')) {
            case 'generatedAt':
              {
                if (a.get('generatedAt') > b.get('generatedAt')) {
                  return 1 * sign;
                } else {
                  return -1 * sign;
                }
              }

            case 'alert':
              {
                if (a.get('alert') > b.get('alert')) {
                  return 1 * sign;
                } else {
                  return -1 * sign;
                }
              }
          }
        }
      });
    },

    processCheckedRecords(checkedRecords, relationName) {
      let modelNames = {
        'routes': 'route',
        'vehicles': 'ss-vehicle',
        'drivers': 'ss-driver'
      };
      let modelRecords = checkedRecords.mapBy(relationName);
      let modelName = modelNames[relationName];
      modelRecords = modelRecords.reduce((a, b) => {
        if (Ember.isBlank(a) && Ember.isBlank(b)) {
          return;
        } else if (Ember.isBlank(a)) {
          return b;
        } else if (Ember.isBlank(b)) {
          return a;
        } else {
          return a.concat(b);
        }
      }, []);
      this.get('activeContext').setCheckedRecords(modelName, modelRecords || []);
    },

    actions: {
      // overrides `generic-widgets/column-widget/component`
      onColumnClick(column) {
        if (!column.sorted) return;
        this.set('sortId', column.get('id'));
        this.set('sortAsc', column.get('ascending'));
        Ember.run.scheduleOnce('afterRender', this, 'refreshData');
      },

      // overrides `iq-widgets/column-widget/component`
      onRowCheckboxToggle() {
        let relationNames = ['routes', 'vehicles', 'drivers'];
        let checkedRecords = this.get('table.rows').filterBy('isChecked');
        relationNames.map(relationName => this.processCheckedRecords(checkedRecords, relationName));
      }

    }
  });

  _exports.default = _default;
});