define("adept-iq/pods/components/iq-widgets/rider-profile-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/rider-profile-widget/config", "adept-iq/utils/populate-call-line", "adept-iq/utils/populate-account", "adept-iq/utils/check-address-flags", "adept-iq/utils/focus_column_widget_row", "adept-iq/utils/check-value", "adept-iq/config/environment", "adept-iq/config/api-urls"], function (_exports, _component, _config, _populateCallLine, _populateAccount, _checkAddressFlags, _focus_column_widget_row, _checkValue, _environment, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['rider-profie-widget'],
    editModal: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    searchservice: Ember.inject.service(),
    riderAddresses: null,
    accountSearchPath: _apiUrls.API_SUB_URL.accountSearchPath,
    config: _config.default,
    defaultIncludes: INCLUDES,

    init() {
      this._super(...arguments);

      try {
        let recordId = 1;
        this.get('editmodalcontext.widgetModelData').forEach(record => {
          record.attributes.id = "" + recordId + "";
          recordId++;
          record.attributes.pickupFreeFormAddress = "";
          record.attributes.pickupCity = "";

          if (!Ember.isEmpty(record.attributes.favoriteTrips) && !Ember.isBlank(record.attributes.favoriteTrips[0].pickup) && !Ember.isBlank(record.attributes.favoriteTrips[0].pickup.address)) {
            record.attributes.pickupFreeFormAddress = (0, _checkValue.default)(record.attributes.favoriteTrips[0].pickup.address.streetNr) + " " + (0, _checkValue.default)(record.attributes.favoriteTrips[0].pickup.address.streetName);
            record.attributes.pickupCity = (0, _checkValue.default)(record.attributes.favoriteTrips[0].pickup.address.region);
          }

          record.attributes.dropoffFreeFormAddress = "";
          record.attributes.dropoffCity = "";

          if (!Ember.isEmpty(record.attributes.favoriteTrips) && !Ember.isBlank(record.attributes.favoriteTrips[0].dropoff) && !Ember.isBlank(record.attributes.favoriteTrips[0].dropoff.address)) {
            record.attributes.dropoffFreeFormAddress = (0, _checkValue.default)(record.attributes.favoriteTrips[0].dropoff.address.streetNr) + " " + (0, _checkValue.default)(record.attributes.favoriteTrips[0].dropoff.address.streetName);
            record.attributes.dropoffCity = (0, _checkValue.default)(record.attributes.favoriteTrips[0].dropoff.address.region);
          }

          this.get('records').pushObject(record.attributes);
        });
        let me = this;
        Ember.run.later(function () {
          (0, _focus_column_widget_row.default)(me);
        }, 50);
      } catch (e) {}
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({});
      this.set('singleActions', []);
    },

    actions: {
      onRowRightClick(row, event) {},

      onRowClick(row, event) {
        let tripData = this.currentRecord;
        let callLine = row.get('record');
        (0, _populateCallLine.default)(tripData, callLine, this.get('geocode'), this.get('store'));
        (0, _populateAccount.default)(tripData, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), callLine.account.accountId);
        (0, _checkAddressFlags.default)(tripData);
        this.closeWidget();
      }

    }
  });

  _exports.default = _default;
});