define("adept-iq/pods/maximized/controller", ["exports", "adept-iq/config/widget-types"], function (_exports, _widgetTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    maximizer: Ember.inject.service(),
    widgetTypesHash: _widgetTypes.widgetTypesHash,
    widget: Ember.computed.alias('maximizer.maximizedWidget')
  });

  _exports.default = _default;
});