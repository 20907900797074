define("adept-iq/pods/components/iq-widgets/report-widget2/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PF+0bxqh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"style\",\"height:100%;width:100%;display:block;align:centers\"],[9],[0,\"\\n\"],[1,[27,\"tableau-view\",null,[[\"sitePath\",\"viewPath\"],[\"#/site/guilford\",\"TABLEAU_TODAYSDATA1/TODAYSJOBCOUNT_?:tabs=no\"]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/report-widget2/template.hbs"
    }
  });

  _exports.default = _default;
});