define("adept-iq/config/key-maps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    Default: {
      Save: {
        action: "onApplyClick",
        title: "Save",
        accessKey: "Ctrl+esc",
        global: true,
        preventDefault: true
      },
      SaveClear: {
        action: "onApplyClearClick",
        title: "Save and Clear",
        accessKey: "esc",
        global: true,
        preventDefault: true
      },
      Clear: {
        action: "onClearClick",
        title: "Clear",
        accessKey: "F7",
        global: true,
        preventDefault: true
      },
      Copy: {
        action: "onCopyClick",
        title: "Copy",
        accessKey: "Ctrl+F12",
        global: true,
        preventDefault: true
      }
    },
    Trip: {
      Save: {
        action: "onApplyClick",
        title: "Book",
        accessKey: "Shift+esc",
        icon: "check",
        global: true,
        isDisabledCondition: true,
        preventDefault: true
      },
      SaveClear: {
        action: "onApplyClearClick",
        title: "Book and Clear",
        accessKey: "esc",
        icon: "check-square-o",
        global: true,
        isDisabledCondition: true,
        preventDefault: true
      },
      Cancel: {
        action: "onCancelClick",
        title: "Cancel Job",
        accessKey: "Shift+-",
        icon: "minus",
        global: true,
        preventDefault: true
      },
      Clear: {
        action: "onClearClick",
        title: "Clear Job",
        accessKey: "Shift+!",
        icon: "refresh",
        global: true,
        preventDefault: true
      },
      Copy: {
        action: "onCopyClick",
        title: "Copy Job",
        accessKey: "Shift+@",
        icon: "copy",
        global: true,
        preventDefault: true
      },
      Search: {
        action: "onSearchClick",
        title: "Search Job",
        accessKey: "Shift+&",
        icon: "search",
        global: true,
        preventDefault: true
      },
      List: {
        action: "onActivitLogs",
        title: "Activity Logs",
        accessKey: "Shift+*",
        icon: "list",
        global: true,
        preventDefault: true
      }
    },
    search: {
      Clear: {
        action: "onClearClick",
        title: "Clear Search",
        accessKey: "Shift+!",
        icon: "refresh",
        global: true,
        preventDefault: true
      },
      NewTrip: {
        action: "onNewClick",
        title: "Add Trip",
        accessKey: "Shift++",
        icon: "plus",
        global: true,
        preventDefault: true
      }
    },
    cancel: {
      SaveClear: {
        action: "onApplyClearClick",
        title: "Cancel",
        accessKey: "esc",
        icon: "check-square-o",
        global: true,
        isDisabledCondition: true,
        preventDefault: true
      },
      NewTrip: {
        action: "onNewClick",
        title: "Add Trip",
        accessKey: "Shift++",
        icon: "plus",
        global: true,
        preventDefault: true
      }
    }
  };
  _exports.default = _default;
});