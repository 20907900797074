define("adept-iq/pods/components/table/columns/check-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table-check-box-column'],
    isChecked: Ember.computed('table.rows.@each.isChecked', function () {
      const rows = this.get('table.rows'); // it's weird to see checkbox when no records are shown

      if (!rows || rows.length === 0) return false;
      return rows.isEvery('isChecked');
    }),
    actions: {
      onCheckboxToggle(event) {
        this.get('table.rows').setEach('isChecked', event.target.checked);
      }

    }
  });

  _exports.default = _default;
});