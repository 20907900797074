define("adept-iq/pods/components/form-components/search-datetimeflatpickr/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o81Ja2iB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"tr\"],[11,\"class\",\"datepickr-tr\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"td\"],[11,\"class\",\"cell-label\"],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"td\"],[11,\"class\",\"edit-cell\"],[9],[0,\"\\n    \"],[1,[27,\"ember-flatpickr\",null,[[\"locale\",\"allowInput\",\"placeholder\",\"dateFormat\",\"time_24hr\",\"enableTime\",\"minDate\",\"date\",\"onChange\",\"onClose\",\"onOpen\"],[\"en\",true,[23,[\"datePlaceholder\"]],[23,[\"dateFormat\"]],true,true,[23,[\"minDate\"]],[23,[\"valueFormatted\"]],[27,\"action\",[[22,0,[]],\"onChangeDate\"],null],[27,\"action\",[[22,0,[]],\"onClose\"],null],[27,\"action\",[[22,0,[]],\"onOpen\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/search-datetimeflatpickr/template.hbs"
    }
  });

  _exports.default = _default;
});