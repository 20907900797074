define("adept-iq/models/leg", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  const LegModel = Model.extend({
    requestTime: attr('date'),
    anchor: attr('string'),
    purpose: attr('string'),
    address: attr('nested'),
    coordinates: attr('nested'),
    pointOfInterest: attr('nested'),
    geoNode: attr('number'),
    stop: belongsTo('stop'),
    trip: belongsTo('trip'),
    origin: belongsTo('location'),
    destination: belongsTo('location'),
    rider: belongsTo('rider'),
    locations: hasMany('location'),
    extraPassengers: Ember.computed('', function () {
      const rider = Ember.get(this, 'rider');
      return rider.get('extraPassengers');
    }),
    equipments: Ember.computed('', function () {
      const rider = Ember.get(this, 'rider');
      return rider.get('equipments');
    }),
    // originlocations :  hasMany('location'),
    // destinationlocations :  hasMany('location'),
    segments: hasMany('segment'),
    segment: Ember.computed('segments', function () {
      if (Ember.get(this, 'segments') != null) {
        const segments = Ember.get(this, 'segments').toArray();
        return segments[0];
      } else {
        return null;
      }
    }),
    legTravelNeeds: hasMany('leg-travel-need'),
    noOfTravelNeeds: Ember.computed('', function () {
      const noOfTravelNeeds = Ember.get(this, 'legTravelNeeds');

      if (!Ember.isEmpty(noOfTravelNeeds)) {
        return noOfTravelNeeds.length;
      }

      return 0;
    }),
    sortedLegTravelNeeds: Ember.computed('legTravelNeeds', function () {
      return Ember.get(this, 'legTravelNeeds').sortBy('travelNeedType.name');
    }),

    /* originlocations1: computed('origin', function() {
         let originlocations =[];
         originlocations.push(get(this, 'origin'));
       return originlocations;
     }),
      destinationlocations1: computed('destination', function() {
         let destinationlocations =[];
         destinationlocations.push(get(this, 'destination'));
       return destinationlocations;
     }),*/
    tomtomFormattedAddress: Ember.computed('streetNr', 'streetAddress', 'locality', function () {
      let address = null;

      if (this.get('streetAddress') !== undefined) {
        address = {
          address: {
            freeformAddress: `${this.get('streetNr')} ${this.get('streetAddress')} ${this.get('region')}`
          }
        };
      }

      return address;
    })
  });
  var _default = LegModel;
  _exports.default = _default;
});