define("adept-iq/services/vehicleinfo", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    distanceUnit: 'mi',

    init() {
      this._super(...arguments);

      this.set('distanceUnit', _environment.default.APP.distanceUnit);
    },

    convertDistance(distanceInMeters) {
      const unit = this.distanceUnit;

      if (Ember.typeOf(distanceInMeters) !== 'number') {
        console.warn(`Invalid distance used for conversion = ${distanceInMeters}`); // eslint-disable-line no-console

        return distanceInMeters;
      }

      if (unit === 'mi') {
        return (distanceInMeters * 0.00062137).toFixed(0);
      } else if (unit === 'km') {
        return (distanceInMeters * 0.001).toFixed(0);
      }

      console.warn(`Invalid distance unit used for conversion = ${unit}`); // eslint-disable-line no-console

      return distanceInMeters;
    },

    vehicleInfoData() {
      const vehicleInfoData = {
        'data': {
          'type': 'vehicleInfo',
          'id': '',
          'attributes': {
            'state': 'active',
            'callsign': '',
            'device': {
              'hardwareId': null,
              'type': '',
              'OS': '',
              'appVersion': ''
            }
          }
        }
      };
      return vehicleInfoData;
    },

    prepareVehicleInfoData(vehicleInfoData, record) {
      vehicleInfoData.data.id = record.get('name');
      vehicleInfoData.data.attributes.callsign = record.get('name');
      return vehicleInfoData;
    }

  });

  _exports.default = _default;
});