define("adept-iq/pods/components/iq-widgets/bar-chart-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/chartjs-widget/component", "adept-iq/utils/filters", "adept-iq/pods/components/generic-widgets/column-widget/classes/filter-column"], function (_exports, _component, _filters, _filterColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    workspaceContext: Ember.inject.service(),
    classNames: ['bar-chart-widget'],
    widget: null,
    options: Ember.computed.alias('widget.state.dataType'),

    /* Applies the choice of data source. */
    chartRecords: Ember.computed('options.modelName', 'workspaceContext.workspaceData.[]', function () {
      const modelName = this.get('options.modelName');
      if (Ember.isBlank(modelName)) return [];
      const wrappedRecords = this.get('workspaceContext.workspaceData').filterBy('modelName', modelName);
      return wrappedRecords.map(a => a.record);
    }),

    /* Builds the chosen filters. */
    filterFunction: Ember.computed('options.columns', function () {
      const filterColumns = Object.values(this.get('options.columns')).map(col => new _filterColumn.default(col));
      const args = filterColumns.map(column => {
        return column.get('filterNode');
      });
      return (0, _filters.buildFilterFunction)((0, _filters.buildCompoundFilterNode)('and', args));
    }),

    /* Applies the choice of filters. */
    filteredRecords: Ember.computed('chartRecords', 'filterFunction', function () {
      return this.get('chartRecords').filter(this.get('filterFunction'));
    }),
    chartSum: Ember.computed.alias('filteredRecords.length'),

    /* Gets the choice for slicing the data. */
    slicedBy: Ember.computed('options.displayOptions', function () {
      if (Ember.isBlank(this.get('options.displayOptions'))) return;
      /*eslint-disable */

      return this.get('options.displayOptions').selected;
      /*eslint-enable */
    }),

    /* Applies the choice for slicing the data. */
    recordSlices: Ember.computed('filteredRecords', 'slicedBy', function () {
      const categories = {};
      const filteredRecords = this.get('filteredRecords');
      const slicedBy = this.get('slicedBy');
      if (Ember.isBlank(slicedBy)) return categories;
      const filterKey = this.get('slicedBy.id');
      const metricOption = this.get('slicedBy.metricOption.id');
      this.get('slicedBy.valueCategories').forEach(category => {
        switch (metricOption) {
          case 'count':
            categories[category.label] = filteredRecords.filterBy(filterKey, category.value).length;
            break;

          case 'sumOccurrences':
            categories[category.label] = filteredRecords.reduce((a, b) => {
              return a + b.get(filterKey).filter(item => item === category.value).length;
            }, 0);
            break;

          default:
            return;
        }
      }); // { 'label1': <value>, 'label2': <value> }

      return categories;
    }),

    /* Applies the choices for displaying data counts and/or percentages. */
    aggregatedSliceData: Ember.computed('recordSlices', 'options.visualOptions.@each.isChecked', function () {
      const visualOptions = this.get('options.visualOptions');
      const showValues = visualOptions.findBy('id', 'value').isChecked;
      const showPercentage = visualOptions.findBy('id', 'percentage').isChecked;
      const totalCount = this.get('chartSum');
      const data = [];
      Object.entries(this.get('recordSlices')).forEach(slice => {
        let label = slice[0];
        const numRecords = slice[1];

        if (showValues) {
          label += ', ' + numRecords;
        }

        if (showPercentage && totalCount !== 0) {
          label += ', ' + Math.floor(100 * numRecords / totalCount) + '%';
        }

        data.push({
          label: label,
          value: numRecords
        });
      }); // [{...}, {...}, ...]

      return data;
    }),
    chartData: Ember.computed('aggregatedSliceData', function () {
      const aggregatedData = this.get('aggregatedSliceData');
      if (Ember.isBlank(aggregatedData)) return {
        data: [],
        labels: []
      };
      const data = [];
      const labels = [];
      const backgroundColor = [];
      const colors = this.get('colors');
      aggregatedData.forEach((dataSlice, i) => {
        data.push(dataSlice.value);
        labels.push(dataSlice.label);
        backgroundColor.push(colors[i % colors.length]);
      });
      const datasets = [{
        data: data,
        backgroundColor: backgroundColor,
        borderWidth: 1
      }];
      return {
        datasets,
        labels
      };
    }),

    /* Applies the chart title input. */
    chartTitle: Ember.computed('widget.state.dataType.{name,title}', function () {
      const title = this.get('widget.state.dataType.title');
      if (Ember.isPresent(title)) return title;
      const name = this.get('widget.state.dataType.name');

      if (Ember.isPresent(name)) {
        return `${name} Bar Chart`;
      }

      return 'Bar Chart';
    }),
    chartOptions: Ember.computed('', function () {
      if (Ember.isBlank(this.get('widget.state'))) return {};
      const options = {
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              min: 0
            }
          }]
        }
      };
      return options;
    }),
    actions: {
      onFilterButtonClick() {
        const workspace = this.get('workspace');
        let topState = workspace.get('topState');

        while (topState === 'barChartWidget') {
          workspace.popState();
          topState = workspace.get('topState');
        }

        const displayName = 'Bar Chart';
        const widget = this.get('widget');
        workspace.pushState('barChartWidget', {
          displayName,
          widget
        });
      }

    }
  });

  _exports.default = _default;
});