define("adept-iq/pods/components/form-components/enum/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2MYOU9iD",
    "block": "{\"symbols\":[\"option\",\"option\"],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"dataFromModel\"]],true],null]],null,{\"statements\":[[4,\"component\",[[27,\"if\",[[23,[\"allowMultiple\"]],\"power-select-multiple\",\"power-select\"],null]],[[\"id\",\"selected\",\"options\",\"disabled\",\"onchange\",\"triggerId\"],[[27,\"readonly\",[[23,[\"triggerId\"]]],null],[27,\"readonly\",[[23,[\"selected\"]]],null],[27,\"readonly\",[[23,[\"options\"]]],null],[27,\"readonly\",[[23,[\"disabled\"]]],null],[27,\"action\",[[22,0,[]],\"onChange\"],null],[27,\"readonly\",[[23,[\"triggerId\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[22,2,[\"name\"]],false],[0,\"\\n    \"]],\"parameters\":[2]},null],[1,[21,\"id\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"dataFromModel\"]],false],null]],null,{\"statements\":[[4,\"component\",[[27,\"if\",[[23,[\"allowMultiple\"]],\"power-select-multiple\",\"power-select\"],null]],[[\"id\",\"selected\",\"options\",\"disabled\",\"onchange\",\"triggerId\"],[[27,\"readonly\",[[23,[\"triggerId\"]]],null],[27,\"readonly\",[[23,[\"value\"]]],null],[27,\"readonly\",[[23,[\"options\"]]],null],[27,\"readonly\",[[23,[\"disabled\"]]],null],[27,\"action\",[[22,0,[]],\"onChange\"],null],[27,\"readonly\",[[23,[\"triggerId\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[22,1,[]],false],[0,\"\\n    \"]],\"parameters\":[1]},null],[1,[21,\"id\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/enum/template.hbs"
    }
  });

  _exports.default = _default;
});