define("adept-iq/models/tm-item", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/item.js */
  var _default = _model.default.extend({
    type: (0, _attr.default)('string'),
    time: (0, _attr.default)('string'),
    agenda: (0, _relationships.belongsTo)('tm-agenda')
  });

  _exports.default = _default;
});