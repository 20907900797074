define("adept-iq/config/zone-polygon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.zone_polygon = void 0;
  // holds the zone polygon for walla walla
  // used by zone-validation.js
  const zone_polygon = [[49.279015, -123.247252], [49.303846, -122.833503], [49.182406, -121.931221], [49.087698, -121.913986], [48.935364, -122.290462], [48.782800, -122.431157], [48.982679, -123.049720], [49.125910, -123.244351]];
  _exports.zone_polygon = zone_polygon;
  var _default = zone_polygon;
  _exports.default = _default;
});