define("adept-iq/adapters/cs-config-item", ["exports", "adept-iq/adapters/-cs-configService"], function (_exports, _csConfigService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_CATEGORY = 'config';

  var _default = _csConfigService.default.extend({
    updateRecord(store, type, snapshot) {
      const data = {};
      const serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      }); // config service does not allow `id` or `name` on update

      delete data.data.id;
      delete data.data.attributes.name;
      const url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
      return this.ajax(url, 'PATCH', {
        data: data
      });
    },

    ajaxOptions(url, type) {
      const options = this._super(...arguments);

      if (type === 'GET') {
        // clear this stuff out; config API doesn't use it
        options.data = null;
      }

      return options;
    },

    urlForCreateRecord(modelName, snapshot) {
      const {
        category
      } = snapshot.attributes();
      return this._buildUrlForPath(category);
    },

    urlForDeleteRecord(id
    /*, modelName, snapshot*/
    ) {
      return this._buildUrlForPath(id);
    },

    urlForFindAll()
    /*modelName, snapshot*/
    {
      // this isn't _really_ a findAll
      return this._buildUrlForPath(DEFAULT_CATEGORY);
    },

    urlForFindRecord(id
    /*, modelName, snapshot*/
    ) {
      return this._buildUrlForPath(id);
    },

    urlForQuery(query
    /*, modelName*/
    ) {
      const path = query.category;
      return this._buildUrlForPath(path);
    },

    urlForQueryRecord(query
    /*, modelName*/
    ) {
      const path = query.category;
      return this._buildUrlForPath(path);
    },

    urlForUpdateRecord(id
    /*, modelName, snapshot*/
    ) {
      return this._buildUrlForPath(id);
    },

    _buildUrlForPath(path) {
      const host = this.get('host');
      const namespace = this.get('namespace');
      return `${host}/${namespace}/${path}`;
    }

  });

  _exports.default = _default;
});