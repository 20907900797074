define("adept-iq/pods/components/iq-widgets/poi-address-search-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/poi-address-search-widget/config", "adept-iq/utils/populate-pickup", "adept-iq/utils/check-address-flags", "adept-iq/utils/check-address-overwrite", "adept-iq/utils/focus_column_widget_row", "lodash", "adept-iq/utils/check-value"], function (_exports, _component, _config, _populatePickup, _checkAddressFlags, _checkAddressOverwrite, _focus_column_widget_row, _lodash, _checkValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['poi-address-search-widget'],
    editModal: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    // need to sub-class this in specific widget
    config: _config.default,
    defaultIncludes: INCLUDES,

    init() {
      this._super(...arguments);

      let recordId = 1;
      this.get('widgetModelData').forEach(record => {
        console.log('widgetModelData ', record.address);

        try {
          const zoneData = this.get('store').peekRecord('zone', record.zoneId);

          if (!Ember.isBlank(zoneData)) {
            record.zoneName = zoneData.get('name');
          }
        } catch (e) {
          const zoneData = this.get('store').peekRecord('zone', record.zoneId);

          if (!Ember.isBlank(zoneData)) {
            Ember.set(record, 'zoneName', zoneData.get('name'));
          }
        }

        this.get('records').pushObject(record);
      });
      console.log('this.get(records) ', this.get('records')); // this.set('records',this.get('widgetModelData'));

      let me = this;
      Ember.run.later(function () {
        (0, _focus_column_widget_row.default)(me);
      }, 100);
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({});
      this.set('singleActions', []);
    },

    validateAddresses(addressRecord) {
      this.set('editModal.errors', []);

      if (addressRecord.address.coord.lat === 0 || addressRecord.address.coord.lng === 0) {
        this.get('editModal.errors').pushObject('Address does not have lat/lng. Please select position on the map.');
      } else {
        if (Ember.isBlank(addressRecord.address.streetNr)) {
          this.get('editModal.errors').pushObject(addressRecord.passengerEvents[0].type + ' does not have street number');
        }

        (0, _checkAddressFlags.default)(this.get('record'));
      }
    },

    actions: {
      onRowRightClick(row, event) {},

      closeWidget(event) {
        this.get('geocode').set('activePOIResults', []);

        this._super(...arguments);
      },

      onRowClick(row, event) {
        let addressRecord = this.addressRecord;
        let tripData = this.currentRecord;
        let ttAddress = row.get('record');
        console.log('ttAddress ', ttAddress);
        this.closeWidget();
        this.get('geocode').set('activePOIResults', []);
        let overwriteAddress = true;
        let overwriteAddressPop = (0, _checkAddressOverwrite.default)(addressRecord.address, ttAddress.address);

        if (overwriteAddressPop) {
          overwriteAddress = confirm('Do you want to overwrite Address');
        }

        if (overwriteAddress) {
          try {
            addressRecord.address.streetNr = ttAddress.address.streetNr;
            addressRecord.address.streetName = ttAddress.address.streetName;
            addressRecord.address.region = ttAddress.address.region;
            addressRecord.address.state = ttAddress.address.state;
            addressRecord.address.postCode = ttAddress.address.postalCode; // addressRecord.address.description = ttAddress.address.description;
            //record.address.country = ttAddress.address.country;
          } catch (e) {}

          try {// Ember.set(addressRecord.address, 'description', checkValue(ttAddress.address.description));
          } catch (e) {// record.address.formattedAddress =  checkValue(ttAddress.address.description);
          }

          try {
            Ember.set(addressRecord.address.tomtomFormattedAddress.address, 'freeformAddress', (0, _checkValue.default)(addressRecord.address.streetNr) + " " + (0, _checkValue.default)(addressRecord.address.streetName) + " " + (0, _checkValue.default)(addressRecord.address.region));
          } catch (e) {
            console.log('e', e);

            try {
              addressRecord.address.tomtomFormattedAddress = {
                address: {
                  freeformAddress: (0, _checkValue.default)(addressRecord.address.streetNr) + " " + (0, _checkValue.default)(addressRecord.address.streetName) + " " + (0, _checkValue.default)(addressRecord.address.region)
                }
              };
            } catch (e) {
              console.log('e1 ', e);
            }
          }

          try {
            Ember.set(addressRecord.address, 'formattedAddress', (0, _checkValue.default)(ttAddress.freeformAddress));
          } catch (e) {
            addressRecord.address.formattedAddress = (0, _checkValue.default)(ttAddress.freeformAddress);
          }

          let options = this.get('store').peekAll('tm-common-operational-attribute');
          let requiredAttributes = ttAddress.requiredAttributes;

          try {
            addressRecord.address.coord.lat = ttAddress.address.coord.lat;
            addressRecord.address.coord.lng = ttAddress.address.coord.lng;
          } catch (e) {//console.log(e);
          }

          if (!Ember.isBlank(addressRecord.address) && !Ember.isBlank(addressRecord.address.coord) && addressRecord.address.coord.lat !== 0 && addressRecord.address.coord.lng !== 0 && addressRecord.address.coord.lat !== 181 && addressRecord.address.coord.lng !== 181 && !Ember.isBlank(addressRecord.address.coord.lat) && !Ember.isBlank(addressRecord.address.coord.lng)) {
            try {
              this.get('geocode').getGeonode(addressRecord.address.coord.lat, addressRecord.address.coord.lng).then(result => {
                const zoneData = this.get('store').peekRecord('zone', result.data.gpsAreaId);
                let zoneDriverNotes = zoneData.get('notes.driver');
                let zoneOperatorNotes = zoneData.get('notes.dispatcher');
                Ember.set(tripData, 'editmodelcontext.predefinedDriverNotes.area', zoneDriverNotes);
                Ember.set(tripData, 'editmodelcontext.predefinedOperatorNotes.area', zoneOperatorNotes);
                let options = this.get('store').peekAll('tm-common-operational-attribute');
                let requiredAttributes = zoneData.get('requiredAttributes');
                let areaAttributes = options.filter(function (o1) {
                  // if match found return false
                  return _lodash.default.findIndex(requiredAttributes, {
                    'type': o1.type
                  }) === -1 ? false : true;
                });
                Ember.set(tripData, 'editmodelcontext.attributes.area', areaAttributes);
              });
            } catch (e) {
              console.log(e);
            }
          }

          console.log('requiredAttributes ', requiredAttributes);
          let addressAttributes = options.filter(function (o1) {
            // if match found return false
            return _lodash.default.findIndex(requiredAttributes, {
              'type': o1.type
            }) === -1 ? false : true;
          });
          console.log('buildingAttributes ', addressAttributes);

          if (!Ember.isBlank(ttAddress.notes)) {
            Ember.set(tripData, 'editmodelcontext.predefinedDriverNotes.address', (0, _checkValue.default)(ttAddress.notes.driver));
            Ember.set(tripData, 'editmodelcontext.predefinedOperatorNotes.address', (0, _checkValue.default)(ttAddress.notes.dispatcher));
          } else {
            Ember.set(tripData, 'editmodelcontext.predefinedDriverNotes.address', '');
            Ember.set(tripData, 'editmodelcontext.predefinedOperatorNotes.address', '');
          }

          Ember.set(tripData, 'editmodelcontext.attributes.address', addressAttributes);
        }
      }

    }
  });

  _exports.default = _default;
});