define("adept-iq/pods/components/iq-widgets/map-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/map-widget/component", "adept-iq/pods/components/iq-widgets/map-widget/data-joins/marker", "adept-iq/pods/components/iq-widgets/map-widget/data-joins/polyline", "adept-iq/pods/components/iq-widgets/map-widget/data-joins/polygon", "adept-iq/pods/components/iq-widgets/map-widget/data-joins/circle", "adept-iq/pods/components/iq-widgets/map-widget/data-joins/shape", "adept-iq/utils/check-value", "adept-iq/utils/check-address-flags", "adept-iq/utils/check-address-overwrite", "tomtom", "lodash", "adept-iq/config/environment"], function (_exports, _component, _marker, _polyline, _polygon, _circle, _shape, _checkValue, _checkAddressFlags, _checkAddressOverwrite, _tomtom, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RecenterControl = void 0;
  const config = {
    title: 'Map'
  };
  const recenterControlOptions = {
    position: 'topleft',
    recenterAction: null
  };

  var RecenterControl = _tomtom.default.L.Control.extend({
    options: recenterControlOptions,
    onAdd: function ()
    /* map */
    {
      const name = 'leaflet-control-recenter';

      const container = _tomtom.default.L.DomUtil.create('div', 'leaflet-control-recenter leaflet-bar');

      this._recenterButton = this._createButton('<i class="fa fa-crosshairs fa-lg crosshairs-big" aria-hidden="true"></i>', "Recenter", name, container, this._recenterMap);
      return container;
    },
    _recenterMap: function () {
      this.options.mapWidget.onRecenterClick();
    },
    _createButton: function (html, title, className, container, fn) {
      var link = _tomtom.default.L.DomUtil.create('a', className, container);

      link.innerHTML = html;
      link.href = '#';
      link.title = title;
      /*
       * Will force screen readers like VoiceOver to read this as "Zoom in - button"
       */

      link.setAttribute('role', 'button');
      link.setAttribute('aria-label', title);

      _tomtom.default.L.DomEvent.disableClickPropagation(link);

      _tomtom.default.L.DomEvent.on(link, 'click', _tomtom.default.L.DomEvent.stop);

      _tomtom.default.L.DomEvent.on(link, 'click', fn, this);

      _tomtom.default.L.DomEvent.on(link, 'click', this._refocusOnMap, this);

      return link;
    }
  });

  _exports.RecenterControl = RecenterControl;

  var _default = _component.default.extend(Ember.Evented, {
    mapContext: Ember.inject.service(),
    geocode: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    markerDataJoin: null,
    polylineDataJoin: null,
    polygonDataJoin: null,
    circleDataJoin: null,
    shapeDataJoin: null,
    config,
    isSearchEnabled: Ember.computed.alias('mapContext.isSearchEnabled'),
    fullMode: _environment.default.APP.fullMode,
    searchText: Ember.computed.alias('mapContext.searchText'),
    lat: Ember.computed.alias('mapContext.lat'),
    lng: Ember.computed.alias('mapContext.lng'),
    isVisible: Ember.computed.alias('mapContext.isVisible'),
    isMCGVisible: Ember.computed.alias('mapContext.isMCGVisible'),
    polylines: Ember.computed.alias('mapContext.polylines'),
    markers: Ember.computed.alias('mapContext.markers'),
    agencyMarkers: Ember.computed.alias('mapContext.agencyMarkers'),
    shapes: Ember.computed.alias('mapContext.shapes'),
    isFiltered: Ember.computed.alias('mapContext.isFiltered'),
    contextMenu: Ember.computed.alias('mapContext.contextMenu'),
    contextMenuOptions: Ember.computed.alias('mapContext.contextMenuOptions'),
    contextMenuPosition: Ember.computed.alias('mapContext.contextMenuPosition'),
    clearButtonEnabled: Ember.computed.gt('activeContext.checkedItems.length', 0),

    init() {
      this._super(...arguments); //console.log('mapContext ',this);


      this.set('markerDataJoin', _marker.default.create());
      this.set('polylineDataJoin', _polyline.default.create());
      this.set('shapeDataJoin', _shape.default.create());
    },

    markerClusterDBClick(a, me) {
      let filterRecords = [];
      const firstMarker = a.layer.getAllChildMarkers()[0];
      const markers = a.layer.getAllChildMarkers();
      const modelName = firstMarker.options.modelName;
      const parentModelName = firstMarker.options.parentModelName;
      let allRecords;

      if (!Ember.isBlank(parentModelName)) {
        allRecords = me.get('store').peekAll(parentModelName);
        markers.forEach(function (marker) {
          const filterRecord = allRecords.filterBy('id', marker.options.parentModelId);

          if (!filterRecords.includes(filterRecord[0])) {
            filterRecords.push(filterRecord[0]);
          }
        });
      } else {
        allRecords = me.get('store').peekAll(modelName);
        markers.forEach(function (marker) {
          const filterRecord = allRecords.filterBy('id', marker.options.modelId);

          if (!filterRecords.includes(filterRecord[0])) {
            filterRecords.push(filterRecord[0]);
          }
        });
      }

      me.get('activeContext').replaceCheckedRecordsByType(filterRecords);
    },

    markerClusterPopup(a, me, map) {
      // a.layer is actually a cluster
      const content = [];
      const markers = a.layer.getAllChildMarkers();
      const popup_latlng = markers[0]._latlng;
      markers.forEach(function (marker) {
        let {
          modelName,
          modelId,
          parentModelName,
          parentModelId,
          MCGLabel
        } = marker.options;

        if (!Ember.isBlank(parentModelName)) {
          modelName = parentModelName;
          modelId = parentModelId;
        }

        content.push("<div class='MGPopup' modelName='" + modelName + "' modelId='" + modelId + "'>" + MCGLabel + "</div>");
      });

      _tomtom.default.L.popup({
        maxHeight: 300,
        maxWidth: 300,
        minWidth: 150,
        closeButton: false
      }).setLatLng(popup_latlng).setContent(content.join("<div>----------------------</div>")).openOn(map);

      Ember.$(".MGPopup").dblclick(function () {
        me.onLayerDblClick(Ember.$(this)[0].attributes.modelName.nodeValue, Ember.$(this)[0].attributes.modelId.nodeValue);
      });
    },

    didInitializeMap(map) {
      const locationControl = new RecenterControl({
        mapWidget: this
      });
      locationControl.addTo(map);
      const me = this; //TODO: bind the click event

      this.set('markerDataJoin.map', map);
      this.set('polylineDataJoin.map', map);
      this.set('shapeDataJoin.map', map);
      this.get('markerDataJoin').on('click', this, 'onLayerClick');
      this.get('markerDataJoin').on('dblClick', this, 'onLayerDblClick');
      this.get('markerDataJoin').on('contextMenu', this, 'onLayerContextMenu');
      this.get('polylineDataJoin').on('click', this, 'onLayerClick');
      this.get('polylineDataJoin').on('dblClick', this, 'onLayerDblClick');
      this.get('polylineDataJoin').on('contextMenu', this, 'onLayerContextMenu');
      this.get('shapeDataJoin').on('click', this, 'onLayerClick');
      this.get('shapeDataJoin').on('dblClick', this, 'onLayerDblClick');
      this.get('shapeDataJoin').on('contextMenu', this, 'onLayerContextMenu');

      this._onMapContextRefresh = () => {
        this.onMarkersChange();
        this.onShapesChange();
      };

      Ember.run.later(function () {
        me.get('activeContext').refreshAll();
        me.onMarkersChange();
        me.onShapesChange();
      }, 10000);
      /* later(function() {
         me.get('workspaceContext').get('reloadWorkspace').perform();
         me.get('workspaceContext').get('mapContext').trigger('refresh');
         me.get('workspaceContext').set('startRefreshing',true);
         
       }, 60000);*/

      this.get('mapContext').on('refresh', this._onMapContextRefresh);
      this.get('geocode.activeGeocode');
      this.get('geocode.activePickGeocode');
      this.get('geocode.activeDropGeocode');
      map.addLayer(this.get("mapContext.MCGTrips"));
      map.addLayer(this.get("mapContext.MCGVehicles"));
      this.get("mapContext.MCGTrips").on('clusterclick', function (a) {
        me.markerClusterPopup(a, me, map);
      });
      this.get("mapContext.MCGVehicles").on('clusterclick', function (a) {
        me.markerClusterPopup(a, me, map);
      });
      this.get("mapContext.MCGTrips").on('clusterdblclick', function (a) {
        me.markerClusterDBClick(a, me);
      });
      this.get("mapContext.MCGVehicles").on('clusterdblclick', function (a) {
        me.markerClusterDBClick(a, me);
      });
      /* this.get("mapContext.MCGTrips").on('clustermouseover', function(event) {
         // your custom L.MarkerCluster extended with function highlight()
         event.layer.highlight();
       })
       
       this.get("mapContext.MCGTrips").on('clustermouseout', function(event) {
         // your custom L.MarkerCluster extended with function resetHighlight()
         event.layer.resetHighlight();
       })
        this.get("mapContext.MCGVehicles").on('clustermouseover', function(event) {
         // your custom L.MarkerCluster extended with function highlight()
         event.layer.highlight();
       })
       
       this.get("mapContext.MCGVehicles").on('clustermouseout', function(event) {
         // your custom L.MarkerCluster extended with function resetHighlight()
         event.layer.resetHighlight();
       })*/
    },

    async willDestroyElement() {
      await Ember.run(() => {
        this.get('markerDataJoin').off('click', this, 'onLayerClick');
        this.get('markerDataJoin').off('dblClick', this, 'onLayerDblClick');
        this.get('markerDataJoin').off('contextMenu', this, 'onLayerContextMenu');
        this.get('polylineDataJoin').off('click', this, 'onLayerClick');
        this.get('polylineDataJoin').off('dblClick', this, 'onLayerDblClick');
        this.get('polylineDataJoin').off('contextMenu', this, 'onLayerContextMenu');
        this.get('shapeDataJoin').off('click', this, 'onLayerClick');
        this.get('shapeDataJoin').off('dblClick', this, 'onLayerDblClick');
        this.get('shapeDataJoin').off('contextMenu', this, 'onLayerContextMenu');
        this.get('mapContext').off('refresh', this._onMapContextRefresh);
        this._onMapContextRefresh = null;
        this.get('markerDataJoin').clear();
        this.get('polylineDataJoin').clear();
        this.get('shapeDataJoin').clear();
      });

      this._super(...arguments);
    },

    activeGeocode: Ember.observer('geocode.activeGeocode', function () {
      const map = this.get('map');
      const geocodeService = this.get('geocode');

      if (geocodeService.get('activeGeocode')) {
        map.on('click', function (event) {
          console.log('markerLayer.getLatLng() ', event.latlng);
          geocodeService.saveNewGeocode(event.latlng);
        });
      } else {
        map.off('click');
      }
    }),
    activePOIGeocode: Ember.observer('geocode.activePOIGeocode', function () {
      const map = this.get('map');
      const geocodeService = this.get('geocode');

      if (geocodeService.get('activePOIGeocode')) {
        map.on('click', function (event) {
          geocodeService.findPOIForRecord(event.latlng);
        });
      } else {
        map.off('click');
      }
    }),
    activePOIResults: Ember.observer('geocode.activePOIResults', function () {
      const map = this.get('map');
      var markerOpt = {
        noMarkerClustering: false
      };
      const me = this;

      try {
        const geocodeService = this.get('geocode');
        const searchResponses = geocodeService.get('activePOIResults');
        console.log('searchResponses ', searchResponses);

        if (!Ember.isEmpty(searchResponses)) {
          let addressRecord = this.get('editmodalcontext.addressRecord');
          let tripData = this.get('editmodalcontext.record');
          console.log('addressRecord ', addressRecord);
          console.log('tripData ', tripData);
          let markersData = [];
          searchResponses.forEach(function (address) {
            console.log('searchResponses ', address.address);
            let latlng = {
              lat: address.address.coord.lat,
              lng: address.address.coord.lng
            };

            const tomtomMarker = _tomtom.default.L.marker(latlng);

            let label = address.address.description;
            tomtomMarker.address = address;
            tomtomMarker.addressRecord = addressRecord;
            tomtomMarker.tripData = tripData;
            tomtomMarker.bindPopup(label, {
              closeButton: false
            });
            markersData.push(tomtomMarker);
            tomtomMarker.on('dblclick', function (event) {
              console.log('tomtomMarker on dblclick event ', event);
              console.log('tomtomMarker on dblclick event.target ', event.target);
              me.toggleProperty('editmodalcontext.showWidgetModal');
              let ttAddress = event.target.address;
              let addressRecord = event.target.addressRecord;
              console.log('tomtomMarker addressRecord ', addressRecord);
              let tripData = event.target.tripData;
              me.get('geocode').set('activePOIResults', []);
              let overwriteAddress = true;
              let overwriteAddressPop = (0, _checkAddressOverwrite.default)(addressRecord.address, ttAddress.address);

              if (overwriteAddressPop) {
                overwriteAddress = confirm('Do you want to overwrite Address');
              }

              if (overwriteAddress) {
                try {
                  addressRecord.address.streetNr = ttAddress.address.streetNr;
                  addressRecord.address.streetName = ttAddress.address.streetName;
                  addressRecord.address.region = ttAddress.address.region;
                  addressRecord.address.state = ttAddress.address.state;
                  addressRecord.address.postCode = ttAddress.address.postalCode;

                  if (!Ember.isBlank(ttAddress.address.description) && ttAddress.address.description.length > 40) {
                    Ember.set(addressRecord.address, 'description', ttAddress.address.description.substring(0, 39));
                  } else {
                    Ember.set(addressRecord.address, 'description', ttAddress.address.description);
                  } //record.address.country = ttAddress.address.country;

                } catch (e) {
                  console.log(e);
                }

                try {// Ember.set(addressRecord.address, 'description', checkValue(ttAddress.address.description));
                } catch (e) {// record.address.formattedAddress =  checkValue(ttAddress.address.description);
                }

                try {
                  Ember.set(addressRecord.address.tomtomFormattedAddress.address, 'freeformAddress', (0, _checkValue.default)(addressRecord.address.streetNr) + " " + (0, _checkValue.default)(addressRecord.address.streetName) + " " + (0, _checkValue.default)(addressRecord.address.region));
                } catch (e) {
                  console.log('e', e);

                  try {
                    addressRecord.address.tomtomFormattedAddress = {
                      address: {
                        freeformAddress: (0, _checkValue.default)(addressRecord.address.streetNr) + " " + (0, _checkValue.default)(addressRecord.address.streetName) + " " + (0, _checkValue.default)(addressRecord.address.region)
                      }
                    };
                  } catch (e) {
                    console.log('e1 ', e);
                  }
                }

                try {
                  Ember.set(addressRecord.address, 'formattedAddress', (0, _checkValue.default)(ttAddress.freeformAddress));
                } catch (e) {
                  addressRecord.address.formattedAddress = (0, _checkValue.default)(ttAddress.freeformAddress);
                }

                let options = me.get('store').peekAll('tm-common-operational-attribute');
                let requiredAttributes = ttAddress.requiredAttributes;

                try {
                  addressRecord.address.coord.lat = ttAddress.address.coord.lat;
                  addressRecord.address.coord.lng = ttAddress.address.coord.lng;
                  me.get('geocode').addressMapPopulate(addressRecord);
                } catch (e) {
                  console.log(e);
                }

                console.log('requiredAttributes ', requiredAttributes);
                let addressAttributes = options.filter(function (o1) {
                  // if match found return false
                  return _lodash.default.findIndex(requiredAttributes, {
                    'type': o1.type
                  }) === -1 ? false : true;
                });
                console.log('buildingAttributes ', addressAttributes);

                if (!Ember.isBlank(ttAddress.notes)) {
                  Ember.set(tripData, 'editmodelcontext.predefinedDriverNotes.address', (0, _checkValue.default)(ttAddress.notes.driver));
                  Ember.set(tripData, 'editmodelcontext.predefinedOperatorNotes.address', (0, _checkValue.default)(ttAddress.notes.dispatcher));
                } else {
                  Ember.set(tripData, 'editmodelcontext.predefinedDriverNotes.address', '');
                  Ember.set(tripData, 'editmodelcontext.predefinedOperatorNotes.address', '');
                }

                Ember.set(tripData, 'editmodelcontext.attributes.address', addressAttributes);
                document.getElementById('inputPOI' + me.get('geocode').get('activePOIIndex')).focus();
                (0, _checkAddressFlags.default)(tripData);
              }
            });
          });

          var markersLayer = _tomtom.default.L.layerGroup(markersData).addTo(map);

          geocodeService.set('activePOIMarkersLayer', markersLayer); // markersLayer.addTo(map);

          /* markersLayer.forEach(function(markerLayer, index) {
             var point = searchResponses[index];
             var viewport = point.viewport;
             if (viewport) {
               map.fitBounds([viewport.topLeftPoint, viewport.btmRightPoint]);
             } else {
               map.panTo(markerLayer.getLatLng());
             }
             markerLayer.on('click', function(event) {
               console.log('markerLayer.getLatLng() ',markerLayer.getLatLng());
               geocodeService.set('disabledFindLocationForRecord',true);
               
               geocodeService.saveNewGeocode(markerLayer.getLatLng());
                 markerLayer.openPopup();
             });         
           
           });*/
        } else {
          if (geocodeService.get('activePOIMarkersLayer')) {
            geocodeService.get('activePOIMarkersLayer').remove(); // map.remove(geocodeService.get('activePOIMarkersLayer'));
          }
        }
      } catch (e) {}
    }),
    activeMarker: Ember.observer('geocode.activeMarker', function () {
      const map = this.get('map');
      const geocodeService = this.get('geocode');

      if (geocodeService.get('oldMarker')) {
        geocodeService.get('oldMarker').remove();
      }

      if (geocodeService.get('activeMarker')) {
        const marker = geocodeService.get('activeMarker');
        marker.addTo(map);
        map.setView(marker._latlng, geocodeService.get('zoom'));
      }
    }),
    activePOIMarker: Ember.observer('geocode.activePOIMarker', function () {
      const map = this.get('map');
      const geocodeService = this.get('geocode');

      if (geocodeService.get('oldPOIMarker')) {
        geocodeService.get('oldPOIMarker').remove();
      }

      if (geocodeService.get('activePOIMarker')) {
        const marker = geocodeService.get('activePOIMarker');
        marker.addTo(map);
        map.setView(marker._latlng, geocodeService.get('zoom'));
      }
    }),
    activePickMarker: Ember.observer('geocode.activePickMarker', function () {
      const map = this.get('map');
      const geocodeService = this.get('geocode');

      if (geocodeService.get('oldPickMarker')) {
        geocodeService.get('oldPickMarker').remove();
      }

      if (this.get('pickMarker')) {
        this.get('pickMarker').remove();
      }

      if (geocodeService.get('activePickMarker')) {
        const marker = geocodeService.get('activePickMarker');
        marker.addTo(map);
        map.setView(marker._latlng, geocodeService.get('zoom'));
        this.set('pickMarker', marker);
        marker.on('dragend', function (event) {
          geocodeService.saveNewGeocode(event.target.getLatLng());
        });
      }
    }),
    activeDropMarker: Ember.observer('geocode.activeDropMarker', function () {
      const map = this.get('map');
      const geocodeService = this.get('geocode');

      if (geocodeService.get('oldDropMarker')) {
        geocodeService.get('oldDropMarker').remove();
      }

      if (this.get('dropMarker')) {
        this.get('dropMarker').remove();
      }

      if (geocodeService.get('activeDropMarker')) {
        const marker = geocodeService.get('activeDropMarker');
        marker.addTo(map);
        map.setView(marker._latlng, geocodeService.get('zoom'));
        this.set('dropMarker', marker);
        marker.on('dragend', function (event) {
          geocodeService.saveNewGeocode(event.target.getLatLng());
        });
      }
    }),
    // TODO need to clean up
    activeTripPolyline: Ember.observer('geocode.activeTripPolyline', function () {
      const me = this;
      const map = this.get('map');
      const geocodeService = this.get('geocode');

      if (this.get('dropMarker')) {
        this.get('dropMarker').remove();
      }

      if (this.get('pickMarker')) {
        this.get('pickMarker').remove();
      }

      if (geocodeService.get('oldTripPolyline')) {
        geocodeService.get('oldTripPolyline').remove();
      }

      if (geocodeService.get('oldDropMarker')) {
        geocodeService.get('oldDropMarker').remove();
      }

      if (geocodeService.get('oldPickMarker')) {
        geocodeService.get('oldPickMarker').remove();
      }

      if (geocodeService.get('oldTripArrow')) {
        geocodeService.get('oldTripArrow').remove();
      }

      if (geocodeService.get('oldPickMarker')) {
        geocodeService.get('oldPickMarker').remove();
      }

      if (geocodeService.get('oldTripArrow')) {
        geocodeService.get('oldTripArrow').remove();
      }

      if (geocodeService.get('tripRouteOnMap')) {
        geocodeService.get('tripRouteOnMap').remove();
      }

      if (geocodeService.get('route4Trip')) {
        geocodeService.get('route4Trip').remove();
      }

      if (geocodeService.get('route4TripPopup')) {
        geocodeService.get('route4TripPopup').remove();
      }

      if (this.get('tripPolyline')) {
        this.get('tripPolyline').remove();
      }

      if (this.get('tripArrow')) {
        this.get('tripArrow').remove();
      }

      if (this.get('route4Trip')) {
        this.get('route4Trip').remove();
      }

      if (geocodeService.get('activeDropMarker')) {
        const marker = geocodeService.get('activeDropMarker');
        marker.addTo(map);
        map.setView(marker._latlng, geocodeService.get('zoom'));
        this.set('dropMarker', marker);
      }

      if (this.get('pickMarker')) {
        this.get('pickMarker').remove();
      }

      if (geocodeService.get('activePickMarker')) {
        const marker = geocodeService.get('activePickMarker');
        marker.addTo(map);
        map.setView(marker._latlng, geocodeService.get('zoom'));
        this.set('pickMarker', marker);
      }

      if (geocodeService.get('activeTripPolyline')) {
        const tripPolyline = geocodeService.get('activeTripPolyline');
        tripPolyline.addTo(map);
        const tripArrow = geocodeService.get('activeTripArrow');
        tripArrow.addTo(map);
        this.set('tripPolyline', tripPolyline);
        this.set('tripArrow', tripArrow);
        const tripRoutePoints = geocodeService.get('tripRoutePoints');
        const locations = tripRoutePoints[0] + "," + tripRoutePoints[1] + ":" + tripRoutePoints[2] + "," + tripRoutePoints[3];
        let route4Trip;

        _tomtom.default.routing({
          traffic: true
        }).travelMode('taxi').locations(locations).go().then(function (routeJson) {
          const summary = routeJson.features[0].properties.summary;
          route4Trip = _tomtom.default.L.geoJson(routeJson, {
            style: {
              color: '#000000',
              opacity: 0.7,
              strokeWidth: "6"
            }
          });
          map.fitBounds(route4Trip.getBounds(), {
            padding: [10, 10]
          });

          const latlng = _tomtom.default.L.latLng(tripRoutePoints[0], tripRoutePoints[1]);

          const summarycalc = '<div> Distance: ' + _tomtom.default.unitFormatConverter.formatDistance(summary.lengthInMeters) + '</div><p>' + '<div>Estimated travel time: ' + _tomtom.default.unitFormatConverter.formatTime(summary.travelTimeInSeconds) + '</div><p>' + '<div> Traffic delay: ' + _tomtom.default.unitFormatConverter.formatTime(summary.trafficDelayInSeconds);

          var route4TripPopup = _tomtom.default.L.popup({
            closeButton: false
          }).setLatLng(latlng).setContent(summarycalc).openOn(map);

          me.get('geocode').set('route4Trip', route4Trip);
          me.get('geocode').set('route4TripPopup', route4TripPopup);
          me.get('geocode').get('route4Trip').addTo(map);
        });
      }
    }),

    updateMarkerData() {
      const markerDataJoin = this.get('markerDataJoin');
      const markers = this.get('markers');
      if (markers) markerDataJoin.join(markers);
    },

    updatePolylineData() {
      const polylineDataJoin = this.get('polylineDataJoin');
      const polylines = this.get('polylines');
      if (polylines) polylineDataJoin.join(polylines);
    },

    updateShapeData() {
      try {
        let shapeDataJoin = this.get('shapeDataJoin');
        const shapes = this.get('shapes');
        shapeDataJoin.join(shapes);
      } catch (es) {}
    },

    onMarkersChange: Ember.observer('activeContext.activeData.[]', 'mapContext.layers', function () {
      Ember.run.scheduleOnce('actions', this, 'updateMarkerData');
    }),
    onPolylinesChange: Ember.observer('activeContext.activeData.[]', 'mapContext.layers', function () {
      Ember.run.scheduleOnce('actions', this, 'updatePolylineData');
    }),
    onShapesChange: Ember.observer('activeContext.activeData.[]', 'mapContext.layers', function () {
      Ember.run.scheduleOnce('actions', this, 'updateShapeData');
    }),

    onLayerClick(modelName, id, e) {
      this.get('mapContext').onLayerClick(modelName, id, e);
    },

    onLayerDblClick(modelName, id, e) {
      this.get('mapContext').onLayerDblClick(modelName, id, e);
    },

    onLayerContextMenu(modelName, id, e) {
      this.get('mapContext').onLayerContextMenu(modelName, id, e);
    },

    onRecenterClick() {
      this.notifyPropertyChange('lat');
      this.notifyPropertyChange('lng');
    },

    getResultName(result) {
      if (typeof result.poi !== 'undefined' && typeof result.poi.name !== 'undefined') {
        return result.poi.name;
      }

      if (result.nm !== '') {
        return result.nm;
      }

      return '';
    },

    getResultAddress(result) {
      if (typeof result.address !== 'undefined') {
        var address = [];

        if (typeof result.address.freeformAddress !== 'undefined') {
          address.push(result.address.freeformAddress);
        }

        if (typeof result.address.countryCodeISO3 !== 'undefined') {
          address.push(result.address.countryCodeISO3);
        }

        return address.join(', ');
      } else if (typeof result.a2 !== 'undefined') {
        return result.a2;
      }

      return '';
    },

    prepareResultElement(result) {
      var resultElement = new _tomtom.default.L.DomUtil.create('div', 'geoResponse-result');
      var name = this.getResultName(result);
      var adress = this.getResultAddress(result);

      if (typeof name !== 'undefined') {
        var nameWrapper = _tomtom.default.L.DomUtil.create('div', 'geoResponse-result-name');

        nameWrapper.innerHTML = name;
        resultElement.appendChild(nameWrapper);
      }

      if (typeof adress !== 'undefined') {
        var addressWrapper = _tomtom.default.L.DomUtil.create('div', 'geoResponse-result-address');

        addressWrapper.innerHTML = adress;
        resultElement.appendChild(addressWrapper);
      }

      return resultElement;
    },

    actions: {
      onSearchButtonClick() {
        this.toggleProperty('isSearchEnabled');
        Ember.run.scheduleOnce('afterRender', () => {
          if (this.get('isSearchEnabled')) {
            this.$('.map-widget-search-box').focus();
          }
        });
      },

      onRemoveSearchClick() {
        this.set('searchText', '');
        this.toggleProperty('isSearchEnabled');
      },

      onSearchTextChange(searchText) {
        this.set('searchText', searchText);
      },

      onClearButtonClick() {
        this.get('activeContext').clearCheckedItems();
      },

      onFilterButtonClick() {
        const workspace = this.get('workspace');
        let topState = workspace.get('topState');

        while (topState === 'filterMapWidget') {
          workspace.popState();
          topState = workspace.get('topState');
        }

        const displayName = "Map Layers";
        workspace.pushState('filterMapWidget', {
          displayName
        });
      },

      onLayerToggle(layer) {
        this.toggleProperty(`isVisible.${layer}`);
      },

      setContextMenu(dropdown) {
        this.set('contextMenu', dropdown);
      },

      onContextMenuOptionClick(option) {
        this.get('mapContext').onContextMenuOptionClick(option);
      },

      onHeaderDoubleClick() {
        const maximizer = this.get('maximizer');

        if (maximizer.maximizedWidget === this.get('widget')) {
          maximizer.minimize();
          return;
        }

        maximizer.maximize(this.get('widget'));
      },

      onExitMaximizedClick() {
        let maximizer = this.get('maximizer');
        maximizer.minimize();
      },

      buildPopupMessage(summary) {
        return ['Distance: ' + _tomtom.default.unitFormatConverter.formatDistance(summary.lengthInMeters), 'Estimated travel time: ' + _tomtom.default.unitFormatConverter.formatTime(summary.travelTimeInSeconds), 'Traffic delay: ' + _tomtom.default.unitFormatConverter.formatTime(summary.trafficDelayInSeconds)].join('<br/>');
      },

      bindPopups(feature, layer) {
        const map = this.get('map');
        layer.on('mouseover', function (e) {
          _tomtom.default.L.popup().setLatLng(e.latlng).setContent(this.buildPopupMessage(feature.properties.summary)).openOn(map);
        });
      }

    }
  });

  _exports.default = _default;
});