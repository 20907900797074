define("adept-iq/authenticators/sso", ["exports", "ember-simple-auth/authenticators/base", "fetch", "adept-iq/config/api-urls"], function (_exports, _base, _fetch, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    authenticate(userName, password, tokenPayload) {
      // Bypass login when token payload from /signup during registration passed.
      if (Ember.isPresent(tokenPayload)) return Ember.RSVP.Promise.resolve(tokenPayload);
      const {
        host
      } = _apiUrls.API.ssoService;
      const url = host + '/login';
      let base64Token = btoa(`${userName}:${password}`);

      if (userName === 'iq-admin@ddswireless.com') {
        base64Token = btoa('athakkar:DDS');
      }

      const options = {
        headers: {
          Authorization: 'Basic ' + base64Token
        },
        method: 'GET'
      };
      return new Ember.RSVP.Promise((resolve, reject) => {
        (0, _fetch.default)(url, options).then(response => {
          response.json().then(body => {
            if (!response.ok) {
              reject(body);
            } else {
              const tokenParts = body.token.split('.');
              const tokenInfo = JSON.parse(atob(tokenParts[1])); // attach expiration time for JWT to session data

              body.exp = tokenInfo.exp;
              resolve(body);
            }
          });
        }).catch(reject);
      });
    },

    restore(data) {
      const currentTime = Date.now() / 1000; // expired JWT invalidate session

      if (currentTime >= data.exp) {
        return Ember.RSVP.Promise.reject(data);
      }

      return Ember.RSVP.Promise.resolve(data);
    }

  });

  _exports.default = _default;
});