define("adept-iq/pods/application/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    socket: Ember.inject.service(),
    actions: {
      openDashboard(dashboard) {
        // TODO: prompt to save changes
        this.get('tooltip').reset();
        this.transitionTo('dashboard', dashboard);
      },

      transitionTo() {
        this.transitionTo(...arguments);
      },

      async logout() {
        localStorage.setItem('lastDashboardId', null);
        const workspace = this.get('workspace');
        const dashboard = this.get('workspace.dashboardInstance');
        const tooltip = this.get('tooltip');
        Ember.run(() => this.get('socket').logout());
        Ember.run(() => workspace.reset());
        Ember.run(() => tooltip.reset());
        this.get('session').invalidate().then(async () => {
          await Ember.run(() => dashboard.clearDashboard());
          await Ember.run(() => this.transitionTo('login'));
        });
        Ember.$("#rc-widget").hide();
      }

    }
  });

  _exports.default = _default;
});