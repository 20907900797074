define("adept-iq/pods/components/iq-widgets/zones-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/utils/populate-new-text-message", "adept-iq/pods/components/iq-widgets/zones-widget/config", "adept-iq/config/environment", "lodash"], function (_exports, _component, _populateNewTextMessage, _config, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['zones-widget'],
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    trip: Ember.inject.service(),
    ajax: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    session: Ember.inject.service(),
    textmessage: Ember.inject.service(),
    config: _config.default,
    defaultIncludes: INCLUDES,

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({});
      this.set('singleActions', [{
        name: 'Send Message',
        action: zone => {
          this.sendMessagesToZones([zone]);
        }
      }]);
      this.set('bulkActions', [{
        name: 'Send Message',
        action: zones => {
          this.sendMessagesToZones(zones);
        }
      }]);
    },

    sendMessagesToZones(zones) {
      let me = this;
      let draftMessages = [];
      zones.map(zone => {
        zone.get('offVehicle').map(vehicle => {
          let newTestMessage = (0, _populateNewTextMessage.default)(me.get('textmessage'), this.get('store'), vehicle, null, null);
          draftMessages.push(newTestMessage);
          return newTestMessage;
        });
      });
      this.get('workspace').pushState('addNewMessage', {
        draftMessages
      });
    }

  });

  _exports.default = _default;
});