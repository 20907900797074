define("adept-iq/pods/components/form-components/combobox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lom9sZQx",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[0,\"\\n\"],[4,\"power-select-with-create\",null,[[\"selected\",\"options\",\"disabled\",\"searchEnabled\",\"searchField\",\"onchange\",\"oncreate\"],[[27,\"readonly\",[[23,[\"selected\"]]],null],[27,\"readonly\",[[23,[\"options\"]]],null],[27,\"readonly\",[[23,[\"disabled\"]]],null],true,\"label\",[27,\"action\",[[22,0,[]],\"onChange\"],null],[27,\"action\",[[22,0,[]],\"createNew\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"style\",\"display:flex; justify-content:space-between;\"],[9],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n       \"],[1,[27,\"get\",[[22,1,[]],[23,[\"optionSearchPath\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"data-option-index\",\"-1\"],[11,\"aria-disabled\",\"true\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"deleteComboItem\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"deleteOption\",[22,1,[]]],null]],[11,\"style\",\"border: none; background: transparent; width: 25px; padding: 0; margin: 0; margin-right: 5px;\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"fa fa-times-circle\"],[11,\"style\",\"margin: auto 1rem;\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/combobox/template.hbs"
    }
  });

  _exports.default = _default;
});