define("adept-iq/models/route", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "lodash", "moment", "adept-iq/mixins/otp-label"], function (_exports, _model, _attr, _relationships, _lodash, _moment, _otpLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    find
  } = _lodash.default;

  var _default = _model.default.extend(_otpLabel.default, {
    name: (0, _attr.default)('string'),
    plannedStartTime: (0, _attr.default)('date'),
    plannedEndTime: (0, _attr.default)('date'),
    lifoDepth: (0, _attr.default)('number'),
    routeFactor: (0, _attr.default)('number'),
    isBooleanExample: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    enumExamples: (0, _attr.default)('object', {
      defaultValue: () => ['One']
    }),
    enumExample: (0, _attr.default)('string', {
      defaultValue: 'One'
    }),
    routeDate: (0, _attr.default)('date'),
    vehicleName: (0, _attr.default)('string'),
    selectedVehicle: (0, _attr.default)(),
    selectedDriver: (0, _attr.default)(),
    driverExternalId: (0, _attr.default)('string'),
    odometer: (0, _attr.default)('string'),
    otp: (0, _attr.default)('string', {
      defaultValue: 'O'
    }),
    otpValue: (0, _attr.default)('number'),
    timestamp: (0, _attr.default)('date', {
      defaultValue: () => new Date()
    }),
    pulloutPolyline: (0, _attr.default)('polyline'),
    stopPolylines: (0, _attr.default)('object'),
    navigationPolyline: (0, _attr.default)('polyline'),
    fullPolyline: Ember.computed('stopPolylines', 'orderedStops', 'counter', function () {
      let stopPolylines = this.get('stopPolylines');
      let stops = this.get('orderedStops');
      if (Ember.isEmpty(stops)) return [];
      return stops.reduce((arr, stop) => {
        let obj = stopPolylines.find(obj => {
          return obj.stopId.toString() === stop.get('id').toString();
        });

        if (obj && obj.polyline) {
          arr.push(...obj.polyline);
        }

        return arr;
      }, []);
    }),
    orderedStops: Ember.computed('orderedClusters.@each.orderedStops', function () {
      return this.get('orderedClusters').reduce((arr, cluster) => {
        let stops = cluster.get('orderedStops');
        arr.push(...stops);
        return arr;
      }, []);
    }),
    orderedClusters: Ember.computed('clusters.@each.ordinal', function () {
      return this.get('clusters').sortBy('ordinal');
    }),
    status: (0, _attr.default)('string'),
    provider: (0, _relationships.belongsTo)(),
    schedule: (0, _relationships.belongsTo)(),
    assignRoute: (0, _relationships.belongsTo)('route'),
    clusters: (0, _relationships.hasMany)(),
    vehicleRoutes: (0, _relationships.hasMany)(),
    driverBreaks: (0, _relationships.hasMany)(),
    places: (0, _relationships.hasMany)(),
    routePlaces: (0, _relationships.hasMany)(),
    isCompleted: Ember.computed.equal('status', 'completed'),
    isEmptyRoute: Ember.computed.empty('clusters.firstObject.trips'),

    isVehicleAvailable(vehicle) {
      let plannedStartTime = this.get('plannedStartTime');
      let plannedEndTime = this.get('plannedEndTime');
      let routeDate = this.get('routeDate');
      plannedStartTime.setDate(routeDate.getDate());
      plannedStartTime.setMonth(routeDate.getMonth());
      plannedStartTime.setFullYear(routeDate.getFullYear());
      plannedEndTime.setDate(routeDate.getDate());
      plannedEndTime.setMonth(routeDate.getMonth());
      plannedEndTime.setFullYear(routeDate.getFullYear());
      return vehicle.isVehicleAvailable((0, _moment.default)(plannedStartTime), (0, _moment.default)(plannedEndTime));
    },

    isDriverAvailable(driver) {
      let plannedStartTime = this.get('plannedStartTime');
      let plannedEndTime = this.get('plannedEndTime');
      let routeDate = this.get('routeDate');
      plannedStartTime.setDate(routeDate.getDate());
      plannedStartTime.setMonth(routeDate.getMonth());
      plannedStartTime.setFullYear(routeDate.getFullYear());
      plannedEndTime.setDate(routeDate.getDate());
      plannedEndTime.setMonth(routeDate.getMonth());
      plannedEndTime.setFullYear(routeDate.getFullYear());
      return driver.isDriverAvailable((0, _moment.default)(plannedStartTime), (0, _moment.default)(plannedEndTime));
    },

    garages: Ember.computed.mapBy('routePlaces', 'place'),
    assignedDriver: Ember.computed.alias('latestVehicleRoute.latestDriver'),
    assignedVehicle: Ember.computed.alias('latestVehicleRoute.vehicle'),
    latestVehicleRoute: Ember.computed('vehicleRoutes.@each.plannedStartTime', function () {
      return this.get('vehicleRoutes').sortBy('plannedStartTime').get('lastObject');
    }),
    // FIXME: set dependent keys so this updates in realtime
    actualDriverBreaks: Ember.computed('driverBreaks', function () {
      const driverBreaks = Ember.get(this, 'driverBreaks');
      let driverBreaksTaken = [];

      if (!Ember.isEmpty(driverBreaks)) {
        const driverBreakEvents = Ember.get(driverBreaks, 'driverBreakEvents');

        if (!Ember.isEmpty(driverBreakEvents)) {
          let completedDriverBreaks = find(driverBreakEvents.toArray(), driverBreakEvent => {
            // TODO: Ankur -  Hardcoded will be remove to some configuration
            return driverBreakEvent.type === 'end';
          });
          completedDriverBreaks.toArray().forEach(function (item) {
            driverBreaksTaken.push(`${item.get('estimatedStart')} ${item.get('estimatedEnd')} `);
          });
        }
      }

      return driverBreaksTaken;
    }),
    // FIXME: set dependent keys so this updates in realtime
    plannedDriverBreaks: Ember.computed('driverBreaks', function () {
      let driverBreaks = [];
      this.get('driverBreaks').forEach(function (item) {
        driverBreaks.push(`${item.get('estimatedStart')} ${item.get('estimatedEnd')} `);
      });
      return driverBreaks;
    }),
    // FIXME: set dependent keys so this updates in realtime
    actualStartEvent: Ember.computed('assignedVehicle', function () {
      const assignedVehicle = Ember.get(this, 'assignedVehicle');

      if (!Ember.isEmpty(assignedVehicle)) {
        const vehicleEvents = Ember.get(assignedVehicle, 'vehicleEvents');

        if (!Ember.isEmpty(vehicleEvents)) {
          const lastVehicleEvent = vehicleEvents.sortBy('timestamp').objectAt(Ember.get(vehicleEvents, 'length') - 1); // TODO: Ankur -  Hardcoded will be remove to some configuration

          if (!Ember.isEmpty(lastVehicleEvent) && lastVehicleEvent.type === 'pullout') {
            return lastVehicleEvent;
          }
        }
      }

      return null;
    }),
    // FIXME: set dependent keys so this updates in realtime
    actualEndEvent: Ember.computed('assignedVehicle', function () {
      const assignedVehicle = Ember.get(this, 'assignedVehicle');

      if (!Ember.isEmpty(assignedVehicle)) {
        const vehicleEvents = Ember.get(assignedVehicle, 'vehicleEvents');

        if (!Ember.isEmpty(vehicleEvents)) {
          const lastVehicleEvent = vehicleEvents.sortBy('timestamp').objectAt(Ember.get(vehicleEvents, 'length') - 1); //Ankur -  Hardcoded will be remove to some configuration

          if (!Ember.isEmpty(lastVehicleEvent) && lastVehicleEvent.type === 'pullin') {
            return lastVehicleEvent;
          }
        }
      }

      return null;
    })
  });

  _exports.default = _default;
});