define("adept-iq/pods/components/iq-widgets/search-trip-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/search-trip-form-widget/config", "ember-concurrency", "adept-iq/utils/populate-new-trip", "adept-iq/utils/populate-copy-trip", "adept-iq/utils/populate-edit-trip", "adept-iq/utils/populate-search-trip", "adept-iq/utils/populate-account", "adept-iq/utils/check-address-flags", "adept-iq/utils/populate-call-line", "adept-iq/config/environment", "adept-iq/config/api-urls", "adept-iq/utils/check-value"], function (_exports, _component, _config, _emberConcurrency, _populateNewTrip, _populateCopyTrip, _populateEditTrip, _populateSearchTrip, _populateAccount, _checkAddressFlags, _populateCallLine, _environment, _apiUrls, _checkValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    trip: Ember.inject.service(),
    geocode: Ember.inject.service(),
    store: Ember.inject.service(),
    editModal: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    searchservice: Ember.inject.service(),
    ajax: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    session: Ember.inject.service(),
    accountSearchPath: _apiUrls.API_SUB_URL.accountSearchPath,
    searchTripComponent: 'iq-widgets/search-trip-form-widget',
    editTripComponent: 'iq-widgets/edit-trip-form-widget',
    newTripComponent: 'iq-widgets/new-trip-form-widget',
    newTripFixComponent: 'iq-widgets/new-trip-fixform-widget',
    notesSeparator: "<>",
    classNames: ['search-trip-form-widget'],

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      let records = this.get('editableRecords');
      let tripRecord = records.firstObject;
      let defaultCardNr = 'CASH';
      let PFCP_GENERAL_CASH_ACCOUNT_NAME = this.get('store').peekAll('tm-config-parameter-value').filterBy('configParameter.id', 'PFCP_GENERAL_CASH_ACCOUNT_NAME');

      if (!Ember.isBlank(PFCP_GENERAL_CASH_ACCOUNT_NAME)) {
        defaultCardNr = PFCP_GENERAL_CASH_ACCOUNT_NAME[0].get('value');
      }

      tripRecord.set('acctCardNr', (0, _checkValue.default)(tripRecord.get('acctCardNr')) === defaultCardNr ? '' : tripRecord.get('acctCardNr'));

      if (!Ember.isBlank(this.prepareQuery(true))) {
        this.onEnter(true);
      } // install keyboard listener


      this._onKeyDown = event => {
        Ember.run.schedule('actions', this, 'onKeyDown', event);
      };

      document.addEventListener('keydown', this._onKeyDown);
    },

    onKeyDown(event) {
      try {
        this.set('editModal.errors', []);
        console.log('onKeyDown ', event.target.value);

        if (event.key === 'Enter') {
          return this.onEnter(false);
        }
      } catch (e) {}
    },

    prepareQuery(initalLoad) {
      let records = this.get('editableRecords');
      let tripRecord = records.firstObject;
      let tripID = tripRecord.get('tripId');
      let telephone = tripRecord.get('passengers.0.phone1.number');
      let name = tripRecord.get('passengers.0.firstName');
      let description = tripRecord.get('stopPoints.0.address.description');
      let streetNr = tripRecord.get('stopPoints.0.address.streetNr');
      let streetName = tripRecord.get('stopPoints.0.address.streetName');
      let account = tripRecord.get('acctCardNr');
      let searchURL = '';

      if (!Ember.isBlank(tripID)) {
        searchURL = searchURL + 'id:' + tripID;
      }

      if (!Ember.isBlank(telephone) && !Ember.isBlank(telephone.trim())) {
        searchURL = 'telephone=' + telephone;
      }
      /*if(!isBlank(description) && !isBlank(description.trim())) {
        if(!isBlank(searchURL)) 
          searchURL = searchURL+ '&description='+description.toUpperCase();
        else
          searchURL = 'description='+description.toUpperCase();
      }*/


      let defaultCardNr = 'CASH';
      let PFCP_GENERAL_CASH_ACCOUNT_NAME = this.get('store').peekAll('tm-config-parameter-value').filterBy('configParameter.id', 'PFCP_GENERAL_CASH_ACCOUNT_NAME');

      if (!Ember.isBlank(PFCP_GENERAL_CASH_ACCOUNT_NAME)) {
        defaultCardNr = PFCP_GENERAL_CASH_ACCOUNT_NAME[0].get('value');
      }

      if (!Ember.isBlank(name) && !Ember.isBlank(name.trim())) {
        if (!Ember.isBlank(searchURL)) searchURL = searchURL + '&name=' + name.toUpperCase();else searchURL = 'name=' + name.toUpperCase();
      }

      if (initalLoad) {
        if (!Ember.isBlank(account) && !Ember.isBlank(account.trim()) && account.toUpperCase() !== defaultCardNr) {
          if (!Ember.isBlank(searchURL)) searchURL = searchURL + '&account=' + account.toUpperCase();else searchURL = 'account=' + account.toUpperCase();
        }
      } else {
        if (!Ember.isBlank(account) && !Ember.isBlank(account.trim())) {
          if (!Ember.isBlank(searchURL)) searchURL = searchURL + '&account=' + account.toUpperCase();else searchURL = 'account=' + account.toUpperCase();
        }
      }

      if (!Ember.isBlank(streetNr) && !Ember.isBlank(streetNr.trim())) {
        if (!Ember.isBlank(searchURL)) searchURL = searchURL + '&streetNr=' + streetNr.toUpperCase();else searchURL = 'streetNr=' + streetNr.toUpperCase();
      }

      if (!Ember.isBlank(streetName) && !Ember.isBlank(streetName.trim())) {
        if (!Ember.isBlank(searchURL)) searchURL = searchURL + '&streetName=' + streetName.toUpperCase();else searchURL = 'streetName=' + streetName.toUpperCase();
      }

      console.log('prepareQuery searchURL', searchURL);
      return searchURL;
    },

    populateBookingData(results) {
      if (!Ember.isEmpty(results)) {
        let widgetModelData = results;

        if (widgetModelData && widgetModelData.length >= 1) {
          this.set('editmodalcontext.showWidgetModal', true);
          this.set('editmodalcontext.widgetModelData', widgetModelData);
          this.set('editmodalcontext.modelWidgetPath', 'search-trip-widget');
        } else if (widgetModelData) {
          this.set('editModal.editableRecords', []);
          this.get('editModal.editableRecords').pushObject(widgetModelData);
          widgetModelData = (0, _populateEditTrip.default)(this.get('trip'), widgetModelData, this.get('store'), this.get('geocode'));
          (0, _populateAccount.default)(widgetModelData, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), widgetModelData.acctCardNr, false);
          const editTripComponent = this.get('editTripComponent');
          this.get('editModal').open(editTripComponent, [widgetModelData], this.get('saveTripRecordFunction').bind(this)); //checkAddressFlags(this.get('record'));
        }
      } else {
        this.set('editModal.errors', []);
        this.get('editModal.errors').pushObject('No result found');
      }
    },

    onEnter(initalLoad) {
      let me = this;
      this.set('editmodalcontext.showWidgetModal', false);
      this.set('editmodalcontext.modelWidgetPath', '');
      let records = this.get('editableRecords');
      let tripRecord = records.firstObject;
      let tripID = tripRecord.get('tripId');

      if (!Ember.isBlank(tripID)) {
        this.store.findRecord('tm-booking', tripID, {
          reload: true
        }).then(updatetrip => {
          this.populateBookingData([updatetrip]);
        }).catch(function (error) {
          console.log('error ', error);
          me.set('editModal.errors', []);
          me.get('editModal.errors').pushObject('No result found');
        });
      } else {
        let searchURL = this.prepareQuery(initalLoad);
        this.store.query('tm-booking', {
          searchURL
        }).then(results => {
          this.populateBookingData(results);
        }).catch(function (error) {
          console.log('error ', error);
          me.set('editModal.errors', []);
          me.get('editModal.errors').pushObject('No result found');
        });
      }
    },

    saveTripRecordFunction(records, editComponentAction) {
      console.log(' saveTripRecordFunction ', editComponentAction);
      const me = this;
      return new Promise((resolve, reject) => {
        let record = records.firstObject;
        record.save().then(() => {
          console.log('after saveTripRecordFunction ');
          const tripId = record.get('id');
          me.get('errorMessage').pushInfo({
            detail: `Trip '${tripId}' successfully saved`
          });
          record.set('lastId', record.get('id'));
          record.set('isForceDirty', false);

          if (!Ember.isBlank(editComponentAction) && editComponentAction === 'onApplyClick') {
            Ember.run(() => {
              let recordRefresh = [];
              let newtrip = (0, _populateEditTrip.default)(me.get('trip'), record, me.get('store'), me.get('geocode'));
              (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, false);
              recordRefresh.push(newtrip);
              let newTripComponent = me.get('editSwitchTripComponent');

              if (this.get('editModal.editComponent') !== null && this.get('editModal.editComponent') === this.get('editSwitchTripComponent')) {
                newTripComponent = me.get('editTripComponent');
              }

              me.get('editModal').open(newTripComponent, recordRefresh, me.get('saveTripRecordFunction').bind(me));
            });
          } else if (!Ember.isBlank(editComponentAction) && editComponentAction === 'onApplyClearClick') {
            Ember.run(() => {
              Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
              me.get('geocode').activateGeocode(false);
              me.get('geocode').deactivateGeocode();
              let recordRefresh = [];
              let newtrip = (0, _populateNewTrip.default)(me.get('trip'), me.get('store'));
              (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, false);
              newtrip.set('lastId', record.get('lastId'));
              recordRefresh.push(newtrip); //me.set('editableRecords',recordRefresh);
              //me.set('records',recordRefresh);

              let newTripComponent = me.get('newSwitchTripComponent');

              if (me.get('editModal.editComponent') !== null && me.get('editModal.editComponent') === me.get('newSwitchTripComponent')) {
                newTripComponent = me.get('newTripComponent');
              }

              me.get('editModal').open(newTripComponent, recordRefresh, me.get('saveTripRecordFunction').bind(me));
            });
          }
        }).catch(function (error) {
          console.log(error);
          reject(error);
        });
      });
    },

    actions: {
      onClearClick() {
        let records = this.get('editableRecords');
        let tripRecord = records.firstObject;
        tripRecord.set('tripId', '');
        tripRecord.set('passengers.0.phone1.number', '');
        tripRecord.set('passengers.0.firstName', '');
        tripRecord.set('stopPoints.0.address.description', '');
        tripRecord.set('stopPoints.0.address.streetNr', '');
        tripRecord.set('stopPoints.0.address.streetName', '');
        tripRecord.set('acctCardNr', '');
        this.set('editmodalcontext.showWidgetModal', false);
        this.set('editmodalcontext.widgetModelData', []);
        this.set('editmodalcontext.modelWidgetPath', 'search-trip-widget');
        this.set('editModal.errors', []);
      },

      onNewClick() {
        this.set('records', []);
        let newtrip = (0, _populateNewTrip.default)(this.get('trip'), this.get('store'));
        (0, _populateAccount.default)(newtrip, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), newtrip.acctCardNr, true); //this.get('records').pushObject(newtrip);
        //this.set('editModal.editableRecords',[]);
        //this.get('editModal.editableRecords').pushObject(newtrip);

        const newTripComponent = this.get('newTripComponent');
        this.get('editModal').open(newTripComponent, [newtrip], this.get('saveTripRecordFunction').bind(this));
      }

    }
  });

  _exports.default = _default;
});