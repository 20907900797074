define("adept-iq/pods/components/iq-widgets/edit-settings-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: "Driver Mobile App Configurations",
      subsections: [{
        title: 'Passwords',
        fields: [{
          id: 'driverAppAdminPassword',
          type: 'text',
          label: 'Driver App Admin Password',
          valuePath: 'driverAppAdminPassword',
          editable: true,
          mandatory: true,
          default: '1234'
        }]
      }, {
        title: 'Wifi Network',
        fields: [{
          id: 'wifiNetwork',
          type: 'multientry',
          label: '',
          valuePath: 'wifiNetwork',
          modelName: 'rms-address',
          isMultiType: true,
          extra: {
            rows: [{
              password: 'default',
              networkName: 'default'
            }]
          }
        }]
      }, {
        title: "Driving Configuration",
        fields: [{
          id: 'vehicleArriveRadius',
          type: 'text',
          label: 'Vehicle Arrive Radius',
          valuePath: 'vehicleArriveRadius',
          description: " A vehicle must be less than this many feet from a stop in order for the Arrive screen or the Start Break screen (for a fixed location break) to be displayed. Note that the vehicle must also be considered stopped in order to display these screens, which is configured in the 'Vehicle Stopped' config value in the Fleet Management widget.",
          unit: 'ft',
          editable: true
        }, {
          id: 'pickupTimeThreshold',
          type: 'text',
          label: 'Pickup Time Threshold',
          valuePath: 'pickupTimeThreshold',
          unit: 'min',
          description: "If the current time is more than this many minutes before the pickup stop’s pickup time, then disable Pickup button and add a countdown.",
          editable: true
        }, {
          id: 'noShowTimeThreshold',
          type: 'text',
          label: 'No Show Time Threshold',
          valuePath: 'noShowTimeThreshold',
          unit: 'min',
          description: "If the current time is more than this many minutes before the pickup stop's pickup time, then disable NoShow button and add a countdown.",
          editable: true
        }, {
          id: 'autoNoShow',
          type: 'text',
          label: 'No Show Requires Dispatch Approval',
          valuePath: 'autoNoShow',
          description: "After a driver presses the NoShow button, the trip is saved as a NoShow. No request is sent to dispatch",
          editable: true
        },
        /* {
              id: 'communicationRetryTimeout',
              type: 'text',
              label: 'Communication Retry Timeout',
              valuePath: 'communicationRetryTimeout',
              unit: 'min',
              description: "Not displayed in the UX for MVP. This controls how long information is stored in the event of no communication between app and backend before it is purged. For MVP the app will set this to zero, meaning all communication is saved indefinitely, until communication is re-established. No user will be allowed to change the value. For MVP+, we will consider making this configurable, and then we need to decide which types of messages are purged, saved. ",
              editable: true
            }, */
        {
          id: 'remainingManifestLengthToDisplay',
          type: 'text',
          label: 'Remaining Manifest Length To Display',
          valuePath: 'remainingManifestLengthToDisplay',
          description: "Units can be number of stops or time period",
          editable: true
        }, {
          id: 'performedManifestLengthToDisplay',
          type: 'text',
          label: 'Performed Manifest Length To Display',
          valuePath: 'performedManifestLengthToDisplay',
          description: "Units can be number of stops or time period",
          editable: true
        }]
      }, {
        title: "Canned Messages",
        fields: [{
          id: 'messageContent',
          type: 'multientry',
          label: 'Message Content',
          valuePath: 'messageContent',
          description: "Canned message content",
          editable: true,
          isMultiType: true,
          extra: {
            rows: [{
              message: 'canned message'
            }, {
              message: 'canned message 2'
            }, {
              message: 'canned message 4'
            }]
          }
        }]
      }, {
        title: 'Communication Categories',
        fields: [{
          id: 'highPriority',
          type: 'text',
          label: 'High Priority',
          valuePath: 'highPriority',
          description: "Applies to any changes to the next stop initiated by the dispatcher such as Cancel Trip, Add Trip, No-Show, Arrive, and Depart.",
          editable: false
        }, {
          id: 'medPriority',
          type: 'text',
          label: 'Medium Priority',
          valuePath: 'medPriority',
          description: "Applies to any changes to the next stop initiated by the dispatcher such as Cancel Trip, Add Trip, No-Show, Arrive, and Depart.",
          editable: false
        }]
      }]
    }, {
      title: 'Passenger Mobile App Configurations',
      subsections: [{
        title: 'Book Trips',
        fields: [{
          id: 'passengersCanBookTripsOnApp',
          type: 'boolean',
          label: 'Passengers Can Book Trips On App',
          valuePath: 'passengersCanBookTripsOnApp',
          default: true,
          extra: {
            options: [{
              label: 'True',
              value: true
            }, {
              label: 'False',
              value: false
            }]
          },
          editable: true
        }, {
          id: 'allowFree-typeAddress',
          type: 'boolean',
          label: 'Allow Free-type Address',
          valuePath: 'allowFree-typeAddress',
          default: true,
          extra: {
            options: [{
              label: 'True',
              value: true
            }, {
              label: 'False',
              value: false
            }]
          },
          editable: true
        }, {
          id: 'allowSame-Daytrip',
          type: 'boolean',
          label: 'Allow Same-Day trip',
          valuePath: 'allowSame-Daytrip',
          default: true,
          extra: {
            options: [{
              label: 'True',
              value: true
            }, {
              label: 'False',
              value: false
            }]
          },
          editable: true
        }, {
          id: 'futuretripTimeline',
          type: 'number',
          label: 'Future trip Timeline',
          valuePath: 'futuretripTimeline',
          default: 2,
          unit: 'days',
          editable: true
        }, {
          id: 'cutoffTimeForTomorrowtrip ',
          type: 'time',
          label: 'Cutoff Time For Tomorrow trip ',
          description: 'When a passenger tries to book a trip for tomorrow, the system will compare the current time with this setting and allow/prevent the trip.',
          unit: 'time',
          valuePath: 'cutoffTimeForTomorrowtrip ',
          default: 1700,
          editable: true
        }, {
          id: 'allowDefaultTravelNeedsChangedPerTrip',
          type: 'boolean',
          label: 'Allow Default Travel Needs Changed Per Trip',
          description: 'When enabled, the passenger is allowed to change their travel needs when trip a trip.',
          valuePath: 'allowDefaultTravelNeedsChangedPerTrip',
          default: false,
          extra: {
            options: [{
              label: 'True',
              value: true
            }, {
              label: 'False',
              value: false
            }]
          },
          editable: true
        }, {
          id: 'trip_eligibility_type',
          type: 'multientry',
          label: 'Eligibility Type',
          valuePath: 'trip_eligibility_type',
          description: "Only passengers with the selected eligibility types can cancel trips.",
          editable: true,
          isMultiType: true,
          extra: {
            rows: [{
              type: 'Eligibility 1'
            }]
          }
        }, {
          id: 'eligibilityMessage',
          type: 'text',
          label: 'Eligibility Message',
          valuePath: 'eligibilityMessage',
          default: 'Trip date must be within eligibility date range.',
          description: ' a message displayed when a passenger enters a trip date that is not within their eligibility date range(s).',
          editable: true
        }, {
          id: 'overlapMessage',
          type: 'text',
          label: 'Overlap Message',
          valuePath: 'overlapMessage',
          default: 'Unable to book trip. Trip overlaps with an existing trip.',
          description: 'A message displayed when a passenger tries to book a trip but it is too close in time to an existing trip.',
          editable: true
        }]
      }, {
        title: 'Cancel Trip',
        fields: [{
          id: 'allowTripCancel ',
          type: 'boolean',
          label: 'Allow Trip Cancel ',
          valuePath: 'allowTripCancel ',
          default: true,
          extra: {
            options: [{
              label: 'Yes',
              value: true
            }, {
              label: 'No',
              value: false
            }]
          },
          description: "",
          editable: true
        }, {
          id: 'lateTripCancelMessage',
          type: 'text',
          label: 'Late Trip Cancel Message',
          valuePath: 'lateTripCancelMessage',
          description: "When a passenger tries to cancel a trip and it will be considered a late cancel,  display this message.",
          default: "Please note that this will be counted as a late cancel.",
          editable: true
        }, {
          id: 'eligibility_type',
          type: 'multientry',
          label: 'Eligibility Type',
          valuePath: 'eligibility_type',
          description: "Only passengers with the selected eligibility types can cancel trips.",
          editable: true,
          isMultiType: true,
          extra: {
            rows: [{
              type: 'Eligibility 1'
            }]
          }
        }]
      }, {
        title: 'Vehicle Location',
        fields: [{
          id: 'when_to_display_vehicle_location',
          type: 'number',
          label: 'Vehicle Location Display',
          unit: 'min',
          valuePath: 'when_to_display_vehicle_location',
          default: '60',
          description: "How many minutes before the trip's pickup ETA to display the vehicle location on the map.",
          editable: true
        }]
      }, {
        title: 'Past Trips',
        fields: [{
          id: 'past_trips',
          type: 'number',
          label: 'Past Trips',
          unit: 'days',
          valuePath: 'past_trips',
          default: '30',
          description: "How many days worth of past trips to display. Default is 30.",
          editable: true
        }]
      }]
    }, {
      title: "Trip Configurations",
      subsections: [{
        title: "trip",
        description: 'Controls the days for which a user is allowed to book a trip.',
        fields: [{
          id: 'futureDays',
          type: 'number',
          label: 'Future Days',
          description: 'Number of days in advance a user is required to book a trip',
          unit: 'days',
          valuePath: 'futureDays',
          default: 2,
          editable: true
        }, {
          id: 'allowsSameDaytrip',
          type: 'boolean',
          label: 'Allows Same Day trip',
          valuePath: 'allowsSameDaytrip',
          editable: true,
          default: false,
          extra: {
            options: [{
              label: 'True',
              value: true
            }, {
              label: 'False',
              value: false
            }]
          }
        }]
      }, {
        title: "No-Show Reasons",
        description: 'Description',
        fields: [{
          // TODO: Make multi-type select
          id: 'reason',
          label: 'Reason',
          valuePath: 'reason',
          description: "A reason a passenger did not show to be picked up.",
          default: 'No Answer',
          editable: true,
          type: 'multientry',
          isMultiType: true,
          extra: {
            rows: [{
              'reason': "No Answer"
            }]
          }
        }]
      }, {
        title: "Cancel Reasons",
        description: 'Description',
        fields: [{
          // TODO: Make multi-type select
          id: 'reason',
          label: 'Reason',
          valuePath: 'reason',
          description: "A reason a passenger cancelled.",
          editable: true,
          default: 'Not Ready',
          type: 'multientry',
          isMultiType: true,
          extra: {
            rows: [{
              'reason': 'Not Ready'
            }, {
              'reason': 'No Longer Need Trip'
            }]
          }
        }]
      }, {
        title: "Late Cancel",
        description: "Determines when a trip cancellation qualifies as a late cancellation.",
        fields: [{
          id: 'lateCancelTimeline',
          type: 'number',
          label: 'Late Cancel Timeline',
          valuePath: 'lateCancelTimeline',
          default: 120,
          description: "minutes before the trip pick-up time.",
          unit: 'minutes',
          editable: true
        }]
      }, {
        title: "Trip Fare",
        description: 'Description',
        fields: [{
          id: 'defaultFare',
          type: 'number',
          label: 'Default Fare',
          valuePath: 'defaultFare',
          default: 250,
          description: "Default price of trip",
          editable: true
        }]
      }, {
        title: "Payment Method",
        description: 'A method used to pay for a trip.',
        fields: [{
          id: 'method',
          type: 'multientry',
          label: 'Method',
          valuePath: 'method',
          description: "Payment method",
          default: 'Cash',
          editable: true,
          isMultiType: true,
          extra: {
            rows: [{
              method: 'Cash'
            }, {
              method: 'Ticket'
            }, {
              method: 'Pass'
            }]
          }
        }]
      }]
    }
    /* {
      title: "System Configuration",
      subsections: [
        {
          title: "Travel Need Types",
          fields: [
            {
              id: 'name',
              type: 'text',
              label: 'Name',
              valuePath: 'name',
              description: "",
              editable: true
            },
        {
              id: 'loadTime',
              type: 'time',
              label: 'Load Time',
              valuePath: 'loadTime',
              description: "",
              editable: true
            },
        {
              id: 'unloadTime',
              type: 'time',
              label: 'Unload Time',
              valuePath: 'unloadTime',
              description: "",
              editable: true
            },
        {
              id: 'vehicleCapacityTypeId',
              type: 'text',
              label: 'Vehicle Capacity Type Id',
              valuePath: 'vehicleCapacityTypeId',
              description: "",
              editable: true
            },
        {
              id: 'vehicleCapacityCount',
              type: 'number',
              label: 'Vehicle Capacity Count',
              valuePath: 'vehicleCapacityCount',
              description: "",
              editable: true
            },
        {
              id: 'displayName',
              type: 'text',
              label: 'Display Name',
              valuePath: 'displayName',
              description: "",
              editable: true
            },
        {
              id: 'displayOrder',
              type: 'number',
              label: 'Display Order',
              valuePath: 'displayOrder',
              description: "",
              editable: true
            },
        {
              id: 'description',
              type: 'text',
              label: 'Description',
              valuePath: 'description',
              description: "",
              editable: true
            }
          ]
        },
        {
          title: "Fare Types",
          fields: [
            {
              id: 'name',
              type: 'text',
              label: 'Name',
              valuePath: 'name',
              description: "",
              editable: true
            }
          ]
        },
        {
          title: "Provider Types",
          fields: [
            {
              id: 'name',
              type: 'text',
              label: 'Name',
              valuePath: 'name',
              description: "",
              editable: true
            }
          ]
        },
        {
          title: "Providers",
          fields: [
            {
              id: 'name',
              type: 'text',
              label: 'Name',
              valuePath: 'name',
              description: "",
              editable: true
            },
        {
              id: 'providerTypeId',
              type: 'number',
              label: 'Provider Type Id',
              valuePath: 'providerTypeId',
              description: "",
              editable: true
            },
        {
              id: 'primaryProviderId',
              type: 'number',
              label: 'Primary Provider Id',
              valuePath: 'primaryProviderId',
              description: "null if the provider is a primary provider, otherwise the id of the primary provider if the provider is a sub-provider",
              editable: true
            },
           ]
        },
        {
          title: "Break Types",
          fields: [
            {
              id: 'name',
              type: 'text',
              label: 'Name',
              valuePath: 'name',
              description: "",
              editable: true
            },
        {
              id: 'defaultDuration',
              type: 'number',
              label: 'Default Duration',
              valuePath: 'defaultDuration',
              description: "",
              editable: true
            },
        {
              id: 'isPaid',
              type: 'boolean',
              label: 'Is Paid',
              valuePath: 'isPaid',
              description: "",
              editable: true
            },
        {
              id: 'isRecognized',
              type: 'boolean',
              label: 'Is Recognized',
              valuePath: 'isRecognized',
              description: "",
              editable: true
            },
        {
              id: 'serviceWindowId',
              type: 'number',
              label: 'Service Window Id',
              valuePath: 'serviceWindowId',
              description: "",
              editable: true
            }
          ]
        },
        {
          title: "Cancel Types",
          fields: [
            {
              id: 'code',
              type: 'number',
              label: 'Code',
              valuePath: 'code',
              description: "",
              editable: true
            },
        {
              id: 'externalCode',
              type: 'number',
              label: 'External Code',
              valuePath: 'externalCode',
              description: "",
              editable: true
            },
        {
              id: 'description',
              type: 'text',
              label: 'Description',
              valuePath: 'description',
              description: "",
              editable: true
            },
          ]
        },
        {
          title: "No Show Reason Codes",
          fields: [
            {
              id: 'code',
              type: 'number',
              label: 'Code',
              valuePath: 'code',
              description: "",
              editable: true
            },
        {
              id: 'externalCode',
              type: 'number',
              label: 'External Code',
              valuePath: 'externalCode',
              description: "",
              editable: true
            },
        {
              id: 'description',
              type: 'text',
              label: 'Description',
              valuePath: 'description',
              description: "",
              editable: true
            },
          ]
        },
        {
          title: "Vehicle Capacity Types",
          fields: [
            {
              id: 'name',
              type: 'text',
              label: 'Name',
              valuePath: 'name',
              description: "",
              editable: true
            },
        {
              id: 'description',
              type: 'text',
              label: 'Description',
              valuePath: 'description',
              description: "",
              editable: true
            },
        {
              id: 'engineId',
              type: 'number',
              label: 'Engine Id',
              valuePath: 'engineId',
              description: "Used by the strategy engine service)",
              editable: true
            },
        {
              id: 'engineName',
              type: 'text',
              label: 'Engine Name',
              valuePath: 'engineName',
              description: "Used by the strategy engine service)",
              editable: true
            },
        {
                id: 'constrainedByLIFO',
                type: 'boolean',
                label: 'Constrained By L I F O',
                valuePath: 'constrainedByLIFO',
                description: "",
                editable: true,
                extra: {
                  options: [
                    { label: 'True', value: true },
                    { label: 'False', value: false }
                  ]
                }
              },
        {
              id: 'dwellFactor',
              type: 'text',
              label: 'Dwell Factor',
              valuePath: 'dwellFactor',
              description: "",
              editable: true
            },
          ]
        },
        {
          title: "Vehicle Types",
          fields: [
            {
              id: 'name',
              type: 'text',
              label: 'Name',
              valuePath: 'name',
              description: "",
              editable: true
            }
          ]
        },
        {
          title: "Address Category Type",
          fields: [
            {
              id: 'name',
              type: 'text',
              label: 'Name',
              valuePath: 'name',
              description: "",
              editable: true
            }
          ]
        },
        {
          title: "Rider Address Type",
          fields: [
            {
              id: 'name',
              type: 'text',
              label: 'Name',
              valuePath: 'name',
              description: "",
              editable: true
            }
          ]
        },
        {
          title: "Passenger Type",
          fields: [
            {
              id: 'name',
              type: 'text',
              label: 'Name',
              valuePath: 'name',
              description: "",
              editable: true
            }
          ]
        },
        {
          title: "Eligibility Type",
          fields: [
            {
              id: 'name',
              type: 'text',
              label: 'Name',
              valuePath: 'name',
              description: "",
              editable: true
            }
          ]
        },
      ]
    },
    {
      title: "Travel Time Parameters",
      subsections: [
        {
          title: "Congested Locations",
          fields: [
            {
              id: 'name',
              type: 'text',
              label: 'Name',
              valuePath: 'name',
              editable: true
            },{
              id: 'address',
              type: 'text',
              label: 'Address',
              valuePath: 'address',
              editable: true
            },{
              id: 'startTimeOfArrivalDelay',
              type: 'time',
              label: 'Start time of arrival delay ',
              valuePath: 'startTimeOfArrivalDelay',
              editable: true
            },
            {
              id: 'endTimeOfArrivalDelay',
              type: 'time',
              label: 'End time of arrival delay ',
              valuePath: 'endTimeOfArrivalDelay',
              editable: true
            },
            {
              id: 'totalMinutesOfArrivalDelay',
              type: 'number',
              label: 'Total Minutes Of Arrival Delay',
              valuePath: 'totalMinutesOfArrivalDelay',
              editable: false,
            }
          ]
        },
        {
          title: "Speed Profiles",
          fields: [
            {
              id: 'roadClass',
              type: 'text',
              label: 'Road Class',
              valuePath: 'roadClass',
              editable: true
            },{
              id: 'timeSegment',
              type: 'text',
              label: 'Time Segment',
              valuePath: 'timeSegment',
              editable: true
            },{
              id: 'adjustmentPercentage',
              type: 'number',
              label: 'Adjustment Percentage',
              valuePath: 'adjustmentPercentage',
              editable: true
            }
          ]
        },
        {
          title: "Speed Regions",
          fields: [
          ]
        },
        {
          title: "StreetNet binary files",
          fields: [
            {
              id: 'mapShape',
              type: 'text',
              label: 'Map Shape',
              valuePath: 'mapShape',
              editable: true
            },{
              id: 'timeSegment',
              type: 'text',
              label: 'Time Segment',
              valuePath: 'timeSegment',
              editable: true
            },{
              id: 'adjustmentPercentage',
              type: 'number',
              label: 'Adjustment Percentage',
              valuePath: 'adjustmentPercentage',
              editable: true
            }
          ]
        }
      ]
    },
    {
      title: "Map",
      subsections: [
        {
          title: "Service Area",
          fields: [
            {
              id: 'serviceArea',
              type: 'text',
              label: 'Service Area',
              valuePath: 'serviceArea',
              editable: true
            }
          ]
        }
      ]
    },
    {
      title: "Scheduling",
      subsections: [
        {
          title: "Service Windows",
          fields: [
            {
              id: 'name',
              type: 'text',
              label: 'Name',
              valuePath: 'name',
              description: "",
              editable: true
            },
        {
              id: 'serviceTypeId',
              type: 'text',
              label: 'Service Type Id',
              valuePath: 'serviceTypeId',
              description: "Underlying scheduling engine service type",
              editable: true
            },
        {
              id: 'searchWindowStart',
              type: 'text',
              label: 'Search Window Start',
              valuePath: 'searchWindowStart',
              description: "",
              editable: true
            },
        {
              id: 'searchWindowEnd',
              type: 'text',
              label: 'Search Window End',
              valuePath: 'searchWindowEnd',
              description: "",
              editable: true
            },
        {
              id: 'promiseWindowStart',
              type: 'number',
              label: 'Promise Window Start',
              valuePath: 'promiseWindowStart',
              description: "Number of minutes prior to promise time",
              editable: true
            },
        {
              id: 'promiseWindowEnd',
              type: 'number',
              label: 'Promise Window End',
              valuePath: 'promiseWindowEnd',
              description: "Number of minutes after promise time",
              editable: true
            },
        {
              id: 'dropWindowStart',
              type: 'number',
              label: 'Drop Window Start',
              valuePath: 'dropWindowStart',
              description: "Number of minutes prior to appt anchored trip requested time",
              editable: true
            },
        {
              id: 'dropWindowEnd',
              type: 'number',
              label: 'Drop Window End',
              valuePath: 'dropWindowEnd',
              description: "Number of minutes after appt anchored trip requested time",
              editable: true
            },
        {
              id: 'scheduleConfigurationId',
              type: 'text',
              label: 'Schedule Configuration Id',
              valuePath: 'scheduleConfigurationId',
              description: "Associated schedule configuration",
              editable: true
            },
          ]
        },
        {
          title: "Schedule Configurations",
          fields: [
            {
              id: 'name',
              type: 'text',
              label: 'Name',
              valuePath: 'name',
              description: "",
              editable: true
            },
        {
              id: 'description',
              type: 'text',
              label: 'Description',
              valuePath: 'description',
              description: "",
              editable: true
            },
            {
              id: 'isReadOnly',
              type: 'boolean',
              label: 'Is Read Only',
              valuePath: 'isReadOnly',
              description: "",
              editable: true,
              default: false,
              extra: {
                options: [
                  {label: 'True', value: true},
                  {label: 'False', value: false},
                ]
              }
            },
          ]
        },
      ]
    },
    {
      title: 'URLs & Addresses',
      fields: [
        {
          id: 'iQAPIGateway',
          type: 'text',
          label: 'IQ API Gateway',
          valuePath: 'iQAPIGateway',
          editable: true
        },
        {
          id: 'clientURLAddress',
          type: 'text',
          label: 'Client URL Address',
          valuePath: 'clientURLAddress',
          editable: true
        },
        {
          id: 'userAccountCreationEmailMessage',
          type: 'text',
          label: 'User Account Creation Email Message',
          valuePath: 'userAccountCreationEmailMessage',
          editable: true
        },
        {
          id: 'userAccountPasswordResetEmailMessage',
          type: 'text',
          label: 'User Account Password Reset Email Message',
          valuePath: 'userAccountPasswordResetEmailMessage',
          editable: true
        },
        {
          id: 'userAccountEmailSenderAddress',
          type: 'text',
          label: 'User Account Email Sender Address',
          valuePath: 'userAccountEmailSenderAddress',
          editable: true
        },
        {
          id: 'userLogoutMinutes',
          type: 'text',
          label: 'User Logout Minutes',
          valuePath: 'userLogoutMinutes',
          editable: true
        },
        {
          id: 'userAccountCreationEmailExpiry',
          type: 'text',
          label: 'User Account Creation Email Expiry',
          valuePath: 'userAccountCreationEmailExpiry',
          editable: true
        }
      ]
    },
    {
      title: 'ETA Calculation',
      fields: [
      {
        id: 'TOMTOM_BASE_URL',
        type: 'text',
        label: 'TomTom Base URL',
        valuePath: 'TOMTOM_BASE_URL',
        editable: true
      },
      {
        id: 'TOMTOM_KEY',
        type: 'text',
        label: 'TomTom Key',
        valuePath: 'TOMTOM_KEY',
        editable: true
      },
      {
        id: 'HISTORIC_TIME_THRESHOLD',
        type: 'text',
        label: 'Historic Time Threshold',
        valuePath: 'HISTORIC_TIME_THRESHOLD',
        editable: true
      },
      {
        id: 'PARTIAL_REFRESH_THRESHOLD',
        type: 'text',
        label: 'Partial Refresh Threshold',
        valuePath: 'PARTIAL_REFRESH_THRESHOLD',
        editable: true
      },
      {
        id: 'UPDATE_THRESHOLDS_METERS',
        type: 'text',
        label: 'Update Thresholds Meters',
        valuePath: 'UPDATE_THRESHOLDS_METERS',
        editable: true
      },
      {
        id: 'UPDATE_THRESHOLDS_SECONDS',
        type: 'text',
        label: 'Update Thresholds Sections',
        valuePath: 'UPDATE_THRESHOLDS_SECONDS',
        editable: true
      },
      {
        id: 'UPDATE_THRESHOLDS_CACHETTL',
        type: 'text',
        label: 'Update Thresholds CacheTTL',
        valuePath: 'UPDATE_THRESHOLDS_CACHETTL',
        editable: true
      }
      ]
    },
    {
      title: 'Driver App License',
      fields: [
        {
          id: 'totalLicenses',
          type: 'text',
          label: 'Total Licenses',
          valuePath: 'totalLicenses',
          editable: true
        },
        {
          id: 'licensesUsed',
          type: 'text',
          label: 'Licenses Used',
          valuePath: 'licensesUsed',
          editable: true
        },
        {
          id: 'licensesRemaining',
          type: 'text',
          label: 'Licenses Remaining',
          valuePath: 'licensesRemaining',
          editable: true
        }
      ]
    },
    { title: 'Fixed Route Engine Parameters',
      fields:[
        {
          id: 'fullFixedRouteItineraryLimit',
          type: 'number',
          label: ' Full Fixed Route Itinerary Limit',
          valuePath: 'FullFixedRouteItineraryLimit',
          description: "The maximum number of possible full fixed route itineraries that the Fixed Route Engine will return, but it may return less.",
          editable: true
        },
        {
          id: 'feederItineraryLimit',
          type: 'number',
          label: ' Feeder Itinerary Limit',
          valuePath: 'FeederItineraryLimit',
          description: "The maximum number of possible feeder itineraries that the Fixed Route Engine will return, but it may return less.",
          editable: true
        },
        {
          id: 'reverseFeederItineraryLimit',
          type: 'number',
          label: ' Reverse Feeder Itinerary Limit',
          valuePath: 'ReverseFeederItineraryLimit',
          description: "The maximum number of possible reverse feeder itineraries that the Fixed Route Engine will return, but it may return less.",
          editable: true
        },
        {
          id: 'maximumTransfers',
          type: 'number',
          label: ' Maximum Transfers',
          valuePath: 'MaximumTransfers',
          description: "The maximum number of transfers a fixed route solution trip is allowed to make.",
          editable: true
        },
        {
          id: 'minimumTransferWaitTime',
          type: 'number',
          label: ' Minimum Transfer Wait Time',
          valuePath: 'MinimumTransferWaitTime',
          description: "The minimum time, in minutes, between transfers on a fixed route solution trip.",
          editable: true
        },
        {
          id: 'walkingTimeExtension',
          type: 'number',
          label: ' Walking Time Extension',
          valuePath: 'WalkingTimeExtension',
          description: "The number of minutes to add as a safety factor when calculating the departure time from the pick location of a full fixed-route trip.",
          editable: true
        },
        {
          id: 'feederServiceTimeExtension',
          type: 'number',
          label: ' Feeder Service Time Extension',
          valuePath: 'FeederServiceTimeExtension',
          description: "The number of minutes to add as a safety factor when calculating the departure time from the pick location or the arrival time at the drop location of a feeder trip.",
          editable: true
        },
        {
          id: 'useLastServiceSegment',
          type: 'boolean',
          label: ' Use Last Service Segment',
          valuePath: 'UseLastServiceSegment',
          description: "Determines whether the Fixed Route Engine can schedule trips on the last service segment of the day.  Use 'true' for yes.  Use 'false' for no.",
          extra: {
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false }
            ]
          },
          editable: true
        },
        {
          id: 'bestTripCriteria',
          type: 'select',
          label: ' Best Trip Criteria',
          valuePath: 'BestTripCriteria',
          description: "The criteria the Fixed Route Engine will use when determining the best full fixed-route itinerary.",
          extra: {
            options: [
              {label: "Least travel time" ,value: 0},
              {label: "Fewest transfers" ,value: 1},
              {label: "Least walking distance" ,value: 2},
              {label: "Closest to request time" ,value: 3},
              {label: "Least demand-response ride distance" ,value: 4},
              {label: "Least demand-response ride time" ,value: 5}
            ]
          },
          editable: true
        },
        {
          id: 'bestFeederTripCriteria',
          type: 'select',
          label: ' Best Feeder Trip Criteria',
          valuePath: 'BestFeederTripCriteria',
          description: "The criteria the Fixed Route Engine will use when determining the best feeder trip itinerary.",
          extra: {
            options: [
              {label: "Least travel time" ,value: 0},
              {label: "Fewest transfers" ,value: 1},
              {label: "Least walking distance" ,value: 2},
              {label: "Closest to request time" ,value: 3},
              {label: "Least demand-response ride distance" ,value: 4},
              {label: "Least demand-response ride time" ,value: 5}
            ]
          },
          editable: true
        },
        {
          id: 'feederServiceMinimumDistance',
          type: 'number',
          label: ' Feeder Service Minimum Distance',
          valuePath: 'FeederServiceMinimumDistance',
          description: "The minimum direct ride distance of the trip in meters required for the Fixed Route Engine to consider feeder trips.  If a trip's direct ride distance is less than this value, then the Fixed Route Engine will not consider the request for feeder trips.",
          editable: true
        },
        {
          id: 'feederServiceSearchAreaShape',
          type: 'number',
          label: ' Feeder Service Search Area Shape',
          valuePath: 'FeederServiceSearchAreaShape',
          description: "The shape that the Fixed Route Engine will use when searching for bus stops suitable for feeder trips.",
          extra: {
            options: [
              {label: "Rectangle" ,value: 0},
              {label: "Semicircle" ,value: 1},
              {label: "Semiellipse" ,value: 2}
            ]
          },
          editable: true
        },
        {
          id: 'directAxisForwardExtent',
          type: 'number',
          label: ' Direct Axis Forward Extent',
          valuePath: 'DirectAxisForwardExtent',
          description: "The distance from the pick location along the direct travel axis towards the drop location that marks the boundary of the geographic search area used to find possible feeder trips.   If the DirectAxisForwardExtentType value is set to 0, this is in meters.  If the DirectAxisForwardExtentType value is set to 1, this is a percentage of the direct ride distance.",
          editable: true
        },
        {
          id: 'directAxisForwardExtentType',
          type: 'select',
          label: ' Direct Axis Forward Extent Type',
          valuePath: 'DirectAxisForwardExtentType',
          description: "Whether the DirectAxisForwardExtent value is an absolute value or a percentage of the direct distance.",
          extra: {
            options: [
              {label: "Absolute value" ,value: 0},
              {label: "Percentage" ,value: 1}
            ]
          },
          editable: true
        },
        {
          id: 'directAxisReverseExtent',
          type: 'number',
          label: ' Direct Axis Reverse Extent',
          valuePath: 'DirectAxisReverseExtent',
          description: "The distance from the pick location along the direct travel axis away from the drop location that marks the boundary of the geographic search area used to find possible feeder trips.  If the DirectAxisReverseExtentType value is set to 0, this is in meters.  If the DirectAxisReverseExtentType value is set to 1, this is a percentage of the direct ride distance.",
          editable: true
        },
        {
          id: 'directAxisReverseExtentType',
          type: 'number',
          label: ' Direct Axis Reverse Extent Type',
          valuePath: 'DirectAxisReverseExtentType',
          description: "Whether the DirectAxisReverseExtent value is an absolute value or a percentage of the direct distance.",
          extra: {
            options: [
              {label: "Absolute value" ,value: 0},
              {label: "Percentage" ,value: 1}
            ]
          },
          editable: true
        },
        {
          id: 'orthogonalAxisExtent',
          type: 'number',
          label: ' Orthogonal Axis Extent',
          valuePath: 'OrthogonalAxisExtent',
          description: "The distance from the direct travel axis along the orthogonal axis that marks the boundary of the geographic search area used to find possible feeder trips.  If the OrthogonalAxisExtentType value is set to 0, this is in meters.  If the OrthogonalAxisExtentType value is set to 1, this is a percentage of the direct ride distance.",
          editable: true
        },
        {
          id: 'orthogonalAxisExtentType',
          type: 'select',
          label: ' Orthogonal Axis Extent Type',
          valuePath: 'OrthogonalAxisExtentType',
          description: "Whether the OrthogonalAxisExtent value is an absolute value or a percentage of the direct distance.",
          extra: {
            options: [
              {label: "Absolute value" ,value: 0},
              {label: "Percentage" ,value: 1}
            ]
          },
          editable: true
        },
        {
          id: 'fullFixedRoutePriority',
          type: 'number',
          label: ' Full Fixed Route Priority',
          valuePath: 'FullFixedRoutePriority',
          description: "The order in which the Fixed Route Engine will for look for full fixed-route itineraries.  A value of 1 means that the Fixed Route Engine will look for full fixed route itineraries first.  A value of 2 means the Fixed Route Engine will look for full fixed route itineraries second.  A value of 3 means the Fixed Route Engine will look for full fixed route itineraries third.  A value of 0 means that the Fixed Route Engine will not look for full fixed route itineraries at all.  Setting this to the same value as FeederPriority or ReverseFeederPriority will result in the Fixed Route Engine returning itineraries of both types together.",
          editable: true
        },
        {
          id: 'feederPriority',
          type: 'number',
          label: ' Feeder Priority',
          valuePath: 'FeederPriority',
          description: "The order in which the Fixed Route Engine will for look for feeder itineraries.  A value of 1 means that the Fixed Route Engine will look for feeder itineraries first.  A value of 2 means the Fixed Route Engine will look for feeder itineraries second.  A value of 3 means the Fixed Route Engine will look for feeder itineraries third.  A value of 0 means that the Fixed Route Engine will not look for feeder itineraries at all.  Setting this to the same value as FullFixedRoutePriority or ReverseFeederPriority will result in the Fixed Route Engine returning itineraries of both types together.",
          editable: true
        },
        {
          id: 'reverseFeederPriority',
          type: 'number',
          label: ' Reverse Feeder Priority',
          valuePath: 'ReverseFeederPriority',
          description: "The order in which the Fixed Route Engine will for look for reverse feeder itineraries.  A value of 1 means that the Fixed Route Engine will look for reverse feeder first.  A value of 2 means the Fixed Route Engine will look for reverse feeder itineraries second.  A value of 3 means the Fixed Route Engine will look for reverse feeder itineraries third.  A value of 0 means that the Fixed Route Engine will not look for reverse feeder itineraries at all.  Setting this to the same value as FullFixedRoutePriority or FeederPriority will result in the Fixed Route Engine returning itineraries of both types together.",
          editable: true
        },
        {
          id: 'defaultWalkingSpeed',
          type: 'number',
          label: ' Default Walking Speed',
          valuePath: 'DefaultWalkingSpeed',
          description: "The speed the Fixed Route Engine will assume that clients can walk in meters per second.",
          editable: true
        },
        {
          id: 'defaultMaximumWalkingDistance',
          type: 'number',
          label: ' Default Maximum Walking Distance',
          valuePath: 'DefaultMaximumWalkingDistance',
          description: "The maximum walking distance, in meters, the Fixed Route Engine will assume that clients can walk.",
          editable: true
        },
        {
          id: 'defaultLoadingTime',
          type: 'number',
          label: ' Default Loading Time',
          valuePath: 'DefaultLoadingTime',
          description: "The time, in minutes, the Fixed Route Engine will assume that clients take to load into a demand response vehicle.",
          editable: true
        },
        {
          id: 'defaultUnloadingTime',
          type: 'number',
          label: ' Default Unloading Time',
          valuePath: 'DefaultUnloadingTime',
          description: "The time, in minutes, the Fixed Route Engine will assume that clients take to unload from a demand response vehicle.",
          editable: true
        },
        {
          id: 'travelOracleType',
          type: 'number',
          label: ' Travel Oracle Type',
          valuePath: 'TravelOracleType',
          description: "The type of Travel Oracle the Fixed Route Engine will use when calculating travel time and distance.",
          editable: true
        },
        {
          id: 'returnCompleteItinerary',
          type: 'boolean',
          label: ' Return Complete Itinerary',
          valuePath: 'ReturnCompleteItinerary',
          description: "Determines whether the Fixed Route Engine will return every stop along the fixed route itinerary.  Use 'true' for yes.  Use 'false' for no.",
          extra: {
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false }
            ]
          },
          editable: true
        },
        {
          id: 'excludeStopsWithinWalkingDistanceFromFeederTrips',
          type: 'boolean',
          label: ' Exclude Stops Within Walking Distance From Feeder Trips',
          valuePath: 'ExcludeStopsWithinWalkingDistanceFromFeederTrips',
          description: "Determines whether the Fixed Route Engine will exclude stops within the client's walking distance from being considered for feeder and reverse feeder solutions.  Use 'true' for yes. Use 'false' for no.",
          extra: {
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false }
            ]
          },
          editable: true
        },
        {
          id: 'demandTripProcessingTimeLimit',
          type: 'text',
          label: ' Demand Trip Processing Time Limit',
          valuePath: 'DemandTripProcessingTimeLimit',
          description: "The number of seconds the Fixed Route Engine is allowed to search for demand itineraries.  Once the time limit is reached, the Fixed Route Engine will return any valid itineraries it has already found.  Use '0' to disable.",
          editable: true
        },
        {
          id: 'subscriptionTripProcessingTimeLimit',
          type: 'text',
          label: ' Subscription Trip Processing Time Limit',
          valuePath: 'SubscriptionTripProcessingTimeLimit',
          description: "The number of seconds the Fixed Route Engine is allowed to search for subscription itineraries.  Once the time limit is reached, the Fixed Route Engine will return any valid itineraries it has already found.  Use '0' to disable.",
          editable: true
        },
        {
          id: 'restrictFeederTransferLocations',
          type: 'text',
          label: ' Restrict Feeder Transfer Locations',
          valuePath: 'RestrictFeederTransferLocations',
          description: "Determines whether the Fixed Route Engine will default stops to not allow clients to transfer between demand response vehicles and the fixed route network.  The default setting can be overriden by explicitly setting the individual stop constraints.  Use 'true' for yes. Use 'false' for no.",
          editable: true
        },
        {
          id: 'restrictWalkingTransferLocations',
          type: 'text',
          label: ' Restrict Walking Transfer Locations',
          valuePath: 'RestrictWalkingTransferLocations',
          description: "Determines whether the Fixed Route Engine will default stops to not allow clients to transfer between walking and the fixed route network.  The default setting can be overriden by explicitly setting the individual stop constraints.  Use 'true' for yes. Use 'false' for no.",
          editable: true
        }
      ]},
    {
      title: 'Book Trip',
      fields: [
        {
          id: 'passengersCanBookTripsOnApp',
          type: 'boolean',
          label: 'Passengers Can Book Trips On App',
          valuePath: 'passengersCanBookTripsOnApp',
          default: 'Yes',
          extra: {
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]
          },
          editable: true
        },
    {
          id: 'allowFree-typeAddress',
          type: 'boolean',
          label: 'Allow Free-type Address',
          valuePath: 'allowFree-typeAddress',
          default: 'Yes',
          extra: {
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]
          },
          editable: true
        },
    {
          id: 'allowSame-Daytrip',
          type: 'boolean',
          label: 'Allow Same-Day trip',
          valuePath: 'allowSame-Daytrip',
          default: false,
          extra: {
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]
          },
          editable: true
        },
    {
          id: 'futuretripTimeline',
          type: 'number',
          label: 'Future trip Timeline',
          valuePath: 'futuretripTimeline',
          default: 2,
          editable: true
        },
    {
          id: 'cutoffTimeForTomorrowtrip ',
          type: 'time',
          label: 'Cutoff Time For Tomorrow trip ',
          valuePath: 'cutoffTimeForTomorrowtrip ',
          default: 1700,
          editable: true
        },
    {
          id: 'allowDefaultTravelNeedsChangedPerTrip',
          type: 'boolean',
          label: 'Allow Default Travel Needs Changed Per Trip',
          valuePath: 'allowDefaultTravelNeedsChangedPerTrip',
          default: true,
          extra: {
            options: [
              { label: 'True', value: true },
              { label: 'False', value: false }
            ]
          },
          editable: true
        },
        {
          // TODO: Convert this to an eligibilities table
          id: 'eligibilityType',
          type: 'text',
          label: 'Eligibility Type',
          valuePath: 'eligibilityType',
          editable: true
        },
        {
          id: 'eligibilityMessage',
          type: 'text',
          label: 'Eligibility Message',
          valuePath: 'eligibilityMessage',
          default: 'Trip date must be within eligibility date range.',
          editable: true
        },
        {
          id: 'overlapMessage',
          type: 'text',
          label: 'Overlap Message',
          valuePath: 'overlapMessage',
          default: 'Unable to book trip. Trip overlaps with an existing trip.',
          editable: true
        }
      ]
    },
    {
      title: 'Scheduling Parameters',
      fields: [
        {
          id: 'ACT_DANCHORREGULAR_11',
          type: 'number',
          label: 'Act Danchorregular 11',
          valuePath: 'ACT_DANCHORREGULAR_11',
          description: "If set to 1, the Engine will assign service type ID of all appointment anchored subscriptions to a hardcoded value of 11.",
          editable: true
        },
    {
          id: 'ACT_FLOAT_ADD1',
          type: 'number',
          label: 'Act Float Add1',
          valuePath: 'ACT_FLOAT_ADD1',
          description: "Extra rideshare time allowed for drop anchored subscription trips as a function of the trip's direct ride time",
          editable: true
        },
    {
          id: 'ACT_FLOAT_ADD2',
          type: 'number',
          label: 'Act Float Add2',
          valuePath: 'ACT_FLOAT_ADD2',
          description: "Extra rideshare time allowed for drop anchored subscription trips as a function of the trip's direct ride time",
          editable: true
        },
    {
          id: 'ACT_FLOAT_ADD3',
          type: 'number',
          label: 'Act Float Add3',
          valuePath: 'ACT_FLOAT_ADD3',
          description: "Extra rideshare time allowed for drop anchored subscription trips as a function of the trip's direct ride time",
          editable: true
        },
    {
          id: 'ACT_FLOAT_ADD4',
          type: 'number',
          label: 'Act Float Add4',
          valuePath: 'ACT_FLOAT_ADD4',
          description: "Extra rideshare time allowed for drop anchored subscription trips as a function of the trip's direct ride time",
          editable: true
        },
    {
          id: 'ACT_FLOAT_TIME1',
          type: 'number',
          label: 'Act Float Time1',
          valuePath: 'ACT_FLOAT_TIME1',
          description: "Maximum direct ride time for which the corresponding extra rideshare time applies",
          editable: true
        },
    {
          id: 'ACT_FLOAT_TIME2',
          type: 'number',
          label: 'Act Float Time2',
          valuePath: 'ACT_FLOAT_TIME2',
          description: "Maximum direct ride time for which the corresponding extra rideshare time applies",
          editable: true
        },
    {
          id: 'ACT_FLOAT_TIME3',
          type: 'number',
          label: 'Act Float Time3',
          valuePath: 'ACT_FLOAT_TIME3',
          description: "Maximum direct ride time for which the corresponding extra rideshare time applies",
          editable: true
        },
    {
          id: 'ACT_FLOAT_TIME4',
          type: 'number',
          label: 'Act Float Time4',
          valuePath: 'ACT_FLOAT_TIME4',
          description: "Maximum direct ride time for which the corresponding extra rideshare time applies",
          editable: true
        },
    {
          id: 'AFFINITY_PACK_SIZE',
          type: 'number',
          label: 'Affinity Pack Size',
          valuePath: 'AFFINITY_PACK_SIZE',
          description: "The number of zone affinities packed into a single field.  Each of the ten original zone affinity fields contains a number.  Each digit of that number represents a zone affinity.",
          editable: true
        },
    {
          id: 'AFFINITY_WEIGHT_DROP',
          type: 'number',
          label: 'Affinity Weight Drop',
          valuePath: 'AFFINITY_WEIGHT_DROP',
          description: "The weighting for drops when calculating route zone affinity",
          editable: true
        },
    {
          id: 'AFFINITY_WEIGHT_PICK',
          type: 'number',
          label: 'Affinity Weight Pick',
          valuePath: 'AFFINITY_WEIGHT_PICK',
          description: "The weighting for picks when calculating route zone affinity",
          editable: true
        },
    {
          id: 'AGENCY_IS_CONSTRAINT',
          type: 'number',
          label: 'Agency Is Constraint',
          valuePath: 'AGENCY_IS_CONSTRAINT',
          description: "If turned on, the scheduling engine will not allow a trip to be scheduled on a route that is part of an agency not associated with the trip",
          editable: true
        },
    {
          id: 'ANGLE_GAP',
          type: 'number',
          label: 'Angle Gap',
          valuePath: 'ANGLE_GAP',
          description: "The minimum number of degrees between stops for an angle to be considered a gap.",
          editable: true
        },
    {
          id: 'ANGLE_SPLIT_POINT',
          type: 'number',
          label: 'Angle Split Point',
          valuePath: 'ANGLE_SPLIT_POINT',
          description: "The minimum number of degrees between stops in a wide angle to be considered a place to split a wide angle.",
          editable: true
        },
    {
          id: 'ANGLE_WIDE',
          type: 'number',
          label: 'Angle Wide',
          valuePath: 'ANGLE_WIDE',
          description: "The minimum number of degrees for an angle to be considered wide for splitting purposes.",
          editable: true
        },
    {
          id: 'APPT_PICKWIN_LENGTH',
          type: 'number',
          label: 'Appt Pickwin Length',
          valuePath: 'APPT_PICKWIN_LENGTH',
          description: "The maximum pick window length allowed for drop anchored trips when the ride time is shortened by moving the picks closer to the drops and trimming the beginning of the pick window.",
          editable: true
        },
    {
          id: 'AT_RISK_WIN_PERCENT',
          type: 'number',
          label: 'At Risk Win Percent',
          valuePath: 'AT_RISK_WIN_PERCENT',
          description: "This defines where in the pick window trips are considered to be at risk of being late.",
          editable: true
        },
    {
          id: 'BATCH_MULTIPLE_PASSES',
          type: 'number',
          label: 'Batch Multiple Passes',
          valuePath: 'BATCH_MULTIPLE_PASSES',
          description: "The number of times to run through the batching process to improve ridesharing.",
          editable: true
        },
    {
          id: 'BEST_FEEDER_CRITERIA',
          type: 'number',
          label: 'Best Feeder Criteria',
          valuePath: 'BEST_FEEDER_CRITERIA',
          description: "The criteria to use when choosing the best feeder trip.  If 0, the criteria is the direct ride distance.  If 1, the criteria is the direct ride time.",
          editable: true
        },
    {
          id: 'BLOCKTRAN_EXTEND_SHIFT',
          type: 'number',
          label: 'Blocktran Extend Shift',
          valuePath: 'BLOCKTRAN_EXTEND_SHIFT',
          description: "If turned on, the scheduling engine will not allow block transfers that cause shifts to be extended",
          editable: true
        },
    {
          id: 'BREAK_OVER_MANUAL',
          type: 'number',
          label: 'Break Over Manual',
          valuePath: 'BREAK_OVER_MANUAL',
          description: "If turned on, inserted manual trips that conflict with breaks are marked as illegal.",
          editable: true
        },
    {
          id: 'CARVE_BEST_WIN',
          type: 'number',
          label: 'Carve Best Win',
          valuePath: 'CARVE_BEST_WIN',
          description: "Make the promise time as close to the request time as possible.  For appointment trips, this has a negative impact on scheduling flexibility.",
          editable: true
        },
    {
          id: 'CARVE_PRO_WIN',
          type: 'number',
          label: 'Carve Pro Win',
          valuePath: 'CARVE_PRO_WIN',
          description: "Determines whether the Window Carving feature is allowed to carve trips with assigned promise times.",
          editable: true
        },
    {
          id: 'CLOSE_STOPS_MAX_TIME',
          type: 'number',
          label: 'Close Stops Max Time',
          valuePath: 'CLOSE_STOPS_MAX_TIME',
          description: "The maximum amount of time between stops that are considered close to each other.",
          editable: true
        },
    {
          id: 'CLUSTER_BY_ANCHOR',
          type: 'number',
          label: 'Cluster By Anchor',
          valuePath: 'CLUSTER_BY_ANCHOR',
          description: "If turned on, the scheduling engine only clusters trips that are of the same anchor type.",
          editable: true
        },
    {
          id: 'COM_DIST',
          type: 'number',
          label: 'Com Dist',
          valuePath: 'COM_DIST',
          description: "The maximum distance between pairs of stops in a cluster",
          editable: true
        },
    {
          id: 'COM_MAX_SIZE',
          type: 'number',
          label: 'Com Max Size',
          valuePath: 'COM_MAX_SIZE',
          description: "The maximum number of stops in a cluster",
          editable: true
        },
    {
          id: 'COM_PRIORITY_TYPE',
          type: 'text',
          label: 'Com Priority Type',
          valuePath: 'COM_PRIORITY_TYPE',
          description: "If P, the clustering algorithm prioritizes common picks over common drops.  If D, the reverse is true.",
          editable: true
        },
    {
          id: 'COM_WIN_OVERLAP',
          type: 'number',
          label: 'Com Win Overlap',
          valuePath: 'COM_WIN_OVERLAP',
          description: "The minimum time overlap between pick/drop windows in a cluster",
          editable: true
        },
    {
          id: 'DELTA_FLEET',
          type: 'number',
          label: 'Delta Fleet',
          valuePath: 'DELTA_FLEET',
          description: "The maximum allowed change in the number of routes between two consecutive engine calls.",
          editable: true
        },
    {
          id: 'DIRECT_RIDE_OK_X_MI_OF_D',
          type: 'number',
          label: 'Direct Ride Ok X Mi Of D',
          valuePath: 'DIRECT_RIDE_OK_X_MI_OF_D',
          description: "This is an alternate name for the GONORTH_OK_X_MI_OF_D parameter",
          editable: true
        },
    {
          id: 'DIRECT_RIDE_OVERSHOOT_PERCENT',
          type: 'number',
          label: 'Direct Ride Overshoot Percent',
          valuePath: 'DIRECT_RIDE_OVERSHOOT_PERCENT',
          description: "This is an alternate name for the GONORTH_OVERSHOOT_PERCENT parameter",
          editable: true
        },
    {
          id: 'DIRECT_RIDE_PERCENT',
          type: 'number',
          label: 'Direct Ride Percent',
          valuePath: 'DIRECT_RIDE_PERCENT',
          description: "This is an alternate name for the GONORTH_PERCENT parameter",
          editable: true
        },
    {
          id: 'DIRECT_RIDE_SWITCH',
          type: 'number',
          label: 'Direct Ride Switch',
          valuePath: 'DIRECT_RIDE_SWITCH',
          description: "This is an alternate name for the GONORTH_RIDE_SWITCH parameter",
          editable: true
        },
    {
          id: 'DRIVEBY_DROP_DIST',
          type: 'number',
          label: 'Driveby Drop Dist',
          valuePath: 'DRIVEBY_DROP_DIST',
          description: "The maximum distance from the route that triggers a driveby drop off.",
          editable: true
        },
    {
          id: 'DRIVEBY_MAX_ADD_T',
          type: 'number',
          label: 'Driveby Max Add T',
          valuePath: 'DRIVEBY_MAX_ADD_T',
          description: "The maximum amount of time allowed to be added to a route by a driveby.",
          editable: true
        },
    {
          id: 'DRIVEBY_PICK_DIST',
          type: 'number',
          label: 'Driveby Pick Dist',
          valuePath: 'DRIVEBY_PICK_DIST',
          description: "The maximum distance from the route that triggers a driveby pick up.",
          editable: true
        },
    {
          id: 'DRIVER_BREAK_SPAN_END',
          type: 'number',
          label: 'Driver Break Span End',
          valuePath: 'DRIVER_BREAK_SPAN_END',
          description: "Any driver break after this limit will be automatically removed by the scheduling engine during the remove driver breaks mode.",
          editable: true
        },
    {
          id: 'DRIVER_BREAK_SPAN_START',
          type: 'number',
          label: 'Driver Break Span Start',
          valuePath: 'DRIVER_BREAK_SPAN_START',
          description: "Any driver break before this limit will be automatically removed by the scheduling engine during the remove driver breaks mode.",
          editable: true
        },
    {
          id: 'DRIVER_FACTOR_SWITCH',
          type: 'number',
          label: 'Driver Factor Switch',
          valuePath: 'DRIVER_FACTOR_SWITCH',
          description: "If turned on, enable the use of the driver factor when scheduling trips.",
          editable: true
        },
    {
          id: 'ETA_CHANGE_THRESHOLD',
          type: 'number',
          label: 'Eta Change Threshold',
          valuePath: 'ETA_CHANGE_THRESHOLD',
          description: "Ignore ETA variances less than this many minutes.   The threshold is applied only when ETAs are being updated using GPS data.",
          editable: true
        },
    {
          id: 'FLEET_REC_INCLUDES_SUBFLEET',
          type: 'number',
          label: 'Fleet Rec Includes Subfleet',
          valuePath: 'FLEET_REC_INCLUDES_SUBFLEET',
          description: "If turned on, keeper route, route trip limit, and route mile limit information from the 2fleeet.asc file are used.",
          editable: true
        },
    {
          id: 'FREEZE_WIN_LENGTH',
          type: 'number',
          label: 'Freeze Win Length',
          valuePath: 'FREEZE_WIN_LENGTH',
          description: "The earliest time in the future that the engine will schedule non-manual trips",
          editable: true
        },
    {
          id: 'FULL_TRIPS_OUT_ALWAYS',
          type: 'number',
          label: 'Full Trips Out Always',
          valuePath: 'FULL_TRIPS_OUT_ALWAYS',
          description: "If turned on, the scheduling engine will always load the full engine state",
          editable: true
        },
    {
          id: 'GROUP_MAX_DIST',
          type: 'number',
          label: 'Group Max Dist',
          valuePath: 'GROUP_MAX_DIST',
          description: "This specifies the maximum distance between stops for them to be considered a group.",
          editable: true
        },
    {
          id: 'HONOR_FLEX_TRIPS',
          type: 'number',
          label: 'Honor Flex Trips',
          valuePath: 'HONOR_FLEX_TRIPS',
          description: "If turned on, this allows semi-manual trips to be scheduled.",
          editable: true
        },
    {
          id: 'HONOR_MANUAL_TRIPS',
          type: 'number',
          label: 'Honor Manual Trips',
          valuePath: 'HONOR_MANUAL_TRIPS',
          description: "If turned on, this allows manual trips to be scheduled",
          editable: true
        },
    {
          id: 'HONOR_SEMIMANUAL_TRIPS',
          type: 'number',
          label: 'Honor Semimanual Trips',
          valuePath: 'HONOR_SEMIMANUAL_TRIPS',
          description: "This is an alternate name for the HONOR_FLEX_TRIPS parameter",
          editable: true
        },
    {
          id: 'IGNORE_INCONSISTENT_MAPPINGS',
          type: 'number',
          label: 'Ignore Inconsistent Mappings',
          valuePath: 'IGNORE_INCONSISTENT_MAPPINGS',
          description: "If turned on, the scheduling engine will not verify that the trip geonodes are mapped to the proper zone and river regions.",
          editable: true
        },
    {
          id: 'IGNORE_TPRO',
          type: 'number',
          label: 'Ignore Tpro',
          valuePath: 'IGNORE_TPRO',
          description: "If turned on, the scheduling engine will ignore the promise time and just use the request time.",
          editable: true
        },
    {
          id: 'INCLUDE_PICK_DWELL_IN_ETA_CALC_',
          type: 'number',
          label: 'Include Pick Dwell In Eta Calc ',
          valuePath: 'INCLUDE_PICK_DWELL_IN_ETA_CALC_',
          description: "If false, the engine will not include the pick dwell in ETA calculations from a performed stop.",
          editable: true
        },
    {
          id: 'INPLACE_DIST',
          type: 'number',
          label: 'Inplace Dist',
          valuePath: 'INPLACE_DIST',
          description: "The minimum distance that must exist between the pick and drop locations for two trips in order to be reoptimized when using the ReOptimization in Place mode.",
          editable: true
        },
    {
          id: 'INPLACE_WIN_OVERLAP',
          type: 'number',
          label: 'Inplace Win Overlap',
          valuePath: 'INPLACE_WIN_OVERLAP',
          description: "The minimum amount of time that service windows must overlap in order to be reoptimized when using the ReOptimization in Place mode.",
          editable: true
        },
    {
          id: 'INPLACE_WL_LIMIT',
          type: 'number',
          label: 'Inplace Wl Limit',
          valuePath: 'INPLACE_WL_LIMIT',
          description: "The maximum number of trips that can be waitlisted when a ReOptimization in Place is performed.",
          editable: true
        },
    {
          id: 'INS_OPT_RIDESHARE',
          type: 'number',
          label: 'Ins Opt Rideshare',
          valuePath: 'INS_OPT_RIDESHARE',
          description: "If turned on, the engine will perform ridesharing on inserts.",
          editable: true
        },
    {
          id: 'INS_OPT_RIDESHARE_ALLOW_WL',
          type: 'number',
          label: 'Ins Opt Rideshare Allow Wl',
          valuePath: 'INS_OPT_RIDESHARE_ALLOW_WL',
          description: "If turned on, waitlist the trip and attempt to insert the original trip on the rideshare route.",
          editable: true
        },
    {
          id: 'INS_OPT_RIDESHARE_MAX_DIST',
          type: 'number',
          label: 'Ins Opt Rideshare Max Dist',
          valuePath: 'INS_OPT_RIDESHARE_MAX_DIST',
          description: "The maximum distance to search for rideshare opportunities",
          editable: true
        },
    {
          id: 'INS_OPT_RIDESHARE_OFF_TIME',
          type: 'number',
          label: 'Ins Opt Rideshare Off Time',
          valuePath: 'INS_OPT_RIDESHARE_OFF_TIME',
          description: "The time of day prior to the trip date that the engine will stop applying the rideshare optimization.",
          editable: true
        },
    {
          id: 'INS_OPT_RIDESHARE_TIME_SPAN',
          type: 'number',
          label: 'Ins Opt Rideshare Time Span',
          valuePath: 'INS_OPT_RIDESHARE_TIME_SPAN',
          description: "The maximum number of minutes to search for rideshare opportunities.",
          editable: true
        },
    {
          id: 'INSERT_NEGOTIATE_INCREMENT',
          type: 'number',
          label: 'Insert Negotiate Increment',
          valuePath: 'INSERT_NEGOTIATE_INCREMENT',
          description: "This is an alternate name for the NEGOTIATE_INCREMENT parameter",
          editable: true
        },
    {
          id: 'INTER_QUOTIENT_TOL',
          type: 'number',
          label: 'Inter Quotient Tol',
          valuePath: 'INTER_QUOTIENT_TOL',
          description: "The maximum difference of inter-borough trips between providers",
          editable: true
        },
    {
          id: 'INTRA_MAX_SHORT_PC',
          type: 'number',
          label: 'Intra Max Short Pc',
          valuePath: 'INTRA_MAX_SHORT_PC',
          description: "The percentage of the longest intra-borough trip that can be used as the maximum distance for a short trip.",
          editable: true
        },
    {
          id: 'INTRA_MIN_LONG_PC',
          type: 'number',
          label: 'Intra Min Long Pc',
          valuePath: 'INTRA_MIN_LONG_PC',
          description: "The percentage of the longest intra-borough trip to use as the minimum distance of a long trip",
          editable: true
        },
    {
          id: 'LONG_TRIP_MIN_DIST',
          type: 'number',
          label: 'Long Trip Min Dist',
          valuePath: 'LONG_TRIP_MIN_DIST',
          description: "Threshold for the scheduling engine to consider a trip 'long'.",
          editable: true
        },
    {
          id: 'LONG_TRIP_MIN_TIME',
          type: 'number',
          label: 'Long Trip Min Time',
          valuePath: 'LONG_TRIP_MIN_TIME',
          description: "This controls how the scheduling engine classifies trips as long trips.  Inter-borough Trips with times over this value are classified as long trips.",
          editable: true
        },
    {
          id: 'MAX_PAIRS_TO_CONSIDER',
          type: 'number',
          label: 'Max Pairs To Consider',
          valuePath: 'MAX_PAIRS_TO_CONSIDER',
          description: "The maximum number of insertion pairs to look at when determining the best place to insert a new trip.",
          editable: true
        },
    {
          id: 'MAX_ROUTES_TO_CONSIDER',
          type: 'number',
          label: 'Max Routes To Consider',
          valuePath: 'MAX_ROUTES_TO_CONSIDER',
          description: "The maximum number of routes to look at when selecting the best route to place a trip on.  Once the number of candidate routes has been reduced to this number or less, the route filtering stops.",
          editable: true
        },
    {
          id: 'MAXRIDE_BY_DIST',
          type: 'number',
          label: 'Maxride By Dist',
          valuePath: 'MAXRIDE_BY_DIST',
          description: "If true, the maximum ride is based on distance, not ride time.",
          editable: true
        },
    {
          id: 'MAXRIDE_COM_PAD',
          type: 'number',
          label: 'Maxride Com Pad',
          valuePath: 'MAXRIDE_COM_PAD',
          description: "Factor used to pad the maximum ride of trips in a cluster",
          editable: true
        },
    {
          id: 'MAXRIDE_FACTOR',
          type: 'number',
          label: 'Maxride Factor',
          valuePath: 'MAXRIDE_FACTOR',
          description: "The initial calculation of a trip's maximum ride distance is this parameter multiplied by the trip's direct ride distance",
          editable: true
        },
    {
          id: 'MAXRIDE_MAX',
          type: 'number',
          label: 'Maxride Max',
          valuePath: 'MAXRIDE_MAX',
          description: "The maximum allowable maximum ride distance",
          editable: true
        },
    {
          id: 'MAXRIDE_MIN',
          type: 'number',
          label: 'Maxride Min',
          valuePath: 'MAXRIDE_MIN',
          description: "The minimum allowable maximum ride distance",
          editable: true
        },
    {
          id: 'MODTRIP_MAX_XY_DELTA',
          type: 'number',
          label: 'Modtrip Max Xy Delta',
          valuePath: 'MODTRIP_MAX_XY_DELTA',
          description: "The maximum mileage differential for minor address changes.",
          editable: true
        },
    {
          id: 'MULTI_ROUTE_OPTION_LIMIT',
          type: 'number',
          label: 'Multi Route Option Limit',
          valuePath: 'MULTI_ROUTE_OPTION_LIMIT',
          description: "For modes that support returning multiple scheduling options, this parameter controls how many possible possible routes  are returned.",
          editable: true
        },
    {
          id: 'NEGOTIATE_INCREMENT',
          type: 'number',
          label: 'Negotiate Increment',
          valuePath: 'NEGOTIATE_INCREMENT',
          description: "Specifies the increment size when searching for a trip promise time during the trip negotiation process.",
          editable: true
        },
    {
          id: 'NEGOTIATE_RANGE',
          type: 'number',
          label: 'Negotiate Range',
          valuePath: 'NEGOTIATE_RANGE',
          description: "The maximum allowed time deviation from the original request time when negotiating trips",
          editable: true
        },
    {
          id: 'NO_FREEZE_WIN',
          type: 'number',
          label: 'No Freeze Win',
          valuePath: 'NO_FREEZE_WIN',
          description: "If turned on, the engine will ignore the parameter FREEZE_WIN_LENGTH and thus will allow trips to be scheduled immediately",
          editable: true
        },
    {
          id: 'OVERLAP_INTER_BOROUGH',
          type: 'number',
          label: 'Overlap Inter Borough',
          valuePath: 'OVERLAP_INTER_BOROUGH',
          description: "The number of minutes that the scheduling engine will ensure exists between pick and drop times on multiples trips for a client where the early trip for that client is between two boroughs.",
          editable: true
        },
    {
          id: 'OVERLAP_INTRA_BOROUGH',
          type: 'number',
          label: 'Overlap Intra Borough',
          valuePath: 'OVERLAP_INTRA_BOROUGH',
          description: "The number of minutes that the scheduling engine will ensure exists between pick and drop times on multiples trips for a client where the early trip for that client is within the same borough.",
          editable: true
        },
    {
          id: 'PANCHOR_INSERT_BIG_WIN',
          type: 'number',
          label: 'Panchor Insert Big Win',
          valuePath: 'PANCHOR_INSERT_BIG_WIN',
          description: "DEPRECATED: see CARVE_BEST_WIN.   Setting this to 0 is equivalent to setting Carve_Best_Win to -1, setting this to 1 is equivalent to setting CARVE_BEST_WIN to 0.",
          editable: true
        },
    {
          id: 'PARENT_OF_PVDR_',
          type: 'number',
          label: 'Parent Of Pvdr ',
          valuePath: 'PARENT_OF_PVDR_',
          description: "Use to define parent-child relationship among providers.  The relationship can be at most 1 level deep (no grandparent provider is allowed).  The value of the parameter is the provider id of the parent.",
          editable: true
        },
    {
          id: 'PASS_RANDOM_SEED',
          type: 'number',
          label: 'Pass Random Seed',
          valuePath: 'PASS_RANDOM_SEED',
          description: "Seed for a pseudorandom number generator, which allows a random selection of candidate(s) upon reaching the end of a strategy section. By default the pseudorandom generator is disabled, which results in a deterministic selection of candidate(s), in other words the first candidate(s) in the remaining list is/are selected.",
          editable: true
        },
    {
          id: 'PATCH_ANCH_SAMEADDR_OK',
          type: 'number',
          label: 'Patch Anch Sameaddr Ok',
          valuePath: 'PATCH_ANCH_SAMEADDR_OK',
          description: "If turned on, the scheduling engine will inclue common stops in the drive by processing logic.",
          editable: true
        },
    {
          id: 'PATCH_DROP_AT_1ST_OPP',
          type: 'number',
          label: 'Patch Drop At 1st Opp',
          valuePath: 'PATCH_DROP_AT_1ST_OPP',
          description: "If turned on, when different stops occur at the same address, drops are scheduled first.",
          editable: true
        },
    {
          id: 'PATCH_PICK_AT_LAST_OPP',
          type: 'number',
          label: 'Patch Pick At Last Opp',
          valuePath: 'PATCH_PICK_AT_LAST_OPP',
          description: "If turned on, when different stops occur at the same address, picks are scheduled last.",
          editable: true
        },
    {
          id: 'PATCH_WC_LAST_SAME_ADDR',
          type: 'number',
          label: 'Patch Wc Last Same Addr',
          valuePath: 'PATCH_WC_LAST_SAME_ADDR',
          description: "If true, the manifest is patched such that wheelchairs are unloaded last if there are any ambulatory trips to the same drop address.",
          editable: true
        },
    {
          id: 'PERCENT_TS_PER_PVDR',
          type: 'number',
          label: 'Percent Ts Per Pvdr',
          valuePath: 'PERCENT_TS_PER_PVDR',
          description: "The percentage of trips that should be assigned to a specific provider",
          editable: true
        },
    {
          id: 'PREVENT_GROUP_TRIP_EARLY_DROP',
          type: 'number',
          label: 'Prevent Group Trip Early Drop',
          valuePath: 'PREVENT_GROUP_TRIP_EARLY_DROP',
          description: "If true, the engine makes sure the drop window of group trips is not enlarged to allow for early drops.  Preventing early drop ensures the service window are respected but decreases ridesharing.",
          editable: true
        },
    {
          id: 'PVDR_IS_CONSTRAINT',
          type: 'number',
          label: 'Pvdr Is Constraint',
          valuePath: 'PVDR_IS_CONSTRAINT',
          description: "If turned on, then any trip that specifies a provider must be assigned to a route of that provider",
          editable: true
        },
    {
          id: 'PWIN_SVCTYPE_',
          type: 'number',
          label: 'Pwin Svctype ',
          valuePath: 'PWIN_SVCTYPE_',
          description: "The size of the pick window for a given service type",
          editable: true
        },
    {
          id: 'QUIET_ASCII',
          type: 'number',
          label: 'Quiet Ascii',
          valuePath: 'QUIET_ASCII',
          description: "If turned on, the scheduling engine will not produce extra diagnostic files in the mt folder.",
          editable: true
        },
    {
          id: 'QUIET_METRIC',
          type: 'number',
          label: 'Quiet Metric',
          valuePath: 'QUIET_METRIC',
          description: "If turned on, the engine will not report scheduling metrics.",
          editable: true
        },
    {
          id: 'REORDER_STRICTLY',
          type: 'number',
          label: 'Reorder Strictly',
          valuePath: 'REORDER_STRICTLY',
          description: "If turned on, the scheduling engine will reorder trips in the exact order specified rather than possibly reordering trips based on an internal analysis of grouping.",
          editable: true
        },
    {
          id: 'RTE_CONSOLIDATION_FAIR_SHR',
          type: 'number',
          label: 'Rte Consolidation Fair Shr',
          valuePath: 'RTE_CONSOLIDATION_FAIR_SHR',
          description: "If turned on, the scheduling engine will use the provider specific fairshare controls",
          editable: true
        },
    {
          id: 'RTE_CONSOLIDATION_KEEP_PCT',
          type: 'number',
          label: 'Rte Consolidation Keep Pct',
          valuePath: 'RTE_CONSOLIDATION_KEEP_PCT',
          description: "The percentage of lightly loaded routes that should remain in service.",
          editable: true
        },
    {
          id: 'RTE_CONSOLIDATION_SHR_',
          type: 'number',
          label: 'Rte Consolidation Shr ',
          valuePath: 'RTE_CONSOLIDATION_SHR_',
          description: "The percentage of lightly loaded routes belonging to a specific provider that should be left in service.",
          editable: true
        },
    {
          id: 'RTE_CONSOLIDATION_SLACK_LEN',
          type: 'number',
          label: 'Rte Consolidation Slack Len',
          valuePath: 'RTE_CONSOLIDATION_SLACK_LEN',
          description: "The maximum amount of time of slack that is ignored when calculating a route's work time",
          editable: true
        },
    {
          id: 'RTE_CONSOLIDATION_STRATEGY',
          type: 'text',
          label: 'Rte Consolidation Strategy',
          valuePath: 'RTE_CONSOLIDATION_STRATEGY',
          description: "The strategy file to use when consolidating lightly loaded routes.",
          editable: true
        },
    {
          id: 'RTE_CONSOLIDATION_T_CNT',
          type: 'number',
          label: 'Rte Consolidation T Cnt',
          valuePath: 'RTE_CONSOLIDATION_T_CNT',
          description: "Routes with less than or equal to this number of bonafide trips are considered lightly loaded routes.",
          editable: true
        },
    {
          id: 'RTE_CONSOLIDATION_WORK_TIME',
          type: 'number',
          label: 'Rte Consolidation Work Time',
          valuePath: 'RTE_CONSOLIDATION_WORK_TIME',
          description: "Routes with less than or equal to this amount of work are considered lightly loaded routes.",
          editable: true
        },
    {
          id: 'RTE_RIDESHARING_WORK_TIME',
          type: 'number',
          label: 'Rte Ridesharing Work Time',
          valuePath: 'RTE_RIDESHARING_WORK_TIME',
          description: "Routes with less than or equal to this amount of work will be removed when optimizing ridesharing.",
          editable: true
        },
    {
          id: 'SET_SAME_ADDR_SAME_ETA',
          type: 'number',
          label: 'Set Same Addr Same Eta',
          valuePath: 'SET_SAME_ADDR_SAME_ETA',
          description: "If turned on, stops at the same address are scheduled with the same ETA, if possible within the service window constraints.",
          editable: true
        },
    {
          id: 'SET_TPRO_SAMEDAY_PICKEARLY',
          type: 'number',
          label: 'Set Tpro Sameday Pickearly',
          valuePath: 'SET_TPRO_SAMEDAY_PICKEARLY',
          description: "Alternate pick early value for same day scheduling",
          editable: true
        },
    {
          id: 'SET_TPRO_SAMEDAY_PICKLATE',
          type: 'number',
          label: 'Set Tpro Sameday Picklate',
          valuePath: 'SET_TPRO_SAMEDAY_PICKLATE',
          description: "Alternate pick late value for same day scheduling",
          editable: true
        },
    {
          id: 'SET_TPRO_SAMEDAY_PICKWIN',
          type: 'number',
          label: 'Set Tpro Sameday Pickwin',
          valuePath: 'SET_TPRO_SAMEDAY_PICKWIN',
          description: "If turned on, use alternate pick early/late values for same day scheduling",
          editable: true
        },
    {
          id: 'SET_TPRO_TO_ETA',
          type: 'number',
          label: 'Set Tpro To Eta',
          valuePath: 'SET_TPRO_TO_ETA',
          description: "If turned on, several effects occur: 1) all subscriptions are treated like demand trips, 2) promise times are ignored for most reoptimizations, and 3) maximum promise times are not set.",
          editable: true
        },
    {
          id: 'SET_TPRO_TO_TREQ',
          type: 'number',
          label: 'Set Tpro To Treq',
          valuePath: 'SET_TPRO_TO_TREQ',
          description: "If turned on, the promise time for a pick anchored trip is set to the request time",
          editable: true
        },
    {
          id: 'SET_TPRO_WL',
          type: 'number',
          label: 'Set Tpro Wl',
          valuePath: 'SET_TPRO_WL',
          description: "If turned on, set the promise time for all trips waitlisted during a batch or insertion.",
          editable: true
        },
    {
          id: 'SET_TPRO_WL_APPTADDER',
          type: 'number',
          label: 'Set Tpro Wl Apptadder',
          valuePath: 'SET_TPRO_WL_APPTADDER',
          description: "Extra rideshare time allowed for drop anchored trips when the promise time is set for a waitlisted trip",
          editable: true
        },
    {
          id: 'SHAREDRIDE_FORMULA',
          type: 'number',
          label: 'Sharedride Formula',
          valuePath: 'SHAREDRIDE_FORMULA',
          description: "Controls the formula to use when calculating shared ride merit.",
          editable: true
        },
    {
          id: 'SHAREDRIDE_MINMERIT',
          type: 'number',
          label: 'Sharedride Minmerit',
          valuePath: 'SHAREDRIDE_MINMERIT',
          description: "Minimum merit percentage required for a trip to be piggy-backed with another trip.",
          editable: true
        },
    {
          id: 'SHAREDRIDE_MINMILE',
          type: 'number',
          label: 'Sharedride Minmile',
          valuePath: 'SHAREDRIDE_MINMILE',
          description: "Minimum direct distance required before a trip is considered a host trip.",
          editable: true
        },
    {
          id: 'SHORT_TRIP_MAX_TIME',
          type: 'number',
          label: 'Short Trip Max Time',
          valuePath: 'SHORT_TRIP_MAX_TIME',
          description: "This controls how the scheduling engine classifies trips as short trips.  Trips with times under this value are classified as short trips.",
          editable: true
        },
    {
          id: 'SITE_USING_GPS_UPDATE',
          type: 'number',
          label: 'Site Using Gps Update',
          valuePath: 'SITE_USING_GPS_UPDATE',
          description: "Enables/disables GPS update functionality. The parameter also controls which method to use when determining the ETA of the first unperformed non-break stop following a break stop when a GPS update is received while on break or just before starting a break on a route.",
          editable: true
        },
    {
          id: 'SKIP_SCHEDULING',
          type: 'number',
          label: 'Skip Scheduling',
          valuePath: 'SKIP_SCHEDULING',
          description: "If turned on, the scheduling engine will generate metrics but will not schedule trips.",
          editable: true
        },
    {
          id: 'SNAPSHOT_PERM_FREQ',
          type: 'number',
          label: 'Snapshot Perm Freq',
          valuePath: 'SNAPSHOT_PERM_FREQ',
          description: "The frequency with which the engine permanently saves it's state and inputs to the history (e.g. every 2500 engine calls the engine copies the contents of mt folder to the history subfolder)",
          editable: true
        },
    {
          id: 'SNAPSHOT_TAIL_FREQ',
          type: 'number',
          label: 'Snapshot Tail Freq',
          valuePath: 'SNAPSHOT_TAIL_FREQ',
          description: "The frequency with which the engine saves a sliding window of it's state and inputs to the history folder (e.g. every 100 engine calls the engine copies all of the files in the mt folder into the history folder)",
          editable: true
        },
    {
          id: 'SNAPSHOT_TAIL_LEN',
          type: 'number',
          label: 'Snapshot Tail Len',
          valuePath: 'SNAPSHOT_TAIL_LEN',
          description: "The size of the sliding window of engine state and inputs (e.g. the engine only keeps the previous 2 sliding window histories)",
          editable: true
        },
    {
          id: 'SPEEDING_MIN_DIST',
          type: 'number',
          label: 'Speeding Min Dist',
          valuePath: 'SPEEDING_MIN_DIST',
          description: "The minimum distance a manual trip must travel before it will be checked for speeding.",
          editable: true
        },
    {
          id: 'SPEEDING_MIN_FACTOR',
          type: 'number',
          label: 'Speeding Min Factor',
          valuePath: 'SPEEDING_MIN_FACTOR',
          description: "The minimum ratio required between a manual trip's speed and the speed returned by Travel Oracle for the trip to considered speeding.",
          editable: true
        },
    {
          id: 'STRATEGY',
          type: 'text',
          label: 'Strategy',
          valuePath: 'STRATEGY',
          description: "The name of the strategy to use for scheduling.",
          editable: true
        },
    {
          id: 'STRATEGY_WEEKEND',
          type: 'text',
          label: 'Strategy Weekend',
          valuePath: 'STRATEGY_WEEKEND',
          description: "The name of the strategy to use for scheduling on the weekend",
          editable: true
        },
    {
          id: 'SUB_OPT_LOG',
          type: 'number',
          label: 'Sub Opt Log',
          valuePath: 'SUB_OPT_LOG',
          description: "If turned on together with the SUB_OPT_MODEL parameter, the scheduling engine prints subscription optimization logs.",
          editable: true
        },
    {
          id: 'SUB_OPT_MIN_SCORE',
          type: 'number',
          label: 'Sub Opt Min Score',
          valuePath: 'SUB_OPT_MIN_SCORE',
          description: "This parameter is used in the subscription optimizer mode (see SUB_OPT_MODEL) to fine tune the condition to determine whether a trip is a singleton trip within a ride-sharing block of trips on a route.  The threshold score, which is the ride-sharing total score multiplied by this parameter value is the cut-off value used to classify a singleton trip.  For example, if the a trip’s ride share score is smaller than the threshold score, it is considered as singleton and shall be removed from its ride-sharing block.",
          editable: true
        },
    {
          id: 'SUB_OPT_MODEL',
          type: 'number',
          label: 'Sub Opt Model',
          valuePath: 'SUB_OPT_MODEL',
          description: "If turned on, the scheduling engine works in the subscription optimizer mode.",
          editable: true
        },
    {
          id: 'SUBSCRIPTIONS_FIRST',
          type: 'number',
          label: 'Subscriptions First',
          valuePath: 'SUBSCRIPTIONS_FIRST',
          description: "If turned on, the built in strategies will attempt to schedule subscription trips before demand trips.",
          editable: true
        },
    {
          id: 'TADJUST_BY_DIST',
          type: 'number',
          label: 'Tadjust By Dist',
          valuePath: 'TADJUST_BY_DIST',
          description: "If turned on, the scheduling engine interprets the first two columns of 1t_adjust.asc as trip length in miles and the third column as a percentage that adjusts the nominal Travel Oracle speed for the region and time.  If turned off, the scheduling engine interprets the first two columns of 1t_adjust.asc as trip length in minutes and the third column as the number of minutes to add to the ride time.",
          editable: true
        },
    {
          id: 'TO_MANIFEST_ON_PIN',
          type: 'number',
          label: 'To Manifest On Pin',
          valuePath: 'TO_MANIFEST_ON_PIN',
          description: "If turned on, the times given by Travel Oracle for a manual trip are used during a re-ETA of the manifest, regardless of user specified pick and drop times.",
          editable: true
        },
    {
          id: 'TOTYPE',
          type: 'text',
          label: 'Totype',
          valuePath: 'TOTYPE',
          description: "This parameter is used to select Travel Oracle type for used by the engine.  If absent, the Travel Oracle type is set to RECTILINEAR by default.",
          editable: true
        },
    {
          id: 'TRIM_SAME_ADDR_DWELL',
          type: 'number',
          label: 'Trim Same Addr Dwell',
          valuePath: 'TRIM_SAME_ADDR_DWELL',
          description: "If turned on, the scheduling engine will reduce the dwell time when multiple picks and/or drops occur at the same address",
          editable: true
        },
    {
          id: 'TRIP_REC_INCLUDES_CID',
          type: 'number',
          label: 'Trip Rec Includes Cid',
          valuePath: 'TRIP_REC_INCLUDES_CID',
          description: "If turned on, the engine will report the client ID number in certain files (such as trips.asc)",
          editable: true
        },
    {
          id: 'TRIPTRAN_EXTEND_SHIFT',
          type: 'number',
          label: 'Triptran Extend Shift',
          valuePath: 'TRIPTRAN_EXTEND_SHIFT',
          description: "If turned on, the scheduling engine will not allow trip transfers that cause shifts to be extended",
          editable: true
        },
    {
          id: 'USE_MAXRIDE_WIN',
          type: 'number',
          label: 'Use Maxride Win',
          valuePath: 'USE_MAXRIDE_WIN',
          description: "If turned on, the scheduling engine will use the maximum ride time when computing the trip pick and drop windows.",
          editable: true
        },
    {
          id: 'VERBOSE_LOG',
          type: 'number',
          label: 'Verbose Log',
          valuePath: 'VERBOSE_LOG',
          description: "This switch controls how much information the scheduling engine stores in the log files.",
          editable: true
        },
       ]
    }*/
    ]
  };
  _exports.default = _default;
});