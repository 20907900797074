define("adept-iq/models/cs-config-item", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // these determine the `id` when persisted
    category: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    unit: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    value: (0, _attr.default)('object'),
    defaultValue: (0, _attr.default)('object'),
    configCategory: (0, _relationships.belongsTo)('cs-config-category'),
    valueString: Ember.computed('value', function () {
      return JSON.stringify(this.get('value'));
    })
  });

  _exports.default = _default;
});