define("adept-iq/pods/components/form-components/button-model/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KeWfW7/p",
    "block": "{\"symbols\":[],\"statements\":[[0,\" \"],[7,\"table\"],[11,\"class\",\"mt-3 places-table\"],[9],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"td\"],[9],[0,\"\\n            \"],[7,\"button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"populateDataList\",[23,[\"record\"]],[23,[\"valuePath\"]],[23,[\"field\",\"modelName\"]]],null]],[12,\"disabled\",[21,\"isButtonEnabled\"]],[12,\"accesskey\",[21,\"accessKey\"]],[12,\"title\",[21,\"label\"]],[11,\"class\",\"multitype-button btn btn-outline-secondary\"],[9],[0,\"\\n              \"],[7,\"i\"],[11,\"class\",\"fa fa-home\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"editmodalcontext\",\"showWidgetModal\"]],true],null]],null,{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"editmodalcontext\",\"modelWidgetPath\"]],\"fixed-price-trip-widget\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"style\",\"position: fixed;\\tz-index: 1000;height: 50px;width: 50%;\"],[9],[0,\"  \\n        \"],[1,[27,\"iq-widgets/fixed-price-trip-widget\",null,[[\"widgetModelData\",\"currentRecord\",\"showWidgetModal\"],[[23,[\"widgetModelData\"]],[23,[\"currentRecord\"]],[23,[\"editmodalcontext\",\"showWidgetModal\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/button-model/template.hbs"
    }
  });

  _exports.default = _default;
});