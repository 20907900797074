define("adept-iq/pods/components/iq-widgets/trips-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/trips-widget',
    rowComponent: 'table/rows/status-formatted-row',
    modelName: 'iq-trip',
    defaultSortId: 'id',
    defaultSortAsc: false,
    accessKey: '5',
    addAccessKey: 'Shift++',
    title: 'Trips',
    columns: [{
      id: 'id',
      type: 'uuid',
      label: 'Trip ID',
      valuePath: 'id',
      valuePreview: '1',
      hidden: false,
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 60,
      isMapMCGLabelVisible: true
    }, {
      id: 'source',
      type: 'text',
      label: 'Trip Origin',
      valuePath: 'trip.tripType',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'Type',
      type: 'text',
      label: 'Trip Type',
      valuePath: 'trip.bookingType',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'requestTime',
      type: 'time',
      label: 'Pickup Time',
      valuePath: 'trip.requestedTime',
      searchable: true,
      valuePreview: '08:00 AM',
      editable: false,
      hidden: false,
      format: 'HH:mm A DD-MM-YYYY',
      defaultWidth: 50
    }, {
      id: 'address',
      type: 'text',
      label: 'Address',
      isMapLabelVisible: true,
      valuePath: 'trip.pick.place.streetAddress',
      editable: false,
      searchable: true,
      hidden: false,
      defaultWidth: 100
    }, {
      id: 'zone',
      type: 'text',
      label: 'Zone',
      isMapLabelVisible: true,
      valuePath: 'trip.zone.name',
      editable: false,
      searchable: true,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'attributes',
      type: 'text',
      label: 'Attributes',
      valuePath: 'trip.attributes',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'paymentType',
      type: 'text',
      label: 'Payment',
      valuePath: 'trip.paymentType',
      searchable: true,
      valuePreview: 'Single',
      editable: false,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'status',
      type: 'text',
      label: 'Trip Status',
      valuePath: 'status',
      searchable: true,
      highlightable: true,
      valuePreview: 'Planned',
      hidden: false,
      isMapLabelVisible: true,
      defaultWidth: 75,
      isMapMCGLabel: true
    }, {
      id: 'riderName',
      type: 'text',
      label: 'Passenger Name',
      valuePath: 'trip.pick.passengerName',
      hidden: false,
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'riderPhone',
      type: 'text',
      label: 'Phone Number',
      valuePath: 'trip.rider.phoneNr',
      hidden: false,
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'anchorETA',
      type: 'time',
      label: 'ETA',
      valuePath: 'trip.dsanchorStop.eta',
      searchable: true,
      valuePreview: '16',
      hidden: true,
      format: 'HH:mm A',
      defaultWidth: 50
    }, {
      id: 'acctCode',
      type: 'text',
      label: 'Account Code',
      valuePath: 'trip.account.acctCode',
      searchable: true,
      hidden: false,
      isMapLabelVisible: true,
      defaultWidth: 75
    }, {
      id: 'acctCardNr',
      type: 'text',
      label: 'Account Card Nr',
      valuePath: 'trip.account.acctCardNr',
      searchable: true,
      hidden: false,
      isMapLabelVisible: true,
      defaultWidth: 75
    }, {
      id: 'fare',
      type: 'text',
      label: 'Fare',
      valuePath: 'trip.fare',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'jobpriority',
      type: 'text',
      label: 'priority',
      valuePath: 'trip.jobpriority',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'bookingType',
      type: 'text',
      label: 'Type',
      valuePath: 'trip.bookingType',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'dispatchType',
      type: 'text',
      label: 'Dispatch Method',
      valuePath: 'trip.dispatchMethod',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'driverId',
      type: 'text',
      label: 'Badge Nr',
      index: 4,
      valuePath: 'driver.badgeNr',
      editable: false,
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 45
    }, {
      id: 'Callsign',
      type: 'text',
      label: 'Callsign',
      valuePath: 'vehicle.callsign',
      searchable: true,
      cellDesc: 'Cab#',
      editable: false,
      hidden: false
    }, {
      id: 'notes',
      type: 'text',
      label: 'Driver Notes',
      valuePath: 'trip.stop.driverNotes',
      editable: false,
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'notes',
      type: 'text',
      label: 'Operator Notes',
      valuePath: 'trip.stop.operatorNotes',
      editable: false,
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'cancelReason',
      type: 'text',
      label: 'Cancel Reason',
      valuePath: 'trip.cancelReason',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'noShowReasonCode',
      type: 'text',
      label: 'No Show Reason Code',
      valuePath: 'trip.noShowReasonCode',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 60
    }]
  };
  _exports.default = _default;
});