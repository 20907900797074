define("adept-iq/config/widget-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.widgetTypesHash = _exports.reportWidgetType4 = _exports.reportWidgetType3 = _exports.reportWidgetType2 = _exports.reportWidgetType1 = _exports.reportWidgetType = _exports.favoritePickupsWidgetType = _exports.riderProfileWidgetType = _exports.poiAddressSearchWidgeType = _exports.fixedPriceTripsWidgetType = _exports.cliWidgetType = _exports.usersWidgetType = _exports.alertsWidgetType = _exports.box4WidgetType = _exports.box3WidgetType = _exports.box2WidgetType = _exports.box1WidgetType = _exports.gaugeWidgetType = _exports.barChartWidgetType = _exports.donutChartWidgetType = _exports.metricsWidgetType = _exports.boxWidgetType = _exports.vehiclesWidgetType = _exports.driversWidgetType = _exports.tripWidgetType = _exports.standWidgetType = _exports.zoneWidgetType = _exports.accountsWidgetType = _exports.passengersWidgetType = _exports.subscriptionsWidgetType = _exports.mapWidgetType = _exports.tripsWidgetType = _exports.stopsWidgetType = _exports.routesWidgetType = _exports.settingsWidgetType = _exports.messagesWidgetType = void 0;
  // this file is for "public" widget config required for listing available
  // widget types and computing widget placements; most other config should be
  // placed in the `config.js` adjacent to the specific widgets' components
  // `name` reflects how the widget appears in a list (e.g. add-widget sidebar).
  // For widget title bar, set `title` in widget's `config.js`.
  // this would be a good place to specify icons for add-widget sidebar
  const messagesWidgetType = {
    id: 'messages',
    name: 'Messages',
    componentName: 'iq-widgets/message-widget',
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.messagesWidgetType = messagesWidgetType;
  const settingsWidgetType = {
    id: 'settings',
    name: 'System',
    componentName: 'iq-widgets/edit-settings-widget',
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.settingsWidgetType = settingsWidgetType;
  const routesWidgetType = {
    id: 'routes',
    name: 'Routes',
    componentName: 'iq-widgets/routes-widget',
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.routesWidgetType = routesWidgetType;
  const stopsWidgetType = {
    id: 'stops',
    name: 'Stops',
    componentName: 'iq-widgets/stops-widget',
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.stopsWidgetType = stopsWidgetType;
  const tripsWidgetType = {
    id: 'trips',
    name: 'Trips',
    componentName: 'iq-widgets/trips-widget',
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.tripsWidgetType = tripsWidgetType;
  const mapWidgetType = {
    id: 'map',
    name: 'Map',
    componentName: 'iq-widgets/map-widget',
    defaultWidth: 3,
    defaultHeight: 3,
    minWidth: 2,
    minHeight: 2
  };
  _exports.mapWidgetType = mapWidgetType;
  const subscriptionsWidgetType = {
    id: 'subscriptions',
    name: 'Subscriptions',
    componentName: 'iq-widgets/subscriptions-widget',
    defaultWidth: 4,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.subscriptionsWidgetType = subscriptionsWidgetType;
  const passengersWidgetType = {
    id: 'passengers',
    name: 'Passengers',
    componentName: 'iq-widgets/passengers-widget',
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.passengersWidgetType = passengersWidgetType;
  const accountsWidgetType = {
    id: 'accounts',
    name: 'Accounts',
    componentName: 'iq-widgets/accounts-widget',
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.accountsWidgetType = accountsWidgetType;
  const zoneWidgetType = {
    id: 'zone',
    name: 'Zones',
    componentName: 'iq-widgets/zones-widget',
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.zoneWidgetType = zoneWidgetType;
  const standWidgetType = {
    id: 'stand',
    name: 'Stands',
    componentName: 'iq-widgets/stands-widget',
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.standWidgetType = standWidgetType;
  const tripWidgetType = {
    id: 'trip',
    name: 'Trip',
    componentName: 'iq-widgets/new-trip-fixform-widget',
    defaultWidth: 2,
    defaultHeight: 8,
    minWidth: 2,
    minHeight: 8
  };
  _exports.tripWidgetType = tripWidgetType;
  const driversWidgetType = {
    id: 'drivers',
    name: 'Drivers',
    componentName: 'iq-widgets/drivers-widget',
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.driversWidgetType = driversWidgetType;
  const vehiclesWidgetType = {
    id: 'vehicles',
    name: 'Vehicles',
    componentName: 'iq-widgets/vehicles-widget',
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.vehiclesWidgetType = vehiclesWidgetType;
  const boxWidgetType = {
    id: 'box',
    name: 'Box',
    componentName: 'iq-widgets/box-widget',
    defaultWidth: 1,
    defaultHeight: 2,
    minWidth: 1,
    minHeight: 1
  };
  _exports.boxWidgetType = boxWidgetType;
  const metricsWidgetType = {
    id: 'metrics',
    name: 'Metrics',
    componentName: 'iq-widgets/metrics-column-widget',
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.metricsWidgetType = metricsWidgetType;
  const donutChartWidgetType = {
    id: 'donut-chart',
    name: 'Donut Chart',
    componentName: 'iq-widgets/donut-chart-widget',
    defaultWidth: 3,
    defaultHeight: 2,
    minWidth: 3,
    minHeight: 3
  };
  _exports.donutChartWidgetType = donutChartWidgetType;
  const barChartWidgetType = {
    id: 'bar-chart',
    name: 'Bar Chart',
    componentName: 'iq-widgets/bar-chart-widget',
    defaultWidth: 4,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 3
  };
  _exports.barChartWidgetType = barChartWidgetType;
  const gaugeWidgetType = {
    id: 'gauge',
    name: 'Gauge',
    componentName: 'iq-widgets/gauge-widget',
    defaultWidth: 3,
    defaultHeight: 2,
    minWidth: 3,
    minHeight: 2
  };
  _exports.gaugeWidgetType = gaugeWidgetType;
  const box1WidgetType = {
    id: 'box-1',
    name: 'Box 1',
    componentName: 'iq-widgets/box-widget',
    defaultWidth: 1,
    defaultHeight: 2,
    minWidth: 1,
    minHeight: 1
  };
  _exports.box1WidgetType = box1WidgetType;
  const box2WidgetType = {
    id: 'box-2',
    name: 'Box 2',
    componentName: 'iq-widgets/box-widget',
    defaultWidth: 1,
    defaultHeight: 2,
    minWidth: 1,
    minHeight: 1
  };
  _exports.box2WidgetType = box2WidgetType;
  const box3WidgetType = {
    id: 'box-3',
    name: 'Box 3',
    componentName: 'iq-widgets/box-widget',
    defaultWidth: 1,
    defaultHeight: 2,
    minWidth: 1,
    minHeight: 1
  };
  _exports.box3WidgetType = box3WidgetType;
  const box4WidgetType = {
    id: 'box-4',
    name: 'Box 4',
    componentName: 'iq-widgets/box-widget',
    defaultWidth: 1,
    defaultHeight: 2,
    minWidth: 1,
    minHeight: 1
  };
  _exports.box4WidgetType = box4WidgetType;
  const alertsWidgetType = {
    id: 'alerts',
    name: 'Alerts',
    componentName: 'iq-widgets/alert-widget',
    defaultWidth: 1,
    defaultHeight: 2,
    minWidth: 1,
    minHeight: 1
  };
  _exports.alertsWidgetType = alertsWidgetType;
  const usersWidgetType = {
    id: 'users',
    name: 'Users',
    componentName: 'iq-widgets/users-widget',
    defaultWidth: 1,
    defaultHeight: 2,
    minWidth: 1,
    minHeight: 1
  };
  _exports.usersWidgetType = usersWidgetType;
  const cliWidgetType = {
    id: 'cli',
    name: 'CLI',
    componentName: 'iq-widgets/cli-widget',
    defaultWidth: 3,
    defaultHeight: 6,
    minWidth: 3,
    minHeight: 6
  };
  _exports.cliWidgetType = cliWidgetType;
  const fixedPriceTripsWidgetType = {
    id: 'fixedPrice',
    name: 'fixedPrice',
    componentName: 'iq-widgets/fixed-price-trip-widget',
    defaultWidth: 4,
    defaultHeight: 6,
    minWidth: 4,
    minHeight: 6
  };
  _exports.fixedPriceTripsWidgetType = fixedPriceTripsWidgetType;
  const poiAddressSearchWidgeType = {
    id: 'poiAddressSearch',
    name: 'poiAddressSearch',
    componentName: 'iq-widgets/address-lookup',
    defaultWidth: 4,
    defaultHeight: 6,
    minWidth: 4,
    minHeight: 6
  };
  _exports.poiAddressSearchWidgeType = poiAddressSearchWidgeType;
  const riderProfileWidgetType = {
    id: 'riderProfile',
    name: 'riderProfile',
    componentName: 'iq-widgets/rider-profile-widget',
    defaultWidth: 4,
    defaultHeight: 6,
    minWidth: 4,
    minHeight: 6
  };
  _exports.riderProfileWidgetType = riderProfileWidgetType;
  const favoritePickupsWidgetType = {
    id: 'favoritePickup',
    name: 'favoritePickup',
    componentName: 'iq-widgets/favorite-pickup-widget',
    defaultWidth: 4,
    defaultHeight: 6,
    minWidth: 4,
    minHeight: 6
  };
  _exports.favoritePickupsWidgetType = favoritePickupsWidgetType;
  const reportWidgetType = {
    id: 'report',
    name: 'report',
    componentName: 'iq-widgets/report-widget',
    defaultWidth: 4,
    defaultHeight: 4,
    minWidth: 4,
    minHeight: 4
  };
  _exports.reportWidgetType = reportWidgetType;
  const reportWidgetType1 = {
    id: 'report1',
    name: 'report1',
    componentName: 'iq-widgets/report-widget1',
    defaultWidth: 4,
    defaultHeight: 4,
    minWidth: 4,
    minHeight: 4
  };
  _exports.reportWidgetType1 = reportWidgetType1;
  const reportWidgetType2 = {
    id: 'report2',
    name: 'report2',
    componentName: 'iq-widgets/report-widget2',
    defaultWidth: 4,
    defaultHeight: 4,
    minWidth: 4,
    minHeight: 4
  };
  _exports.reportWidgetType2 = reportWidgetType2;
  const reportWidgetType3 = {
    id: 'report3',
    name: 'report3',
    componentName: 'iq-widgets/report-widget3',
    defaultWidth: 4,
    defaultHeight: 4,
    minWidth: 4,
    minHeight: 4
  };
  _exports.reportWidgetType3 = reportWidgetType3;
  const reportWidgetType4 = {
    id: 'report4',
    name: 'report4',
    componentName: 'iq-widgets/report-widget4',
    defaultWidth: 8,
    defaultHeight: 8,
    minWidth: 6,
    minHeight: 6
  };
  _exports.reportWidgetType4 = reportWidgetType4;
  const widgetTypes = [cliWidgetType, settingsWidgetType, routesWidgetType, stopsWidgetType, tripsWidgetType, mapWidgetType, passengersWidgetType, driversWidgetType, vehiclesWidgetType, tripWidgetType, boxWidgetType, metricsWidgetType, donutChartWidgetType, barChartWidgetType, gaugeWidgetType, box1WidgetType, box2WidgetType, box3WidgetType, box4WidgetType, alertsWidgetType, usersWidgetType, subscriptionsWidgetType, fixedPriceTripsWidgetType, favoritePickupsWidgetType, poiAddressSearchWidgeType, accountsWidgetType, zoneWidgetType, standWidgetType, messagesWidgetType, reportWidgetType, reportWidgetType1, reportWidgetType2, reportWidgetType3, reportWidgetType4];
  const widgetTypesHash = widgetTypes.reduce((obj, widgetType) => {
    obj[widgetType.id] = widgetType;
    return obj;
  }, {});
  _exports.widgetTypesHash = widgetTypesHash;
  var _default = widgetTypes;
  _exports.default = _default;
});