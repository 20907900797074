define("adept-iq/pods/components/iq-widgets/stands-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/stands-widget/config", "adept-iq/config/environment", "lodash"], function (_exports, _component, _config, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['stands-widget'],
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    trip: Ember.inject.service(),
    ajax: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    session: Ember.inject.service(),
    config: _config.default,
    defaultIncludes: INCLUDES,

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({});
      this.set('singleActions', [{
        name: 'Activity Log',
        action: () =>
        /* models */
        {}
      }]);
      this.set('bulkActions', [{
        name: 'Activity Log',
        action: () =>
        /* models */
        {}
      }]);
    }

  });

  _exports.default = _default;
});