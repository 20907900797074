define("adept-iq/services/animation-timer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FRAMES_PER_SECOND = 30;

  var _default = Ember.Service.extend(Ember.Evented, {
    fps: FRAMES_PER_SECOND,

    init() {
      this._super(...arguments);

      setInterval(() => {
        Ember.run.scheduleOnce('afterRender', this, 'tick');
      }, 1000 / FRAMES_PER_SECOND);
    },

    tick() {
      this.trigger('tick');
    }

  });

  _exports.default = _default;
});