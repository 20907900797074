define("adept-iq/models/dashboard", ["exports", "ember-data", "adept-iq/classes/dashboard-instance", "lodash", "moment"], function (_exports, _emberData, _dashboardInstance, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  const CLONEABLE_PROPERTIES = ['name', 'role', 'startDate', 'endDate', 'referenceDate', 'mapState', 'widgets'];

  var _default = Model.extend({
    name: attr('string', {
      defaultValue: 'Untitled'
    }),
    role: attr('string', {
      defaultValue: 'Dispatch'
    }),
    // 'workspaces-{users,default,admin}'; can't be changed once saved
    category: attr('string', {
      defaultValue: 'workspaces-users'
    }),
    // absolute timeframe as selected by user
    startDate: attr('date', {
      defaultValue: () => (0, _moment.default)().startOf('day').subtract(1, 'day').toDate() //moment(Date.now()).subtract(3, 'hours')

    }),
    endDate: attr('date', {
      defaultValue: () => (0, _moment.default)().startOf('day').toDate()
    }),
    // when `startDate` or `endDate` is modified, store the user's current date
    // so that the relative timeframe can be calculated from a later date
    referenceDate: attr('date', {
      defaultValue: () => (0, _moment.default)(Date.now()).subtract(3, 'hours')
    }),
    mapState: attr('object', {
      defaultValue: () => {}
    }),
    widgets: attr('object', {
      defaultValue: () => []
    }),
    isDefaultWorkspace: Ember.computed.equal('category', 'workspaces-users'),

    configureWidgetsOnPermission() {
      const configPermissions = this.store.peekAll('cs-config-permission').get('firstObject.value');
      const widgets = [];
      this.get('widgets').forEach(widget => {
        let configPermission;

        if (!Ember.isNone(configPermissions)) {
          configPermission = configPermissions[widget.typeId];
        } //if (!isNone(configPermission) && configPermission.show) {


        widgets.push(widget); //}
      });
      return widgets;
    },

    makeInstance() {
      const widgets = this.configureWidgetsOnPermission();

      const instance = _dashboardInstance.default.deserialize({
        schemaVersion: 1,
        attributes: {
          id: this.get('id'),
          name: this.get('name'),
          role: this.get('role'),
          startDate: this.get('startDate'),
          endDate: this.get('endDate'),
          referenceDate: this.get('referenceDate'),
          mapState: this.get('mapState'),
          widgets: widgets
        }
      });

      instance.set('model', this);
      return instance;
    },

    commitInstance(instance) {
      const {
        attributes
      } = instance.serialize();
      const {
        name,
        role,
        startDate,
        endDate,
        referenceDate,
        mapState,
        widgets
      } = attributes;
      this.setProperties({
        name,
        role,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        referenceDate: new Date(referenceDate),
        mapState,
        widgets
      });
    },

    getOptionsForClone() {
      return _lodash.default.cloneDeep(this.getProperties(CLONEABLE_PROPERTIES));
    }

  });

  _exports.default = _default;
});