define("adept-iq/pods/components/header-nav/component", ["exports", "moment", "adept-iq/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editModal: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    scheduleGeneration: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    stopsReorder: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['row', 'no-gutters', 'header-wrapper', 'header-nav'],
    classNameBindings: ['isLightMode:is-light:is-dark', 'isReordering'],
    dashboards: null,
    transitionTo: null,
    configureWorkspaceAllowed: false,
    saveWorkspaceAllowed: false,
    saveAsWorkspaceAllowed: false,
    deleteWorkspaceAllowed: false,
    openWorkspaceAllowed: false,
    generateScheduleAllowed: false,
    closeScheduleAllowed: false,
    systemConfigurationAllowed: false,
    resetPasswordAllowed: false,
    logoutAllowed: true,
    isReordering: Ember.computed.alias('stopsReorder.reorderInProgress'),
    isEditing: Ember.computed.alias('workspace.isEditing'),
    isLightMode: Ember.computed.alias('workspace.isLightMode'),
    isAuthenticated: Ember.computed.alias('session.isAuthenticated'),
    abridgedDetail: Ember.computed('errorMessage.message', function () {
      return this.get('errorMessage').message.detail;
    }),
    errorMessageInfoContentChange: Ember.observer('errorMessage.info', function () {
      Ember.run.later(function () {
        if (Ember.$('.text-info-multi').length > 0) {
          Ember.$('.text-info-multi').height(Ember.$('.text-info-multi')[0].scrollHeight);
        }
      }, 500);
    }),
    configPermissionsChange: Ember.observer('workspace.configPermissions', function () {
      this.resetHeaderFunctions();
      this.configureHeaderPermission();
    }),
    oneDayTimelineNotFuture: Ember.computed('workspace.{startDate,endDate}', function () {
      const startDate = (0, _moment.default)(this.get('workspace.startDate'));
      const endDate = (0, _moment.default)(this.get('workspace.endDate'));
      const currentDate = (0, _moment.default)().startOf('day');

      if (startDate.format('YYYY-MMM-D') !== endDate.format('YYYY-MMM-D')) {
        return false;
      }

      if (endDate.startOf('day').isAfter(currentDate)) {
        return false;
      }

      const routes = this.get('activeContext.workspaceData.route'); // Only 1 date is selected by this point
      // make sure that the schedule is not locked already.

      if (routes.some(route => {
        return route.get('schedule.locked');
      })) {
        return false;
      }

      return true;
    }),

    init() {
      this._super(...arguments);

      this.configureHeaderPermission();
      this.set('fullMode', _environment.default.APP.fullMode);
    },

    resetHeaderFunctions() {
      this.set('configureWorkspaceAllowed', true);
      this.set('saveWorkspaceAllowed', true);
      this.set('saveAsWorkspaceAllowed', true);
      this.set('deleteWorkspaceAllowed', true);
      this.set('openWorkspaceAllowed', true);
      this.set('generateScheduleAllowed', true);
      this.set('closeScheduleAllowed', true);
      this.set('systemConfigurationAllowed', true);
      this.set('resetPasswordAllowed', true);
      this.set('logoutAllowed', true);
    },

    configureHeaderPermission() {
      const configPermissions = this.get('workspace.configPermissions');

      if (!Ember.isNone(configPermissions)) {
        const headerFunctions = configPermissions.headerFunctions;

        if (!Ember.isNone(headerFunctions)) {
          headerFunctions.forEach(headerFunction => {
            if (headerFunction === 'all') {
              this.set('configureWorkspaceAllowed', true);
              this.set('saveWorkspaceAllowed', true);
              this.set('saveAsWorkspaceAllowed', true);
              this.set('deleteWorkspaceAllowed', true);
              this.set('openWorkspaceAllowed', true);
              this.set('generateScheduleAllowed', true);
              this.set('closeScheduleAllowed', true);
              this.set('systemConfigurationAllowed', true);
              this.set('resetPasswordAllowed', true);
              this.set('logoutAllowed', true);
            } else {
              const allowedFunctionName = headerFunction + 'Allowed';
              this.set(allowedFunctionName, true);
            }
          });
        }
      }
    },

    actions: {
      onCloseReorder() {
        this.get('stopsReorder').stopReorder();
      },

      onEditWorkspaceClick(dropdown) {
        const workspace = this.get('workspace');
        dropdown.actions.close();
        if (this.get('isEditing')) return;
        const displayName = 'Add Widgets';
        workspace.pushState('editWorkspace', {
          displayName
        }).then(() => {// Q: state pushed?
        }).catch(() => {// denied access
        });
      },

      onSaveWorkspaceClick(dropdown) {
        dropdown.actions.close();
        const dashboardModel = this.get('workspace.dashboardModel'); //console.log('dashboardModel ',dashboardModel);

        if (dashboardModel.get('isDefaultWorkspace')) {
          const tooltip = this.get('tooltip');
          tooltip.setProperties({
            tip: 'Unable to Save. You must use the "Save Workspace As" option to save changes made to a default workspace.',
            primaryActionText: 'Ok',

            primaryAction() {
              tooltip.reset();
            }

          });
          return;
        }

        const dashboardInstance = this.get('workspace.dashboard'); //console.log('dashboardInstance ',dashboardInstance);

        dashboardModel.commitInstance(dashboardInstance);
        /*eslint-disable */

        return dashboardModel.save();
        /*eslint-enable */
      },

      onSaveWorkspaceAsClick(dropdown) {
        dropdown.actions.close();
        this.get('workspace').pushState('saveWorkspaceAs');
      },

      onDeleteWorkspaceClick(dropdown) {
        dropdown.actions.close();
        const dashboardModel = this.get('workspace.dashboardModel');

        if (dashboardModel.get('isDefaultWorkspace')) {
          const tooltip = this.get('tooltip');
          tooltip.setProperties({
            tip: 'Unable to delete. Default workspaces cannot be deleted.',
            primaryActionText: 'Ok',

            primaryAction() {
              tooltip.reset();
            }

          });
          return;
        }

        this.get('workspace').pushState('deleteWorkspace');
      },

      onOpenWorkspaceClick(dropdown) {
        dropdown.actions.close();
        this.get('workspace').pushState('openWorkspace');
      },

      toggleTimelinePicker() {
        const workspace = this.get('workspace');
        workspace.toggleProperty('isEditingTimeline');
      },

      onGenerateScheduleClick(dropdown) {
        dropdown.actions.close();
        this.get('scheduleGeneration.task').perform();
      },

      transitionToForgotPassword(dropdown, link) {
        dropdown.actions.close();
        this.transitionTo(link);
      },

      transitionToModalAndClose(dropdown, modal) {
        dropdown.actions.close();
        this.transitionTo(`index.modals.${modal}`);
      },

      toggleLayoutType(dropdown) {
        dropdown.actions.close();
        this.toggleProperty('isLightMode');
      },

      onEditApplyCancel() {
        this.get('editModal').cancel();
      },

      onEditApplyCommit() {
        this.get('editModal').commit();
      },

      onErrorDismiss() {
        this.get('errorMessage').shiftError();
      },

      onWarningDismiss() {
        this.get('errorMessage').shiftWarning();
      },

      onInfoDismiss() {
        this.get('errorMessage').shiftInfo();
      },

      onCloseScheduleClick(dropdown) {
        dropdown.actions.close();
        this.get('workspace').pushState('closeSchedule');
      },

      onSystemConfigurationClick(dropdown) {
        dropdown.actions.close();
        this.get('workspace').pushState('editSystemConfiguration', {
          displayName: 'System Configuration'
        });
      },

      onLogout() {
        this.onLogout();
      }

    }
  });

  _exports.default = _default;
});