define("adept-iq/models/tm-activity-log", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/address.js */
  var _default = _model.default.extend({
    tripId: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    comments: (0, _attr.default)('string'),
    actualTime: (0, _attr.default)('date'),
    callSign: (0, _attr.default)('string'),
    priority: (0, _attr.default)('string'),
    logBy: (0, _attr.default)('string')
  });

  _exports.default = _default;
});