define("adept-iq/pods/components/iq-widgets/vehicles-widget/mapconfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/vehicles-widget',
    rowComponent: 'table/rows/vehicle-status-formatted-row',
    modelName: 'vehicle',
    accessKey: '7',
    title: 'Taxis',
    defaultSortId: 'callSign',
    defaultSortAsc: true,
    columns: [{
      id: 'callSign',
      type: 'text',
      label: 'Callsign',
      valuePath: 'callsign',
      valuePreview: '2',
      index: 0,
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 45,
      isMapMCGLabelVisible: true
    }, {
      id: 'driverId',
      type: 'text',
      label: 'Badge Nr',
      index: 4,
      valuePath: 'driver.badgeNr',
      editable: false,
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 45
    }, {
      id: 'driverName',
      type: 'text',
      label: 'Driver Name',
      index: 5,
      valuePath: 'driver.name',
      isMapLabelVisible: true,
      searchable: true,
      editable: true,
      defaultWidth: 60
    }, {
      id: 'zone',
      type: 'text',
      label: 'Zone',
      valuePath: 'zone.name',
      searchable: true,
      editable: false,
      defaultWidth: 45
    }, {
      id: 'zoneQueuePos',
      type: 'text',
      label: 'Zone QPos',
      valuePath: 'zoneQueuePos',
      isMapLabelVisible: true,
      searchable: true,
      editable: false,
      defaultWidth: 45
    }, {
      id: 'stand',
      type: 'text',
      label: 'Stand',
      valuePath: 'stand.name',
      searchable: true,
      editable: false,
      defaultWidth: 45
    }, {
      id: 'standQueuePosition',
      type: 'text',
      label: 'Stand QPos',
      valuePath: 'standQueuePos',
      isMapLabelVisible: true,
      searchable: true,
      editable: false,
      defaultWidth: 45
    }, {
      id: 'status',
      type: 'text',
      label: 'Status',
      valuePath: 'drivedStatus',
      index: 2,
      highlightable: true,
      isMapLabelVisible: true,
      searchable: true,
      editable: false,
      defaultWidth: 45,
      hidden: false
    }, {
      id: 'mdtid',
      type: 'text',
      label: 'MDT ID',
      valuePath: 'hardwareId',
      searchable: true,
      editable: false,
      defaultWidth: 45,
      hidden: true
    }, {
      id: 'model',
      type: 'text',
      label: 'Model',
      valuePath: 'vehicleModel',
      searchable: true,
      editable: false,
      defaultWidth: 45,
      hidden: true
    }, {
      id: 'make',
      type: 'text',
      label: 'Make',
      valuePath: 'make',
      searchable: true,
      editable: false,
      defaultWidth: 45,
      hidden: true
    }, {
      id: 'ETA',
      type: 'text',
      label: 'ETA',
      valuePath: 'ETA',
      searchable: true,
      editable: false,
      defaultWidth: 45,
      hidden: true
    }, {
      id: 'attributes',
      type: 'text',
      label: 'Attributes',
      index: 3,
      valuePath: 'attributesList',
      isMapLabelVisible: true,
      searchable: true,
      editable: false,
      defaultWidth: 100,
      hidden: true
    }, {
      id: 'suspensionFlag',
      type: 'boolean',
      label: 'Suspended',
      valuePath: 'suspensionFlag',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'GPS',
      type: 'text',
      label: 'GPS',
      valuePath: 'GPS',
      searchable: true,
      isMapLabelVisible: true,
      editable: false,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'Lat',
      type: 'text',
      label: 'Lat',
      valuePath: 'lat',
      searchable: true,
      isMapLabelVisible: true,
      editable: false,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'Lng',
      type: 'text',
      label: 'Lng',
      valuePath: 'lng',
      searchable: true,
      isMapLabelVisible: true,
      editable: false,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'GPSTimestamp',
      type: 'datetime',
      label: 'Times',
      valuePath: 'timestamp',
      searchable: true,
      valuePreview: '09:00 AM',
      editable: false,
      format: 'YYYY-MM-DD hh:mm A',
      defaultWidth: 50,
      hidden: true
    }, {
      id: 'speed',
      type: 'number',
      label: 'Speed',
      valuePath: 'speed',
      searchable: true,
      editable: false,
      defaultWidth: 50,
      hidden: true
    }, {
      id: 'heading',
      type: 'number',
      label: 'Heading',
      valuePath: 'heading',
      searchable: true,
      editable: false,
      defaultWidth: 50,
      hidden: true
    }]
  };
  _exports.default = _default;
});