define("adept-iq/pods/dashboard/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    isEditing: Ember.computed.alias('workspace.isEditing'),
    tileSize: Ember.computed.alias('workspace.tileSize'),
    tileSpacing: Ember.computed.alias('workspace.tileSpacing'),
    dashboard: Ember.computed.alias('data.dashboard')
  });

  _exports.default = _default;
});