define("adept-iq/serializers/avl", ["exports", "adept-iq/serializers/application", "adept-iq/mixins/iq-model-identification"], function (_exports, _application, _iqModelIdentification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    iqModelName: 'vehicle',
    iqModelForeignKey: 'vehicleId',
    iqModelRelationship: 'iqVehicle'
  });

  _exports.default = _default;
});