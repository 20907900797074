define("adept-iq/classes/markers/vehicle", ["exports", "adept-iq/classes/markers/base", "adept-iq/pods/components/iq-widgets/map-widget/config/marker", "adept-iq/config/environment"], function (_exports, _base, _marker, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // average over (up to) the last N update timestamps
  const MAX_SMOOTHING_N = 10; // don't average until there are N timestamps

  const MIN_SMOOTHING_N = 5; // assume 1s vehicle updates off the bat

  const DEFAULT_INTERVAL_MS = 1000;
  const Z_INDEX_OFFSET = 1000;

  var _default = _base.default.extend({
    label: 'Vehicle',
    isActive: true,
    Z_INDEX_OFFSET: Z_INDEX_OFFSET,
    iqVehicle: Ember.computed.alias('record'),
    speed: Ember.computed.alias('iqVehicle.speed'),
    isEmergencyVehicle: Ember.computed.alias('iqVehicle.isEmergencyVehicle'),
    lastKnownCoord: Ember.computed.alias('iqVehicle.lastKnownCoord'),
    lat: Ember.computed('iqVehicle.lat', function () {
      const lat = this.get('iqVehicle.lat');

      const _lat = this.get('lastKnownCoord') ? this.get('lastKnownCoord').lat : null;

      if (Ember.isPresent(lat)) {
        return lat;
      } else if (Ember.isPresent(this.marker)) {
        // actual lat is null, retrieve the last known marker position
        return this.marker._latlng.lat;
      } else if (Ember.isPresent(_lat)) {
        return _lat;
      }

      return null;
    }),
    lng: Ember.computed('iqVehicle.lng', function () {
      const lng = this.get('iqVehicle.lng');
      this.get('style');

      const _lng = this.get('lastKnownCoord') ? this.get('lastKnownCoord').lng : null;

      if (Ember.isPresent(lng)) {
        return lng;
      } else if (Ember.isPresent(this.marker)) {
        // actual lng is null, retrieve the last known marker position
        return this.marker._latlng.lng;
      } else if (Ember.isPresent(_lng)) {
        return _lng;
      }

      return null;
    }),
    style: 'vehicle',
    // style of the marker determined by otp and or linked to an emergency message
    iconStyle: Ember.computed('iqVehicle.{lat,lng,isEmergencyVehicle}', function () {
      const otp = this.get('iqVehicle.otp');
      const lng = this.get('iqVehicle.lng');
      let style = 'vehicle';

      if (this.get('speed') && Ember.isPresent(lng)) {
        style += 'Driving';
      }

      if (_environment.default.APP.avlmLite) {
        return style + 'AVLM';
      }

      if (this.get('isEmergencyVehicle')) {
        style += 'Emergency';
      }

      switch (otp) {
        case 'D':
          style += 'Danger';
          return style;

        case 'L':
          style += 'Late';
          return style;

        default:
          return style;
      }
    }),

    init() {
      this._super(...arguments); // each vehicle marker subscribes to a central `animationTimer` service
      // that fires an event on animation ticks

    },

    destroy() {
      this._super(...arguments);
    },

    onAnimationTimerTick() {
      const iqVehicle = this.get('iqVehicle');

      if (!iqVehicle) {
        return;
      } // if the vehicle has no actual lat/lng, there is nothing we can do


      const actualLat = iqVehicle.get('lat');
      const actualLng = iqVehicle.get('lng');
      const heading = iqVehicle.get('heading');

      if (Ember.isNone(actualLat) || Ember.isNone(actualLng)) {
        this.set('lat', null);
        this.set('lng', null);
        return;
      } // if we don't have a cached marker location, snap marker to current coords


      const lat = this.get('_lat');
      const lng = this.get('_lng');

      if (Ember.isNone(lat) || Ember.isNone(lng)) {
        this.set('lat', actualLat);
        this.set('lng', actualLng);
        return;
      } // calculate average time between last n location updates


      const interval = this.computeAverageTimeWindow(iqVehicle); // calculate % of time that has elapsed before projected update

      const lastTime = iqVehicle.get('currentAVLLocation.localTimestamp').getTime();
      const now = new Date().getTime();
      const t = Math.min(1.0, (now - lastTime) / interval); // interpolate along vector from last cached marker location to known coords

      const diffLat = actualLat - lat;
      const diffLng = actualLng - lng;
      const newLat = lat + t * diffLat;
      const newLng = lng + t * diffLng;

      if (this.marker) {
        // NOTE: we intentionally do not update the `lat` and `lng` properties,
        // becuase this would trigger an expensive update at the map widget level
        const iconStyle = this.get('iconStyle'); // we know vehicle heading now, so switch to arrow icon
        // this.marker.setIcon(MARKERS.vehicleDriving.icon);

        this.marker.setIcon(_marker.default[iconStyle].icon); // make this vehicle sit on top of other markers (e.g. stops)

        this.marker.setZIndexOffset(Z_INDEX_OFFSET); // update marker position and angle directly

        this.marker.setLatLng([newLat, newLng]);
        let angle = heading;

        if (angle === 0) {
          angle = -45;
        } else {
          angle = angle / 2 - 45;
        } // console.log(angle);


        this.marker.setRotationAngle(angle);
      }
    },

    // calculates the (rolling) average time between location updates
    computeAverageTimeWindow(iqVehicle) {
      const locations = iqVehicle.get('sortedavls'); // if we have insufficient data points, return a default static guess

      if (locations.length < MIN_SMOOTHING_N) {
        return DEFAULT_INTERVAL_MS;
      } // use up to N points


      const n = Math.min(locations.length, MAX_SMOOTHING_N); // `localTimestamp` is a front-end only property set when we push the record

      const timestamps = locations.slice(locations.length - n, locations.length).map(avlLocation => avlLocation.get('localTimestamp').getTime()); // compute the average

      let sum = 0;

      for (let i = 1; i < timestamps.length; i++) {
        sum += timestamps[i] - timestamps[i - 1];
      } // n points yields (n-1) intervals


      return sum / (n - 1);
    }

  });

  _exports.default = _default;
});