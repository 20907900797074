define("adept-iq/serializers/cs-config-permission", ["exports", "adept-iq/serializers/-ss-schedulingService"], function (_exports, _ssSchedulingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    attrs: {},

    serialize()
    /* snapshot, options */
    {
      let json = this._super(...arguments);

      return json;
    },

    normalizeResponse: function (store, primaryModelClass, payload) {
      let configPermissions = [];
      payload.map(configItem => {
        let data = {
          attributes: {
            value: configItem.get('value')
          },
          id: configItem.id,
          type: 'cs-config-permission'
        };
        data.attributes.name = configItem.name;
        configPermissions.push(data);
      });
      return {
        data: configPermissions
      };
    }
  });

  _exports.default = _default;
});