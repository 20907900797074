define("adept-iq/mixins/trip-attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /* eslint-disable ember/use-brace-expansion */
    attributes: Ember.computed('account', 'editmodelcontext.attributes.account.[]', 'editmodelcontext.attributes.address.[]', 'editmodelcontext.attributes.area.[]', 'editmodelcontext.attributes.cli.[]', 'editmodelcontext.editAttributes.account.[]', 'editmodelcontext.editAttributes.address.[]', 'editmodelcontext.editAttributes.area.[]', 'editmodelcontext.editAttributes.cli.[]', 'editmodelcontext.editAttributes.editTrip[]', 'manualAttributes.[]', function () {
      let assignsAttributes = [];
      let assigns = []; //Account, cli, building, area and region could have attributes associated with.

      if (!Ember.isEmpty(this.get('editmodelcontext.attributes.account'))) {
        assignsAttributes.push(...this.get('editmodelcontext.attributes.account'));
      }

      if (!Ember.isEmpty(this.get('editmodelcontext.attributes.cli'))) {
        assignsAttributes.push(...this.get('editmodelcontext.attributes.cli'));
      }

      if (!Ember.isEmpty(this.get('editmodelcontext.attributes.address'))) {
        assignsAttributes.push(...this.get('editmodelcontext.attributes.address'));
      }

      if (!Ember.isEmpty(this.get('editmodelcontext.attributes.area'))) {
        assignsAttributes.push(...this.get('editmodelcontext.attributes.area'));
      }

      if (!Ember.isEmpty(this.get('editmodelcontext.attributes.region'))) {
        assignsAttributes.push(...this.get('editmodelcontext.attributes.region'));
      } //Edit Trip Assigned Attributes


      if (!Ember.isEmpty(this.get('editmodelcontext.editAttributes.account'))) {
        assignsAttributes.push(...this.get('editmodelcontext.editAttributes.account'));
      }

      if (!Ember.isEmpty(this.get('editmodelcontext.editAttributes.cli'))) {
        assignsAttributes.push(...this.get('editmodelcontext.editAttributes.cli'));
      }

      if (!Ember.isEmpty(this.get('editmodelcontext.editAttributes.address'))) {
        assignsAttributes.push(...this.get('editmodelcontext.editAttributes.address'));
      }

      if (!Ember.isEmpty(this.get('editmodelcontext.editAttributes.area'))) {
        assignsAttributes.push(...this.get('editmodelcontext.editAttributes.area'));
      }

      if (!Ember.isEmpty(this.get('editmodelcontext.editAttributes.region'))) {
        assignsAttributes.push(...this.get('editmodelcontext.editAttributes.region'));
      }

      if (!Ember.isEmpty(this.get('editmodelcontext.editAttributes.editTrip'))) {
        assignsAttributes.push(...this.get('editmodelcontext.editAttributes.editTrip'));
      }

      if (!Ember.isEmpty(this.get('manualAttributes'))) {
        assignsAttributes.push(...this.get('manualAttributes'));
      } // Attributes unique and attribute notes population


      assignsAttributes.forEach(function (item) {
        if (!assigns.isAny('type', item.get('type'))) {
          assigns.addObject(item);
        }
      });
      return assigns;
    })
  });

  _exports.default = _default;
});