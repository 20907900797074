define("adept-iq/models/tm-pickup-window", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/pickup-window.js */
  var _default = _model.default.extend({
    start: (0, _attr.default)('string'),
    end: (0, _attr.default)('string'),
    passengerEvent: (0, _relationships.belongsTo)('tm-passenger-event')
  });

  _exports.default = _default;
});