define("adept-iq/models/stc", ["exports", "ember-data/model", "ember-data/attr", "adept-iq/mixins/zone-label", "adept-iq/mixins/zone-style", "ember-data/relationships", "adept-iq/config/environment"], function (_exports, _model, _attr, _zoneLabel, _zoneStyle, _relationships, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_zoneLabel.default, _zoneStyle.default, {
    name: (0, _attr.default)('string'),
    points: (0, _attr.default)(),
    noOfPoints: Ember.computed('points', function () {
      let count = 0;
      const points = this.get('points');

      if (this.get('points') != null) {
        for (let i = 1; points[i] != null; i++) {
          count++;
        }

        return count;
      }

      return 0;
    }),
    polypoints: Ember.computed('points', 'noOfPoints', function () {
      let polygonPoints = [];
      const points = this.get('points');

      if (!Ember.isEmpty(points) && this.get('noOfPoints') === 1) {
        if (this.get('points') != null) {
          const item = points[1];
          return item;
        }
      } else {
        if (this.get('points') != null) {
          for (let i = 1; points[i] != null; i++) {
            let item = points[i];
            let polygonPoint = [];
            polygonPoint.push(item.lat);
            polygonPoint.push(item.lng);
            polygonPoints.push(polygonPoint);
          }
        }
      }

      return polygonPoints;
    }),
    trips: (0, _relationships.hasMany)('trip'),
    vehicles: (0, _relationships.hasMany)('vehicleState'),
    // eslint-disable-next-line ember/use-brace-expansion
    workspacetrips: Ember.computed('trips.@each', 'trips.@each.bookingType', 'trips.@each.status', function () {
      if (!Ember.isEmpty(this.get('trips'))) {
        return this.get('trips').filter(function (item) {
          return _environment.default.workspaceContext.startDate < item.get('requestedTime') && _environment.default.workspaceContext.endDate > item.get('requestedTime');
        });
      } else {
        return [];
      }
    }),
    asap: Ember.computed('workspacetrips.[]', function () {
      if (!Ember.isEmpty(this.get('workspacetrips'))) {
        return this.get('workspacetrips').filter(trip => trip.get('bookingType') === 'asap' && (trip.get('iqtrip.status') === 'booked' || trip.get('iqtrip.status') === 'active')).length;
      } else {
        return 0;
      }
    }),
    pb: Ember.computed('workspacetrips.[]', function () {
      if (!Ember.isEmpty(this.get('workspacetrips'))) {
        return this.get('workspacetrips').filter(trip => trip.get('bookingType') === 'prebook' && (trip.get('iqtrip.status') === 'booked' || trip.get('iqtrip.status') === 'active')).length;
      } else {
        return 0;
      }
    }),
    auction: Ember.computed('workspacetrips.[]', function () {
      if (!Ember.isEmpty(this.get('workspacetrips'))) {
        return this.get('workspacetrips').filter(trip => trip.get('dispatchMethod') === 'auction' && (trip.get('iqtrip.status') === 'booked' || trip.get('iqtrip.status') === 'active' || trip.get('iqtrip.status') === 'offered')).length;
      } else {
        return 0;
      }
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    freeVehicle: Ember.computed('vehicles.@each', 'vehicles.@each.status', function () {
      if (!Ember.isEmpty(this.get('vehicles'))) {
        return this.get('vehicles').filterBy('status', 'free').length;
      } else {
        return 0;
      }
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    stcVehicle: Ember.computed('vehicles.@each', 'vehicles.@each.status', function () {
      if (!Ember.isEmpty(this.get('vehicles'))) {
        return this.get('vehicles').filterBy('status', 'STC').length;
      } else {
        return 0;
      }
    }),
    totalVehicle: Ember.computed('vehicles', function () {
      return this.get('vehicles').length;
    })
  });

  _exports.default = _default;
});