define("adept-iq/serializers/rider", ["exports", "adept-iq/serializers/-dispatch", "adept-iq/mixins/iq-model-identification"], function (_exports, _dispatch, _iqModelIdentification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend(_iqModelIdentification.default, {
    iqModelName: 'iq-rider',
    iqModelForeignKey: 'id',
    iqModelRelationship: 'iqRider'
  });

  _exports.default = _default;
});