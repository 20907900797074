define("adept-iq/models/vehicle-route", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    plannedStartTime: attr('date'),
    plannedEndTime: attr('date'),
    route: belongsTo(),
    vehicle: belongsTo(),
    driverShifts: hasMany(),
    latestDriver: Ember.computed.alias('latestDriverShift.driver'),
    latestDriverShift: Ember.computed('driverShifts.@each.plannedStartTime', function () {
      return this.get('driverShifts').sortBy('plannedStartTime').get('lastObject');
    })
  });

  _exports.default = _default;
});