define("adept-iq/helpers/idandindex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.idandindex = idandindex;
  _exports.default = void 0;

  function idandindex(args) {
    const s = args[0] + args[1];
    return s;
  }

  var _default = Ember.Helper.helper(idandindex);

  _exports.default = _default;
});