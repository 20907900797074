define("adept-iq/serializers/agency-marker", ["exports", "ember-data", "tomtom"], function (_exports, _emberData, _tomtom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    JSONAPISerializer
  } = _emberData.default;

  var _default = JSONAPISerializer.extend({
    keyForAttribute: function (attr) {
      return attr;
    },

    keyForRelationship(key) {
      return key;
    },

    serialize(snapshot, options = {}) {
      options.includeId = true;

      const json = this._super(snapshot, options);

      json.data.type = 'travelNeedType';
      return json;
    },

    normalizeResponse: function (store, primaryModelClass, payload) {
      const data = Ember.makeArray(payload.data);
      const new_data = data.map(m => {
        const new_icon = _tomtom.default.L.svgIcon({
          icon: m.attributes.options.icon
        });

        m.attributes.options.icon = new_icon;
        return m;
      });
      payload.data = new_data;
      return payload;
    }
  });

  _exports.default = _default;
});