define("adept-iq/pods/components/form-components/searchable-enum-model/component", ["exports", "ember-concurrency", "ember-keyboard-shortcuts"], function (_exports, _emberConcurrency, _emberKeyboardShortcuts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-select-table'],
    store: Ember.inject.service(),
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    value: null,
    disabled: false,
    extra: null,
    options: null,
    widgetModelData: null,
    keyboardShortcuts: null,
    allowMultiple: Ember.computed.alias('extra.allowMultiple'),
    optionSearchPath: Ember.computed.alias('extra.optionSearchPath'),
    optionModelName: Ember.computed.alias('extra.optionModelName'),
    optionSearchEnabled: Ember.computed.alias('extra.optionSearchEnabled'),
    optionTempRecordSelected: Ember.computed.alias('extra.optionTempRecordSelected'),
    optionFilterFunction: Ember.computed.alias('extra.optionFilterFunction'),
    optionWidgetModel: Ember.computed.alias('extra.optionWidgetModel'),
    optionModelWidgetPath: Ember.computed.alias('extra.optionModelWidgetPath'),
    includes: Ember.computed('extra.optionIncludes', function () {
      return this.get('extra.optionIncludes') !== undefined ? this.get('extra.optionIncludes') : '';
    }),

    init() {
      const selectedPath = this.get('extra.optionSelectedPath');

      if (!this.get('optionSearchEnabled')) {
        if (Ember.isEmpty(selectedPath)) {
          this.set('selected', this.get(`value`));
        } else {
          this.set('selected', this.get(`value.${selectedPath}`));
        }
      } else {
        let object = {};
        object[this.get('optionSearchPath')] = this.get('value');
        this.set('selected', object);
      }

      if (!this.get('optionSearchEnabled')) {
        this.loadEnum();
      }

      this._super(...arguments);

      let altaccesskey = 'alt+' + this.get('accessKey');

      if (!Ember.isBlank(this.get('accessKey'))) {
        let accessKeyConfig = {
          [altaccesskey]: {
            action: 'handleFocus',
            // action to trigger
            global: true,
            preventDefault: true
          }
        };
        this.set('keyboardShortcuts', accessKeyConfig);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    },

    loadEnum() {
      this.store.findAll(this.get('optionModelName')).then(results => {
        this.searchResults(results);
      });
    },

    searchResults(results) {
      if (this.get('optionFilterFunction')) {
        let filteredResults = results.filter(result => {
          let available = this.get('record')[this.get('optionFilterFunction')](result);
          return available;
        });
        this.set('options', filteredResults);
      } else {
        this.set('options', results);
      }
    },

    // will do this when optionSearchEnabled is true
    // otherwise it will do loadEnum
    search: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(500);
      const query = {
        filter: `ilike(${this.get('optionSearchPath')},'%${term}%')`,
        include: this.get('includes')
      };
      const results = yield this.store.query(this.get('optionModelName'), query);
      console;
      this.searchResults(results);
    }),
    actions: {
      createNew(option) {
        //console.log('New option',option) // eslint-disable-line no-console
        this.options.push(option);
      },

      onSelected(newRecord) {
        this.set('selected', newRecord);

        if (this.get('optionSearchEnabled')) {
          this.set(`record.${this.get('valuePath')}`, newRecord.get(this.get('optionSearchPath')));
          this.set(`record.${this.get('optionTempRecordSelected')}`, newRecord);
        } else {
          this.set(`record.${this.get('valuePath')}`, newRecord);
        }

        this.set('record.isForceDirty', true);
      },

      onFocus(select, e) {
        Ember.$('.ember-power-select-search-input').focus();
        return false;
      },

      handleKeydown(select, e) {
        this.set('populateAddress', false);

        if (e.keyCode !== 13) {
          return;
        }
        /*let action = this.get('onkeydown');
        if (action && action(select, e) === false) {
          e.stopPropagation();
          return false;
        }
        if (e.keyCode === 13) {
          e.stopPropagation();
        }*/
        //console.log('select.searchText) ',select.searchText);
        //console.log('optionTempRecordSelected ',this.get('optionTempRecordSelected'));


        if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
          //console.log('select.searchText) ',select.searchText);
          let newRider = this.store.createRecord('rider', {
            id: select.searchText,
            riderId: select.searchText
          });
          let riderArray = [];
          riderArray.push(select.searchText); //if (!selected.includes(select.searchText)) {

          this.set('options', riderArray);
          this.set(`record.${this.get('valuePath')}`, newRider.get(this.get('optionSearchPath')));
          this.set(`record.${this.get('optionTempRecordSelected')}`, newRider);
          this.set('selected', newRider);
          this.set('record.isForceDirty', true);
          e.stopPropagation();
          return false; //select.actions.choose(select.searchText);
          //this.get('options').push(newRider);
          //}
        } // TODO: add code to store data
        //  to correct api host when kafka topic is done.

      },

      handleFocus() {
        //console.log('ember-power-select-trigger',$('.ember-power-select-trigger'));
        if (!Ember.isBlank(this.get('accessKey'))) {
          this.input = document.querySelector(`.ember-power-select-trigger[id="${this.get('triggerId')}"]`);

          if (this.input) {
            Ember.run.next(this.input, 'focus');
          }
        }
      }

    }
  });

  _exports.default = _default;
});