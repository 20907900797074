define("adept-iq/adapters/cs-config-permission", ["exports", "adept-iq/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    findAll(store
    /*, type, sinceToken, snapshotRecordArray*/
    ) {
      return store.query('cs-config-item', {
        category: 'config-UI_restrictions'
      });
    }

  });

  _exports.default = _default;
});