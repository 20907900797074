define("adept-iq/pods/components/iq-widgets/map-widget/config/marker", ["exports", "tomtom", "adept-iq/config/icon-paths"], function (_exports, _tomtom, _iconPaths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    location: {
      icon: _tomtom.default.L.svgIcon({
        icon: {
          icon: 'fa fa-building',
          iconSize: [32, 37],
          iconAnchor: [16, 2],
          style: {
            color: '#fff'
          },
          noPlainSVG: true
        }
      })
    },
    locationLate: {
      icon: _tomtom.default.L.svgIcon({
        icon: {
          icon: 'fa fa-building',
          iconSize: [32, 37],
          iconAnchor: [16, 2],
          style: {
            color: '#fff'
          },
          noPlainSVG: true
        },
        background: {
          icon: 'icon-marker-red'
        }
      })
    },
    locationDanger: {
      icon: _tomtom.default.L.svgIcon({
        icon: {
          icon: 'fa fa-building',
          iconSize: [32, 37],
          iconAnchor: [16, 2],
          style: {
            color: '#fff'
          },
          noPlainSVG: true
        },
        background: {
          icon: 'icon-marker-orange'
        }
      })
    },
    active: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.active,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    arrived: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.arrived,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    arrive: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.arrive,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    accepted: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.accepted,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    booked: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.booked,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    noshow: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.noshow,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    accept: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.accept,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    underdispatch: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.underdispatch,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    dispatch: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.dispatch,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    inservice: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.inservice,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    load: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.load,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    offered: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.offered,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    cancelled: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.cancelled,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    onhold: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.onhold,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    scrub: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.scrub,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    altered: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.altered,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    completed: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.completed,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    pick: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.future,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    pickup: {
      draggable: true,
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.future,
        iconSize: [30, 42],
        iconAnchor: [16, 16]
      })
    },
    pickLate: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.futureLate,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    pickDanger: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.futureInDanger,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    drop: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.future,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    dropoff: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.future,
        iconSize: [30, 42],
        iconAnchor: [16, 16]
      })
    },
    dropLate: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.futureLate,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    dropDanger: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.futureInDanger,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    break: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.stops.break,
        iconSize: [24, 30],
        iconAnchor: [16, 16]
      })
    },
    vehicleoffered: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.offered,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehiclearrived: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.arrived,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicleempty: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.empty,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehiclefree: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.free,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicleoff: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.off,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicleonbreak: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.onbreak,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehiclefreetempoff: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.tempoff,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehiclefreeonbreak: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.onhold,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicletempoff: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.tempoff,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicleonhold: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.onhold,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehiclechecking: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.checking,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicleunexplained: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.unexplained,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicleemergency: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.emergency,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicle: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicle,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicleDriving: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.atWork,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicleLate: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicle,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicleDanger: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.vehicle,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicleAtwork: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.atWork,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicleaccepted: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.accepted,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehiclebooked: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.booked,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicleload: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.load,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicleoccupied: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.occupied,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehiclestc: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.stc,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    },
    vehicleud: {
      icon: _tomtom.default.L.icon({
        iconUrl: _iconPaths.default.vehicles.ud,
        iconSize: [16, 24],
        iconAnchor: [16, 16]
      })
    }
  };
  _exports.default = _default;
});