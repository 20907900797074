define("adept-iq/models/tm-canned-message", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MESSAGE_STATUSES = _exports.MESSAGE_STATUS_DELETED = _exports.MESSAGE_STATUS_FAILED = _exports.MESSAGE_STATUS_RECEIVED = _exports.MESSAGE_STATUS_SENT = _exports.MESSAGE_STATUS_SENDING = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  const MESSAGE_STATUS_SENDING = 'Sending';
  _exports.MESSAGE_STATUS_SENDING = MESSAGE_STATUS_SENDING;
  const MESSAGE_STATUS_SENT = 'Sent';
  _exports.MESSAGE_STATUS_SENT = MESSAGE_STATUS_SENT;
  const MESSAGE_STATUS_RECEIVED = 'Received';
  _exports.MESSAGE_STATUS_RECEIVED = MESSAGE_STATUS_RECEIVED;
  const MESSAGE_STATUS_FAILED = 'Failed';
  _exports.MESSAGE_STATUS_FAILED = MESSAGE_STATUS_FAILED;
  const MESSAGE_STATUS_DELETED = 'Deleted';
  _exports.MESSAGE_STATUS_DELETED = MESSAGE_STATUS_DELETED;
  const MESSAGE_STATUSES = [MESSAGE_STATUS_SENDING, MESSAGE_STATUS_SENT, MESSAGE_STATUS_RECEIVED, MESSAGE_STATUS_FAILED, MESSAGE_STATUS_DELETED];
  _exports.MESSAGE_STATUSES = MESSAGE_STATUSES;

  var _default = Model.extend({
    body: attr('string'),
    messageNr: attr('string'),
    replyToMsgId: attr('string'),
    priority: attr('string', {
      defaultValue: 'normal'
    }),
    createdTime: attr('date', {
      defaultValue: () => new Date()
    }),
    deliveredTime: attr('date'),
    readTime: attr('date'),
    deletedTime: attr('date'),
    // these are used to normalize non-JSON-API payload
    driverId: attr('string'),
    driverEndPointType: attr('string', {
      defaultValue: 'recipient'
    }),
    // TODO: make sure API is supplying these
    vehicleId: attr('string'),
    routeId: attr('string'),
    // this is "proxied" to `driverId` and `driverEndPointType` above
    driver: (0, _relationships.belongsTo)('tm-driver'),
    // TODO: implement when supported by API
    // vehicle: belongsTo('tm-vehicle'),
    // route: belongsTo('tm-route'),
    isDelivered: Ember.computed('deliveredTime', function () {
      return Ember.isPresent(this.get('deliveredTime'));
    }),
    isRead: Ember.computed('readTime', function () {
      return Ember.isPresent(this.get('readTime'));
    }),
    isDeleted: Ember.computed('deletedTime', function () {
      return Ember.isPresent(this.get('deletedTime'));
    }),
    status: Ember.computed('isDelivered', 'isRead', 'isDeleted', function () {
      if (this.get('isDeleted')) return MESSAGE_STATUS_DELETED;
      if (this.get('isRead')) return MESSAGE_STATUS_RECEIVED;
      if (this.get('isDelivered')) return MESSAGE_STATUS_SENT; // TODO: how do we know when a message is "failed"?

      return MESSAGE_STATUS_SENDING;
    }),
    readString: Ember.computed('isRead', function () {
      return this.get('isRead') ? 'Yes' : 'No';
    })
  });

  _exports.default = _default;
});