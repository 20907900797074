define("adept-iq/pods/components/iq-widgets/assign-trip-2-route-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'riderFirstName',
        label: 'First Name',
        valuePath: 'trip.rider.lastName',
        editable: false
      }, {
        id: 'riderLastName',
        label: 'Rider Last Name',
        valuePath: 'trip.rider.lastName',
        editable: false
      }, {
        id: 'riderId',
        label: 'Rider ID',
        valuePath: 'rider.riderId',
        editable: false
      }]
    }, {
      title: 'Select Route',
      fields: [{
        id: 'route',
        type: 'routeenum',
        label: 'Route',
        valuePath: 'assignRoute',
        cellDesc: 'assignRoute',
        editable: true,
        hidden: false,
        extra: {
          optionModelName: 'route',
          optionSearchPath: 'description'
        }
      }, {
        id: 'vehicle',
        type: 'uuid',
        label: 'Vehicle',
        valuePath: 'assignRoute.assignedVehicle.name',
        editable: false
      }, {
        id: 'driverId',
        type: 'uuid',
        label: 'Driver ID',
        valuePath: 'assignRoute.assignedDriver.externalId',
        editable: false
      }, {
        id: 'driverLastName',
        type: 'text',
        label: 'Driver Last Name',
        valuePath: 'assignRoute.assignedDriver.lastName',
        editable: false
      }, {
        id: 'driverFirstName',
        type: 'text',
        label: 'Driver First Name',
        valuePath: 'assignRoute.assignedDriver.firstName',
        editable: false
      }]
    }]
  };
  _exports.default = _default;
});