define("adept-iq/models/tm-route-exec", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/coord.js */
  var _default = _model.default.extend({
    version: (0, _attr.default)('string'),
    trackingId: (0, _attr.default)('string'),
    routeId: (0, _attr.default)('string'),
    driver: (0, _attr.default)(),
    vehicle: (0, _attr.default)(),
    state: (0, _attr.default)('string'),
    passengers: (0, _attr.default)(),
    stopPoints: (0, _attr.default)(),
    prices: (0, _attr.default)(),
    clusters: (0, _attr.default)(),
    breaks: (0, _attr.default)(),
    requestedProvider: (0, _attr.default)(),
    source: (0, _attr.default)(),
    start: (0, _attr.default)(),
    end: (0, _attr.default)(),
    driverScheduleId: (0, _attr.default)('string'),
    vehicleScheduleId: (0, _attr.default)('string')
  });

  _exports.default = _default;
});