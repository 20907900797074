define("adept-iq/pods/components/table/cells/user-editable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QZ/GGA5Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isDoubleClick\"]]],null,{\"statements\":[[0,\"  \"],[7,\"input\"],[11,\"class\",\"can-focus\"],[12,\"value\",[27,\"readonly\",[[23,[\"buffer\"]]],null]],[12,\"oninput\",[27,\"action\",[[22,0,[]],\"onInput\"],[[\"value\"],[\"target.value\"]]]],[12,\"onblur\",[27,\"action\",[[22,0,[]],\"onBlur\"],null]],[12,\"onkeydown\",[27,\"action\",[[22,0,[]],\"onKeydown\"],[[\"value\"],[\"keyCode\"]]]],[11,\"type\",\"text\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[21,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/table/cells/user-editable/template.hbs"
    }
  });

  _exports.default = _default;
});