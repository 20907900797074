define("adept-iq/pods/components/iq-widgets/poi-address-search-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/address-lookup',
    rowComponent: 'table/rows/otp-formatted-row',
    modelName: 'address-lookup',
    defaultSortId: 'address.description',
    defaultSortAsc: false,
    hideWidgetControls: true,
    hideHeader: true,
    checkBoxColumn: false,
    isFormControls: true,
    title: 'Address search Details',
    columns: [{
      id: 'name',
      type: 'text',
      label: 'Name',
      valuePath: 'address.description',
      editable: false,
      searchable: true,
      hidden: false,
      defaultWidth: 300
    }, {
      id: 'streetNumber',
      type: 'text',
      label: 'Street Number',
      valuePath: 'address.streetNr',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'streetName',
      type: 'text',
      label: 'Street Name',
      valuePath: 'address.streetName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 300
    }, {
      id: 'city',
      type: 'text',
      label: 'City',
      valuePath: 'address.region',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 200
    }]
  };
  _exports.default = _default;
});