define("adept-iq/pods/components/iq-widgets/users-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/users-widget/config", "adept-iq/config/environment", "adept-iq/config/api-urls", "lodash"], function (_exports, _component, _config, _environment, _apiUrls, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['users-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    editComponent: 'iq-widgets/users-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,

    // TODO: saveRecordFunction shall be moved to a service later on
    // This save is specific to saving drivers and all related models.
    saveRecordFunction(records) {
      let that = this; // using custom api call because sso api is non standard
      // and in-consistent.

      function userAPICall(method, data, url, dataType) {
        let session = that.get('session');
        let ajax = that.get('ajax');
        return ajax.request(url, {
          host: _apiUrls.API.ssoService.host,
          dataType: 'text',
          contentType: dataType,
          method: method,
          data: data,
          headers: {
            'Content-Type': dataType,
            Accept: dataType,
            Authorization: `Bearer ${session.data.authenticated.token}`
          }
        });
      }

      function saveStatus(userId, status) {
        return userAPICall('PUT', {
          status: status
        }, `/user/${userId}/status`, 'application/json');
      }

      function saveRoles(userId, roles) {
        let idRoles = _lodash.default.map(roles, role => {
          return role.roleName;
        });

        return userAPICall('GET', null, `/user/${userId}/roles`, 'text').then(currentRolesString => {
          let currentRoles = JSON.parse(currentRolesString);

          let removeRoles = _lodash.default.difference(currentRoles.roles, idRoles);

          let addRoles = _lodash.default.difference(idRoles, currentRoles.roles);

          return Promise.all(removeRoles.map(removeRole => {
            return userAPICall('DELETE', null, `/user/${userId}/roles/${removeRole}`, 'text');
          })).then(() => {
            return Promise.all(addRoles.map(addRole => {
              return userAPICall('PUT', null, `/user/${userId}/roles/${addRole}`, 'text');
            }));
          });
        });
      }

      function addNewUser(record) {
        let roles = [];

        if (!Ember.isEmpty(record.UserRoleRolenames)) {
          roles.push(record.UserRoleRolenames.roleName);
        } // sendMail defaulted to false because of a bug in api


        let data = {
          displayName: record.displayName,
          email: record.email,
          status: record.status,
          roles: roles,
          sendEmail: false
        };
        return userAPICall('POST', data, `/user`, 'application/json');
      }

      this.set('editModal.errors', []);
      return new Promise((resolve, reject) => {
        records.forEach(record => {
          if (record.isNew) {
            addNewUser(record, this).then(result => {
              let resultObject = JSON.parse(result); // returning the record or the newly created id
              // does not exist. This will happen a bit later
              // when the backend guys have time. reload will fail without
              // this.

              if (resultObject.userId !== undefined) {
                that.get('store').find('sso-user', resultObject.userId).then(result => {
                  that.get('records').pushObject(result);
                  record.destroyRecord();
                  that.set('editModal.editableRecords', []);
                  that.get('editModal.editableRecords').pushObject(result);
                });
              }
            }).catch(e => {
              reject(e);
            });
          } else {
            saveStatus(record.userId, record.status, this).then(() => {
              saveRoles(record.userId, record.UserRoleRolenames, this).then(() => {
                record.reload().then(() => {
                  record.set('isForceDirty', false);
                  that.get('activeContext').refreshTableContent(that.get('config.modelName'));
                });
              });
            }).catch(e => {
              reject(e);
            });
          }
        });
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('tableActions', [{
        name: 'New User',
        action: function () {
          let newModel = this.get('store').createRecord(this.get('config.modelName'));
          this.set('editModal.editableRecords', []);
          let editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, [newModel], this.get('saveRecordFunction').bind(this));
        }.bind(this)
      }]);
      this.set('singleActions', [{
        name: 'Edit',
        action: model => {
          let editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, [model], this.get('saveRecordFunction').bind(this));
        }
      }]);
      this.set('bulkActions', [{
        name: 'Bulk Edit',
        action: models => {
          let editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, models, this.get('saveRecordFunction').bind(this));
        }
      }]);
    }

  });

  _exports.default = _default;
});