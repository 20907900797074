define("adept-iq/pods/components/form-components/account-model/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7MM5owZ6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"table\"],[11,\"class\",\"mt-3 places-table\"],[9],[0,\"\\n  \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"td\"],[9],[7,\"input\"],[11,\"class\",\"form-control\"],[12,\"value\",[27,\"readonly\",[[23,[\"value\"]]],null]],[12,\"accesskey\",[21,\"accessKey\"]],[12,\"disabled\",[27,\"readonly\",[[23,[\"disabled\"]]],null]],[12,\"onkeydown\",[27,\"action\",[[22,0,[]],\"handleKeydown\"],null]],[12,\"maxlength\",[27,\"readonly\",[[23,[\"maxlength\"]]],null]],[12,\"oninput\",[27,\"action\",[[22,0,[]],\"onInput\"],[[\"value\"],[\"target.value\"]]]],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"td\"],[9],[1,[27,\"form-components/account\",null,[[\"record\"],[[23,[\"record\"]]]]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/account-model/template.hbs"
    }
  });

  _exports.default = _default;
});