define("adept-iq/adapters/tm-text-message", ["exports", "adept-iq/adapters/-tm-tripManagement"], function (_exports, _tmTripManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tmTripManagement.default.extend({
    updateRecord(store, type, snapshot) {
      const data = {};
      const serializer = this.store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      const id = snapshot.id;
      const url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      return this.ajax(url, 'PUT', {
        data: data
      });
    }

  });

  _exports.default = _default;
});