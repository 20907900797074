define("adept-iq/pods/components/iq-widgets/edit-settings-widget/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    type: 'Travel Need Type',
    id: 'travelNeedType',
    description: 'travelNeedType description here'
  }, {
    type: 'Fare Type',
    id: 'fareType',
    description: 'fareType description here'
  }, {
    type: 'Provider Type',
    id: 'providerType',
    description: 'providerType description here'
  }, {
    type: 'Providers',
    id: 'providers',
    description: 'providers description here'
  }, {
    type: 'Break Type',
    id: 'breakType',
    description: 'breakType description here'
  }, {
    type: 'Cancel Type',
    id: 'cancelType',
    description: 'cancelType description here'
  }, {
    type: 'No Show Reason Codes',
    id: 'noShowReasonCodes',
    description: 'noShowReasonCodes description here'
  }, {
    type: 'Vehicle Capacity Type',
    id: 'vehicleCapacityType',
    description: 'vehicleCapacityType description here'
  }, {
    type: 'Vehicle Type',
    id: 'vehicleType',
    description: 'vehicleType description here'
  }, {
    type: 'Address Category Type',
    id: 'addressCategoryType',
    description: 'addressCategoryType description here'
  }, {
    type: 'Rider Address Type',
    id: 'riderAddressType',
    description: 'riderAddressType description here'
  }, {
    type: 'Passenger Type',
    id: 'passengerType',
    description: 'passengerType description here'
  }, {
    type: 'Eligibility Type',
    id: 'eligibilityType',
    description: 'eligibilityType description here'
  }];
  _exports.default = _default;
});