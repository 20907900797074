define("adept-iq/serializers/tm-rider-profile", ["exports", "adept-iq/serializers/-tm-tripManagement"], function (_exports, _tmTripManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tmTripManagement.default.extend({
    serialize()
    /* snapshot, options */
    {
      let json = this._super(...arguments);

      json.data.type = 'rider-profile';

      try {
        delete json.data.attributes.favoriteTrips[0].price;
        delete json.data.attributes.favoriteTrips[0].customerReference;
      } catch (e) {//console.log(e);
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      let normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});