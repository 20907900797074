define("adept-iq/pods/components/iq-widgets/activity-log-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/activity-log-widget',
    rowComponent: 'table/rows/otp-formatted-row',
    modelName: 'tm-activity-log',
    defaultSortId: 'id',
    defaultSortAsc: false,
    hideWidgetControls: true,
    hideHeader: true,
    checkBoxColumn: false,
    isFormControls: true,
    title: 'Trip logs',
    download: true,
    columns: [{
      id: 'id',
      type: 'uuid',
      label: 'ID',
      valuePath: 'id',
      hidden: true,
      isMapLabelVisible: false,
      searchable: true,
      defaultWidth: 60,
      isMapMCGLabelVisible: false
    }, {
      id: 'tripId',
      type: 'number',
      label: 'Trip ID',
      valuePath: 'tripId',
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 80,
      isMapMCGLabelVisible: true,
      sorted: false
    }, {
      id: 'type',
      type: 'text',
      label: 'Type',
      valuePath: 'type',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 150,
      sorted: false
    }, {
      id: 'actualTime',
      type: 'time',
      label: 'Time',
      isMapLabelVisible: true,
      valuePath: 'actualTime',
      editable: false,
      format: 'HH:mm A',
      searchable: true,
      defaultWidth: 100,
      sorted: false
    }, {
      id: 'callSign',
      type: 'text',
      label: 'Call Sign',
      valuePath: 'callSign',
      editable: false,
      hidden: false,
      searchable: true,
      sorted: false
    }, {
      id: 'priority',
      type: 'text',
      label: 'priority',
      valuePath: 'priority',
      editable: false,
      hidden: false,
      searchable: true,
      sorted: false
    }, {
      id: 'logBy',
      type: 'text',
      label: 'Log By',
      valuePath: 'logBy',
      editable: false,
      hidden: false,
      searchable: true,
      sorted: false
    }, {
      id: 'Comments',
      type: 'text',
      label: 'Comments',
      valuePath: 'comments',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 300,
      sorted: false
    }]
  };
  _exports.default = _default;
});