define("adept-iq/adapters/tm-activity-log", ["exports", "adept-iq/adapters/-tm-tripManagement"], function (_exports, _tmTripManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tmTripManagement.default.extend({
    query(store, type, query) {
      let url = this.buildURL(type.modelName, null, null, 'query', query);
      url = url.replace('query/tm-activity-log', 'activity-log');
      return this.ajax(url, 'GET');
    },

    buildURL(modelName, id, snapshot, requestType, query) {
      const baseUrl = this._super(requestType, id, null);

      const url = baseUrl + "/" + modelName + "?filter=attributes/tripId==" + query.filter.tripId;
      return url;
    }

  });

  _exports.default = _default;
});