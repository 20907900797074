define("adept-iq/utils/populate-poi-address", ["exports", "adept-iq/utils/check-value"], function (_exports, _checkValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = populateAddress;

  function populateAddressData(address, addressData, geocode, store, addressType, tripData) {
    //console.log('address ',address);
    try {
      address.unitNr = (0, _checkValue.default)(addressData.unitNr);
    } catch (e) {
      Ember.set(address, 'unitNr', (0, _checkValue.default)(addressData.unitNr));
    }

    address.streetNr = (0, _checkValue.default)(addressData.streetNr);
    address.streetName = (0, _checkValue.default)(addressData.streetName);
    address.region = (0, _checkValue.default)(addressData.region);
    address.state = (0, _checkValue.default)(addressData.region);
    address.postCode = (0, _checkValue.default)(addressData.postalCode);

    try {
      address.description = (0, _checkValue.default)(addressData.description);
    } catch (e) {
      Ember.set(address, 'description', addressData.description);
    } //address.country = ttAddress.address.country;


    try {
      Ember.set(address.tomtomFormattedAddress.address, 'freeformAddress', `${address.streetNr} ${address.streetName} ${address.region}`);
    } catch (e) {}
    /*try {
      Ember.set(address, 'formattedAddress', `${address.streetNr} ${address.streetName} ${address.region}`);
     } catch(e)   {
     }*/


    if (!Ember.isBlank(addressData) && !Ember.isBlank(addressData.coord) && addressData.coord.lat !== 0 && addressData.coord.lng !== 0 && addressData.coord.lat !== 181 && addressData.coord.lng !== 181 && addressData.coord.lat !== -181 && addressData.coord.lng !== -181 && !Ember.isBlank(addressData.coord.lat) && !Ember.isBlank(addressData.coord.lng)) {
      address.coord.lat = addressData.coord.lat;
      address.coord.lng = addressData.coord.lng;
    } else {
      address.coord.lat = 49.127196;
      address.coord.lng = -123.093865;
    } //address.geoNode = addressData.get('geoNode');


    let addressResult = [];
    addressResult.push(address);
    geocode.set('markerType', addressType);

    if (!Ember.isBlank(address) && !Ember.isBlank(address.coord) && address.coord.lat !== 0 && address.coord.lng !== 0 && address.coord.lat !== 181 && address.coord.lng !== 181 && address.coord.lat !== -181 && address.coord.lng !== -181 && !Ember.isBlank(address.coord.lat) && !Ember.isBlank(address.coord.lng)) {
      geocode.addressMapPopulate(address);

      try {
        geocode.getGeonode(addressData.coord.lat, addressData.coord.lng).then(result => {
          const zoneData = store.peekRecord('zone', result.data.gpsAreaId);
          Ember.set(address, 'geoNode', zoneData.get('name'));
          let notes = zoneData.get('notes');
          let zoneDriverNotes = "";
          let zoneOperatorNotes = "";

          if (!Ember.isBlank(notes)) {
            zoneDriverNotes = notes.driver;
            zoneOperatorNotes = notes.dispatcher;
          }

          Ember.set(tripData, 'editmodelcontext.predefinedDriverNotes.area', zoneDriverNotes);
          Ember.set(tripData, 'editmodelcontext.predefinedOperatorNotes.area', zoneOperatorNotes);
          let options = this.get('store').peekAll('tm-common-operational-attribute');
          let requiredAttributes = zoneData.get('requiredAttributes');
          let areaAttributes = options.filter(function (o1) {
            // if match found return false
            return lodash.findIndex(requiredAttributes, {
              'type': o1.type
            }) === -1 ? false : true;
          });
          Ember.set(tripData, 'editmodelcontext.attributes.area', areaAttributes);
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  function populateDataPreloadedData(address, addressData, geocode, store, addressType, tripData) {
    try {
      address.unitNr = (0, _checkValue.default)(addressData.unitNr);
    } catch (e) {
      Ember.set(address, 'unitNr', (0, _checkValue.default)(addressData.get('unitNr')));
    }

    address.streetNr = addressData.get('streetNr');
    address.streetName = (0, _checkValue.default)(addressData.get('streetName'));
    address.region = (0, _checkValue.default)(addressData.get('locality'));
    address.state = (0, _checkValue.default)(addressData.get('region'));
    address.postCode = (0, _checkValue.default)(addressData.get('postalCode'));

    try {
      address.description = (0, _checkValue.default)(addressData.description);
    } catch (e) {
      Ember.set(address, 'description', addressData.get('description'));
    }

    Ember.set(address.tomtomFormattedAddress.address, 'freeformAddress', `${addressData.get('streetNr')} ${addressData.get('streetName')} ${addressData.get('region')}`);
    address.coord.lat = addressData.get('coord.lat');
    address.coord.lng = addressData.get('coord.lng'); //address.geoNode = addressData.get('geoNode');

    let addressResult = [];
    addressResult.push(address);
    geocode.set('markerType', addressType);

    if (!Ember.isBlank(address) && !Ember.isBlank(address.coord) && address.coord.lat !== 0 && address.coord.lng !== 0 && address.coord.lat !== 181 && address.coord.lng !== 181 && !Ember.isBlank(address.coord.lat) && !Ember.isBlank(address.coord.lng)) {
      geocode.addressMapPopulate(address);

      try {
        geocode.getGeonode(addressData.get('coord.lat'), addressData.get('coord.lng')).then(result => {
          const zoneData = store.peekRecord('zone', result.data.gpsAreaId); //Ember.set(address, 'geoNode', zoneData.get('name'));
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  function clearAddressData(address, geocode) {
    try {
      address.unitNr = '';
    } catch (e) {
      Ember.set(address, 'unitNr', '');
    }

    address.streetNr = '';
    address.streetName = '';
    address.region = '';
    address.state = '';
    address.postCode = '';

    try {
      address.description = '';
    } catch (e) {
      Ember.set(address, 'description', '');
    } //address.country = '';


    if (address.coord) {
      address.coord.lat = 0;
      address.coord.lng = 0;
    }

    try {
      Ember.set(address.tomtomFormattedAddress.address, 'freeformAddress', '');
    } catch (e) {} //TODO clear data according to type.


    geocode.deactivateGeocode();
  }

  function populateAddress(address, addressData, geocode, store, addressType, tripData) {
    if (addressData !== null) {
      try {
        populateAddressData(address, addressData, geocode, store, addressType, tripData);
      } catch (e) {
        console.log(e);
        populateDataPreloadedData(address, addressData, geocode, store, addressType, tripData);
      }
    } else {
      clearAddressData(address, geocode, store, addressType);
    }

    return;
  }
});