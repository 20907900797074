define("adept-iq/pods/components/iq-widgets/cli-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "adept-iq/config/environment", "adept-iq/config/api-urls", "ember-concurrency", "adept-iq/utils/populate-new-trip", "adept-iq/utils/populate-copy-trip", "adept-iq/utils/populate-edit-trip", "adept-iq/utils/populate-search-trip", "adept-iq/utils/populate-account", "adept-iq/utils/check-address-flags", "adept-iq/utils/populate-call-line"], function (_exports, _component, _environment, _apiUrls, _emberConcurrency, _populateNewTrip, _populateCopyTrip, _populateEditTrip, _populateSearchTrip, _populateAccount, _checkAddressFlags, _populateCallLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    dragCoordinator: Ember.inject.service(),
    classNames: ['cli-widget'],
    trip: Ember.inject.service(),
    geocode: Ember.inject.service(),
    store: Ember.inject.service(),
    editModal: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    searchservice: Ember.inject.service(),
    ajax: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    session: Ember.inject.service(),
    records: null,
    phoneSearchValue: null,
    phoneSearchPath: _apiUrls.API_SUB_URL.phoneSearchPath,
    accountSearchPath: _apiUrls.API_SUB_URL.accountSearchPath,
    populatePhoneOnAccept: _environment.default.APP.populatePhoneOnAccept,
    editComponent: 'iq-widgets/passengers-form-widget',
    newTripComponent: 'iq-widgets/new-trip-form-widget',
    passengerTripComponent: 'iq-widgets/new-trip-passenger-form-widget',

    init() {
      this._super(...arguments);

      this.set('records', []);
      let ringCentralURL = "https://ringcentral.github.io/ringcentral-embeddable/adapter.js?appKey=" + _environment.default.RING_CENTRAL.appKey + "&appSecret=" + _environment.default.RING_CENTRAL.appSecret + "&appServer=" + _environment.default.RING_CENTRAL.appServer;

      (function () {
        var rcs = document.createElement("script");
        rcs.src = ringCentralURL;
        var rcs0 = document.getElementsByTagName("script")[0];
        rcs0.parentNode.insertBefore(rcs, rcs0);
      })(); //this.set('awsAmazonConnect',API.awsAmazonConnect.host);

    },

    search: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(50);
      let searchResults;
      searchResults = yield this.getRiderProfile(this.get('phoneSearchPath'), this.get('phoneSearchValue'));
      return searchResults;
    }),

    getRiderProfile(phoneSearchPath, phoneSearchValue) {
      return this.get('ajax').put(_apiUrls.API.tripManagementService.host + `/rider-profile?filter=${phoneSearchPath}==` + phoneSearchValue, {
        method: 'GET',
        contentType: "application/json"
      }).then(response => {
        return response.data;
      }).catch(function () {
        return [];
      });
    },

    saveTripRecordFunction(records, editComponentAction) {
      console.log(' saveTripRecordFunction ', editComponentAction);
      const me = this;
      return new Promise((resolve, reject) => {
        let record = records.firstObject;
        record.save().then(() => {
          console.log('after saveTripRecordFunction ');
          const tripId = record.get('id');
          me.get('errorMessage').pushInfo({
            detail: `Trip '${tripId}' successfully saved`
          });
          record.set('lastId', record.get('id'));
          record.set('isForceDirty', false);

          if (!Ember.isBlank(editComponentAction) && editComponentAction === 'onApplyClick') {
            Ember.run(() => {
              let recordRefresh = [];
              let newtrip = (0, _populateEditTrip.default)(me.get('trip'), record, me.get('store'), me.get('geocode'));
              (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, false);
              recordRefresh.push(newtrip);
              let newTripComponent = me.get('editSwitchTripComponent');

              if (this.get('editModal.editComponent') !== null && this.get('editModal.editComponent') === this.get('editSwitchTripComponent')) {
                newTripComponent = me.get('editTripComponent');
              }

              me.get('editModal').open(newTripComponent, recordRefresh, me.get('saveTripRecordFunction').bind(me));
            });
          } else if (!Ember.isBlank(editComponentAction) && editComponentAction === 'onApplyClearClick') {
            Ember.run(() => {
              Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
              me.get('geocode').activateGeocode(false);
              me.get('geocode').deactivateGeocode();
              let recordRefresh = [];
              let newtrip = (0, _populateNewTrip.default)(me.get('trip'), me.get('store'));
              (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, false);
              newtrip.set('lastId', record.get('lastId'));
              recordRefresh.push(newtrip); //me.set('editableRecords',recordRefresh);
              //me.set('records',recordRefresh);

              let newTripComponent = me.get('newSwitchTripComponent');

              if (me.get('editModal.editComponent') !== null && me.get('editModal.editComponent') === me.get('newSwitchTripComponent')) {
                newTripComponent = me.get('newTripComponent');
              }

              me.get('editModal').open(newTripComponent, recordRefresh, me.get('saveTripRecordFunction').bind(me));
            });
          }
        }).catch(function (error) {
          console.log(error);
          reject(error);
        });
      });
    },

    prepareNewTrip(riderPhoneNr) {
      let trip = (0, _populateNewTrip.default)(this.get('trip'), this.get('store'));
      trip.passengers[0].phone1.number = riderPhoneNr;
      Ember.run(() => {
        trip.set('isForceDirty', true); //this.get('records').pushObject(trip);

        let newTripComponent = this.get('newTripComponent');
        this.get('editModal').open(newTripComponent, [trip], this.get('saveTripRecordFunction').bind(this));
      });
    },

    preparePassengerTrip(model) {
      let rider = model; //.get('rider.content');

      let trip = (0, _populateNewTrip.default)(this.get('trip'), this.get('store'));
      trip.passengers[0].phone1.number = rider.phoneNr;
      trip.passengers[0].name = rider.name;
      trip.passengers[0].firstName = rider.name;
      this.set('phoneSearchValue', rider.phoneNr);
      Ember.run(() => {
        this.get('search').perform().then(results => {
          //console.log('rider.callLines',rider.callLines);
          let widgetModelData = results; //console.log('widgetModelData ',widgetModelData);

          trip.editmodelcontext.callerTrips = widgetModelData;

          if (widgetModelData && widgetModelData.length > 1) {
            this.set('editmodalcontext.showWidgetModal', true);
            this.set('editmodalcontext.widgetModelData', widgetModelData);
            this.set('editmodalcontext.modelWidgetPath', 'rider-profile-widget');
          } else if (widgetModelData && widgetModelData.length === 1) {
            (0, _populateCallLine.default)(trip, results.firstObject.attributes, this.get('geocode'), this.get('store'));
            (0, _populateAccount.default)(trip, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), results.firstObject.attributes.account.accountId, true, rider.phoneNr);
          }

          (0, _checkAddressFlags.default)(trip);
        });
        trip.set('isForceDirty', true); //this.get('records').pushObject(trip);
        // this.set('editModal.editableRecords',[]);
        // this.get('editModal.editableRecords').pushObject(trip);

        let newTripComponent = this.get('newTripComponent');
        this.get('editModal').open(newTripComponent, [trip], this.get('saveTripRecordFunction').bind(this));
      });
    },

    prepareNewPassengerTrip(phoneNr) {
      let records = this.get('editModal.editableRecords');
      let tripRecord = records.firstObject;
      tripRecord.set('passengers.0.phone1.number', phoneNr); //trip.passengers[0].name =  rider.name;
      //trip.passengers[0].firstName =  rider.name;

      this.set('phoneSearchValue', phoneNr);
      this.set('editModal.errors', []);
      Ember.run(() => {
        this.get('search').perform().then(results => {
          //console.log('rider.callLines',rider.callLines);
          if (!Ember.isEmpty(results)) {
            let searchRecord = results.firstObject;
            let widgetModelData = results; //console.log('widgetModelData ',widgetModelData);

            tripRecord.set('editmodelcontext.callerTrips', widgetModelData);

            if (widgetModelData && widgetModelData.length > 1) {
              this.set('editmodalcontext.showWidgetModal', true);
              this.set('editmodalcontext.widgetModelData', widgetModelData);
              this.set('editmodalcontext.modelWidgetPath', 'rider-profile-widget');
              this.set('editmodalcontext.record', tripRecord);
            } else if (widgetModelData && widgetModelData.length === 1) {
              (0, _populateCallLine.default)(tripRecord, results.firstObject.attributes, this.get('geocode'), this.get('store'));
              (0, _populateAccount.default)(tripRecord, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), results.firstObject.attributes.account.accountId, true, phoneNr);
            }

            (0, _checkAddressFlags.default)(tripRecord);
          } else {
            //console.log('parentValuePath',this.get('parentValuePath') );
            this.get('editModal.errors').pushObject('No result found');
          }
        }); //let newTripComponent = this.get('newTripComponent');
        //this.get('editModal').open(newTripComponent, [booking], this.get('saveTripRecordFunction').bind(this));
      });
    },

    didInsertElement() {
      this._super(...arguments);

      let me = this;
      window.addEventListener('message', e => {
        const data = e.data;
        console.log('addEventListener ', e.data);

        if (data) {
          switch (data.type) {
            case 'rc-active-call-notify':
              {
                // get call when user gets a ringing call
                //console.log('rc-call-ring-notify data.call[from]',data.call['from']);

                /* console.log('rc-call-ring-notify',data.call.from.phoneNumber);
                 const customerNo = data.call.from.phoneNumber;
                 let callerNumber =  customerNo;
                 callerNumber = callerNumber.substring(2);
                  me.prepareNewPassengerTrip(callerNumber);*/
                break;
              }

            case 'rc-call-init-notify':
              // get call when user creates a call from dial
              console.log(data.call);
              break;

            case 'rc-call-start-notify':
              {
                // get call when a incoming call is accepted or a outbound call is connected
                console.log('rc-call-ring-notify data.call', data);
                console.log('rc-call-ring-notify data.call.from.phoneNumber', data.call.from);
                const customerNo = data.call.from;
                let callerNumber = customerNo;
                callerNumber = callerNumber.substring(2);
                document.getElementById('callerNo').innerHTML = callerNumber;

                if (me.get('populatePhoneOnAccept')) {
                  document.getElementById('inputPhone').focus();
                  me.prepareNewPassengerTrip(callerNumber);
                }

                break;
              }

            case 'rc-call-hold-notify':
            case 'rc-call-resume-notify':
              {
                // get call when a incoming call is accepted or a outbound call is connected
                console.log('rc-call-ring-notify data.call', data);
                console.log('rc-call-ring-notify data.call.from.phoneNumber', data.call.from);
                const customerNo = data.call.from;
                let callerNumber = customerNo;
                callerNumber = callerNumber.substring(2);
                document.getElementById('callerNo').innerHTML = callerNumber;
                break;
              }

            case 'rc-call-end-notify':
              // get call on call end event
              console.log(data.call);
              break;

            default:
              break;
          }
        }
      });
      Ember.$("#rc-widget").show();
      console.log('isndie didInsertElement', document.getElementById("containerDiv"));
      Ember.run.schedule('afterRender', () => {
        this.$(connect.core.initCCP(document.getElementById('containerDiv'), {
          ccpUrl: 'https://taxiuxconnectdemo.awsapps.com/connect/ccp#',
          loginPopup: true,
          ccpLoadTimeout: 25000000,
          softphone: {
            allowFramedSoftphone: true
          }
        }));
        this.$(connect.contact(function (contact) {
          const activeConnection = contact.getActiveInitialConnection();
          const contactId = activeConnection['contactId'];
          const connectionId = activeConnection['connectionId'];
          const conn = new connect.Connection(contactId, connectionId);
          const customerNo = conn.getEndpoint().phoneNumber;
          document.getElementById('containerDiv').style.display = 'block'; //console.log('customerNo ',customerNo);

          document.getElementById('callerNo').value = customerNo;
          document.getElementById('callerNo').innerHTML = customerNo;
          contact.onConnected(function () {
            console.log('contact.onConnected ', document.getElementById('callerNo').innerHTML);

            if (!contact.isSoftphoneCall(contact)) {
              let callRidersRecords = [];
              let callerNumber = document.getElementById('callerNo').innerHTML;
              callerNumber = callerNumber.substring(2);
              me.prepareNewPassengerTrip(callerNumber);
            }
          });
          contact.onAccepted(function () {
            {
              console.log('contact.onAccepted ', document.getElementById('callerNo').innerHTML, ' contact.isSoftphoneCall() ', contact.isSoftphoneCall());

              if (contact.isSoftphoneCall()) {
                let callRidersRecords = [];
                let callerNumber = document.getElementById('callerNo').innerHTML;
                callerNumber = callerNumber.substring(2);
                me.prepareNewPassengerTrip(callerNumber);
              }
            }
          });
        }));
      });
    }

  });

  _exports.default = _default;
});