define("adept-iq/serializers/tm-passenger", ["exports", "adept-iq/serializers/-tm-tripManagement"], function (_exports, _tmTripManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tmTripManagement.default.extend({
    normalizeResponse: function (store, primaryModelClass, payload) {
      const data = Ember.makeArray(payload.data);

      for (let i = 0; i < data.length; i++) {
        data[i].type = 'tm-passenger';
      }

      return payload;
    }
  });

  _exports.default = _default;
});