define("adept-iq/utils/search-data", ["exports", "adept-iq/config/environment", "adept-iq/config/api-urls", "ember-concurrency"], function (_exports, _environment, _apiUrls, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = searchData;
  let optionSearchPath;
  let optionSearchValue;
  let optionModelName;
  let ajax;

  function getRiderProfile() {
    return ajax.put(_apiUrls.API.tripManagementService.host + `/${optionModelName}?filter=${optionSearchPath}==` + optionSearchValue.toUpperCase(), {
      method: 'GET',
      contentType: "application/json"
    }).then(response => {
      return response.data;
    }).catch(function (error) {
      return [];
    });
  }

  search: (0, _emberConcurrency.task)(function* (term) {
    yield (0, _emberConcurrency.timeout)(50);
    let searchResults;
    searchResults = yield getRiderProfile(); //console.log('search searchResults ',searchResults);

    return searchResults;
  });

  function searchData(optionSearchPath, optionModelName, optionSearchValue) {
    this.optionSearchPath = optionSearchPath;
    this.optionSearchValue = optionSearchValue;
    this.optionModelName = optionModelName;
    this.ajax = ajax;
    this.search.perform().then(results => {
      return results;
    });
  }
});