define("adept-iq/pods/components/iq-widgets/vehicles-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'vehicleId',
        type: 'text',
        label: 'ID',
        valuePath: 'id',
        editable: false
      }]
    }, {
      title: 'Editable',
      fields: [{
        id: 'name',
        type: 'text',
        label: 'Vehicle Id',
        valuePath: 'name'
      }, {
        id: 'type',
        type: 'enum',
        label: 'Type',
        valuePath: 'vehicleType',
        cellDesc: 'vehicleType',
        editable: true,
        hidden: false,
        extra: {
          optionModelName: 'ss-vehicle-type',
          optionSearchPath: 'name'
        }
      }, {
        id: 'noOfAmbulatorySeats',
        type: 'text',
        label: 'Ambulatory Seats',
        valuePath: 'noOfAmbulatorySeats',
        editable: false,
        hidden: false
      }, {
        id: 'noOfWheelChairSeats',
        type: 'text',
        label: 'Wheelchair spaces',
        valuePath: 'noOfWheelChairSeats',
        editable: false,
        hidden: false
      }, {
        id: 'active',
        type: 'boolean',
        label: 'Active',
        valuePath: 'active',
        extra: {
          options: [{
            label: 'True',
            value: true
          }, {
            label: 'False',
            value: false
          }]
        }
      }]
    }, {
      title: 'Garage start Address',
      fields: [{
        id: 'originaddresses',
        type: 'locations',
        label: '',
        valuePath: 'startGarages',
        modelName: 'ss-location',
        isMultiType: true,
        extra: {
          aliasPath: 'alias',
          notesPath: 'notes',
          streetNrPath: 'streetNr',
          streetNamePath: 'streetName',
          localityPath: 'city',
          regionPath: 'county',
          subRegionPath: 'state',
          postalCodePath: 'postalCode',
          countryPath: 'country',
          defaultOptionsPath: 'riderAddresses'
        }
      }]
    }, {
      title: 'Garage end Address',
      fields: [{
        id: 'destinationaddresses',
        type: 'locations',
        label: 'Drop-off Address',
        valuePath: 'endGarages',
        modelName: 'ss-location',
        isMultiType: true,
        extra: {
          aliasPath: 'alias',
          notesPath: 'notes',
          streetNrPath: 'streetNr',
          streetNamePath: 'streetName',
          localityPath: 'city',
          regionPath: 'county',
          subRegionPath: 'state',
          postalCodePath: 'postalCode',
          countryPath: 'country',
          defaultOptionsPath: 'riderAddresses'
        }
      }]
    }, {
      title: 'Availabilities',
      fields: [{
        id: 'availabilities',
        type: 'scheduleavailabilities',
        label: '',
        valuePath: 'availability',
        modelName: 'ss-vehicle-availability',
        isMultiType: true,
        extra: {
          startTimePath: 'startTime',
          endTimePath: 'endTime',
          shiftStartPath: 'shiftStart',
          shiftEndPath: 'shiftEnd',
          sundayPath: 'sunday',
          mondayPath: 'monday',
          tuesdayPath: 'tuesday',
          wednesdayPath: 'wednesday',
          thursdayPath: 'thursday',
          fridayPath: 'friday',
          saturdayPath: 'saturday',
          shiftBreaksPath: 'shiftBreaks',
          breakTypeModelName: 'breakType',
          shiftBreakTypePath: 'breakType',
          aliasPath: 'alias',
          notesPath: 'notes',
          streetNrPath: 'streetNr',
          streetNamePath: 'streetName',
          localityPath: 'city',
          regionPath: 'county',
          subRegionPath: 'state',
          postalCodePath: 'postalCode',
          countryPath: 'country',
          addressvaluePath: 'address',
          addressmodelName: 'ss-location',
          breakPromisedStartPath: 'promisedStart',
          breakEndTimePath: 'endTime',
          breakDurationPath: 'plannedDuration'
        }
      }]
    }]
  };
  _exports.default = _default;
});