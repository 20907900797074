define("adept-iq/pods/components/iq-widgets/favorite-pickup-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/favorite-pickup-widget/config", "adept-iq/utils/populate-pickup", "adept-iq/utils/check-address-flags", "adept-iq/utils/check-address-overwrite", "adept-iq/utils/focus_column_widget_row", "adept-iq/utils/check-value"], function (_exports, _component, _config, _populatePickup, _checkAddressFlags, _checkAddressOverwrite, _focus_column_widget_row, _checkValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['favorite-pickup-widget'],
    editModal: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    riderAddresses: null,
    // need to sub-class this in specific widget
    config: _config.default,
    defaultIncludes: INCLUDES,

    init() {
      this._super(...arguments);

      let recordId = 1;
      this.get('widgetModelData').forEach(record => {
        record.id = "" + recordId + "";
        recordId++;

        if (!Ember.isBlank(record.pickup) && !Ember.isBlank(record.pickup.address)) {
          try {
            record.pickupFreeFormAddress = (0, _checkValue.default)(record.pickup.address.streetNr) + " " + (0, _checkValue.default)(record.pickup.address.streetName);
            record.pickupCity = (0, _checkValue.default)(record.pickup.address.region);
          } catch (e) {
            Ember.set(record, 'pickupFreeFormAddress', (0, _checkValue.default)(record.pickup.address.streetNr) + " " + (0, _checkValue.default)(record.pickup.address.streetName));
            Ember.set(record, 'pickupCity', (0, _checkValue.default)(record.pickup.address.region));
          }
        } else {
          try {
            record.pickupFreeFormAddress = "";
            record.pickupCity = "";
          } catch (e) {
            Ember.set(record, 'pickupFreeFormAddress', "");
            Ember.set(record, 'pickupCity', "");
          }
        }

        this.get('records').pushObject(record);
      });
      let me = this;
      Ember.run.later(function () {
        (0, _focus_column_widget_row.default)(me);
      }, 10);
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({});
      this.set('singleActions', []);
    },

    actions: {
      onRowRightClick(row, event) {},

      onRowClick(row, event) {
        let tripData = this.currentRecord;
        let pickupData = row.get('record');
        this.closeWidget();
        let overwriteAddress = true;
        let overwriteAddressPop = (0, _checkAddressOverwrite.default)(tripData.stopPoints[0].address, pickupData.pickup.address);

        if (overwriteAddressPop) {
          overwriteAddress = confirm('Do you want to overwrite Address');
        }

        if (overwriteAddress) {
          (0, _populatePickup.default)(tripData, pickupData, this.get('geocode'), this.get('store'), 'pickup');
          (0, _checkAddressFlags.default)(tripData);
        }
      }

    }
  });

  _exports.default = _default;
});