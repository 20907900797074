define("adept-iq/adapters/tm-rider-profile", ["exports", "adept-iq/adapters/-tm-tripManagement"], function (_exports, _tmTripManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tmTripManagement.default.extend({
    /*findRecord: function(store, type, id, snapshot) {
       console.log('snapshot ',snapshot);
          let url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
        let query = this.buildQuery(snapshot);
         url += ""+id;
        return this.ajax(url, 'GET', { data: query });
    },*/
    updateRecord(store, type, snapshot) {
      const data = {};
      const serializer = this.store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      const id = snapshot.id;
      const url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      return this.ajax(url, 'PUT', {
        data: data
      });
    },

    buildURL: function (type, id, record) {
      let url = this._super(type, id, record);

      url += "?filter=attributes/phone1/number==" + id;
      return url;
    }
  });

  _exports.default = _default;
});