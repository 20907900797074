define("adept-iq/pods/components/iq-widgets/routes-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/routes-widget/config", "adept-iq/utils/find-row-record", "adept-iq/config/environment", "adept-iq/config/api-urls"], function (_exports, _component, _config, _findRowRecord, _environment, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = ['clusters', 'vehicleRoutes', 'vehicleRoutes.driverShifts', 'vehicleRoutes.vehicle', 'vehicleRoutes.driverShifts.driver', 'vehicleRoutes.vehicle.vehicleType', 'driverBreaks', 'driverBreaks.driverBreakEvents', 'vehicleRoutes.vehicle.vehicleEvents'];

  var _default = _component.default.extend({
    classNames: ['routes-widget', 'data-test-routes-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    dragCoordinator: Ember.inject.service(),
    routeexecevent: Ember.inject.service(),
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspace: Ember.inject.service(),
    trip: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    editComponent: 'iq-widgets/routes-form-widget',
    vehicleEventComponent: 'iq-widgets/vehicle-event-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,

    sendMessagesToVehicle(record, status) {
      let assignedDriver = record.get('assignedDriver');
      let assignedVehicle = record.get('assignedVehicle');
      let message = ` ${status} Route action performed by dispatch`;
      let draftMessage = this.get('store').createRecord('tm-canned-message', {
        // TODO: should this be `driver.externalId`?
        driverId: assignedDriver.get('externalId'),
        vehicleId: assignedVehicle.get('name'),
        routeId: `${record.get('name')}`,
        body: message // TODO: how do we get the current route ID for a vehicle?
        // routeId: driver.get('routeId')

      });
      return draftMessage.save();
    },

    saveRouteStartRecordFunction(records) {
      let me = this;
      return new Ember.RSVP.Promise((resolve, reject) => {
        records.forEach(record => {
          let routeStartData = this.get('routeexecevent').routeStartData();
          let preparedRouteStartData = this.get('routeexecevent').prepareRouteStartData(routeStartData, record);
          const json = JSON.stringify(preparedRouteStartData);
          let session = this.get('session');
          return this.get('ajax').post(_apiUrls.API.avlmService.host + '/production/route-exec-event', {
            method: 'POST',
            contentType: "application/json",
            headers: {
              "Authorization": `Bearer ${session.data.authenticated.token}`
            },
            data: json
          }).then(response => {
            record.set('isForceDirty', false);
            record.set('id', response.data.id);
            me.get('activeContext').refreshTableContent('route');
            me.sendMessagesToVehicle(record, 'Start');
          }).catch(function (error) {
            reject(error);
          });
        });
      });
    },

    saveRouteEndRecordFunction(records) {
      let me = this;
      return new Ember.RSVP.Promise((resolve, reject) => {
        records.forEach(record => {
          let routeEndData = this.get('routeexecevent').routeEndData();
          let preparedRouteEndData = this.get('routeexecevent').prepareRouteEndData(routeEndData, record);
          const json = JSON.stringify(preparedRouteEndData);
          let session = this.get('session');
          return this.get('ajax').post(_apiUrls.API.avlmService.host + '/production/route-exec-event', {
            method: 'POST',
            contentType: "application/json",
            headers: {
              "Authorization": `Bearer ${session.data.authenticated.token}`
            },
            data: json
          }).then(response => {
            record.set('isForceDirty', false);
            record.set('id', response.data.id);
            me.get('activeContext').refreshTableContent('route');
            me.sendMessagesToVehicle(record, 'End');
          }).catch(function (error) {
            reject(error);
          });
        });
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('tableActions', [{
        name: 'New Route',
        action: function () {
          let newModel = this.get('store').createRecord(this.config.modelName);
          let plannedStartTime = new Date(this.workspace.startDate);
          let plannedEndTime = new Date(this.workspace.startDate);
          plannedStartTime.setHours(6);
          plannedEndTime.setHours(18);
          newModel.set('routeDate', new Date(this.workspace.startDate));
          newModel.set('plannedStartTime', plannedStartTime);
          newModel.set('plannedEndTime', plannedEndTime);
          this.get('records').pushObject(newModel);
          let editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, [newModel], this.get('saveRecordFunction').bind(this));
        }.bind(this)
      }]);
      this.set('singleActions', [{
        name: 'Send Message',
        action: route => {
          this.sendMessagesToRoutes([route]);
        }
      }, {
        name: 'Start Route',
        model: 'vehicle-event',
        action: model => {
          model.set('odometer', '');
          model.set('timestamp', new Date());
          let vehicleEventComponent = this.get('vehicleEventComponent');
          this.get('editModal').open(vehicleEventComponent, [model], this.get('saveRouteStartRecordFunction').bind(this));
        }
      }, {
        name: 'End Route',
        action: model => {
          model.set('odometer', '');
          model.set('timestamp', new Date());
          let vehicleEventComponent = this.get('vehicleEventComponent');
          this.get('editModal').open(vehicleEventComponent, [model], this.get('saveRouteEndRecordFunction').bind(this));
        }
      }, {
        name: 'Activity Log',
        action: route => {
          let routeName = route.get('name');
          this.get('workspace').pushState('routeActivityLog', {
            displayName: `Route #${routeName} Activity Log`,
            route
          });
        }
      }, {
        name: 'Delete Route',
        model: 'vehicle-event',
        action: model => {
          this.deleteRoute(model);
        }
      }]);
      this.set('bulkActions', [{
        name: 'Activity Log',
        action: () =>
        /* model */
        {}
      }, {
        name: 'Bulk Message',
        action: routes => {
          this.sendMessagesToRoutes(routes);
        }
      }]);
    },

    dragRowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),

    dragStart(event) {
      let row = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('dragRowGroup'));
      if (Ember.isBlank(row)) return;
      let dragId = Ember.guidFor(row);
      this.get('dragCoordinator').setSourceRow(dragId, row, 'routes');
      event.dataTransfer.setData('text', dragId);
    },

    dragEnd() {
      this.get('dragCoordinator').clearSourceRows();
    },

    // saveRecordFunction(/* records */) {
    //   let vehicleEvent = this.get('store').createRecord('vehicle-event', {
    //     type: 'pullout',
    //     timestamp: '',
    //     lat: 0.0,
    //     lng: 0.0,
    //     heading: 0,
    //     speed: 0,
    //     odometer: 5467567775,
    //     vehicle: '10'
    //   });
    //   return vehicleEvent.save();
    // },
    // TODO: saveRecordFunction shall be moved to a service later on
    // This save is specific to saving drivers and all related models.
    saveRecordFunction(records) {
      function saveSelectedVehicle(route, vehicleRoute) {
        if (route.get('selectedVehicle') !== undefined) {
          vehicleRoute.set('route', route);
          vehicleRoute.set('vehicle', route.get('selectedVehicle'));
          vehicleRoute.set('plannedStartTime', new Date(route.get('plannedStartTime')));
          vehicleRoute.set('plannedEndTime', new Date(route.get('plannedEndTime')));
          return Ember.RSVP.Promise.all([vehicleRoute.save()]);
        } else {
          return Ember.RSVP.Promise.all([]);
        }
      }

      function saveSelectedDriver(route, driverShift) {
        if (route.get('selectedDriver') !== undefined) {
          driverShift.set('route', route);
          driverShift.set('driver', route.get('selectedDriver'));
          driverShift.set('plannedStartTime', new Date(route.get('plannedStartTime')));
          driverShift.set('plannedEndTime', new Date(route.get('plannedEndTime')));
          return Ember.RSVP.Promise.all([driverShift.save()]);
        } else {
          return Ember.RSVP.Promise.all([]);
        }
      }

      return new Ember.RSVP.Promise(() => {
        records.forEach(record => {
          let schedule = this.store.peekAll('schedule');
          let provider = this.store.peekAll('provider');
          record.set('schedule', schedule.get('firstObject'));
          record.set('provider', provider.get('firstObject'));
          record.save().then(() => {
            let vehicleRoute = this.get('store').createRecord('vehicleRoute');
            saveSelectedVehicle(record, vehicleRoute).then(results => {
              let driverShift = this.get('store').createRecord('driverShift');

              if (results.length > 0) {
                driverShift.set('vehicleRoute', results[0]);
                saveSelectedDriver(record, driverShift).then(() => {
                  record.set('isForceDirty', false);
                  this.get('activeContext').refreshTableContent(this.get('config.modelName'));
                });
              } else {
                record.set('isForceDirty', false);
                this.get('activeContext').refreshTableContent(this.get('config.modelName'));
              }
            });
          });
        });
      });
    },

    //singleActions: computed('table.rows.@each.{selected,status}', function() {
    //  let routeStartAction = this.get('routeStartAction');
    //  let routeEndAction = this.get('routeEndAction');
    //  let routeSendMessageAction = this.get('routeSendMessageAction');
    //  let routeActivityAction = this.get('routeActivityAction');
    //  let routeDeleteAction = this.get('routeDeleteAction');
    //  //console.log('routeActivityAction ',routeActivityAction);
    //  let row = this.get('table.rows').findBy('selected', true);
    //  if (!row) return [];
    //  let isRouteContainsTrips = true;
    //  if(row.get('clusters') === null ||
    //    row.get('clusters') === undefined ||
    //    row.get('clusters').length === 0) {
    //      isRouteContainsTrips = false;
    //  }
    //  if(row.get('status'))
    //  switch(row.get('status')) {
    //    case 'Not Started': {
    //      if(isRouteContainsTrips) {
    //        return [routeStartAction,routeActivityAction];
    //      } else {
    //        return [routeStartAction,routeActivityAction,routeDeleteAction];
    //      }
    //    }
    //    case 'Signed On':
    //      if(isRouteContainsTrips) {
    //        return [routeStartAction,routeSendMessageAction,routeActivityAction];
    //      } else {
    //        return [routeStartAction,routeSendMessageAction,routeActivityAction,routeDeleteAction];
    //      }
    //   case 'Started':
    //      //TODO All stops are completed check.
    //      if(isRouteContainsTrips) {
    //        return [routeEndAction,routeActivityAction];
    //      } else {
    //        return [routeEndAction,routeActivityAction,routeDeleteAction];
    //      }
    //    case 'Route Ended':
    //    case 'Signed Off':
    //      return [routeActivityAction];
    //    default:
    //      if(isRouteContainsTrips) {
    //        return [routeActivityAction];
    //      } else {
    //        return [routeActivityAction,routeDeleteAction];
    //      }
    //  }
    //}),
    sendMessagesToRoutes(routes) {
      let draftMessages = routes.map(route => {
        return this.get('store').createRecord('tm-canned-message', {
          // TODO: should this be `assignedDriver.externalId`?
          driverId: route.get('assignedDriver.id'),
          routeId: route.get('id'),
          vehicleId: route.get('assignedVehicle.id')
        });
      });
      this.get('workspace').pushState('addNewMessage', {
        draftMessages
      });
    },

    deleteRoute(route) {
      let isCompleted = route.get('isCompleted');
      let isEmptyRoute = route.get('isEmptyRoute');

      if (isCompleted || !isEmptyRoute) {
        this.get('errorMessage').pushError({
          detail: 'Cannot delete trips that is not empty'
        });
      } else {
        // delete endpoint does not exist in dispatch yet.
        route.deleteRecord();
        route.save();
      }
    }

  });

  _exports.default = _default;
});