define("adept-iq/helpers/titleandkey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.titleandkey = titleandkey;
  _exports.default = void 0;

  function titleandkey(args) {
    const s = args[0] + " " + args[1];
    return s;
  }

  var _default = Ember.Helper.helper(titleandkey);

  _exports.default = _default;
});