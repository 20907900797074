define("adept-iq/models/attributes", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    provider: attr(),
    name: attr(),
    description: attr(),
    type: attr(),
    displayName: attr(),
    datum: attr()
  });

  _exports.default = _default;
});