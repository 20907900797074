define("adept-iq/models/account", ["exports", "ember-data", "ember-data/relationships", "adept-iq/config/environment"], function (_exports, _emberData, _relationships, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  function formatTextExtension(className, value) {
    return {
      className: className,
      value: value
    };
  }

  var _default = Model.extend({
    effectiveDTM: attr('date'),
    expiryDTM: attr('date'),
    location: attr(),
    acctName: attr('string'),
    acctCode: attr('string'),
    acctCardNr: attr('string'),
    city: attr('string'),
    trips: (0, _relationships.hasMany)('trip'),
    requestTime: attr('date'),
    phoneNr: attr('string'),
    balance: attr('string'),
    creditLimit: attr('string'),
    contactName: attr('string'),
    driverNotes: attr('string'),
    operatorNotes: attr('string'),
    tripsRemaining: attr('number'),
    tripLimit: attr('string'),
    defaultFixedPriceTripsId: attr(),
    defaultFavoritePickupId: attr(),
    defaultFavoriteTripId: attr(),
    favoriteTrips: (0, _relationships.hasMany)('favorite-trip'),
    favoritePickups: Ember.computed('favoriteTrips', function () {
      if (!Ember.isEmpty(this.get('favoriteTrips'))) {
        return this.get('favoriteTrips').filterBy('priceType', 'normal');
      } else {
        return null;
      }
    }),
    fixedPriceTrips: Ember.computed('favoriteTrips', function () {
      //console.log('defaultFavoritePickup favoritePickups',this.get('favoritePickups'));
      //console.log('defaultFavoritePickup defaultFavoritePickupId',this.get('defaultFavoritePickupId'));
      if (!Ember.isEmpty(this.get('favoriteTrips'))) {
        return this.get('favoriteTrips').filterBy('priceType', 'fixedPrice');
      } else {
        return null;
      }
    }),
    callLines: (0, _relationships.hasMany)('call-line'),
    requiredAttributes: attr(),
    requiredAttributesList: Ember.computed('requiredAttributes.[]', function () {
      return Array.prototype.map.call(this.get('requiredAttributes'), attribute => attribute.type).toString();
    }),
    defaultFixedPriceTrip: Ember.computed('favoriteTrips@each', 'defaultFavoriteTripId', function () {
      //console.log('defaultFixedPriceTrip fixedPriceTrips',this.get('fixedPriceTrips'));
      //console.log('defaultFixedPriceTrip defaultFixedPriceTripsId',this.get('defaultFixedPriceTripsId'));
      if (!Ember.isEmpty(this.get('favoriteTrips')) && !Ember.isBlank(this.get('defaultFavoriteTripId'))) {
        //console.log('isndie defaultFixedPriceTripsId',this.get('fixedPriceTrips').filterBy('id', this.get('defaultFixedPriceTripsId')));
        return this.get('favoriteTrips').findBy('id', this.get('defaultFavoriteTripId'));
      } else {
        return null;
      }
    }),
    defaultFavoritePickup: Ember.computed('favoriteTrips@each', 'defaultFavoriteTripId', function () {
      //console.log('defaultFavoritePickup favoritePickups',this.get('favoritePickups'));
      //console.log('defaultFavoritePickup defaultFavoritePickupId',this.get('defaultFavoritePickupId'));
      if (!Ember.isEmpty(this.get('favoriteTrips')) && !Ember.isBlank(this.get('defaultFavoriteTripId'))) {
        return this.get('favoriteTrips').findBy('id', this.get('defaultFavoriteTripId'));
      } else {
        return null;
      }
    }),
    formattedFavoriteTripsPickup: Ember.computed('defaultFavoriteTrip@each', function () {
      let addresses = [];

      if (!Ember.isEmpty(this.get('favoriteTrips'))) {
        this.get('favoriteTrips').forEach(function (item) {
          const pickupAddress = item.get('pickAddress');

          if (pickupAddress.get('streetNr') !== undefined && pickupAddress.get('streetName') !== undefined) {
            addresses.push(formatTextExtension('valueItem', `${pickupAddress.get('streetNr')} ${pickupAddress.get('streetName')} ${pickupAddress.get('region')}`));
          }
        });
      }

      return {
        values: addresses
      };
    }),
    accountAddresses: Ember.computed('favoritePickups@each', function () {
      let addresses = [];

      if (!Ember.isEmpty(this.get('favoritePickups'))) {
        this.get('favoritePickups').forEach(function (item) {
          addresses.push(item.get('pickAddress'));
        });
      }

      return addresses;
    })
    /*,
    workspacetrips: computed('trips@each', function() {
      if(!isEmpty(this.get('trips'))) {
        return this.get('trips').filter(function(item) {
           return (ENV.workspaceContext.startDate < item.get('requestTime') &&
                    (ENV.workspaceContext.endDate > item.get('requestTime')));
      });
    } else {
      return [];
    }
    }),
    sortedtrips :computed('workspacetrips.[]',function()
    {
    return this.get('workspacetrips').sortBy('requestTime');
    }),
    latesttripDate: computed('sortedtrips.[]', function() {
    if(!isEmpty(this.get('sortedtrips'))) {
      return this.get('sortedtrips').lastObject.get('requestTime');
    }
    return null;
    }),
    firsttripDate: computed('sortedtrips.[]', function() {
    if(!isEmpty(this.get('sortedtrips'))) {
      return this.get('sortedtrips').firstObject.get('requestTime');
    }
    return null;
    })  */

  });

  _exports.default = _default;
});