define("adept-iq/services/workspace", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_SIZE = 'small';
  const DEFAULT_TILE_SIZE = 100;
  const DEFAULT_TILE_SPACING = 4;

  const logger = () => null;

  const STATE_MACHINE = {
    defaultStateId: 'default',
    // TODO: relocate hooks to separated modules
    states: [{
      id: 'default',
      hooks: []
    }, {
      id: 'editWorkspace',
      sideDrawerComponent: 'side-drawer/add-widget',
      hooks: [{
        from: '*',

        action() {
          // this allows us to roll back on cancel
          this.get('dashboard').makeWidgetsSavepoint(); // set tooltip

          this.get('tooltip').setProperties({
            tip: 'Configuration mode:',
            primaryActionText: 'Exit Configuration',
            secondaryActionText: 'Undo Changes',
            primaryAction: () => {
              this.popState('editWorkspace');
              this.get('errorMessage').pushInfo({
                detail: 'In order to retain the workspace changes, you must save the workspace.'
              });
            },
            secondaryAction: () => {
              this.get('dashboard').rollbackWidgets(); // Allow time for the GridStack instance to update the grid with the
              // rolled back widget configs before popping edit workspace state.

              setTimeout(() => {
                this.popState('editWorkspace');
              }, 15);
            }
          });
        }

      }, {
        to: '*',

        action() {
          this.get('tooltip').reset();
        }

      }]
    }, {
      id: 'editRecords',
      isSideDrawerVisible: true,
      hooks: []
    }, {
      id: 'filterColumnWidget',
      sideDrawerComponent: 'side-drawer/filter-settings',
      hooks: []
    }, {
      id: 'gaugeWidget',
      sideDrawerComponent: 'side-drawer/gauge-chart-settings',
      hooks: []
    }, {
      id: 'donutChartWidget',
      sideDrawerComponent: 'side-drawer/donut-chart-settings',
      hooks: []
    }, {
      id: 'barChartWidget',
      sideDrawerComponent: 'side-drawer/bar-chart-settings',
      hooks: []
    }, {
      id: 'boxWidget',
      sideDrawerComponent: 'side-drawer/box-widget-settings',
      hooks: []
    }, {
      // TODO: is this a real state?
      id: 'metricsColumnWidget',
      isSideDrawerVisible: true,
      hooks: []
    }, {
      id: 'filterMapWidget',
      sideDrawerComponent: 'side-drawer/map-filter',
      hooks: []
    }, {
      id: 'addNewMessage',
      sideDrawerComponent: 'side-drawer/add-new-message',
      hooks: [{
        to: '*',

        action() {
          this.get('tooltip').reset();
        }

      }]
    }, {
      id: 'openWorkspace',
      hooks: [{
        to: '*',

        action() {
          this.get('tooltip').reset();
        }

      }]
    }, {
      id: 'saveWorkspaceAs',
      hooks: [{
        to: '*',

        action() {
          this.get('tooltip').reset();
        }

      }]
    }, {
      id: 'deleteWorkspace',
      hooks: [{
        from: '*',

        action() {
          this.get('tooltip').setProperties({
            tip: 'Permanently delete workspace?',
            primaryActionText: 'Delete Workspace',
            secondaryActionText: 'Keep Workspace',
            primaryAction: () => {
              this.popState('deleteWorkspace').then(() => {
                return this.get('dashboardModel').destroyRecord().then(() => {
                  // must clear this before transitioning to index
                  localStorage.setItem('lastDashboardId', null);
                  this.get('router').transitionTo('index');
                });
              });
            },
            secondaryAction: () => {
              this.popState('deleteWorkspace');
            }
          });
        }

      }, {
        to: '*',

        action() {
          this.get('tooltip').reset();
        }

      }]
    }, {
      id: 'closeSchedule',
      hooks: []
    }, {
      id: 'routeActivityLog',
      sideDrawerComponent: 'side-drawer/route-activity-log'
    }, {
      id: 'tripActivityLog',
      sideDrawerComponent: 'side-drawer/trip-activity-log'
    }, {
      id: 'editSystemConfiguration',
      sideDrawerComponent: 'side-drawer/system-config'
    }]
  };

  var _default = Ember.Service.extend({
    activeContext: Ember.inject.service(),
    router: Ember.inject.service(),
    scheduleGeneration: Ember.inject.service(),
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    isLightMode: false,
    size: DEFAULT_SIZE,
    tileSize: DEFAULT_TILE_SIZE,
    tileSpacing: DEFAULT_TILE_SPACING,
    isDragging: false,
    isResizing: false,
    contentHeight: null,
    contentWidth: null,
    stack: null,
    hasLockedSchedule: false,
    configPermissions: null,
    _dashboardModel: null,
    _dashboardInstance: null,
    isSideDrawerVisible: Ember.computed.alias('stack.lastObject.isSideDrawerVisible'),
    sideDrawerComponent: Ember.computed.readOnly('stack.lastObject.sideDrawerComponent'),
    topState: Ember.computed.alias('stack.lastObject.state'),
    topStateDisplayName: Ember.computed.alias('stack.lastObject.options.displayName'),
    topOptions: Ember.computed.alias('stack.lastObject.options'),
    stackDepth: Ember.computed.alias('stack.length'),
    isEditingTimeline: false,
    isEditing: Ember.computed.equal('topState', 'editWorkspace'),
    isGlobalSpinnerVisible: Ember.computed.alias('scheduleGeneration.isRunning'),
    isDashboardPickerOpen: Ember.computed.equal('topState', 'openWorkspace'),
    isDashboardSaveAsOpen: Ember.computed.equal('topState', 'saveWorkspaceAs'),
    isClosingSchedule: Ember.computed.equal('topState', 'closeSchedule'),
    gridWidth: Ember.computed('contentWidth', 'tileSize', function () {
      return Math.floor(this.get('contentWidth') / this.get('tileSize'));
    }),
    gridHeight: Ember.computed('contentHeight', 'tileSize', function () {
      return Math.floor(this.get('contentHeight') / this.get('tileSize'));
    }),
    onDatesChange: Ember.observer('endDate', function () {
      this.validateTimeLineForLockedSchedule();
    }),

    validateTimeLineForLockedSchedule() {
      /*eslint-disable */
      const me = this;
      /*eslint-enable */

      const startDate = (0, _moment.default)(this.get('startDate'));
      const endDate = (0, _moment.default)(this.get('endDate')); //Ankur - Commented Currently Transit specific code

      /*me.store.findAll('schedule', { reload: true })
        .then(function(schedules) {
          schedules.forEach(schedule => {
            if (schedule.get('locked')) {
              const scheduleStart = moment(schedule.startTime);
              const scheduleEnd = moment(schedule.endTime);
               if (startDate.isBetween(scheduleStart, scheduleEnd) ||
                  endDate.isBetween(scheduleStart, scheduleEnd) ||
                  scheduleStart.isBetween(startDate, endDate) ||
                  scheduleEnd.isBetween(startDate, endDate)) {
                me.get('errorMessage').pushInfo({ detail: 'The current timeline contains at least one day with a closed schedule. In order to edit data, you must be viewing day(s) where the schedule is not closed.'});
              }
            }
          });
        });*/
    },

    isTimelineSpanMultipleDays: Ember.computed('startDate', 'endDate', function () {
      const startDate = (0, _moment.default)(this.get('startDate'));
      const endDate = (0, _moment.default)(this.get('endDate'));
      const singleDate = (0, _moment.default)(startDate);
      let isMultipleDays = false;

      if (!(startDate.isSameOrAfter(singleDate.startOf('day')) && endDate.isSameOrBefore(singleDate.endOf('day')))) {
        isMultipleDays = true;
      }

      return isMultipleDays;
    }),
    startDate: Ember.computed('dashboard.{startDate,referenceDate}', {
      get()
      /* key */
      {
        const startDate = this.get('dashboard.startDate');
        const referenceDate = this.get('dashboard.referenceDate');
        return (0, _moment.default)().startOf('day').add(startDate - referenceDate, 'ms').toDate();
      },

      set(key, value) {
        const referenceDate = (0, _moment.default)().startOf('day').toDate();
        this.set('dashboard.startDate', value);
        this.set('dashboard.referenceDate', referenceDate);
        return value;
      }

    }),
    endDate: Ember.computed('dashboard.{endDate,referenceDate}', {
      get()
      /* key */
      {
        const endDate = this.get('dashboard.endDate');
        const referenceDate = this.get('dashboard.referenceDate');
        return (0, _moment.default)().startOf('day').add(endDate - referenceDate, 'ms').toDate();
      },

      set(key, value) {
        const referenceDate = (0, _moment.default)().startOf('day').toDate();
        this.set('dashboard.endDate', value);
        this.set('dashboard.referenceDate', referenceDate);
        return value;
      }

    }),
    // for backward compatibility; it's better to use `dashboardInstance` or
    // `dashboardModel` expclitily
    dashboard: Ember.computed.alias('dashboardInstance'),
    // this is a DashboardInstance class; it is decoupled from the model.
    // you can replace this to update local state without persisting
    dashboardInstance: Ember.computed('_dashboardInstance', {
      get() {
        return this.get('_dashboardInstance');
      },

      set(key, value) {
        (true && !(value.constructor.modelName !== 'dashboard') && Ember.assert('cannot set workspace dashboard instance to be dashboard model', value.constructor.modelName !== 'dashboard'));
        this.set('_dashboardInstance', value);
        return value;
      }

    }),
    dashboardModel: Ember.computed('_dashboardModel', {
      get() {
        return this.get('_dashboardModel');
      },

      set(key, value) {
        this.set('_dashboardModel', value);

        if (value) {
          this.resetForDashboardChange();
          this.set('dashboardInstance', value.makeInstance());
        } else {
          this.set('dashboardInstance', null);
        }

        return value;
      }

    }),

    init() {
      this._super(...arguments);

      this.set('stack', ['default']);
      this.validateTimeLineForLockedSchedule();
    },

    reset() {
      this.setProperties({
        isLightMode: false,
        size: DEFAULT_SIZE,
        tileSize: DEFAULT_TILE_SIZE
      });
      this.resetForDashboardChange();
    },

    resetForDashboardChange() {
      this.setProperties({
        isDragging: false,
        isResizing: false
      });
      this.set('stack', [{
        state: 'default'
      }]);
    },

    pushState(newStateId, options = {}) {
      const previousStateId = this.get('topState');
      const previousState = STATE_MACHINE.states.findBy('id', previousStateId);
      logger('covering', previousStateId); // TODO: allow for promise actions

      if (previousState) {
        Ember.makeArray(previousState.hooks).filter(({
          to
        }) => {
          return to === newStateId || to === '*';
        }).forEach(({
          action
        }) => {
          action.call(this, this);
        });
      } // TODO: check if we are allowed to push state based on top state


      const newState = STATE_MACHINE.states.findBy('id', newStateId);
      /*eslint-disable */

      if (!newState) throw `unknown state with id '${newStateId}'`;
      /*eslint-enable */

      logger('pushing', newStateId);
      Ember.makeArray(newState.hooks).filter(({
        from
      }) => {
        return from === previousStateId || from === '*';
      }).forEach(({
        action
      }) => {
        action.call(this, this);
      });
      this.get('stack').pushObject({
        state: newStateId,
        isSideDrawerVisible: newState.isSideDrawerVisible,
        sideDrawerComponent: newState.sideDrawerComponent,
        options
      });
      return Ember.RSVP.Promise.resolve();
    },

    popState(stateId) {
      if (this.get('stackDepth') === 0) {
        /*eslint-disable */
        throw `cannot pop state: stack is empty`;
        /*eslint-enable */
      }

      const previousStateId = this.get('topState');
      const previousState = STATE_MACHINE.states.findBy('id', previousStateId);

      if (Ember.isPresent(stateId) && stateId !== previousStateId) {
        /*eslint-disable */
        throw `cannot pop state ${stateId}: top state is ${previousStateId}`;
        /*eslint-enable */
      }

      logger('popping', previousStateId);
      this.get('stack').popObject();
      const newStateId = this.get('topState');
      const newState = STATE_MACHINE.states.findBy('id', newStateId); // TODO: allow for promise actions

      Ember.makeArray(previousState.hooks).filter(({
        to
      }) => {
        return to === newStateId || to === '*';
      }).forEach(({
        action
      }) => {
        action.call(this, this);
      });
      logger('uncovering', newStateId); // TODO: allow for promise actions

      if (newState) {
        Ember.makeArray(newState.hooks).filter(({
          from
        }) => {
          return from === previousStateId || from === '*';
        }).forEach(({
          action
        }) => {
          action.call(this, this);
        });
      }

      return Ember.RSVP.Promise.resolve();
    }

  });

  _exports.default = _default;
});