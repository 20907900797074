define("adept-iq/models/iq-trip", ["exports", "ember-data/model", "ember-data/relationships", "adept-iq/mixins/otp-label", "adept-iq/mixins/status-value", "lodash"], function (_exports, _model, _relationships, _otpLabel, _statusValue, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    sample
  } = _lodash.default;

  var _default = _model.default.extend(_statusValue.default, {
    trip: (0, _relationships.belongsTo)('trip', {
      inverse: 'iqTrip'
    }),
    tripExecEvent: (0, _relationships.belongsTo)('trip-exec-event', {
      inverse: 'iqTrip'
    }),
    otp: Ember.computed('', function () {
      return sample(['O', 'L', 'D', 'E']);
    }),
    otpValue: Ember.computed('', function () {
      return sample(['O', 'L', 'D', 'E']);
    }),
    bookingType: Ember.computed('trip.bookingType', function () {
      return this.get('trip.bookingType');
    }),
    paymentype: Ember.computed('', function () {
      return this.get('trip.paymentType');
    }),
    status: Ember.computed('trip.content.tripStatus', 'tripExecEvent.content.status', function () {
      let tripExecEvent = this.get('tripExecEvent.content');

      if (this.get('trip.tripStatus') === 'completed' || this.get('trip.tripStatus') === 'cancelled') {
        return this.get('trip.tripStatus');
      }

      if (tripExecEvent) {
        return tripExecEvent.get('status');
      }

      return this.get('trip.tripStatus');
    }),
    drivedStatus: Ember.computed('trip.tripStatus', 'tripExecEvent.content.status', function () {
      let tripExecEvent = this.get('tripExecEvent.content');

      if (this.get('trip.tripStatus') === 'completed' || this.get('trip.tripStatus') === 'cancelled') {
        return this.get('trip.ripStatus');
      }

      if (tripExecEvent) {
        return tripExecEvent.get('status');
      }

      return this.get('trip.tripStatus');
    }),
    driver: Ember.computed('tripExecEvent.content.driver', function () {
      let tripExecEvent = this.get('tripExecEvent.content');

      if (tripExecEvent) {
        return tripExecEvent.get('driver');
      }

      return null;
    }),
    vehicle: Ember.computed('tripExecEvent.content.vehicle', function () {
      let tripExecEvent = this.get('tripExecEvent.content');

      if (tripExecEvent) {
        return tripExecEvent.get('vehicle');
      }

      return null;
    }),
    statusValue: Ember.computed.alias('status'),
    zone: Ember.computed.alias('trip.zone'),
    lat: Ember.computed.alias('trip.lat'),
    lng: Ember.computed.alias('trip.lng')
  });

  _exports.default = _default;
});