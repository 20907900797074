define("adept-iq/pods/components/form-components/populatecli/component", ["exports", "ember-concurrency", "adept-iq/utils/check-address-flags", "adept-iq/utils/populate-account", "adept-iq/utils/populate-call-line", "adept-iq/config/environment", "adept-iq/config/api-urls"], function (_exports, _emberConcurrency, _checkAddressFlags, _populateAccount, _populateCallLine, _environment, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-populatecli'],
    trip: Ember.inject.service(),
    geocode: Ember.inject.service(),
    store: Ember.inject.service(),
    editModal: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    searchservice: Ember.inject.service(),
    ajax: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    session: Ember.inject.service(),
    isAccountPickups: false,
    isAccountFixedPrice: false,
    phoneSearchValue: null,
    accountSearchPath: _apiUrls.API_SUB_URL.accountSearchPath,
    editComponent: 'iq-widgets/passengers-form-widget',
    newTripComponent: 'iq-widgets/new-trip-form-widget',
    passengerTripComponent: 'iq-widgets/new-trip-passenger-form-widget',

    init() {
      this._super(...arguments);
    },

    prepareRiderData() {},

    search: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(50);
      let searchResults;
      searchResults = yield this.getRiderProfile(this.get('phoneSearchPath'), this.get('phoneSearchValue'));
      return searchResults;
    }),

    getRiderProfile(phoneSearchPath, phoneSearchValue) {
      return this.get('ajax').put(_apiUrls.API.tripManagementService.host + `/rider-profile?filter=${phoneSearchPath}==` + phoneSearchValue, {
        method: 'GET',
        contentType: "application/json"
      }).then(response => {
        return response.data;
      }).catch(function () {
        return [];
      });
    },

    actions: {
      populateCliInfo() {
        let phoneNr = document.getElementById('callerNo').innerHTML;
        let tripRecord = this.get('record');
        tripRecord.set('passengers.0.phone1.number', phoneNr); //trip.passengers[0].name =  rider.name;
        //trip.passengers[0].firstName =  rider.name;

        this.set('phoneSearchValue', phoneNr);
        this.set('editModal.errors', []);
        Ember.run(() => {
          this.get('search').perform().then(results => {
            //console.log('rider.callLines',rider.callLines);
            if (!Ember.isEmpty(results)) {
              let searchRecord = results.firstObject;
              let widgetModelData = results; //console.log('widgetModelData ',widgetModelData);

              tripRecord.set('editmodelcontext.callerTrips', widgetModelData);

              if (widgetModelData && widgetModelData.length > 1) {
                this.set('editmodalcontext.showWidgetModal', true);
                this.set('editmodalcontext.widgetModelData', widgetModelData);
                this.set('editmodalcontext.modelWidgetPath', 'rider-profile-widget');
                this.set('editmodalcontext.record', tripRecord);
              } else if (widgetModelData && widgetModelData.length === 1) {
                (0, _populateCallLine.default)(tripRecord, results.firstObject.attributes, this.get('geocode'), this.get('store'));
                (0, _populateAccount.default)(tripRecord, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), results.firstObject.attributes.account.accountId, true, phoneNr);
              }

              (0, _checkAddressFlags.default)(tripRecord);
            } else {
              //console.log('parentValuePath',this.get('parentValuePath') );
              this.get('editModal.errors').pushObject('No result found');
            }
          });
        });
      }

    }
  });

  _exports.default = _default;
});