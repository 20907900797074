define("adept-iq/topic-transforms/alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(store, payload
  /*, logger*/
  ) {
    payload.data.type = 'alert';

    if (!payload.data.relationships) {
      payload.data.relationships = {};
    }

    if (!payload.included) {
      payload.included = [];
    }

    if (payload.data.attributes.providerId) {
      payload.data.relationships.provider = {
        data: {
          type: 'provider',
          id: payload.data.attributes.providerId
        }
      };
      payload.included.push({
        id: payload.data.attributes.providerId,
        type: 'provider'
      });
    }

    return payload;
  }
});