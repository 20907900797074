define("adept-iq/serializers/avlm-driver", ["exports", "adept-iq/serializers/-avlm-avlmService", "adept-iq/mixins/iq-model-identification"], function (_exports, _avlmAvlmService, _iqModelIdentification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlmAvlmService.default.extend({
    iqModelName: 'iq-driver',
    iqModelForeignKey: 'schedulingDriverId',
    // doesn't currently exist
    iqModelRelationship: 'iqDriver'
  });

  _exports.default = _default;
});