define("adept-iq/pods/components/iq-widgets/drivers-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/drivers-form-widget/config", "moment"], function (_exports, _component, _config, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    errorMessage: Ember.inject.service(),

    validateRecords() {
      let valid = true;
      let records = this.get('editableRecords');
      this.set('editModal.errors', []);
      records.forEach(record => {
        const availabilities = record.availability;

        if (record.driverId === undefined || record.driverId.trim().length < 1) {
          this.get('editModal.errors').pushObject('Invalid Driver Id');
          this.get('errorMessage').pushWarning({
            detail: 'Invalid Driver Id'
          });
        }

        availabilities.forEach(availability => {
          const startTime = (0, _moment.default)(availability.startTime);
          const endTime = (0, _moment.default)(availability.endTime);
          const shiftStart = (0, _moment.default)(availability.shiftStart, 'HH:mm:ss');
          const shiftEnd = (0, _moment.default)(availability.shiftEnd, 'HH:mm:ss');

          if (endTime.isBefore(startTime)) {
            valid = false;
            this.get('editModal.errors').pushObject('Effective end date cannot be before effective start date');
          }

          if (shiftEnd.isBefore(shiftStart)) {
            valid = false;
            this.get('editModal.errors').pushObject('Shift end cannot be before shift start');
          }
        });
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        let lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          let records = this.get('editableRecords');
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(({
            record,
            properties
          }) => {
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      onApplyClick() {
        if (this.validateRecords()) {
          this.get('service').apply();
        }
      } // onCellValueChange(record, valuePath, value, options) {
      //   if (valuePath === 'vehicles.firstObject.name') {
      //     let vehicle = record.get('vehicles.firstObject');
      //     record.vehicles.removeObject(vehicle);
      //   }
      //   else {
      //     this.get('service').setRecordValue(record, valuePath, value, options);
      //   }
      // }


    }
  });

  _exports.default = _default;
});