define("adept-iq/services/searchservice", ["exports", "ember-concurrency", "adept-iq/config/environment", "adept-iq/config/api-urls"], function (_exports, _emberConcurrency, _environment, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    optionSearchPath: null,
    optionModelName: null,
    optionSearchValue: null,
    modalData: null,
    // eslint-disable-next-line no-unused-vars
    search: (0, _emberConcurrency.task)(function* (term) {
      //console.log('search value ', this.get('value'));
      yield (0, _emberConcurrency.timeout)(50);
      let searchResults;
      searchResults = yield this.getRiderProfile(this.get('optionSearchPath'), this.get('optionModelName'), this.get('optionSearchValue'));
      return searchResults;
    }),

    getRiderProfile(optionSearchPath, optionSearchValue) {
      return this.get('ajax').put(_apiUrls.API.tripManagementService.host + `/rider-profile?filter=${optionSearchPath}==` + optionSearchValue.toUpperCase(), {
        method: 'GET',
        contentType: "application/json"
      }).then(response => {
        return response.data;
      }).catch(function () {
        return [];
      });
    },

    searchRiderProfile(optionSearchPath, optionSearchValue) {
      this.set('optionSearchPath', optionSearchPath);
      this.set('optionModelName', 'rider-profile');
      this.set('optionSearchValue', optionSearchValue);
      this.get('search').perform().then(results => {
        return results;
      });
    }

  });

  _exports.default = _default;
});