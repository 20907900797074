define("adept-iq/mixins/status-value", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    statusValue: Ember.computed('drivedStatus', function () {
      let statusValue = '';

      if (this.get('drivedStatus')) {
        statusValue = _lodash.default.lowerCase(_lodash.default.camelCase(this.get('drivedStatus')));
        statusValue = statusValue.replace(/\s+/g, '');
      }

      return statusValue;
    })
  });

  _exports.default = _default;
});