define("adept-iq/topic-transforms/subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(store, payload, logger) {
    payload.data.type = 'subscription';
    let {
      fareTypeName,
      fareTypeId,
      serviceWindowId,
      serviceWindowName
    } = payload.data.attributes; // topic does not currently have ID for fareType, so try to look it up

    if (Ember.isBlank(fareTypeId) && Ember.isPresent(fareTypeName)) {
      let fareType = store.peekAll('fare-type').findBy('name', fareTypeName);

      if (fareType) {
        fareTypeId = fareType.get('id');
      } else {
        logger.warn(`could not find fareType with name '${fareTypeName}'`);
      }
    }

    if (Ember.isPresent(fareTypeId)) {
      payload.data.relationships.fareType = {
        data: {
          id: fareTypeId,
          type: 'fareType'
        }
      };
    } // same thing with service window


    if (Ember.isBlank(serviceWindowId) && Ember.isPresent(serviceWindowName)) {
      let serviceWindow = store.peekAll('service-window').findBy('name', serviceWindowName);

      if (serviceWindow) {
        serviceWindowId = serviceWindow.get('id');
      } else {
        // FIXME: it's probably not safe to assume these are the same; this
        // shouldn't happen when service windows pre-loaded from config service
        serviceWindowId = serviceWindowName;
        payload.included.push({
          id: serviceWindowId,
          type: 'serviceWindow',
          attributes: {
            name: serviceWindowName
          }
        });
      }
    }

    if (Ember.isPresent(serviceWindowId)) {
      payload.data.relationships.serviceWindow = {
        data: {
          id: serviceWindowId,
          type: 'serviceWindow'
        }
      };
    } // subscriptions have their own kind of travelNeeds in trip domain model


    let {
      travelNeeds
    } = payload.data.relationships;
    delete payload.data.relationships.travelNeeds;
    travelNeeds.data.setEach('type', 'subscriptionTravelNeed');
    payload.data.relationships.subscriptionTravelNeeds = travelNeeds;
    payload.included.filterBy('type', 'travelNeed').forEach(travelNeedPayload => {
      travelNeedPayload.type = 'subscriptionTravelNeed';
      travelNeedPayload.relationships = travelNeedPayload.relationships || {};
      let {
        passengerTypeName,
        travelNeedTypeName
      } = travelNeedPayload.attributes;
      let passengerType = store.peekAll('passenger-type').findBy('name', passengerTypeName);

      if (passengerType) {
        travelNeedPayload.relationships.passengerType = {
          data: {
            id: passengerType.get('id'),
            type: 'passengerType'
          }
        };
      }

      let travelNeedType = store.peekAll('travel-need-type').findBy('name', travelNeedTypeName);

      if (travelNeedType) {
        travelNeedPayload.relationships.travelNeedType = {
          data: {
            id: travelNeedType.get('id'),
            type: 'travelNeedType'
          }
        };
      }
    });
    payload.included.filterBy('type', 'rider').forEach(riderPayload => {
      let {
        rmsPK
      } = riderPayload.attributes;

      if (rmsPK) {
        delete riderPayload.attributes.rmsPK;
        riderPayload.attributes.riderId = rmsPK;
      }
    });
    return payload;
  }
});