define("adept-iq/pods/components/iq-widgets/stops-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/stops-widget/config", "adept-iq/utils/find-row-record", "adept-iq/config/environment", "adept-iq/config/api-urls", "moment"], function (_exports, _component, _config, _findRowRecord, _environment, _apiUrls, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = ['clusters', 'vehicleRoutes', 'vehicleRoutes.driverShifts', 'vehicleRoutes.vehicle', 'vehicleRoutes.driverShifts.driver', 'vehicleRoutes.vehicle.vehicleType', 'driverBreaks', 'driverBreaks.driverBreakEvents', 'vehicleRoutes.vehicle.vehicleEvents', 'clusters.trips', 'clusters.trips.stops'];

  var _default = _component.default.extend({
    classNames: ['stops-widget'],
    editModal: Ember.inject.service(),
    dragCoordinator: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    editComponent: 'iq-widgets/stops-form-widget',
    driverBreakEditComponent: 'iq-widgets/driver-breaks-form-widget',
    vehicleEventComponent: 'iq-widgets/vehicle-event-form-widget',
    noShowComponent: 'iq-widgets/noshow-event-stop-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,
    routeexecevent: Ember.inject.service(),

    saveDriverBreakFunction(records) {
      this.set('editModal.errors', []);
      return new Promise((resolve, reject) => {
        records.forEach(record => {
          let promisedStartMoment = (0, _moment.default)(record.get('promisedStart'));
          let promisedEndMoment = (0, _moment.default)(record.get('promisedEnd'));
          record.set('estimatedStart', record.get('promisedStart'));
          record.set('estimatedEnd', record.get('promisedEnd'));
          record.set('plannedDuration', promisedEndMoment.diff(promisedStartMoment, 'seconds'));
          record.set('externalId', `${record.get('route.id')}${(0, _moment.default)().unix()}`);
          record.set('notes', `${promisedEndMoment.diff(promisedStartMoment, 'minutes')} minutes break`);
          record.set('ordinal', 1);
          record.set('state', 'planned');
          record.save().then(() => {
            record.set('isForceDirty', false);
            this.get('activeContext').refreshTableContent(this.get('modelName'));
          }).catch(e => {
            reject(e);
          });
        });
      });
    },

    sendMessagesToVehicle(record, status) {
      const assignedDriver = record.get('trip.cluster.route.assignedDriver');
      const assignedVehicle = record.get('trip.cluster.route.assignedVehicle');
      const rider = record.get("trip.rider");
      const message = ` ${status} action performed by dispatch for ${rider.get('firstName')} ${rider.get('lastName')},
               ${record.get('type')} : ${record.get('place.address')} , ${record.get('trip.requestTime')} `;
      const draftMessage = this.get('store').createRecord('tm-canned-message', {
        // TODO: should this be `driver.externalId`?
        driverId: assignedDriver.get('externalId'),
        vehicleId: assignedVehicle.get('name'),
        routeId: `${record.get('trip.cluster.route.id')}`,
        replyToMsgId: `${record.get('trip.id')}`,
        body: message // TODO: how do we get the current route ID for a vehicle?
        // routeId: driver.get('routeId')

      });
      return draftMessage.save();
    },

    sourceRowId: null,
    dragTarget: null,

    saveStopArriveRecordFunction(records) {
      let me = this;
      return new Promise((resolve, reject) => {
        records.forEach(record => {
          const routeStartData = this.get('routeexecevent').stopArriveData();
          const preparedRouteStartData = this.get('routeexecevent').prepareStopArriveData(routeStartData, record);
          const json = JSON.stringify(preparedRouteStartData);
          const session = this.get('session');
          return this.get('ajax').post(_apiUrls.API.avlmService.host + '/production/route-exec-event', {
            method: 'POST',
            contentType: "application/json",
            headers: {
              "Authorization": `Bearer ${session.data.authenticated.token}`
            },
            data: json
          }).then(response => {
            record.set('isForceDirty', false);
            record.set('id', response.data.id);
            me.get('activeContext').refreshTableContent('route');
            me.sendMessagesToVehicle(record, 'Arrive');
          }).catch(function (error) {
            reject(error);
          });
        });
      });
    },

    saveStopDepartRecordFunction(records) {
      const me = this;
      return new Promise((resolve, reject) => {
        records.forEach(record => {
          const routeEndData = this.get('routeexecevent').stopDepartData();
          const preparedRouteEndData = this.get('routeexecevent').prepareStopDepartData(routeEndData, record);
          const json = JSON.stringify(preparedRouteEndData);
          const session = this.get('session');
          return this.get('ajax').post(_apiUrls.API.avlmService.host + '/production/route-exec-event', {
            method: 'POST',
            contentType: "application/json",
            headers: {
              "Authorization": `Bearer ${session.data.authenticated.token}`
            },
            data: json
          }).then(response => {
            record.set('isForceDirty', false);
            record.set('id', response.data.id);
            me.get('activeContext').refreshTableContent('route');
            me.sendMessagesToVehicle(record, 'Depart');
          }).catch(function (error) {
            reject(error);
          });
        });
      });
    },

    saveNoShowRecordFunction(records) {
      const me = this;
      return new Promise((resolve, reject) => {
        records.forEach(record => {
          const noShowData = this.get('routeexecevent').noShowData();
          const prepareNoShowData = this.get('routeexecevent').prepareStopNoShowData(noShowData, record);
          const json = JSON.stringify(prepareNoShowData);
          const session = this.get('session');
          return this.get('ajax').post(_apiUrls.API.avlmService.host + '/production/route-exec-event', {
            method: 'POST',
            contentType: "application/json",
            headers: {
              "Authorization": `Bearer ${session.data.authenticated.token}`
            },
            data: json
          }).then(response => {
            record.set('isForceDirty', false);
            record.set('id', response.data.id);
            me.get('activeContext').refreshTableContent('route');
          }).catch(function (error) {
            reject(error);
          });
        });
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({});
      /*this.set('singleActions', [{
        name: 'Arrive',
          action: (model) => {
            let records = [];
            records.push(model);
            model.set('odometer','');
            model.set('timestamp',new Date());
            this.saveStopArriveRecordFunction(records);
          }
        },
        {
          name: 'Depart',
          action: (model) => {
            let records = [];
            records.push(model);
            model.set('odometer','');
            model.set('timestamp',new Date());
            this.saveStopDepartRecordFunction(records);
          }
        },
        {
          name: 'No Show',
          action: (model) => {
            let noShowReasonCodes = this.get('store').peekAll('no-show-reason-code');
            model.set('noShowReason',noShowReasonCodes.firstObject);
            model.set('timestamp',new Date());
            let noShowComponent = this.get('noShowComponent');
            this.get('editModal').open(noShowComponent, [model], this.get('saveNoShowRecordFunction').bind(this));
           }
        },
        {
          name: 'Remove',
          action: ( model) => {
           }
        } ,
        {
          name: 'Edit',
            action: ( model) => {
             }
        },
        {
          name: 'Add Driver Break',
            action: (model) => {
              let placeModel = this.get('store').createRecord('place');
              let newModel = this.get('store').createRecord('driver-break', {
                route: model.get('trip.cluster.route'),
                place: placeModel,
                promisedStart: new Date(),
                promisedEnd: new Date()
              });
               this.get('records').pushObject(newModel);
              let editComponent = this.get('driverBreakEditComponent');
              this.get('editModal').open(editComponent, [newModel], this.get('saveRecordFunction').bind(this));
            }
        }
      ]);*/

      this.set('bulkActions', [{
        name: 'Bulk Edit',
        action: models => {
          let editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, models);
        }
      }]);
    },

    // singleActions: computed('table.rows.@each.{selected,status}', function() {
    //   let stopArriveAction = this.get('stopArriveAction');
    //   let stopDepartAction = this.get('stopDepartAction');
    //   let stopNoshowAction = this.get('stopNoshowAction');
    //   let breakStartAction = this.get('breakStartAction');
    //   let breakEndAction = this.get('breakEndAction');
    //   let breakRemoveAction = this.get('breakRemoveAction');
    //   let breakAddAction = this.get('breakAddAction');
    //   let breakEditAction = this.get('breakEditAction');
    //   let row = this.get('table.rows').findBy('selected', true);
    //   if (!row) return [];
    //   switch(row.get('type')) {
    //     case 'pick': {
    //       switch(row.get('status')) {
    //         case 'Arrived': {
    //           return [stopDepartAction,stopNoshowAction,breakAddAction];
    //         }
    //         case 'Scheduled'  :  {
    //           if(row.get('actualArrivalTime') === null ||
    //             row.get('actualArrivalTime') === undefined) {
    //               return [stopArriveAction,stopNoshowAction,breakAddAction];
    //           } else {
    //             return [stopNoshowAction,breakAddAction];
    //           }
    //         }
    //         case 'Cancelled': {
    //           return [breakAddAction];
    //         }
    //         case 'No Showed': {
    //           return [breakAddAction];
    //         }
    //         default: {
    //           return [breakAddAction];
    //         }
    //       }
    //       break;
    //     }
    //     case 'drop': {
    //       switch(row.get('status')) {
    //         case 'Arrived'  :  {
    //           return [stopDepartAction,breakAddAction];
    //         }
    //         case 'Scheduled': {
    //           if(row.get('actualArrivalTime') === null ||
    //             row.get('actualArrivalTime') === undefined) {
    //               return [stopArriveAction,breakAddAction];
    //           }
    //         }
    //         case 'Cancelled': {
    //           return [breakAddAction];
    //         }
    //         default: {
    //           return [breakAddAction];
    //         }
    //       }
    //       break;
    //     }
    //    case 'break': {
    //     switch(row.get('status')) {
    //       case 'Arrived': {
    //         return [breakEndAction,breakAddAction];
    //       }
    //       case 'Scheduled'  :  {
    //         if(row.get('breakStartTime') === null ||
    //           row.get('breakStartTime') === undefined) {
    //             return [breakStartAction,breakEditAction,breakRemoveAction,breakAddAction];
    //         } else {
    //           return [breakEndAction,breakAddAction];
    //         }
    //       }
    //       case 'Departed': {
    //         return [breakAddAction];
    //       }
    //       default: {
    //         return [breakAddAction];
    //       }
    //     }
    //     }
    //   }
    // }),
    dragRowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),
    dragDirection: Ember.computed('dragTarget', 'sourceRowId', function () {
      const targetRow = this.get('dragTarget');

      if (targetRow) {
        const rows = this.get('dragRowGroup');
        const targetIdx = rows.indexOf((0, _findRowRecord.findRowRecordByElement)(targetRow, rows));
        const sourceIdx = rows.indexOf(rows.findBy('rowId', this.get('sourceRowId')));
        return sourceIdx - targetIdx;
      }
    }).readOnly(),
    dragOverClass: Ember.computed('dragDirection', function () {
      const direction = this.get('dragDirection') < 0 ? 'below' : 'above';
      return ` drag-target-${direction} drag-target`;
    }),

    dragStart(event) {
      const row = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('dragRowGroup'));
      if (Ember.isBlank(row)) return;
      this.set('sourceRowId', row.get('rowId'));
      const dragId = Ember.guidFor(row);
      this.get('dragCoordinator').setSourceRow(dragId, row, 'stops');
      event.dataTransfer.setData('text', dragId);
    },

    dragOver(event) {
      event.preventDefault();
      if (this.get('dragCoordinator.widgetType') !== 'stops') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drag over other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.set('dragTarget', rowElement);
      this.$(`#${rowElement.id}`).addClass(this.get('dragOverClass'));
    },

    dragLeave(event) {
      event.preventDefault();
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drag over other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.$(`#${rowElement.id}`).removeClass(['drag-target-above', 'drag-target-below', 'drag-target']);
    },

    drop(event) {
      if (this.get('dragCoordinator.widgetType') !== 'stops') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drop onto other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.$(`#${rowElement.id}`).removeClass(['drag-target-above', 'drag-target-below', 'drag-target']);
      const table = this.get('table');
      const rows = this.get('dragRowGroup'); // let sourceRowRecord = rows.findBy('rowId', this.get('sourceRowId'));
      // // User might drop elements that are not Stops widget rows.
      // if (isBlank(sourceRowRecord)) {
      //   return;
      // }

      const sourceRowRecord = this.get('dragCoordinator').getSourceRow(event.dataTransfer.getData('text'));
      if (Ember.isBlank(sourceRowRecord)) return;
      const targetRowRecord = (0, _findRowRecord.findRowRecordByElement)(event.target, rows);

      const _rows = rows.toArray();

      const targetRowIdx = _rows.indexOf(targetRowRecord) + (this.get('dragDirection') < 0 ? 0 : 0);
      event.dataTransfer.dropEffect = 'move';
      event.preventDefault();
      event.stopPropagation();
      table.propertyWillChange('rows');

      _rows.removeObject(sourceRowRecord);

      _rows.insertAt(targetRowIdx, sourceRowRecord);

      rows.setObjects(_rows);
      table.propertyDidChange('rows');
    },

    dragEnd() {
      this.set('sourceRowId', null);
      this.set('dragTarget', null);
      this.get('dragCoordinator').clearSourceRows();
    }

  });

  _exports.default = _default;
});