define("adept-iq/adapters/-tm-tripManagement", ["exports", "adept-iq/adapters/application", "adept-iq/config/api-urls"], function (_exports, _application, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    defaultSerializer: '-tm-tripManagement',
    host: _apiUrls.API.tripManagementService.host,
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    pathForType: function (type) {
      // trim 'tm' prefix from model type
      const regEx = /^(?:tm-)?(.*)$/;
      const match = regEx.exec(type)[1];
      return this._super(match);
    }
  });

  _exports.default = _default;
});