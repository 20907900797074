define("adept-iq/utils/data-join", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    cache: null,
    nodes: null,
    dirtyMarkers: null,
    dirtyPolylines: null,

    init() {
      this._super(...arguments);

      this.set('cache', {});
      this.set('dirtyMarkers', {});
      this.set('dirtyPolylines', {});
      this.set('nodes', []);
    },

    clear() {
      this.join([]);
      const cache = this.get('cache');
      Object.entries(cache).forEach(([key, node]) => {
        delete cache[key];
        this.release(node);
      });
    },

    // override initialize, enter, update, and exit with custom behaviour
    initialize: data => data,

    enter()
    /* node, i */
    {},

    update()
    /* node, data, i */
    {},

    exit()
    /* node */
    {},

    release()
    /* node */
    {},

    keyForNode: node => Ember.get(node, 'id'),
    keyForData: data => Ember.get(data, 'id'),
    keyForMarker: node => {
      const id = node.get('id');
      const lat = node.get('lat');
      const lng = node.get('lng');
      const style = node.get('iconStyle');
      const label = node.get('label');

      if (lat && lng) {
        return `${id}-${lat}-${lng}-${style}-${label}`;
      }

      return null;
    },
    keyForPolyline: node => {
      const id = node.get('id');
      const points = node.get('points');
      const style = node.get('style');
      const label = node.get('label');

      if (points) {
        return `${id}-${style}-${label}`;
      }

      return null;
    },

    join(collection) {
      const cache = this.get('cache');
      const nodes = this.get('nodes');
      const dirtyMarkers = this.get('dirtyMarkers');
      const dirtyPolylines = this.get('dirtyPolylines'); // build lookup tables

      const wasInNodes = {};

      for (let i = 0; i < nodes.length; i++) {
        wasInNodes[this.keyForNode(nodes[i])] = true;
      }

      const isInCollection = {};

      for (let i = 0; i < collection.length; i++) {
        isInCollection[this.keyForNode(collection[i])] = true;
      } // exit outgoing nodes first


      for (let i = nodes.length - 1; i >= 0; i--) {
        const node = nodes[i];
        const key = this.keyForNode(node);
        const markerKey = this.keyForMarker(node);
        const polylineKey = this.keyForPolyline(node);

        if (!isInCollection[key]) {
          this.exit(node);
          if (polylineKey) delete dirtyPolylines[key];
          if (markerKey) delete dirtyMarkers[key];
        }
      }

      nodes.clear();
      collection.forEach((data, i) => {
        const key = this.keyForData(data);
        let node = cache[key];

        if (!node) {
          node = this.initialize(data);
          cache[key] = node;
        }

        nodes.push(node);
        const markerKey = this.keyForMarker(node);
        const cacheMarkerKey = dirtyMarkers[key] ? dirtyMarkers[key] : null;
        const polylineKey = this.keyForPolyline(node);
        const cachePolylineKey = dirtyPolylines[key] ? dirtyPolylines[key] : null;

        if (wasInNodes[key]) {
          if (markerKey !== cacheMarkerKey || polylineKey !== cachePolylineKey) {
            this.update(node, data, i);
            if (markerKey) dirtyMarkers[key] = markerKey;
            if (polylineKey) dirtyPolylines[key] = polylineKey;
          } else if (!markerKey && !polylineKey) this.update(node, data, i);
        } else {
          this.enter(node, i);
          if (markerKey) dirtyMarkers[key] = markerKey;
          if (polylineKey) dirtyPolylines[key] = polylineKey;
        }
      });
      return this;
    }

  });

  _exports.default = _default;
});