define("adept-iq/pods/components/timeline-picker/component", ["exports", "adept-iq/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    timeSpan: Ember.inject.service(),
    store: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    classNames: ['timeline-picker'],
    zoomPercent: 50,
    minZoomPercent: 25,
    maxZoomPercent: 100,
    zoomPercentStep: 1,
    proposedStartDate: null,
    proposedEndDate: null,
    isRelative: null,
    isFullAccess: true,
    fieldValidity: null,
    areFieldsValid: Ember.computed('fieldValidity.{startDate,endDate,calendar}', function () {
      // body
      const fv = this.get('fieldValidity');
      /*eslint-disable */

      for (let state in fv) {
        if (fv[state] === false) {
          return false;
        }
      }
      /*eslint-enable */


      return true;
    }),
    isAbsoluteMode: true,
    minusValue: Ember.computed('timeSpan.minusValue', function () {
      return this.get('timeSpan.minusValue');
    }),
    plusValue: Ember.computed('timeSpan.plusValue', function () {
      return this.get('timeSpan.plusValue');
    }),
    canChooseRelative: Ember.computed('proposedStartDate', 'proposedEndDate', function () {
      const start = this.get('proposedStartDate').getTime();
      const end = this.get('proposedEndDate').getTime();
      const now = Date.now();

      if (now <= start || now > end) {
        return false;
      }

      return true;
    }),

    updateTimeSpanService(minusValue, plusValue) {
      this.get('timeSpan').setSpanMillis(minusValue, plusValue);
    },

    stopTimeSpanService() {
      this.get('timeSpan').stopInterval();
    },

    init() {
      this._super(...arguments);

      const startDate = this.get('workspace.startDate');
      const proposedStartDate = new Date(); //proposedStartDate.setHours(0,0,0,0);

      proposedStartDate.setTime(startDate.getTime());
      this.set('proposedStartDate', proposedStartDate);
      const endDate = this.get('workspace.endDate');
      const proposedEndDate = new Date(); // proposedEndDate.setHours(23,59,59,999);

      proposedEndDate.setTime(endDate.getTime());
      this.set('proposedEndDate', proposedEndDate);
      this.set('isRelative', this.get('timeSpan.isTimeSpanRunning'));
      this.set('fieldValidity', {});
      const isFullAccess = this.store.peekAll('cs-config-permission').get('firstObject.isFullAccessTimeline');
      this.set('isFullAccess', isFullAccess);
    },

    actions: {
      onZoomPercentChange(zoomPercent) {
        this.set('zoomPercent', parseInt(zoomPercent, 10));
      },

      checkValidity() {
        return this.get('areFieldsValid');
      },

      onCloseButtonClick() {
        this.send('close');
      },

      onApplyButtonClick() {
        if (this.get('areFieldsValid')) {
          const newStartDate = this.get('proposedStartDate');
          const newEndDate = this.get('proposedEndDate');

          if (this.get('isRelative') && this.get('canChooseRelative')) {
            const now = Date.now();
            this.updateTimeSpanService(now - newStartDate.getTime(), newEndDate.getTime() - now);
            _environment.default.workspaceContext.startDate = now - newStartDate.getTime();
            _environment.default.workspaceContext.endDate = newEndDate.getTime() - now;
          } else {
            this.stopTimeSpanService();
            this.set('workspace.startDate', newStartDate);
            this.set('workspace.endDate', newEndDate);
            _environment.default.workspaceContext.startDate = this.get('workspace.startDate');
            _environment.default.workspaceContext.endDate = this.get('workspace.endDate');
          } //this.get('workspaceContext').set('startRefreshing',false);


          this.send('close');
        }
      },

      onChangeAbsoluteCheckBox() {
        this.toggleProperty('isRelative');
      },

      close() {
        const workspace = this.get('workspace');
        workspace.toggleProperty('isEditingTimeline');
      }

    }
  });

  _exports.default = _default;
});