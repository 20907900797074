define("adept-iq/models/leg-subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    startTime: attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    endTime: attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    shiftStart: attr('string', {
      defaultValue: '06:00:00'
    }),
    shiftEnd: attr('string', {
      defaultValue: '17:00:00'
    }),
    sunday: attr('boolean'),
    monday: attr('boolean'),
    tuesday: attr('boolean'),
    wednesday: attr('boolean'),
    thursday: attr('boolean'),
    friday: attr('boolean'),
    saturday: attr('boolean'),
    everyweekday: attr('string'),
    pattern: attr('string'),
    recurring: attr('string'),
    dailyConfig: attr('string'),
    startDateTime: attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    endDateTime: attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    excludeStartDateTime: attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    excludeEndDateTime: attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    leg: belongsTo('ss-leg'),
    selectedDOWs: Ember.computed('sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', function () {
      const selectedDOWArray = [];
      if (this.get('sunday')) selectedDOWArray.push('sunday');
      if (this.get('monday')) selectedDOWArray.push('monday');
      if (this.get('tuesday')) selectedDOWArray.push('tuesday');
      if (this.get('wednesday')) selectedDOWArray.push('wednesday');
      if (this.get('thursday')) selectedDOWArray.push('thursday');
      if (this.get('friday')) selectedDOWArray.push('friday');
      if (this.get('saturday')) selectedDOWArray.push('saturday');
      return selectedDOWArray;
    })
  });

  _exports.default = _default;
});