define("adept-iq/pods/components/iq-widgets/gauge-widget/gauge-chart/component", ["exports", "adept-iq/pods/components/ember-chart-fix/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Chart */
  const NEEDLE_RADIUS = 80;

  var _default = _component.default.extend({
    chart: null,
    needleAngle: Ember.computed('options.labels.{dial,min,max}', function () {
      const options = this.get('options');
      const needleAngle = (options.labels.dial - options.labels.min) / (options.labels.max - options.labels.min);
      return options.rotation + needleAngle * Math.PI;
    }),

    drawGaugeComponents() {
      const chart = this.get('chart');
      const options = chart.options;
      const ctx = chart.chart.ctx;
      const cw = chart.width;
      const ch = chart.height; // Get positions for dial

      const cx = cw / 2;
      const cy = ch - ch / 4;
      const radianAngle = this.get('needleAngle');
      const radius = NEEDLE_RADIUS;
      ctx.translate(cx, cy);
      ctx.rotate(radianAngle);
      ctx.beginPath();
      ctx.moveTo(0, -5);
      ctx.lineTo(radius, 0);
      ctx.lineTo(0, 5);
      ctx.fillStyle = '#007bff';
      ctx.fill();
      ctx.rotate(-radianAngle);
      ctx.translate(-cx, -cy);
      ctx.beginPath();
      ctx.arc(cx, cy, 7, 0, Math.PI * 2);
      ctx.fill(); // configure label text

      const fontStyle = 'Avenir Next';
      const txt1 = options.labels.min;
      const txt2 = options.labels.max; // let txt3 = options.labels.dial;

      const color = '#666666';
      ctx.font = '12px ' + fontStyle;
      ctx.fillStyle = color;
      ctx.textAlign = 'center';
      ctx.testBaseline = 'bottom';
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      const yPadding = 7;
      const yPosition = chart.chartArea.top + chart.chartArea.bottom + yPadding;
      ctx.fillText(txt1, centerX - NEEDLE_RADIUS, yPosition);
      ctx.fillText(txt2, centerX + NEEDLE_RADIUS, yPosition); // ctx.fillText(txt3, centerX, yPosition);
    },

    didReceiveAttrs() {
      this._super(...arguments);
      /*eslint-disable */


      const _this = this;
      /*eslint-enable */


      Chart.plugins.register({
        afterDraw: function (chart) {
          if (chart.config.options.gauge) {
            _this.drawGaugeComponents();
          }
        }
      });
      Chart.plugins.register({
        resize: function () {}
      });
    },

    didInsertElement() {
      const context = this.get('element');
      const data = this.get('data');
      const type = this.get('type');
      const options = this.get('options');
      const chart = new Chart(context, {
        type: type,
        data: data,
        options: options
      });
      this.set('chart', chart);
    }

  });

  _exports.default = _default;
});