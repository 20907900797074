define("adept-iq/classes/tree-nodes/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    // config
    label: null,
    type: null,
    depth: 0,
    parent: null,
    children: null,
    isTreeModified: Ember.computed('tree.@each.isModified', function () {
      return this.get('tree').any(node => node.get('isModified'));
    }),
    isTreeOutdated: Ember.computed('tree.@each.isOutdated', function () {
      return this.get('tree').any(node => node.get('isOutdated'));
    }),
    isTreeDirty: Ember.computed.or('isTreeModified', 'isTreeOutdated'),
    tree: Ember.computed('children.@each.tree', function () {
      const nodes = [this];
      const children = this.get('children');

      if (Ember.isEmpty(children)) {
        return nodes;
      }

      children.sortBy('label').forEach(child => {
        const tree = child.get('tree');
        nodes.push(...tree);
      });
      return nodes;
    }),
    visibleTree: Ember.computed('isExpanded', 'isRemoved', 'children.@each.visibleTree', function () {
      if (this.get('isRemoved')) {
        return [];
      }

      const nodes = [this];
      const children = this.get('children');

      if (!this.get('isExpanded') || Ember.isEmpty(children)) {
        return nodes;
      }

      children.sortBy('label').forEach(child => {
        const tree = child.get('visibleTree');
        nodes.push(...tree);
      });
      return nodes;
    }),
    visibleChildren: Ember.computed('children.@each.isRemoved', function () {
      const children = this.get('children');

      if (Ember.isEmpty(children)) {
        return [];
      }

      return children.rejectBy('isRemoved');
    })
  });

  _exports.default = _default;
});