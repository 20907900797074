define("adept-iq/serializers/address-lookup", ["exports", "adept-iq/serializers/-tm-tripManagement"], function (_exports, _tmTripManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tmTripManagement.default.extend({
    normalizeResponse: function (store, primaryModelClass, payload) {
      console.log('normalizeResponse payload', payload);
      const data = Ember.makeArray(payload.data);
      console.log('normalizeResponse data ', data);

      for (let i = 0; i < data.length; i++) {
        data[i].type = 'address-lookup';
      }

      return payload;
    }
  });

  _exports.default = _default;
});