define("adept-iq/pods/components/iq-widgets/favorite-pickup-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/favorite-pickup-widget',
    rowComponent: 'table/rows/otp-formatted-row',
    modelName: 'favorite-trip',
    defaultSortId: 'referenceCode',
    defaultSortAsc: false,
    hideWidgetControls: true,
    hideHeader: true,
    checkBoxColumn: false,
    isFormControls: true,
    title: 'Pickup Trip Details',
    columns: [{
      id: 'pickupFreeFormAddress',
      type: 'text',
      label: 'Pickup Address',
      valuePath: 'pickupFreeFormAddress',
      editable: false,
      hidden: false,
      defaultWidth: 200
    }, {
      id: 'pickupCity',
      type: 'text',
      label: 'City',
      valuePath: 'pickupCity',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'customerReference',
      type: 'text',
      label: 'Reference Code',
      valuePath: 'customerReference',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 60
    }]
  };
  _exports.default = _default;
});