define("adept-iq/utils/populate-pickup", ["exports", "adept-iq/utils/check-address-flags", "adept-iq/utils/populate-address"], function (_exports, _checkAddressFlags, _populateAddress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = populatePickup;

  function populatePickupData(tripData, pickupData, geocode, store) {
    Ember.set(tripData, 'editmodelcontext.predefinedDriverNotes.address', pickupData.pickup.noteForDriver); //Ember.set(tripData,'editmodelcontext.predefinedOperatorNotes.account', pickupData.pickup.noteForDispatch);

    (0, _populateAddress.default)(tripData.stopPoints[0].address, pickupData.pickup.address, geocode, store, 'pickup', tripData, true);
    (0, _checkAddressFlags.default)(tripData);
  }

  function clearTripData(tripData, geocode, store) {
    (0, _populateAddress.default)(tripData.stopPoints[0].address, null, geocode, store, 'pickup', tripData, true);
    geocode.deactivateGeocode();
  }

  function populatePickup(tripRecord, pickupData, geocode, store) {
    if (pickupData !== null) {
      populatePickupData(tripRecord, pickupData, geocode, store);
    } else {
      clearTripData(tripRecord, geocode, store);
    }

    return;
  }
});