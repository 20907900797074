define("adept-iq/pods/components/iq-widgets/map-widget/data-joins/polyline", ["exports", "adept-iq/pods/components/iq-widgets/map-widget/data-joins/base", "tomtom", "adept-iq/pods/components/iq-widgets/map-widget/config/polyline", "adept-iq/pods/components/iq-widgets/map-widget/config/arrow", "lodash"], function (_exports, _base, _tomtom, _polyline, _arrow, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // pixel distance from stop
  const ARROW_OFFSET = 7;

  var _default = _base.default.extend({
    enter(node) {
      let points = node.get('points');
      if (Ember.isEmpty(points)) return;
      this.createPolylineFor(node);
    },

    update(node) {
      let points = node.get('points');
      let polyline = node.get('polyline');

      if (Ember.isEmpty(points)) {
        if (polyline) {
          this.removePolylineFor(node);
        }

        return;
      }

      if (!polyline) {
        this.createPolylineFor(node);
      }

      this.updatePolylineFor(node);
    },

    exit(node) {
      if (node.get('polyline')) {
        this.removePolylineFor(node);
      }
    },

    createPolylineFor(node) {
      let label = node.get('label');
      let record = node.get('record');
      let style = node.get('style');
      let defaultOptions = _polyline.default[style] || {};
      let nodeOptions = node.get('options') || {};
      let modelName = record.constructor.modelName;

      let options = _lodash.default.merge(defaultOptions, nodeOptions, {
        label,
        opacity: node.get('opacity'),
        // for active context clicks
        isActive: node.get('isActive'),
        isTraversal: node.get('isTraversal'),
        modelId: record.get('id'),
        modelName
      });

      let points = node.get('points');

      let polyline = _tomtom.default.L.polyline(points, options);

      polyline.bindPopup(label, {
        closeButton: false
      });
      this.bindMouseEvents(polyline);
      node.set('polyline', polyline);
      let map = this.get('map');
      polyline.addTo(map);
      let arrowStyle = node.get('arrowStyle');

      if (arrowStyle && points.length > 1) {
        // we actually reverse points and draw the head of the arrow at the tail;
        // this allows the offset to be set correctly in pixels
        let arrowPoints = points.slice(points.length - 2, points.length);
        arrowPoints.reverse();
        let arrow = L.polylineDecorator(arrowPoints, {
          patterns: [{
            offset: node.get('stopsIsVisible') ? ARROW_OFFSET : 0,
            repeat: 0,
            symbol: L.Symbol.arrowHead({
              headAngle: -290,
              // flips arrow around so it looks right
              pixelSize: 8,
              polygon: false,
              pathOptions: {
                weight: 2,
                stroke: true,
                color: _arrow.default[arrowStyle].color,
                opacity: options.opacity
              }
            })
          }]
        });
        polyline.setStyle({
          color: _arrow.default[arrowStyle].color
        });
        arrow.addTo(map);
        node.set('arrow', arrow);
      }
    },

    updatePolylineFor(node) {
      let opacity = node.get('opacity');
      let polyline = node.get('polyline');

      if (polyline) {
        let points = node.get('points');
        let label = node.get('label');
        let style = node.get('style');
        let defaultOptions = _polyline.default[style] || {};
        let nodeOptions = node.get('options') || {};

        let options = _lodash.default.merge(defaultOptions, nodeOptions, {
          opacity
        }); // polyline does not have `setOpacity` method


        polyline.setLatLngs(points);
        polyline.setStyle(options);

        polyline._popup.setContent(label);

        polyline.setTooltipContent(label);
        let arrow = node.get('arrow');

        if (arrow) {
          let arrowStyle = node.get('arrowStyle');
          let offset = node.get('stopsIsVisible') ? ARROW_OFFSET : 0; // this enables otp colour changes

          arrow.setStyle({
            color: _arrow.default[arrowStyle].color,
            opacity: options.opacity
          }); // also color line to match

          polyline.setStyle({
            color: _arrow.default[arrowStyle].color
          }); // decorator internals don't get updated permanently by `setStyle`

          arrow.options.patterns.forEach(pattern => {
            pattern.symbol.options.pathOptions.opacity = opacity;
            pattern.offset = offset;
          }); // have to dig really deep into the internals to get offset to refresh

          arrow._patterns.forEach(pattern => {
            pattern.offset.value = offset;
          }); // ensure that arrow is redrawn when offset changes


          arrow.redraw();
        }
      }
    },

    removePolylineFor(node) {
      let polyline = node.get('polyline');
      let arrow = node.get('arrow');

      if (arrow) {
        arrow.remove();
        node.set('arrow', null);
      }

      if (polyline) {
        polyline.remove();
        node.set('polyline', null);
      }
    }

  });

  _exports.default = _default;
});