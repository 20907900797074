define("adept-iq/classes/tree-nodes/object", ["exports", "adept-iq/classes/tree-nodes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    type: 'object',
    configItem: null,
    isExpandable: true,
    isObject: true,
    isRemoved: false,
    // disabled for read-only release
    // canRemove: computed.readOnly('parent.isCollection'),
    isModified: Ember.computed('children.@each.isModified', function () {
      const children = this.get('children');
      return Ember.makeArray(children).any(child => child.get('isModified'));
    })
  });

  _exports.default = _default;
});