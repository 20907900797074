define("adept-iq/pods/components/close-schedule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6J0VMaO+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"secondary-window\",null,[[\"title\",\"onXButtonClick\",\"isLoading\"],[\"Close Schedule\",[27,\"action\",[[22,0,[]],\"onXButtonClick\"],null],[23,[\"loadDashboardsTask\",\"isRunning\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dashboard-picker-section\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"dashboard-picker-section-title\"],[9],[0,\"\\n      Close Schedule\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"dashboard-picker-item\"],[9],[0,\"\\n      Closing a schedule makes its data non-editable. Proceed?\\n\\n      \"],[7,\"div\"],[11,\"class\",\"spacer\"],[9],[10],[0,\"\\n\\n      \"],[7,\"button\"],[9],[0,\" Yes \"],[3,\"action\",[[22,0,[]],\"onXButtonClick\",[23,[\"dashboard\"]]]],[10],[0,\"\\n      \"],[7,\"button\"],[9],[0,\" No \"],[3,\"action\",[[22,0,[]],\"onXButtonClick\",[23,[\"dashboard\"]]]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/close-schedule/template.hbs"
    }
  });

  _exports.default = _default;
});