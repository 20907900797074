define("adept-iq/models/vehicle-state", ["exports", "ember-data", "adept-iq/mixins/otp-label", "adept-iq/mixins/status-value"], function (_exports, _emberData, _otpLabel, _statusValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    status: attr('string'),
    zones: attr(),
    driver: belongsTo('driver'),
    zone: belongsTo('zone'),
    stand: belongsTo('stand'),
    stc: belongsTo('stc'),
    standQueuePos: attr('string'),
    zoneQueuePos: attr('string'),
    offset: attr(),
    trip: belongsTo('trip', {
      inverse: 'vehicleState'
    }),
    iqVehicle: belongsTo('iq-vehicle', {
      inverse: 'vehicleState'
    }),
    vehicle: belongsTo('vehicle', {
      inverse: 'vehicleState'
    })
  });

  _exports.default = _default;
});