define("adept-iq/pods/components/iq-widgets/message-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/message-widget/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['message-widget'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    config: _config.default,
    defaultIncludes: INCLUDES,

    didInsertElement() {
      this._super(...arguments);

      this.set('tableActions', [{
        name: 'Show All',
        action: () => {
          let rows = this.get('table.rows');
          rows.forEach(r => {
            r.set('hidden', false);
          });
        }
      }]);
      this.set('bulkActions', [{
        name: 'Activity Log',
        action: () =>
        /* model */
        {}
      }]);
      this.set('respondAction', {
        name: 'Respond',
        action: message => {
          let workspace = this.get('workspace');
          let draftMessage = this.get('store').createRecord('tm-canned-message', {
            replyToMsgId: message.get('messageNr'),
            driver: message.get('driver'),
            driverId: message.get('driverId'),
            vehicleId: message.get('vehicleId'),
            routeId: message.get('routeId')
          });
          workspace.pushState('addNewMessage', {
            draftMessages: [draftMessage]
          });
          message.set('readTime', new Date());
          return message.save();
        }
      });
      this.set('hideAction', {
        name: 'Hide',
        action: model => {
          let row = this.get('table.rows').findBy('id', model.get('id'));
          row.set('hidden', true);
          model.set('readTime', new Date());
          return model.save();
        }
      });
      this.set('resendAction', {
        name: 'Resend',
        action: model => {
          // TODO: how do we re-send?
          model.set('readTime', new Date());
          return model.save();
        }
      });
    },

    singleActions: Ember.computed('table.rows.@each.{selected,status}', function () {
      let respondAction = this.get('respondAction');
      let hideAction = this.get('hideAction');
      let resendAction = this.get('resendAction');
      let row = this.get('table.rows').findBy('selected', true);
      if (!row) return []; // TODO: extract statuses to constants

      switch (row.get('status')) {
        case 'Received':
          return [respondAction, hideAction];

        case 'Sending':
        case 'Sent':
          return [hideAction];

        case 'Failed':
          return [resendAction];

        default:
          return [];
      }
    })
  });

  _exports.default = _default;
});