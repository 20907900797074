define("adept-iq/mixins/iq-model-identification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const REQUIRED_PROPERTIES = ['iqModelName', 'iqModelForeignKey', 'iqModelRelationship'];

  var _default = Ember.Mixin.create({
    // e.g. `iq-driver`
    iqModelName: null,
    // canonical `id` key (e.g. `externalId`)
    iqModelForeignKey: null,
    // e.g. `iqDriver`; this is how you get to the IQ model
    iqModelRelationship: null,

    init() {
      this._super(...arguments);

      REQUIRED_PROPERTIES.forEach(propertyName => {
        const value = this.get(propertyName);
        if (Ember.isPresent(value)) return;
        (true && !(false) && Ember.assert(`iq-model-identification: '${propertyName}' property required`));
      });
    },

    normalizeArrayResponse() {
      const normalized = this._super(...arguments);

      normalized.included = normalized.included || [];
      normalized.data.forEach(payload => {
        this._addInverseRelationship(payload, normalized.included);
      });
      return normalized;
    },

    normalizeSingleResponse() {
      const normalized = this._super(...arguments);

      normalized.included = normalized.included || [];

      this._addInverseRelationship(normalized.data, normalized.included);

      return normalized;
    },

    _addInverseRelationship(payload, included) {
      const iqModelName = this.get('iqModelName');
      const iqModelForeignKey = this.get('iqModelForeignKey');
      let iqModelId;

      if (iqModelForeignKey === 'id') {
        iqModelId = payload.id;
      } else {
        iqModelId = payload.attributes[iqModelForeignKey];
      }

      if (Ember.isEmpty(iqModelId)) {
        // don't have data needed to connect, so don't try
        return;
      }

      included.push({
        id: iqModelId,
        type: iqModelName,
        attributes: {},
        relationships: {}
      });
    },

    normalize(typeClass, hash) {
      const iqModelName = this.get('iqModelName');
      const iqModelForeignKey = this.get('iqModelForeignKey');
      const iqModelRelationship = this.get('iqModelRelationship');
      let iqModelId;

      if (iqModelForeignKey === 'id') {
        iqModelId = hash.id;
      } else if (hash.attributes) {
        iqModelId = hash.attributes[iqModelForeignKey];
      }

      const normalized = this._super(...arguments);

      if (Ember.isEmpty(iqModelId)) {
        // we don't have the data required to connect, so don't try
        return normalized;
      }

      normalized.data.relationships[iqModelRelationship] = {
        data: {
          id: iqModelId,
          type: iqModelName
        }
      };
      return normalized;
    }

  });

  _exports.default = _default;
});