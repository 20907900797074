define("adept-iq/pods/components/iq-widgets/alert-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/alert-widget',
    rowComponent: 'table/rows/otp-formatted-row',
    modelName: 'alert',
    title: 'Alerts',
    hideWidgetControls: true,
    columns: [{
      id: 'generatedAt',
      index: 0,
      type: 'text',
      label: 'Time',
      valuePath: 'generatedAt',
      editable: false,
      hidden: false,
      defaultWidth: 70
    }, {
      id: 'alert',
      index: 1,
      type: 'text',
      label: 'Alert Description',
      valuePath: 'alert',
      editable: false,
      hidden: false,
      defaultWidth: 400
    }]
  };
  _exports.default = _default;
});