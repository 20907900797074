define("adept-iq/pods/components/iq-widgets/drivers-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        type: 'text',
        label: 'ID',
        valuePath: 'id',
        editable: false
      }]
    }, {
      title: 'Editable',
      fields: [{
        id: 'driverId',
        type: 'text',
        label: 'Driver ID',
        valuePath: 'driverId'
      }, {
        id: 'firstName',
        type: 'text',
        label: 'First Name',
        valuePath: 'firstName'
      }, {
        id: 'lastName',
        type: 'text',
        label: 'Last Name',
        valuePath: 'lastName'
      }, {
        id: 'status',
        type: 'text',
        label: 'Status',
        valuePath: 'status',
        editable: false
      }, {
        id: 'areaCode',
        type: 'text',
        label: 'Area Code',
        valuePath: 'areaCode'
      }, {
        id: 'phoneNumber',
        type: 'text',
        label: 'Phone Number',
        valuePath: 'phoneNumber'
      }, {
        id: 'active',
        type: 'boolean',
        label: 'Active',
        valuePath: 'active',
        extra: {
          options: [{
            label: 'True',
            value: true
          }, {
            label: 'False',
            value: false
          }]
        }
      }, {
        id: 'preferredVehicle',
        type: 'searchable-enum',
        label: 'Prefered Vehicle',
        valuePath: 'preferredVehicleName',
        extra: {
          optionModelName: 'ss-vehicle',
          optionSearchPath: 'name',
          optionSearchEnabled: true
        }
      }]
    }, {
      title: 'Availabilities',
      fields: [{
        id: 'availabilities',
        type: 'availabilities',
        label: '',
        valuePath: 'availability',
        modelName: 'ss-driver-availability',
        isMultiType: true,
        extra: {
          startTimePath: 'startTime',
          endTimePath: 'endTime',
          shiftStartPath: 'shiftStart',
          shiftEndPath: 'shiftEnd',
          sundayPath: 'sunday',
          mondayPath: 'monday',
          tuesdayPath: 'tuesday',
          wednesdayPath: 'wednesday',
          thursdayPath: 'thursday',
          fridayPath: 'friday',
          saturdayPath: 'saturday'
        }
      }]
    }]
  };
  _exports.default = _default;
});