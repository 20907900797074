define("adept-iq/services/routeexecevent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    routeStartData() {
      let routeStartData = {
        "data": {
          "type": "routeExecEvent",
          "attributes": {
            "routeId": "",
            "content": {
              "type": "pullOut",
              "actualTime": ""
            },
            "driver": {
              "id": "",
              "badgeNr": "",
              "name": ""
            },
            "vehicle": {
              "id": "",
              "callsign": "",
              "location": {
                "coord": {
                  "lat": 0.0,
                  "lng": 0.0
                },
                "time": ""
              },
              "odo": 0
            }
          }
        }
      };
      return routeStartData;
    },

    routeEndData() {
      let routeEndData = {
        "data": {
          "type": "routeExecEvent",
          "attributes": {
            "routeId": "",
            "content": {
              "type": "pullIn",
              "actualTime": ""
            },
            "driver": {
              "id": "",
              "badgeNr": "",
              "name": ""
            },
            "vehicle": {
              "id": "",
              "callsign": "",
              "location": {
                "coord": {
                  "lat": 0.0,
                  "lng": 0.0
                },
                "time": ""
              },
              "odo": 0
            }
          }
        }
      };
      return routeEndData;
    },

    stopArriveData() {
      let stopArriveData = {
        "data": {
          "type": "routeExecEvent",
          "attributes": {
            "trackingId": "1",
            "routeId": "",
            "content": {
              "type": "arrive",
              "stopIndex": 0,
              "stopId": "",
              "actualTime": "",
              "passengerEvents": [{
                "passengerId": "",
                "passengerType": "client",
                "type": "pickup",
                "tripId": ""
              }]
            },
            "driver": {
              "id": "",
              "badgeNr": "",
              "name": ""
            },
            "vehicle": {
              "id": "",
              "callsign": "",
              "location": {
                "coord": {
                  "lat": 0.0,
                  "lng": 0.0
                },
                "time": ""
              },
              "odo": 0
            }
          }
        }
      };
      return stopArriveData;
    },

    stopDepartData() {
      let stopDepartData = {
        "data": {
          "type": "routeExecEvent",
          "attributes": {
            "trackingId": "",
            "routeId": "",
            "content": {
              "type": "depart",
              "stopIndex": 0,
              "stopId": "",
              "actualTime": "",
              "passengerEvents": [{
                "passengerId": "",
                "passengerType": "client",
                "type": "pickup",
                "tripId": ""
              }]
            },
            "driver": {
              "id": "",
              "badgeNr": "",
              "name": ""
            },
            "vehicle": {
              "id": "",
              "callsign": "",
              "location": {
                "coord": {
                  "lat": 0.0,
                  "lng": 0.0
                },
                "time": ""
              },
              "odo": 0.0
            }
          }
        }
      };
      return stopDepartData;
    },

    onBreakData() {
      let onBreakData = {
        "data": {
          "type": "routeExecEvent",
          "attributes": {
            "trackingId": "1",
            "routeId": "2",
            "content": {
              "type": "onBreak",
              "actualTime": "2018-07-13T16:52:08.777Z",
              "breakId": "13"
            },
            "driver": {
              "id": "19031",
              "badgeNr": "19031",
              "name": "DRVR"
            },
            "vehicle": {
              "id": "214",
              "callsign": "214",
              "location": {
                "coord": {
                  "lat": 46.05991,
                  "lng": -118.36189
                },
                "time": "2018-07-13T15:52:08.777Z"
              },
              "mov": {
                "speed": 25,
                "heading": 15
              },
              "odo": 154783.6
            }
          }
        }
      };
      return onBreakData;
    },

    offBreakData() {
      let offBreakData = {
        "data": {
          "type": "routeExecEvent",
          "attributes": {
            "trackingId": "1",
            "routeId": "2",
            "content": {
              "type": "offBreak",
              "actualTime": "2018-07-13T16:52:08.777Z",
              "breakId": "13"
            },
            "driver": {
              "id": "19031",
              "badgeNr": "19031",
              "name": "DRVR"
            },
            "vehicle": {
              "id": "214",
              "callsign": "214",
              "location": {
                "coord": {
                  "lat": 46.05991,
                  "lng": -118.36189
                },
                "time": "2018-07-13T15:52:08.777Z"
              },
              "mov": {
                "speed": 25,
                "heading": 15
              },
              "odo": 154783.6
            }
          }
        }
      };
      return offBreakData;
    },

    noShowData() {
      let noShowData = {
        "data": {
          "type": "routeExecEvent",
          "attributes": {
            "trackingId": "",
            "routeId": "",
            "content": {
              "type": "depart",
              "stopIndex": 1,
              "stopId": "",
              "actualTime": "",
              "passengerEvents": [{
                "passengerId": "",
                "passengerType": "",
                "type": "noShow",
                "details": {
                  "code": "",
                  "message": ""
                },
                "tripId": ""
              }]
            },
            "driver": {
              "id": "",
              "badgeNr": "",
              "name": ""
            },
            "vehicle": {
              "id": "",
              "callsign": "",
              "location": {
                "coord": {
                  "lat": 0.0,
                  "lng": 0.0
                },
                "time": ""
              },
              "odo": 0.0
            }
          }
        }
      };
      return noShowData;
    },

    cancelData() {
      let cancelData = {
        "data": {
          "type": "routeExecEvent",
          "attributes": {
            "trackingId": "",
            "routeId": "",
            "content": {
              "type": "depart",
              "stopIndex": 1,
              "stopId": "",
              "actualTime": "",
              "passengerEvents": [{
                "passengerId": "",
                "passengerType": "",
                "type": "cancel",
                "details": {
                  "code": "",
                  "message": ""
                },
                "tripId": ""
              }]
            },
            "driver": {
              "id": "",
              "badgeNr": "",
              "name": ""
            },
            "vehicle": {
              "id": "",
              "callsign": "",
              "location": {
                "coord": {
                  "lat": 0.0,
                  "lng": 0.0
                },
                "time": ""
              },
              "odo": 0.0
            }
          }
        }
      };
      return cancelData;
    },

    prepareRouteStartData(routeStartData, record) {
      routeStartData.data.attributes.routeId = record.id;
      routeStartData.data.attributes.content.actualTime = record.timestamp;
      let assignedDriver = record.get('assignedDriver');
      let assignedVehicle = record.get('assignedDriver');
      routeStartData.data.attributes.driver.id = assignedDriver.get('externalId');
      routeStartData.data.attributes.driver.badgeNr = assignedDriver.get('externalId');
      routeStartData.data.attributes.driver.name = assignedDriver.get('fullName');
      routeStartData.data.attributes.vehicle.id = assignedVehicle.get('name');
      routeStartData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      routeStartData.data.attributes.vehicle.odo = record.get('odometer');
      routeStartData.data.attributes.vehicle.location.time = record.timestamp;
      routeStartData.data.attributes.vehicle.location.coord.lat = record.get('routePlaces.firstObject.place.lat');
      routeStartData.data.attributes.vehicle.location.coord.lng = record.get('routePlaces.firstObject.place.lng');
      return routeStartData;
    },

    prepareRouteEndData(routeEndData, record) {
      routeEndData.data.attributes.routeId = record.id;
      routeEndData.data.attributes.content.actualTime = record.timestamp;
      routeEndData.data.attributes.driver.id = record.get('assignedDriver.externalId');
      routeEndData.data.attributes.driver.badgeNr = record.get('assignedDriver.externalId');
      routeEndData.data.attributes.driver.name = record.get('assignedDriver.firstName');
      routeEndData.data.attributes.vehicle.id = record.get('assignedVehicle.name');
      routeEndData.data.attributes.vehicle.callsign = record.get('assignedVehicle.name');
      routeEndData.data.attributes.vehicle.location.time = record.timestamp;
      routeEndData.data.attributes.vehicle.location.coord.lat = record.get('routePlaces.lastObject.place.lat');
      routeEndData.data.attributes.vehicle.location.coord.lng = record.get('routePlaces.lastObject.place.lng');
      return routeEndData;
    },

    prepareStopArriveData(stopArriveData, record) {
      stopArriveData.data.attributes.trackingId = "1";
      stopArriveData.data.attributes.routeId = record.get('trip.cluster.route.id');
      stopArriveData.data.attributes.content.actualTime = record.timestamp;
      stopArriveData.data.attributes.content.stopId = record.id;
      stopArriveData.data.attributes.content.stopIndex = record.get('ordinal');
      let assignedDriver = record.get('trip.cluster.route.assignedDriver');
      let assignedVehicle = record.get('trip.cluster.route.assignedVehicle');
      stopArriveData.data.attributes.content.passengerEvents[0].passengerId = record.get("trip.rider.id");
      stopArriveData.data.attributes.content.passengerEvents[0].passengerType = "client";

      if (record.get('type') === 'drop') {
        stopArriveData.data.attributes.content.passengerEvents[0].type = "dropoff";
      }

      stopArriveData.data.attributes.content.passengerEvents[0].tripId = record.get('id');
      stopArriveData.data.attributes.driver.id = assignedDriver.get('externalId');
      stopArriveData.data.attributes.driver.badgeNr = assignedDriver.get('externalId');
      stopArriveData.data.attributes.driver.name = assignedDriver.get('fullName');
      stopArriveData.data.attributes.vehicle.id = assignedVehicle.get('name');
      stopArriveData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      stopArriveData.data.attributes.vehicle.odo = record.get('odometer');
      stopArriveData.data.attributes.vehicle.location.time = record.timestamp;
      stopArriveData.data.attributes.vehicle.location.coord.lat = record.get('place.lat');
      stopArriveData.data.attributes.vehicle.location.coord.lng = record.get('place.lng');
      return stopArriveData;
    },

    prepareStopDepartData(stopDepartData, record) {
      stopDepartData.data.attributes.trackingId = "1";
      stopDepartData.data.attributes.routeId = record.get('trip.cluster.route.id');
      stopDepartData.data.attributes.content.actualTime = record.timestamp;
      stopDepartData.data.attributes.content.stopId = record.id;
      let assignedDriver = record.get('trip.cluster.route.assignedDriver');
      let assignedVehicle = record.get('trip.cluster.route.assignedVehicle');
      stopDepartData.data.attributes.content.passengerEvents[0].passengerId = record.get("trip.rider.id");
      stopDepartData.data.attributes.content.passengerEvents[0].passengerType = "client";

      if (record.get('type') === 'drop') {
        stopDepartData.data.attributes.content.passengerEvents[0].type = "dropoff";
      }

      stopDepartData.data.attributes.content.passengerEvents[0].tripId = record.get('id');
      stopDepartData.data.attributes.driver.id = assignedDriver.get('externalId');
      stopDepartData.data.attributes.driver.badgeNr = assignedDriver.get('externalId');
      stopDepartData.data.attributes.driver.name = assignedDriver.get('fullName');
      stopDepartData.data.attributes.vehicle.id = assignedVehicle.get('name');
      stopDepartData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      stopDepartData.data.attributes.vehicle.odo = record.get('odometer');
      stopDepartData.data.attributes.vehicle.location.time = record.timestamp;
      stopDepartData.data.attributes.vehicle.location.coord.lat = record.get('place.lat');
      stopDepartData.data.attributes.vehicle.location.coord.lng = record.get('place.lng');
      return stopDepartData;
    },

    prepareStopNoShowData(noShowData, record) {
      noShowData.data.attributes.trackingId = "1";
      noShowData.data.attributes.routeId = record.get('trip.cluster.route.id');
      noShowData.data.attributes.content.actualTime = record.timestamp;
      noShowData.data.attributes.content.stopId = record.id;
      let assignedDriver = record.get('trip.cluster.route.assignedDriver');
      let assignedVehicle = record.get('trip.cluster.route.assignedVehicle');
      noShowData.data.attributes.content.passengerEvents[0].passengerId = record.get("trip.rider.id");
      noShowData.data.attributes.content.passengerEvents[0].passengerType = "client";

      if (record.get('type') === 'drop') {
        noShowData.data.attributes.content.passengerEvents[0].type = "dropoff";
      }

      noShowData.data.attributes.content.passengerEvents[0].tripId = record.get('id');
      let noShowReasonCode = record.get("noShowReason");
      noShowData.data.attributes.content.passengerEvents[0].details.code = noShowReasonCode.get('externalCode');
      noShowData.data.attributes.content.passengerEvents[0].details.message = record.get('noShowNotes');
      noShowData.data.attributes.driver.id = assignedDriver.get('externalId');
      noShowData.data.attributes.driver.badgeNr = assignedDriver.get('externalId');
      noShowData.data.attributes.driver.name = assignedDriver.get('fullName');
      noShowData.data.attributes.vehicle.id = assignedVehicle.get('name');
      noShowData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      noShowData.data.attributes.vehicle.odo = record.get('odometer');
      noShowData.data.attributes.vehicle.location.time = record.timestamp;
      noShowData.data.attributes.vehicle.location.coord.lat = record.get('place.lat');
      noShowData.data.attributes.vehicle.location.coord.lng = record.get('place.lng');
      return noShowData;
    },

    prepareTripNoShowData(noShowData, record) {
      noShowData.data.attributes.trackingId = "1";
      noShowData.data.attributes.routeId = record.get('dsTrip.cluster.route.id');
      noShowData.data.attributes.content.actualTime = record.timestamp;
      noShowData.data.attributes.content.stopId = record.get('dsTrip.stops.firstObject.id');
      let assignedDriver = record.get('dsTrip.cluster.route.assignedDriver');
      let assignedVehicle = record.get('dsTrip.cluster.route.assignedVehicle');
      noShowData.data.attributes.content.passengerEvents[0].passengerId = record.get("rider.id");
      noShowData.data.attributes.content.passengerEvents[0].passengerType = "client";

      if (record.get('type') === 'drop') {
        noShowData.data.attributes.content.passengerEvents[0].type = "dropoff";
      }

      noShowData.data.attributes.content.passengerEvents[0].tripId = record.get('dsTrip.id');
      let noShowReasonCode = record.get("noShowReason");
      noShowData.data.attributes.content.passengerEvents[0].details.code = noShowReasonCode.get('externalCode');
      noShowData.data.attributes.content.passengerEvents[0].details.message = record.get('noShowNotes'); //driver data

      noShowData.data.attributes.driver.id = assignedDriver.get('externalId');
      noShowData.data.attributes.driver.badgeNr = assignedDriver.get('externalId');
      noShowData.data.attributes.driver.name = assignedDriver.get('fullName'); ///vehicle data

      noShowData.data.attributes.vehicle.id = assignedVehicle.get('name');
      noShowData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      noShowData.data.attributes.vehicle.odo = record.get('odometer');
      noShowData.data.attributes.vehicle.location.time = record.timestamp; //vehicle locatioon data

      noShowData.data.attributes.vehicle.location.coord.lat = record.get('dsTrip.stops.firstObject.place.lat');
      noShowData.data.attributes.vehicle.location.coord.lng = record.get('dsTrip.stops.firstObject.place.lng');
      return noShowData;
    },

    prepareTripCancelData(cancelData, record) {
      cancelData.data.attributes.trackingId = "1";
      cancelData.data.attributes.routeId = record.get('dsTrip.cluster.route.id');
      cancelData.data.attributes.content.actualTime = record.timestamp;
      cancelData.data.attributes.content.stopId = record.get('dsTrip.stops.firstObject.id');
      let assignedDriver = record.get('dsTrip.cluster.route.assignedDriver');
      let assignedVehicle = record.get('dsTrip.cluster.route.assignedVehicle');
      cancelData.data.attributes.content.passengerEvents[0].passengerId = record.get("rider.id");
      cancelData.data.attributes.content.passengerEvents[0].passengerType = "client";

      if (record.get('type') === 'drop') {
        cancelData.data.attributes.content.passengerEvents[0].type = "dropoff";
      }

      cancelData.data.attributes.content.passengerEvents[0].tripId = record.get('dsTrip.id');
      let noShowReasonCode = record.get("noShowReason");
      cancelData.data.attributes.content.passengerEvents[0].details.code = noShowReasonCode.get('externalCode');
      cancelData.data.attributes.content.passengerEvents[0].details.message = record.get('noShowNotes'); //driver data

      cancelData.data.attributes.driver.id = assignedDriver.get('externalId');
      cancelData.data.attributes.driver.badgeNr = assignedDriver.get('externalId');
      cancelData.data.attributes.driver.name = assignedDriver.get('fullName'); ///vehicle data

      cancelData.data.attributes.vehicle.id = assignedVehicle.get('name');
      cancelData.data.attributes.vehicle.callsign = assignedVehicle.get('name');
      cancelData.data.attributes.vehicle.odo = record.get('odometer');
      cancelData.data.attributes.vehicle.location.time = record.timestamp; //vehicle locatioon data

      cancelData.data.attributes.vehicle.location.coord.lat = record.get('dsTrip.stops.firstObject.place.lat');
      cancelData.data.attributes.vehicle.location.coord.lng = record.get('dsTrip.stops.firstObject.place.lng');
      return cancelData;
    }

  });

  _exports.default = _default;
});