define("adept-iq/classes/shapes/zone", ["exports", "adept-iq/classes/shapes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    style: 'zone',
    label: 'Zones',
    parentLabel: 'Zones',
    isActive: true,
    isTraversal: true,
    zone: Ember.computed.alias('record'),
    lat: Ember.computed.alias('zone.lat'),
    lng: Ember.computed.alias('zone.lng'),
    radius: Ember.computed.alias('zone.radius'),
    options: Ember.computed.alias('zone.options'),
    points: Ember.computed.alias('zone.polypoints'),
    caption: Ember.computed.alias('zone.name'),
    status: Ember.computed.alias('zone.status'),
    noOfPoints: Ember.computed.alias('zone.noOfPoints')
  });

  _exports.default = _default;
});