define("adept-iq/serializers/tm-activity-log", ["exports", "adept-iq/serializers/-tm-tripManagement"], function (_exports, _tmTripManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tmTripManagement.default.extend({
    serialize()
    /* snapshot, options */
    {
      let json = this._super(...arguments);

      json.data.type = 'activity-log';
      return json;
    },

    normalizeResponse: function (store, primaryModelClass, payload) {
      const data = Ember.makeArray(payload.data);

      for (let i = 0; i < data.length; i++) {
        data[i].type = 'tm-activity-log';
      }

      return payload;
    }
  });

  _exports.default = _default;
});