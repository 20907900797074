define("adept-iq/pods/components/generic-widgets/base-widget/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    classNames: ['dashboard-widget'],
    classNameBindings: ['disabled'],
    // override with per-widget static configuration
    config: null,
    // widget model (see: `app/classes/widget.js`)
    widget: null,
    width: Ember.computed.alias('widget.width'),
    height: Ember.computed.alias('widget.height'),
    state: Ember.computed.alias('widget.state'),
    isEditing: Ember.computed.alias('workspace.isEditing'),
    disabled: Ember.computed.readOnly('isEditing'),

    didInsertElement() {
      this._super(...arguments);

      this._resizeObserver = new ResizeObserver(() => {
        Ember.run.debounce(this, 'onWidgetResize', 250);
      });

      this._resizeObserver.observe(this.$()[0]);
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this._resizeObserver) {
        this._resizeObserver.disconnect();

        this._resizeObserver = null;
      }
    },

    didRender() {
      this._super(...arguments); //TODO better way to do
      //$("input.can-focus[type='checkbox']:checked").closest('tr').css('background-color', 'yellow');
      // $("input.can-focus[type='checkbox']:not(:checked)").closest('tr').css('background-color', 'white');


      Ember.$("input.can-focus[type='checkbox']:checked").each(function () {
        let firstRow = Ember.$(this).closest('tr');

        if (firstRow) {
          firstRow.css('background-color', 'yellow'); //firstRow.addClass('checkbox_row_heighlighted');
        }
      });
      Ember.$("input.can-focus[type='checkbox']:not(:checked)").each(function () {
        let firstRow = Ember.$(this).closest('tr');

        if (firstRow) {
          //firstRow.removeClass('checkbox_row_heighlighted');
          firstRow.css('background-color', 'white');
          Ember.$(this).closest('tr:nth-child(2n-1)').css('background-color', 'rgba(230, 231, 235, .4)');
        }
      });
    },

    // override this to resize 3rd party integrations (e.g. map)
    onWidgetResize() {}

  });

  _exports.default = _default;
});