define("adept-iq/utils/populate-new-trip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = populateNewTrip;

  function populateNewTripData(tripTemplate, store) {
    let defaultCardNr = 'CASH';
    let PFCP_GENERAL_CASH_ACCOUNT_NAME = store.peekAll('tm-config-parameter-value').filterBy('configParameter.id', 'PFCP_GENERAL_CASH_ACCOUNT_NAME');

    if (!Ember.isBlank(PFCP_GENERAL_CASH_ACCOUNT_NAME)) {
      defaultCardNr = PFCP_GENERAL_CASH_ACCOUNT_NAME[0].get('value');
    }

    let tripData = tripTemplate.tripData().data.attributes;
    let newtrip = store.createRecord('tm-booking', {
      passengers: tripData.passengers,
      prices: tripData.prices,
      relatedIds: tripData.relatedIds,
      requestedProvider: tripData.requestedProvider,
      source: tripData.source,
      status: tripData.status,
      stopPoints: tripData.stopPoints,
      trackingId: tripData.trackingId,
      acctCardNr: defaultCardNr,
      manualAttributes: [],
      // Helper property after this.
      editmodelcontext: {
        accountTrips: [],
        accountFixedPriceTrips: [],
        callerTrips: [],
        assigns: [],
        swapAddressDisabled: true,
        saveCLIDisabled: true,
        driverNotes: '',
        operatorNotes: '',
        attributes: {
          account: [],
          cli: [],
          address: [],
          area: []
        },
        predefinedDriverNotes: {
          account: '',
          cli: '',
          address: '',
          area: '',
          building: '',
          attribute: '',
          unit: '',
          editTrip: ''
        },
        predefinedOperatorNotes: {
          account: '',
          cli: '',
          address: '',
          area: '',
          building: '',
          attribute: '',
          unit: '',
          editTrip: ''
        },
        editAttributes: {
          account: [],
          cli: [],
          building: [],
          area: [],
          region: [],
          editTrip: []
        }
      }
    });
    newtrip.stopPoints[0].passengerEvents[0].time = new Date();
    newtrip.stopPoints[0].address.tomtomFormattedAddress = {
      address: {
        freeformAddress: ''
      }
    };
    newtrip.stopPoints[1].address.tomtomFormattedAddress = {
      address: {
        freeformAddress: ''
      }
    }; //let subscriptions = this.createSubscriptionRecord1();
    //trip.set('subscriptions',subscriptions);
    //////console.log(('newtrip ',newtrip);

    return newtrip;
  }

  function populateNewTrip(tripTemplate, store) {
    return populateNewTripData(tripTemplate, store);
  }
});