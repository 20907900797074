define("adept-iq/models/tm-operator", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/operator.js */
  var _default = _model.default.extend({
    operatorId: (0, _attr.default)('string'),
    name: (0, _attr.default)('string') //source: belongsTo('tm-source')

  });

  _exports.default = _default;
});