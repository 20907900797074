define("adept-iq/classes/polylines/trip", ["exports", "adept-iq/classes/polylines/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    iqTrip: Ember.computed.alias('record'),
    points: Ember.computed.alias('iqTrip.trip.polyline'),
    stopsIsVisible: null,
    arrowStyle: Ember.computed('iqTrip.otp', function () {
      const otp = this.get('iqTrip.otp');

      switch (otp) {
        case 'D':
          return 'tripArrowPlannedDanger';

        case 'L':
          return 'tripArrowPlannedLate';

        default:
          return 'tripArrowPlanned';
      }
    }),

    /*eslint-disable */
    options: {
      weight: 2
    }
    /*eslint-enable */

  });

  _exports.default = _default;
});