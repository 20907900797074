define("adept-iq/pods/components/iq-widgets/zones-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/zones-widget',
    rowComponent: 'table/rows/zonestat-formatted-row',
    modelName: 'zone',
    accessKey: '9',
    defaultSortId: 'asap',
    defaultSortAsc: false,
    title: 'Zones',
    columns: [{
      id: 'name',
      type: 'text',
      label: 'Name',
      valuePath: 'name',
      editable: false,
      searchable: true,
      parentLabel: true,
      index: 0,
      isMapLabelVisible: true,
      hidden: false
    }, {
      id: 'asap',
      type: 'number',
      label: 'ASAP',
      valuePath: 'asap',
      index: 1,
      searchable: true,
      editable: false,
      isMapLabelVisible: true,
      highlightable: true,
      imgPath: 'fa-user',
      hidden: false
    }, {
      id: 'pb',
      type: 'number',
      label: 'PB',
      valuePath: 'pb',
      index: 2,
      searchable: true,
      isMapLabelVisible: true,
      imgPath: 'fa-clock-o',
      editable: false,
      hidden: false
    }, {
      id: 'auction',
      type: 'number',
      label: 'Auction',
      index: 3,
      valuePath: 'auction',
      searchable: true,
      isMapLabelVisible: true,
      imgPath: 'fa-gavel',
      editable: false,
      hidden: false
    }, {
      id: 'freeVehicle',
      type: 'number',
      label: 'Free Vehicles',
      valuePath: 'freeVehicle',
      index: 4,
      searchable: true,
      isMapLabelVisible: true,
      imgPath: 'fa-cab',
      editable: false,
      highlightable: true,
      hidden: false
    }, {
      id: 'stcVehicle',
      type: 'number',
      label: 'STC Vehicles',
      valuePath: 'stcVehicle',
      index: 5,
      searchable: true,
      isMapLabelVisible: true,
      imgPath: 'fa-circle-o',
      editable: false,
      highlightable: true,
      hidden: false
    }, {
      id: 'totalVehicle',
      type: 'number',
      label: 'Total Vehicles',
      valuePath: 'totalVehicle',
      index: 6,
      searchable: true,
      isMapLabelVisible: true,
      imgPath: 'fa-cog',
      editable: false,
      highlightable: true,
      hidden: true
    }]
  };
  _exports.default = _default;
});