define("adept-iq/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r8MdtLHe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"application-wrapper\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"workspace\",\"isGlobalSpinnerVisible\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"global-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"workspace\",\"isDashboardPickerOpen\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"dashboard-picker\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"workspace\",\"isDashboardSaveAsOpen\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"dashboard-save-as\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"workspace\",\"isClosingSchedule\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"close-schedule\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"header-nav-wrapper\"],[9],[0,\"\\n    \"],[1,[27,\"header-nav\",null,[[\"dashboards\",\"openDashboard\",\"transitionTo\",\"onLogout\"],[[23,[\"data\",\"dashboards\"]],[27,\"route-action\",[\"openDashboard\"],null],[27,\"route-action\",[\"transitionTo\"],null],[27,\"route-action\",[\"logout\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \\n  \\n\"],[4,\"if\",[[23,[\"workspace\",\"isEditingTimeline\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"timeline-picker-dropdown\"],[9],[0,\"\\n      \"],[1,[21,\"timeline-picker\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"content-wrapper\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"workspace\",\"sideDrawerComponent\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"side-drawer-container\",null,[[\"sideDrawerComponent\"],[[23,[\"workspace\",\"sideDrawerComponent\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});