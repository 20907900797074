define("adept-iq/serializers/avlm-route-exec-event", ["exports", "adept-iq/serializers/-avlm-avlmService"], function (_exports, _avlmAvlmService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlmAvlmService.default.extend({
    normalize(modelClass, resourceHash) {
      let normalized = this._super(...arguments);

      return normalized;
    }

  });

  _exports.default = _default;
});