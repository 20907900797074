define("adept-iq/config/tomtom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tomtom = void 0;
  const tomtom = {
    search: {
      searchKey: 'llnKTAlKB9cZgDidfx4XfQiMTFGp6WS1',
      routingKey: 'llnKTAlKB9cZgDidfx4XfQiMTFGp6WS1',
      countrySet: 'CA',
      center: {
        lat: 49.224734,
        lon: -122.881157
      },
      radius: 20000
    },
    key: 'llnKTAlKB9cZgDidfx4XfQiMTFGp6WS1',
    basePath: '/assets/tomtom',
    // basePath not used on styleUrlMapping values
    styleUrlMapping: {
      main: {
        basic: '/assets/tomtom-dds/styles/baasic_main.json',
        hybrid: '/assets/tomtom/styles/hybrid_main.json',
        labels: '/assets/tomtom/styles/labels_main.json'
      },
      night: {
        basic: '/assets/tomtom/styles/basic_night.json',
        hybrid: '/assets/tomtom/styles/hybrid_night.json',
        labels: '/assets/tomtom/styles/labels_night.json'
      }
    },
    source: 'vector',
    traffic: {
      style: 's3',
      refresh: 5000,
      key: 'llnKTAlKB9cZgDidfx4XfQiMTFGp6WS1',
      diff: true
    },
    trafficFlow: {
      key: 'llnKTAlKB9cZgDidfx4XfQiMTFGp6WS1',
      refresh: 180000
    }
  };
  _exports.tomtom = tomtom;
});