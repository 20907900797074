define("adept-iq/pods/components/close-schedule/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dashboard-picker'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    schedules: null,

    init() {
      this._super(...arguments);

      const schedules = [];
      const routes = this.get('activeContext.workspaceData.route');
      routes.forEach(route => {
        if (!schedules.includes(route.schedule.content)) {
          if (!route.schedule.content.locked) {
            schedules.push(route.schedule.content);
          }
        }
      });
      this.set('schedules', schedules);
    },

    closeSchedule() {
      const schedules = this.get('schedules');
      schedules.forEach(schedule => {
        schedule.set('locked', true);
        schedule.save();
      });
    },

    actions: {
      onYesButtonClick() {
        this.closeSchedule();
        this.get('workspace').popState('closeSchedule');
      },

      onNoButtonClick() {
        this.get('workspace').popState('closeSchedule');
      }

    }
  });

  _exports.default = _default;
});