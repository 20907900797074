define("adept-iq/pods/components/form-components/locations/component", ["exports", "tomtom", "adept-iq/config/environment", "ember-concurrency", "adept-iq/utils/zone-validation", "adept-iq/utils/check-address-overwrite", "adept-iq/utils/check-address-flags", "adept-iq/config/api-urls", "lodash", "adept-iq/utils/check-value", "ember-keyboard-shortcuts"], function (_exports, _tomtom, _environment, _emberConcurrency, _zoneValidation, _checkAddressOverwrite, _checkAddressFlags, _apiUrls, _lodash, _checkValue, _emberKeyboardShortcuts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-locations'],
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    value: null,
    disabled: false,
    addressOptions: null,
    keyboardShortcuts: null,
    // addresses: computed.readOnly('value'),
    addressTypePath: Ember.computed.readOnly('extra.addressTypePath'),
    aliasPath: Ember.computed.readOnly('extra.aliasPath'),
    descriptionPath: Ember.computed.readOnly('extra.descriptionPath'),
    unitPath: Ember.computed.readOnly('extra.unitPath'),
    notesPath: Ember.computed.readOnly('extra.notesPath'),
    streetNrPath: Ember.computed.readOnly('extra.streetNrPath'),
    streetNamePath: Ember.computed.readOnly('extra.streetNamePath'),
    localityPath: Ember.computed.readOnly('extra.localityPath'),
    regionPath: Ember.computed.readOnly('extra.regionPath'),
    subRegionPath: Ember.computed.readOnly('extra.subRegionPath'),
    postalCodePath: Ember.computed.readOnly('extra.postalCodePath'),
    countryPath: Ember.computed.readOnly('extra.countryPath'),
    defaultOptionsPath: Ember.computed.readOnly('extra.defaultOptionsPath'),
    useOptionRecord: Ember.computed.readOnly('extra.useOptionRecord'),
    useRecordWithId: Ember.computed.readOnly('extra.useRecordWithId'),
    pickup: Ember.computed.readOnly('extra.pickup'),
    dropoff: Ember.computed.readOnly('extra.dropoff'),
    swipeAddressAccessKey: Ember.computed.readOnly('extra.swipeAddressAccessKey'),
    locationsPath: 'address.latlng',
    geoNodePath: 'address.geoNode',
    poiSearchPath: _apiUrls.API_SUB_URL.poiSearchPath,
    altPickupAccesskey: null,
    altDropoffAccesskey: null,
    altSwipeAddressKey: null,
    //locationsPath: 'latlng',
    //geoNodePath: 'geoNode',
    addressRecord: null,
    addressInputRecord: null,
    selected: null,

    geocodeAddressChange() {
      const record = this.get('addressRecord');
      const tomtomAddress = this.get('geocode.tomtomAddress');

      if (!Ember.isEmpty(tomtomAddress)) {
        this.convertTTAddressToModel(record, tomtomAddress, true);
        this.disableAddressSearchOnMap(record, record.passengerEvents[0].type);
        this.removeObserver('geocode.tomtomAddress', this.geocodeAddressChange);
      }
    },

    geocodeLatLngChange() {
      const record = this.get('addressRecord');
      const tomtomLatLng = this.get('geocode.tomtomLatLng');

      if (!Ember.isEmpty(tomtomLatLng)) {
        record.address.coord.lat = tomtomLatLng.lat;
        record.address.coord.lng = tomtomLatLng.lon;
      }
    },

    prepareQuery(record) {
      let description = this.get('geocode').get('activePOIDescription');
      let streetNr = '';
      let streetName = '';
      let unitNr = '';
      let region = '';

      if (!Ember.isBlank(record)) {
        streetNr = record.address.streetNr;
        streetName = record.address.streetName;
        unitNr = record.address.unitNr;
        region = record.address.region;
      }

      let searchURL = '';

      if (!Ember.isBlank(description) && !Ember.isBlank(description.trim())) {
        searchURL = searchURL + 'description=' + description.toUpperCase();
      }

      if (!Ember.isBlank(streetNr) && !Ember.isBlank(streetNr.trim())) {
        searchURL = 'streetNr=' + streetNr.toUpperCase();
      }

      if (!Ember.isBlank(streetName) && !Ember.isBlank(streetName.trim())) {
        if (!Ember.isBlank(searchURL)) searchURL = searchURL + '&streetName=' + streetName.toUpperCase();else searchURL = 'streetName=' + streetName.toUpperCase();
      }

      if (!Ember.isBlank(unitNr) && !Ember.isBlank(unitNr.trim()) && !Ember.isBlank(streetNr) && !Ember.isBlank(streetNr.trim()) && !Ember.isBlank(streetName) && !Ember.isBlank(streetName.trim())) {
        if (!Ember.isBlank(searchURL)) searchURL = searchURL + '&unitNr=' + unitNr.toUpperCase();else searchURL = 'unitNr=' + unitNr.toUpperCase();
      }

      if (!Ember.isBlank(region) && !Ember.isBlank(region.trim())) {
        if (!Ember.isBlank(searchURL)) searchURL = searchURL + '&region=' + region.toUpperCase();else searchURL = 'region=' + region.toUpperCase();
      }

      return searchURL;
    },

    // eslint-disable-next-line no-unused-vars
    search: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(50);
      let searchResults;
      searchResults = yield this.getPOIData(this.get('poiSearchPath'), this.get('value'), this.get('optionModelName'));
      return searchResults;
    }),

    getPOIData(optionSearchPath, filterValue, optionModelName) {
      return this.get('ajax').put(_apiUrls.API.tripManagementService.host + optionSearchPath + '=' + this.get('geocode').get('activePOIDescription'), {
        method: 'GET',
        contentType: "application/json"
      }).then(response => {
        return response.data;
      }).catch(function () {
        return [];
      });
    },

    validateAddresses(addressRecord) {
      this.set('editModal.errors', []);
      let me = this;

      if (addressRecord.address.coord.lat === 0 || addressRecord.address.coord.lng === 0) {
        this.get('editModal.errors').pushObject('Address does not have lat/lng. Please select position on the map.');
      } else {
        if (Ember.isBlank(addressRecord.address.streetNr)) {
          this.get('editModal.errors').pushObject(addressRecord.passengerEvents[0].type + ' does not have street number');
        }

        (0, _checkAddressFlags.default)(this.get('record'));
      }
    },

    init() {
      this._super(...arguments);

      let addresses = this.get('value');
      this.set('addresses', addresses);
      this.set('addressOptions', []);
      const defaultOptionsPath = this.get('defaultOptionsPath');

      if (this.get('record') !== null && this.get('record') !== undefined && !Ember.isNone(defaultOptionsPath) && this.get('record').get(defaultOptionsPath) !== null && this.get('record').get(defaultOptionsPath) !== undefined) {
        // this.set('aliasOptions',this.get('record').get(defaultOptionsPath));
        // this.set('addressOptions', this.get('record').get(defaultOptionsPath));
        this.get('record').get(defaultOptionsPath).forEach(address => {
          this.get('addressOptions').push(address);
        });
        addresses.forEach(address => {
          this.get('addressOptions').push(address);
        });
      }

      addresses.forEach(address => {
        this.get('geocode').addressMapPopulate(address);
      });
      addresses.forEach(address => {
        try {
          Ember.set(address.address.tomtomFormattedAddress.address, 'freeformAddress', (0, _checkValue.default)((0, _checkValue.default)(address.address.streetNr) + " " + (0, _checkValue.default)(address.address.streetName) + " " + (0, _checkValue.default)(address.address.region)));
        } catch (e) {
          address.address.tomtomFormattedAddress = {
            address: {
              freeformAddress: (0, _checkValue.default)((0, _checkValue.default)(address.address.streetNr) + " " + (0, _checkValue.default)(address.address.streetName) + " " + (0, _checkValue.default)(address.address.region))
            }
          };
        }

        if (Ember.isBlank(address.address.coord)) {
          try {
            Ember.set(address.address, 'coord', {
              "lat": 0,
              "lng": 0
            });
          } catch (e) {
            address.address.coord = {
              "lat": 0,
              "lng": 0
            };
          }
        }
      });
      let altPickupAccesskey = 'alt+' + this.get('pickup.accessKey');
      let altDropoffAccesskey = 'alt+' + this.get('dropoff.accessKey');
      let altSwipeAddressKey = 'alt+' + this.get('swipeAddressAccessKey');
      this.set('altPickupAccesskey', altPickupAccesskey);
      this.set('altDropoffAccesskey', altDropoffAccesskey);
      this.set('altSwipeAddressKey', altSwipeAddressKey);
      let accessKeyConfig = {
        [altSwipeAddressKey]: {
          action: 'onSwapAddress',
          // action to trigger
          global: true,
          preventDefault: true
        },
        [altPickupAccesskey]: {
          action: 'handlePickupFocus',
          // action to trigger
          global: true,
          preventDefault: true
        },
        [altDropoffAccesskey]: {
          action: 'handleDropoffFocus',
          // action to trigger
          global: true,
          preventDefault: true
        }
      };
      this.set('keyboardShortcuts', accessKeyConfig);
      this.addObserver('geocode.tomtomLatLng', this.geocodeLatLngChange);
    },

    didInsertElement() {
      //console.log('keyboardShortcuts', this.get('keyboardShortcuts'));
      this._super(...arguments);

      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    },

    addressSearchResults(results) {
      const emberResults = []; //let defaultOptions = this.get('record').get(this.get('defaultOptionsPath'));

      results.forEach(result => {
        if (result.type === 'POI' || result.type === 'Point Address' || result.type === 'Address Range') {
          emberResults.push(Ember.Object.create(result));
        }
      });
      /*if(defaultOptions) {
        defaultOptions.forEach(option => {
          emberResults.push(option);
        });
      }*/

      this.set('addressOptions', emberResults);
    },

    searchAddress: (0, _emberConcurrency.task)(function* (term) {
      this.populateAddressModel(this.get('addressInputRecord'), term);

      if (term.length > 3) {
        yield _tomtom.default.geocode().key(_environment.default.tomtom.search.searchKey).query(term).limit(20).countrySet(_environment.default.tomtom.search.countrySet).typeahead(true).center(_environment.default.tomtom.search.center).radius(_environment.default.tomtom.search.radius).callback(this.addressSearchResults.bind(this)).go();
      }
    }),
    convertTTAddressToModel: function (record, ttAddress, checkOverwrite) {
      this.set('record.isForceDirty', true);
      let overwriteAddress = true;
      let overwriteAddressPop = (0, _checkAddressOverwrite.default)(record.address, ttAddress.address);

      if (overwriteAddressPop && checkOverwrite) {
        overwriteAddress = confirm('Do you want to overwrite Address');
      }

      if (overwriteAddress) {
        this.set('record.isForceDirty', true);

        try {
          record.address.streetNr = ttAddress.address.streetNumber;
          record.address.streetName = ttAddress.address.streetName;
          record.address.region = ttAddress.address.municipalitySubdivision || ttAddress.address.municipality;
          record.address.state = ttAddress.address.countrySubdivisionName;
          record.address.postCode = ttAddress.address.postalCode; //record.address.description = ttAddress.poi.name;
          //record.address.country = ttAddress.address.country;
        } catch (e) {
          Ember.set(record.address, 'streetNr', (0, _checkValue.default)(ttAddress.address.streetNumber));
          Ember.set(record.address, 'streetName', (0, _checkValue.default)(ttAddress.address.streetName || ttAddress.address.municipality));
          Ember.set(record.address, 'region', (0, _checkValue.default)(ttAddress.address.municipalitySubdivision || ttAddress.address.municipality));
          Ember.set(record.address, 'state', (0, _checkValue.default)(ttAddress.address.countrySubdivisionName));
          Ember.set(record.address, 'postCode', (0, _checkValue.default)(ttAddress.address.postCode));
          console.log('e', e);
        }

        try {
          Ember.set(record.address.tomtomFormattedAddress.address, 'freeformAddress', (0, _checkValue.default)(record.address.streetNr) + " " + (0, _checkValue.default)(record.address.streetName) + " " + (0, _checkValue.default)(record.address.region));
        } catch (e) {
          console.log('e', e);

          try {
            record.address.tomtomFormattedAddress = {
              address: {
                freeformAddress: (0, _checkValue.default)(record.address.streetNr) + " " + (0, _checkValue.default)(record.address.streetName) + " " + (0, _checkValue.default)(record.address.region)
              }
            };
          } catch (e) {
            console.log('e1 ', e);
          }
        }

        try {
          Ember.set(record.address, 'formattedAddress', (0, _checkValue.default)(ttAddress.freeformAddress));
        } catch (e) {
          record.address.formattedAddress = (0, _checkValue.default)(ttAddress.freeformAddress);
        }
      }

      try {
        record.address.coord.lat = ttAddress.position.lat;
        record.address.coord.lng = ttAddress.position.lon;
      } catch (e) {//console.log(e);
      }

      try {
        let searchURL = this.prepareQuery(record);
        this.store.query('address-lookup', {
          searchURL
        }).then(results => {
          this.get('geocode').addressLookupResults(results, record, this.get('record'));
        }).catch(function (error) {
          console.log('error ', error);
        });
      } catch (e) {
        console.log(e);
      }

      try {
        this.get('geocode').getGeonode(ttAddress.position.lat, ttAddress.position.lon).then(result => {
          const zoneData = this.get('store').peekRecord('zone', result.data.gpsAreaId);
          Ember.set(record.address, 'geoNode', zoneData.get('name'));
          let notes = zoneData.get('notes');
          let zoneDriverNotes = "";
          let zoneOperatorNotes = "";

          if (!Ember.isBlank(notes)) {
            zoneDriverNotes = notes.driver;
            zoneOperatorNotes = notes.dispatcher;
          }

          let tripData = this.get('record');
          Ember.set(tripData, 'editmodelcontext.predefinedDriverNotes.area', zoneDriverNotes);
          Ember.set(tripData, 'editmodelcontext.predefinedOperatorNotes.area', zoneOperatorNotes);
          let options = this.get('store').peekAll('tm-common-operational-attribute');
          let requiredAttributes = zoneData.get('requiredAttributes');
          let areaAttributes = options.filter(function (o1) {
            // if match found return false
            return _lodash.default.findIndex(requiredAttributes, {
              'type': o1.type
            }) === -1 ? false : true;
          });
          Ember.set(tripData, 'editmodelcontext.attributes.area', areaAttributes);
        });
      } catch (e) {//console.log(e);
      }

      try {
        this.get('geocode').activateGeocode(false, record, record.passengerEvents[0].type);
        this.validateAddresses(record);
      } catch (e) {
        console.log('e ', e);
      }

      try {
        this.set('addressOptions', this.get('record').get(this.get('defaultOptionsPath')));
      } catch (e) {}
    },
    clearAddressModel: function (record) {
      // Ember.set(record.address,'unitNr','');
      try {
        record.address.streetNr = '';
        record.address.streetName = '';
        record.address.region = '';
        record.address.state = '';
        record.address.postCode = '';
      } catch (e) {
        Ember.set(record.address, 'streetNr', '');
        Ember.set(record.address, 'streetName', '');
        Ember.set(record.address, 'region', '');
        Ember.set(record.address, 'state', '');
        Ember.set(record.address, 'postCode', '');
      }

      Ember.set(record.address, 'description', '');

      try {
        Ember.set(record.address.tomtomFormattedAddress.address, 'freeformAddress', '');
      } catch (e) {
        console.log('e', e);

        try {
          record.address.tomtomFormattedAddress = {
            address: {
              freeformAddress: ''
            }
          };
        } catch (e) {
          console.log('e1 ', e);
        }
      } //record.address.country = '';


      record.address.coord.lat = 0;
      record.address.coord.lng = 0;
    },
    populateAddressModel: function (record, searchText) {
      //Ember.set(record.address,'unitNr','');
      try {
        record.address.streetNr = '';
        record.address.streetName = searchText;
        record.address.region = '';
        record.address.state = '';
        record.address.postCode = '';
      } catch (e) {
        Ember.set(record.address, 'streetNr', '');
        Ember.set(record.address, 'streetName', searchText);
        Ember.set(record.address, 'region', '');
        Ember.set(record.address, 'state', '');
        Ember.set(record.address, 'postCode', '');
      }

      try {
        Ember.set(record.address.tomtomFormattedAddress.address, 'freeformAddress', searchText);
      } catch (e) {
        console.log('e', e);

        try {
          record.address.tomtomFormattedAddress = {
            address: {
              freeformAddress: searchText
            }
          };
        } catch (e) {
          console.log('e1 ', e);
        }
      }

      Ember.set(record.address, 'description', ''); //record.address.country = '';

      record.address.coord.lat = 0;
      record.address.coord.lng = 0;
    },

    enableAddressSearchOnMap(record, type) {
      Ember.$('html,body,.tomtom-map').addClass('custom-cursor');
      this.get('geocode').set('disabledFindLocationForRecord', true);
      this.get('geocode').activateGeocode(true, record, type, false);
      this.set('addressRecord', record);
      this.addObserver('geocode.tomtomAddress', this.geocodeAddressChange);
    },

    disableAddressSearchOnMap(record, type) {
      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
      this.get('geocode').activateGeocode(false, record, type);
      this.set('addressRecord', null);
      this.removeObserver('geocode.tomtomAddress', this.geocodeAddressChange);
      this.get('geocode').set('disabledFindLocationForRecord', false);
    },

    enableAddressPOIOnMap(record, type) {
      Ember.$('html,body,.tomtom-map').addClass('custom-cursor');
      this.get('geocode').set('activePOIDescription', record.address.description); //this.get('geocode').set('disabledFindLocationForRecord',true);

      this.get('geocode').activatePOIGeocode(true, record, type, false);
      this.set('addressRecord', record);
      this.addObserver('geocode.tomtomAddress', this.geocodeAddressChange);
    },

    disableAddressPOIOnMap(record, type) {
      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
      this.get('geocode').set('activePOIDescription', '');
      this.get('geocode').activatePOIGeocode(false, record, type);
      this.set('addressRecord', null);
      /*this.removeObserver('geocode.tomtomAddress', this.geocodeAddressChange);
      this.get('geocode').set('disabledFindLocationForRecord', false);*/
    },

    actions: {
      onInput(record, valuePath, value) {
        Ember.set(record, valuePath, value);
        this.set('record.isForceDirty', true);
      },

      onFocus(select, e) {
        Ember.$('.ember-power-select-search-input').focus();
        e.stopPropagation();
      },

      onClose(record, valuePath, dropdown, e) {
        if (valuePath) {
          this.input = document.querySelector('.ember-power-select-trigger[id=' + valuePath + ']');

          if (this.input) {
            Ember.run.next(this.input, 'focus');
          }
        }
      },

      onAddressSelected(record, valuePath, value) {
        console.log('onAddressSelected ', value);

        if (this.get('useRecordWithId') && !Ember.isNone(value.get('id'))) {
          this.set('record.place', value);
          this.set('addresses', [value]);
        } else {
          if (!(0, _zoneValidation.insideZone)([value.get('position.lat'), value.get('position.lon')])) {
            this.get('errorMessage').pushError({
              detail: 'Invalid Zone'
            });
            return;
          }

          this.convertTTAddressToModel(record, value, false);
        }
      },

      onAliasAddressSelected(record, valuePath, value) {
        this.convertTTAliasAddressToModel(record, value);
      },

      onPickOnMap(record) {
        const activeGeocode = this.get('geocode.activeGeocode');

        if (activeGeocode) {
          this.disableAddressSearchOnMap(record, record.passengerEvents[0].type);
        } else {
          this.enableAddressSearchOnMap(record, record.passengerEvents[0].type);
        }
      },

      onPOIOnMap(record) {
        const activePOIGeocode = this.get('geocode.activePOIGeocode');

        if (activePOIGeocode) {
          this.disableAddressPOIOnMap(record, 'poi');
        } else {
          this.enableAddressPOIOnMap(record, 'poi');
        }
      },

      onAddNewAddress(record, valuePath, modelName) {
        const newAddress = this.get('editModal').pushRecordValue(record, valuePath, modelName);
        this.get('editModal').pushRecordValue(newAddress, 'locations', 'rms-location');
      },

      onRemoveAddress(record, valuePath, value) {
        let options = {
          record,
          valuePath
        };
        this.get('onRemove')(value, options);
      },

      handleKeydown(record, type, index, e, event) {
        this.set('addressInputRecord', record);
        const key = event.keyCode;

        if (key == 34 || key == 27 || key == 92 || key == 91 || key == 93 || key == 94 || key == 62 || key == 60 || key == 123 || key == 124 || key == 125 || key == 16 || key == 39 || event.ctrlKey || event.altKey || event.shiftKey) {
          return;
        }

        var inp = String.fromCharCode(event.keyCode);

        if (/[a-zA-Z0-9-_ ]/.test(inp) || event.keyCode === 8) {
          this.populateAddressModel(record, event.target.value);
          this.set('record.isForceDirty', true);
          this.set('addressRecord', record);
          (0, _checkAddressFlags.default)(this.get('record'));
        }

        if (e.keyCode === 13) {
          this.set('editmodalcontext.showWidgetModal', false);
          this.set('editmodalcontext.modelWidgetPath', '');
          this.get('geocode').set('activePOIResults', []);
          this.clearAddressModel(record);
          this.populateAddressModel(record, event.target.value);
          this.set('record.isForceDirty', true);
          this.set('addressRecord', record);
          (0, _checkAddressFlags.default)(this.get('record'));
        }
      },

      onSwapAddress() {
        let record = this.get('record');

        let pickAddress = _lodash.default.cloneDeep(record.stopPoints[0]);

        let dropAddress = _lodash.default.cloneDeep(record.stopPoints[1]);

        this.set('record.stopPoints.1.address', _lodash.default.cloneDeep(pickAddress.address));
        this.set('record.stopPoints.0.address', _lodash.default.cloneDeep(dropAddress.address));
        this.get('geocode').deactivateGeocode();
        let pickAddressResult = [];
        pickAddressResult.push(dropAddress);
        this.get('geocode').set('markerType', 'pickup');
        this.get('geocode').set('currentRecord', record.stopPoints[0]);
        this.get('geocode').addressMapPopulate(record.stopPoints[0]);
        let dropAddressResult = [];
        dropAddressResult.push(pickAddress);
        this.get('geocode').set('markerType', 'dropoff');
        this.get('geocode').set('currentRecord', record.stopPoints[1].address);
        this.get('geocode').addressMapPopulate(record.stopPoints[1]);
      },

      handlePickupFocus() {
        this.input = document.querySelector(`.ember-power-select-trigger[id=pickup]`);

        if (this.input) {
          Ember.run.next(this.input, 'focus');
        }
      },

      handleDropoffFocus() {
        this.input = document.querySelector(`.ember-power-select-trigger[id=dropoff]`);

        if (this.input) {
          Ember.run.next(this.input, 'focus');
        }
      },

      handlePOIKeydown(record, valuePath, type, index, event) {
        let me = this;
        console.log('handlePOIKeydown ', record);

        if (event.keyCode !== 13) {
          return;
        }

        this.set('editmodalcontext.showWidgetModal', false);
        this.set('editmodalcontext.modelWidgetPath', '');
        this.get('geocode').set('activePOIRecord', record);
        this.get('geocode').set('activePOIDescription', record.address.description);
        this.get('geocode').set('activePOIIndex', index);
        this.get('geocode').set('markerType', type);
        this.get('geocode').set('activePOIResults', []);
        this.set('editmodalcontext.addressRecord', record);
        this.set('editmodalcontext.record', this.get('record')); //Ankur Need to enable if allow marker click
        // this.set('addressRecord', record);
        // this.addObserver('geocode.tomtomAddress', this.geocodeAddressChange);
        //this.get('geocode').set('disabledFindLocationForRecord',true);

        let searchURL = this.prepareQuery();
        this.store.query('address-lookup', {
          searchURL
        }).then(results => {
          this.get('geocode').addressPOISearchResults(results);
        }).catch(function (error) {
          me.get('editModal.errors').pushObject('No result found');
        });
      },

      handleUnitKeydown(record, valuePath, type, index, event) {
        let me = this;

        if (event.keyCode !== 13) {
          return;
        }

        this.set('editmodalcontext.showWidgetModal', false);
        this.set('editmodalcontext.modelWidgetPath', '');
        this.get('geocode').set('activePOIRecord', record);
        this.get('geocode').set('activePOIIndex', index);
        this.get('geocode').set('markerType', type); //Ankur Need to enable if allow marker click
        // this.set('addressRecord', record);
        // this.addObserver('geocode.tomtomAddress', this.geocodeAddressChange);
        //this.get('geocode').set('disabledFindLocationForRecord',true);

        try {
          let searchURL = this.prepareQuery(record);
          this.store.query('address-lookup', {
            searchURL
          }).then(results => {
            this.get('geocode').addressUnitLookupResults(results, record, this.get('record'));
          }).catch(function (error) {
            me.get('editModal.errors').pushObject('No result found');
          });
        } catch (e) {
          console.log(e);
        }
      }

    }
  });

  _exports.default = _default;
});