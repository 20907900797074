define("adept-iq/utils/check-address-flags", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = checkAddressFlags;

  function checkAddressFlags(record) {
    try {
      let pickAddress = _lodash.default.cloneDeep(record.stopPoints[0]);

      let dropAddress = _lodash.default.cloneDeep(record.stopPoints[1]); // Enble this code if require same functionality as JCallbooker


      let isPickupPresent = true;
      let isDropPresent = true;
      let isCliDataPresent = true;

      if (Ember.isBlank(record.passengers[0].phone1.number)) {
        isCliDataPresent = false;
      }

      if (Ember.isBlank(record.passengers[0].firstName)) {
        isCliDataPresent = false;
      }

      if (Ember.isEmpty(pickAddress) || Ember.isEmpty(pickAddress.address) || Ember.isEmpty(pickAddress.address.streetNr) && Ember.isEmpty(pickAddress.address.streetName)) {
        isPickupPresent = false;
      }

      if (Ember.isEmpty(dropAddress) || Ember.isEmpty(dropAddress.address) || Ember.isEmpty(dropAddress.address.streetNr) && Ember.isEmpty(dropAddress.address.streetName)) {
        isDropPresent = false;
      }

      if (isPickupPresent && isDropPresent) {
        record.set('editmodelcontext.swapAddressDisabled', false);
      }

      if (isPickupPresent) {
        record.set('editmodelcontext.saveCLIDisabled', false);
      }
    } catch (e) {
      console.log(e);
    }

    return;
  }
});