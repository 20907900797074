define("adept-iq/pods/components/side-drawer/map-label-settings/component", ["exports", "adept-iq/config/column-types", "adept-iq/config/filter-types"], function (_exports, _columnTypes, _filterTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mapContext: Ember.inject.service(),
    classNames: ['filter-settings'],
    columnTypesHash: _columnTypes.columnTypesHash,
    filterTypesHash: _filterTypes.filterTypesHash,
    layer: null,
    toggleEye: false,
    isEyeOpen: Ember.computed.not('toggleEye'),
    sortedLabels: Ember.computed('layer.labels', function () {
      const labels = this.get('layer.labels');
      const sortedLabels = labels.sortBy('index');
      return sortedLabels;
    }),
    actions: {
      toggleCheckBoxes() {
        const layer = this.get('layer');
        const sortedLabels = this.get('sortedLabels');
        const eye = this.toggleProperty('isEyeOpen');
        sortedLabels.forEach(label => {
          const layerId = Ember.get(layer, 'id');
          const labelId = Ember.get(label, 'id');
          this.get('mapContext').setLayerLabelVisibility(layerId, labelId, eye);
        });

        if (!eye) {
          const labelId = Ember.get(sortedLabels[0], 'id');
          const layerId = Ember.get(layer, 'id');
          this.get('mapContext').setLayerLabelVisibility(layerId, labelId, !eye);
        }
      },

      onReorderItems(labels) {
        const layer = this.get('layer');
        const labelIds = labels.mapBy('id');
        this.get('mapContext').setLayerLabelsOrder(layer.id, labelIds);
      },

      setVisibility(label, event) {
        const layer = this.get('layer');
        const layerId = Ember.get(layer, 'id');
        const labelId = Ember.get(label, 'id');
        this.get('mapContext').setLayerLabelVisibility(layerId, labelId, event.target.checked);
      },

      onSelectFilterType(label, filterType) {
        const layer = this.get('layer');
        const layerId = Ember.get(layer, 'id');
        const labelId = Ember.get(label, 'id');
        const filterTypeId = Ember.get(filterType, 'id');
        this.get('mapContext').setLayerLabelFilterType(layerId, labelId, filterTypeId);
      },

      onFilterValueChange(label, index, event) {
        const layer = this.get('layer');
        const layerId = Ember.get(layer, 'id');
        const labelId = Ember.get(label, 'id');
        let filterValues = Ember.get(label, 'filterValues') || []; // treat filter values as immutable

        filterValues = filterValues.slice();
        filterValues[index] = event.target.value;
        this.get('mapContext').setLayerLabelFilterValues(layerId, labelId, filterValues);
      },

      clearFilters() {
        const dashboard = this.get('mapContext.workspace.dashboard');
        dashboard.set('mapState', null);
      }

    }
  });

  _exports.default = _default;
});