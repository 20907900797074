define("adept-iq/utils/focus_column_widget_row", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = focusFirstItem;

  function focusFirstItem(me) {
    let className = me.get('classNames')[me.get('classNames').length - 1];
    let isSelected = Ember.$(':scope .' + className + ' .is-selected').first();

    if (Ember.isBlank(isSelected[0])) {
      const el = Ember.$(':scope .' + className + ' .lt-row .table-base-cell').first(); // auto-focus on first input control

      if (el) {
        const currentRow = Ember.$(':scope .' + className + ' .lt-row').first();
        const nextRow = currentRow[0];

        if (nextRow && nextRow.tagName !== 'OCCLUDED-CONTENT') {
          // move table selection down one row
          const tableRows = me.get('table.rows');
          tableRows.setEach('selected', false);
          const recordId = nextRow.getAttribute('data-record-id');
          const nextTableRow = tableRows.findBy('content.id', recordId);
          nextTableRow.set('selected', true); // move browser focus to same column in next row

          const nextLtCell = nextRow.children[0];
          const focusElement = nextLtCell.querySelector('.can-focus') || nextLtCell.firstElementChild;

          if (focusElement) {
            focusElement.focus();
          }
        }
      }
    } else {
      const nextRow = isSelected[0];

      if (nextRow && nextRow.tagName !== 'OCCLUDED-CONTENT') {
        // move table selection down one row
        const tableRows = me.get('table.rows');
        tableRows.setEach('selected', false);
        const recordId = nextRow.getAttribute('data-record-id');
        const nextTableRow = tableRows.findBy('content.id', recordId);
        nextTableRow.set('selected', true); // move browser focus to same column in next row

        const nextLtCell = nextRow.children[0];
        const focusElement = nextLtCell.querySelector('.can-focus') || nextLtCell.firstElementChild;

        if (focusElement) {
          focusElement.focus();
        }
      }
    }
  }
});