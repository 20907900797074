define("adept-iq/models/attribute", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _emberDataModelFragments.default.Fragment.extend({
    name: attr('string'),
    key: attr('string')
  });

  _exports.default = _default;
});