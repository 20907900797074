define("adept-iq/services/map-context", ["exports", "lodash", "ember-inflector", "adept-iq/config/column-types", "adept-iq/config/filter-types", "adept-iq/config/map-layers", "adept-iq/classes/markers/agency", "adept-iq/classes/markers/stop", "adept-iq/classes/markers/vehicle", "adept-iq/classes/polylines/route", "adept-iq/classes/polylines/trip", "adept-iq/classes/polygons/zone", "adept-iq/classes/circles/stand", "adept-iq/classes/shapes/zone", "adept-iq/classes/shapes/stand", "adept-iq/config/environment", "tomtom", "adept-iq/utils/filters", "adept-iq/pods/components/generic-widgets/column-widget/utils/compute-ordered-ids"], function (_exports, _lodash, _emberInflector, _columnTypes, _filterTypes, _mapLayers, _agency, _stop, _vehicle, _route, _trip, _zone, _stand, _zone2, _stand2, _environment, _tomtom, _filters, _computeOrderedIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    flatten
  } = _lodash.default;
  const DEFAULT_LATLNG = [_environment.default.tomtom.search.center.lat, _environment.default.tomtom.search.center.lon]; // DDS Canada

  var _default = Ember.Service.extend(Ember.Evented, {
    activeContext: Ember.inject.service(),
    animationTimer: Ember.inject.service(),
    store: Ember.inject.service(),
    editModal: Ember.inject.service(),
    workspace: Ember.inject.service(),
    isSearchEnabled: false,
    searchText: '',
    MCGTrips: _tomtom.default.L.markerClusterGroup({
      zoomToBoundsOnClick: false,
      spiderfyOnMaxZoom: false,
      iconCreateFunction: function (cluster) {
        return _tomtom.default.L.divIcon({
          html: cluster.getChildCount(),
          className: 'myJobcluster',
          iconSize: _tomtom.default.L.point(40, 40)
        });
      }
    }),
    MCGVehicles: _tomtom.default.L.markerClusterGroup({
      zoomToBoundsOnClick: false,
      spiderfyOnMaxZoom: false,
      iconCreateFunction: function (cluster) {
        return _tomtom.default.L.divIcon({
          html: cluster.getChildCount(),
          className: 'myVehiclecluster',
          iconSize: _tomtom.default.L.point(40, 40)
        });
      }
    }),
    config: _mapLayers.default,
    contextMenu: null,
    contextMenuOptions: null,
    contextMenuPosition: null,
    contextMenuRecord: null,

    init() {
      this._super(...arguments);

      this.set('contextMenuOptions', [{
        id: 'edit',
        name: 'Edit',
        action: model => {
          const inflector = new _emberInflector.default(_emberInflector.default.defaultRules);
          const modelName = inflector.pluralize(model.constructor.modelName);
          const component = `iq-widgets/${modelName}-form-widget`;
          this.get('editModal').open(component, [model]);
        }
      }]);
    },

    isFiltered: Ember.computed('layers', function () {
      return !!this.get('layers').find(({
        labels
      }) => {
        return !!labels.find(label => {
          const {
            filterTypeId,
            filterValues
          } = label;
          const filterType = _filterTypes.filterTypesHash[filterTypeId];
          return (0, _filters.testFilterValues)(filterType, filterValues);
        });
      });
    }),
    // merge dashboard's `mapState` on top of layer config defaults
    layers: Ember.computed('workspace.dashboard.mapState', function () {
      const config = _lodash.default.cloneDeep(_mapLayers.default);

      const mapState = this.get('workspace.dashboard.mapState'); // remove labels that are un-available

      config.forEach(currConfig => {
        const labels = [];
        currConfig.labels.forEach(label => {
          if (!label.unAvailable) {
            labels.push(label);
          }
        });

        if (labels.length > 0) {
          currConfig.labels = labels;
        }
      });
      if (!mapState) return config;
      Object.entries(mapState).forEach(([layerId, layerState]) => {
        const layer = config.findBy('id', layerId);
        if (!layer) return;
        ['isVisible', 'opacity'].forEach(property => {
          if (Ember.isNone(layerState[property])) return;
          layer[property] = layerState[property];
        });

        if (layerState.types) {
          Object.entries(layerState.types).forEach(([typeId, typeState]) => {
            const type = layer.types.findBy('id', typeId);
            if (!type) return;
            ['isVisible'].forEach(property => {
              if (Ember.isNone(typeState[property])) return;
              type[property] = typeState[property];
            });
          });
        }

        if (layerState.labels) {
          Object.entries(layerState.labels).forEach(([labelId, labelState]) => {
            const label = layer.labels.findBy('id', labelId);
            if (!label) return;
            ['isVisible', 'index', 'filterTypeId', 'filterValues'].forEach(property => {
              if (Ember.isNone(labelState[property])) return;
              label[property] = labelState[property];
            });
          });
        }

        layer.labels = layer.labels || []; // ensure every label has index

        const orderedIds = (0, _computeOrderedIds.default)(layer.labels);
        orderedIds.forEach((labelId, index) => {
          const label = layer.labels.findBy('id', labelId);
          label.index = index;
        });
      });
      return config;
    }),
    lat: Ember.computed('activeContext.checkedItems.[]', function () {
      console.log('activeContext.checkedItems ', this.get('activeContext.checkedItems'));
      const lats = this.get('activeContext.checkedItems').map(function (a) {
        return a.record.lat;
      });
      const lat = this.computeCenter(lats);
      return isNaN(lat) ? DEFAULT_LATLNG[0] : lat;
    }),
    lng: Ember.computed('activeContext.checkedItems.[]', function () {
      const lngs = this.get('activeContext.checkedItems').map(function (a) {
        return a.record.lng;
      });
      const lng = this.computeCenter(lngs);
      return isNaN(lng) ? DEFAULT_LATLNG[1] : lng;
    }),
    // eslint-disable-next-line ember/no-duplicate-dependent-keys
    polylines: Ember.computed('activeContext.implicitData', 'layers', 'searchText', function () {
      // each item is a store record, with `otp` if so endowed
      const items = this.get('activeContext.implicitData');
      const searchText = this.get('searchText');
      const layers = this.get('layers').filterBy('type', 'polylines');
      const layerPolylines = layers.map(layer => {
        if (layer.isVisible === false) return [];
        const layerItems = items.filterBy('modelName', layer.modelName);
        const layerRecords = layerItems.mapBy('record');
        const filterNode = (0, _filters.buildCompoundFilterNode)('and', [this.buildSearchFilterNode(layer, searchText), this.buildColumnFilterNode(layer)]);
        const filter = (0, _filters.buildFilterFunction)(filterNode);
        const stopsIsVisible = this.get('layers').findBy('id', 'stops').isVisible;
        return layer.types.map(type => {
          if (type.isVisible === false) return [];
          let typeRecords = type.valueKey ? layerRecords.filterBy(type.valueKey, type.id) : layerRecords;
          const filteredRecords = typeRecords.filter(filter);
          return filteredRecords.reduce((arr, record) => {
            const polyline = this.polylineObjectFor(layer, type, record);
            const label = this.getLabel(record, layer);
            let otpStatus = ''; // Include Routes when otpStatus is mocked or provided.

            if (layer.id === 'trips') {
              otpStatus = this._getOTP_ODLE_styleSuffix(record);
            }

            polyline.setProperties({
              style: `${type.style}${otpStatus}`,
              type: type.id,
              label: label,
              opacity: layer.opacity,
              otpStatus
            });

            if (layer.id === 'trips') {
              polyline.set('stopsIsVisible', stopsIsVisible);
            }

            arr.push(polyline);
            return arr;
          }, []);
        });
      });
      return flatten(flatten(layerPolylines));
    }),
    // eslint-disable-next-line ember/no-duplicate-dependent-keys
    markers: Ember.computed('activeContext.serverFilteringChecked', 'activeContext.{implicitData,emergencyVehicles}', 'layers', 'searchText', function () {
      const vehicles = this.get('activeContext.emergencyVehicles').toArray();
      const stops = this.get('activeContext.activeStops').toArray();
      const items = this.get('activeContext.implicitData');
      const searchText = this.get('searchText');
      const layers = this.get('layers').filterBy('type', 'markers');
      const layerMarkers = layers.map(layer => {
        if (layer.isVisible === false) return [];
        let layerRecords = [];

        if (layer.isActive) {
          //layerRecords = items.filterBy('modelName', layer.modelName).mapBy('record');
          if (layer.modelName === 'vehicle') {
            layerRecords = layerRecords.pushObjects(vehicles).uniqBy('id');
          } else if (layer.modelName === 'stop') {
            layerRecords = layerRecords.pushObjects(stops).uniqBy('id');
          }
        } else {
          // TODO: query `workspace-context` instead of store
          layerRecords = items.filterBy('modelName', layer.modelName).mapBy('record');
        }

        const filterNode = (0, _filters.buildCompoundFilterNode)('and', [this.buildSearchFilterNode(layer, searchText), this.buildColumnFilterNode(layer)]);
        const filter = (0, _filters.buildFilterFunction)(filterNode);
        return layer.types.map(type => {
          if (type.isVisible === false) return [];
          let typeRecords = layerRecords;

          if (type.valueKey) {
            typeRecords = layerRecords.filter(record => {
              return record.get(type.valueKey) === type.id;
            });
          }

          const filteredRecords = typeRecords.filter(filter);
          return filteredRecords.reduce((arr, record) => {
            const marker = this.markerObjectFor(layer, type, record); // TODO: move this to marker model

            let markerStyle = type.style;
            markerStyle = this._getLayerStyleSuffix(record) + record.get('statusValue'); //this._getOTPstyleSuffix(record, type.id);

            marker.setProperties({
              style: markerStyle,
              type: type.id,
              label: this.getLabel(record, layer),
              MCGLabel: this.getMCGLabel(record, layer),
              opacity: layer.opacity,
              isActive: layer.isActive,
              isTraversal: layer.isTraversal,
              isMCG: layer.isMCG,
              MCGTrips: this.get('MCGTrips'),
              MCGVehicles: this.get('MCGVehicles')
            });
            arr.push(marker);
            return arr;
          }, []);
        });
      });
      return flatten(flatten(layerMarkers));
    }),
    polygons: Ember.computed('activeContext.implicitData', 'layers', 'searchText', function () {
      //const items = this.get('store').peekAll('zone');
      const items = this.get('activeContext.implicitData');
      const searchText = this.get('searchText');
      const layers = this.get('layers').filterBy('type', 'polygons');
      const layerPolygons = layers.map(layer => {
        if (layer.isVisible === false) return [];
        let layerRecords;

        if (layer.isActive) {
          layerRecords = items.filterBy('modelName', layer.modelName).mapBy('record');
        } else {
          // TODO: query `workspace-context` instead of store
          layerRecords = this.get('store').peekAll(layer.modelName);
        }

        const filterNode = (0, _filters.buildCompoundFilterNode)('and', [this.buildSearchFilterNode(layer, searchText), this.buildColumnFilterNode(layer)]);
        const filter = (0, _filters.buildFilterFunction)(filterNode);
        return layer.types.map(type => {
          if (type.isVisible === false) return [];
          let typeRecords = layerRecords;

          if (type.valueKey) {
            typeRecords = layerRecords.filter(record => {
              return record.get(type.valueKey) === type.id;
            });
          }

          const filteredRecords = typeRecords.filter(filter);
          return filteredRecords.reduce((arr, record) => {
            const polygon = this.polygonObjectFor(layer, type, record); // TODO: move this to polygon model

            const polygonStyle = type.style;
            polygon.setProperties({
              style: polygonStyle,
              type: type.id,
              label: this.getLabel(record, layer),
              parentLabel: this.getParentLabel(record, layer),
              opacity: layer.opacity,
              isActive: layer.isActive
            });
            arr.push(polygon);
            return arr;
          }, []);
        });
      });
      return flatten(flatten(layerPolygons));
    }),
    circles: Ember.computed('activeContext.implicitData', 'layers', 'searchText', function () {
      //const items = this.get('store').peekAll('zone');
      const items = this.get('activeContext.implicitData');
      const searchText = this.get('searchText');
      const layers = this.get('layers').filterBy('type', 'circles');
      const layerCircles = layers.map(layer => {
        if (layer.isVisible === false) return [];
        let layerRecords;

        if (layer.isActive) {
          layerRecords = items.filterBy('modelName', layer.modelName).mapBy('record');
        } else {
          // TODO: query `workspace-context` instead of store
          layerRecords = this.get('store').peekAll(layer.modelName);
        }

        const filterNode = (0, _filters.buildCompoundFilterNode)('and', [this.buildSearchFilterNode(layer, searchText), this.buildColumnFilterNode(layer)]);
        const filter = (0, _filters.buildFilterFunction)(filterNode);
        return layer.types.map(type => {
          if (type.isVisible === false) return [];
          let typeRecords = layerRecords;

          if (type.valueKey) {
            typeRecords = layerRecords.filter(record => {
              return record.get(type.valueKey) === type.id;
            });
          }

          const filteredRecords = typeRecords.filter(filter);
          return filteredRecords.reduce((arr, record) => {
            const circle = this.circleObjectFor(layer, type, record); // TODO: move this to polygon model

            const circleStyle = type.style;
            circle.setProperties({
              style: circleStyle,
              type: type.id,
              label: this.getLabel(record, layer),
              parentLabel: this.getParentLabel(record, layer),
              opacity: layer.opacity,
              isActive: layer.isActive,
              radius: record.get('radius')
            });
            arr.push(circle);
            return arr;
          }, []);
        });
      });
      return flatten(flatten(layerCircles));
    }),
    // eslint-disable-next-line ember/no-duplicate-dependent-keys
    shapes: Ember.computed('activeContext.implicitData', 'layers', 'searchText', function () {
      //const items = this.get('store').peekAll('zone');
      const items = this.get('activeContext.implicitData');
      const searchText = this.get('searchText');
      const layers = this.get('layers').filterBy('type', 'shapes');
      const layerShapes = layers.map(layer => {
        if (layer.isVisible === false) return [];
        let layerRecords;

        if (layer.isActive) {
          layerRecords = items.filterBy('modelName', layer.modelName).mapBy('record');
        } else {
          // TODO: query `workspace-context` instead of store
          layerRecords = this.get('store').peekAll(layer.modelName);
        }

        const filterNode = (0, _filters.buildCompoundFilterNode)('and', [this.buildSearchFilterNode(layer, searchText), this.buildColumnFilterNode(layer)]);
        const filter = (0, _filters.buildFilterFunction)(filterNode);
        return layer.types.map(type => {
          if (type.isVisible === false) return [];
          let typeRecords = layerRecords;

          if (type.valueKey) {
            typeRecords = layerRecords.filter(record => {
              return record.get(type.valueKey) === type.id;
            });
          }

          const filteredRecords = typeRecords.filter(filter);
          return filteredRecords.reduce((arr, record) => {
            const shape = this.shapeObjectFor(layer, type, record); // TODO: move this to polygon model

            const shapeStyle = type.style;
            shape.setProperties({
              style: shapeStyle,
              type: type.id,
              label: this.getLabel(record, layer),
              parentLabel: this.getParentLabel(record, layer),
              opacity: layer.opacity,
              isActive: layer.isActive,
              radius: record.get('radius'),
              noOfPoints: record.get('noOfPoints')
            });
            arr.push(shape);
            return arr;
          }, []);
        });
      });
      return flatten(flatten(layerShapes));
    }),

    // TODO: this logic should be encapsulated in a data join
    markerObjectFor(layer, layerType, record) {
      if (!this._markerCache) {
        this._markerCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let marker = this._markerCache[id];

      if (!marker) {
        const markerClass = this.markerClassForLayer(layer);
        marker = markerClass.create({
          id,
          record,
          animationTimer: this.get('animationTimer')
        });
        this._markerCache[id] = marker;
      }

      return marker;
    },

    // TODO: this logic should be encapsulated in a data join
    polygonObjectFor(layer, layerType, record) {
      if (!this._polygonCache) {
        this._polygonCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let polygon = this._polygonCache[id];

      if (!polygon) {
        const polygonClass = this.polygonClassForLayer(layer);
        polygon = polygonClass.create({
          id,
          record,
          animationTimer: this.get('animationTimer')
        });
        this._polygonCache[id] = polygon;
      }

      return polygon;
    },

    circleObjectFor(layer, layerType, record) {
      if (!this._circleCache) {
        this._circleCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let circle = this._circleCache[id];

      if (!circle) {
        const circleClass = this.circleClassForLayer(layer);
        circle = circleClass.create({
          id,
          record,
          animationTimer: this.get('animationTimer')
        });
        this._circleCache[id] = circle;
      }

      return circle;
    },

    shapeObjectFor(layer, layerType, record) {
      if (!this._shapeCache) {
        this._shapeCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let shape = this._shapeCache[id];

      if (!shape) {
        const shapeClass = this.shapeClassForLayer(layer);
        shape = shapeClass.create({
          id,
          record,
          animationTimer: this.get('animationTimer')
        });
        this._shapeCache[id] = shape;
      }

      return shape;
    },

    polylineObjectFor(layer, layerType, record) {
      if (!this._polylineCache) {
        this._polylineCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let polyline = this._polylineCache[id];

      if (!polyline) {
        const polylineClass = this.polylineClassForLayer(layer);
        polyline = polylineClass.create({
          id,
          record,
          animationTimer: this.get('animationTimer')
        });
        this._polylineCache[id] = polyline;
      }

      return polyline;
    },

    markerClassForLayer(layer) {
      switch (layer.id) {
        case 'agency':
          return _agency.default;

        case 'vehicles':
          return _vehicle.default;

        case 'stops':
          return _stop.default;

        default:
          throw `unhandled layer id '${layer.id}'`;
      }
    },

    polylineClassForLayer(layer) {
      switch (layer.id) {
        case 'routes':
          return _route.default;

        case 'trips':
          return _trip.default;

        default:
          throw `unhandled layer id '${layer.id}'`;
      }
    },

    polygonClassForLayer(layer) {
      switch (layer.id) {
        case 'zones':
          return _zone.default;

        default:
          throw `unhandled layer id '${layer.id}'`;
      }
    },

    circleClassForLayer(layer) {
      switch (layer.id) {
        case 'stands':
          return _stand.default;

        default:
          throw `unhandled layer id '${layer.id}'`;
      }
    },

    shapeClassForLayer(layer) {
      switch (layer.id) {
        case 'stands':
          return _stand2.default;

        case 'zones':
          return _zone2.default;

        default:
          throw `unhandled layer id '${layer.id}'`;
      }
    },

    buildSearchFilterNode(layer, searchText) {
      if (Ember.isBlank(searchText) || !layer.isVisible) return null;
      if (!layer.isVisible) return null;
      const filterNodes = layer.labels.reduce((arr, label) => {
        if (!label.isSearchable) return arr; // TODO: determine if we shuld be searching hidden labels
        // if (!label.isVisible) return arr;

        const filterType = _columnTypes.columnTypesHash[label.type].searchFilterType;
        const filterNode = (0, _filters.buildValueFilterNode)(filterType, label.valuePath, searchText);
        arr.push(filterNode);
        return arr;
      }, []);
      return (0, _filters.buildCompoundFilterNode)('or', filterNodes);
    },

    buildColumnFilterNode(layer) {
      if (!layer.isVisible) return null;
      const filterNodes = layer.labels.reduce((arr, label) => {
        if (!label.isVisible || !label.isSearchable) return arr;
        const filterType = _filterTypes.filterTypesHash[label.filterTypeId];
        let filterValues = label.filterValues;
        if (!(0, _filters.testFilterValues)(filterType, filterValues)) return arr;
        filterValues = label.filterValues.map(filterType.parseValue);
        const filterNode = (0, _filters.buildValueFilterNode)(filterType, label.valuePath, filterValues);
        arr.push(filterNode);
        return arr;
      }, []);
      return (0, _filters.buildCompoundFilterNode)('and', filterNodes);
    },

    computeCenter(values) {
      if (Ember.isEmpty(values)) return NaN;
      const [min, max] = values.reduce(([min, max], value) => {
        if (Ember.isPresent(value)) {
          min = Math.max(min, value);
          max = Math.min(max, value);
        }

        return [min, max];
      }, [-Infinity, Infinity]); // will return NaN if min and max are infinite

      return (min + max) / 2;
    },

    getLabel(record, layer) {
      const lines = [];
      const sortedLabels = layer.labels.sort((a, b) => a.index - b.index);
      sortedLabels.forEach(label => {
        if (!label.isVisible || label.parentLabel) return;

        if (!Ember.isBlank(label.imgPath)) {
          lines.push(`<i class="fa ${label.imgPath}"></i> ${record.get(label.valuePath)}`);
        } else {
          lines.push(`${label.label} ${record.get(label.valuePath)}`);
        }
      });
      return `${lines.join('<br/>')}`;
    },

    getMCGLabel(record, layer) {
      const lines = [];
      const sortedLabels = layer.labels.sort((a, b) => a.index - b.index);
      sortedLabels.forEach(label => {
        if (!label.isMCGVisible || label.parentLabel) return;

        if (!Ember.isBlank(label.imgPath)) {
          lines.push(`<i class="fa ${label.imgPath}"></i> ${record.get(label.valuePath)}`);
        } else {
          lines.push(`${label.label} ${record.get(label.valuePath)}`);
        }
      });
      return `${lines.join('<br/>')}`;
    },

    getParentLabel(record, layer) {
      const lines = [];
      const sortedLabels = layer.labels.sort((a, b) => a.index - b.index);
      sortedLabels.forEach(label => {
        if (!label.isVisible || !label.parentLabel) return;
        lines.push(`${record.get(label.valuePath)}`);
      });
      return `${lines.join('<br/>')}`;
    },

    setLayerVisibility(layerId, isVisible) {
      this.get('workspace.dashboard').mergeMapState({
        [layerId]: {
          isVisible
        }
      });
    },

    setLayerOpacity(layerId, opacity) {
      this.get('workspace.dashboard').mergeMapState({
        [layerId]: {
          opacity
        }
      });
    },

    setLayerTypeVisibility(layerId, typeId, isVisible) {
      this.get('workspace.dashboard').mergeMapState({
        [layerId]: {
          types: {
            [typeId]: {
              isVisible
            }
          }
        }
      });
    },

    setLayerLabelVisibility(layerId, labelId, isVisible) {
      this.get('workspace.dashboard').mergeMapState({
        [layerId]: {
          labels: {
            [labelId]: {
              isVisible
            }
          }
        }
      });
    },

    setLayerLabelFilterType(layerId, labelId, filterTypeId) {
      this.get('workspace.dashboard').mergeMapState({
        [layerId]: {
          labels: {
            [labelId]: {
              filterTypeId
            }
          }
        }
      });
    },

    setLayerLabelFilterValues(layerId, labelId, filterValues) {
      this.get('workspace.dashboard').mergeMapState({
        [layerId]: {
          labels: {
            [labelId]: {
              filterValues
            }
          }
        }
      });
    },

    setLayerLabelsOrder(layerId, labelIds) {
      const labels = labelIds.reduce((obj, labelId, index) => {
        obj[labelId] = {
          index
        };
        return obj;
      }, {});
      this.get('workspace.dashboard').mergeMapState({
        [layerId]: {
          labels
        }
      });
    },

    setContextMenuPosition(e) {
      this.set('contextMenuPosition', function () {
        return {
          style: {
            top: e.originalEvent.clientY,
            left: e.originalEvent.clientX
          }
        };
      });
    },

    onContextMenuOptionClick(option) {
      const record = this.get('contextMenuRecord');
      this.get('contextMenu').actions.close();

      if (option.action) {
        option.action(record);
      }
    },

    onLayerClick()
    /* modelName, id, e */
    {},

    onLayerDblClick(modelName, id
    /*, e*/
    ) {
      const record = this.get('store').peekRecord(modelName, id);
      this.get('activeContext').replaceCheckedRecordsByType(record);
    },

    onLayerContextMenu(modelName, id, e) {
      const record = this.get('store').peekRecord(modelName, id);
      this.setContextMenuPosition(e);
      this.set('contextMenuRecord', record);
      this.get('contextMenu').actions.open();
    },

    _getOTPstyleSuffix(record, typeId) {
      let otp = record.get('otp'),
          suffix = '';
      if (typeId !== 'pick' && typeId !== 'drop') return suffix;

      if (otp === 'L') {
        suffix += 'Late';
      } else if (otp === 'D') {
        suffix += 'Danger';
      }

      return suffix;
    },

    _getLayerStyleSuffix(record) {
      let suffix = '';

      if (record.constructor.modelName === 'vehicle') {
        suffix = 'vehicle';
      }

      return suffix;
    },

    _getOTP_ODLE_styleSuffix(record) {
      switch (record.get('otpStatus')) {
        case 'L':
          return 'Late';

        case 'D':
          return 'Danger';

        default:
          return '';
      }
    }

  });

  _exports.default = _default;
});