define("adept-iq/pods/components/form-components/phone-model/component", ["exports", "ember-concurrency", "adept-iq/utils/check-address-flags", "adept-iq/utils/populate-account", "adept-iq/utils/populate-call-line", "adept-iq/config/environment", "adept-iq/config/api-urls"], function (_exports, _emberConcurrency, _checkAddressFlags, _populateAccount, _populateCallLine, _environment, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-phone-model'],
    value: null,
    maxlength: null,
    disabled: false,
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    searchservice: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    optionSearchPath: Ember.computed.alias('extra.optionSearchPath'),
    optionModelName: Ember.computed.alias('extra.optionModelName'),
    parentValuePath: Ember.computed.alias('extra.parentValuePath'),
    optionWidgetModel: Ember.computed.alias('extra.optionWidgetModel'),
    optionModelWidgetPath: Ember.computed.alias('extra.optionModelWidgetPath'),
    accountSearchPath: _apiUrls.API_SUB_URL.accountSearchPath,
    modalData: null,

    init() {
      this._super(...arguments);
    },

    // eslint-disable-next-line no-unused-vars
    search: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(50);
      let searchResults;
      searchResults = yield this.getProfileData(this.get('optionSearchPath'), this.get('value'), this.get('optionModelName'));
      return searchResults;
    }),

    getProfileData(optionSearchPath, filterValue, optionModelName) {
      return this.get('ajax').put(_apiUrls.API.tripManagementService.host + `/${optionModelName}?filter=${optionSearchPath}==` + filterValue.toUpperCase(), {
        method: 'GET',
        contentType: "application/json"
      }).then(response => {
        return response.data;
      }).catch(function () {
        return [];
      });
    },

    doPhoneDetailsSearch() {
      this.get('search').perform().then(results => {
        if (!Ember.isEmpty(results)) {
          let searchRecord = results.firstObject;

          if (!Ember.isBlank(this.get('parentValuePath'))) {
            this.set(`record.${this.get('parentValuePath')}`, searchRecord);
          }

          Ember.run(() => {
            let widgetModelData = results; //console.log('widgetModelData ',widgetModelData);

            this.set('record.editmodelcontext.callerTrips', widgetModelData);

            if (widgetModelData && widgetModelData.length > 1) {
              this.set('editmodalcontext.showWidgetModal', true);
              this.set('editmodalcontext.widgetModelData', widgetModelData);
              this.set('editmodalcontext.modelWidgetPath', this.get('optionModelWidgetPath'));
              this.set('editmodalcontext.record', this.get('record'));
            } else if (widgetModelData && widgetModelData.length === 1) {
              (0, _populateCallLine.default)(this.get('record'), results.firstObject.attributes, this.get('geocode'), this.get('store'));
              (0, _populateAccount.default)(this.get('record'), null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), results.firstObject.attributes.account.accountId, true, this.get('value'));
            }

            (0, _checkAddressFlags.default)(this.get('record'));
          });
        } else {
          //console.log('parentValuePath',this.get('parentValuePath') );
          this.set(`record.${this.get('parentValuePath')}`, null);
          this.get('editModal.errors').pushObject('No result found');
        }
      });
    },

    actions: {
      onInput(value) {
        this.set(`record.${this.get('valuePath')}`, value.trim());
      },

      populateCliInfo() {
        let phoneNr = document.getElementById('callerNo').innerHTML;
        let tripRecord = this.get('record');
        tripRecord.set('passengers.0.phone1.number', phoneNr); //trip.passengers[0].name =  rider.name;
        //trip.passengers[0].firstName =  rider.name;

        this.set('phoneSearchValue', phoneNr);
        this.set('editModal.errors', []);
        this.doPhoneDetailsSearch();
      },

      handleKeydown(event) {
        if (event.keyCode !== 13) {
          return;
        }

        this.set('editmodalcontext.showWidgetModal', false);
        this.set('editmodalcontext.modelWidgetPath', '');
        this.set('editModal.errors', []);

        if (this.get('optionModelName')) {
          this.doPhoneDetailsSearch();
        }
      }

    }
  });

  _exports.default = _default;
});