define("adept-iq/pods/components/form-components/account/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-places'],
    editmodalcontext: Ember.inject.service(),
    widgetModelData: null,
    modelWidgetPath: null,
    geocode: Ember.inject.service(),
    isAccountPickups: 'isAccountPickups',
    isAccountFixedPriceTrips: 'isAccountFixedPriceTrips',
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    accountPickups: {
      isButtonEnabledPath: 'isAccountPickups',
      widgetModelDataPath: 'editmodelcontext.accountTrips',
      modelWidgetPath: 'favorite-pickup-widget',
      label: 'Pickups',
      accessKey: 'k'
    },
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    accountFixedPriceTrips: {
      isButtonEnabledPath: 'isAccountFixedPriceTrips',
      widgetModelDataPath: 'editmodelcontext.accountFixedPriceTrips',
      modelWidgetPath: 'fixed-price-trip-widget',
      label: 'Fixed Price Trips',
      accessKey: 'x'
    },

    init() {
      this._super(...arguments);
    },

    actions: {
      populateAccountPickups() {
        //console.log('accountTrips)',this.get('record').get(this.get('accountPickups.widgetModelDataPath')));
        Ember.run(() => {
          this.set('widgetModelData', this.get('record').get(this.get('accountPickups.widgetModelDataPath')));
          this.set('editmodalcontext.modelWidgetPath', this.get('accountPickups.modelWidgetPath'));
          this.set('editmodalcontext.showWidgetModal', false);
          this.set('editmodalcontext.showWidgetModal', true);
        });
      },

      populateAccountFixedPriceTrips() {
        //console.log('accountFixedPriceTrips)',this.get('record').get(this.get('accountFixedPriceTrips.widgetModelDataPath')));
        Ember.run(() => {
          this.set('widgetModelData', this.get('record').get(this.get('accountFixedPriceTrips.widgetModelDataPath')));
          this.set('editmodalcontext.modelWidgetPath', this.get('accountFixedPriceTrips.modelWidgetPath'));
          this.set('editmodalcontext.showWidgetModal', false);
          this.set('editmodalcontext.showWidgetModal', true);
        });
      }

    }
  });

  _exports.default = _default;
});