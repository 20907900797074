define("adept-iq/pods/components/side-drawer/bar-chart-settings/component", ["exports", "adept-iq/pods/components/side-drawer/bar-chart-settings/config", "adept-iq/pods/components/generic-widgets/column-widget/classes/filter-column", "ember-cli-mirage/assert"], function (_exports, _config, _filterColumn, _assert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    workspace: Ember.inject.service(),
    widget: Ember.computed.readOnly('workspace.topOptions.widget'),
    title: 'Bar Chart',
    dataType: null,
    sourceOptions: null,

    init() {
      this._super(...arguments);

      this.set('sourceOptions', _config.default);
      const dataType = this.get('widget.state.dataType');
      this.set('dataType', dataType);

      if (Ember.isPresent(dataType)) {
        this.set('filterColumns', Object.values(dataType.columns).map(col => new _filterColumn.default(col)));
      }
    },

    actions: {
      onTypeSelect(dataType) {
        this.set('dataType', dataType);
        this.set('filterColumns', Object.values(dataType.columns).map(col => new _filterColumn.default(col)));
        this.get('widget').mergeState({
          dataType
        });
      },

      setDisplayOptionVisibility(selected) {
        try {
          Ember.run(() => {
            this.set('dataType.displayOptions.selected', selected); // We don't want to merge properties like `valueCategories`.

            Ember.set(this.get('widget'), 'state.dataType', this.get('dataType'));
            this.get('widget').notifyPropertyChange('state');
          });
        } catch (e) {
          // Catches API errors, such as missing Mirage route `/travel-needs`
          if (!(e instanceof _assert.MirageError)) {
            throw e;
          }
        }
      },

      setDisplayOptionMetric(selected) {
        this.set('dataType.displayOptions.selected.metricOption', selected);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      },

      onFilterValueChange(column, index, event) {
        let filterValues = column.get('filterValues') || [];
        filterValues = filterValues.slice();
        filterValues[index] = event.target.value; // Update the displayed filter column.

        Ember.set(column, 'filterValues', filterValues);
        const id = column.get('id'); // Update the column config.

        Ember.set(this.get('dataType'), `columns.${id}.filterValues`, filterValues);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      },

      onSelectFilterType(column, filterType) {
        // Update the displayed filter column.
        Ember.set(column, 'filterTypeId', filterType.id);
        const id = column.get('id'); // Update the column config.

        Ember.set(this.get('dataType'), `columns.${id}.filterTypeId`, filterType.id);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      },

      onTitleInput(data) {
        this.set('dataType.title', data);
        this.get('widget').mergeState({
          dataType: this.get('dataType')
        });
      },

      setVisualOptionVisibility(selected, event) {
        const dataType = this.get('dataType');
        Ember.set(selected, 'isChecked', event.target.checked);
        this.get('widget').mergeState({
          dataType
        });
      }

    }
  });

  _exports.default = _default;
});