define("adept-iq/services/editmodalcontext", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    available: null,
    assigns: null,
    showWidgetModal: false,
    widgetModelData: null,
    modelWidgetPath: null,
    saveStatus: false,
    lastBookedId: null,
    editComponentAction: null,

    init() {
      this._super(...arguments);

      this.set('assigns', []);
      this.set('widgetModelData', []);
      this.set('modelWidgetPath', null);
    },

    setAssigns(assigment) {
      //this.set('assigns',[]);
      this.set('assigns', assigment);
    },

    setWidgetModelData(widgetModelData) {
      //this.set('assigns',[]);
      this.set('widgetModelData', widgetModelData);
    },

    setshowWidgetModal(showWidgetModal) {
      //this.set('assigns',[]);
      this.set('showWidgetModal', showWidgetModal);
    },

    deactivateAttributes() {
      this.set('available', null);
      this.set('assigns', null);
    },

    reinitilized() {
      this.set('assigns', []);
      this.set('showWidgetModal', false);
      this.set('widgetModelData', []);
      this.set('modelWidgetPath', null);
    }

  });

  _exports.default = _default;
});