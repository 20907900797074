define("adept-iq/pods/components/side-drawer/route-activity-log/config", ["exports", "moment", "lodash", "adept-iq/config/environment"], function (_exports, _moment, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.logEntryTypes = _exports.columns = void 0;
  const TIME_FORMAT = 'YYYY-MM-DD hh:mmA'; // matches {{handleBars}}-style interpolation

  const TEMPLATE_INTERPOLATION = /{{([\s\S]+?)}}/g;

  const interpolate = (template, attrs) => {
    const compiledTemplate = _lodash.default.template(template, {
      interpolate: TEMPLATE_INTERPOLATION
    });

    return compiledTemplate(attrs);
  };

  function convertDistance(distanceInMeters, distanceUnit) {
    if (distanceUnit === 'mi') {
      return (distanceInMeters * 0.00062137).toFixed(0);
    } else if (distanceUnit === 'km') {
      return (distanceInMeters * 0.001).toFixed(0);
    }

    console.warn(`Invalid distance unit used for conversion = ${distanceUnit}`); // eslint-disable-line no-console

    return distanceInMeters;
  }

  const columns = [{
    label: 'Activity',
    valuePath: 'activity'
  }, {
    label: 'Time',
    valuePath: 'date',
    format: date => (0, _moment.default)(date).format(TIME_FORMAT)
  }, {
    label: 'Details',
    valuePath: 'details'
  }, {
    label: 'User',
    valuePath: 'user'
  }];
  _exports.columns = columns;
  const logEntryTypes = [{
    activity: 'Start',
    routeExecEventType: 'pullOut',

    buildAttrs(routeExecEvent
    /*, store*/
    ) {
      return {
        driverId: routeExecEvent.get('driver.badgeNr'),
        driverName: routeExecEvent.get('driver.name'),
        vehicleName: routeExecEvent.get('vehicle.callSign'),
        odometer: routeExecEvent.get('vehicle.odo'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Route Started. Driver {{driverId}}. Vehicle {{vehicleName}}.'; // these aren't always available yet

      if (attrs.odometer) {
        const convertedDistance = convertDistance(parseInt(attrs.odometer, 10), _environment.default.APP.distanceUnit);
        template += ` Odometer ${convertedDistance}.`;
      }

      if (attrs.lat) {
        template += ' Lat {{lat}}.';
      }

      if (attrs.lng) {
        template += ' Lng {{lng}}.';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'End',
    routeExecEventType: 'pullIn',

    buildAttrs(routeExecEvent
    /*, store*/
    ) {
      return {
        driverId: routeExecEvent.get('driver.badgeNr'),
        driverName: routeExecEvent.get('driver.name'),
        vehicleName: routeExecEvent.get('vehicle.callSign'),
        odometer: routeExecEvent.get('vehicle.odo'),
        lat: routeExecEvent.get('vehicle.location.coord.lat'),
        lng: routeExecEvent.get('vehicle.location.coord.lng')
      };
    },

    buildDetails(attrs) {
      let template = 'Route ended. Driver {{driverId}}. Vehicle {{vehicleName}}.'; // these aren't always available yet

      if (attrs.odometer) {
        const convertedDistance = convertDistance(parseInt(attrs.odometer, 10), _environment.default.APP.distanceUnit);
        template += ` Odometer ${convertedDistance}.`;
      }

      if (attrs.lat) {
        template += ' Lat {{lat}}.';
      }

      if (attrs.lng) {
        template += ' Lng {{lng}}';
      }

      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Arrive Stop',
    routeExecEventType: 'arrive',

    buildAttrs(routeExecEvent, store) {
      let passengerEvents = routeExecEvent.get('content.passengerEvents');
      let tripId, dsTripId;
      passengerEvents = Ember.makeArray(passengerEvents);
      const pickupEvent = passengerEvents.findBy('type', 'pickup');
      const dropoffEvent = passengerEvents.findBy('type', 'dropoff');
      const attrs = {
        driverId: routeExecEvent.get('driver.badgeNr')
      };

      if (pickupEvent) {
        dsTripId = Ember.get(pickupEvent, 'tripId');
        attrs.stopType = 'pickup';
      } else if (dropoffEvent) {
        dsTripId = Ember.get(dropoffEvent, 'tripId');
        attrs.stopType = 'drop-off';
      }

      const dsTrip = store.peekRecord('trip', dsTripId);

      if (dsTrip) {
        tripId = dsTrip.get('externalId');
      }

      attrs.tripId = tripId || '<loading>';
      return attrs;
    },

    buildDetails(attrs) {
      const template = 'Trip {{tripId}} {{stopType}} arrived.';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Depart Stop',
    routeExecEventType: 'depart',

    condition(routeExecEvent) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      return Ember.makeArray(passengerEvents).any(passengerEvent => {
        return ['pickup', 'dropoff'].includes(Ember.get(passengerEvent, 'type'));
      });
    },

    buildAttrs(routeExecEvent, store) {
      let passengerEvents = routeExecEvent.get('content.passengerEvents');
      let tripId, dsTripId;
      passengerEvents = Ember.makeArray(passengerEvents);
      const pickupEvent = passengerEvents.findBy('type', 'pickup');
      const dropoffEvent = passengerEvents.findBy('type', 'dropoff');
      const noShowEvent = passengerEvents.findBy('type', 'noShow');
      const attrs = {
        driverId: routeExecEvent.get('driver.badgeNr')
      };

      if (pickupEvent) {
        dsTripId = pickupEvent.tripId;
        attrs.stopType = 'pickup';
      } else if (dropoffEvent) {
        dsTripId = dropoffEvent.tripId;
        attrs.stopType = 'drop-off';
      } else if (noShowEvent) {
        dsTripId = noShowEvent.tripId;
        attrs.stopType = 'no-show';
      }

      const dsTrip = store.peekRecord('trip', dsTripId);

      if (dsTrip) {
        tripId = dsTrip.get('externalId');
      }

      attrs.tripId = tripId || '<loading>';
      return attrs;
    },

    buildDetails(attrs) {
      const template = 'Trip {{tripId}} {{stopType}} departed.';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'No Show Trip',
    routeExecEventType: 'onStop',

    condition(routeExecEvent) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents'); // there must be a client noShow event to render this activity log event

      return Ember.makeArray(passengerEvents).filterBy('type', 'noShow').findBy('passengerType', 'client');
    },

    buildAttrs(routeExecEvent, store) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const passengerEvent = Ember.makeArray(passengerEvents).filterBy('type', 'noShow').findBy('passengerType', 'client');
      let tripId;
      const dsTrip = store.peekRecord('trip', Ember.get(passengerEvent, 'tripId'));

      if (dsTrip) {
        tripId = dsTrip.get('externalId');
      }

      return {
        tripId: tripId || '<loading>',
        reason: Ember.get(passengerEvent, 'details.message')
      };
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}} no-showed.';

      if (attrs.reason) {
        template += ' No-show reason "{{reason}}".';
      }

      return interpolate(template, attrs);
    },

    buildUser() {
      return 'Passenger';
    }

  }, // the following are not currently represented by routeExecEvents:
  {
    activity: 'Remove Vehicle',
    attrs: () => {},
    details: () => 'Vehicle <vehicleName> removed.',
    user: () => null
  }, {
    activity: 'Add Vehicle',
    attrs: () => {},
    details: () => 'Vehicle <vehicleName> added.',
    user: () => null
  }, {
    activity: 'Cancel Trip',
    attrs: () => {},
    details: () => 'Trip <tripId> cancelled. Cancel reason <reason>.',
    user: () => null
  }, {
    activity: 'Transfer Trip',
    attrs: () => {},
    details: () => 'Trip <tripId> transferred to route <routeName>.',
    user: () => null
  }, {
    activity: 'Waitlist Trip',
    attrs: () => {},
    details: () => 'Trip <tripId> placed on waitlist.',
    user: () => null
  }, {
    activity: 'Message Received',
    attrs: () => {},
    details: () => 'Message <cannedMessage> received from vehicle <vehicleName>.',
    user: () => null
  }, {
    activity: 'Message Sent',
    attrs: () => {},
    details: () => 'Message <cannedMessage> sent to vehicle <vehicleName>.',
    user: () => null
  }, {
    activity: 'Edit',
    attrs: () => {},
    details: () => 'Route edited. Route\'s start odometer changed from <odometerValue> to <odometerValue>.',
    user: () => null
  }, {
    activity: 'Close',
    attrs: () => {},
    details: () => 'Route\'s date of <date> was closed.',
    user: () => null
  }, {
    activity: 'Create',
    attrs: () => {},
    details: () => 'Route created.',
    user: () => null
  }, {
    activity: 'Add Trip',
    attrs: () => {},
    details: () => 'Trip <tripId> added',
    user: () => null
  }];
  _exports.logEntryTypes = logEntryTypes;
});