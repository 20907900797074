define("adept-iq/services/geocode", ["exports", "adept-iq/config/environment", "adept-iq/config/api-urls", "tomtom", "adept-iq/utils/zone-validation", "adept-iq/utils/check-value", "adept-iq/pods/components/iq-widgets/map-widget/config/arrow", "adept-iq/pods/components/iq-widgets/map-widget/decorations/arrow", "adept-iq/pods/components/iq-widgets/map-widget/config/polyline", "adept-iq/pods/components/iq-widgets/map-widget/config/marker"], function (_exports, _environment, _apiUrls, _tomtom, _zoneValidation, _checkValue, _arrow, _arrow2, _polyline, _marker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    store: Ember.inject.service(),
    activeGeocode: false,
    activeMarker: null,
    activePickMarker: null,
    activeDropMarker: null,
    oldPickMarker: null,
    oldDropMarker: null,
    oldTripPolyline: null,
    activeTripPolyline: null,
    route4Trip: null,
    route4TripPopup: null,
    activeTripArrow: null,
    oldTripArrow: null,
    oldMarker: null,
    markerType: '',
    latlng: null,
    tomtomAddress: null,
    tomtomLatLng: null,
    zoom: 16,
    currentRecord: null,
    activeTripRouteOnMap: null,
    tripRouteOnMap: null,
    oldTripRouteOnMap: null,
    tripRoutePoints: null,
    disabledFindLocationForRecord: false,
    activePOIResults: null,
    activePOIGeocode: false,
    activePOIDescription: null,
    activePOIMarkersLayer: null,
    activePOIRecord: null,
    activePOIIndex: -1,

    init() {
      this._super(...arguments);
    },

    addressSearchResults(results) {
      const record = this.get('currentRecord');

      if (results.length > 0) {
        const latLng = results.firstObject.position;

        let markerOptions = _marker.default[this.get('markerType')];

        this.set('latlng', {
          lat: latLng.lat,
          lng: latLng.lon
        });

        const tomtomMarker = _tomtom.default.L.marker(this.get('latlng'), markerOptions);

        if (this.get('markerType') === 'pickup') {
          if (this.get('activePickMarker')) {
            this.set('oldPickMarker', this.get('activePickMarker'));
          } else {
            this.set('oldPickMarker', tomtomMarker);
          }

          this.set('activePickMarker', tomtomMarker);
        } else if (this.get('markerType') === 'dropoff') {
          if (this.get('activeDropMarker')) {
            this.set('oldDropMarker', this.get('activeDropMarker'));
          } else {
            this.set('oldDropMarker', tomtomMarker);
          }

          this.set('activeDropMarker', tomtomMarker);
        } else {
          this.set('oldMarker', this.get('activeMarker'));
          this.set('activeMarker', tomtomMarker);
        }
      } else if (record !== undefined && record.address.cord.lat !== 0 && record.address.cord.lng !== 0) {
        this.set('latlng', {
          lat: record.address.cord.lat,
          lng: record.address.cord.lng
        });

        const tomtomMarker = _tomtom.default.L.marker(this.get('latlng'));

        if (this.get('markerType') === 'pickup') {
          if (this.get('activePickMarker')) {
            this.set('oldPickMarker', this.get('activePickMarker'));
          } else {
            this.set('oldPickMarker', tomtomMarker);
          }

          this.set('activePickMarker', tomtomMarker);
        } else if (this.get('markerType') === 'dropoff') {
          if (this.get('activeDropMarker')) {
            this.set('oldDropMarker', this.get('activeDropMarker'));
          } else {
            this.set('oldDropMarker', tomtomMarker);
          }

          this.set('activeDropMarker', tomtomMarker);
        } else {
          this.set('oldMarker', this.get('activeMarker'));
          this.set('activeMarker', tomtomMarker);
        }
      }

      if (this.get('activePickMarker') && this.get('activeDropMarker')) {
        let tripPoints = [[this.get('activePickMarker._latlng.lat'), this.get('activePickMarker._latlng.lng')], [this.get('activeDropMarker._latlng.lat'), this.get('activeDropMarker._latlng.lng')]];
        let arrowPoints = tripPoints.slice(tripPoints.length - 2, tripPoints.length);
        let polyLineOptions = _polyline.default['dotted'];
        let arrowOptions = _arrow.default['tripArrowPlannedOffset'];
        let activeTripArrow = (0, _arrow2.default)(arrowPoints, arrowOptions);
        let tripRoutePoints = [];
        tripRoutePoints.push(this.get('activePickMarker._latlng.lat'));
        tripRoutePoints.push(this.get('activePickMarker._latlng.lng'));
        tripRoutePoints.push(this.get('activeDropMarker._latlng.lat'));
        tripRoutePoints.push(this.get('activeDropMarker._latlng.lng'));
        this.set('tripRoutePoints', tripRoutePoints);

        let tripPolyline = _tomtom.default.L.polyline(tripPoints, polyLineOptions);

        if (this.get('activeTripPolyline')) {
          this.set('oldTripPolyline', this.get('activeTripPolyline'));
        } else {
          this.set('oldTripPolyline', tripPolyline);
        }

        if (this.get('activeTripArrow')) {
          this.set('oldTripArrow', this.get('activeTripArrow'));
        } else {
          this.set('oldTripArrow', activeTripArrow);
        }

        this.set('activeTripArrow', activeTripArrow);
        this.set('activeTripPolyline', tripPolyline);
      }
    },

    addressPOISearchResults(results) {
      if (results.length > 0) {
        this.set('editmodalcontext.showWidgetModal', true);
        this.set('editmodalcontext.widgetModelData', results);
        this.set('editmodalcontext.modelWidgetPath', 'address-lookup-poi');
        this.set('activePOIResults', results); //drawPOIMarker(geoResponses);
      } else {
        this.set('activePOIResults', []);
      }
    },

    addressLookupResults(results, addressRecord, currentRecord) {
      if (results.length === 1) {
        let ttAddress = results.firstObject;

        if (!Ember.isBlank(ttAddress.notes)) {
          Ember.set(currentRecord, 'editmodelcontext.predefinedDriverNotes.address', (0, _checkValue.default)(ttAddress.notes.driver));
          Ember.set(currentRecord, 'editmodelcontext.predefinedOperatorNotes.address', (0, _checkValue.default)(ttAddress.notes.dispatcher));
        } else {
          Ember.set(currentRecord, 'editmodelcontext.predefinedDriverNotes.address', '');
          Ember.set(currentRecord, 'editmodelcontext.predefinedOperatorNotes.address', '');
        }

        this.set('activePOIResults', []); //drawPOIMarker(geoResponses);
      } else {
        this.set('editmodalcontext.addressRecord', addressRecord);
        this.set('editmodalcontext.record', currentRecord);
        this.set('editmodalcontext.showWidgetModal', true);
        this.set('editmodalcontext.widgetModelData', results);
        this.set('editmodalcontext.modelWidgetPath', '');
        this.set('activePOIResults', []);
      }
    },

    addressUnitLookupResults(results, addressRecord, currentRecord) {
      if (results.length === 1) {
        let ttAddress = results.firstObject;

        if (!Ember.isBlank(ttAddress.notes)) {
          Ember.set(currentRecord, 'editmodelcontext.predefinedDriverNotes.address', (0, _checkValue.default)(ttAddress.notes.driver));
          Ember.set(currentRecord, 'editmodelcontext.predefinedOperatorNotes.address', (0, _checkValue.default)(ttAddress.notes.dispatcher));
        } else {
          Ember.set(currentRecord, 'editmodelcontext.predefinedDriverNotes.address', '');
          Ember.set(currentRecord, 'editmodelcontext.predefinedOperatorNotes.address', '');
        }

        this.set('activePOIResults', []); //drawPOIMarker(geoResponses);
      } else {
        this.set('editmodalcontext.addressRecord', addressRecord);
        this.set('editmodalcontext.record', currentRecord);
        this.set('editmodalcontext.showWidgetModal', true);
        this.set('editmodalcontext.widgetModelData', results);
        this.set('editmodalcontext.modelWidgetPath', 'address-lookup-poi');
        this.set('activePOIResults', []);
      }
    },

    addressMapPopulate(stoppoint) {
      console.log('stoppoint', stoppoint);
      let address = stoppoint.address;

      if (!Ember.isBlank(stoppoint.address) && !Ember.isBlank(stoppoint.address.coord) && stoppoint.address.coord.lat !== 0 && stoppoint.address.coord.lng !== 0 && stoppoint.address.coord.lat !== 181 && stoppoint.address.coord.lng !== 181 && stoppoint.address.coord.lat !== -181 && stoppoint.address.coord.lng !== -181 && !Ember.isBlank(stoppoint.address.coord.lat) && !Ember.isBlank(stoppoint.address.coord.lng)) {
        let markerOptions = _marker.default[stoppoint.passengerEvents[0].type];
        this.set('latlng', {
          lat: address.coord.lat,
          lng: address.coord.lng
        });

        const tomtomMarker = _tomtom.default.L.marker(this.get('latlng'), markerOptions);

        if (stoppoint.passengerEvents[0].type === 'pickup') {
          if (this.get('activePickMarker')) {
            this.set('oldPickMarker', this.get('activePickMarker'));
          } else {
            this.set('oldPickMarker', tomtomMarker);
          }

          this.set('activePickMarker', tomtomMarker);
        } else if (stoppoint.passengerEvents[0].type === 'dropoff') {
          if (this.get('activeDropMarker')) {
            this.set('oldDropMarker', this.get('activeDropMarker'));
          } else {
            this.set('oldDropMarker', tomtomMarker);
          }

          this.set('activeDropMarker', tomtomMarker);
        } else {
          this.set('oldMarker', this.get('activeMarker'));
          this.set('activeMarker', tomtomMarker);
        }
      }

      if (this.get('activePickMarker') && this.get('activeDropMarker')) {
        let tripPoints = [[this.get('activePickMarker._latlng.lat'), this.get('activePickMarker._latlng.lng')], [this.get('activeDropMarker._latlng.lat'), this.get('activeDropMarker._latlng.lng')]];
        let arrowPoints = tripPoints.slice(tripPoints.length - 2, tripPoints.length);
        let polyLineOptions = _polyline.default['dotted'];
        let arrowOptions = _arrow.default['tripArrowPlannedOffset'];
        let activeTripArrow = (0, _arrow2.default)(arrowPoints, arrowOptions);
        let tripRoutePoints = [];
        tripRoutePoints.push(this.get('activePickMarker._latlng.lat'));
        tripRoutePoints.push(this.get('activePickMarker._latlng.lng'));
        tripRoutePoints.push(this.get('activeDropMarker._latlng.lat'));
        tripRoutePoints.push(this.get('activeDropMarker._latlng.lng'));
        this.set('tripRoutePoints', tripRoutePoints);

        let tripPolyline = _tomtom.default.L.polyline(tripPoints, polyLineOptions);

        if (this.get('activeTripPolyline')) {
          this.set('oldTripPolyline', this.get('activeTripPolyline'));
        } else {
          this.set('oldTripPolyline', tripPolyline);
        }

        if (this.get('activeTripArrow')) {
          this.set('oldTripArrow', this.get('activeTripArrow'));
        } else {
          this.set('oldTripArrow', activeTripArrow);
        }

        this.set('activeTripArrow', activeTripArrow);
        this.set('activeTripPolyline', tripPolyline);
      }
    },

    addressPopulateOnMap(stoppoint, type) {
      console.log('stoppoint', stoppoint, ' type ', type);

      if (!Ember.isBlank(stoppoint) && !Ember.isBlank(stoppoint.coord) && stoppoint.coord.lat !== 0 && stoppoint.coord.lng !== 0 && stoppoint.coord.lat !== 181 && stoppoint.coord.lng !== 181 && stoppoint.coord.lat !== -181 && stoppoint.coord.lng !== -181 && !Ember.isBlank(stoppoint.coord.lat) && !Ember.isBlank(stoppoint.coord.lng)) {
        let markerOptions = _marker.default[type];
        this.set('latlng', {
          lat: stoppoint.coord.lat,
          lng: stoppoint.coord.lng
        });

        const tomtomMarker = _tomtom.default.L.marker(this.get('latlng'), markerOptions);

        if (type === 'pickup') {
          if (this.get('activePickMarker')) {
            this.set('oldPickMarker', this.get('activePickMarker'));
          } else {
            this.set('oldPickMarker', tomtomMarker);
          }

          this.set('activePickMarker', tomtomMarker);
        } else if (type === 'dropoff') {
          if (this.get('activeDropMarker')) {
            this.set('oldDropMarker', this.get('activeDropMarker'));
          } else {
            this.set('oldDropMarker', tomtomMarker);
          }

          this.set('activeDropMarker', tomtomMarker);
        } else {
          this.set('oldMarker', this.get('activeMarker'));
          this.set('activeMarker', tomtomMarker);
        }
      }

      if (this.get('activePickMarker') && this.get('activeDropMarker')) {
        let tripPoints = [[this.get('activePickMarker._latlng.lat'), this.get('activePickMarker._latlng.lng')], [this.get('activeDropMarker._latlng.lat'), this.get('activeDropMarker._latlng.lng')]];
        let arrowPoints = tripPoints.slice(tripPoints.length - 2, tripPoints.length);
        let polyLineOptions = _polyline.default['dotted'];
        let arrowOptions = _arrow.default['tripArrowPlannedOffset'];
        let activeTripArrow = (0, _arrow2.default)(arrowPoints, arrowOptions);
        let tripRoutePoints = [];
        tripRoutePoints.push(this.get('activePickMarker._latlng.lat'));
        tripRoutePoints.push(this.get('activePickMarker._latlng.lng'));
        tripRoutePoints.push(this.get('activeDropMarker._latlng.lat'));
        tripRoutePoints.push(this.get('activeDropMarker._latlng.lng'));
        this.set('tripRoutePoints', tripRoutePoints);

        let tripPolyline = _tomtom.default.L.polyline(tripPoints, polyLineOptions);

        if (this.get('activeTripPolyline')) {
          this.set('oldTripPolyline', this.get('activeTripPolyline'));
        } else {
          this.set('oldTripPolyline', tripPolyline);
        }

        if (this.get('activeTripArrow')) {
          this.set('oldTripArrow', this.get('activeTripArrow'));
        } else {
          this.set('oldTripArrow', activeTripArrow);
        }

        this.set('activeTripArrow', activeTripArrow);
        this.set('activeTripPolyline', tripPolyline);
      }
    },

    findLocationForRecord(record) {
      if (record !== undefined) {
        try {
          if (record.address.streetName !== undefined) {
            if (record.address.streetNr !== undefined || record.address.streetName !== undefined || record.address.region !== null) {
              let streetNr = (0, _checkValue.default)(record.address.streetNr);
              let streetName = (0, _checkValue.default)(record.address.streetName);
              let city = (0, _checkValue.default)(record.address.region);
              let queryString = `${streetNr} ${streetName} , ${city}`;

              if (!Ember.isBlank(record.address.streetNr) === undefined) {
                queryString = `${streetName} ${city}`;
              }

              _tomtom.default.fuzzySearch().key(_environment.default.tomtom.search.searchKey).query(queryString).countrySet(_environment.default.tomtom.search.countrySet).typeahead(false).limit(1).center(_environment.default.tomtom.search.center).radius(_environment.default.tomtom.search.radius).callback(this.addressSearchResults.bind(this)).go();
            }
          } else {
            if (record.address.streetNr !== undefined || record.address.streetAddress !== undefined || record.address.subRegion !== null) {
              _tomtom.default.fuzzySearch().key(_environment.default.tomtom.search.searchKey).query(`${record.address.streetNr} ${record.address.streetAddress} ${record.address.subRegion}`).countrySet(_environment.default.tomtom.search.countrySet).typeahead(false).limit(1).center(_environment.default.tomtom.search.center).radius(_environment.default.tomtom.search.radius).callback(this.addressSearchResults.bind(this)).go();
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    },

    findPOIForRecord(latlng) {
      this.set('latlng', latlng); // if the point is outside the valid zone poolygon
      // return error and exit

      /* if (!insideZone([ latlng.lat, latlng.lng ])) {
         this.get('errorMessage').pushError({ detail:'Invalid Zone' });
         return;
       }*/
      // let markerOptions = MARKERS['location'];
      //const tomtomMarker = tomtom.L.marker(latlng,markerOptions);
      // this.set('activePOIMarker', tomtomMarker);

      try {
        if (this.get('activePOIDescription') !== undefined) {
          let queryString = this.get('activePOIDescription');

          _tomtom.default.poiSearch().key(_environment.default.tomtom.search.searchKey).query(queryString).countrySet(_environment.default.tomtom.search.countrySet).typeahead(false).limit(100) //.limit(1)
          //.center(latlng)
          //.radius(ENV.tomtom.search.poiradius)
          .center(_environment.default.tomtom.search.center).radius(_environment.default.tomtom.search.radius).callback(this.addressPOISearchResults.bind(this)).go();
        }
      } catch (e) {
        console.log(e);
      }
    },

    activateGeocode(activeGeocode, record, markerType) {
      if (!this.get('disabledFindLocationForRecord')) {
        this.findLocationForRecord(record);
      }

      this.set('activeGeocode', activeGeocode);
      this.set('markerType', markerType);
    },

    deactivateGeocode() {
      this.set('activeGeocode', false);
      this.set('tomtomAddress', null);
      this.set('activeMarker', null);
      this.set('activePickMarker', null);
      this.set('activeDropMarker', null);
      this.set('oldMarker', null);
      this.set('oldPickMarker', null);
      this.set('oldDropMarker', null);
      this.set('oldTripPolyline', null);
      this.set('oldTripArrow', null);
      this.set('activeTripArrow', null);
      this.set('activeTripRouteOnMap', null);
      this.set('oldTripRouteOnMap', null);
      this.set('tripRouteOnMap', null);
      this.set('tripRoutePoints', null);
      this.set('disabledFindLocationForRecord', false);
      this.set('markerType', '');
      this.set('activePOIMarkers', false);
      this.set('activePOIGeocode', false);
      this.set('activePOIDescription', '');
      this.set('activePOIMarkersLayer', null);
      this.set('activePOIRecord', null);
      this.set('activePOIIndex', -1);
      this.set('activeTripPolyline', null);
    },

    activatePOIGeocode(activeGeocode, record, markerType) {
      this.set('activePOIGeocode', activeGeocode);
      this.set('markerType', markerType);
    },

    deactivatePOIGeocode() {
      this.set('activeTripPolyline', null);
      this.set('activePOIGeocode', false);
      this.set('activeGeocode', false);
      this.set('tomtomAddress', null);
      this.set('activeMarker', null);
      this.set('activePickMarker', null);
      this.set('activeDropMarker', null);
      this.set('oldMarker', null);
      this.set('oldPickMarker', null);
      this.set('oldDropMarker', null);
      this.set('oldTripPolyline', null);
      this.set('oldTripArrow', null);
      this.set('activeTripArrow', null);
      this.set('activeTripRouteOnMap', null);
      this.set('oldTripRouteOnMap', null);
      this.set('tripRouteOnMap', null);
      this.set('tripRoutePoints', null);
      this.set('disabledFindLocationForRecord', false);
      this.set('markerType', '');
    },

    reverseGeocodeResult(result) {
      const latlng = this.get('latlng');
      result.position = {
        lat: parseFloat(latlng.lat.toFixed(5)),
        lon: parseFloat(latlng.lng.toFixed(5))
      };
      this.set('tomtomAddress', result);
    },

    prepareQuery(streetNr, streetName, region) {
      let searchURL = '';

      if (!Ember.isBlank(streetNr) && !Ember.isBlank(streetNr.trim())) {
        searchURL = 'streetNr=' + streetNr.toUpperCase();
      }

      if (!Ember.isBlank(streetName) && !Ember.isBlank(streetName.trim())) {
        if (!Ember.isBlank(searchURL)) searchURL = searchURL + '&streetName=' + streetName.toUpperCase();else searchURL = 'streetName=' + streetName.toUpperCase();
      }

      return searchURL;
    },

    addressLookupAttributes(streetNr, streetName, region, addressData, tripData) {
      let searchURL = this.prepareQuery(streetNr, streetName, region);
      this.get('store').query('address-lookup', {
        searchURL
      }).then(results => {
        this.get('geocode').addressLookupResults(results, addressData, tripData);
      }).catch(function (error) {
        return null;
      });
    },

    saveNewGeocode(latlng) {
      this.set('latlng', latlng); // if the point is outside the valid zone poolygon
      // return error and exit

      if (!(0, _zoneValidation.insideZone)([latlng.lat, latlng.lng])) {
        this.get('errorMessage').pushError({
          detail: 'Invalid Zone'
        });
        return;
      }

      let markerOptions = _marker.default[this.get('markerType')];

      const tomtomMarker = _tomtom.default.L.marker(this.get('latlng'), markerOptions);

      if (this.get('disabledFindLocationForRecord')) {
        _tomtom.default.reverseGeocode().key(_environment.default.tomtom.search.searchKey).position(`${latlng.lat},${latlng.lng}`).go().then(this.reverseGeocodeResult.bind(this));
      } else {
        this.set('tomtomLatLng', {
          lat: parseFloat(latlng.lat.toFixed(5)),
          lon: parseFloat(latlng.lng.toFixed(5))
        });
      }

      if (this.get('markerType') === 'pickup') {
        //////console.log(('activePickMarker',this.get('activePickMarker') ,' tomtomMarker ',tomtomMarker );
        if (this.get('activePickMarker')) {
          this.set('oldPickMarker', this.get('activePickMarker'));
        } else {
          this.set('oldPickMarker', tomtomMarker);
        }

        this.set('activePickMarker', tomtomMarker);
      } else if (this.get('markerType') === 'dropoff') {
        if (this.get('activeDropMarker')) {
          this.set('oldDropMarker', this.get('activeDropMarker'));
        } else {
          this.set('oldDropMarker', tomtomMarker);
        }

        this.set('activeDropMarker', tomtomMarker);
      } else {
        this.set('oldMarker', this.get('activeMarker'));
        this.set('activeMarker', tomtomMarker);
      }

      if (this.get('activePickMarker') && this.get('activeDropMarker')) {
        let tripPoints = [[this.get('activePickMarker._latlng.lat'), this.get('activePickMarker._latlng.lng')], [this.get('activeDropMarker._latlng.lat'), this.get('activeDropMarker._latlng.lng')]];
        let arrowPoints = tripPoints.slice(tripPoints.length - 2, tripPoints.length);
        let polyLineOptions = _polyline.default['dotted'];
        let arrowOptions = _arrow.default['tripArrowPlannedOffset'];
        let tripRoutePoints = [];
        tripRoutePoints.push(this.get('activePickMarker._latlng.lat'));
        tripRoutePoints.push(this.get('activePickMarker._latlng.lng'));
        tripRoutePoints.push(this.get('activeDropMarker._latlng.lat'));
        tripRoutePoints.push(this.get('activeDropMarker._latlng.lng'));
        this.set('tripRoutePoints', tripRoutePoints);
        let activeTripArrow = (0, _arrow2.default)(arrowPoints, arrowOptions);

        let tripPolyline = _tomtom.default.L.polyline(tripPoints, polyLineOptions);

        if (this.get('activeTripPolyline')) {
          this.set('oldTripPolyline', this.get('activeTripPolyline'));
        } else {
          this.set('oldTripPolyline', tripPolyline);
        }

        if (this.get('activeTripArrow')) {
          this.set('oldTripArrow', this.get('activeTripArrow'));
        } else {
          this.set('oldTripArrow', activeTripArrow);
        }

        this.set('activeTripArrow', activeTripArrow);
        this.set('activeTripPolyline', tripPolyline);
      }
    },

    // geonode is part of DDS' service used by the scheduling engine
    getGeonode(lat, lng) {
      //let queryString = 'lat='+lat+'&lon='+lng;
      return this.get('ajax').request('/pfrestapi/v1/areas/company-id/2', {
        host: _apiUrls.API.geocodeService.host,
        contentType: "application/json",
        method: 'GET',
        data: {
          lat: lat,
          lon: lng
        }
      });
    }

  });

  _exports.default = _default;
});