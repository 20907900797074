define("adept-iq/pods/components/iq-widgets/map-widget/data-joins/base", ["exports", "adept-iq/utils/data-join", "tomtom"], function (_exports, _dataJoin, _tomtom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dataJoin.default.extend(Ember.Evented, {
    map: null,

    bindMouseEvents(layer) {
      layer.on('click', e => {
        const {
          modelName,
          modelId,
          isActive
        } = e.target.options;
        if (!isActive) return;
        this.trigger('click', modelName, modelId, e);
      });
      layer.on('dblclick', e => {
        _tomtom.default.L.DomEvent.stop(e);

        let {
          modelName,
          modelId,
          isTraversal,
          parentModelName,
          parentModelId
        } = e.target.options;

        if (!Ember.isBlank(parentModelName)) {
          modelName = parentModelName;
          modelId = parentModelId;
        }

        if (!isTraversal) return;
        this.trigger('dblClick', modelName, modelId, e);
      });
      layer.on('contextmenu', e => {
        const {
          modelName,
          modelId,
          isActive
        } = e.target.options;
        if (!isActive) return;
        this.trigger('contextMenu', modelName, modelId, e);
      });
    }

  });

  _exports.default = _default;
});