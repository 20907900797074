define("adept-iq/models/tm-required-attribute", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/required-attribute.js */
  var _default = _model.default.extend({
    type: (0, _attr.default)('string'),
    cnt: (0, _attr.default)('number'),
    passenger: (0, _relationships.belongsTo)('tm-passenger')
  });

  _exports.default = _default;
});