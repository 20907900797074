define("adept-iq/pods/components/iq-widgets/subscriptions-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        type: 'text',
        label: 'ID',
        valuePath: 'id',
        editable: false
      }]
    }, {
      title: 'Editable',
      fields: [{
        id: 'anchor',
        type: 'enum',
        label: 'Anchor',
        valuePath: 'anchor',
        extra: {
          options: ['pick', 'drop']
        }
      }, {
        id: 'purpose',
        type: 'text',
        label: 'Purpose',
        valuePath: 'purpose'
      }, {
        id: 'maximumOccurrences',
        type: 'number',
        label: 'Max Occurences',
        valuePath: 'maximumOccurrences'
      }, {
        id: 'requestTime',
        type: 'timeflatpickr',
        label: 'Request Time',
        valuePath: 'requestTime',
        editable: true
      }]
    }, {
      title: 'Fare',
      fields: [{
        id: 'type',
        type: 'searchable-enum',
        label: 'Payment Type',
        valuePath: 'fareType',
        editable: true,
        extra: {
          optionModelName: 'fare-type',
          optionSearchPath: 'name',
          optionSelectedPath: '',
          optionIncludes: '',
          optionSearchEnabled: false
        }
      }, {
        id: 'fare',
        type: 'number',
        label: 'Amount',
        valuePath: 'fare',
        editable: true
      }]
    }, {
      title: 'Origin',
      fields: [{
        id: 'origins',
        type: 'locations',
        label: '',
        valuePath: 'origins',
        modelName: 'location',
        isMultiType: true,
        extra: {
          aliasPath: 'alias',
          notesPath: 'notes',
          streetNrPath: 'streetNr',
          streetNamePath: 'streetName',
          localityPath: 'city',
          regionPath: 'county',
          subRegionPath: 'state',
          postalCodePath: 'postalCode',
          countryPath: 'country',
          defaultOptionsPath: 'rider.iqRider.rmsRider.riderAddresses',
          useOptionRecord: true
        }
      }]
    }, {
      title: 'Destination',
      fields: [{
        id: 'destinations',
        type: 'locations',
        label: '',
        valuePath: 'destinations',
        modelName: 'location',
        isMultiType: true,
        extra: {
          aliasPath: 'alias',
          notesPath: 'notes',
          streetNrPath: 'streetNr',
          streetNamePath: 'streetName',
          localityPath: 'city',
          regionPath: 'county',
          subRegionPath: 'state',
          postalCodePath: 'postalCode',
          countryPath: 'country',
          defaultOptionsPath: 'rider.iqRider.rmsRider.riderAddresses',
          useOptionRecord: true
        }
      }]
    }, {
      title: 'Travel Needs',
      fields: [{
        id: 'travelNeeds',
        type: 'travel-needs',
        label: '',
        valuePath: 'subscriptionTravelNeeds',
        modelName: 'subscription-travel-need',
        isMultiType: true,
        extra: {
          travelNeedTypeModelName: 'travel-need-type',
          passengerTypeModelName: 'passenger-type',
          countPath: 'count',
          travelNeedTypePath: 'travelNeedType',
          passengerTypePath: 'passengerType'
        }
      }]
    }, {
      title: 'Subscription',
      fields: [{
        id: 'subscriptions',
        type: 'subscriptions',
        label: '',
        valuePath: 'recurrencePatterns',
        modelName: 'subscription-recurence-pattern',
        isMultiType: true,
        extra: {
          recurringPath: 'recurring',
          dailyConfigPath: 'type',
          selectedWOMPath: 'selectedWOM',
          startDateTimePath: 'subscriptionStartDate',
          endDateTimePath: 'subscriptionEndDate',
          sundayPath: 'sunday',
          mondayPath: 'monday',
          tuesdayPath: 'tuesday',
          wednesdayPath: 'wednesday',
          thursdayPath: 'thursday',
          fridayPath: 'friday',
          saturdayPath: 'saturday',
          excludeStartDateTimePath: 'exclusionStartDate',
          excludeEndDateTimePath: 'exclusionEndDate',
          maxOccurencesPath: 'subscriptionMaxOccurences',
          separationCountPath: 'separationCount',
          dayOfMonthPath: 'dayOfMonth'
        }
      }]
    }]
  };
  _exports.default = _default;
});