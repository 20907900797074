define("adept-iq/models/avl", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships"], function (_exports, _emberData, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    lat: (0, _attr.default)('number'),
    lng: (0, _attr.default)('number'),
    heading: (0, _attr.default)('number'),
    speed: (0, _attr.default)('number'),
    odo: (0, _attr.default)('number'),
    timestamp: (0, _attr.default)('date'),
    // we add this in because `id` gets replaced with a composite key
    vehicleId: (0, _attr.default)('string'),
    vehicle: (0, _relationships.belongsTo)('vehicle', {
      inverse: 'avl'
    }),
    localTimestamp: null,

    init() {
      this._super(...arguments);

      this.set('localTimestamp', new Date());
    }

  });

  _exports.default = _default;
});