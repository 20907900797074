define("adept-iq/utils/populate-trip-search-data", ["exports", "adept-iq/utils/check-value"], function (_exports, _checkValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = populateTripSearchData;

  function populateData(updatetrip, store) {
    let iqTrip = store.peekRecord('trip', updatetrip.id);

    if (Ember.isBlank(iqTrip)) {
      let account;
      let driver;
      let vehicle;
      let rider = store.peekRecord('rider', updatetrip.passengers[0].id);

      if (Ember.isBlank(rider)) {
        rider = store.createRecord('rider', {
          id: updatetrip.passengers[0].id,
          riderId: updatetrip.passengers[0].id,
          name: updatetrip.passengers[0].firstName,
          phoneNr: updatetrip.passengers[0].phone1.number,
          areaCode: updatetrip.passengers[0].phone1.areaCode,
          extension: updatetrip.passengers[0].phone1.extension
        });
      }

      let originlocations = [];
      let locationPickupID = updatetrip.id + "." + updatetrip.stopPoints[0].index + "." + updatetrip.stopPoints[0].index;
      let locationDropoffID = updatetrip.id + "." + updatetrip.stopPoints[1].index + "." + updatetrip.stopPoints[1].index;
      let origin = store.createRecord('place', {
        id: locationPickupID,
        streetNr: (0, _checkValue.default)(updatetrip.stopPoints[0].address.streetNr),
        streetName: (0, _checkValue.default)(updatetrip.stopPoints[0].address.streetName),
        city: (0, _checkValue.default)(updatetrip.stopPoints[0].address.region),
        postalCode: (0, _checkValue.default)(updatetrip.stopPoints[0].address.postalCode)
      });
      origin.set('type', 'pick');
      originlocations.push(origin);
      let destinationlocations = [];
      let destination = store.createRecord('place', {
        id: locationDropoffID,
        streetNr: (0, _checkValue.default)(updatetrip.stopPoints[1].address.streetNr),
        streetName: (0, _checkValue.default)(updatetrip.stopPoints[1].address.streetName),
        city: (0, _checkValue.default)(updatetrip.stopPoints[1].address.region),
        postalCode: (0, _checkValue.default)(updatetrip.stopPoints[1].address.postalCode)
      });
      destination.set('type', 'drop');
      destinationlocations.push(destination);
      let pick = store.createRecord('stop', {
        id: locationPickupID,
        location: origin,
        type: 'pick',
        tripId: updatetrip.id,
        orderId: updatetrip.id
      });
      let drop = store.createRecord('stop', {
        id: locationDropoffID,
        location: destination,
        type: 'drop',
        tripId: updatetrip.id,
        orderId: updatetrip.id
      });
      let bsAccounts = store.peekAll('account');
      let bsAccount = bsAccounts.filterBy('acctCardNr', updatetrip.prices[0].payerIdentifier);

      if (!Ember.isBlank(updatetrip.driver)) {
        driver = store.peekRecord('driver', updatetrip.driver.id);
      }

      if (!Ember.isBlank(updatetrip.vehicle)) {
        vehicle = store.peekRecord('vehicle', updatetrip.vehicle.id);
      }

      if (Ember.isBlank(bsAccount)) {
        account = store.createRecord('account', {
          id: updatetrip.prices[0].payerIdentifier,
          acctCardNr: updatetrip.prices[0].payerIdentifier
        });
      } else {
        account = bsAccount.firstObject;
      }

      let trip = store.createRecord('trip', {
        id: updatetrip.id,
        planState: 1,
        anchor: 'pick',
        fare: updatetrip.prices[0].fare,
        rider: rider,
        origin: origin,
        pick: pick,
        drop: drop,
        destination: destination,
        originlocations: originlocations,
        destinationlocations: destinationlocations,
        driverNotes: '',
        account: account,
        acctCardNr: account.acctCardNr,
        driver: driver,
        vehicle: vehicle,
        status: updatetrip.state
      });
      store.createRecord('stop', {
        id: locationPickupID,
        stop: pick
      });
      store.createRecord('stop', {
        id: locationDropoffID,
        stop: drop
      });
      iqTrip = store.createRecord('trip', {
        id: updatetrip.id,
        trip: trip
      });
    }

    return iqTrip;
  }

  function populateTripSearchData(updatetrip, store) {
    return populateData(updatetrip, store);
  }
});