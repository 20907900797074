define("adept-iq/serializers/driver-break", ["exports", "adept-iq/serializers/-dispatch"], function (_exports, _dispatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    attrs: {
      driverbreakId: {
        serialize: false
      },
      actualArrivalTime: {
        serialize: false
      },
      actualDepartTime: {
        serialize: false
      },
      odometer: {
        serialize: false
      },
      plannedDepartTime: {
        serialize: false
      },
      serviceWindow: {
        serialize: false
      },
      breakStartTime: {
        serialize: false
      },
      breakEndTime: {
        serialize: false
      },
      BreakType: {
        serialize: false
      },
      BreakCategory: {
        serialize: false
      },
      driverBreakId: {
        serialize: false
      },
      promisedEnd: {
        serialize: false
      }
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // this is because of inconsistensies in api
      // returns an array instead of an object after a POST
      if (requestType === 'createRecord' && Ember.typeOf(payload.data) === 'array') {
        if (payload.data.length === 1) {
          payload.data = payload.data[0];
        }
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});