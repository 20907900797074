define("adept-iq/classes/polylines/route", ["exports", "adept-iq/classes/polylines/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    dsRoute: Ember.computed.alias('record'),
    points: Ember.computed('type', 'dsRoute.{fullPolyline,navigationPolyline}', function () {
      switch (this.get('type')) {
        case 'planned':
          return this.get('dsRoute.fullPolyline');

        case 'performed':
          return this.get('dsRoute.fullPolyline');

        case 'navigation':
          return this.get('dsRoute.navigationPolyline');

        default:
          /*eslint-disable */
          throw 'unknown route polyline type';

        /*eslint-enable */
      }
    }),

    /*eslint-disable */
    options: {
      weight: 3
    }
    /*eslint-enable */

  });

  _exports.default = _default;
});