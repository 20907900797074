define("adept-iq/models/tm-requested-provider", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/requested-provider.js */
  var _default = _model.default.extend({
    type: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    trip: (0, _relationships.belongsTo)('tm-booking')
  });

  _exports.default = _default;
});