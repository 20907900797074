define("adept-iq/models/driver-shift", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    hasMany,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    plannedStartTime: attr('date'),
    plannedEndTime: attr('date'),
    driver: belongsTo(),
    vehicleRoute: belongsTo('vehicleRoute', {
      inverse: 'driverShifts'
    }),
    driverEvents: hasMany()
  });

  _exports.default = _default;
});