define("adept-iq/pods/components/iq-widgets/routes-widget/routes-row/component", ["exports", "adept-iq/pods/components/table/rows/otp-formatted-row/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    attributeBindings: ['draggable'],
    draggable: 'true'
  });

  _exports.default = _default;
});