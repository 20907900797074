define("adept-iq/utils/check-address-overwrite", ["exports", "adept-iq/utils/check-value"], function (_exports, _checkValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = checkAddressOverwrite;

  function checkAddressOverwritePopup(address, addressData) {
    if (Ember.isBlank(address.streetNr) && Ember.isBlank(address.streetName) && Ember.isBlank(address.region)) {
      return false;
    }

    if ((0, _checkValue.default)(address.streetNr) === (0, _checkValue.default)(addressData.streetNr) && (0, _checkValue.default)(address.streetName) === (0, _checkValue.default)(addressData.streetName) && (0, _checkValue.default)(address.region) === (0, _checkValue.default)(addressData.region)) {
      return false;
    }

    return true;
  }

  function checkAddressOverwrite(address, addressData) {
    return checkAddressOverwritePopup(address, addressData);
  }
});