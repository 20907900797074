define("adept-iq/pods/components/form-components/enum/component", ["exports", "ember-keyboard-shortcuts"], function (_exports, _emberKeyboardShortcuts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-select'],
    value: null,
    disabled: false,
    extra: null,
    store: Ember.inject.service(),
    dataFromModel: false,
    options: Ember.computed.alias('extra.options'),
    allowMultiple: Ember.computed.alias('extra.allowMultiple'),
    optionModelName: Ember.computed.alias('extra.optionModelName'),
    keyboardShortcuts: null,

    init() {
      this._super(...arguments);

      if (this.get('extra.optionModelName') !== undefined) {
        this.set('dataFromModel', true);
        this.set('options', this.get('store').peekAll(this.get('extra.optionModelName')));
      }

      let altaccesskey = 'alt+' + this.get('accessKey');

      if (!Ember.isBlank(this.get('accessKey'))) {
        let accessKeyConfig = {
          [altaccesskey]: {
            action: 'handleFocus',
            // action to trigger
            global: true,
            preventDefault: true
          }
        };
        this.set('keyboardShortcuts', accessKeyConfig);
      }
    },

    didInsertElement() {
      this._super(...arguments); //console.log('enum keyboardShortcuts ',this.get('keyboardShortcuts'));


      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    },

    selected: Ember.computed('value', 'options', function () {
      if (this.get('extra.optionModelName') !== undefined) {
        if (this.get('value') === undefined || this.get('value.id') === undefined) {
          this.set('value', this.get('options').firstObject);
        }

        return this.get('options').find(option => {
          return option.id === this.get('value.id');
        });
      }
    }),
    actions: {
      onChange(option) {
        this.get('onChange')(option);
      },

      handleFocus() {
        if (!Ember.isBlank(this.get('accessKey'))) {
          this.input = document.querySelector(`.ember-power-select-trigger[id="${this.get('triggerId')}"]`);

          if (this.input) {
            Ember.run.next(this.input, 'focus');
          }
        }
      }

    }
  });

  _exports.default = _default;
});