define("adept-iq/pods/components/iq-widgets/noshow-event-stop-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'riderFirstName',
        label: 'First Name',
        valuePath: 'trip.rider.firstName',
        editable: false
      }, {
        id: 'riderLastName',
        label: 'Rider Last Name',
        valuePath: 'trip.rider.lastName',
        editable: false
      }, {
        id: 'externalId',
        label: 'External ID',
        valuePath: 'trip.externalId',
        editable: false
      }, {
        id: 'requestTime',
        label: 'Request Time',
        valuePath: 'trip.requestTime',
        editable: false
      }, {
        id: 'pickupaddress',
        label: 'Pickup Address ',
        valuePath: 'trip.pick.place.address',
        editable: false
      }, {
        id: 'dropaddress',
        label: 'Dropoff Address',
        valuePath: 'trip.drop.place.address',
        editable: false
      }]
    }, {
      title: 'Editable',
      fields: [{
        id: 'timestamp',
        type: 'datetimeflatpickr',
        label: 'Date Time',
        valuePath: 'timestamp',
        editable: true,
        hidden: false,
        format: 'YYYY-MM-DD hh:mm A',
        defaultWidth: 50
      }, {
        id: 'noShowReason',
        type: 'enum',
        label: 'Type',
        valuePath: 'noShowReason',
        cellDesc: 'noShowReason',
        editable: true,
        hidden: false,
        extra: {
          optionModelName: 'no-show-reason-code',
          optionSearchPath: 'description'
        }
      }, {
        id: 'notes',
        type: 'text',
        editable: true,
        label: 'Notes',
        valuePath: 'noShowNotes'
      }]
    }]
  };
  _exports.default = _default;
});