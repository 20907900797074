define("adept-iq/pods/components/form-components/scheduleavailabilities/component", ["exports", "moment", "adept-iq/config/environment", "tomtom", "ember-concurrency"], function (_exports, _moment, _environment, _tomtom, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function cleanFieldString(text) {
    return typeof text === 'undefined' ? '' : text;
  }

  var _default = Ember.Component.extend({
    classNames: ['form-components-availabilities'],
    editModal: Ember.inject.service(),
    value: null,
    disabled: false,
    dayOfWeeks: null,
    store: Ember.inject.service(),
    availabilities: Ember.computed.readOnly('value'),
    availabilityType: Ember.computed.readOnly('valuePath'),
    startTimePath: Ember.computed.readOnly('extra.startTimePath'),
    endTimePath: Ember.computed.readOnly('extra.endTimePath'),
    shiftStartPath: Ember.computed.readOnly('extra.shiftStartPath'),
    shiftEndPath: Ember.computed.readOnly('extra.shiftEndPath'),
    sundayPath: Ember.computed.readOnly('extra.sundayPath'),
    mondayPath: Ember.computed.readOnly('extra.mondayPath'),
    tuesdayPath: Ember.computed.readOnly('extra.tuesdayPath'),
    wednesdayPath: Ember.computed.readOnly('extra.wednesdayPath'),
    thursdayPath: Ember.computed.readOnly('extra.thursdayPath'),
    fridayPath: Ember.computed.readOnly('extra.fridayPath'),
    saturdayPath: Ember.computed.readOnly('extra.saturdayPath'),
    shiftBreaksPath: Ember.computed.readOnly('extra.shiftBreaksPath'),
    shiftBreakTypePath: Ember.computed.readOnly('extra.shiftBreakTypePath'),
    shiftBreaks: null,
    breakTypes: null,
    addressOptions: null,
    addresses: Ember.computed.readOnly('value'),
    addressType: Ember.computed.readOnly('extra.addressvaluePath'),
    aliasPath: Ember.computed.readOnly('extra.aliasPath'),
    notesPath: Ember.computed.readOnly('extra.notesPath'),
    streetNrPath: Ember.computed.readOnly('extra.streetNrPath'),
    streetNamePath: Ember.computed.readOnly('extra.streetNamePath'),
    localityPath: Ember.computed.readOnly('extra.localityPath'),
    regionPath: Ember.computed.readOnly('extra.regionPath'),
    subRegionPath: Ember.computed.readOnly('extra.subRegionPath'),
    postalCodePath: Ember.computed.readOnly('extra.postalCodePath'),
    countryPath: Ember.computed.readOnly('extra.countryPath'),
    locationsPath: 'latlng',
    addressRecord: null,
    selected: null,
    geocodeAddressChange: Ember.observer('geocode.tomtomAddress', function () {
      const record = this.get('addressRecord');
      const tomtomAddress = this.get('geocode.tomtomAddress');
      this.convertTTAddressToModel(record, tomtomAddress);
      this.disableAddressSearchOnMap(record, record.type);
    }),

    validateAddresses() {
      const records = this.get('addresses');
      this.set('editModal.errors', []);
      records.forEach(record => {
        if (record.lat === 0 || record.lng === 0) {
          this.get('editModal.errors').pushObject('Address does not have lat/lng. Please select position on the map.');
        }
      });
    },

    addressSearchResults(results) {
      this.set('addressOptions', results);
    },

    searchAddress: (0, _emberConcurrency.task)(function* (term) {
      yield _tomtom.default.fuzzySearch().key(_environment.default.tomtom.search.searchKey).query(term).countrySet(_environment.default.tomtom.search.countrySet).typeahead(true).center(_environment.default.tomtom.search.center).radius(_environment.default.tomtom.search.radius).callback(this.addressSearchResults.bind(this)).go();
    }),
    convertTTAddressToModel: function (record, ttAddress) {
      if (ttAddress.id === undefined && ttAddress.id === null || ttAddress.position !== undefined && ttAddress.position !== null) {
        let addressvaluePath = this.get('streetNrPath');
        let options = {
          record,
          addressvaluePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(cleanFieldString(ttAddress.address.streetNr), options);
        options.addressvaluePath = this.get('streetNamePath');
        this.get('onChange')(cleanFieldString(ttAddress.address.streetName), options);
        options.addressvaluePath = this.get('localityPath');
        this.get('onChange')(cleanFieldString(ttAddress.address.municipality), options);
        options.addressvaluePath = this.get('regionPath');
        this.get('onChange')(cleanFieldString(ttAddress.address.countrySubdivisionName), options);
        options.addressvaluePath = this.get('subRegionPath');
        this.get('onChange')(cleanFieldString(ttAddress.address.countrySecondarySubdivision), options);
        options.addressvaluePath = this.get('postalCodePath');
        this.get('onChange')(cleanFieldString(ttAddress.address.postalCode), options);
        options.addressvaluePath = this.get('countryPath');
        this.get('onChange')(cleanFieldString(ttAddress.address.countryCode), options);
        options.addressvaluePath = this.get('aliasPath');
        this.get('onChange')('', options);
        record.set('lat', ttAddress.position.lat);
        record.set('lng', ttAddress.position.lon);
        this.get('geocode').getGeonode(ttAddress.address.freeformAddress).then(result => {
          record.set('geoNode', result.data.gpsAreaId); //this.set('addressRecord', record);
        });
      } else {
        let addressvaluePath = this.get('streetNrPath');
        let options = {
          record,
          addressvaluePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(cleanFieldString(ttAddress.streetNr), options);
        options.addressvaluePath = this.get('streetNamePath');
        this.get('onChange')(cleanFieldString(ttAddress.streetAddress), options);
        options.addressvaluePath = this.get('localityPath');
        this.get('onChange')(cleanFieldString(ttAddress.locality), options);
        options.addressvaluePath = this.get('regionPath');
        this.get('onChange')(cleanFieldString(ttAddress.countrySubdivisionName), options);
        options.addressvaluePath = this.get('subRegionPath');
        this.get('onChange')(cleanFieldString(ttAddress.countrySecondarySubdivision), options);
        options.addressvaluePath = this.get('postalCodePath');
        this.get('onChange')(cleanFieldString(ttAddress.postalCode), options);
        options.addressvaluePath = this.get('countryPath');
        this.get('onChange')(cleanFieldString(ttAddress.countryCode), options);
        options.addressvaluePath = this.get('aliasPath');
        this.get('onChange')(cleanFieldString(ttAddress.alias), options);
        options.addressvaluePath = this.get('notesPath');
        this.get('onChange')(cleanFieldString(ttAddress.notes), options);
        record.set('lat', ttAddress.locations.firstObject.lat);
        record.set('lng', ttAddress.locations.firstObject.lng);
        this.get('geocode').getGeonode(ttAddress.tomtomFormattedAddress.address.freeformAddress).then(result => {
          record.set('geoNode', result.data.gpsAreaId);
        });
      }

      this.get('geocode').activateGeocode(false, record, record.type);
      this.validateAddresses();
      this.set('addressOptions', this.get('record').get('rider.riderAddresses'));
    },
    clearAddressModel: function (record) {
      let addressvaluePath = this.get('streetNrPath');
      let options = {
        record,
        addressvaluePath
      };
      this.get('onChange')('', options);
      options.addressvaluePath = this.get('streetNamePath');
      this.get('onChange')('', options);
      options.addressvaluePath = this.get('localityPath');
      this.get('onChange')('', options);
      options.addressvaluePath = this.get('regionPath');
      this.get('onChange')('', options);
      options.addressvaluePath = this.get('subRegionPath');
      this.get('onChange')('', options);
      options.addressvaluePath = this.get('postalCodePath');
      this.get('onChange')('', options);
      options.addressvaluePath = this.get('countryPath');
      this.get('onChange')('', options);
      options.addressvaluePath = this.get('aliasPath');
      this.get('onChange')('', options);
      record.set('lat', 0);
      record.set('lng', 0);
    },

    enableAddressSearchOnMap(record, type) {
      Ember.$('html,body,.tomtom-map').addClass('custom-cursor');
      this.get('geocode').activateGeocode(true, record, type);
      this.set('addressRecord', record);
    },

    disableAddressSearchOnMap(record, type) {
      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
      this.get('geocode').activateGeocode(false, record, type);
      this.set('addressRecord', null);
    },

    init() {
      this.set('dayOfWeeks', ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']);
      this.set('datePlaceHolder', _environment.default.dateTimeFormat.dateMoment);
      this.set('timePlaceHolder', _environment.default.dateTimeFormat.timeMoment);
      this.set('dateFormat', _environment.default.dateTimeFormat.dateFlatPickr);
      this.set('timeFormat', _environment.default.dateTimeFormat.timeFlatPickr);

      this._super(...arguments);

      this.set('shiftBreaks', this.record.get('shiftBreaks'));
    },

    didInsertElement: function () {
      const breakTypeModelName = this.get('extra.breakTypeModelName');
      this.set('breakTypes', this.get('store').peekAll(breakTypeModelName));
    },

    getAvailibilityBreaks(record) {
      return record.get('shiftBreaks');
    },

    actions: {
      onInput(record, valuePath, value) {
        let options = {
          record,
          valuePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(value, options);
      },

      onChangeDate(record, valuePath, value) {
        let options = {
          record,
          valuePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(value[0], options);
      },

      onClose(currentValue, stringValue, datePicker) {
        const currentValueMoment = (0, _moment.default)(currentValue[0]);
        const newValueMoment = (0, _moment.default)(stringValue);

        if (!newValueMoment._isValid) {
          // $(`#${datePicker.element.id}`).focus();
          this.set('editModal.errors', ['Date entered is invalid.']);
        }

        if (datePicker.config.allowInput && datePicker._input.value && newValueMoment._isValid && !currentValueMoment.isSame(newValueMoment)) {
          // date format is for database so should not be from config
          datePicker.setDate(newValueMoment.format('YYYY-MM-DD'), true);
        } // $(datePicker.altInput.className).select();
        // $(datePicker.element).select();
        // $('.effective-start').select();

      },

      onChangeTime(record, valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        let options = {
          record,
          valuePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(momentTime.format('HH:mm:ss'), options);
      },

      onCheckboxChange(record, valuePath) {
        let switchState = record.get(valuePath);
        let options = {
          record,
          valuePath
        };
        switchState = !switchState;
        this.set('record.isForceDirty', true);
        this.get('onChange')(switchState, options);
      },

      onDOWChange(record, values) {
        record.set('sunday', values.includes('sunday'));
        record.set('monday', values.includes('monday'));
        record.set('tuesday', values.includes('tuesday'));
        record.set('wednesday', values.includes('wednesday'));
        record.set('thursday', values.includes('thursday'));
        record.set('friday', values.includes('friday'));
        record.set('saturday', values.includes('saturday'));
        this.set('record.isForceDirty', true);
      },

      onAddNewAvailability() {
        this.get('onCreate')();
      },

      onRemoveAvailability(record, valuePath, value) {
        let options = {
          record,
          valuePath
        };
        this.get('onRemove')(value, options);
      },

      onAddNewBreak(record, valuePath, modelName) {
        //const newAddress = this.get('editModal').pushRecordValue(record., 'locations', 'location');
        const shiftBreak = this.get('editModal').pushRecordValue(record, valuePath, modelName);
        shiftBreak.set('breakType', this.get('breakTypes.firstObject')); //this.get('editModal').pushRecordValue(record, 'shiftBreaks', 'ss-shift-break');
      },

      onbreakTypeChange(record, valuePath, value) {
        record.set(valuePath, value);
        this.record.set(valuePath, value);
        this.set('record.isForceDirty', true);
      },

      onRemoveBreak(record, valuePath, value) {
        let options = {
          record,
          valuePath
        };
        this.get('onRemove')(value, options);
      },

      onAddressSelected(record, valuePath, value) {
        this.convertTTAddressToModel(record, value);
      },

      onAliasAddressSelected(record, valuePath, value) {
        this.convertTTAliasAddressToModel(record, value);
      },

      onPickOnMap(record) {
        const activeGeocode = this.get('geocode.activeGeocode');

        if (activeGeocode) {
          this.disableAddressSearchOnMap(record, record.type);
        } else {
          this.enableAddressSearchOnMap(record, record.type);
        }
      },

      handleKeydown(record, valuePath, dropdown, e) {
        if (e.keyCode !== 13) {
          return;
        }

        let text = e.target.value;
        let options = {
          record,
          valuePath: 'streetAddress'
        }; // address has been forced without geocodes
        // this.set('record.isForceDirty', true);

        this.clearAddressModel(record); // this.set('record.isForceDirty', true);

        this.get('onChange')(text, options);
        this.set('addressRecord', record);
        this.validateAddresses();
      }

    }
  });

  _exports.default = _default;
});