define("adept-iq/models/tm-stop-point", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/stop-point.js */
  var _default = _model.default.extend({
    index: (0, _attr.default)('number'),
    address: (0, _relationships.belongsTo)('tm-address'),
    directions: (0, _attr.default)('string'),
    passengerEvents: (0, _relationships.hasMany)('tm-passenger-event'),
    trip: (0, _relationships.belongsTo)('tm-booking')
  });

  _exports.default = _default;
});