define("adept-iq/utils/zone-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.insideZone = insideZone;

  function insideZone(point, zonePolygon) {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
    const x = point[0];
    const y = point[1];
    let inside = false;

    for (let i = 0, j = zonePolygon.length - 1; i < zonePolygon.length; j = i++) {
      const xi = zonePolygon[i][0];
      const yi = zonePolygon[i][1];
      const xj = zonePolygon[j][0];
      const yj = zonePolygon[j][1];
      /*eslint-disable */

      const intersect = yi > y != yj > y && x < (xj - xi) * (y - yi) / (yj - yi) + xi;
      /*eslint-enable */

      if (intersect) inside = !inside;
    }

    return inside;
  }
});