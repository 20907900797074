define("adept-iq/services/stops-reorder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    reorderInProgress: false,
    widget: null,
    currentState: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'currentState', {});
    },

    // called when a user starts a reorder in stops widget
    startReorder(widget) {
      Ember.set(this, 'widget', widget);
      this.sortByETA();
      Ember.set(this, 'reorderInProgress', true);
    },

    // called when a user stops a reorder in stops widget
    stopReorder() {
      let currentState = this.get('currentState');

      if (Object.keys(currentState).length === 0 && currentState.constructor === Object) {
        currentState = {
          sortId: 'id',
          sortAsc: true
        };
      }

      this.set('widget.state', currentState);
      Ember.set(this, 'currentState', {});
      Ember.set(this, 'reorderInProgress', false);
    },

    // stopBreakOrd is the ordinal which is also
    // equivalent to eta
    // using stopBreakOrd because eta is sometimes empty
    sortByETA() {
      const oldState = this.get('widget.state') || {};
      Ember.set(this, 'currentState', oldState);
      this.set('widget.state', {
        sortId: 'stopBreakOrd',
        sortAsc: true
      });
    }

  });

  _exports.default = _default;
});