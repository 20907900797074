define("adept-iq/pods/components/iq-widgets/cli-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3qhwrvHI",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\"],[7,\"div\"],[11,\"id\",\"callerNo\"],[11,\"style\",\"display:none\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"awsAmazonConnect\"],[11,\"style\",\"display:none\"],[9],[1,[21,\"awsAmazonConnect\"],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"containerDiv\"],[11,\"draggable\",\"true\"],[11,\"onload\",\"autoResize(this)\"],[11,\"style\",\"overflow:hidden;height:500px;width:400px;display:block\"],[11,\"height\",\"500px\"],[11,\"width\",\"400px\"],[9],[10],[0,\"\\n \\n\"],[2,\"<iframe width=\\\"250\\\" height=\\\"400\\\" allow=\\\"microphone\\\" style=\\\"z-index:9999;overflow:hidden;display:block\\\"  id=\\\"rc-widget\\\" allow=\\\"microphone\\\"  src=\\\"https://ringcentral.github.io/ringcentral-embeddable/app.html\\\">\\n </iframe> \"],[0,\"\\n \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/cli-widget/template.hbs"
    }
  });

  _exports.default = _default;
});