define("adept-iq/pods/components/iq-widgets/cancel-type-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/cancel-type-form-widget/config", "adept-iq/utils/populate-new-trip", "adept-iq/utils/populate-copy-trip", "adept-iq/utils/populate-edit-trip", "adept-iq/utils/populate-search-trip", "adept-iq/utils/populate-account", "adept-iq/config/api-urls"], function (_exports, _component, _config, _populateNewTrip, _populateCopyTrip, _populateEditTrip, _populateSearchTrip, _populateAccount, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    trip: Ember.inject.service(),
    store: Ember.inject.service(),
    searchservice: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    accountSearchPath: _apiUrls.API_SUB_URL.accountSearchPath,
    newTripComponent: 'iq-widgets/new-trip-form-widget',

    init() {
      this._super(...arguments);

      let valid = true;
      let records = this.get('editableRecords');
      this.set('editModal.errors', []);
      records.forEach(record => {
        record.set('lastId', record.get('id'));
      });
      return valid;
    },

    validateRecords() {
      let valid = true;
      let records = this.get('editableRecords');
      this.set('editModal.errors', []);
      records.forEach(record => {
        //TODO Temparory for Taxibook Integration
        if (Ember.isBlank(record.cancelNotes)) {
          valid = false;
          this.get('editModal.errors').pushObject('Cancel Notes should be there');
        }
      });
      return valid;
    },

    saveTripRecordFunction(records, editComponentAction) {
      console.log(' saveTripRecordFunction ', editComponentAction);
      const me = this;
      return new Promise((resolve, reject) => {
        let record = records.firstObject;
        record.save().then(() => {
          console.log('after saveTripRecordFunction ');
          const tripId = record.get('id');
          me.get('errorMessage').pushInfo({
            detail: `Trip '${tripId}' successfully saved`
          });
          record.set('lastId', record.get('id'));
          record.set('isForceDirty', false);

          if (!Ember.isBlank(editComponentAction) && editComponentAction === 'onApplyClick') {
            run(() => {
              let recordRefresh = [];
              let newtrip = (0, _populateEditTrip.default)(me.get('trip'), record, me.get('store'), me.get('geocode'));
              (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, false);
              recordRefresh.push(newtrip);
              let newTripComponent = me.get('editSwitchTripComponent');

              if (this.get('editModal.editComponent') !== null && this.get('editModal.editComponent') === this.get('editSwitchTripComponent')) {
                newTripComponent = me.get('editTripComponent');
              }

              me.get('editModal').open(newTripComponent, recordRefresh, me.get('saveTripRecordFunction').bind(me));
            });
          } else if (!Ember.isBlank(editComponentAction) && editComponentAction === 'onApplyClearClick') {
            run(() => {
              Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
              me.get('geocode').activateGeocode(false);
              me.get('geocode').deactivateGeocode();
              let recordRefresh = [];
              let newtrip = (0, _populateNewTrip.default)(me.get('trip'), me.get('store'));
              (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, false);
              newtrip.set('lastId', record.get('lastId'));
              recordRefresh.push(newtrip); //me.set('editableRecords',recordRefresh);
              //me.set('records',recordRefresh);

              let newTripComponent = me.get('newSwitchTripComponent');

              if (me.get('editModal.editComponent') !== null && me.get('editModal.editComponent') === me.get('newSwitchTripComponent')) {
                newTripComponent = me.get('newTripComponent');
              }

              me.get('editModal').open(newTripComponent, recordRefresh, me.get('saveTripRecordFunction').bind(me));
            });
          }
        }).catch(function (error) {
          console.log(error);
          reject(error);
        });
      });
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        /*let lastUndoState = this.get('editModal.undoHistory').popObject();
         if (lastUndoState === null) {
          let records = this.get('editableRecords');
           records.forEach(record => {
            record.set('isForceDirty', false);
          });
         }
        else {
          lastUndoState.forEach(({ record, properties }) => {
            record.setProperties(properties);
          });
        }
         this.set('isLastUndoStateCommitted', true);*/
      },

      onNewClick() {
        this.set('records', []);
        let newtrip = (0, _populateNewTrip.default)(this.get('trip'), this.get('store'));
        (0, _populateAccount.default)(newtrip, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), newtrip.acctCardNr, true); //this.get('records').pushObject(newtrip);
        //this.set('editModal.editableRecords',[]);
        //this.get('editModal.editableRecords').pushObject(newtrip);

        const newTripComponent = this.get('newTripComponent');
        this.get('editModal').open(newTripComponent, [newtrip], this.get('saveTripRecordFunction').bind(this));
      },

      onApplyClearClick() {
        if (this.validateRecords()) {
          this.get('service').apply();
          this.get('editModal').commit();
        }
      },

      onApplyClick() {
        if (this.validateRecords()) {
          this.get('service').apply();
          this.get('editModal').commit();
        }
      }

    }
  });

  _exports.default = _default;
});