define("adept-iq/models/tm-rider", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/coord.js */
  var _default = _model.default.extend({
    //provider:  attr(),
    passengerType: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    middleName: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    phone1: (0, _attr.default)(),
    //phone2:  attr(),
    account: (0, _attr.default)(),
    countryCode: (0, _attr.default)('string'),
    requiredAttributes: (0, _attr.default)(),
    boardingOverhead: (0, _attr.default)(),
    apps: (0, _attr.default)(),
    defaultFavoritePaymentId: (0, _attr.default)(),
    favoritePayments: (0, _attr.default)(),
    defaultFavoritePickupId: (0, _attr.default)('number'),
    favoritePickups: (0, _attr.default)(),
    eligibilities: (0, _attr.default)(),
    //SSN: attr('string'),
    //dob: attr('string'),
    //companions:  attr(),
    //pcas:  attr(),
    //locale: attr('string'),
    //externalId: attr('string'),
    status: (0, _attr.default)('string')
  });

  _exports.default = _default;
});