define("adept-iq/pods/components/side-drawer/trip-activity-log/config", ["exports", "moment", "lodash"], function (_exports, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.logEntryTypes = _exports.columns = void 0;
  const TIME_FORMAT = 'YYYY-MM-DD hh:mmA';
  const TEMPLATE_INTERPOLATION = /{{([\s\S]+?)}}/g;

  const interpolate = (template, attrs) => {
    const compiledTemplate = _lodash.default.template(template, {
      interpolate: TEMPLATE_INTERPOLATION
    });

    return compiledTemplate(attrs);
  };

  const columns = [{
    label: 'Activity',
    valuePath: 'activity'
  }, {
    label: 'Time',
    valuePath: 'date',
    format: date => (0, _moment.default)(date).format(TIME_FORMAT)
  }, {
    label: 'Details',
    valuePath: 'details'
  }, {
    label: 'User',
    valuePath: 'user'
  }];
  _exports.columns = columns;
  const logEntryTypes = [{
    activity: 'Arrive Stop',
    routeExecEventType: 'arriveActual',

    condition(routeExecEvent, trip) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      return Ember.makeArray(passengerEvents).findBy('tripId', trip.get('trip.id'));
    },

    buildAttrs(routeExecEvent, store, passengerEvents) {
      let dsTripId, tripId; //let passengerEvents = stopPoints.get('passengerEvents');

      passengerEvents = Ember.makeArray(passengerEvents);
      const pickupEvent = passengerEvents.findBy('type', 'pickup');
      const dropoffEvent = passengerEvents.findBy('type', 'dropoff');
      const attrs = {
        driverId: routeExecEvent.driver.badgeNr
      };

      if (pickupEvent) {
        dsTripId = Ember.get(pickupEvent, 'tripId');
        attrs.stopType = 'pickup';
      } else if (dropoffEvent) {
        dsTripId = Ember.get(dropoffEvent, 'tripId');
        attrs.stopType = 'drop-off';
      }
      /*const dsTrip = store.peekRecord('trip', dsTripId);
       if (dsTrip) {
        tripId = dsTrip.get('externalId');
      }*/


      attrs.tripId = routeExecEvent.id || '<loading>';
      return attrs;
    },

    buildDetails(attrs) {
      const template = 'Trip {{tripId}} {{stopType}} arrived.';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'Depart Stop',
    routeExecEventType: 'departActual',

    condition(routeExecEvent, trip) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      return Ember.makeArray(passengerEvents).any(passengerEvent => {
        return Ember.get(passengerEvent, 'tripId') === trip.get('trip.id') && ['pickup', 'dropoff'].includes(Ember.get(passengerEvent, 'type'));
      });
    },

    buildAttrs(routeExecEvent, store) {
      let dsTripId, tripId;
      let passengerEvents = routeExecEvent.get('content.passengerEvents');
      passengerEvents = Ember.makeArray(passengerEvents);
      const pickupEvent = passengerEvents.findBy('type', 'pickup');
      const dropoffEvent = passengerEvents.findBy('type', 'dropoff');
      const noShowEvent = passengerEvents.findBy('type', 'noShow');
      const attrs = {
        driverId: routeExecEvent.get('driver.badgeNr')
      };

      if (pickupEvent) {
        dsTripId = pickupEvent.tripId;
        attrs.stopType = 'pickup';
      } else if (dropoffEvent) {
        dsTripId = dropoffEvent.tripId;
        attrs.stopType = 'drop-off';
      } else if (noShowEvent) {
        dsTripId = noShowEvent.tripId;
        attrs.stopType = 'no-show';
      }

      const dsTrip = store.peekRecord('trip', dsTripId);

      if (dsTrip) {
        tripId = dsTrip.get('externalId');
      }

      attrs.tripId = tripId || '<loading>';
      return attrs;
    },

    buildDetails(attrs) {
      const template = 'Trip {{tripId}} {{stopType}} departed.';
      return interpolate(template, attrs);
    },

    buildUser(attrs) {
      const template = 'Driver {{driverId}}';
      return interpolate(template, attrs);
    }

  }, {
    activity: 'No Show Trip',
    routeExecEventType: 'noShow',

    condition(routeExecEvent, trip) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents'); // there must be a client noShow event to render this activity log event

      return Ember.makeArray(passengerEvents).any(passengerEvent => {
        return Ember.get(passengerEvent, 'tripId') === trip.get('trip.id') && Ember.get(passengerEvent, 'passengerType') === 'client';
      });
    },

    buildAttrs(routeExecEvent, store) {
      const passengerEvents = routeExecEvent.get('content.passengerEvents');
      const passengerEvent = Ember.makeArray(passengerEvents).filterBy('type', 'dropoff').findBy('passengerType', 'client');
      if (!passengerEvent) return;
      let tripId;
      const dsTrip = store.peekRecord('trip', Ember.get(passengerEvent, 'tripId'));

      if (dsTrip) {
        tripId = dsTrip.get('externalId');
      }

      return {
        tripId: tripId || '<loading>',
        reason: Ember.get(passengerEvent, 'details.message')
      };
    },

    buildDetails(attrs) {
      let template = 'Trip {{tripId}} no-showed.';

      if (attrs.reason) {
        template += ' No-show reason "{{reason}}".';
      }

      return interpolate(template, attrs);
    },

    buildUser() {
      return 'Passenger';
    }

  }];
  _exports.logEntryTypes = logEntryTypes;
});