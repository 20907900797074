define("adept-iq/config/map-layers", ["exports", "adept-iq/config/icon-paths", "adept-iq/pods/components/iq-widgets/stops-widget/config", "adept-iq/pods/components/iq-widgets/vehicles-widget/mapconfig", "adept-iq/pods/components/iq-widgets/zones-widget/config", "adept-iq/pods/components/iq-widgets/stands-widget/config"], function (_exports, _iconPaths, _config, _mapconfig, _config2, _config3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: make this JSON-only
  // TODO: it would be better to look up values from static config at time of use,
  // but for now this handles the differences in config format
  const extractLabelsFromWidgetConfig = widgetConfig => {
    return widgetConfig.columns.map(columnConfig => {
      const {
        id,
        index,
        type,
        label,
        valuePath,
        valuePreview,
        imgPath,
        parentLabel,
        searchable,
        isMapLabelVisible,
        isMapMCGLabelVisible
      } = columnConfig;
      return {
        id,
        index,
        type,
        label,
        valuePath,
        valuePreview,
        imgPath,
        parentLabel,
        isSearchable: searchable,
        isVisible: isMapLabelVisible,
        isMCGVisible: isMapMCGLabelVisible
      };
    });
  };

  const layers = [{
    id: 'vehicles',
    type: 'markers',
    label: 'Vehicles',
    modelName: 'vehicle',
    isVisible: true,
    isActive: true,
    isTraversal: true,
    isMCG: true,
    opacity: 1,
    types: [{
      id: 'free',
      label: 'Free',
      valueKey: 'drivedStatus',
      style: 'free',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.free
    }, {
      id: 'freeonBreak',
      label: 'On Break',
      valueKey: 'drivedStatus',
      style: 'onBreak',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.onbreak
    }, {
      id: 'freetemOff',
      label: 'Temp Off',
      valueKey: 'drivedStatus',
      style: 'tempOff',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.onbreak
    }, {
      id: 'onHold',
      label: 'On Hold',
      valueKey: 'drivedStatus',
      style: 'onhold',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.onhold
    }, {
      id: 'off',
      label: 'Off',
      valueKey: 'drivedStatus',
      style: 'off',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.off
    }, {
      id: 'STC',
      label: 'STC',
      valueKey: 'drivedStatus',
      style: 'STC',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.stc
    }, {
      id: 'emergency',
      label: 'Emergency',
      valueKey: 'drivedStatus',
      style: 'emergency',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.emergency
    }, {
      id: 'occupied',
      label: 'Occupied',
      valueKey: 'drivedStatus',
      style: 'occupied',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.occupied
    }, {
      id: 'unexplained',
      label: 'Unexplained',
      valueKey: 'drivedStatus',
      style: 'unexplained',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.unexplained
    }, {
      id: 'unknown',
      label: 'Unknown',
      valueKey: 'drivedStatus',
      style: 'unknown',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.unknown
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_mapconfig.default)
  }, {
    id: 'zones',
    type: 'shapes',
    label: 'Zones',
    modelName: 'zone',
    isVisible: true,
    isActive: false,
    // does not respond to active context
    isTraversal: true,
    opacity: 1,
    types: [{
      id: 'high',
      label: 'High',
      valueKey: 'status',
      style: 'high',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.zones.high
    }, {
      id: 'medium',
      label: 'Medium',
      valueKey: 'status',
      style: 'medium',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.zones.medium
    }, {
      id: 'normal',
      label: 'Normal',
      valueKey: 'status',
      style: 'normal',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.zones.normal
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_config2.default)
  }, {
    id: 'stands',
    type: 'shapes',
    label: 'Stands',
    modelName: 'stand',
    isVisible: true,
    isActive: false,
    // does not respond to active context
    isTraversal: true,
    opacity: 1,
    types: [{
      id: 'high',
      label: 'High',
      valueKey: 'status',
      style: 'high',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.zones.high
    }, {
      id: 'medium',
      label: 'Medium',
      valueKey: 'status',
      style: 'medium',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.zones.medium
    }, {
      id: 'normal',
      label: 'Normal',
      valueKey: 'status',
      style: 'normal',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.zones.normal
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_config3.default)
  }];
  var _default = layers;
  _exports.default = _default;
});