define("adept-iq/pods/components/iq-widgets/vehicles-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/vehicles-form-widget/config", "moment"], function (_exports, _component, _config, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    classNames: ['vehicle-form-widget', 'data-test-vehicle-form-widget'],

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords');
      this.set('editModal.errors', []);
      records.forEach(record => {
        const availabilities = record.availability;
        const shiftBreaks = record.shiftBreaks;

        if (record.name === undefined || record.name.trim().length < 1) {
          this.get('editModal.errors').pushObject('Vehicle Id');
          valid = false;
        }

        if (record.get('vehicleType.id') === undefined || record.get('vehicleType.id') === null) {
          this.get('editModal.errors').pushObject('Vehicle Type');
          valid = false;
        }

        if (Ember.isEmpty(record.get('startGarages.firstObject.latlng'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Start Garage address should be there');
        } else if (Ember.isEmpty(record.get('startGarages.firstObject.geoNode'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Start Garage address should be valid');
        }

        if (Ember.isEmpty(record.get('endGarages.firstObject.latlng'))) {
          valid = false;
          this.get('editModal.errors').pushObject('End Garage address should be there');
        } else if (Ember.isEmpty(record.get('endGarages.firstObject.geoNode'))) {
          valid = false;
          this.get('editModal.errors').pushObject('End Garage address should be valid');
        }

        availabilities.forEach(availability => {
          const startTime = (0, _moment.default)(availability.startTime);
          const endTime = (0, _moment.default)(availability.endTime);
          const shiftStart = (0, _moment.default)(availability.shiftStart, 'HH:mm:ss');
          const shiftEnd = (0, _moment.default)(availability.shiftEnd, 'HH:mm:ss');

          if (endTime.isBefore(startTime)) {
            valid = false;
            this.get('editModal.errors').pushObject('Effective end date cannot be before effective start date');
          }

          if (shiftEnd.isBefore(shiftStart)) {
            valid = false;
            this.get('editModal.errors').pushObject('Shift end cannot be before shift start');
          }
        });
        shiftBreaks.forEach(shiftBreak => {
          const startTime = (0, _moment.default)(shiftBreak.startTime, 'HH:mm:ss');
          const endTime = (0, _moment.default)(shiftBreak.endTime, 'HH:mm:ss');

          if (endTime.isBefore(startTime)) {
            valid = false;
            this.get('editModal.errors').pushObject('Effective end date cannot be before effective start date');
          }

          const startDate = new Date(startTime);
          const endDate = new Date(endTime);
          const difference = startDate - endDate;
          const diff_result = new Date(difference);
          const hourDiff = diff_result.getHours();

          if (hourDiff <= 0) {
            valid = false;
            this.get('editModal.errors').pushObject('Break end date cannot same as start date');
          }
        });
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        /*let lastUndoState = this.get('editModal.undoHistory').popObject();
         if (lastUndoState === null) {
          let records = this.get('editableRecords');
           records.forEach(record => {
            record.set('isForceDirty', false);
          });
         }
        else {
          lastUndoState.forEach(({ record, properties }) => {
            record.setProperties(properties);
          });
        }
         this.set('isLastUndoStateCommitted', true);*/
      },

      onApplyClick() {
        if (this.validateRecords()) {
          this.get('service').apply();
        }
      } // onCellValueChange(record, valuePath, value, options) {
      //   if (valuePath === 'vehicles.firstObject.name') {
      //     let vehicle = record.get('vehicles.firstObject');
      //     record.vehicles.removeObject(vehicle);
      //   }
      //   else {
      //     this.get('service').setRecordValue(record, valuePath, value, options);
      //   }
      // }


    }
  });

  _exports.default = _default;
});