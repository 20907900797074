define("adept-iq/models/driver-state", ["exports", "ember-data", "adept-iq/mixins/otp-label", "adept-iq/mixins/status-value"], function (_exports, _emberData, _otpLabel, _statusValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    status: attr('string'),
    vehicle: belongsTo('vehicle'),
    offset: attr(),
    trip: hasMany('trip'),
    driver: belongsTo('driver', {
      inverse: 'driverState'
    })
  });

  _exports.default = _default;
});