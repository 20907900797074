define("adept-iq/pods/components/table/rows/vehicle-status-formatted-row/component", ["exports", "adept-iq/pods/components/table/rows/action-row/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNameBindings: ['statusCategory'],
    // override with sub-class-specific CP
    statusValue: Ember.computed.alias('row.statusValue'),
    statusCategory: Ember.computed('row.statusValue', function () {
      const statusValue = this.get('row.statusValue');
      const statusclass = 'vehiclestatus-' + statusValue;
      return statusclass;
    })
  });

  _exports.default = _default;
});