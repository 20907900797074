define("adept-iq/utils/populate-new-text-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = populateNewTextMessage;

  function populateNewTextMessageData(textMessageTemplate, store, vehicle, driver, trip) {
    console.log('textMessageTemplate.textmessage.data', textMessageTemplate.textMessageData().data);
    let textmessageData = textMessageTemplate.textMessageData().data[0].attributes;
    let newTextMessage = store.createRecord('tm-text-message', {
      subject: textmessageData.subject,
      body: textmessageData.body,
      sender: textmessageData.sender,
      recipients: textmessageData.recipients,
      deliveryTime: textmessageData.deliveryTime,
      conversation: textmessageData.conversation,
      vehicle: vehicle,
      driver: driver
    });

    if (!Ember.isBlank(vehicle)) {
      newTextMessage.set('vehicle', vehicle);

      if (vehicle.get('driver')) {
        driver = vehicle.get('driver');
        newTextMessage.recipients[0].id = vehicle.get('driver.id');
        newTextMessage.set('driver', vehicle.get('driver'));
      }
    }

    if (!Ember.isBlank(driver)) {
      newTextMessage.recipients[0].id = driver.get('id');
      newTextMessage.set('driver', driver);

      if (driver.get('vehicle')) {
        vehicle = driver.get('vehicle');
        newTextMessage.set('vehicle', vehicle);
      }
    }

    if (!Ember.isBlank(trip)) {
      if (trip.get('driver')) {
        driver = trip.get('driver');
        newTextMessage.recipients[0].id = trip.get('driver.id');
        newTextMessage.set('driver', driver);
      }

      if (trip.get('vehicle')) {
        vehicle = trip.get('vehicle');
        newTextMessage.set('vehicle', vehicle);
      }
    }

    return newTextMessage;
  }

  function populateNewTextMessage(textMessageTemplate, store, vehicle, driver, trip) {
    return populateNewTextMessageData(textMessageTemplate, store, vehicle, driver, trip);
  }
});