define("adept-iq/pods/components/iq-widgets/column-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/column-widget/component", "adept-iq/config/column-types", "adept-iq/utils/filters"], function (_exports, _component, _columnTypes, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const logger = {
    log: () => null,
    warn: console.warn // eslint-disable-line no-console

  };

  var _default = _component.default.extend({
    activeContext: Ember.inject.service(),
    classNames: ['iqux-column-widget'],
    layoutName: 'components/generic-widgets/column-widget',
    defaultIncludes: null,

    didInsertElement() {
      this._super(...arguments);

      this._onActiveContextRefresh = modelNames => {
        if (!modelNames.includes(this.get('config.modelName'))) {
          // not refreshing this model. give control back
          if (this.get('isDestroyed')) {
            return;
          }

          this.set('activeContext.refreshInProgress', false);
          return;
        }

        ;
        this.refreshData();
      };

      this._onActiveContextClear = () =>
      /* modelNames */
      {
        this.clearData();
      };

      this.get('activeContext').on('refresh', this._onActiveContextRefresh);
      this.get('activeContext').on('clear', this._onActiveContextClear);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.get('activeContext').off('refresh', this._onActiveContextRefresh);
      this.get('activeContext').off('clear', this._onActiveContextClear);
      this._onActiveContextRefresh = null;
      this._onActiveContextClear = null;
    },

    fetchDataQuery() {
      const params = {
        sorts: []
      }; // this only applies for server-side pagination

      if (this.get('paginationEnabled')) {
        params.page = {
          offset: this.get('records.length'),
          limit: this.get('limit')
        };
      }

      const sort = this.buildSortParam();

      if (sort) {
        params.sorts.push(sort);
      }

      const includes = this.get('defaultIncludes');

      if (!Ember.isEmpty(includes)) {
        params.includes = includes;
      }

      const filters = [this.buildSearchFilter(), this.buildColumnFilter()];
      params.filter = (0, _filters.buildCompoundFilterNode)('and', filters);
      const modelName = this.get('config.modelName'); //console.log(modelName,'[this.buildSearchFilter() ',this.buildSearchFilter());
      //console.log(modelName,'[this.buildColumnFilter() ',this.buildColumnFilter());

      return this.get('activeContext').query(modelName, params);
    },

    buildSortParam() {
      const config = this.get('config');
      const state = this.get('state');
      let columnConfig; // user-specified sort order always takes precedence

      if (!Ember.isEmpty(state) && Ember.isPresent(state.sortId)) {
        columnConfig = config.columns.findBy('id', state.sortId);
      } // fall back to default sort order; this is optional in widget config


      if (!columnConfig && Ember.isPresent(config.defaultSortId)) {
        columnConfig = config.columns.findBy('id', config.defaultSortId);
      } // collection will be unsorted; this is expected when there is no specified
      // workspace sort or widget default sort


      if (!columnConfig) return null;

      let columnType = _columnTypes.default.findBy('id', columnConfig.type);

      if (!columnType) {
        //logger.warn(`tried to sort by column with ID '${columnConfig.id}', but its type '${columnConfig.type}' is not a valid column type`);
        return null;
      }

      if (!columnType.compare) {
        // logger.warn(`column type '${columnType.id}' does not have a compare function configured`);
        return null;
      }

      let sortAsc = true;

      if (!Ember.isEmpty(state) && Ember.isPresent(state.sortAsc)) {
        sortAsc = state.sortAsc;
      } else if (Ember.isPresent(config.defaultSortAsc)) {
        sortAsc = config.defaultSortAsc;
      }

      return {
        path: columnConfig.valuePath,
        asc: sortAsc,
        compare: columnType.compare
      };
    },

    buildSearchFilter() {
      const searchText = this.get('searchText');
      if (Ember.isEmpty(searchText)) return null;
      const searchableConfigs = this.get('table.columns').rejectBy('hidden').mapBy('config').compact().filterBy('searchable');
      const args = searchableConfigs.map(config => {
        const columnType = _columnTypes.default.findBy('id', config.type);

        const filterType = columnType.searchFilterType;
        return (0, _filters.buildValueFilterNode)(filterType, config.valuePath, [searchText], config.format);
      });
      return (0, _filters.buildCompoundFilterNode)('or', args);
    },

    buildColumnFilter() {
      const args = this.get('table.columns').map(column => {
        return column.get('filterNode');
      });
      return (0, _filters.buildCompoundFilterNode)('and', args);
    }

  });

  _exports.default = _default;
});