define("adept-iq/models/tm-passenger", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/address.js */
  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    middleName: (0, _attr.default)('string'),
    phone1: (0, _relationships.belongsTo)('tm-phone1'),
    phone2: (0, _relationships.belongsTo)('tm-phone2'),
    email: (0, _attr.default)('string'),
    bookingNote: (0, _attr.default)('string'),
    requiredAttributes: (0, _relationships.hasMany)('tm-required-attribute'),
    boardingOverhead: (0, _relationships.belongsTo)('tm-boarding-overhead'),
    trip: (0, _relationships.belongsTo)('tm-booking')
  });

  _exports.default = _default;
});