define("adept-iq/services/socket", ["exports", "lodash", "adept-iq/config/environment", "adept-iq/config/api-urls", "moment", "md5", "adept-iq/topic-transforms/alert", "adept-iq/topic-transforms/avl", "adept-iq/topic-transforms/driver", "adept-iq/topic-transforms/message", "adept-iq/topic-transforms/navigation-info", "adept-iq/topic-transforms/rider", "adept-iq/topic-transforms/account", "adept-iq/topic-transforms/route", "adept-iq/topic-transforms/route-exec-event", "adept-iq/topic-transforms/stop", "adept-iq/topic-transforms/subscription", "adept-iq/topic-transforms/trip", "adept-iq/topic-transforms/zone", "adept-iq/topic-transforms/stand", "adept-iq/topic-transforms/user", "adept-iq/topic-transforms/vehicle", "adept-iq/topic-transforms/vehicle-state", "adept-iq/topic-transforms/driver-state", "adept-iq/topic-transforms/trip-exec-event"], function (_exports, _lodash, _environment, _apiUrls, _moment, _md, _alert, _avl, _driver, _message, _navigationInfo, _rider, _account, _route, _routeExecEvent, _stop, _subscription, _trip, _zone, _stand, _user, _vehicle, _vehicleState, _driverState, _tripExecEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TIME_FILTERING_ENABLED = false;
  const CHUNK_SIZE = 2000;
  const SOCKET_CLEAN_CLOSURE_CODE = 1000;
  const SOCKET_BAD_JWT = 4001;
  const SOCKET_BAD_PREFIX = 4002;
  const SOCKET_DUPLICATE_CONSUMER = 4003;
  const SOCKET_URL_BASE = _apiUrls.API.streamingService.host; // `loadAsync` tells UI not to wait for this on initial load

  const SUBSCRIPTION_TOPICS = [{
    topic: 'tripWidgetTaxi',
    loadAsync: true
  }, {
    topic: 'zoneWidgetTaxi',
    loadAsync: true
  }, //{ topic: 'zoneStatWidget', loadAsync: true },
  //{ topic: 'routeWidget', loadAsync: true },
  {
    topic: 'stopWidgetTaxi',
    loadAsync: true
  }, {
    topic: 'avlWidget',
    loadAsync: true
  }, // { topic: 'navigationInfoWidget', loadAsync: true },
  //{ topic: 'routeExecEventsWidget', loadAsync: true },
  {
    topic: 'driverWidgetTaxi',
    loadAsync: true
  }, {
    topic: 'vehicleWidgetTaxi',
    loadAsync: true
  }, //  { topic: 'messageWidget', loadAsync: true },
  {
    topic: 'accountWidgetTaxi',
    loadAsync: true
  }, {
    topic: 'riderWidget',
    loadAsync: true
  }, //  { topic: 'driverState', loadAsync: true },
  {
    topic: 'tripExecEventWidgetTaxi',
    loadAsync: true
  }, {
    topic: 'vehicleStateWidgetTaxi',
    loadAsync: true
  }, {
    topic: 'driverStateWidgetTaxi',
    loadAsync: true
  }];
  const EVENT_HANDLERS = {
    open: 'onSocketOpen',
    close: 'onSocketClose',
    error: 'onSocketError',
    message: 'onSocketMessage'
  };
  const TRANSFORMS = {
    alert: _alert.default,
    avl: _avl.default,
    driver: _driver.default,
    message: _message.default,
    navigationInfo: _navigationInfo.default,
    rider: _rider.default,
    account: _account.default,
    route: _route.default,
    routeExecEvents: _routeExecEvent.default,
    stop: _stop.default,
    subscription: _subscription.default,
    trip: _trip.default,
    tripExecEvent: _tripExecEvent.default,
    user: _user.default,
    vehicle: _vehicle.default,
    vehicleState: _vehicleState.default,
    driverState: _driverState.default,
    zone: _zone.default,
    stand: _stand.default
  };
  const logger = {
    warn: console.warn,

    // eslint-disable-line no-console
    debug(...args) {// console.debug(prefix, ...args); // eslint-disable-line no-console
    }

  };

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    websockets: Ember.inject.service(),
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    sockets: null,
    connected: null,
    queue: null,
    // used to identify duplicate payloads
    digestLookup: null,

    init() {
      this._super(...arguments);

      this.set('sockets', []);
      this.set('queue', []);
      this.set('digestLookup', {});
      this.set('connected', false);
      window.disconnectSocket = this.disconnect.bind(this);
    },

    connect(startDate, endDate) {
      // Don't reconnect if already connected
      if (!this.get('connected')) {
        this.disconnect();
        const session = this.get('session');
        const sessionId = this.generateUUID();
        const promises = [];
        _environment.default.workspaceContext.startDate = startDate;
        _environment.default.workspaceContext.endDate = endDate;
        const sockets = SUBSCRIPTION_TOPICS.map(({
          topic,
          loadAsync
        }) => {
          const socketUrl = this.socketUrlFor(topic, sessionId, startDate, endDate); // socketFor takes two arguments: a url, and a protocol array (optional; adds the Sec-WebSocket-Protocol header to the request)

          const socket = this.get('websockets').socketFor(socketUrl, [`${session.data.authenticated.token}`]);
          const deferred = Ember.RSVP.defer(); // initial load only waits sync topics

          if (!loadAsync) {
            promises.push(deferred.promise);
          } // build and cache handler functions (so we can unsubscribe on close)


          const handlers = Object.entries(EVENT_HANDLERS).reduce((obj, entry) => {
            const [eventName, handlerName] = entry;

            obj[eventName] = event => {
              // augment each call with topic
              Ember.run.schedule('actions', this, handlerName, topic, event, deferred);
            };

            return obj;
          }, {}); // subscribe to events

          Object.entries(handlers).forEach(([eventName, handler]) => {
            socket.on(eventName, handler);
          });
          return {
            topic,
            socket,
            handlers
          };
        });
        this.set('sockets', sockets);
        this.set('connected', true);
        return Ember.RSVP.allSettled(promises);
      }
    },

    generateUUID() {
      return Math.random().toString(36).substr(2, 9);
    },

    socketUrlFor(topic, sessionId, startDate, endDate) {
      const queryTerms = [`topic=${topic}`, `consumerGroup=_${sessionId}`];

      if (TIME_FILTERING_ENABLED) {
        if (topic === 'tripWidget') {
          const filterString = [`requestTime=ge=${startDate.toISOString()}`, `requestTime=le=${endDate.toISOString()}`].join('%26');
          queryTerms.push(`filter=${filterString}`);
        }

        if (topic === 'routeWidget') {
          const filterString = [`plannedStartTime=lt=${endDate.toISOString()}`, `plannedEndTime=gt=${startDate.toISOString()}`].join('%26');
          queryTerms.push(`filter=${filterString}`);
        }
      }

      const queryString = queryTerms.join('&');
      return `${SOCKET_URL_BASE}/?${queryString}`;
    },

    disconnect() {
      this.get('sockets').forEach(({
        socket,
        handlers
      }) => {
        Object.entries(handlers).forEach(({
          eventName,
          handler
        }) => {
          socket.off(eventName, handler);
        });
        socket.close();
      });
      this.set('sockets', []);
    },

    logout() {
      this.disconnect();
      this.set('connected', false);
    },

    onSocketOpen()
    /*topic, event*/
    {},

    onSocketClose(topic, event) {
      logger.warn('close', topic, event, null);
      const sockets = this.get('sockets');
      const currentSocket = sockets.find(socket => {
        return socket.topic === topic;
      });

      if (SOCKET_CLEAN_CLOSURE_CODE === event.code) {
        this.set('connected', false);
      } else if (SOCKET_BAD_JWT === event.code) {
        // If BAD_JWT is received, disconnect and perform standard logout procedures
        if (!_environment.default.APP.avlmLite) {
          Ember.run(() => this.store.unloadAll());
        } else {
          this.store.unloadAll('cs-config-permission');
        }

        this.disconnect();
        this.get('session').invalidate().then(() => {
          this.get('router').transitionTo('login');
        });
      } else if (SOCKET_BAD_PREFIX === event.code || SOCKET_DUPLICATE_CONSUMER === event.code) {
        // If we receive either bad-prefix or duplicate-consumer
        // we set the connected flag to false to allow reconnection
        this.set('connected', false);
        Ember.run.later(this, () => {
          Ember.run.scheduleOnce('actions', this, 'scheduledConnect');
        }, 1000);
      } else {
        Ember.run.later(this, () => {
          logger.warn('attempting to reconnect to socket');
          currentSocket.socket.reconnect();
        }, 1000);
      }
    },

    scheduledConnect() {
      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      _environment.default.workspaceContext.startDate = this.get('workspace.startDate');
      _environment.default.workspaceContext.endDate = this.get('workspace.endDate');
      this.connect(startDate, endDate);
    },

    onSocketError(topic, event, deferred) {
      logger.warn('error', topic, event, null);
      deferred.reject(event);
    },

    onSocketMessage(topic, event, deferred) {
      if (event.data) {
        let payload;
        /*let digestLookup = this.get('digestLookup');
        let digest = md5(event.data);
        digestLookup[digest] = (digestLookup[digest] || 0) + 1;
         if (digestLookup[digest] > 1) {
          console.warn(`duplicate ${topic} payload`, event.data.slice(0,100));
        }*/

        try {
          // console.log('event.data',event.data);
          payload = JSON.parse(event.data);
        } catch (err) {
          throw `bad topic json payload: ${event.data}`;
        }

        this.onTopicPayload(topic, payload);
      }

      deferred.resolve();
    },

    // this is a good entry point for stubbing a socket payload
    onTopicPayload(topic, payload) {
      // console.log('onTopicPayload payload ',payload);

      /*eslint-disable */
      payload = Ember.makeArray(payload);
      /*eslint-enable */

      if (payload.length === 0) {
        return;
      }

      const workspaceContext = this.get('workspaceContext');
      console.log('workspaceContext.get(startRefreshing) ', workspaceContext.get('startRefreshing'));

      if (!workspaceContext.get('startRefreshing')) {
        //console.log('payload ',payload);

        /* let payloads = payload.map(function(a) {return a.message.data;});
         let payloadStr =  '{"data":'+JSON.stringify(payloads)+"}";
         let payloadArr = {
            data: {
             payloads
           }
         };*/
        // console.log('payloads ',payloadStr);
        //  this.get('workspaceContext').pushNewPayloads(payload);
        payload.forEach(payload1 => {
          if (payload1 !== undefined) this.get('store').pushPayload(payload1.message);
        }); //this.get('store').push(payloadStr);
      } else {
        //this.get('workspaceContext').pushPayloads(payload);
        //run.scheduleOnce('afterRender', workspaceContext, 'flushChanges');
        const updatePayloads = payload.filterBy('action', 'UPDATE');
        const deletePayloads = payload.filterBy('action', 'DELETE');

        if (updatePayloads.length > 0) {
          this.get('queue').push(...updatePayloads);
          Ember.run.scheduleOnce('afterRender', this, 'processUpdateQueue');
        }

        if (deletePayloads.length > 0) {
          deletePayloads.forEach(deletePayload => {
            this.handleDeletePayload(deletePayload);
          });
          const workspaceContext = this.get('workspaceContext');
          Ember.run.scheduleOnce('afterRender', workspaceContext, 'flushChanges');
        }
      }
    },

    processUpdateQueue() {
      const queue = this.get('queue');

      if (queue.length === 0) {
        return;
      }

      const t = new Date().getTime();
      const chunk = [];
      const chunkSize = Math.min(queue.length, CHUNK_SIZE);

      for (let i = 0; i < chunkSize; i++) {
        chunk.push(queue.shift());
      }

      const payloads = chunk.reduce((arr, payload) => {
        const unpackedPayloads = this.unpackTopicPayload(payload);
        Ember.makeArray(unpackedPayloads).forEach(unpackedPayload => {
          arr.push(unpackedPayload);
        });
        return arr;
      }, []);

      if (payloads.length > 0) {
        this.get('workspaceContext').pushPayloads(payloads);
        const workspaceContext = this.get('workspaceContext');
        Ember.run.scheduleOnce('afterRender', workspaceContext, 'flushChanges');
      }

      Ember.run.next(() => {
        Ember.run.scheduleOnce('afterRender', this, 'processUpdateQueue');
      });
    },

    handleUpdatePayload(payload) {
      let payloads = Ember.makeArray(this.unpackTopicPayload(payload.message));
      return this.get('workspaceContext').pushPayloads(payloads);
    },

    handleDeletePayload(payload) {
      const {
        model,
        message: {
          id
        }
      } = payload;
      const workspaceContext = this.get('workspaceContext');

      switch (model) {
        case 'alertsWidget':
          return workspaceContext.removeRecord('alert', id);

        case 'driverWidget':
          return workspaceContext.removeRecord('iq-driver', id);

        case 'messageWidget':
          return workspaceContext.removeRecord('avlm-canned-message', id);

        case 'riderWidget':
          return workspaceContext.removeRecord('iq-rider', id);

        case 'routeWidget':
          return workspaceContext.removeRecord('iq-route', id);

        case 'stopWidget':
          return workspaceContext.removeRecord('iq-stop', id);

        case 'subscriptionWidget':
          return workspaceContext.removeRecord('subscription', id);

        case 'tripWidget':
          return workspaceContext.removeRecord('iq-trip', id);

        case 'userWidget':
          return workspaceContext.removeRecord('sso-user', id);

        case 'vehicleWidget':
          return workspaceContext.removeRecord('vehicle', id);

        case 'avlmRouteWidget':
          return workspaceContext.removeRecord('iq-route', id);

        case 'avlmTripWidget':
          return workspaceContext.removeRecord('iq-trip', id);

        case 'avlmStopWidget':
          return workspaceContext.removeRecord('iq-stop', id);

        case 'avlmVehicleWidget':
          return workspaceContext.removeRecord('vehicle', id);

        default:
          {
            logger.warn(`unhandled socket delete model '${model}'`);
            break;
          }
      }
    },

    unpackTopicPayload(payload) {
      const {
        model
      } = payload;

      switch (model) {
        case 'routeWidgetTaxi':
          return this.unpackRouteTopicPayload(payload.message);

        case 'avl':
          return this.unpackAVLTopicPayload(payload.message);

        case 'driver':
          return this.unpackDriverTopicPayload(payload.message);

        case 'navigationInfoWidgetTaxi':
          return this.unpackNavigationInfoPayload(payload.message);

        case 'routeExecEventsWidgetTaxi':
          return this.unpackRouteExecEventPayload(payload.message);

        case 'stop':
          return this.unpackStopTopicPayload(payload.message);

        case 'trip':
          return this.unpackTripTopicPayload(payload.message);

        case 'tripExecEvent':
          return this.unpackTripExecEventTopicPayload(payload.message);

        case 'vehicle':
          return this.unpackVehicleTopicPayload(payload.message);

        case 'zone':
          return this.unpackZoneTopicPayload(payload.message);

        case 'stand':
          return this.unpackStandTopicPayload(payload.message);

        case 'zoneStatWidgetTaxi':
          return this.unpackZoneStatTopicPayload(payload.message);

        case 'account':
          return this.unpackAccountTopicPayload(payload.message);

        case 'rider':
          return this.unpackRiderTopicPayload(payload.message);

        case 'driverState':
          return this.unpackDriverStateTopicPayload(payload.message);

        case 'vehicleState':
          return this.unpackVehicleStateTopicPayload(payload.message);

        default:
          throw `unhandled topic payload type '${model}'`;
      }
    },

    unpackRouteTopicPayload(payload) {
      const transformedPayloads = this.transformPayload(payload);
      const normalizedPayloads = this.normalizePayloads(transformedPayloads);
      return normalizedPayloads;
    },

    unpackTripExecEventTopicPayload(payload) {
      const transformedPayloads = this.transformPayload(payload);
      const normalizedPayloads = this.normalizePayloads(transformedPayloads);
      return normalizedPayloads;
    },

    unpackTripTopicPayload(payload) {
      const transformedPayloads = this.transformPayload(payload);
      const normalizedPayloads = this.normalizePayloads(transformedPayloads);
      return normalizedPayloads;
    },

    unpackStopTopicPayload(payload) {
      const transformedPayloads = this.transformPayload(payload);
      const normalizedPayloads = this.normalizePayloads(transformedPayloads);
      return normalizedPayloads;
    },

    unpackVehicleStateTopicPayload(payload) {
      const transformedPayloads = this.transformPayload(payload);
      const normalizedPayloads = this.normalizePayloads(transformedPayloads);
      return normalizedPayloads;
    },

    unpackDriverStateTopicPayload(payload) {
      const transformedPayloads = this.transformPayload(payload);
      const normalizedPayloads = this.normalizePayloads(transformedPayloads);
      return normalizedPayloads;
    },

    unpackAVLTopicPayload(payload) {
      const transformedPayloads = this.transformPayload(payload);
      const normalizedPayloads = this.normalizePayloads(transformedPayloads);
      return normalizedPayloads;
    },

    unpackVehicleTopicPayload(payload) {
      const transformedPayloads = this.transformPayload(payload);
      const normalizedPayloads = this.normalizePayloads(transformedPayloads);
      return normalizedPayloads;
    },

    unpackRiderTopicPayload(payload) {
      const transformedPayloads = this.transformPayload(payload);
      const normalizedPayloads = this.normalizePayloads(transformedPayloads);
      return normalizedPayloads;
    },

    unpackDriverTopicPayload(payload) {
      const transformedPayloads = this.transformPayload(payload);
      const normalizedPayloads = this.normalizePayloads(transformedPayloads);
      return normalizedPayloads;
    },

    unpackZoneTopicPayload(payload) {
      const transformedPayloads = this.transformPayload(payload);
      const normalizedPayloads = this.normalizePayloads(transformedPayloads);
      return normalizedPayloads;
    },

    unpackStandTopicPayload(payload) {
      const transformedPayloads = this.transformPayload(payload);
      const normalizedPayloads = this.normalizePayloads(transformedPayloads);
      return normalizedPayloads;
    },

    unpackAccountTopicPayload(payload) {
      const transformedPayloads = this.transformPayload(payload);
      const normalizedPayloads = this.normalizePayloads(transformedPayloads);
      return normalizedPayloads;
    },

    unpackZoneStatTopicPayload(payload) {
      payload.data.type = 'zone-stat';
      return this._normalizePayload(payload);
    },

    unpackNavigationInfoPayload(payload) {
      const transformedPayloads = this.transformPayload(payload);
      const normalizedPayloads = this.normalizePayloads(transformedPayloads);
      return normalizedPayloads;
    },

    unpackRouteExecEventPayload(payload) {
      let {
        routeId,
        tripId,
        type
      } = payload.data.attributes;
      let stopId = payload.data.attributes.stopPointId;
      let routeAttributes = {};
      let tripAttributes = {};
      let stopAttributes = {};

      switch (type) {
        case 'No Show':
          {
            //logger.debug(`routeExec ${type} tripId=${tripId}`)
            tripAttributes.status = type;
            break;
          }

        case 'Arrive':
        case 'Depart':
          {
            //logger.debug(`routeExec ${type} stopId=${stopId}`)
            stopAttributes.status = type;
            break;
          }

        case 'Accept':
        case 'Reject':
        case 'OnBreak':
        case 'OffBreak':
          {
            //logger.debug(`routeExec ${type} routeId=${routeId}`)
            routeAttributes.status = type;
            break;
          }
        // these are currently defined but unused

        case 'OnStop':
        case 'Complete':
        case 'work': // TODO: check if this should be capitalized

        case 'ETA':
        case 'AVL':
        case 'Pullout':
        case 'Pullin':
        default:
          break;
      }

      let normalizedPayloads = [];

      if (routeId) {
        let {
          otpRouteStatus,
          otpRouteValue
        } = payload.data.attributes;

        if (Ember.isPresent(otpRouteStatus)) {
          routeAttributes.otp = otpRouteStatus;
        }

        if (Ember.isPresent(otpRouteValue)) {
          routeAttributes.otpValue = otpRouteValue;
        }

        let routePayload = {
          data: {
            id: routeId,
            type: 'route',
            attributes: routeAttributes
          }
        };

        let normalizedRoutePayload = this._normalizePayload(routePayload);

        normalizedPayloads.push(normalizedRoutePayload);
      }

      if (tripId) {
        let {
          otpTripStatus,
          otpTripValue
        } = payload.data.attributes;

        if (Ember.isPresent(otpTripStatus)) {
          tripAttributes.otp = otpTripStatus;
        }

        if (Ember.isPresent(otpTripValue)) {
          tripAttributes.otpValue = otpTripValue;
        }

        let tripPayload = {
          data: {
            id: tripId,
            type: 'trip',
            attributes: tripAttributes
          }
        };

        let normalizedTripPayload = this._normalizePayload(tripPayload);

        normalizedPayloads.push(normalizedTripPayload);
      }

      if (stopId) {
        let {
          otpStopStatus,
          otpStopValue
        } = payload.data.attributes;

        if (Ember.isPresent(otpStopStatus)) {
          stopAttributes.otp = otpStopStatus;
        }

        if (Ember.isPresent(otpStopValue)) {
          stopAttributes.otpValue = otpStopValue;
        }

        let stopPayload = {
          data: {
            id: stopId,
            type: 'stop',
            attributes: stopAttributes
          }
        };

        let normalizedStopPayload = this._normalizePayload(stopPayload);

        normalizedPayloads.push(normalizedStopPayload);
      }

      return normalizedPayloads;
    },

    // one-to-many transform; always returns an array
    transformPayload(payload) {
      const store = this.get('store');
      const transform = TRANSFORMS[payload.data.type];
      (true && !(transform) && Ember.assert(`transform exists for payload type '${payload.data.type}'`, transform));

      const clonedPayload = _lodash.default.cloneDeep(payload);

      const transformedPayloads = transform ? transform(store, clonedPayload, logger) : clonedPayload;
      return Ember.makeArray(transformedPayloads);
    },

    transformMessagePayload(payload) {
      const store = this.get('store');
      const transform = TRANSFORMS[payload.message.type];
      (true && !(transform) && Ember.assert(`transform exists for payload type '${payload.data.type}'`, transform));

      const clonedPayload = _lodash.default.cloneDeep(payload);

      const transformedPayloads = transform ? transform(store, clonedPayload, logger) : clonedPayload;
      return Ember.makeArray(transformedPayloads);
    },

    // many-to-many transform
    _normalizePayloads(payloads) {
      const store = this.get('store');
      return payloads.map(payload => {
        const {
          id,
          type
        } = payload.data;
        const modelClass = store.modelFor(type);
        const serializer = store.serializerFor(type);
        const normalized = serializer.normalizeResponse(store, modelClass, payload, id, 'findRecord');
        return normalized;
      });
    },

    _normalizePayload(payload) {
      const store = this.get('store');
      const {
        id,
        type
      } = payload.data;
      const modelClass = store.modelFor(type);
      const serializer = store.serializerFor(type);
      return serializer.normalizeResponse(store, modelClass, payload, id, 'findRecord');
    },

    normalizePayloads(payloads) {
      const store = this.get('store');
      return payloads.map(payload => {
        const {
          id,
          type
        } = payload.data;
        const modelClass = store.modelFor(type);
        const serializer = store.serializerFor(type);
        const normalized = serializer.normalizeResponse(store, modelClass, payload, id, 'findRecord');
        return normalized;
      });
    },

    normalizeMessagePayloads(payloads) {
      const store = this.get('store');
      return payloads.map(payload => {
        const {
          id,
          type
        } = payload.data;
        const modelClass = store.modelFor(type);
        const serializer = store.serializerFor(type);
        const normalized = serializer.normalizeResponse(store, modelClass, payload, id, 'findRecord');
        return normalized;
      });
    },

    normalizePayload(payload) {
      const store = this.get('store');
      const {
        id,
        type
      } = payload.data;
      const modelClass = store.modelFor(type);
      const serializer = store.serializerFor(type);
      return serializer.normalizeResponse(store, modelClass, payload, id, 'findRecord');
    }

  });

  _exports.default = _default;
});