define("adept-iq/models/iq-rider", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    rider: (0, _relationships.belongsTo)('rider', {
      inverse: 'iqRider'
    }),
    trips: Ember.computed.alias('rider.trips')
  });

  _exports.default = _default;
});