define("adept-iq/pods/components/table/cells/user-editable/component", ["exports", "adept-iq/pods/components/table/cells/base-cell/component", "adept-iq/utils/save-records", "adept-iq/mixins/table-row-value", "moment"], function (_exports, _component, _saveRecords, _tableRowValue, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const KEYCODE_TAB = 9;
  const KEYCODE_ENTER = 13;
  const KEYCODE_ESCAPE = 27;

  var _default = _component.default.extend(_tableRowValue.default, {
    classNames: ['table-user-editable-cell', 'data-test-table-user-editable-cell'],
    classNameBindings: ['canFocus'],
    errorMessage: Ember.inject.service(),
    editModal: Ember.inject.service(),
    isEditing: false,
    buffer: null,
    canFocus: Ember.computed.not('isEditing'),
    isDoubleClick: Ember.computed.and('row.isEditing', 'column.isEditing'),
    onIsEditingChange: Ember.observer('isDoubleClick', function () {
      if (this.get('isDoubleClick')) {
        this.startEditing();
      }
    }),

    startEditing() {
      if (this.get('isEditing')) return;
      this.set('isEditing', true);
      const row = this.get('row');
      const valuePath = this.get('column.valuePath');
      const value = row.get(valuePath);
      const type = this.get('column.columnType');
      const format = this.get('column.format');
      const buffer = this.formatInputValue(value, type, format);
      this.set('buffer', buffer);
      Ember.run.scheduleOnce('afterRender', () => {
        this.$('input').focus();
      });
    },

    formatInputValue(value, type, format) {
      switch (type.id) {
        case 'date':
        case 'datetime':
        case 'datetimeflatpickr':
        case 'time':
          return format(value);

        default:
          return value ? value.toString() : '';
      }
    },

    parseInputValue(value, type, parse, originalValue) {
      switch (type.id) {
        case 'date':
          return new Date(value);

        case 'datetime':
        case 'datetimeflatpickr':
        case 'time':
          return parse(value, originalValue);

        default:
          return value;
      }
    },

    validate(value) {
      let valid = true;
      const config = this.get('column.config');

      if (Ember.isNone(config.validation)) {
        return true;
      }

      const minValue = config.validation.minValue;
      const maxValue = config.validation.maxValue;
      const type = config.validation.type;

      if (!Ember.isNone(minValue) && !Ember.isNone(maxValue)) {
        switch (type) {
          case 'text':
            {
              if (value.length < minValue || value.length > maxValue) {
                let message = '';

                if (minValue === maxValue) {
                  message = `${config.label} must be ${minValue} characters in length.`;
                } else {
                  message = `${config.label} must be between ${minValue} and ${maxValue} characters in length.`;
                }

                valid = false;
                this.get('errorMessage').pushWarning({
                  detail: message
                });
              }

              break;
            }

          case 'phoneNumber':
            {
              const numOnly = value.replace(/[^0-9]/g, '');

              if (numOnly.length < minValue || numOnly.length > maxValue) {
                let message = '';

                if (minValue === maxValue) {
                  message = `${config.label} must have ${minValue} digits.`;
                } else {
                  message = `${config.label} must be between ${minValue} and ${maxValue} digits.`;
                }

                valid = false;
                this.get('errorMessage').pushWarning({
                  detail: message
                });
              }

              break;
            }

          case 'date':
            {
              const valueMoment = (0, _moment.default)(value);
              const minValueMoment = minValue === 'now' ? (0, _moment.default)() : (0, _moment.default)(minValue);
              const maxValueMoment = maxValue === 'now' ? (0, _moment.default)() : (0, _moment.default)(maxValue);
              let message = '';

              if (!valueMoment.isBetween(minValueMoment, maxValueMoment, null, '[]')) {
                valid = false;
                message = `${config.label} must be between ${minValueMoment.format('YYYY-MM-DD')} and ${maxValueMoment.format('YYYY-MM-DD')}.`;
                this.get('errorMessage').pushWarning({
                  detail: message
                });
              }

              break;
            }

          default:
            {
              valid = false;
              break;
            }
        }
      }

      return valid;
    },

    saveChanges() {
      if (!this.get('isEditing')) return;
      this.set('row.isEditing', false);
      this.set('column.isEditing', false);
      this.set('isEditing', false);
      const buffer = this.get('buffer');
      const type = this.get('column.columnType');
      const parse = this.get('column.parse');
      const row = this.get('row');
      const valuePath = this.get('column.valuePath');
      const origialValue = row.get(valuePath) || '';
      const newValue = this.parseInputValue(buffer, type, parse, origialValue) || '';

      if (this.validate(newValue)) {
        row.set(valuePath, newValue); // get the last model in the value path

        const path = valuePath.split('.');
        path.pop();
        const modelPatch = path.join('.'); // patch that specific model

        row.get(modelPatch).reload().then(res => {
          return (0, _saveRecords.default)([res]);
        });
      }
    },

    discardChanges() {
      if (!this.get('isEditing')) return;
      this.set('isEditing', false);
      this.set('row.isEditing', false);
      this.set('column.isEditing', false);
    },

    actions: {
      onInput(text) {
        this.set('buffer', text);
      },

      onKeydown(keyCode) {
        if (keyCode === KEYCODE_ESCAPE) {
          this.discardChanges();
        }

        if (keyCode === KEYCODE_TAB || keyCode === KEYCODE_ENTER) {
          this.saveChanges();
        }
      },

      onBlur() {
        this.saveChanges();
      }

    }
  });

  _exports.default = _default;
});