define("adept-iq/pods/components/form-components/add-new-message/component", ["exports", "adept-iq/pods/components/form-components/add-new-message/config", "adept-iq/utils/populate-new-text-message"], function (_exports, _config, _populateNewTextMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    editModal: Ember.inject.service(),
    textmessage: Ember.inject.service(),
    title: 'Send Message',
    sections: _config.default.sections,
    templates: null,
    selectedTemplate: null,
    messageText: null,
    selectedVehicles: null,
    selectedZones: null,
    selectedDrivers: null,
    vehicles: null,
    zones: null,
    drivers: null,
    options: null,
    tempValue: null,
    draftMessages: Ember.computed.alias('workspace.topOptions.draftMessages'),

    /* This Screen is developed as  a demo screen can be do better way*/
    init() {
      let me = this;

      this._super(...arguments);

      Ember.run.later(function () {
        let options = [];
        options.push('Zone');
        options.push('Vehicle');
        options.push('Driver');
        let vehicles = [];
        vehicles = me.get('store').peekAll('iqVehicle').filter(vehicle => vehicle.get('status') !== 'off');
        /*.then(function(vehiclesList){
          console.log('vehiclesList ',vehiclesList);
                vehiclesList.forEach(record => {
            console.log('vehiclesList ',record.get('status'));
            if(record.get('status') !== 'off') {
              vehicles.push(record);
            }
          });
         })*/

        let zones = me.get('store').peekAll('zone');
        let drivers = me.get('store').peekAll('driver');
        console.log('vehicles ', vehicles);
        console.log('zones ', zones);
        console.log('drivers ', drivers);
        me.set('vehicles', vehicles);
        me.set('drivers', drivers);
        me.set('zones', zones);
        me.set('options', options);
        me.set('tempValue', 'Vehicle');
      }, 10000);
    },

    validateRecords(draftMessages) {
      let valid = true;
      this.set('editModal.errors', []);
      console.log('isBlank ', Ember.isBlank(this.get('messageText')));

      if (Ember.isBlank(this.get('messageText'))) {
        valid = false;
        this.get('errorMessage').pushError({
          detail: `Message should be there`
        });
      }

      return valid;
    },

    sendMessage() {
      let draftMessages;

      if (!Ember.isBlank(this.get('selectedVehicles'))) {
        draftMessages = this.get('selectedVehicles').map(vehicle => {
          let newTestMessage = (0, _populateNewTextMessage.default)(this.get('textmessage'), this.get('store'), vehicle, null, null);
          return newTestMessage;
        });
      }

      if (!Ember.isBlank(this.get('selectedDrivers'))) {
        draftMessages = this.get('selectedDrivers').map(driver => {
          let newTestMessage = (0, _populateNewTextMessage.default)(this.get('textmessage'), this.get('store'), null, driver, null);
          return newTestMessage;
        });
      }

      if (!Ember.isBlank(this.get('selectedZones'))) {
        draftMessages = this.get('selectedZones').map(zone => {
          zone.get('offVehicle').map(vehicle => {
            let newTestMessage = (0, _populateNewTextMessage.default)(this.get('textmessage'), this.get('store'), vehicle, null, null);
            draftMessages.push(newTestMessage);
            return newTestMessage;
          });
        });
      }

      if (this.validateRecords(draftMessages)) {
        draftMessages.forEach(message => {
          message.set('body', this.get('messageText'));
          message.set('subject', this.get('messageText'));
        });
        const me = this;
        return new Promise((resolve, reject) => {
          draftMessages.forEach(message => {
            message.save().then(() => {
              me.get('errorMessage').pushInfo({
                detail: `Message successfully send`
              });
            }).catch(function () {
              return [];
            });
          });
        });
      }
    },

    cancelMessage() {
      this.get('tooltip').reset();
    },

    actions: {
      valueChanged(value) {
        this.set('selectedVehicles', []);
        this.set('selectedDrivers', []);
        this.set('selectedZones', []);
        this.set('tempValue', value.trim());
      },

      onInput(value) {
        this.set(`messageText`, value);
      },

      onVehiclesChange(record, value) {
        console.log('onVehiclesChange record ', record);
        console.log('onVehiclesChange value ', value);
        this.set('selectedVehicles', value);
      },

      onDriversChange(record, value) {
        this.set('selectedDrivers', value);
      },

      onZonesChange(record, value) {
        this.set('selectedZones', value);
      },

      onSendMessageButtonClick() {
        this.sendMessage();
        /*let tooltip = this.get('tooltip');
         tooltip.setProperties({
          tip: 'Send new message?',
          primaryActionText: 'Confirm',
          secondaryActionText: 'Cancel',
          primaryAction: () => {
            tooltip.reset();
            this.sendMessage();
          },
          secondaryAction: () => {
            toolbar.reset();
            this.cancelMessage();
          }
        });*/
      },

      onTemplateSelect(template) {
        this.set('selectedTemplate', template);
      },

      onCloseButtonClick() {
        this.get('workspace').popState();
      }

    }
  });

  _exports.default = _default;
});