define("adept-iq/models/cluster", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    distance: attr('number'),
    endTime: attr('date'),
    headCount: attr('number'),
    ordinal: attr('number'),
    startTime: attr('date'),
    state: attr('string'),
    route: belongsTo(),
    schedule: belongsTo(),
    trips: hasMany(),
    orderedStops: Ember.computed('trips.@each.ordinalStops', function () {
      const ordinalStops = this.get('trips').reduce((arr, trip) => {
        const stops = trip.get('ordinalStops');
        arr.push(...stops);
        return arr;
      }, []);
      return ordinalStops.sortBy('ordinal');
    })
  });

  _exports.default = _default;
});