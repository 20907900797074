define("adept-iq/pods/components/generic-widgets/tree-widget/tree-widget-row/component", ["exports", "adept-iq/pods/components/generic-widgets/tree-widget/tree-widget-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['tree-widget-row'],
    classNameBindings: ['isExpanded', 'isExpandable', 'isCategory', 'isObject', 'isValue', 'isNumber', 'isString', 'isBoolean', 'rowDepthClass', 'isModified', 'isOutdated'],
    node: null,
    // immutable
    label: Ember.computed.readOnly('node.label'),
    depth: Ember.computed.readOnly('node.depth'),
    canEdit: Ember.computed.readOnly('node.canEdit'),
    isExpandable: Ember.computed.readOnly('node.isExpandable'),
    isCategory: Ember.computed.readOnly('node.isCategory'),
    isCollection: Ember.computed.readOnly('node.isCollection'),
    isObject: Ember.computed.readOnly('node.isObject'),
    isValue: Ember.computed.readOnly('node.isValue'),
    isNumber: Ember.computed.readOnly('node.isNumber'),
    isString: Ember.computed.readOnly('node.isString'),
    isBoolean: Ember.computed.readOnly('node.isBoolean'),
    isColor: Ember.computed.readOnly('node.isColor'),
    // component doesn't know about hidden/deleted children
    children: Ember.computed.readOnly('node.visibleChildren'),
    canAdd: Ember.computed.readOnly('node.canAdd'),
    canRemove: Ember.computed.readOnly('node.canRemove'),
    // mutable
    value: Ember.computed.alias('node.value'),
    isExpanded: Ember.computed.alias('node.isExpanded'),
    // state
    isOutdated: Ember.computed.alias('node.isTreeOutdated'),
    isModified: Ember.computed.alias('node.isTreeModified'),
    // indentation styling
    rowDepthClass: Ember.computed('depth', function () {
      const depth = this.get('depth');
      return `row-depth-${depth}`;
    }),
    actions: {
      onExpandButtonToggle() {
        this.toggleProperty('node.isExpanded');
      },

      onRemoveButtonClicked() {
        this.set('node.isRemoved', true);
      },

      onAddButtonClicked() {// TODO: implement add functionality
      },

      onColorChanged(colorValue) {
        this.set('node.value', colorValue);
      },

      onCheckboxClick() {
        this.toggleProperty('value');
      },

      onTextInput(text) {
        this.set('node.value', text);
      }

    }
  });

  _exports.default = _default;
});