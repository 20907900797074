define("adept-iq/pods/components/generic-widgets/tree-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/tree-widget/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ROW_SELECTOR = '.tree-widget-row';
  const CONTROL_SELECTOR = '.btn-expand,.tree-widget-row-input';
  const EXPAND_BUTTON_SELECTOR = '.btn-expand';

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['tree-widget'],
    rowComponent: 'generic-widgets/tree-widget/tree-widget-row',
    // override these when extending component
    rootNodes: null,
    isLoading: false,
    // eliminates hierarchical tree structure for easier rendering and styling
    flattenedNodes: Ember.computed('rootNodes.@each.visibleTree', function () {
      const rootNodes = this.get('rootNodes');
      if (Ember.isEmpty(rootNodes)) return [];
      return rootNodes.sortBy('label').reduce((arr, node) => {
        const nodes = node.get('visibleTree');
        arr.push(...nodes);
        return arr;
      }, []);
    }),

    didInsertElement() {
      this._super(...arguments);

      this._onKeyDown = event => {
        Ember.run.schedule('afterRender', this, 'onKeyDown', event);
      };

      this.$().on('keydown', this._onKeyDown);
      this.$(CONTROL_SELECTOR).first().focus();
    },

    willRemoveElement() {
      this.$().off('keydown', this._onKeyDown);
      this._onKeyDown = null;

      this._super(...arguments);
    },

    onKeyDown(event) {
      switch (event.key) {
        case 'ArrowUp':
          return this.onArrowUp(event);

        case 'ArrowDown':
          return this.onArrowDown(event);

        case 'ArrowLeft':
          return this.onArrowLeft(event);

        case 'ArrowRight':
          return this.onArrowRight(event);

        default:
          return '';
      }
    },

    onArrowUp(event) {
      const row = event.target.closest(ROW_SELECTOR);
      const previousRow = row.previousElementSibling;
      if (!previousRow) return;
      const control = previousRow.querySelector(CONTROL_SELECTOR);
      if (!control) return;
      event.preventDefault();
      control.focus();
    },

    onArrowDown(event) {
      const row = event.target.closest(ROW_SELECTOR);
      const nextRow = row.nextElementSibling;
      if (!nextRow) return;
      const control = nextRow.querySelector(CONTROL_SELECTOR);
      if (!control) return;
      event.preventDefault();
      control.focus();
    },

    onArrowLeft(event) {
      const row = event.target.closest(ROW_SELECTOR);
      if (!row.classList.contains('is-expanded')) return;
      const expandButton = row.querySelector(EXPAND_BUTTON_SELECTOR);
      if (!expandButton) return;
      event.preventDefault();
      expandButton.click();
    },

    onArrowRight(event) {
      const row = event.target.closest(ROW_SELECTOR);

      if (!row.classList.contains('is-expandable') || row.classList.contains('is-expanded')) {
        return;
      }

      const expandButton = row.querySelector(EXPAND_BUTTON_SELECTOR);
      if (!expandButton) return;
      event.preventDefault();
      expandButton.click();
    },

    click(event) {
      const row = event.target.closest(ROW_SELECTOR);
      const control = row.querySelector(CONTROL_SELECTOR);

      if (control) {
        control.focus();
      }
    }

  });

  _exports.default = _default;
});