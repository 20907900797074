define("adept-iq/classes/shapes/stand", ["exports", "adept-iq/classes/shapes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    style: 'stand',
    label: 'Stands',
    parentLabel: 'Stands',
    isActive: true,
    isTraversal: true,
    stand: Ember.computed.alias('record'),
    lat: Ember.computed.alias('stand.lat'),
    lng: Ember.computed.alias('stand.lng'),
    radius: Ember.computed.alias('stand.radius'),
    options: Ember.computed.alias('stand.options'),
    points: Ember.computed.alias('stand.polypoints'),
    caption: Ember.computed.alias('stand.name'),
    status: Ember.computed.alias('stand.status'),
    noOfPoints: Ember.computed.alias('stand.noOfPoints')
  });

  _exports.default = _default;
});