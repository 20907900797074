define("adept-iq/pods/components/iq-widgets/accounts-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/accounts-widget',
    rowComponent: 'table/rows/otp-formatted-row',
    modelName: 'account',
    defaultSortId: 'name',
    defaultSortAsc: false,
    accessKey: 'a',
    title: 'Accounts',
    columns: [{
      id: 'acctName',
      index: 0,
      type: 'text',
      label: 'Name',
      valuePath: 'acctName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'acctCode',
      type: 'text',
      label: 'Code',
      valuePath: 'acctCode',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'acctCardNr',
      type: 'text',
      label: 'Card Nr',
      valuePath: 'acctCardNr',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'contactName',
      type: 'text',
      label: 'Contact Name',
      valuePath: 'contactName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'phoneNr',
      type: 'text',
      label: 'Phone Number',
      valuePath: 'phoneNr',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'driverNotes',
      type: 'text',
      label: 'Driver Notes',
      valuePath: 'driverNotes',
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'operatorNotes',
      type: 'text',
      label: 'Operator Notes',
      valuePath: 'operatorNotes',
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'expiryDTM',
      type: 'datetime',
      label: 'Expiry Date',
      valuePath: 'expiryDTM',
      valuePreview: '09:00 AM',
      editable: false,
      format: 'YYYY-MM-DD hh:mm A',
      defaultWidth: 50,
      hidden: true
    }, {
      id: 'balance',
      type: 'text',
      label: 'Balance',
      valuePath: 'balance',
      valuePreview: 'Pick',
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'creditLimit',
      type: 'text',
      label: 'Credit Limit',
      valuePath: 'creditLimit',
      valuePreview: 'Pick',
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'tripsRemaining',
      type: 'text',
      label: 'Trips remaining',
      valuePath: 'tripsRemaining',
      valuePreview: 'Pick',
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'tripLimit',
      type: 'text',
      label: 'Trip Limit',
      valuePath: 'tripLimit',
      valuePreview: 'Pick',
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'Address',
      index: 5,
      type: 'enum-address',
      label: 'Address',
      valuePath: 'formattedFavoriteTripsPickup',
      cellDesc: 'Addresses',
      searchable: true,
      editable: false,
      hidden: false,
      defaultWidth: 120
    }]
  };
  _exports.default = _default;
});