define("adept-iq/pods/components/side-drawer/system-config/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UNSAVED_CHANGES_CONFIRMATION = 'You have unsaved changes that will be discarded. Refresh?';

  var _default = Ember.Component.extend({
    tagName: '',
    systemConfig: Ember.inject.service(),
    title: 'System Configuration',
    // gets passed in by `side-drawer-container`
    onShrinkClick: null,
    rootNodes: Ember.computed.readOnly('systemConfig.rootNodes'),
    isProcessing: Ember.computed.readOnly('systemConfig.isProcessing'),
    isModified: Ember.computed.readOnly('systemConfig.isModified'),
    actions: {
      onSaveClick() {
        this.get('systemConfig').save();
      },

      onRefreshClick() {
        /*eslint-disable */
        if (this.get('isModified') && !confirm(UNSAVED_CHANGES_CONFIRMATION)) {
          return;
        }
        /*eslint-enable */


        this.get('systemConfig').refresh();
      }

    }
  });

  _exports.default = _default;
});