define("adept-iq/pods/components/iq-widgets/new-trip-fixform-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/trip-form-widget/component", "adept-iq/utils/populate-new-trip", "adept-iq/utils/populate-account", "adept-iq/config/api-urls", "adept-iq/pods/components/iq-widgets/new-trip-fixform-widget/config"], function (_exports, _component, _component2, _populateNewTrip, _populateAccount, _apiUrls, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RESIZE_CURSOR_OFFSET = -2;
  const MAX_WIDTH = 800;
  const RESIZE_DEBOUNCE_TIME = 250;

  var _default = _component2.default.extend({
    classNames: ['new-trip-fixform-widget'],
    editableSections: _config.default.editableSections,
    router: Ember.inject.service('router'),
    accountSearchPath: _apiUrls.API_SUB_URL.accountSearchPath,
    trip: Ember.inject.service(),
    geocode: Ember.inject.service(),
    store: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    searchservice: Ember.inject.service(),
    ajax: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    session: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),

    init() {
      console.log('new-trip-fixform-widget', this.get('editModal.showTripFormPopup')); // this.set('editModal.showTripFormPopup', false);

      this._super(...arguments);

      this.set('editableRecords', []);
      this.set('undoHistory', []);
      this.set('errors', []);
      let newtrip = (0, _populateNewTrip.default)(this.get('trip'), this.get('store'));
      (0, _populateAccount.default)(newtrip, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), newtrip.acctCardNr, true);
      this.get('editableRecords').pushObject(newtrip);
      let newTripComponent = this.get('newTripComponent');
      this.get('editModal').open(newTripComponent, [newtrip], this.get('saveTripRecordFunction').bind(this));
    },

    actions: {
      resizeModal(position) {
        let windowWidth = $(window).width(); // Resize cursor offset ensures cursor is centered on handle.

        let modalWidth = windowWidth - position.x + RESIZE_CURSOR_OFFSET;
        run.scheduleOnce('afterRender', () => {
          if (windowWidth - modalWidth >= MAX_WIDTH) {
            $('.edit-widget-modal').css('right', `${windowWidth - MAX_WIDTH}px`);
          } else {
            $('.edit-widget-modal').css('right', `${modalWidth}px`);
          }
        });
      },

      closeWidget() {
        this.toggleProperty('editmodalcontext.showWidgetModal');
      }

    }
  });

  _exports.default = _default;
});