define("adept-iq/pods/dashboard/modals/edit-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3+6daL0Z",
    "block": "{\"symbols\":[],\"statements\":[[0,\"    \\n \"],[7,\"div\"],[11,\"id\",\"draggable\"],[11,\"draggable\",\"true\"],[11,\"style\",\"z-index:9999;display:none\"],[11,\"class\",\"ui-widget-content\"],[9],[0,\"\\n\\n \"],[1,[21,\"iq-widgets/cli-widget\"],false],[0,\"\\n \"],[10],[0,\" \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/dashboard/modals/edit-form/template.hbs"
    }
  });

  _exports.default = _default;
});