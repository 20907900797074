define("adept-iq/pods/components/iq-widgets/trip-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/trip-form-widget/config", "adept-iq/utils/check-value", "moment", "adept-iq/utils/populate-new-trip", "adept-iq/utils/populate-copy-trip", "adept-iq/utils/populate-edit-trip", "adept-iq/utils/populate-search-trip", "adept-iq/utils/populate-account", "adept-iq/config/environment", "adept-iq/config/api-urls"], function (_exports, _component, _config, _checkValue, _moment, _populateNewTrip, _populateCopyTrip, _populateEditTrip, _populateSearchTrip, _populateAccount, _environment, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    trip: Ember.inject.service(),
    geocode: Ember.inject.service(),
    store: Ember.inject.service(),
    editModal: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    searchservice: Ember.inject.service(),
    ajax: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    session: Ember.inject.service(),
    editComponentAction: null,
    accountSearchPath: _apiUrls.API_SUB_URL.accountSearchPath,
    searchTripComponent: 'iq-widgets/search-trip-form-widget',
    editTripComponent: 'iq-widgets/edit-trip-form-widget',
    newTripComponent: 'iq-widgets/new-trip-form-widget',
    editSwitchTripComponent: 'iq-widgets/edit-switch-trip-form-widget',
    newSwitchTripComponent: 'iq-widgets/new-switch-trip-form-widget',
    newTripFixComponent: 'iq-widgets/new-trip-fixform-widget',
    cancelTripComponent: 'iq-widgets/cancel-type-form-widget',
    notesSeparator: "<>",
    classNames: ['trip-form-widget'],

    prepareData() {
      let records = this.get('editableRecords');
      this.set('editModal.errors', []);
      records.forEach(record => {
        //Setting Data from helper Model Properties to orignal Model Properties.
        let requiredAttributes = [];

        if (!Ember.isEmpty(record.get('attributes'))) {
          record.get('attributes').forEach(function (item) {
            requiredAttributes.push({
              "type": item.get('type'),
              "cnt": 0
            });
          });
        }

        record.set('passengers.0.requiredAttributes', requiredAttributes);
        record.set('stopPoints.0.passengerEvents.0.driverNote', (0, _checkValue.default)(record.get('driverNotes')) + this.get('notesSeparator') + (0, _checkValue.default)(record.get('predefinedNotes.driverNotes')));
        record.set('passengers.0.bookingNote', (0, _checkValue.default)(record.get('operatorNotes')) + this.get('notesSeparator') + (0, _checkValue.default)(record.get('predefinedNotes.operatorNotes')));
        /*try {
          record.set('stopPoints.0.address.formattedAddress', record.get('stopPoints.0.address.tomtomFormattedAddress.address.freeformAddress'));
         } catch(e) {
         }
         try {
          record.set('stopPoints.1.address.formattedAddress', record.get('stopPoints.1.address.tomtomFormattedAddress.address.freeformAddress'));
        } catch(e) {
          //console.log(e);
        }*/

        try {
          record.set('price.payerIdentifier', record.get('account.attributes.account.accountId'));
        } catch (e) {//console.log(e);
        }

        try {
          record.set('prices.0.payerIdentifier', record.get('account.attributes.account.accountId'));
        } catch (e) {
          console.log(e);
        }

        try {
          record.set('prices.0.fixed', record.get('fixedAmount'));
        } catch (e) {
          console.log(e);
        }

        console.log('prepareData record', record);
      });
    },

    validateRecords() {
      let valid = true;
      this.prepareData();
      let records = this.get('editableRecords');
      console.log('validateRecords', records);
      this.set('editModal.errors', []);
      records.forEach(record => {
        const requestTime = (0, _moment.default)(record.stopPoints[0].passengerEvents[0].time);

        if (Ember.isNone(requestTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Date should be there');
        }

        let latlng = "";

        if (record.stopPoints[0].address.coord.lat !== undefined && record.stopPoints[0].address.coord.lng !== undefined && record.stopPoints[0].address.coord.lat !== 0 && record.stopPoints[0].address.coord.lng !== 0 && record.stopPoints[0].address.coord.lat !== 181 && record.stopPoints[0].address.coord.lng !== 181 && record.stopPoints[0].address.coord.lat !== -181 && record.stopPoints[0].address.coord.lng !== -181) {
          latlng = `${record.stopPoints[0].address.coord.lat}/${record.stopPoints[0].address.coord.lng}`;
        }

        if (Ember.isEmpty(latlng)) {
          valid = false;
          this.get('editModal.errors').pushObject('Valid Pickup address should be there');
        }
        /* if (record.stopPoints[0].address.streetNr !== undefined) {
           valid = false;
           this.get('editModal.errors').pushObject('Pickup address should contain street number');
         }*/
        //TODO Temparory for Taxibook Integration


        if (Ember.isBlank(record.acctCardNr)) {
          valid = false;
          this.get('editModal.errors').pushObject('Account should be there');
        }
      });
      return valid;
    },

    init() {
      this._super(...arguments);

      this.set('editmodalcontext.showWidgetModal', false);
      this.set('editModal.showActivityLog', false);
      this.set('editModal.editComponentAction', '');
      /*if(isEmpty(this.get('editableRecords'))) {
      let newtrip =  populateNewTrip(this.get('trip'),
                                          this.get('store'));
         populateAccount(newtrip,
                        null,
                        this.get('geocode'),
                        this.get('store'),
                        this.get('editmodalcontext'),
                        this.get('searchservice'),
                        this.get('accountSearchPath'),
                        newtrip.acctCardNr,
                        true);
        this.get('editableRecords').pushObject(newtrip);
        let newTripComponent = this.get('newTripComponent');
        this.get('editModal').open(newTripComponent, [newtrip], this.get('saveTripRecordFunction').bind(this));
      }*/
    },

    didInsertElement() {
      this._super(...arguments);

      const el = this.$('input,.ember-power-select-trigger'); //console.log('el ',el);

      if (el && el[0]) {
        el[0].focus();
      }
    },

    saveTripRecordFunction(records, editComponentAction) {
      console.log(' saveTripRecordFunction ', editComponentAction);
      const me = this;
      return new Promise((resolve, reject) => {
        let record = records.firstObject;
        record.save().then(() => {
          console.log('after saveTripRecordFunction ');
          const tripId = record.get('id');
          me.get('errorMessage').pushInfo({
            detail: `Trip '${tripId}' successfully saved`
          });
          record.set('lastId', record.get('id'));
          record.set('isForceDirty', false);

          if (!Ember.isBlank(editComponentAction) && editComponentAction === 'onApplyClick') {
            Ember.run(() => {
              Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
              me.get('geocode').activateGeocode(false);
              me.get('geocode').deactivateGeocode();
              let recordRefresh = [];
              let newtrip = (0, _populateEditTrip.default)(me.get('trip'), record, me.get('store'), me.get('geocode'));
              (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, false);
              recordRefresh.push(newtrip);
              let newTripComponent = me.get('editSwitchTripComponent');

              if (this.get('editModal.editComponent') !== null && this.get('editModal.editComponent') === this.get('editSwitchTripComponent')) {
                newTripComponent = me.get('editTripComponent');
              }

              me.get('editModal').open(newTripComponent, recordRefresh, me.get('saveTripRecordFunction').bind(me));
            });
          } else if (!Ember.isBlank(editComponentAction) && editComponentAction === 'onApplyClearClick') {
            Ember.run(() => {
              Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
              me.get('geocode').activateGeocode(false);
              me.get('geocode').deactivateGeocode();
              let recordRefresh = [];
              let newtrip = (0, _populateNewTrip.default)(me.get('trip'), me.get('store'));
              (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, false);
              newtrip.set('lastId', record.get('lastId'));
              recordRefresh.push(newtrip); //me.set('editableRecords',recordRefresh);
              //me.set('records',recordRefresh);

              let newTripComponent = me.get('newSwitchTripComponent');

              if (me.get('editModal.editComponent') !== null && me.get('editModal.editComponent') === me.get('newSwitchTripComponent')) {
                newTripComponent = me.get('newTripComponent');
              }

              me.get('editModal').open(newTripComponent, recordRefresh, me.get('saveTripRecordFunction').bind(me));
            });
          }
        }).catch(function (error) {
          console.log(error);
          reject(error);
        });
      });
    },

    saveCancelRecordFunction(records) {
      let me = this;
      return new Promise((resolve, reject) => {
        records.forEach(record => {
          let tripId = record.id;
          let session = this.get('session');
          return this.get('ajax').patch(_apiUrls.API.tripManagementService.host + '/booking/' + record.id, {
            method: 'PATCH',
            //return this.get('ajax').delete(ENV.API.avlmBookingService.host+'/tripAssignmentAPI/taapi/booking/'+record.id, {
            //method: 'DELETE',  
            contentType: "application/json",
            dataType: "text",
            headers: {
              "Authorization": `Bearer ${session.data.authenticated.token}`
            },
            data: {
              "data": {
                "type": "booking",
                "id": record.id,
                "attributes": {
                  "status": "cancelled"
                }
              }
            }
          }).then(response => {
            record.set('isForceDirty', false);
            me.get('errorMessage').pushInfo({
              detail: `Trip '${tripId}' successfully cancelled`
            });
            let newtrip = (0, _populateNewTrip.default)(me.get('trip'), me.get('store'));
            (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, true); //me.get('records').pushObject(newtrip);
            //this.set('editModal.editableRecords',[]);
            //this.get('editModal.editableRecords').pushObject(newtrip);

            const newTripComponent = me.get('newTripComponent');
            me.get('editModal').open(newTripComponent, [newtrip], this.get('saveTripRecordFunction').bind(me));
          }).catch(function (error) {
            me.set('editModal.errors', []);

            if (error.payload && error.payload.message) {
              me.get('editModal.errors').pushObject(error.payload.message);
            }

            reject(error);
          });
        });
      });
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        let lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          let records = this.get('editableRecords');
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(({
            record,
            properties
          }) => {
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      async onSearchClick() {
        this.set('editModal.errors', []);
        Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
        await this.get('geocode').activateGeocode(false);
        await this.set('addressRecord', null);
        await this.get('geocode').deactivateGeocode();
        let records = this.get('editableRecords');
        const newtrip = (0, _populateSearchTrip.default)(this.get('trip'), this.get('store'));
        let tripRecord = records.firstObject;
        newtrip.set('passengers.0.phone1.number', (0, _checkValue.default)(tripRecord.get('passengers.0.phone1.number')));
        newtrip.set('stopPoints.0.address.description', (0, _checkValue.default)(tripRecord.get('stopPoints.0.address.description')));
        newtrip.set('stopPoints.0.address.streetNr', (0, _checkValue.default)(tripRecord.get('stopPoints.0.address.streetNr')));
        newtrip.set('stopPoints.0.address.streetName', (0, _checkValue.default)(tripRecord.get('stopPoints.0.address.streetName')));
        newtrip.set('acctCardNr', (0, _checkValue.default)(tripRecord.get('acctCardNr')) === 'CASH' ? '' : tripRecord.get('acctCardNr'));
        newtrip.set('passengers.0.firstName', (0, _checkValue.default)(tripRecord.get('passengers.0.firstName') === 'CASH' ? '' : tripRecord.get('passengers.0.firstName')));
        const searchTripComponent = this.get('searchTripComponent');
        this.get('editModal').open(searchTripComponent, [newtrip], this.get('searchTripRecordFunction'));
      },

      onCloseClick() {
        this.get('geocode').deactivateGeocode();
        this.get('service').close();
        /* if(this.get('activeContext.clearCheckedItemsOnAction')) {
           this.get('activeContext').clearCheckedItems();
           this.get('errorMessage').pushInfo({ });
         }*/
      },

      async onApplyClick() {
        let me = this;
        let records = me.get('editableRecords');

        if (this.validateRecords()) {
          this.set('editmodalcontext.editComponentAction', 'onApplyClick');
          console.log('onApplyClick editComponentAction', this.get('editComponentAction'));
          await this.get('service').apply();
          await this.get('editModal').commit('onApplyClick');
        }
      },

      onCopyClick() {
        let me = this;
        let records = me.get('editableRecords');
        let recordRefresh = [];
        records.forEach(record => {
          let newtrip = (0, _populateCopyTrip.default)(this.get('trip'), record, this.get('store'), this.get('geocode'));
          (0, _populateAccount.default)(newtrip, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), newtrip.acctCardNr, false); //console.log('newtrip ',newtrip);

          recordRefresh.push(newtrip);
        });
        me.set('editableRecords', recordRefresh);
        me.set('records', recordRefresh);
        let newTripComponent = me.get('newTripComponent');
        me.get('editModal').open(newTripComponent, recordRefresh, me.get('saveTripRecordFunction').bind(me));
      },

      onActivitLogs() {
        let me = this;
        let records = this.get('editableRecords');
        let tripRecord = records.firstObject;
        let tripID = tripRecord.get('id');

        if (!Ember.isBlank(tripID)) {
          this.get('store').query('tm-activity-log', {
            filter: {
              tripId: tripID
            }
          }).then(function (activityLogs) {
            Ember.run(() => {
              me.set('editmodalcontext.showWidgetModal', true);
              me.set('editmodalcontext.widgetModelData', activityLogs);
              me.set('editmodalcontext.modelWidgetPath', 'activity-log-widget');
            });
          });
        } else {
          this.get('editModal.errors').pushObject('No Trip ID entered for Activity Logs');
        }
      },

      async onApplyClearClick() {
        let me = this;
        let records = me.get('editableRecords');

        if (this.validateRecords()) {
          this.set('editmodalcontext.editComponentAction', 'onApplyClearClick');
          Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
          await this.get('service').apply();
          await this.get('editModal').commit('onApplyClearClick');
        }
      },

      async onCancelClick() {
        this.set('editModal.errors', []);
        Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
        await this.get('geocode').activateGeocode(false);
        await this.set('addressRecord', null);
        await this.get('geocode').deactivateGeocode();
        let records = this.get('editableRecords');
        let tripRecord = records.firstObject;
        let tripID = tripRecord.get('id');

        if (!Ember.isBlank(tripID)) {
          tripRecord.set('timestamp', new Date()); //this.get('records').pushObject(model);
          //TODO for fix new trip screen
          //this.set('editModal.editableRecords',[]);
          //this.get('editModal.editableRecords').pushObject(model);

          let cancelTripComponent = this.get('cancelTripComponent');
          this.get('editModal').open(cancelTripComponent, [tripRecord], this.get('saveCancelRecordFunction').bind(this));
        } else {
          this.get('editModal.errors').pushObject('No Trip to Cancel');
        }
      },

      async onClearClick() {
        this.set('editModal.errors', []);
        Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
        await this.get('geocode').activateGeocode(false);
        await this.set('addressRecord', null);
        await this.get('geocode').deactivateGeocode();
        let records = this.get('editableRecords');
        let recordRefresh = [];
        records.forEach(() => {
          let newtrip = (0, _populateNewTrip.default)(this.get('trip'), this.get('store'));
          (0, _populateAccount.default)(newtrip, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), newtrip.acctCardNr, true);
          recordRefresh.push(newtrip);
        }); //this.get('lastRecordId').push([]);

        this.set('records', recordRefresh);
        this.set('editableRecords', recordRefresh);
        let newTripComponent = this.get('newTripComponent');

        if (this.get('editModal.editComponent') !== null && this.get('editModal.editComponent') === this.get('newTripComponent')) {
          newTripComponent = this.get('newSwitchTripComponent');
        }

        this.get('editModal').open(newTripComponent, recordRefresh, this.get('saveTripRecordFunction').bind(this));
        /*if(this.get('activeContext.clearCheckedItemsOnAction')) {
          this.get('activeContext').clearCheckedItems();
          this.get('errorMessage').pushInfo({ });
        } */
      }

    }
  });

  _exports.default = _default;
});