define("adept-iq/pods/components/form-components/date/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-datetimeflatpickr'],
    editModal: Ember.inject.service(),
    value: null,
    disabled: false,
    defaultDate: null,
    maxDate: null,
    minDate: null,

    init() {
      this._super(...arguments);

      const value = this.get('value') || '';
      this.set('minDate', (0, _moment.default)().subtract(125, 'years').toDate());
      this.set('value', value);
      this.set('dateFormat', 'm/d/Y');
      this.set('datePlaceholder', 'MM/DD/YYYY');
    },

    actions: {
      onChangeDate(value) {
        this.get('onChange')(value[0]);
      },

      onClose(currentValue, stringValue, datePicker) {
        const currentValueMoment = (0, _moment.default)(currentValue[0]);
        const newValueMoment = (0, _moment.default)(stringValue);

        if (!newValueMoment._isValid && stringValue) {
          this.set('editModal.errors', ['Date entered is invalid.']);
        }

        if (datePicker.config.allowInput && datePicker._input.value && newValueMoment._isValid && !currentValueMoment.isSame(newValueMoment)) {
          datePicker.setDate(newValueMoment.toDate(), true);
        }
      }

    }
  });

  _exports.default = _default;
});