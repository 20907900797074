define("adept-iq/models/sso-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    email: attr('string'),
    userId: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    status: attr('string', {
      defaultValue: 'active'
    }),
    lastLoginAt: attr('date'),
    roleNames: attr('object'),
    calculatedStatus: Ember.computed('status', 'lastLoginAt', {
      get()
      /* key */
      {
        if (this.get('status') === 'active' && this.get('lastLoginAt') === null) {
          return 'pending';
        }

        return this.get('status');
      },

      set(key, value) {
        this.set('status', value);

        if (this.get('status') === 'active' && this.get('lastLoginAt') === null) {
          return 'pending';
        }

        return value;
      }

    })
  });

  _exports.default = _default;
});