define("adept-iq/pods/components/form-components/timeflatpickr/component", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-datetimeflatpickr'],
    value: null,
    disabled: false,
    defaultDate: null,
    maxDate: null,

    init() {
      this._super(...arguments);

      this.set('timeFormat', _environment.default.dateTimeFormat.timeFlatPickr);
      this.set('timePlaceholder', _environment.default.dateTimeFormat.timeMoment);
    },

    actions: {
      onChangeTime() {// required by flatpickr
      },

      onClose(currentValue) {
        let newValue = null;

        if (Ember.isBlank(this.get('value'))) {
          newValue = new Date();
        } else {
          newValue = new Date(this.get('value'));
        } // change the date part only


        newValue.setHours(currentValue[0].getHours());
        newValue.setMinutes(currentValue[0].getMinutes());
        this.get('onChange')(newValue);
      }

    }
  });

  _exports.default = _default;
});