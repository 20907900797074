define("adept-iq/pods/components/iq-widgets/vehicles-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/vehicles-widget/config", "adept-iq/utils/populate-new-text-message", "lodash", "adept-iq/config/environment"], function (_exports, _component, _config, _populateNewTextMessage, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['vehicles-widget'],
    editModal: Ember.inject.service(),
    //workspace: service(),
    editComponent: 'iq-widgets/vehicles-form-widget',
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    vehicleinfo: Ember.inject.service(),
    session: Ember.inject.service(),
    textmessage: Ember.inject.service(),
    config: _config.default,
    defaultIncludes: INCLUDES,

    saveRecordFunction(records) {
      function saveDeleteAllObjects(objects) {
        const deleteObjects = _lodash.default.difference(objects.content.canonicalState, objects.content.currentState); // delete objects that have been removed from the array


        return Promise.all(deleteObjects.map(deleteObject => {
          deleteObject._record.deleteRecord();

          return deleteObject.save();
        })).then(() => {
          return Promise.all(objects.map(object => object.save()));
        });
      }

      function saveLocationObject(object) {
        if (object !== undefined) {
          return Promise.all([object.save()]);
        }

        return Promise.all([]);
      }

      this.set('editModal.errors', []);
      return new Promise((resolve, reject) => {
        records.forEach(record => {
          saveLocationObject(record.startGarages.firstObject).then(() => {
            saveLocationObject(record.endGarages.firstObject).then(() => {
              record.save();
            });
          }).then(() => {
            saveDeleteAllObjects(record.get('availability')).then(() => {
              saveDeleteAllObjects(record.get('shiftBreaks')).then(() => {
                record.set('isForceDirty', false);
              });
            }).catch(e => {
              if (e.errors[0].status === '400' || e.errors[0].status === '409') {
                this.get('editModal.errors').pushObject('Invalid Vehicle Id');
              } else {
                reject(e);
              }
            });
          });
        });
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({});
      this.set('singleActions', [{
        name: 'Send Message',
        action: vehicle => {
          this.sendMessagesToVehicles([vehicle]);
        }
      }]);
      this.set('bulkActions', [{
        name: 'Send Message',
        action: vehicles => {
          this.sendMessagesToVehicles(vehicles);
        }
      }]);
    },

    sendMessagesToVehicles(vehicles) {
      let me = this;
      let draftMessages = vehicles.map(vehicle => {
        let newTestMessage = (0, _populateNewTextMessage.default)(me.get('textmessage'), this.get('store'), vehicle, null, null);
        return newTestMessage;
      });
      this.get('workspace').pushState('addNewMessage', {
        draftMessages
      });
    }

  });

  _exports.default = _default;
});