define("adept-iq/pods/components/form-components/subscriptions/component", ["exports", "moment", "adept-iq/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-subscriptions'],
    editModal: Ember.inject.service(),
    value: null,
    disabled: false,
    dayOfWeeks: null,
    subscriptions: Ember.computed.readOnly('value'),
    recurringPath: Ember.computed.readOnly('extra.recurringPath'),
    availabilityType: Ember.computed.readOnly('valuePath'),
    startTimePath: Ember.computed.readOnly('extra.startTimePath'),
    endTimePath: Ember.computed.readOnly('extra.endTimePath'),
    shiftStartPath: Ember.computed.readOnly('extra.shiftStartPath'),
    shiftEndPath: Ember.computed.readOnly('extra.shiftEndPath'),
    sundayPath: Ember.computed.readOnly('extra.sundayPath'),
    mondayPath: Ember.computed.readOnly('extra.mondayPath'),
    tuesdayPath: Ember.computed.readOnly('extra.tuesdayPath'),
    wednesdayPath: Ember.computed.readOnly('extra.wednesdayPath'),
    thursdayPath: Ember.computed.readOnly('extra.thursdayPath'),
    fridayPath: Ember.computed.readOnly('extra.fridayPath'),
    saturdayPath: Ember.computed.readOnly('extra.saturdayPath'),
    startDatePath: Ember.computed.readOnly('extra.startDateTimePath'),
    endDatePath: Ember.computed.readOnly('extra.endDateTimePath'),
    exStartDatePath: Ember.computed.readOnly('extra.excludeStartDateTimePath'),
    exEndDatePath: Ember.computed.readOnly('extra.excludeEndDateTimePath'),

    init() {
      this._super(...arguments);

      let recurring = this.get(`record.${this.get('valuePath')}.firstObject.${this.get('recurringPath')}`);
      this.set('dayOfWeeks', ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']);
      this.set('dateTimePlaceHolder', _environment.default.dateTimeFlatPickr);
      this.set('recurringOptions', ['yes', 'no']);

      if (!Ember.isNone(recurring) || recurring === 'yes') {
        this.set('recurring', 'yes');
      } else {
        this.set('recurring', 'no');
      }

      this.set('frequencyOptions', ['daily', 'weekly', 'montly']);
      this.set('frequency', 'daily');
      this.set('dailyDaysOptions', ['Every Day', 'Every WeekDay']);
      this.set('dailyDays', 'Every Day');
      this.set('weekOfMonthOptions', ['first', 'second', 'Third', 'fourth', 'last']);
      this.set('dayOfWeekOfMonthOptions', ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']);
      this.set('datePlaceHolder', _environment.default.dateTimeFormat.dateMoment);
      this.set('timePlaceHolder', _environment.default.dateTimeFormat.timeMoment);
      this.set('dateFormat', _environment.default.dateTimeFormat.dateFlatPickr); //    this.set('monthFrequency', ['sunday','monday','tuesday','wednesday','thursday','friday','saturday']);
    },

    actions: {
      onInput(record, valuePath, value) {
        let options = {
          record,
          valuePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(value, options);
      },

      onChangeDate(record, valuePath, value) {
        let options = {
          record,
          valuePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(value[0], options);
      },

      onClose(currentValue, stringValue, datePicker) {
        const currentValueMoment = (0, _moment.default)(currentValue[0]);
        const newValueMoment = (0, _moment.default)(stringValue);

        if (!newValueMoment._isValid) {
          // $(`#${datePicker.element.id}`).focus();
          this.set('editModal.errors', ['Date entered is invalid.']);
        }

        if (datePicker.config.allowInput && datePicker._input.value && newValueMoment._isValid && !currentValueMoment.isSame(newValueMoment)) {
          // date format is for database so should not be from config
          datePicker.setDate(newValueMoment.format('YYYY-MM-DD'), true);
        } // $(datePicker.altInput.className).select();
        // $(datePicker.element).select();
        // $('.effective-start').select();

      },

      onChangeTime(record, valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        let options = {
          record,
          valuePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(momentTime.format('HH:mm:ss'), options);
      },

      onCheckboxChange(record, valuePath) {
        let switchState = record.get(valuePath);
        let options = {
          record,
          valuePath
        };
        switchState = !switchState;
        this.set('record.isForceDirty', true);
        this.get('onChange')(switchState, options);
      },

      onDOWChange(record, values) {
        record.set('sunday', values.includes('sunday'));
        record.set('monday', values.includes('monday'));
        record.set('tuesday', values.includes('tuesday'));
        record.set('wednesday', values.includes('wednesday'));
        record.set('thursday', values.includes('thursday'));
        record.set('friday', values.includes('friday'));
        record.set('saturday', values.includes('saturday'));
      },

      onAddNewAvailability() {
        this.get('onCreate')();
      },

      onRecurringChange(record, value) {
        record.set('recurring', value);
        this.set('recurring', value);
      },

      onFrequencyChange(record, value) {
        record.set('frequency', value);
        this.set('frequency', value);
      },

      onRemoveAvailability(record, valuePath, value) {
        let options = {
          record,
          valuePath
        };
        this.get('onRemove')(value, options);
      },

      onDailyDaysChange(record, value) {
        record.set('dailyDays', value);
      },

      onDailyOptionsChange(record, value) {
        record.set('dailyDays', value);
      },

      onWOMChange(record, value) {
        record.set('dailyDays', value);
      },

      onDOWOMChange(record, value) {
        record.set('dailyDays', value);
      }

    }
  });

  _exports.default = _default;
});