define("adept-iq/models/driver-break", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    hasMany,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    driverBreakId: attr('number'),
    externalId: attr('string'),
    state: attr('string'),
    ordinal: attr('number'),
    promisedStart: attr('date'),
    estimatedStart: attr('date'),
    estimatedEnd: attr('date'),
    plannedDuration: attr('number'),
    notes: attr('string', {
      defaultValue: ''
    }),
    driverBreakEvents: hasMany(),
    breakType: belongsTo(),
    place: belongsTo(),
    route: belongsTo(),
    schedule: belongsTo(),
    actualArrivalTime: attr('date'),
    actualDepartTime: attr('date'),
    odometer: attr('number'),
    plannedDepartTime: attr('date'),
    serviceWindow: attr('string'),
    breakStartTime: attr('date'),
    breakEndTime: attr('date'),
    BreakType: attr('string'),
    BreakCategory: attr('string'),
    eventtype: 'driver-break',
    promisedEnd: attr('date'),
    places: Ember.computed('place', function () {
      return [this.get('place')];
    })
  });

  _exports.default = _default;
});