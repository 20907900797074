define("adept-iq/serializers/tm-booking", ["exports", "adept-iq/serializers/-tm-tripManagement"], function (_exports, _tmTripManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tmTripManagement.default.extend({
    serialize()
    /* snapshot, options */
    {
      let json = this._super(...arguments);

      console.log('booking json ', json);

      try {
        if (json.data.attributes.source.name) {
          delete json.data.attributes.source.name;
        }
      } catch (e) {}

      try {
        if (json.data.attributes.source.operator) {
          delete json.data.attributes.source.operator;
        }
      } catch (e) {}

      try {
        delete json.data.attributes.fixedAmount;
      } catch (e) {//console.log(e);
      }

      try {
        delete json.data.attributes.status;
      } catch (e) {//console.log(e);
      }

      try {
        delete json.data.attributes.dispatchType;
      } catch (e) {//console.log(e);
      }

      try {
        delete json.data.attributes.tripType;
      } catch (e) {//console.log(e);
      }

      try {
        delete json.data.attributes.routeExec;
      } catch (e) {}

      try {
        json.data.attributes.stopPoints[0].address.formattedAddress = json.data.attributes.stopPoints[0].address.tomtomFormattedAddress.address.freeformAddress;
        delete json.data.attributes.stopPoints[0].address.tomtomFormattedAddress;
      } catch (e) {//console.log(e);
      }

      try {
        json.data.attributes.stopPoints[1].address.formattedAddress = json.data.attributes.stopPoints[1].address.tomtomFormattedAddress.address.freeformAddress;
        delete json.data.attributes.stopPoints[1].address.tomtomFormattedAddress;
      } catch (e) {//console.log(e);
      }

      try {
        delete json.data.attributes.acctCardNr;
      } catch (e) {}

      try {
        delete json.data.attributes.driverNotes;
      } catch (e) {}

      try {
        delete json.data.attributes.operatorNotes;
      } catch (e) {}

      try {
        json.data.attributes.price.payerIdentifier = json.data.attributes.account.attributes.account.accountId;

        if (Ember.isBlank(json.data.attributes.price.passengerId)) {
          delete json.data.attributes.price.passengerId;
        }
      } catch (e) {//console.log(e);
      }

      try {
        json.data.attributes.prices[0].payerIdentifier = json.data.attributes.account.attributes.account.accountId;
        delete json.data.attributes.prices[0].passengerId;
      } catch (e) {//console.log(e);
      }

      try {
        delete json.data.attributes.account;
      } catch (e) {}

      try {
        delete json.data.attributes.manualAttributes;
      } catch (e) {} //If no data to save than Delete object
      // Remove helper data


      try {
        if (Ember.isBlank(json.data.attributes.passengers[0].phone1.number)) {
          delete json.data.attributes.passengers[0].phone1;
        }
      } catch (ex) {}
      /* try {
          delete json.data.attributes.passengers[0].callout;
        } catch (e) {
         }*/


      try {
        if (Ember.isBlank(json.data.attributes.passengers[0].phone2.number)) {
          delete json.data.attributes.passengers[0].phone2;
        }
      } catch (ex) {}

      try {
        delete json.data.attributes.stopPoints[0].address.coord.geoNode;
        delete json.data.attributes.stopPoints[1].address.coord.geoNode;
      } catch (ex) {}

      try {
        delete json.data.attributes.stopPoints[0].address.geoNode;
        delete json.data.attributes.stopPoints[1].address.geoNode; //TODO - for Booking to Taxibook (Temporary)

        delete json.data.attributes.stopPoints[1].passengerEvents[0].time;
        /*if(isBlank(json.data.attributes.stopPoints[1].address.formattedAddress)) {
          delete json.data.attributes.stopPoints[1];
        }*/
      } catch (ex) {}

      try {
        delete json.data.attributes.subscriptions;
      } catch (e) {}

      try {
        delete json.data.attributes.editmodelcontext;
      } catch (ex) {}

      return json;
    },

    normalizeResponse: function (store, primaryModelClass, payload) {
      console.log('normalizeResponse payload', payload);
      const data = Ember.makeArray(payload.data);
      console.log('normalizeResponse data ', data);

      for (let i = 0; i < data.length; i++) {
        data[i].type = 'tm-booking';
      }

      return payload;
    }
  });

  _exports.default = _default;
});