define("adept-iq/pods/components/iq-widgets/map-widget/decorations/arrow", ["exports", "tomtom"], function (_exports, _tomtom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function toRadians(angle) {
    return angle / 180 * Math.PI;
  }

  function toDegrees(angle) {
    return angle / Math.PI * 180;
  } // SOURCE: https://www.igismap.com/formula-to-find-bearing-or-heading-angle-between-two-points-latitude-longitude/


  function getBearing(points) {
    const X = Math.cos(toRadians(points[1][0])) * Math.sin(toRadians(points[1][1] - points[0][1]));
    const Y = Math.cos(toRadians(points[0][0])) * Math.sin(toRadians(points[1][0])) - Math.sin(toRadians(points[0][0])) * Math.cos(toRadians(points[1][0])) * Math.cos(toRadians(points[1][1] - points[0][1]));
    const bearing = toDegrees(Math.atan2(X, Y));
    return bearing;
  }

  function _default(points, arrowOptions) {
    const arrow = _tomtom.default.L.marker(points[1], arrowOptions); // relies on `vendor/leaflet/leaflet.rotatedMarker.js`


    arrow.setRotationAngle(getBearing(points) - 90);
    return arrow;
  }
});