define("adept-iq/pods/components/iq-widgets/edit-settings-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/edit-settings-widget/config", "lodash"], function (_exports, _component, _config, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['edit-settings-widget'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    config: _config.default,
    defaultIncludes: INCLUDES,
    // old-school modal
    editModal: Ember.inject.service(),
    editComponent: 'iq-widgets/edit-settings-form-widget',
    systemEditComponent: 'iq-widgets/system-config-form-widget',

    // TODO: saveRecordFunction shall be moved to a service later on
    // This save is specific to saving drivers and all related models.
    saveRecordFunction(records) {
      function saveDeleteAllObjects(objects) {
        const deleteObjects = _lodash.default.difference(objects.content.canonicalState, objects.content.currentState); // delete objects that have been removed from the array


        return Promise.all(deleteObjects.map(deleteObject => {
          deleteObject._record.deleteRecord();

          return deleteObject.save();
        })).then(() => {
          return Promise.all(objects.map(object => object.save()));
        });
      }

      this.set('editModal.errors', []);
      return new Promise((resolve, reject) => {
        records.forEach(record => {
          record.save().then(() => {
            saveDeleteAllObjects(record.get('availability')).then(() => {
              record.set('isForceDirty', false);
            });
          }).catch(e => {
            if (e.errors[0].status === '400' || e.errors[0].status === '409') {
              this.get('editModal.errors').pushObject('Invalid Setting Id');
            } else {
              reject(e);
            }
          });
        });
      });
    },

    fetchDataQuery() {
      return this.get('data');
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('singleActions', [{
        name: 'Edit',
        action: model => {
          let editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, [model], this.get('saveRecordFunction').bind(this));
        }
      }]);
      this.set('bulkActions', [{
        name: 'Activity Log',
        action: () =>
        /* model */
        {}
      }]);
    },

    data: Ember.computed('', function () {
      const values = [
      /* {type: 'System Configuration', description: 'Configurations for multiple system types', subsections: true }, */
      {
        type: 'Driver Mobile App Configurations',
        description: 'Configure driver application',
        subsections: true
      }, {
        type: 'Passenger Mobile App Configurations',
        description: 'Configure passenger application',
        subsections: true
      }, {
        type: 'Trip Configurations',
        description: 'Configure trip rules',
        subsections: true
      }
      /* {type: 'Travel Time Parameters', description: 'this is a description',  subsections: true},
      {type: 'Map', description: 'this is a description',  subsections: true},
      {type: 'Scheduling', description: 'this is a description',  subsections: true},
      {type: 'URLs & Addresses', description: 'this is a description'},
      {type: 'ETA Calculation', description: 'this is a description'},
      {type: 'Driver App License', description: 'this is a description'},
      {type: 'Fixed Route Engine Parameters', description: 'this is a description'},
      {type: 'Driving Configuration', description: 'this is a description'},
      {type: 'Scheduling Parameters', description: 'this is a description'} */
      ];
      let store = this.get('store');
      return values.map((m, i) => {
        values[i].id = i;
        return store.peekRecord('system-config-type', `${i}`) || store.createRecord('system-config-type', values[i]);
      });
    })
  });

  _exports.default = _default;
});