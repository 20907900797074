define("adept-iq/pods/components/form-components/datetimeflatpickr/component", ["exports", "moment", "adept-iq/config/environment", "ember-keyboard-shortcuts"], function (_exports, _moment, _environment, _emberKeyboardShortcuts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-datetimeflatpickr'],
    editModal: Ember.inject.service(),
    value: null,
    valueFormatted: null,
    disabled: false,
    defaultDate: null,
    maxDate: null,
    minDate: null,

    init() {
      this._super(...arguments);

      const value = this.get('value') || (0, _moment.default)().toDate();
      this.set('minDate', (0, _moment.default)(this.get('value')).startOf('day').toDate());
      this.set('valueFormatted', value);
      this.set('dateFormat', _environment.default.dateTimeFormat.dateTimeFlatPickr);
      this.set('datePlaceholder', _environment.default.dateTimeFormat.dateTimeMoment);
      const altaccesskey = 'alt+' + this.get('accessKey');

      if (!Ember.isBlank(this.get('accessKey'))) {
        const accessKeyConfig = {
          [altaccesskey]: {
            action: 'handleFocus',
            // action to trigger
            global: true,
            preventDefault: true
          }
        };
        this.set('keyboardShortcuts', accessKeyConfig);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    },

    actions: {
      onChangeDate(value) {
        this.set('record.isForceDirty', true);
        this.set(`record.${this.get('valuePath')}`, value[0]);
      },

      onClose(currentValue, stringValue, datePicker) {
        const currentValueMoment = (0, _moment.default)(currentValue[0]);
        const newValueMoment = (0, _moment.default)(stringValue);

        if (!newValueMoment._isValid) {
          // $(`#${datePicker.element.id}`).focus();
          this.set('editModal.errors', ['Date entered is invalid.']);
        }

        if (datePicker.config.allowInput && datePicker._input.value && newValueMoment._isValid && !currentValueMoment.isSame(newValueMoment)) {
          datePicker.setDate(newValueMoment.toDate(), true);
        } // $(datePicker.altInput.className).select();
        // $(datePicker.element).select();
        // $('.effective-start').select();

      },

      onOpen() {
        Ember.$('.numInputWrapper').focus();
      },

      handleFocus() {
        //console.log('flatpickr-input',$('.flatpickr-input'));
        if (!Ember.isBlank(this.get('accessKey'))) {
          Ember.$('.flatpickr-input').focus();
          /*this.input = document.querySelector(`.flatpickr-input`);
          if (this.input) {
                  run.next(this.input, 'focus');
          }*/
        }
      }

    }
  });

  _exports.default = _default;
});