define("adept-iq/topic-transforms/message", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;
  const CANNED_RESPONSE_TYPES = {
    'X': 'None',
    'A': 'Acknowledge',
    'Y': 'Yes/No'
  };

  function transform(store, payload
  /*, logger*/
  ) {
    payload.data.type = 'avlm-canned-message';
    const transformedPayloads = [];

    if (!_environment.default.APP.avlmLite && payload.data.attributes.priority === 'E') {
      payload.data.type = 'avlm-emergency-message';
    } // Because we receive included payloads with an empty 'id', we need to empty
    // the included array and only push valid payloads


    const avlmDriver = payload.included.filterBy('type', 'driver');
    const avlmRoute = payload.included.filterBy('type', 'route');
    const avlmVehicle = payload.included.filterBy('type', 'vehicle'); // Set the included array to empty

    payload.included = []; // Determine if each exists and has an id

    if (!avlmRoute[0] || avlmRoute[0].id === '') {
      delete payload.data.relationships.route;
    } else {
      // create a dummy iq-route record to have an earlier association to iq-routes
      const iqPayload = {
        data: {
          id: avlmRoute[0].id,
          type: 'iqRoute'
        }
      };
      transformedPayloads.push(iqPayload);
      payload.included.push(avlmRoute[0]);
    }

    if (!avlmDriver[0] || avlmDriver[0].id === '') {
      delete payload.data.relationships.driver;
    } else {
      // create a dummy iq-driver record to have an earlier association to iq-driver
      const iqPayload = {
        data: {
          id: avlmDriver[0].id,
          type: 'iqDriver'
        }
      };
      transformedPayloads.push(iqPayload);
      payload.included.push(avlmDriver[0]);
    }

    if (!avlmVehicle[0] || avlmVehicle[0].id === '') {
      delete payload.data.relationships.vehicle;
    } else {
      // create a dummy vehicle record to have an earlier association to vehicle
      const iqPayload = {
        data: {
          id: avlmVehicle[0].id,
          type: 'iqVehicle'
        }
      };
      transformedPayloads.push(iqPayload);
      payload.included.push(avlmVehicle[0]);
    }

    if (avlmDriver.length !== 0) {
      payload.data.attributes.driver = {
        firstName: avlmDriver[0].attributes.firstName,
        id: avlmDriver[0].id,
        lastName: avlmDriver[0].attributes.lastName
      };
    }

    payload.data.attributes.responseTypeText = CANNED_RESPONSE_TYPES[payload.data.attributes.responseType];
    transformedPayloads.push(payload);
    return transformedPayloads;
  }
});