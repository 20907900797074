define("adept-iq/transforms/nested", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Ember from 'ember';
  const {
    Transform
  } = _emberData.default; // function copy(source) {
  //     let keys = Object.keys(source);
  //     let dest = {};
  //     for(let i = 0; i < keys.length; i++) {
  //         if(keys[i].charAt(0) !== '_') {
  //             dest[keys[i]] = source[keys[i]];
  //         }
  //     }
  //     return dest;
  // }

  var _default = Transform.extend({
    deserialize: function (serialized) {
      return serialized;
    },
    serialize: function (deserialized) {
      return deserialized;
    }
  });

  _exports.default = _default;
});