define("adept-iq/models/tm-address", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/address.js */
  var _default = _model.default.extend({
    formattedAddress: (0, _attr.default)('string'),
    streetName: (0, _attr.default)('string'),
    streetNr: (0, _attr.default)('string'),
    unitNr: (0, _attr.default)('string'),
    region: (0, _attr.default)('string'),
    postCode: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    country: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    coord: (0, _relationships.belongsTo)('tm-coord'),
    addressType: (0, _attr.default)('string'),
    latlng: Ember.computed('coord', function () {
      if (this.get('coord.lat') !== undefined && this.get('coord.lng') !== undefined) {
        return `${this.get('coord.lat')}/${this.get('coord.lng')}`;
      }

      return '';
    }),
    tomtomFormattedAddress: Ember.computed('streetNr', 'streetName', 'region', function () {
      if (this.get('streetName') !== undefined) {
        return {
          address: {
            freeformAddress: `${this.get('streetNr')} ${this.get('streetName')} ${this.get('region')}`
          }
        };
      }

      return null;
    }),
    fullAddress: Ember.computed('address', 'pointOfInterest', function () {
      const alias = Ember.get(this, 'pointOfInterest.alias');
      const address = Ember.get(this, 'address');
      const streetAddress = [];

      if (address) {
        if (alias) {
          streetAddress.push(alias + ',');
        }

        streetAddress.push(address.streetNr);
        streetAddress.push(address.streetName);
        const result = [streetAddress.join(' ')];
        result.push(address.regions); // result.push(address.county);

        result.push(address.stateAbbreviation);
        result.push(address.postalCode);
        return result.join(', ');
      }

      return '';
    }).readOnly(),
    streetAddress: Ember.computed('streetNr', 'streetName', function () {
      const streetNr = this.get('streetNr');
      const streetName = this.get('streetName');
      return `${streetNr} ${streetName}`;
    })
  });

  _exports.default = _default;
});