define("adept-iq/utils/populate-copy-trip", ["exports", "adept-iq/utils/check-value", "adept-iq/utils/check-address-flags", "lodash", "moment"], function (_exports, _checkValue, _checkAddressFlags, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = populateCopyTrip;

  function populateCopyTripData(tripTemplate, updatetrip, store, geocode) {
    let tripData = tripTemplate.tripData().data.attributes;
    let copytrip = store.createRecord('tm-booking', {
      passengers: updatetrip.passengers,
      prices: updatetrip.prices,
      relatedIds: updatetrip.relatedIds,
      requestedProvider: updatetrip.requestedProvider,
      source: tripData.source,
      status: updatetrip.status,
      stopPoints: updatetrip.stopPoints,
      trackingId: updatetrip.trackingId
    }); //Helper UI Model

    copytrip.set('editmodelcontext', {
      callerTrips: [],
      assigns: [],
      swapAddressDisabled: true,
      saveCLIDisabled: true,
      driverNotes: '',
      operatorNotes: '',
      attributes: {
        account: [],
        cli: [],
        address: [],
        area: []
      },
      predefinedDriverNotes: {
        account: '',
        cli: '',
        address: '',
        area: '',
        building: '',
        attribute: '',
        unit: '',
        editTrip: ''
      },
      predefinedOperatorNotes: {
        account: '',
        cli: '',
        address: '',
        area: '',
        building: '',
        attribute: '',
        unit: '',
        editTrip: ''
      },
      editAttributes: {
        account: [],
        cli: [],
        building: [],
        area: [],
        region: [],
        editTrip: []
      }
    }); //passgenger

    if (Ember.isBlank(updatetrip.passengers[0].phone1)) {
      try {
        Ember.set(updatetrip.passengers[0], 'phone1', tripData.passengers[0].phone1);
      } catch (e) {
        updatetrip.passengers[0].phone1 = tripData.passengers[0].phone1;
      }
    } //Attributes


    let options = store.peekAll('tm-common-operational-attribute');
    let requiredAttributes = updatetrip.passengers[0].requiredAttributes;
    let assigns = options.filter(function (o1) {
      // if match found return false
      return _lodash.default.findIndex(requiredAttributes, {
        'type': o1.displayName
      }) === -1 ? false : true;
    });

    if (!Ember.isEmpty(requiredAttributes) && Ember.isEmpty(assigns)) {
      assigns = options.filter(function (o1) {
        // if match found return false
        return _lodash.default.findIndex(requiredAttributes, {
          'type': o1.type
        }) === -1 ? false : true;
      });
    }

    copytrip.set('manualAttributes', assigns); //Notes

    try {
      if (updatetrip.stopPoints[0].passengerEvents[0].driverNote && updatetrip.stopPoints[0].passengerEvents[0].driverNote.indexOf('\\') === -1 && updatetrip.get('predefinedNotes.driverNotes') !== updatetrip.stopPoints[0].passengerEvents[0].driverNote) {
        copytrip.set('driverNotes', updatetrip.stopPoints[0].passengerEvents[0].driverNote);
      }

      if (updatetrip.stopPoints[0].passengerEvents[0].driverNote && updatetrip.stopPoints[0].passengerEvents[0].driverNote.indexOf('<>') !== -1) {
        copytrip.set('driverNotes', updatetrip.stopPoints[0].passengerEvents[0].driverNote.substr(0, updatetrip.stopPoints[0].passengerEvents[0].driverNote.indexOf('<>')));
        copytrip.set('editmodelcontext.predefinedDriverNotes.editTrip', updatetrip.stopPoints[0].passengerEvents[0].driverNote.substr(updatetrip.stopPoints[0].passengerEvents[0].driverNote.indexOf('<>')));
      }
    } catch (e) {}

    try {
      if (updatetrip.passengers[0].bookingNote && updatetrip.passengers[0].bookingNote.indexOf('\\') === -1 && updatetrip.get('predefinedNotes.operatorNotes') !== updatetrip.passengers[0].bookingNote) {
        copytrip.set('operatorNotes', updatetrip.passengers[0].bookingNote);
      }

      if (updatetrip.passengers[0].bookingNote && updatetrip.passengers[0].bookingNote.indexOf('<>') !== -1) {
        copytrip.set('operatorNotes', updatetrip.passengers[0].bookingNote.substr(0, updatetrip.passengers[0].bookingNote.indexOf('<>')));
        copytrip.set('editmodelcontext.predefinedOperatorNotes.editTrip', updatetrip.passengers[0].bookingNote.substr(updatetrip.passengers[0].bookingNote.indexOf('<>')));
      }
    } catch (e) {} //Account


    let defaultCardNr = 'CASH';
    let PFCP_GENERAL_CASH_ACCOUNT_NAME = store.peekAll('tm-config-parameter-value').filterBy('configParameter.id', 'PFCP_GENERAL_CASH_ACCOUNT_NAME');

    if (!Ember.isBlank(PFCP_GENERAL_CASH_ACCOUNT_NAME)) {
      defaultCardNr = PFCP_GENERAL_CASH_ACCOUNT_NAME[0].get('value');
    }

    try {
      if (!Ember.isBlank(updatetrip.prices) && !Ember.isBlank(updatetrip.prices[0])) {
        defaultCardNr = updatetrip.prices[0].payerIdentifier;
      } else {
        defaultCardNr = updatetrip.price.payerIdentifier;
      }
    } catch (e) {}

    copytrip.set('acctCardNr', defaultCardNr);
    let fixedAmount;

    try {
      if (!Ember.isBlank(updatetrip.prices) && !Ember.isBlank(updatetrip.prices[0])) {
        defaultCardNr = updatetrip.prices[0].fixed;
      } else {
        defaultCardNr = updatetrip.price.fixed;
      }
    } catch (e) {
      console.log(e);
    }

    if (!Ember.isBlank(fixedAmount)) {
      copytrip.set('fixedAmount', fixedAmount);
    } // Last trip Id


    updatetrip.set('lastId', updatetrip.get('id')); //Stoppoints means Trip Details

    try {
      try {
        let currentUTC = _moment.default.utc().format();

        if ((0, _moment.default)(currentUTC).isAfter(updatetrip.stopPoints[0].passengerEvents[0].time)) {
          Ember.set(copytrip.stopPoints[0].passengerEvents[0], 'time', new Date());
        } else {
          Ember.set(copytrip.stopPoints[0].passengerEvents[0], 'time', updatetrip.stopPoints[0].passengerEvents[0].time);
        }
      } catch (e) {
        let currentUTC = _moment.default.utc().format();

        if ((0, _moment.default)(currentUTC).isAfter(updatetrip.stopPoints[0].passengerEvents[0].time)) {
          copytrip.stopPoints[0].passengerEvents[0].time = updatetrip.stopPoints[0].passengerEvents[0].time;
        } else {
          Ember.set(copytrip.stopPoints[0].passengerEvents[0], 'time', new Date());
        }
      }
    } catch (e) {}

    for (let counter = 0; counter < copytrip.stopPoints.length; counter++) {
      try {
        Ember.set(copytrip.stopPoints[counter].address.tomtomFormattedAddress.address, 'freeformAddress', (0, _checkValue.default)(updatetrip.stopPoints[counter].address.streetNr) + " " + (0, _checkValue.default)(updatetrip.stopPoints[counter].address.streetName) + " " + (0, _checkValue.default)(updatetrip.stopPoints[counter].address.region));
      } catch (e) {
        copytrip.stopPoints[counter].address.tomtomFormattedAddress = {
          address: {
            freeformAddress: (0, _checkValue.default)(updatetrip.stopPoints[counter].address.streetNr) + " " + (0, _checkValue.default)(updatetrip.stopPoints[counter].address.streetName) + " " + (0, _checkValue.default)(updatetrip.stopPoints[counter].address.region)
          }
        };

        if (!Ember.isBlank(copytrip.stopPoints[counter].address) && !Ember.isBlank(copytrip.stopPoints[counter].address.coord) && copytrip.stopPoints[counter].address.coord.lat !== 0 && copytrip.stopPoints[counter].address.coord.lng !== 0 && copytrip.stopPoints[counter].address.coord.lat !== 181 && copytrip.stopPoints[counter].address.coord.lng !== 181 && copytrip.stopPoints[counter].address.coord.lat !== -181 && copytrip.stopPoints[counter].address.coord.lng !== -181 && !Ember.isBlank(copytrip.stopPoints[counter].address.coord.lat) && !Ember.isBlank(copytrip.stopPoints[counter].address.coord.lng)) {
          try {
            geocode.getGeonode(copytrip.stopPoints[counter].address.coord.lat, copytrip.stopPoints[counter].address.coord.lng).then(result => {
              const zoneData = store.peekRecord('zone', result.data.gpsAreaId);
              Ember.set(copytrip.stopPoints[counter].address, 'geoNode', zoneData.get('name')); //Ember.set(copytrip.stopPoints[counter].address, 'zoneId', result.data.gpsAreaId);
            });
          } catch (e) {
            console.log(e);
          }
        }
        /*try {
          Ember.set(copytrip.stopPoints[counter].address, 'formattedAddress', checkValue(updatetrip.stopPoints[counter].address.streetNr) + " "+  checkValue(updatetrip.stopPoints[counter].address.streetName)  + " " +    checkValue(updatetrip.stopPoints[counter].address.region));
        } catch(e)   {
          copytrip.stopPoints[counter].address.formattedAddress = checkValue(updatetrip.stopPoints[counter].address.streetNr) + " "+  checkValue(updatetrip.stopPoints[counter].address.streetName)  + " " +    checkValue(updatetrip.stopPoints[counter].address.region)
        }*/

      }

      (0, _checkAddressFlags.default)(copytrip);
    }

    console.log('copytrip ', copytrip); //let subscriptions = this.createSubscriptionRecord1();
    //trip.set('subscriptions',subscriptions);

    return copytrip;
  }

  function populateCopyTrip(tripTemplate, trip, store, geocode) {
    return populateCopyTripData(tripTemplate, trip, store, geocode);
  }
});