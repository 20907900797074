define("adept-iq/classes/dashboard-instance", ["exports", "lodash", "adept-iq/classes/widget", "md5", "moment"], function (_exports, _lodash, _widget, _md, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SCHEMA_VERSION = 1;
  const DashboardInstance = Ember.Object.extend({
    id: null,
    name: null,
    model: null,
    // underlying ember-data model
    startDate: null,
    endDate: null,
    referenceDate: null,
    isDirty: false,
    // TODO: implement
    _mapState: null,
    // POJO of map state
    _widgets: null,
    // array of Widget objects
    _widgetsSavepoint: null,
    // for rollback
    _widgetCache: null,
    // re-use widget objects if possible
    mapState: Ember.computed('_mapState', {
      get() {
        let mapState = this.get('_mapState');

        if (!mapState) {
          mapState = {};
          /*eslint-disable */

          this.set('_mapState', mapState);
          /*eslint-enable */
        }

        return mapState;
      },

      set(key, value) {
        if (!value) {
          /*eslint-disable */
          value = {};
          /*eslint-enable */
        }

        this.set('_mapState', value);
        return value;
      }

    }),
    widgets: Ember.computed('_widgets', {
      get() {
        let widgets = this.get('_widgets');

        if (!widgets) {
          widgets = [];
          /*eslint-disable */

          this.set('_widgets', widgets);
          /*eslint-enable */
        }

        return widgets;
      },

      set(key, value) {
        if (!value) {
          /*eslint-disable */
          value = [];
          /*eslint-enable */
        }

        this.set('_widgets', value);
        return value;
      }

    }),
    height: Ember.computed('widgets.@each.{y,height}', function () {
      return this.get('widgets').reduce((max, widget) => {
        const y = widget.get('y');
        const height = widget.get('height');
        return Math.max(max, y + height);
      }, 0);
    }),
    width: Ember.computed('widgets.@each.{x,width}', function () {
      return this.get('widgets').reduce((max, widget) => {
        const x = widget.get('x');
        const width = widget.get('width');
        return Math.max(max, x + width);
      }, 0);
    }),

    // TODO: this should live in map widget state
    mergeMapState(changes = {}) {
      const mapState = this.get('mapState');

      const mergedState = _lodash.default.merge({}, mapState, changes);

      this.set('mapState', mergedState); // TODO: check if this is necessary

      this.notifyPropertyChange('mapState');
      this.set('hasDirtyWidgets', true);
    },

    makeWidgetsSavepoint() {
      const widgetsSavepoint = this.get('widgets').map(widget => widget.serialize());
      this.set('_widgetsSavepoint', widgetsSavepoint);
    },

    rollbackWidgets() {
      const widgetsSavepoint = this.get('_widgetsSavepoint');

      if (!widgetsSavepoint) {
        /*eslint-disable */
        throw 'no dashboard savepoint; must call makeWidgetsSavepoint() first';
        /*eslint-enable */
      }

      this.get('widgets').clear();
      widgetsSavepoint.forEach(({
        attributes
      }) => {
        this.addWidget(attributes);
      });
    },

    addWidget(options) {
      const widget = this.makeWidget(options);
      this.get('widgets').pushObject(widget);
    },

    removeWidget(widget) {
      this.get('widgets').removeObject(widget);
    },

    clearDashboard() {
      this.get('widgets').clear();
    },

    makeWidget(options) {
      const {
        id
      } = options;
      let widgetCache = this.get('_widgetCache');

      if (!widgetCache) {
        widgetCache = {};
        this.set('_widgetCache', widgetCache);
      }

      if (Ember.isPresent(id)) {
        const currWidget = widgetCache[id];

        if (currWidget) {
          currWidget.setProperties(options);
          return currWidget;
        }
      }

      const extendedOptions = Object.assign({}, options, {
        dashboard: this
      });

      const widget = _widget.default.create(extendedOptions);

      widgetCache[widget.get('id')] = widget;
      return widget;
    },

    serialize() {
      const attributes = {
        id: this.get('id'),
        name: this.get('name'),
        role: this.get('role'),
        startDate: this.get('startDate').toISOString(),
        //moment(Date.now()).subtract(3, 'hours'),
        endDate: this.get('endDate').toISOString(),
        referenceDate: this.get('referenceDate').toISOString(),
        widgets: this.get('widgets').map(widget => widget.serialize().attributes),
        mapState: _lodash.default.cloneDeep(this.get('mapState'))
      };
      const serialized = {
        schemaVersion: SCHEMA_VERSION,
        timestamp: new Date().getTime(),
        hash: (0, _md.default)(JSON.stringify(attributes)),
        attributes
      };
      return serialized;
    }

  });
  DashboardInstance.reopenClass({
    deserialize(serialized) {
      // for legacy, assume missing schema version means 1
      const {
        schemaVersion = 1
      } = serialized;

      if (schemaVersion !== 1) {
        /*eslint-disable */
        throw 'unknown schema version';
        /*eslint-enable */
      }

      const {
        id,
        name,
        role,
        startDate,
        endDate,
        referenceDate,
        mapState,
        widgets
      } = serialized.attributes;
      const dashboardInstance = DashboardInstance.create({
        id,
        name,
        role,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        referenceDate: new Date(referenceDate),
        mapState: _lodash.default.cloneDeep(mapState)
      });
      dashboardInstance.set('widgets', widgets.map(attributes => {
        const widget = _widget.default.deserialize({
          schemaVersion,
          attributes
        });

        widget.set('dashboard', dashboardInstance);
        return widget;
      }));
      return dashboardInstance;
    }

  });
  var _default = DashboardInstance;
  _exports.default = _default;
});