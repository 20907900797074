define("adept-iq/pods/components/iq-widgets/rider-profile-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/call-line-widget',
    rowComponent: 'table/rows/otp-formatted-row',
    modelName: 'rider-profile',
    defaultSortId: 'account.accountId',
    defaultSortAsc: false,
    hideWidgetControls: true,
    hideHeader: true,
    checkBoxColumn: false,
    isFormControls: true,
    title: 'CLI Address Details',
    columns: [{
      id: 'contactFirstName',
      type: 'text',
      label: 'Client Name',
      valuePath: 'name',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'accountNr',
      type: 'text',
      label: 'Account No',
      valuePath: 'account.accountId',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'pickupFreeFormAddress',
      type: 'text',
      label: 'Pickup Address',
      valuePath: 'pickupFreeFormAddress',
      editable: false,
      hidden: false,
      defaultWidth: 200
    }, {
      id: 'pickupCity',
      type: 'text',
      label: 'City',
      valuePath: 'pickupCity',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'dropoffFreeFormAddress',
      type: 'text',
      label: 'Drop Off Address',
      valuePath: 'dropoffFreeFormAddress',
      editable: false,
      hidden: false,
      defaultWidth: 200
    }, {
      id: 'dropoffCity',
      type: 'text',
      label: 'City',
      valuePath: 'dropoffCity',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100
    }]
  };
  _exports.default = _default;
});