define("adept-iq/pods/components/side-drawer/trip-activity-log/component", ["exports", "ember-concurrency", "ember-light-table", "adept-iq/pods/components/side-drawer/trip-activity-log/config"], function (_exports, _emberConcurrency, _emberLightTable, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    columns: _config.columns,
    trip: Ember.computed.readOnly('workspace.topOptions.trip'),
    title: 'Trip Activity Log',
    table: null,
    events: null,
    isLoading: Ember.computed.alias('fetchEventsTask.isRunning'),
    startDate: Ember.computed.alias('workspace.startDate'),
    endDate: Ember.computed.alias('workspace.endDate'),
    onFilteredEventsChange: Ember.observer('filteredEvents', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateTableRows');
    }),

    init() {
      this._super(...arguments);

      const currColumns = this.get('columns');
      const table = new _emberLightTable.default(currColumns);
      this.set('table', table); // initialize key observer

      this.onFilteredEventsChange();
      this.get('fetchEventsTask').perform();
    },

    filteredEvents: Ember.computed('events.[]', 'startDate', 'endDate', function () {
      let events = this.get('events') || [];
      const startDate = this.get('startDate');

      if (startDate) {
        const startTime = startDate.getTime();
        events = events.filter(event => {
          const date = Ember.get(event, 'date');
          return startTime <= date.getTime();
        });
      }

      const endDate = this.get('endDate');

      if (endDate) {
        const endTime = endDate.getTime();
        events = events.filter(event => {
          const date = Ember.get(event, 'date');
          return date.getTime() <= endTime;
        });
      } // sort reverse-chronologically while we're at it


      events = events.sortBy('date');
      events.reverse();
      return events;
    }),
    fetchEventsTask: (0, _emberConcurrency.task)(function* () {
      this.set('events', []); // need a scheduled route to retrieve routeExecEvents

      const tripStatus = this.get('trip.status');
      if (!['Active', 'Completed', 'No Show'].includes(tripStatus)) return; //console.log('trip ',this.get('trip'));
      //const trip = this.get('trip');
      //let trip = trip.get('trip');

      const tripId = this.get('trip.trip.id'); //console.log('tripId ',tripId);

      const routeExecEvent = yield this.get('store').findRecord('tm-route-exec', tripId); //console.log('routeExecEvent',routeExecEvent);

      let events = [];

      const event = this._buildEvent(routeExecEvent);

      if (event) {
        events.push(event);
      }

      this.set('events', events); //console.log('events ',events);
    }).drop(),

    updateTableRows() {
      const table = this.get('table');
      const filteredEvents = this.get('filteredEvents');
      table.setRows(filteredEvents);
    },

    // this should be replaced when there is a real API
    _buildEvent(routeExecEvent) {
      //console.log('_buildEvent routeExecEvent ',routeExecEvent);
      const date = new Date(routeExecEvent.get('content.actualTime'));
      const type = routeExecEvent.get('content.type'); //console.log('logEntryTypes ',logEntryTypes);

      const logEntryType = _config.logEntryTypes.findBy('routeExecEventType', 'arriveActual'); //console.log('logEntryType ',logEntryType);


      if (!logEntryType) return;
      const {
        activity,
        condition,
        buildAttrs,
        buildDetails,
        buildUser
      } = logEntryType; //console.log('logEntryType ',logEntryType);

      /*eslint-disable */
      // if (condition && !condition(routeExecEvent, this.get('trip'))) return null;

      const attrs = buildAttrs(routeExecEvent, this.get('store'), routeExecEvent.stopPoints[0].passengerEvents[0]); //console.log('attrs ',attrs);

      if (!attrs) return null;
      const details = buildDetails(attrs);
      const user = buildUser(attrs);
      return {
        activity,
        date,
        details,
        user
      };
      /*eslint-enable */
    }

  });

  _exports.default = _default;
});