define("adept-iq/adapters/route", ["exports", "adept-iq/adapters/-dispatch"], function (_exports, _dispatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // export default DispatchAdapter;
  var _default = _dispatch.default.extend({
    // TODO: This is a temporary solution for creating a route
    // dispatch backend will have a proper endpoint for POST route
    buildURL: function (modelType, id, snapshot, requestType) {
      if (requestType === 'createRecord') {
        return `${this.host}/schedule/${snapshot.record.get('schedule.id')}/route/clusterization`;
      }

      return this._super(...arguments);
    }
  });

  _exports.default = _default;
});