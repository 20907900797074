define("adept-iq/pods/components/iq-widgets/passengers-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/config/environment", "adept-iq/pods/components/iq-widgets/passengers-widget/config", "ember-concurrency", "adept-iq/utils/populate-new-trip", "adept-iq/utils/populate-copy-trip", "adept-iq/utils/populate-edit-trip", "adept-iq/utils/populate-search-trip", "adept-iq/utils/populate-account", "adept-iq/utils/check-address-flags", "adept-iq/utils/populate-call-line", "adept-iq/config/api-urls"], function (_exports, _component, _environment, _config, _emberConcurrency, _populateNewTrip, _populateCopyTrip, _populateEditTrip, _populateSearchTrip, _populateAccount, _checkAddressFlags, _populateCallLine, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['passengers-widget'],
    editModal: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    store: Ember.inject.service(),
    trip: Ember.inject.service(),
    ajax: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    session: Ember.inject.service(),
    searchservice: Ember.inject.service(),
    phoneSearchValue: null,
    optionSearchValue: null,
    phoneSearchPath: _apiUrls.API_SUB_URL.phoneSearchPath,
    accountSearchPath: _apiUrls.API_SUB_URL.accountSearchPath,
    config: _config.default,
    defaultIncludes: INCLUDES,
    editComponent: 'iq-widgets/passengers-form-widget',
    newTripComponent: 'iq-widgets/new-trip-form-widget',
    // eslint-disable-next-line no-unused-vars
    search: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(50);
      let searchResults;
      searchResults = yield this.getRiderProfile(this.get('phoneSearchPath'), this.get('phoneSearchValue'));
      return searchResults;
    }),

    getRiderProfile(phoneSearchPath, phoneSearchValue) {
      return this.get('ajax').put(_apiUrls.API.tripManagementService.host + `/rider-profile?filter=${phoneSearchPath}==` + phoneSearchValue, {
        method: 'GET',
        contentType: "application/json"
      }).then(response => {
        return response.data;
      }).catch(function (error) {
        return [];
      });
    },

    saveTripRecordFunction(records, editComponentAction) {
      console.log(' saveTripRecordFunction ', editComponentAction);
      const me = this;
      return new Ember.RSVP.Promise((resolve, reject) => {
        let record = records.firstObject;
        record.save().then(() => {
          console.log('after saveTripRecordFunction ');
          const tripId = record.get('id');
          me.get('errorMessage').pushInfo({
            detail: `Trip '${tripId}' successfully saved`
          });
          record.set('lastId', record.get('id'));
          record.set('isForceDirty', false);

          if (!Ember.isBlank(editComponentAction) && editComponentAction === 'onApplyClick') {
            Ember.run(() => {
              let recordRefresh = [];
              let newtrip = (0, _populateEditTrip.default)(me.get('trip'), record, me.get('store'), me.get('geocode'));
              (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, false);
              recordRefresh.push(newtrip);
              let newTripComponent = me.get('editSwitchTripComponent');

              if (this.get('editModal.editComponent') !== null && this.get('editModal.editComponent') === this.get('editSwitchTripComponent')) {
                newTripComponent = me.get('editTripComponent');
              }

              me.get('editModal').open(newTripComponent, recordRefresh, me.get('saveTripRecordFunction').bind(me));
            });
          } else if (!Ember.isBlank(editComponentAction) && editComponentAction === 'onApplyClearClick') {
            Ember.run(() => {
              Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
              me.get('geocode').activateGeocode(false);
              me.get('geocode').deactivateGeocode();
              let recordRefresh = [];
              let newtrip = (0, _populateNewTrip.default)(me.get('trip'), me.get('store'));
              (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, false);
              newtrip.set('lastId', record.get('lastId'));
              recordRefresh.push(newtrip); //me.set('editableRecords',recordRefresh);
              //me.set('records',recordRefresh);

              let newTripComponent = me.get('newSwitchTripComponent');

              if (me.get('editModal.editComponent') !== null && me.get('editModal.editComponent') === me.get('newSwitchTripComponent')) {
                newTripComponent = me.get('newTripComponent');
              }

              me.get('editModal').open(newTripComponent, recordRefresh, me.get('saveTripRecordFunction').bind(me));
            });
          }
        }).catch(function (error) {
          console.log(error);
          reject(error);
        });
      });
    },

    prepareNewTrip(model) {
      let rider = model;
      let trip = (0, _populateNewTrip.default)(this.get('trip'), this.get('store'));
      trip.passengers[0].phone1.number = rider.phoneNr;
      this.set('phoneSearchValue', rider.phoneNr);
      trip.passengers[0].name = rider.name;
      trip.passengers[0].firstName = rider.name;
      Ember.run(() => {
        this.get('search').perform().then(results => {
          let widgetModelData = results;
          trip.editmodelcontext.callerTrips = widgetModelData;

          if (widgetModelData && widgetModelData.length > 1) {
            this.set('editmodalcontext.showWidgetModal', true);
            this.set('editmodalcontext.widgetModelData', widgetModelData);
            this.set('editmodalcontext.modelWidgetPath', 'rider-profile-widget');
          } else if (widgetModelData && widgetModelData.length === 1) {
            (0, _populateCallLine.default)(trip, results.firstObject.attributes, this.get('geocode'), this.get('store'));
            (0, _populateAccount.default)(trip, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), results.firstObject.attributes.account.accountId, true);
            (0, _checkAddressFlags.default)(trip);
          }

          (0, _checkAddressFlags.default)(trip);
        }); //this.get('records').pushObject(trip);

        let newTripComponent = this.get('newTripComponent');
        this.get('editModal').open(newTripComponent, [trip], this.get('saveTripRecordFunction').bind(this));
      });
    },

    rowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({});
      this.set('singleActions', [{
        name: 'Add Trip',
        action: model => {
          this.prepareNewTrip(model);
        }
      }, {
        name: 'View Activity Log',
        action: () =>
        /* models */
        {}
      }]);
    }

  });

  _exports.default = _default;
});