define("adept-iq/topic-transforms/route-exec-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(store, payload, logger) {
    const {
      routeId,
      tripId,
      breakId,
      type
    } = payload.data.attributes;
    const stopId = payload.data.attributes.stopPointId;
    const routeAttributes = {};
    const tripAttributes = {};
    const stopAttributes = {};

    switch (type) {
      case 'No Show':
        {
          //logger.debug(`routeExec ${type} tripId=${tripId}`);
          tripAttributes.status = type;
          stopAttributes.status = type;
          break;
        }

      case 'Arrive':
        {
          //logger.debug(`routeExec ${type} routeId=${routeId}`);
          stopAttributes.status = type;
          routeAttributes.status = type;
          break;
        }

      case 'Depart':
        {
          // logger.debug(`routeExec ${type} stopId=${stopId}`);
          stopAttributes.status = type;
          routeAttributes.status = type;
          break;
        }

      case 'Accept':
      case 'Reject':
      case 'OnBreak':
        {
          //logger.debug(`routeExec ${type} routeId=${routeId}`);
          stopAttributes.status = type;
          routeAttributes.status = type;
          break;
        }

      case 'OffBreak':
        {
          //logger.debug(`routeExec ${type} routeId=${routeId}`);
          stopAttributes.status = type;
          routeAttributes.status = type;
          break;
        }
      // these are currently defined but unused

      case 'OnStop':
      case 'Complete':
        {
          //logger.debug(`routeExec ${type} routeId=${routeId}`);
          routeAttributes.status = type;
          break;
        }

      case 'work': // TODO: check if this should be capitalized

      case 'ETA':
      case 'AVL':
      case 'Pullout':
        {
          //logger.debug(`routeExec ${type} routeId=${routeId}`);
          stopAttributes.status = type;
          routeAttributes.status = type;
          break;
        }

      case 'Pullin':
        {
          // logger.debug(`routeExec ${type} routeId=${routeId}`);
          stopAttributes.status = type;
          routeAttributes.status = type;
          break;
        }

      default:
        break;
    }

    const transformedPayloads = [];

    if (routeId) {
      const {
        odo,
        otpRouteStatus,
        otpRouteValue
      } = payload.data.attributes;

      if (Ember.isPresent(otpRouteStatus)) {
        routeAttributes.otp = otpRouteStatus;
      }

      if (Ember.isPresent(otpRouteValue)) {
        routeAttributes.otpValue = otpRouteValue;
      }

      routeAttributes.odometer = odo;
      const routePayload = {
        data: {
          id: routeId,
          type: 'route',
          attributes: routeAttributes
        }
      };
      transformedPayloads.push(routePayload);
    }

    if (tripId) {
      const {
        otpTripStatus,
        otpTripValue
      } = payload.data.attributes;

      if (Ember.isPresent(otpTripStatus)) {
        tripAttributes.otp = otpTripStatus;
      }

      if (Ember.isPresent(otpTripValue)) {
        tripAttributes.otpValue = otpTripValue;
      }

      const tripPayload = {
        data: {
          id: tripId,
          type: 'trip',
          attributes: tripAttributes
        }
      };
      transformedPayloads.push(tripPayload);
    }

    if (stopId) {
      const {
        odo,
        otpStopStatus,
        otpStopValue
      } = payload.data.attributes;

      if (Ember.isPresent(otpStopStatus)) {
        stopAttributes.otp = otpStopStatus;
      }

      if (Ember.isPresent(otpStopValue)) {
        stopAttributes.otpValue = otpStopValue;
      }

      stopAttributes.odometer = odo;
      const stopPayload = {
        data: {
          id: stopId,
          type: 'stop',
          attributes: stopAttributes
        }
      };
      transformedPayloads.push(stopPayload);
    }

    if (breakId) {
      const {
        odo,
        otpStopStatus,
        otpStopValue
      } = payload.data.attributes;

      if (Ember.isPresent(otpStopStatus)) {
        stopAttributes.otp = otpStopStatus;
      }

      if (Ember.isPresent(otpStopValue)) {
        stopAttributes.otpValue = otpStopValue;
      }

      stopAttributes.odometer = odo;
      const stopPayload = {
        data: {
          id: `B${breakId}`,
          type: 'stop',
          attributes: stopAttributes
        }
      };
      transformedPayloads.push(stopPayload);
    }

    return transformedPayloads;
  }
});