define("adept-iq/serializers/tm-canned-message", ["exports", "adept-iq/serializers/-tm-tripManagement"], function (_exports, _tmTripManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tmTripManagement.default.extend({
    // this payload includes a non-JSON-API-compliant driver relationship
    // with `endPointType` metadata; this restructures the payload to have
    // a bona-fide relationship and moves the metadata to an attribute
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.data.forEach(data => {
        // TODO: normalize vehicle and route relationships when they are supported
        this.normalizeDriver(data);
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    normalizeDriver(data) {
      // remove non-JSON-API compliant driver object
      let {
        driver
      } = data.attributes;
      delete data.attributes.driver;
      let driverId = driver.id;
      delete driver.id;
      let driverEndPointType = driver.endPointType;
      delete driver.endPointType; // normalize as attributes

      data.attributes.driverId = driverId;
      data.attributes.driverEndPointType = driverEndPointType;
      data.relationships = data.relationships || {}; // and a bona-fide Ember-Data relationship

      data.relationships.driver = {
        data: {
          id: driverId,
          type: 'driver'
        }
      };
    },

    serialize(snapshot, options) {
      let serialized = this._super(snapshot, options);

      let {
        attributes,
        relationships
      } = serialized.data; // rebuild non-JSON-API compliant driver object

      let {
        driverEndPointType
      } = attributes;
      delete attributes.driverEndPointType;
      let {
        driverId
      } = attributes;
      delete attributes.driverId;

      if (relationships) {
        delete relationships.driver;
      }

      attributes.driver = {
        id: driverId,
        endPointType: driverEndPointType
      }; // TODO: serialize vehicle and route relationships when they are supported

      return serialized;
    }

  });

  _exports.default = _default;
});