define("adept-iq/serializers/zone", ["exports", "adept-iq/serializers/-dispatch"], function (_exports, _dispatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    attrs: {
      name: 'zoneName'
    }
  });

  _exports.default = _default;
});