define("adept-iq/pods/components/form-components/timeflatpickr/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "whVURrZB",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"ember-flatpickr\",null,[[\"locale\",\"allowInput\",\"noCalendar\",\"placeholder\",\"dateFormat\",\"enableTime\",\"date\",\"onChange\",\"onClose\"],[\"en\",true,true,[23,[\"timePlaceholder\"]],[23,[\"timeFormat\"]],true,[27,\"readonly\",[[23,[\"value\"]]],null],[27,\"action\",[[22,0,[]],\"onChangeTime\"],null],[27,\"action\",[[22,0,[]],\"onClose\"],null]]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/timeflatpickr/template.hbs"
    }
  });

  _exports.default = _default;
});