define("adept-iq/pods/components/iq-widgets/users-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        type: 'text',
        label: 'ID',
        valuePath: 'userId',
        editable: false
      }]
    }, {
      title: 'Editable',
      fields: [{
        id: 'name',
        type: 'text',
        label: 'Name',
        editable: true,
        valuePath: 'displayName'
      }, {
        id: 'email',
        type: 'text',
        label: 'Email',
        editable: true,
        valuePath: 'email'
      }, {
        id: 'status',
        type: 'enum',
        label: 'Status',
        valuePath: 'calculatedStatus',
        extra: {
          options: ['active', 'inactive']
        }
      }, {
        id: 'role',
        type: 'searchable-enum',
        label: 'Role',
        valuePath: 'UserRoleRolenames',
        editable: true,
        extra: {
          optionModelName: 'sso-role',
          optionSearchPath: 'displayName',
          optionSelectedPath: 'firstObject',
          optionIncludes: '',
          optionSearchEnabled: false
        }
      }]
    }]
  };
  _exports.default = _default;
});