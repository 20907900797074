define("adept-iq/pods/components/form-components/account-model/component", ["exports", "ember-concurrency", "adept-iq/utils/check-address-flags", "adept-iq/utils/populate-account", "adept-iq/utils/populate-call-line", "adept-iq/config/environment", "adept-iq/config/api-urls"], function (_exports, _emberConcurrency, _checkAddressFlags, _populateAccount, _populateCallLine, _environment, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-account-model'],
    value: null,
    disabled: false,
    maxlength: null,
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    searchservice: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    optionSearchPath: Ember.computed.alias('extra.optionSearchPath'),
    optionModelName: Ember.computed.alias('extra.optionModelName'),
    parentValuePath: Ember.computed.alias('extra.parentValuePath'),
    optionWidgetModel: Ember.computed.alias('extra.optionWidgetModel'),
    optionModelWidgetPath: Ember.computed.alias('extra.optionModelWidgetPath'),
    accountSearchPath: _apiUrls.API_SUB_URL.accountSearchPath,
    modalData: null,

    init() {
      this._super(...arguments);
    },

    // eslint-disable-next-line no-unused-vars
    search: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(50);
      const searchResults = yield this.getRiderProfile(this.get('optionSearchPath'), this.get('value'), this.get('optionModelName'));
      return searchResults;
    }),

    getRiderProfile(optionSearchPath, filterValue, optionModelName) {
      return this.get('ajax').put(_apiUrls.API.tripManagementService.host + `/${optionModelName}?filter=${optionSearchPath}==` + filterValue.toUpperCase(), {
        method: 'GET',
        contentType: "application/json"
      }).then(response => {
        return response.data;
      }).catch(function () {
        return [];
      });
    },

    actions: {
      onInput(value) {
        this.set(`record.${this.get('valuePath')}`, value.trim());
      },

      handleKeydown(event) {
        if (event.keyCode !== 13) {
          return;
        }

        this.set('editmodalcontext.showWidgetModal', false);
        this.set('editmodalcontext.modelWidgetPath', '');
        this.set('editModal.errors', []);

        if (this.get('optionModelName')) {
          this.get('search').perform().then(results => {
            if (!Ember.isEmpty(results)) {
              let searchRecord = results.firstObject;

              if (!Ember.isBlank(this.get('parentValuePath'))) {
                this.set(`record.${this.get('parentValuePath')}`, searchRecord);
              }

              Ember.run(() => {
                (0, _populateAccount.default)(this.record, searchRecord, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('optionSearchPath'), searchRecord.attributes.account.accountId, true);
              });
            } else {
              this.set(`record.${this.get('parentValuePath')}`, null);

              if (this.get('valuePath') === 'acctCardNr') {
                this.get('editModal.errors').pushObject('No account is present with this No.');
                this.set('record.editmodelcontext.accountTrips', []);
                this.set('record.editmodelcontext.accountFixedPriceTrips', []);
                this.set('editmodalcontext.showWidgetModal', false);
              }
            }
          });
        }
      }

    }
  });

  _exports.default = _default;
});