define("adept-iq/services/maximizer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    router: Ember.inject.service('router'),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    dashboard: Ember.computed.alias('workspace.dashboard'),
    maximizedWidget: null,
    checkedItems: null,

    maximize(widget) {
      this.set('maximizedWidget', widget);
      this.set('checkedItems', this.get('activeContext.checkedItems'));
      const router = this.get('router');
      router.transitionTo('maximized');
    },

    minimize() {
      this.set('maximizedWidget', null);
      const router = this.get('router');
      router.transitionTo('dashboard', this.get('dashboard').model);
    }

  });

  _exports.default = _default;
});