define("adept-iq/pods/components/iq-widgets/assign-trip-2-route-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/assign-trip-2-route-widget/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),

    validateRecords() {
      let valid = true;
      let records = this.get('editableRecords');
      this.set('editModal.errors', []);
      records.forEach(record => {
        if (record.get('assignRoute.id') === undefined || record.get('assignRoute.id') === null) {
          this.get('editModal.errors').pushObject('Route Id');
          valid = false;
        }
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        /*let lastUndoState = this.get('editModal.undoHistory').popObject();
         if (lastUndoState === null) {
          let records = this.get('editableRecords');
           records.forEach(record => {
            record.set('isForceDirty', false);
          });
         }
        else {
          lastUndoState.forEach(({ record, properties }) => {
            record.setProperties(properties);
          });
        }
         this.set('isLastUndoStateCommitted', true);*/
      },

      onApplyClick() {
        if (this.validateRecords()) {
          this.get('service').apply();
        }
      } // onCellValueChange(record, valuePath, value, options) {
      //   if (valuePath === 'vehicles.firstObject.name') {
      //     let vehicle = record.get('vehicles.firstObject');
      //     record.vehicles.removeObject(vehicle);
      //   }
      //   else {
      //     this.get('service').setRecordValue(record, valuePath, value, options);
      //   }
      // }


    }
  });

  _exports.default = _default;
});