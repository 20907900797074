define("adept-iq/models/rider-info", ["exports", "ember-data", "adept-iq/utils/name-parser"], function (_exports, _emberData, _nameParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;
  /*
          This is the rider model for data coming from rider-management and later updated with
          travel-needs. The rider-info model holds data to be sent to the trip service.
          This is necessary because a complex nesting of DS models and ember-data-fragments
          has problems serializing. See app/confirm/route.js.
   */

  var _default = Model.extend({
    riderId: attr('string'),
    firstName: attr('string'),
    middleName: attr('string'),
    lastName: attr('string'),
    notes: attr('string'),
    travelNeeds: hasMany('rider-travel-need-info'),
    fullName: Ember.computed('firstName', 'middleName', 'lastName', {
      get()
      /* key*/
      {
        let middleName = Ember.get(this, 'middleName');

        if (Ember.isEmpty(middleName)) {
          middleName = ' ';
        } else {
          middleName = ` ${middleName} `;
        }

        return `${Ember.get(this, 'firstName')}${middleName}${Ember.get(this, 'lastName') || ''}`.trim();
      },

      set(key, value) {
        const nameParts = _nameParser.default.parse(value);

        Ember.set(this, 'firstName', nameParts.firstName);
        Ember.set(this, 'middleName', nameParts.middleName);
        Ember.set(this, 'lastName', nameParts.lastName);
        return value;
      }

    })
  });

  _exports.default = _default;
});