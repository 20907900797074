define("adept-iq/classes/tree-nodes/value", ["exports", "adept-iq/classes/tree-nodes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    type: null,
    configItem: null,
    // TODO: bind this with permissions
    canEdit: true,
    isRemoved: false,
    originalValue: null,
    isValue: true,
    isProperty: Ember.computed.readOnly('parent.isObject'),
    isNumber: Ember.computed.equal('type', 'number'),
    isString: Ember.computed.equal('type', 'string'),
    isBoolean: Ember.computed.equal('type', 'boolean'),
    isColor: Ember.computed.equal('type', 'color'),
    isOutdated: Ember.computed('originalValue', 'configItem.value', function () {
      const originalValue = this.get('originalValue');
      const actualValue = this.get('configItem.value');

      if (Ember.isEmpty(originalValue) && Ember.isEmpty(actualValue)) {
        return false;
      }

      return JSON.stringify(originalValue) !== JSON.stringify(actualValue);
    }),
    isModified: Ember.computed('value', 'originalValue', function () {
      const value = this.get('value');
      const originalValue = this.get('originalValue');

      if (Ember.isEmpty(value) && Ember.isEmpty(originalValue)) {
        return false;
      }

      return JSON.stringify(value) !== JSON.stringify(originalValue);
    }),
    isDirty: Ember.computed.or('isOutdated', 'isModified')
  });

  _exports.default = _default;
});