define("adept-iq/models/passenger-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    isPca: Ember.computed('name', function () {
      return this.get('name') === 'pca';
    }),
    isCompanion: Ember.computed('name', function () {
      return this.get('name') === 'companion';
    }),
    isClient: Ember.computed('name', function () {
      return this.get('name') === 'client';
    })
  });

  _exports.default = _default;
});