define("adept-iq/models/bs-subscription-travel-need", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  const SubscriptionTravelNeed = Model.extend({
    count: attr('number'),
    subscription: belongsTo('bs-subscription'),
    travelNeedType: belongsTo('bs-travel-need-type'),
    passengerType: belongsTo('bs-passenger-type'),
    isClient: Ember.computed.equal('passengerType.name', 'client'),
    isPca: Ember.computed.equal('passengerType.name', 'pca'),
    isCompanion: Ember.computed.equal('passengerType.name', 'companion'),
    basicTravelNeedType: Ember.computed('travelNeedType', function () {
      const name = Ember.get(this, 'travelNeedType.name');
      return name === 'ambulatory' || name === 'wideAmbulatory' || name === 'wheelchair' || name === 'wideWheelchair';
    }),
    isEquipment: Ember.computed('travelNeedType', function () {
      return !this.get('travelNeedType.isServiceAnimal');
    }),
    isExtraPassenger: Ember.computed('travelNeedType', function () {
      return this.get('travelNeedType.isServiceAnimal');
    })
  });
  var _default = SubscriptionTravelNeed;
  _exports.default = _default;
});