define("adept-iq/models/iq-vehicle", ["exports", "ember-data/model", "ember-data/relationships", "adept-iq/mixins/otp-label", "adept-iq/mixins/status-value"], function (_exports, _model, _relationships, _otpLabel, _statusValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_statusValue.default, {
    vehicle: (0, _relationships.belongsTo)('vehicle', {
      inverse: 'iqVehicle'
    }),
    vehicleState: (0, _relationships.belongsTo)('vehicle-state', {
      inverse: 'iqVehicle'
    }),
    otp: Ember.computed.alias('avlmVehicle.otp'),
    lat: Ember.computed.alias('vehicle.lat'),
    lng: Ember.computed.alias('vehicle.lng'),
    timestamp: Ember.computed.alias('vehicle.timestamp'),
    heading: Ember.computed.alias('vehicle.heading'),
    speed: Ember.computed.alias('vehicle.speed'),
    currentAVLLocation: Ember.computed.alias('vehicle.lastObject'),
    sortedAvls: Ember.computed.alias('vehicle.sortedAvls'),
    lastKnownCoord: Ember.computed.alias('vehicle.lastKnownCoord'),
    point: Ember.computed.collect('lat', 'lng'),
    //status:computed.alias('vehicle.status'),
    statusValue: Ember.computed.alias('status'),
    status: Ember.computed('vehicleState.content.status', function () {
      if (this.get('vehicleState.content')) {
        let vehicleState = this.get('vehicleState.content');

        if (vehicleState) {
          return vehicleState.get('status');
        }
      }

      return this.get('vehicle.status');
    }),
    drivedStatus: Ember.computed('vehicleState.content.status', function () {
      if (this.get('vehicleState.content')) {
        let vehicleState = this.get('vehicleState.content');

        if (vehicleState) {
          return vehicleState.get('status');
        }
      }

      return this.get('vehicle.status');
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    driver: Ember.computed('vehicleState.content.driver', 'drivedStatus', function () {
      let vehicleState = this.get('vehicleState.content');

      if (vehicleState) {
        if (this.get('drivedStatus') === 'off') {
          return null;
        }

        return vehicleState.get('driver');
      }

      return null;
    }),
    standQueuePos: Ember.computed.alias('vehicleState.content.standQueuePos'),
    zoneQueuePos: Ember.computed.alias('vehicleState.content.zoneQueuePos'),
    zone: Ember.computed('vehicleState.content.zone', function () {
      let vehicleState = this.get('vehicleState.content');

      if (vehicleState) {
        return vehicleState.get('zone');
      }
    }),
    stand: Ember.computed('vehicleState.content.stand', function () {
      let vehicleState = this.get('vehicleState.content');

      if (vehicleState) {
        return vehicleState.get('stand');
      }
    })
  });

  _exports.default = _default;
});