define("adept-iq/models/coord", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* app/models/coord.js */
  var _default = _emberDataModelFragments.default.Fragment.extend({
    lat: _emberData.default.attr('number'),
    lng: _emberData.default.attr('number'),
    address: _emberData.default.belongsTo('address')
  });

  _exports.default = _default;
});