define("adept-iq/pods/components/form-components/multiselect/component", ["exports", "lodash"], function (_exports, _lodash2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-multi-select'],
    value: null,
    disabled: false,
    extra: null,
    store: Ember.inject.service(),
    dataFromModel: false,
    options: null,
    selectedAssign: null,
    selectedAvailable: null,
    assigns: null,
    allOptions: null,
    allowMultiple: Ember.computed.alias('extra.allowMultiple'),
    optionModelName: Ember.computed.alias('extra.optionModelName'),
    assignedValuePath: Ember.computed.alias('valuePath'),
    additionalAssignedValuePath: Ember.computed.alias('extra.additionalValuePath'),

    assignsChanged() {
      console.log('assignsChanged ');
      let record = this.get('record');
      Ember.run(() => {
        let requiredAttributes = record.get('attributes');
        let allOptions = this.get('allOptions');
        let availableOptions = [];

        if (!Ember.isEmpty(requiredAttributes)) {
          availableOptions = allOptions.filter(function (o1) {
            // if match found return false
            return _lodash2.default.findIndex(requiredAttributes, {
              'id': o1.id
            }) !== -1 ? false : true;
          });
        } else {
          availableOptions = allOptions;
        }

        availableOptions = availableOptions.sortBy('displayName');
        this.set('options', availableOptions);

        if (!Ember.isEmpty(availableOptions)) {
          this.set('selectedAvailable', availableOptions.firstObject);
        }

        if (!Ember.isEmpty(requiredAttributes) && Ember.isBlank(this.get('selectedAssign'))) {
          this.set('selectedAssign', requiredAttributes.firstObject);
        }
      });
    },

    init() {
      this._super(...arguments);

      let record = this.get('record');
      Ember.run(() => {
        if (this.get('extra.optionModelName') !== undefined) {
          this.set('dataFromModel', true);
          let options = this.get('store').peekAll(this.get('extra.optionModelName'));
          let assigns = record.get(this.get('additionalAssignedValuePath'));
          this.set('allOptions', options);

          if (assigns !== undefined && assigns !== null && assigns.length !== 0) {
            options = options.filter(function (o1) {
              // if match found return false
              return _lodash2.default.findIndex(assigns, {
                'id': o1.id
              }) !== -1 ? false : true;
            });
          } //options = _lodash.sortBy(options, 'displayName');


          options = options.sortBy('displayName');
          this.set('options', options);

          if (options !== undefined && options !== null && options.length !== 0) {
            this.set('selectedAvailable', options.firstObject);
          }
        }
      });
    },

    assignsDataChanged: Ember.observer('record.attributes', function () {
      this.assignsChanged();
    }),

    toggleOptionBetweenSelect(event, shiftFrom, shiftTo, selectFrom, selectedTo, removeAdditionalAttributes = false) {
      let option = event.target.value;

      if (this.get(shiftFrom) !== undefined && this.get(shiftFrom) !== null && option !== '') {
        let removeOptions = this.get('allOptions').filter(function (item) {
          return item.id === option;
        });

        if (removeAdditionalAttributes) {
          let elementPos = this.get('record.attributes').map(function (x) {
            return x.id;
          }).indexOf(option);
          let requiredOptions = this.get('record.attributes').filter(function (item) {
            return item.id != option;
          });

          if (!Ember.isEmpty(requiredOptions)) {
            if (requiredOptions[elementPos]) {
              this.set(selectFrom, requiredOptions[elementPos]);
            } else if (requiredOptions[elementPos - 1]) {
              this.set(selectFrom, requiredOptions[elementPos - 1]);
            }
          }

          if (!Ember.isEmpty(this.get(shiftFrom))) {
            this.get(shiftFrom).removeObject(removeOptions[0]);
          }

          if (!Ember.isEmpty(this.get('record.editmodelcontext.attributes.account'))) {
            this.get('record.editmodelcontext.attributes.account').removeObject(removeOptions[0]);
          }

          if (!Ember.isEmpty(this.get('record.editmodelcontext.attributes.address'))) {
            this.get('record.editmodelcontext.attributes.address').removeObject(removeOptions[0]);
          }

          if (!Ember.isEmpty(this.get('record.editmodelcontext.attributes.area'))) {
            this.get('record.editmodelcontext.attributes.area').removeObject(removeOptions[0]);
          }

          if (!Ember.isEmpty(this.get('record.editmodelcontext.attributes.cli'))) {
            this.get('record.editmodelcontext.attributes.cli').removeObject(removeOptions[0]);
          }

          if (!Ember.isEmpty(this.get('record.editmodelcontext.attributes.cli'))) {
            this.get('record.editmodelcontext.attributes.cli').removeObject(removeOptions[0]);
          }
        } else {
          let elementAvailablePos = this.get('options').map(function (x) {
            return x.id;
          }).indexOf(option);
          let availableOptions = this.get('options').filter(function (item) {
            return item.id != option;
          });

          if (!Ember.isEmpty(availableOptions)) {
            if (availableOptions[elementAvailablePos]) {
              this.set(selectFrom, availableOptions[elementAvailablePos]);
            } else if (availableOptions[elementAvailablePos - 1]) {
              this.set(selectFrom, availableOptions[elementAvailablePos - 1]);
            }
          }

          let assignsOptions = this.get(shiftTo);

          if (assignsOptions === null) {
            assignsOptions = [];
            assignsOptions.push(removeOptions[0]);
          } else {
            assignsOptions.push(removeOptions[0]);
          }

          assignsOptions = _lodash2.default.sortBy(assignsOptions, 'displayName');
          this.set(shiftTo, []);
          this.set(shiftTo, assignsOptions);
          this.set(selectedTo, removeOptions[0]);
        }

        this.set('record.isForceDirty', true);
      }
    },

    actions: {
      selectionSaved(event) {//console.log('selectionSaved ', event);
      },

      handleKeydownonAvailabled(event) {
        if (event.keyCode !== 13) {
          return;
        } //////console.log(('this.get(additionalAssignedValuePath)',this.get('additionalAssignedValuePath'));


        if (!Ember.isEmpty(this.get('additionalAssignedValuePath'))) {
          this.toggleOptionBetweenSelect(event, 'options', 'record.' + this.get('additionalAssignedValuePath'), 'selectedAvailable', 'selectedAssign');
        }

        return true;
      },

      handleKeydownoonAssignment(event) {
        if (event.keyCode !== 13) {
          return;
        } //////console.log(('this.get(additionalAssignedValuePath)',this.get('additionalAssignedValuePath'));


        if (!Ember.isEmpty(this.get('additionalAssignedValuePath'))) {
          this.toggleOptionBetweenSelect(event, 'record.' + this.get('additionalAssignedValuePath'), 'options', 'selectedAssign', 'selectedAvailable', true);
        }

        return true;
      },

      onAvailabled(event) {
        //////console.log(('this.get(additionalAssignedValuePath)',this.get('additionalAssignedValuePath'));
        if (!Ember.isEmpty(this.get('additionalAssignedValuePath'))) {
          this.toggleOptionBetweenSelect(event, 'options', 'record.' + this.get('additionalAssignedValuePath'), 'selectedAvailable', 'selectedAssign');
        }
      },

      onAssignment(event) {
        //////console.log(('this.get(additionalAssignedValuePath)',this.get('additionalAssignedValuePath'));
        if (!Ember.isEmpty(this.get('additionalAssignedValuePath'))) {
          this.toggleOptionBetweenSelect(event, 'record.' + this.get('additionalAssignedValuePath'), 'options', 'selectedAssign', 'selectedAvailable', true);
        }
      }

    }
  });

  _exports.default = _default;
});