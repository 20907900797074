define("adept-iq/models/zone", ["exports", "ember-data/model", "ember-data/attr", "adept-iq/mixins/zone-label", "adept-iq/mixins/zone-style", "ember-data/relationships", "adept-iq/config/environment"], function (_exports, _model, _attr, _zoneLabel, _zoneStyle, _relationships, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_zoneLabel.default, _zoneStyle.default, {
    name: (0, _attr.default)('string'),
    points: (0, _attr.default)(),
    noOfPoints: Ember.computed('points', function () {
      let count = 0;
      const points = this.get('points');

      if (this.get('points') != null) {
        for (let i = 1; points[i] != null; i++) {
          count++;
        }

        return count;
      }

      return 0;
    }),
    polypoints: Ember.computed('points', 'noOfPoints', function () {
      let polygonPoints = [];
      const points = this.get('points');

      if (!Ember.isEmpty(points) && this.get('noOfPoints') === 1) {
        if (this.get('points') != null) {
          const item = points[1];
          return item;
        }
      } else {
        if (this.get('points') != null) {
          for (let i = 1; points[i] != null; i++) {
            let item = points[i];
            let polygonPoint = [];
            polygonPoint.push(item.lat);
            polygonPoint.push(item.lng);
            polygonPoints.push(polygonPoint);
          }
        }
      }

      return polygonPoints;
    }),
    calculateCenter: Ember.computed('points', 'noOfPoints', function () {
      let minlat = false;
      let minlng = false;
      let maxlat = false;
      let maxlng = false;
      const points = this.get('points');

      for (let i = 1; points[i] != null; i++) {
        let item = points[i];

        if (minlat === false) {
          minlat = item.lat;
        } else {
          minlat = item.lat < minlat ? item.lat : minlat;
        }

        if (maxlat === false) {
          maxlat = item.lat;
        } else {
          maxlat = item.lat > maxlat ? item.lat : maxlat;
        }

        if (minlng === false) {
          minlng = item.lng;
        } else {
          minlng = item.lng < minlng ? item.lng : minlng;
        }

        if (maxlng === false) {
          maxlng = item.lng;
        } else {
          maxlng = item.lng > maxlng ? item.lng : maxlng;
        }
      } // Calculate the center


      let lat = maxlat - (maxlat - minlat) / 2;
      let lng = maxlng - (maxlng - minlng) / 2;
      return {
        lat: lat,
        lng: lng
      };
    }),
    lat: Ember.computed.alias('calculateCenter.lat'),
    lng: Ember.computed.alias('calculateCenter.lng'),
    trips: (0, _relationships.hasMany)('trip'),
    vehicles: (0, _relationships.hasMany)('vehicleState'),
    // eslint-disable-next-line ember/use-brace-expansion
    workspacetrips: Ember.computed('trips.@each', 'trips.@each.bookingType', 'trips.@each.tripStatus', function () {
      if (!Ember.isEmpty(this.get('trips'))) {
        return this.get('trips').filter(function (item) {
          return _environment.default.workspaceContext.startDate < item.get('requestedTime') && _environment.default.workspaceContext.endDate > item.get('requestedTime');
        });
      } else {
        return [];
      }
    }),
    asap: Ember.computed('workspacetrips.[]', function () {
      if (!Ember.isEmpty(this.get('workspacetrips'))) {
        return this.get('workspacetrips').filter(trip => trip.get('bookingType') === 'asap' && (trip.get('tripStatus') === 'booked' || trip.get('tripStatus') === 'active')).length;
      } else {
        return 0;
      }
    }),
    pb: Ember.computed('workspacetrips.[]', function () {
      if (!Ember.isEmpty(this.get('workspacetrips'))) {
        return this.get('workspacetrips').filter(trip => trip.get('bookingType') === 'prebook' && (trip.get('tripStatus') === 'booked' || trip.get('tripStatus') === 'active')).length;
      } else {
        return 0;
      }
    }),
    auction: Ember.computed('workspacetrips.[]', function () {
      if (!Ember.isEmpty(this.get('workspacetrips'))) {
        return this.get('workspacetrips').filter(trip => trip.get('dispatchMethod') === 'auction' && (trip.get('tripStatus') === 'booked' || trip.get('tripStatus') === 'active' || trip.get('tripStatus') === 'offered')).length;
      } else {
        return 0;
      }
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    freeVehicle: Ember.computed('vehicles.@each', 'vehicles.@each.status', function () {
      if (!Ember.isEmpty(this.get('vehicles'))) {
        return this.get('vehicles').filterBy('status', 'free').length;
      } else {
        return 0;
      }
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    stcVehicle: Ember.computed('vehicles.@each', 'vehicles.@each.status', function () {
      if (!Ember.isEmpty(this.get('vehicles'))) {
        return this.get('vehicles').filterBy('status', 'STC').length;
      } else {
        return 0;
      }
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    offVehicle: Ember.computed('vehicles.@each', 'vehicles.@each.status', function () {
      if (!Ember.isEmpty(this.get('vehicles'))) {
        return this.get('vehicles').filter(vehicle => vehicle.get('status') !== 'off');
      } else {
        return [];
      }
    }),
    totalVehicle: Ember.computed('vehicles', function () {
      return this.get('vehicles').length;
    }),
    notes: (0, _attr.default)(),
    requiredAttributes: (0, _attr.default)()
  });

  _exports.default = _default;
});