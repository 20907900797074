define("adept-iq/pods/components/table/rows/zonestat-formatted-row/component", ["exports", "adept-iq/pods/components/table/rows/action-row/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNameBindings: ['alertCategory'],
    // override with sub-class-specific CP
    alertCategory: Ember.computed('row.status', function () {
      let status = this.get('status');

      if (status === 'high') {
        return 'zone-danger';
      } else if (status === 'medium') {
        return 'zone-warning';
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});