define("adept-iq/pods/components/iq-widgets/search-trip-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/search-trip-widget',
    rowComponent: 'table/rows/otp-formatted-row',
    modelName: 'tm-booking',
    defaultSortId: 'id',
    defaultSortAsc: false,
    hideWidgetControls: true,
    hideHeader: true,
    checkBoxColumn: false,
    isFormControls: true,
    title: 'Trip search Details',
    columns: [{
      id: 'id',
      type: 'uuid',
      label: 'Trip ID',
      valuePath: 'id',
      valuePreview: '1',
      hidden: false,
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 100,
      isMapMCGLabelVisible: true
    }, {
      id: 'source',
      type: 'text',
      label: 'Trip Origin',
      valuePath: 'source.type',
      searchable: true,
      editable: false,
      hidden: false,
      defaultWidth: 60
    }, {
      id: 'Type',
      type: 'text',
      label: 'Trip Type',
      valuePath: 'tripType',
      searchable: true,
      editable: false,
      hidden: false,
      defaultWidth: 60
    }, {
      id: 'requestTime',
      type: 'time',
      label: 'Pickup Time',
      valuePath: 'stopPoints.0.passengerEvents.0.time',
      searchable: true,
      valuePreview: '08:00 AM',
      editable: false,
      hidden: false,
      format: 'HH:mm A DD-MM-YYYY',
      defaultWidth: 100
    }, {
      id: 'streetNr',
      type: 'text',
      label: 'Street Nr',
      isMapLabelVisible: true,
      valuePath: 'stopPoints.0.address.streetNr',
      editable: false,
      searchable: true,
      hidden: false,
      defaultWidth: 75
    }, {
      id: 'streetName',
      type: 'text',
      label: 'Street Name',
      isMapLabelVisible: true,
      valuePath: 'stopPoints.0.address.streetName',
      editable: false,
      searchable: true,
      hidden: false,
      defaultWidth: 150
    }, {
      id: 'paymentType',
      type: 'text',
      label: 'Payment',
      valuePath: 'prices.0.paymentTypeName',
      searchable: true,
      valuePreview: 'Single',
      editable: false,
      hidden: false,
      defaultWidth: 80
    }, {
      id: 'status',
      type: 'text',
      label: 'Trip Status',
      valuePath: 'status',
      searchable: true,
      highlightable: true,
      valuePreview: 'Planned',
      hidden: false,
      isMapLabelVisible: true,
      defaultWidth: 75,
      isMapMCGLabel: true
    }, {
      id: 'riderName',
      type: 'text',
      label: 'Passenger Name',
      valuePath: 'passengers.0.firstName',
      hidden: false,
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'riderPhone',
      type: 'text',
      label: 'Phone Number',
      valuePath: 'passengers.0.phone1.number',
      hidden: false,
      searchable: true,
      defaultWidth: 120
    }, {
      id: 'acctCardNr',
      type: 'text',
      label: 'Account Card Nr',
      valuePath: 'prices.0.payerIdentifier',
      searchable: true,
      hidden: false,
      isMapLabelVisible: true,
      defaultWidth: 75
    }, {
      id: 'fare',
      type: 'text',
      label: 'Fare',
      valuePath: 'trip.fare',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'jobpriority',
      type: 'text',
      label: 'Priority',
      valuePath: 'trip.jobpriority',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'bookingType',
      type: 'text',
      label: 'Type',
      valuePath: 'bookingType',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'dispatchType',
      type: 'text',
      label: 'Dispatch Method',
      valuePath: 'dispatchType',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 60
    }]
  };
  _exports.default = _default;
});