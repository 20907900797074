define("adept-iq/services/workspace-context", ["exports", "ember-concurrency", "adept-iq/config/active-context-graph", "adept-iq/config/api-preloads", "moment", "adept-iq/config/environment", "lodash"], function (_exports, _emberConcurrency, _activeContextGraph, _apiPreloads, _moment, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // there is a bug in ED that causes watcher errors after unloading records;
  // if this flag is false, records will be "soft" deleted but not unloaded
  const UNLOAD_DELETED_RECORDS = false; // number of records to process pushing at a time

  const CHUNK_SIZE = 1000;

  const flattenData = data => {
    return Object.entries(data).reduce((arr, [modelName, records]) => {
      records.forEach(record => {
        arr.push({
          modelName,
          record
        });
      });
      return arr;
    }, []);
  };

  const logger = {};

  var _default = Ember.Service.extend(Ember.Evented, {
    socket: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    mapContext: Ember.inject.service(),
    startRefreshing: true,
    _structuredWorkspace: null,
    // keep track of newly pushed or removed records until `flushChanges`
    _queue: null,
    // used internally to manually invalidate data
    _invalidationCounter: 0,
    // keeps track of records that have been (or are pending) deletion
    _deletedLookup: null,

    init() {
      let me = this;

      this._super(...arguments);

      this.set('_queue', []);
      this.set('_deletedLookup', {});

      const active = _activeContextGraph.activeContextNodes.reduce((acu, node) => {
        acu[node.modelName] = [];
        return acu;
      }, {});

      this.set('_structuredWorkspace', active);
      this.get('loadAPIDataTask').perform(); // activates observer and triggers initial load

      if (_environment.default.APP.fullMode) {
        this.onDatesChange();
      }
    },

    reset() {
      this.trigger('change', _activeContextGraph.activeContextNodes.mapBy('modelName'));
      this.get('connectSocketsTask').perform();
    },

    // flattened for easy binding & iteration
    workspaceData: Ember.computed('_structuredWorkspace', function () {
      const structuredWorkspaceData = this.get('_structuredWorkspace');
      return flattenData(structuredWorkspaceData);
    }),
    // loads static data at application start
    loadAPIDataTask: (0, _emberConcurrency.task)(function* () {
      if (!_environment.default.APP.avlmLite) {
        const store = this.get('store');
        const loadedModelNames = [];

        const refresh = () => {
          this.trigger('change', loadedModelNames.slice());
          loadedModelNames.clear();
        }; // async loads can call refresh when each finishes


        _apiPreloads.apiPreloads.filterBy('async').forEach(({
          modelName
        }) => {
          return store.findAll(modelName).then(collection => {
            loadedModelNames.push(collection.modelName);
            Ember.run.debounce(this, refresh, 100);
          });
        }); // sync loads only refresh when all are done


        const syncPromises = _apiPreloads.apiPreloads.rejectBy('async').map(({
          modelName
        }) => {
          return store.findAll(modelName).then(collection => {
            loadedModelNames.push(collection.modelName);
          });
        });

        yield Ember.RSVP.all(syncPromises).then(collections => {
          loadedModelNames.pushObjects(collections.mapBy('modelName'));
          Ember.run.debounce(this, refresh, 100);
        });
      }
    }).drop(),
    connectSocketsTask: (0, _emberConcurrency.task)(function* () {
      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      (true && !(startTime < endTime) && Ember.assert('workspace startTime must predate endTime', startTime < endTime)); // this will wait for any sync topics before resolving

      yield this.get('socket').connect(startDate, endDate);
    }).keepLatest(),

    pushNewPayloads(payloads) {
      //this.get('store').pushPayload(payload);
      //payloads.forEach((payload) => this.pushPayload(payload));
      //this.get('store').push(payloads);
      //payloads.forEach((payload) => this.get('store').push(payload));
      // if(this.get('startRefeshing')) {
      //  this.get('reloadWorkspace').perform();
      //}
      payloads.forEach(payload => {
        if (payload !== undefined) this.get('store').pushPayload(payload.message);
      });
    },

    pushPayloads(payloads) {
      payloads.forEach(payload => this.pushPayload(payload));
    },

    pushPayload(payload) {
      this.get('_queue').push({
        action: 'push',
        payload
      });
    },

    removeRecord(modelName, id) {
      // this may not actually exist yet
      const record = this.get('store').peekRecord(modelName, id);
      const queue = this.get('_queue');
      queue.push({
        action: 'remove',
        modelName,
        id,
        record
      });

      if (record) {
        // mark isomorphic models for deletion
        // TODO: expand this to BFS so it works correctly on non-iq-models
        _activeContextGraph.activeContextNodes.findBy('modelName', modelName).links.filterBy('type', 'belongsTo') // should be redundant
        //.filterBy('isIsomorphism', true)
        .forEach(({
          path
        }) => {
          let relatedRecord = record.get(path);

          if (relatedRecord.hasOwnProperty('content')) {
            relatedRecord = relatedRecord.content;
          }

          if (relatedRecord) {
            queue.push({
              action: 'remove',
              modelName: relatedRecord.constructor.modelName,
              id: relatedRecord.get('id'),
              record: relatedRecord
            });
          }
        });
      }
    },

    processPayloadForMap(payload, store) {
      const type = payload.data.type;

      if (type === 'avl') {
        return true;
      }

      if (type === 'stop') {
        return true;
      }

      if (type === 'zone' || type === 'stand') {
        return true;
      }

      return false;
    },

    processTimeConstraints(leftTimeConstraint, rightTimeConstraint) {
      try {
        let leftTime, rightTime;
        const workspace = this.get('workspace');
        const startTime = workspace.get('startDate').getTime();
        const endTime = workspace.get('endDate').getTime();

        if (leftTimeConstraint) {
          const date = leftTimeConstraint;
          leftTime = Ember.isPresent(date) ? date.getTime() : null;
        }

        if (rightTimeConstraint) {
          const date = rightTimeConstraint;
          rightTime = Ember.isPresent(date) ? date.getTime() : null;
        }

        if (Ember.isPresent(leftTime) && Ember.isPresent(rightTime)) {
          if (leftTime <= endTime && rightTime >= startTime) return true;
        }
      } catch (e) {}

      return false;
    },

    reloadWorkspace: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      let struc = this.get('_structuredWorkspace');
      yield struc = _activeContextGraph.activeContextNodes.reduce((obj, node) => {
        const peekedRecords = store.peekAll(node.modelName);
        let filteredRecords;

        if (node.isActive && this.get('startRefreshing')) {
          filteredRecords = peekedRecords.filter(record => {
            //TODO
            // let bRemoved = false;
            //bRemoved = this.isRecordRemoved(record);
            // if (!bRemoved) {
            if (this.get('activeContext.serverFilteringChecked')) {
              return true;
            }

            if (record.constructor.modelName === 'rider' || record.constructor.modelName === 'account') {
              const workspace = this.get('workspace');
              const startTime = workspace.get('startDate').getTime();
              const endTime = workspace.get('endDate').getTime();
              let tripsWithActiveContext;

              if (!Ember.isEmpty(record.get('trips'))) {
                tripsWithActiveContext = record.get('trips').filter(function (item) {
                  return startTime < item.get('requestedTime') && endTime > item.get('requestedTime');
                });
              }

              if (!Ember.isEmpty(tripsWithActiveContext)) {
                return true;
              } else {
                return false;
              }
            } else if (node.leftTimeConstraint !== undefined && node.rightTimeConstraint !== undefined) {
              return this.processTimeConstraints(record.get(node.leftTimeConstraint), record.get(node.rightTimeConstraint));
            } else {
              return true;
            } //}
            //return false;

          });
        } else {
          filteredRecords = peekedRecords.toArray();
        }

        obj[node.modelName] = filteredRecords;
        return obj;
      }, {});
      this.set('_structuredWorkspace', struc);
    }).keepLatest(),

    isRecordRemoved(record) {
      switch (record.constructor.modelName) {
        case 'trip':
        case 'iq-trip':
        case 'stop':
        case 'iq-stop':
          return record.get('status') === 'Removed';

        default:
          return false;
      }
    },

    flushChanges() {
      const store = this.get('store');
      const queue = this.get('_queue');
      const mapContext = this.get('mapContext');
      let updateMapContext = false;
      if (Ember.isEmpty(queue)) return;
      const pushModelNames = queue.filterBy('action', 'push').mapBy('payload.data.type').uniq();
      const removeModelNames = queue.filterBy('action', 'remove').mapBy('record.constructor.modelName').uniq(); // these model types might not need to be refreshed

      const countModelNames = pushModelNames.reject(modelName => {
        // any model type for which a record is removed _must_ be refreshed after
        return removeModelNames.includes(modelName);
      }); // count these types before processing queue

      const recordCount = countModelNames.reduce((obj, modelName) => {
        obj[modelName] = store.peekAll(modelName).length;
        return obj;
      }, {});
      const deletedLookup = this.get('_deletedLookup');
      const killQueue = [];
      let t = new Date().getTime();
      const chunkSize = Math.min(queue.length, CHUNK_SIZE);
      const chunk = [];

      for (let i = 0; i < chunkSize; i++) {
        chunk.push(queue.shift());
      }

      chunk.forEach(async (item
      /*, i*/
      ) => {
        switch (item.action) {
          case 'push':
            {
              updateMapContext = true; //this.processPayloadForMap(item.payload, store);

              store.push(item.payload);
              break;
            }

          case 'remove':
            {
              // mark record as deleted so that widgets can pull new data without it
              let record = item.record;

              if (!record) {
                record = store.peekRecord(item.modelName, item.id);
              }

              if (record) {
                killQueue.push(item.record);
              } // this allow for faster filtering later


              const key = `${item.modelName}:${item.id}`;
              deletedLookup[key] = true;
              break;
            }

          default:
            break;
        }
      });
      this.get('reloadWorkspace').perform();

      if (updateMapContext) {
        mapContext.trigger('refresh');
      }

      const refreshModelNames = removeModelNames.slice();
      Object.entries(recordCount).forEach(([modelName, count]) => {
        const newCount = store.peekAll(modelName).length;

        if (newCount > count) {
          refreshModelNames.push(modelName);
        }
      });

      if (refreshModelNames.length > 0) {
        this.trigger('change', refreshModelNames);
      }

      if (UNLOAD_DELETED_RECORDS) {
        t = new Date().getTime();
        Ember.run.schedule('afterRender', () => {
          killQueue.forEach(record => {
            store.unloadRecord(record);
          });
        }); //logger.debug(`unloaded ${killQueue.length} records`, t);
      }

      Ember.run.next(() => {
        Ember.run.schedule('afterRender', this, 'flushChanges');
      });
    },

    onDatesChange: Ember.observer('workspace.{startDate,endDate}', function () {
      Ember.run.scheduleOnce('afterRender', this, 'reset');
      this.get('reloadWorkspace').perform();
      this.get('mapContext').trigger('refresh');
    })
  });

  _exports.default = _default;
});