define("adept-iq/pods/components/iq-widgets/stops-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/stops-widget',
    rowComponent: 'table/rows/status-formatted-row',
    modelName: 'stop',
    defaultSortId: 'trip.id',
    defaultSortAsc: false,
    accessKey: 's',
    title: 'Stops',
    columns: [{
      id: 'tripId',
      type: 'number',
      label: 'Trip ID',
      valuePath: 'trip.id',
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 60,
      isMapMCGLabelVisible: true
    }, {
      id: 'type',
      type: 'text',
      label: 'Type',
      valuePath: 'type',
      valuePreview: 'Pick',
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 50
    }, {
      id: 'name',
      type: 'text',
      label: 'Passenger Name',
      isMapLabelVisible: true,
      valuePath: 'passengerName',
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'riderPhone',
      type: 'text',
      label: 'Phone Number',
      valuePath: 'trip.rider.phoneNr',
      hidden: false,
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'status',
      type: 'text',
      label: 'Status',
      isMapLabelVisible: true,
      valuePath: 'trip.drivedStatus',
      highlightable: true,
      searchable: true,
      editable: false,
      defaultWidth: 60
    }, {
      id: 'requestedTime',
      type: 'time',
      label: 'Pickup Time',
      isMapLabelVisible: true,
      valuePath: 'trip.requestedTime',
      editable: false,
      format: 'HH:mm A',
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'address',
      type: 'text',
      label: 'Address',
      isMapLabelVisible: true,
      valuePath: 'place.streetAddress',
      editable: false,
      searchable: true,
      hidden: false,
      defaultWidth: 100
    }, {
      id: 'pickupCity',
      type: 'text',
      label: 'Pickup City',
      isMapLabelVisible: true,
      valuePath: 'place.region',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'notes',
      type: 'text',
      label: 'Notes',
      valuePath: 'drivernotes',
      editable: false,
      searchable: true,
      hidden: true,
      defaultWidth: 100
    }, {
      id: 'calloutType',
      type: 'text',
      label: 'Callout',
      valuePath: 'calloutType',
      editable: false,
      searchable: true,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'invoiceRefNum',
      type: 'text',
      label: 'PO#',
      valuePath: 'invoiceRefNum',
      editable: false,
      searchable: true,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'fare',
      type: 'number',
      label: 'Fare',
      valuePath: 'fare',
      editable: false,
      searchable: true,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'bookingId',
      type: 'number',
      label: 'Booking ID',
      valuePath: 'bookingId',
      editable: false,
      searchable: true,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'zone',
      type: 'text',
      label: 'Zone',
      isMapLabelVisible: true,
      valuePath: 'trip.zone.name',
      editable: false,
      searchable: true,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'attributes',
      type: 'text',
      label: 'Attributes',
      valuePath: 'trip.attributes',
      searchable: true,
      editable: false,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'callSign',
      type: 'text',
      label: 'Callsign',
      valuePath: 'trip.vehicle.callsign',
      searchable: true,
      isMapLabelVisible: true
    }]
  };
  _exports.default = _default;
});