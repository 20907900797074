define("adept-iq/mixins/trip-predefined-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // eslint-disable-next-line ember/use-brace-expansion
    predefinedNotes: Ember.computed('account', 'attributes.[]', 'editmodelcontext.predefinedDriverNotes.account', 'editmodelcontext.predefinedDriverNotes.address', 'editmodelcontext.predefinedDriverNotes.area', 'editmodelcontext.predefinedDriverNotes.cli', 'editmodelcontext.predefinedDriverNotes.editTrip', 'editmodelcontext.predefinedOperatorNotes.account', 'editmodelcontext.predefinedOperatorNotes.address', 'editmodelcontext.predefinedOperatorNotes.area', 'editmodelcontext.predefinedOperatorNotes.cli', 'editmodelcontext.predefinedOperatorNotes.editTrip', function () {
      //Account,cli,Address,unit, building,region.area and attribute  could have driver/operator notes associated with.
      const notesSeparator = '\\';
      let driverNotes = [];
      let operatorNotes = []; //Attributes Driver and operatornotes

      if (!Ember.isBlank(this.get('attributes'))) {
        // Attribute notes population
        this.get('attributes').forEach(function (item) {
          if (!Ember.isBlank(item.get('notes.driver'))) {
            driverNotes.push(item.get('notes.driver'));
          }

          if (!Ember.isBlank(item.get('notes.dispatcher'))) {
            operatorNotes.push(item.get('notes.dispatcher'));
          }
        });
      } //Driver Notes population


      if (!Ember.isBlank(this.get('editmodelcontext.predefinedDriverNotes.account'))) {
        driverNotes.push(this.get('editmodelcontext.predefinedDriverNotes.account'));
      }

      if (!Ember.isBlank(this.get('editmodelcontext.predefinedDriverNotes.cli'))) {
        driverNotes.push(this.get('editmodelcontext.predefinedDriverNotes.cli'));
      }

      if (!Ember.isBlank(this.get('editmodelcontext.predefinedDriverNotes.address'))) {
        driverNotes.push(this.get('editmodelcontext.predefinedDriverNotes.address'));
      }

      if (!Ember.isBlank(this.get('editmodelcontext.predefinedDriverNotes.unit'))) {
        driverNotes.push(this.get('editmodelcontext.predefinedDriverNotes.unit'));
      }

      if (!Ember.isBlank(this.get('editmodelcontext.predefinedDriverNotes.building'))) {
        driverNotes.push(this.get('editmodelcontext.predefinedDriverNotes.building'));
      }

      if (!Ember.isBlank(this.get('editmodelcontext.predefinedDriverNotes.area'))) {
        driverNotes.push(this.get('editmodelcontext.predefinedDriverNotes.area'));
      }

      if (!Ember.isBlank(this.get('editmodelcontext.predefinedDriverNotes.region'))) {
        driverNotes.push(this.get('editmodelcontext.predefinedDriverNotes.region'));
      }

      if (!Ember.isBlank(this.get('editmodelcontext.predefinedDriverNotes.editTrip'))) {
        driverNotes.push(this.get('editmodelcontext.predefinedDriverNotes.editTrip'));
      } //Operator Notes population


      if (!Ember.isBlank(this.get('editmodelcontext.predefinedOperatorNotes.account'))) {
        operatorNotes.push(this.get('editmodelcontext.predefinedOperatorNotes.account'));
      }

      if (!Ember.isBlank(this.get('editmodelcontext.predefinedOperatorNotes.cli'))) {
        operatorNotes.push(this.get('editmodelcontext.predefinedOperatorNotes.cli'));
      }

      if (!Ember.isBlank(this.get('editmodelcontext.predefinedOperatorNotes.address'))) {
        operatorNotes.push(this.get('editmodelcontext.predefinedOperatorNotes.address'));
      }

      if (!Ember.isBlank(this.get('editmodelcontext.predefinedOperatorNotes.unit'))) {
        operatorNotes.push(this.get('editmodelcontext.predefinedOperatorNotes.unit'));
      }

      if (!Ember.isBlank(this.get('editmodelcontext.predefinedOperatorNotes.building'))) {
        operatorNotes.push(this.get('editmodelcontext.predefinedOperatorNotes.building'));
      }

      if (!Ember.isBlank(this.get('editmodelcontext.predefinedOperatorNotes.area'))) {
        operatorNotes.push(this.get('editmodelcontext.predefinedOperatorNotes.area'));
      }

      if (!Ember.isBlank(this.get('editmodelcontext.predefinedOperatorNotes.region'))) {
        operatorNotes.push(this.get('editmodelcontext.predefinedOperatorNotes.region'));
      }

      if (!Ember.isBlank(this.get('editmodelcontext.predefinedOperatorNotes.editTrip'))) {
        operatorNotes.push(this.get('editmodelcontext.predefinedOperatorNotes.editTrip'));
      }

      return {
        driverNotes: driverNotes.join(notesSeparator),
        operatorNotes: operatorNotes.join(notesSeparator)
      };
    })
  });

  _exports.default = _default;
});