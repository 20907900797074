define("adept-iq/models/rider", ["exports", "ember-data", "adept-iq/utils/name-parser", "ember-data/relationships", "adept-iq/config/environment"], function (_exports, _emberData, _nameParser, _relationships, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  function formatTextExtension(className, value) {
    return {
      className: className,
      value: value
    };
  }

  var _default = Model.extend({
    riderId: attr('string'),
    name: attr('string'),
    firstName: attr('string'),
    middleName: attr('string'),
    lastName: attr('string'),
    areaCode: attr('string', {
      defaultValue: ''
    }),
    phoneNr: attr('string', {
      defaultValue: ''
    }),
    extension: attr('string'),
    attributes: attr('string'),
    driverNotes: attr('string'),
    operatorNotes: attr('string'),
    calloutType: attr('string'),
    notes: attr('string'),
    email: attr('string'),
    trips: hasMany('trip'),
    callLines: hasMany('call-line'),
    requestTime: attr('date'),
    equipments: Ember.computed.filterBy('travelNeeds', 'isEquipment', true),
    extraPassengers: Ember.computed.filterBy('travelNeeds', 'isExtraPassenger', true),
    primaryAddresses: hasMany('address', {
      inverse: 'ridersPrimary'
    }),
    formattedAccount: Ember.computed('callLines', function () {
      const accounts = [];
      this.get('callLines').forEach(function (item) {
        const account = item.get('account');

        if (!Ember.isEmpty(account) && !Ember.isBlank(account.get('acctCode'))) {
          accounts.push(formatTextExtension('valueItem', `${account.get('acctCode')} ${account.get('acctCardNr')}`));
        }
      });
      return {
        values: accounts
      };
    }),
    formattedPickupAddresses: Ember.computed('callLines', function () {
      let addresses = [];
      this.get('callLines').forEach(function (item) {
        const pickupAddress = item.get('address');

        if (!Ember.isBlank(pickupAddress)) {
          //if(pickupAddress.get('streetNr') !== undefined && pickupAddress.get('streetName') !== undefined) {
          addresses.push(formatTextExtension('valueItem', `${pickupAddress.get('streetNr')} ${pickupAddress.get('streetName')} ${pickupAddress.get('region')}`)); //}  
        }
      });
      return {
        values: addresses
      };
    }),
    fullPhoneNumber: Ember.computed('areaCode', 'phoneNr', 'extension', {
      get()
      /* key */
      {
        const re = /\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})/g;
        const subst = '$1 $2-$3';
        const str = `${Ember.get(this, 'areaCode')} ${Ember.get(this, 'phoneNr')}`;
        return str.replace(re, subst);
      },

      set(_, value) {
        value = value.trim();
        this.set('areaCode', value.substr(0, 3));
        this.set('phoneNr', value.substr(4, 10).replace(/-/g, ""));
        return value;
      }

    }),
    fullName: Ember.computed('firstName', 'middleName', 'lastName', {
      get()
      /* key*/
      {
        let middleName = Ember.get(this, 'middleName');

        if (Ember.isBlank(middleName)) {
          middleName = ' ';
        } else {
          middleName = ` ${middleName} `;
        }

        return `${Ember.get(this, 'firstName')}${middleName}${Ember.get(this, 'lastName') || ''}`.trim();
      },

      set(key, value) {
        const nameParts = _nameParser.default.parse(value);

        Ember.set(this, 'firstName', nameParts.firstName);
        Ember.set(this, 'middleName', nameParts.middleName);
        Ember.set(this, 'lastName', nameParts.lastName);
        return value;
      }

    }),
    phone: Ember.computed('', function () {
      let rider = this.get('iqRider.rmsRider');

      if (rider) {
        return rider.get('fullPhoneNumber');
      }
    }),

    /*workspacetrips: computed('trips@each', function() {
       if(!isEmpty(this.get('trips'))) {
          return this.get('trips').filter(function(item) {
             return (ENV.workspaceContext.startDate < item.get('requestTime') &&
                      (ENV.workspaceContext.endDate > item.get('requestTime')));
        });
      } else {
        return [];
      }
    }),
     sortedtrips :computed('workspacetrips.[]',function()
    {
      return this.get('workspacetrips').sortBy('requestTime');
    }),
     latesttripDate: computed('sortedtrips.[]', function() {
      if(!isEmpty(this.get('sortedtrips'))) {
        let sortedtrips = this.get('sortedtrips');
        return sortedtrips.lastObject.get('requestTime');
      }
      return null;
    }),
    firsttripDate: computed('sortedtrips.[]', function() {
      if(!isEmpty(this.get('sortedtrips'))) {
        let sortedtrips = this.get('sortedtrips');
        return sortedtrips.firstObject.get('requestTime');
      }
      return null;
    })  ,*/
    travelNeeds: hasMany('rms-rider-travel-need')
  });

  _exports.default = _default;
});