define("adept-iq/topic-transforms/navigation-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  function transform(store, payload, logger) {
    const routeId = payload.data.id;
    const navigationPolyline = payload.data.attributes.polyline; // route will be created if non-existent, so show a warning

    const route = store.peekRecord('route', routeId);

    if (!route) {
      logger.warn(`received navigationInfo for unknown route '${routeId}'`);
    }

    return {
      data: {
        id: routeId,
        type: 'route',
        attributes: {
          navigationPolyline
        }
      }
    };
  }
});