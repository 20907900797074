define("adept-iq/pods/components/table/cells/check-box/component", ["exports", "adept-iq/pods/components/table/cells/base-cell/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['table-check-box-cell', 'data-test-table-check-box-cell'],
    activeContext: Ember.inject.service(),
    isChecked: Ember.computed.alias('row.isChecked'),
    actions: {
      onToggleCheckbox(event) {
        //console.log('event.target.checked ',event.target.checked);
        //console.log('activeContext.serverFilteringEnabled',this.get('activeContext.serverFilteringEnabled'));
        if (this.get('activeContext.serverFilteringEnabled')) {
          this.get('activeContext').set('serverFilteringChecked', event.target.checked);
        } //console.log('activeContext.serverFilteringChecked',this.get('activeContext.serverFilteringChecked'));


        const row = this.get('row');
        const rows = this.get('table.rows');
        const lastCheckedRow = rows.findBy('isLastChecked', true);
        const affectedRows = [];

        if (event.shiftKey && lastCheckedRow) {
          const lastCheckedIndex = rows.indexOf(lastCheckedRow);
          const checkedIndex = rows.indexOf(row);
          const start = Math.min(checkedIndex, lastCheckedIndex);
          const end = Math.max(checkedIndex, lastCheckedIndex);

          for (let i = start; i <= end; i++) {
            rows.objectAt(i).set('isChecked', event.target.checked);
            affectedRows.push(rows.objectAt(i));
          }
        } else {
          row.set('isChecked', event.target.checked);
          affectedRows.push(row);
        }

        rows.setEach('isLastChecked', false);
        row.set('isLastChecked', true);
      }

    }
  });

  _exports.default = _default;
});