define("adept-iq/pods/components/iq-widgets/search-trip-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/search-trip-widget/config", "adept-iq/utils/populate-new-trip", "adept-iq/utils/populate-copy-trip", "adept-iq/utils/populate-edit-trip", "adept-iq/utils/populate-search-trip", "adept-iq/utils/populate-account", "adept-iq/utils/check-address-flags", "adept-iq/utils/populate-call-line", "adept-iq/utils/focus_column_widget_row", "adept-iq/config/api-urls", "adept-iq/utils/check-value"], function (_exports, _component, _config, _populateNewTrip, _populateCopyTrip, _populateEditTrip, _populateSearchTrip, _populateAccount, _checkAddressFlags, _populateCallLine, _focus_column_widget_row, _apiUrls, _checkValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['search-trip-widget'],
    store: Ember.inject.service(),
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    trip: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    searchservice: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    session: Ember.inject.service(),
    routeexecevent: Ember.inject.service(),
    textmessage: Ember.inject.service(),
    geocode: Ember.inject.service(),
    config: _config.default,
    defaultIncludes: INCLUDES,
    accountSearchPath: _apiUrls.API_SUB_URL.accountSearchPath,
    noShowComponent: 'iq-widgets/noshow-event-form-widget',
    editTripComponent: 'iq-widgets/edit-trip-form-widget',
    newTripComponent: 'iq-widgets/new-trip-form-widget',
    cancelTripComponent: 'iq-widgets/cancel-type-form-widget',
    assignTrip2RouteComponent: 'iq-widgets/assign-trip-2-route-widget',
    activityLogComponent: 'iq-widgets/activity-log-widget',
    riderAddresses: null,

    init() {
      this._super(...arguments);

      let recordId = 1;
      console.log('this.get(widgetModelData) ', this.get('widgetModelData'));
      this.get('widgetModelData').forEach(record => {
        console.log('widgetModelData ', record.id);
      });
      this.set('records', this.get('widgetModelData'));
      let me = this;
      Ember.run.later(function () {
        (0, _focus_column_widget_row.default)(me);
      }, 50);
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({});
      this.set('singleActions', []);
    },

    saveTripRecordFunction(records, editComponentAction) {
      console.log(' saveTripRecordFunction ', editComponentAction);
      const me = this;
      return new Promise((resolve, reject) => {
        let record = records.firstObject;
        record.save().then(() => {
          console.log('after saveTripRecordFunction ');
          const tripId = record.get('id');
          me.get('errorMessage').pushInfo({
            detail: `Trip '${tripId}' successfully saved`
          });
          record.set('lastId', record.get('id'));
          record.set('isForceDirty', false);

          if (!Ember.isBlank(editComponentAction) && editComponentAction === 'onApplyClick') {
            Ember.run(() => {
              let recordRefresh = [];
              let newtrip = (0, _populateEditTrip.default)(me.get('trip'), record, me.get('store'), me.get('geocode'));
              (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, false);
              recordRefresh.push(newtrip);
              let newTripComponent = me.get('editSwitchTripComponent');

              if (this.get('editModal.editComponent') !== null && this.get('editModal.editComponent') === this.get('editSwitchTripComponent')) {
                newTripComponent = me.get('editTripComponent');
              }

              me.get('editModal').open(newTripComponent, recordRefresh, me.get('saveTripRecordFunction').bind(me));
            });
          } else if (!Ember.isBlank(editComponentAction) && editComponentAction === 'onApplyClearClick') {
            Ember.run(() => {
              Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
              me.get('geocode').activateGeocode(false);
              me.get('geocode').deactivateGeocode();
              let recordRefresh = [];
              let newtrip = (0, _populateNewTrip.default)(me.get('trip'), me.get('store'));
              (0, _populateAccount.default)(newtrip, null, me.get('geocode'), me.get('store'), me.get('editmodalcontext'), me.get('searchservice'), me.get('accountSearchPath'), newtrip.acctCardNr, false);
              newtrip.set('lastId', record.get('lastId'));
              recordRefresh.push(newtrip); //me.set('editableRecords',recordRefresh);
              //me.set('records',recordRefresh);

              let newTripComponent = me.get('newSwitchTripComponent');

              if (me.get('editModal.editComponent') !== null && me.get('editModal.editComponent') === me.get('newSwitchTripComponent')) {
                newTripComponent = me.get('newTripComponent');
              }

              me.get('editModal').open(newTripComponent, recordRefresh, me.get('saveTripRecordFunction').bind(me));
            });
          }
        }).catch(function (error) {
          console.log(error);
          reject(error);
        });
      });
    },

    actions: {
      onRowRightClick(row, event) {},

      closeWidget(event) {
        this._super(...arguments);
      },

      onRowClick(row, event) {
        let widgetModelData = row.get('record');
        this.closeWidget();
        let tripData = (0, _populateEditTrip.default)(this.get('trip'), widgetModelData, this.get('store'), this.get('geocode'));
        (0, _populateAccount.default)(tripData, null, this.get('geocode'), this.get('store'), this.get('editmodalcontext'), this.get('searchservice'), this.get('accountSearchPath'), widgetModelData.acctCardNr, false); //tripRecord = widgetModelData;    
        //this.set('editModal.editableRecords',[]);
        //this.set('editModal.editableRecords',[widgetModelData]);

        this.set('editModal.editableRecords', []);
        this.get('editModal.editableRecords').pushObject(widgetModelData);
        this.store.findRecord('tm-route-exec', widgetModelData.id, {
          reload: true
        }).then(routeExec => {
          tripData.set('routeExec', routeExec);
          let editTripComponent = this.get('editTripComponent');

          if (this.get('editModal.editComponent') !== null && this.get('editModal.editComponent') === this.get('editTripComponent')) {
            editTripComponent = this.get('editSwitchTripComponent');
          }

          this.get('editModal').open(editTripComponent, [tripData], this.get('saveTripRecordFunction').bind(this));
        });
      }

    }
  });

  _exports.default = _default;
});