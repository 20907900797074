define("adept-iq/pods/dashboard/modals/edit-form/controller", ["exports", "adept-iq/utils/populate-new-trip", "adept-iq/utils/populate-account", "adept-iq/config/api-urls"], function (_exports, _populateNewTrip, _populateAccount, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RESIZE_CURSOR_OFFSET = -2;
  const MAX_WIDTH = 800;
  const RESIZE_DEBOUNCE_TIME = 250;

  var _default = Ember.Controller.extend({
    editModal: Ember.inject.service(),
    editmodalcontext: Ember.inject.service(),
    editableRecords: Ember.computed.alias('editModal.editableRecords'),
    showTripFormPopup: Ember.computed.alias('editModal.showTripFormPopup'),
    editComponent: Ember.computed.alias('editModal.editComponent'),
    showEditPopup: true,
    accountSearchPath: _apiUrls.API_SUB_URL.accountSearchPath,
    trip: Ember.inject.service(),
    geocode: Ember.inject.service(),
    store: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    searchservice: Ember.inject.service(),
    ajax: Ember.inject.service(),
    errorMessage: Ember.inject.service(),
    session: Ember.inject.service(),

    init() {
      this._super(...arguments);

      console.log('editComponent ', this.get('editComponent'));
      console.log('showTripFormPopup ', this.get('showTripFormPopup'));

      if ((this.get('editComponent') === 'iq-widgets/new-trip-fixform-widget' || this.get('editComponent') === 'iq-widgets/edit-trip-form-widget' || this.get('editComponent') === 'iq-widgets/new-trip-form-widget') && !this.get('showTripFormPopup')) {
        this.set('showEditPopup', false);
      }

      this._onResize = () => {
        if (this.get('isDragging') || this.get('isResizing')) return;
        Ember.run.debounce(this, 'resizedWindow', RESIZE_DEBOUNCE_TIME);
      };

      this._resizeObserver = new ResizeObserver(this._onResize);

      this._resizeObserver.observe(Ember.$('body')[0]);

      Ember.$('.modal-dialog').draggable();
    },

    resizedWindow() {
      let position = Ember.$('.edit-widget-modal').width();
      let coords = {
        x: position
      };

      if (this.get('isDestroyed')) {
        return;
      }

      this.send('resizeModal', coords);
    },

    willDestroyElement() {
      this._resizeObserver.disconnect();

      this._resizeObserver = null;
      this._onResize = null;
    },

    actions: {
      resizeModal(position) {
        let windowWidth = Ember.$(window).width(); // Resize cursor offset ensures cursor is centered on handle.

        let modalWidth = windowWidth - position.x + RESIZE_CURSOR_OFFSET;
        Ember.run.scheduleOnce('afterRender', () => {
          if (windowWidth - modalWidth >= MAX_WIDTH) {
            Ember.$('.edit-widget-modal').css('right', `${windowWidth - MAX_WIDTH}px`);
          } else {
            Ember.$('.edit-widget-modal').css('right', `${modalWidth}px`);
          }
        });
      },

      closeWidget() {
        this.toggleProperty('editmodalcontext.showWidgetModal');
      }

    }
  });

  _exports.default = _default;
});