define("adept-iq/pods/components/iq-widgets/cancel-type-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        index: 3,
        type: 'uuid',
        label: 'ID',
        valuePath: 'id',
        valuePreview: '1',
        hidden: false,
        isMapLabelVisible: true,
        searchable: true,
        defaultWidth: 60
      }, {
        id: 'lastId',
        type: 'text',
        label: 'ID',
        valuePath: 'lastId',
        editable: false
      }]
    }, {
      title: 'Editable',
      fields: [{
        id: 'notes',
        type: 'text',
        editable: true,
        label: 'Notes',
        valuePath: 'cancelNotes'
      }]
    }]
  };
  _exports.default = _default;
});