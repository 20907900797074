define("adept-iq/utils/populate-edit-trip", ["exports", "adept-iq/utils/check-address-flags", "adept-iq/utils/check-value", "lodash", "moment"], function (_exports, _checkAddressFlags, _checkValue, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = populateEditTrip;
  const TIME_ZONE = 'America/Los_Angeles';

  function populateEditTripData(tripTemplate, updatetrip, store, geocode) {
    let tripData = tripTemplate.tripData().data.attributes; //Account, attribute, cli, building and unit could have driver/operator notes associated with.
    //Account, area,cli, building and region could have attributes associated with.

    console.log('populateEditTripData tripData ', tripData); //Helper UI model

    updatetrip.set('editmodelcontext', {
      callerTrips: [],
      assigns: [],
      swapAddressDisabled: true,
      saveCLIDisabled: true,
      driverNotes: '',
      operatorNotes: '',
      attributes: {
        account: [],
        cli: [],
        building: [],
        area: [],
        region: []
      },
      predefinedDriverNotes: {
        account: '',
        cli: '',
        address: '',
        area: '',
        building: '',
        attribute: '',
        unit: '',
        editTrip: ''
      },
      predefinedOperatorNotes: {
        account: '',
        cli: '',
        address: '',
        area: '',
        building: '',
        attribute: '',
        unit: '',
        editTrip: ''
      },
      editAttributes: {
        account: [],
        cli: [],
        building: [],
        area: [],
        region: [],
        editTrip: []
      }
    });
    updatetrip.set('source', tripData.source); //passenger

    if (Ember.isBlank(updatetrip.passengers[0].phone1)) {
      try {
        Ember.set(updatetrip.passengers[0], 'phone1', tripData.passengers[0].phone1);
      } catch (e) {
        updatetrip.passengers[0].phone1 = tripData.passengers[0].phone1;
      }
    }

    console.log('updatetrip.stopPoints.length ', updatetrip.stopPoints.length); //Attributes

    let options = store.peekAll('tm-common-operational-attribute');
    let requiredAttributes = updatetrip.passengers[0].requiredAttributes;
    console.log('inside edit trip ', requiredAttributes);
    let assigns = options.filter(function (o1) {
      // if match found return false
      return _lodash.default.findIndex(requiredAttributes, {
        'type': o1.displayName
      }) === -1 ? false : true;
    });

    if (!Ember.isEmpty(requiredAttributes) && Ember.isEmpty(assigns)) {
      assigns = options.filter(function (o1) {
        // if match found return false
        return _lodash.default.findIndex(requiredAttributes, {
          'type': o1.type
        }) === -1 ? false : true;
      });
    }

    console.log('inside edit trip assigns', assigns);
    updatetrip.set('manualAttributes', assigns); //updatetrip.set('driverNotes', updatetrip.stopPoints[0].passengerEvents[0].driverNote);
    //updatetrip.set('operatorNotes',updatetrip.passengers[0].bookingNote);
    //Notes

    try {
      if (updatetrip.stopPoints[0].passengerEvents[0].driverNote && updatetrip.stopPoints[0].passengerEvents[0].driverNote.indexOf('\\') !== -1 && updatetrip.get('predefinedNotes.driverNotes') !== updatetrip.stopPoints[0].passengerEvents[0].driverNote) {
        updatetrip.set('driverNotes', updatetrip.stopPoints[0].passengerEvents[0].driverNote);
      }

      if (updatetrip.stopPoints[0].passengerEvents[0].driverNote && updatetrip.stopPoints[0].passengerEvents[0].driverNote.indexOf('<>') !== -1) {
        let manualDriverNotes = updatetrip.stopPoints[0].passengerEvents[0].driverNote.substr(0, updatetrip.stopPoints[0].passengerEvents[0].driverNote.indexOf('<>'));
        manualDriverNotes = manualDriverNotes.replace('<>', '');
        updatetrip.set('driverNotes', manualDriverNotes);
        let autoPopulateNotes = updatetrip.stopPoints[0].passengerEvents[0].driverNote.substr(updatetrip.stopPoints[0].passengerEvents[0].driverNote.indexOf('<>'));
        autoPopulateNotes = autoPopulateNotes.replace('<>', '');
        updatetrip.set('editmodelcontext.predefinedDriverNotes.editTrip', autoPopulateNotes);
      }
    } catch (e) {}

    try {
      if (updatetrip.passengers[0].bookingNote && updatetrip.passengers[0].bookingNote.indexOf('\\') !== -1 && updatetrip.get('predefinedNotes.operatorNotes') !== updatetrip.passengers[0].bookingNote) {
        updatetrip.set('operatorNotes', updatetrip.passengers[0].bookingNote);
      }

      if (updatetrip.passengers[0].bookingNote && updatetrip.passengers[0].bookingNote.indexOf('<>') !== -1) {
        let manualOperatorNotes = updatetrip.passengers[0].bookingNote.substr(0, updatetrip.passengers[0].bookingNote.indexOf('<>'));
        manualOperatorNotes = manualOperatorNotes.replace('<>', '');
        updatetrip.set('operatorNotes', manualOperatorNotes);
        let autoPopulateNotes = updatetrip.passengers[0].bookingNote.substr(updatetrip.passengers[0].bookingNote.indexOf('<>'));
        autoPopulateNotes = autoPopulateNotes.replace('<>', '');
        updatetrip.set('editmodelcontext.predefinedOperatorNotes.editTrip', autoPopulateNotes);
      }
    } catch (e) {} //Accounts


    let defaultCardNr = 'CASH';
    let PFCP_GENERAL_CASH_ACCOUNT_NAME = store.peekAll('tm-config-parameter-value').filterBy('configParameter.id', 'PFCP_GENERAL_CASH_ACCOUNT_NAME');

    if (!Ember.isBlank(PFCP_GENERAL_CASH_ACCOUNT_NAME)) {
      defaultCardNr = PFCP_GENERAL_CASH_ACCOUNT_NAME[0].get('value');
    }

    try {
      if (!Ember.isBlank(updatetrip.prices) && !Ember.isBlank(updatetrip.prices[0])) {
        defaultCardNr = updatetrip.prices[0].payerIdentifier;
      } else {
        defaultCardNr = updatetrip.price.payerIdentifier;
      }
    } catch (e) {
      console.log(e);
    }

    updatetrip.set('acctCardNr', defaultCardNr);
    updatetrip.set('lastId', updatetrip.get('id'));
    let fixedAmount;

    try {
      if (!Ember.isBlank(updatetrip.prices) && !Ember.isBlank(updatetrip.prices[0])) {
        defaultCardNr = updatetrip.prices[0].fixed;
      } else {
        defaultCardNr = updatetrip.price.fixed;
      }
    } catch (e) {
      console.log(e);
    }

    if (!Ember.isBlank(fixedAmount)) {
      updatetrip.set('fixedAmount', fixedAmount);
    }

    for (let counter = 0; counter < updatetrip.stopPoints.length; counter++) {
      try {
        Ember.set(updatetrip.stopPoints[counter].address.tomtomFormattedAddress.address, 'freeformAddress', (0, _checkValue.default)(updatetrip.stopPoints[counter].address.streetNr) + " " + (0, _checkValue.default)(updatetrip.stopPoints[counter].address.streetName) + " " + (0, _checkValue.default)(updatetrip.stopPoints[counter].address.region));
      } catch (e) {
        updatetrip.stopPoints[counter].address.tomtomFormattedAddress = {
          address: {
            freeformAddress: (0, _checkValue.default)(updatetrip.stopPoints[counter].address.streetNr) + " " + (0, _checkValue.default)(updatetrip.stopPoints[counter].address.streetName) + " " + (0, _checkValue.default)(updatetrip.stopPoints[counter].address.region)
          }
        };
      }

      if (!Ember.isBlank(updatetrip.stopPoints[counter].address) && !Ember.isBlank(updatetrip.stopPoints[counter].address.coord) && updatetrip.stopPoints[counter].address.coord.lat !== 0 && updatetrip.stopPoints[counter].address.coord.lng !== 0 && updatetrip.stopPoints[counter].address.coord.lat !== 181 && updatetrip.stopPoints[counter].address.coord.lng !== 181 && updatetrip.stopPoints[counter].address.coord.lat !== -181 && updatetrip.stopPoints[counter].address.coord.lng !== -181 && !Ember.isBlank(updatetrip.stopPoints[counter].address.coord.lat) && !Ember.isBlank(updatetrip.stopPoints[counter].address.coord.lng)) {
        try {
          geocode.getGeonode(updatetrip.stopPoints[counter].address.coord.lat, updatetrip.stopPoints[counter].address.coord.lng).then(result => {
            const zoneData = store.peekRecord('zone', result.data.gpsAreaId);
            Ember.set(updatetrip.stopPoints[counter].address, 'geoNode', zoneData.get('name'));
          });
        } catch (e) {
          console.log(e);
        }
      }

      try {
        Ember.set(updatetrip.stopPoints[counter].address, 'formattedAddress', (0, _checkValue.default)(updatetrip.stopPoints[counter].address.streetNr) + " " + (0, _checkValue.default)(updatetrip.stopPoints[counter].address.streetName) + " " + (0, _checkValue.default)(updatetrip.stopPoints[counter].address.region));
      } catch (e) {
        updatetrip.stopPoints[counter].address.formattedAddress = (0, _checkValue.default)(updatetrip.stopPoints[counter].address.streetNr) + " " + (0, _checkValue.default)(updatetrip.stopPoints[counter].address.streetName) + " " + (0, _checkValue.default)(updatetrip.stopPoints[counter].address.region);
      }
    }

    (0, _checkAddressFlags.default)(updatetrip);
    console.log('edit trip updatetrip', updatetrip);
    console.log('After updatetrip ', updatetrip); //let subscriptions = this.createSubscriptionRecord1();
    //trip.set('subscriptions',subscriptions);

    return updatetrip;
  }

  function populateEditTrip(tripTemplate, updatetrip, store, geocode) {
    return populateEditTripData(tripTemplate, updatetrip, store, geocode);
  }
});