define("adept-iq/pods/components/iq-widgets/map-widget/data-joins/polygon", ["exports", "adept-iq/pods/components/iq-widgets/map-widget/data-joins/base", "adept-iq/pods/components/iq-widgets/map-widget/config/polygon", "lodash", "tomtom"], function (_exports, _base, _polygon, _lodash, _tomtom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    enter(node) {
      const points = node.get('points');
      if (Ember.isEmpty(points)) return;
      this.createPolygonFor(node);
    },

    update(node) {
      const points = node.get('points');
      const polygon = node.get('polygon');

      if (Ember.isEmpty(points)) {
        if (polygon) {
          this.removePolygonFor(node);
        }

        return;
      }

      if (!polygon) {
        this.createPolygonFor(node);
      }

      this.updatePolygonFor(node);
    },

    exit(node) {
      if (node.get('polygon')) {
        this.removePolygonFor(node);
      }
    },

    createPolygonFor(node) {
      const label = node.get('label');
      const record = node.get('record');
      let style = node.get('style');
      const parentLabel = node.get('parentLabel');

      if (record.get('style') !== undefined && record.get('style')) {
        style = record.get('style');
      }

      const defaultOptions = _polygon.default[style] || {};
      const nodeOptions = node.get('options') || {};
      const modelName = record.constructor.modelName;

      const options = _lodash.default.merge(defaultOptions, nodeOptions, {
        label,
        opacity: node.get('opacity'),
        // for active context clicks
        isActive: node.get('isActive'),
        isTraversal: node.get('isTraversal'),
        modelId: record.get('id'),
        modelName
      });

      const points = [node.get('points')];

      const polygon = _tomtom.default.L.polygon(points, options);

      polygon.setStyle(style);
      this.bindMouseEvents(polygon);
      node.set('polygon', polygon);
      const map = this.get('map');
      polygon.addTo(map);
      const parentLabelFormatted = '<table  style="position:relative;"><tr></tr><tr>' + '<td rowspan="2" style="font-weight: bold; font-size: 2em;text-align: center;opacity:0.4;">' + parentLabel + '</td><td rowspan="2">&nbsp;</td><<td rowspan="2">' + label + '</td></tr></table>'; //polygon.bindPopup(label, { closeButton: false });

      polygon.bindTooltip(parentLabelFormatted, {
        permanent: true,
        direction: "center",
        opacity: 0.4
      }).openTooltip();
    },

    updatePolygonFor(node) {
      const opacity = node.get('opacity');
      const polygon = node.get('polygon');

      if (polygon) {
        const label = node.get('label');
        const parentLabel = node.get('parentLabel');
        let style = node.get('style');
        const record = node.get('record');

        if (record.get('style') !== undefined && record.get('style')) {
          style = record.get('style');
        }

        const defaultOptions = _polygon.default[style] || {};
        const nodeOptions = node.get('options') || {};
        const modelName = record.constructor.modelName;

        const options = _lodash.default.merge(defaultOptions, nodeOptions, {
          label,
          opacity: opacity,
          // for active context clicks
          isActive: node.get('isActive'),
          isTraversal: node.get('isTraversal'),
          modelId: record.get('id'),
          modelName
        });

        polygon.setStyle(options);
        this.bindMouseEvents(polygon);
        const parentLabelFormatted = '<table  style="position:relative;"><tr></tr><tr>' + '<td rowspan="2" style="font-weight: bold; font-size: 2em;text-align: center;">' + parentLabel + '</td><td rowspan="2">&nbsp;</td><td rowspan="2">' + label + '</td></tr></table>';
        polygon.setTooltipContent(parentLabelFormatted);
      }
    },

    removePolygonFor(node) {
      const polygon = node.get('polygon');

      if (polygon) {
        polygon.remove();
        node.set('polygon', null);
      }
    }

  });

  _exports.default = _default;
});