define("adept-iq/adapters/cs-config-category", ["exports", "adept-iq/adapters/-cs-configService"], function (_exports, _csConfigService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _csConfigService.default.extend({
    findRecord()
    /* store, type, id, snapshot */
    {
      throw 'adapter method unsupported';
    },

    findAll()
    /* store, type, sinceToken, snapshotRecordArray */
    {
      throw 'adapter method unsupported';
    },

    query()
    /* store, type, query = {}, recordArray */
    {
      throw 'adapter method unsupported';
    }

  });

  _exports.default = _default;
});