define("adept-iq/models/message", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    date: attr('date'),
    time: attr('time'),
    message: attr('string'),
    driverId: attr('uuid'),
    driverLastName: attr('string'),
    driverFirstName: attr('string'),
    vehicleId: attr('uuid'),
    routeId: attr('uuid'),
    hidden: attr('boolean', {
      defaultValue: false
    }),
    status: Ember.computed('', function () {
      let a = ['Received', 'Sent', 'Sending', 'Failed'];
      return a[Math.floor(Math.random() * a.length)];
    })
  });

  _exports.default = _default;
});