define("adept-iq/classes/tree-nodes/category", ["exports", "adept-iq/classes/tree-nodes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    type: 'category',
    configCategory: null,
    isCategory: true,
    isExpandable: true,
    // TODO: get this from config service somehow
    isCollection: Ember.computed('children.@each.type', function () {
      const children = this.get('children');

      if (Ember.isEmpty(children)) {
        return false;
      }

      return children.every(child => {
        return child.get('type') === 'object';
      });
    }),
    isModified: Ember.computed('children.length', 'visibleChildren.length', function () {
      return this.get('children.length') !== this.get('visibleChildren.length');
    }) // disabled for read-only release
    // canAdd: computed.readOnly('isCollection')

  });

  _exports.default = _default;
});