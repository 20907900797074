define("adept-iq/models/bs-subscription-recurrence-pattern", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  const WOM_OPTIONS = {
    first: 'first',
    second: 'second',
    third: 'third',
    fourth: 'fourth',
    last: 'last'
  };

  var _default = Model.extend({
    type: attr('string'),
    separationCount: attr('number', {
      defaultValue: 1
    }),
    sunday: attr('boolean'),
    monday: attr('boolean'),
    tuesday: attr('boolean'),
    wednesday: attr('boolean'),
    thursday: attr('boolean'),
    friday: attr('boolean'),
    saturday: attr('boolean'),
    dayOfMonth: attr('number', {
      defaultValue: null
    }),
    weekOfMonth: attr('number', {
      defaultValue: null
    }),
    monthOfYear: attr('number', {
      defaultValue: null
    }),
    everyweekday: attr('string'),
    pattern: attr('string'),
    recurring: attr('boolean', {
      defaultValue: true
    }),
    selectedDOWs: Ember.computed('sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', function () {
      const selectedDOWArray = [];
      if (this.get('sunday')) selectedDOWArray.push('sunday');
      if (this.get('monday')) selectedDOWArray.push('monday');
      if (this.get('tuesday')) selectedDOWArray.push('tuesday');
      if (this.get('wednesday')) selectedDOWArray.push('wednesday');
      if (this.get('thursday')) selectedDOWArray.push('thursday');
      if (this.get('friday')) selectedDOWArray.push('friday');
      if (this.get('saturday')) selectedDOWArray.push('saturday');
      return selectedDOWArray;
    }),
    isEveryDay: Ember.computed('sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', function () {
      return this.get('sunday') && this.get('monday') && this.get('tuesday') && this.get('wednesday') && this.get('thursday') && this.get('friday') && this.get('saturday');
    }),
    selectedWOM: Ember.computed('weekOfMonth', {
      get()
      /* key */
      {
        const weekOfMonth = this.get('weekOfMonth');

        switch (weekOfMonth) {
          case -1:
            return WOM_OPTIONS.last;

          case 1:
            return WOM_OPTIONS.first;

          case 2:
            return WOM_OPTIONS.second;

          case 3:
            return WOM_OPTIONS.third;

          case 4:
            return WOM_OPTIONS.fourth;

          default:
            return null;
        }
      },

      set(_, value) {
        let weekOfMonth = null;

        switch (value) {
          case WOM_OPTIONS.last:
            weekOfMonth = -1;
            break;

          case WOM_OPTIONS.first:
            weekOfMonth = 1;
            break;

          case WOM_OPTIONS.second:
            weekOfMonth = 2;
            break;

          case WOM_OPTIONS.third:
            weekOfMonth = 3;
            break;

          case WOM_OPTIONS.fourth:
            weekOfMonth = 4;
            break;

          default:
            weekOfMonth = null;
        }

        this.set('weekOfMonth', weekOfMonth);
        return value;
      }

    }),
    subscriptionStartDate: Ember.computed('subscription.startDate', {
      get()
      /* key */
      {
        return this.get('subscription.startDate');
      },

      set(_, value) {
        this.set('subscription.startDate', value);
        return value;
      }

    }),
    subscriptionEndDate: Ember.computed('subscription.endDate', {
      get()
      /* key */
      {
        return this.get('subscription.endDate');
      },

      set(_, value) {
        this.set('subscription.endDate', value);
        return value;
      }

    }),
    subscriptionMaxOccurences: Ember.computed('subscription.maximumOccurrences', {
      get()
      /* key */
      {
        return this.get('subscription.maximumOccurrences');
      },

      set(_, value) {
        this.set('subscription.maximumOccurrences', value);
        return value;
      }

    }),
    exclusionStartDate: Ember.computed('subscription.exclusions.firstObject.startDate', {
      get()
      /* key */
      {
        return this.get('subscription.exclusions.firstObject.startDate');
      },

      set(_, value) {
        this.set('subscription.exclusions.firstObject.startDate', value);
        return value;
      }

    }),
    exclusionEndDate: Ember.computed('subscription.exclusions.firstObject.endDate', {
      get()
      /* key */
      {
        return this.get('subscription.exclusions.firstObject.endDate');
      },

      set(_, value) {
        this.set('subscription.exclusions.firstObject.endDate', value);
        return value;
      }

    })
  });

  _exports.default = _default;
});