define("adept-iq/config/api-preloads", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.apiPreloads = void 0;
  const apiPreloads = [// rider management API
  //{ modelName: 'rms-rider', async: true },
  //{ modelName: 'rms-travel-need-type', async: true },
  //{ modelName: 'rms-passenger-type', async: true },
  //{ modelName: 'rms-eligibility-type', async: true },
  //TM API
  {
    modelName: 'tm-common-operational-attribute',
    async: true
  }, {
    modelName: 'tm-config-parameter-value',
    async: true
  }, {
    modelName: 'zone',
    async: true
  }];
  _exports.apiPreloads = apiPreloads;
});