define("adept-iq/pods/components/table/rows/action-row/component", ["exports", "adept-iq/pods/components/table/rows/base-row/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['table-action-row', 'data-test-table-action-row'],
    classNameBindings: ['status'],
    attributeBindings: ['data-record-id'],
    // this property name must be dasherized
    'data-record-id': Ember.computed.alias('row.content.id'),
    status: Ember.computed.alias('row.content.status'),
    modelName: Ember.computed.alias('row.content.constructor.modelName'),

    didInsertElement() {
      this.$().on('contextmenu', event => {
        event.preventDefault();

        if (event.target.type !== 'checkbox') {
          const extra = this.get('extra');

          if (extra.onRightClick) {
            extra.onRightClick(this.get('row'), event);
          }
        }
      });
    }

  });

  _exports.default = _default;
});