define("adept-iq/adapters/tm-booking", ["exports", "adept-iq/adapters/-tm-tripManagement"], function (_exports, _tmTripManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tmTripManagement.default.extend({
    urlForFindRecord(id, modelName, snapshot) {
      console.log('inside urlForFindRecord ');

      let url = this._super(...arguments);

      let query = Ember.get(snapshot, 'options.query');

      if (query) {
        url += '?' + query;
      }

      return url;
    },

    urlForQuery: function (query, modelName) {
      let url = this._super(...arguments); //let query = Ember.get(snapshot, 'options.query');


      if (query) {
        url += '?' + query.searchURL;
      }

      return url;
    },

    updateRecord(store, type, snapshot) {
      const data = {};
      const serializer = this.store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      const id = snapshot.id;
      const url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      console.log('updateRecord ', this.ajax(url, 'PUT', {
        data: data
      }));
      return data;
    }

  });

  _exports.default = _default;
});