define("adept-iq/pods/dashboard/modals/edit-form/resize-handle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      const elem = this.$();
      elem.on('mousedown', () => {
        //resize may happen outside of current element
        Ember.$(document).on('mousemove', event => {
          Ember.run.scheduleOnce('afterRender', this, 'resizeModal', event);
        }); //rm listeners to avoid bloat

        Ember.$(document).on('mouseup', () => {
          Ember.run.scheduleOnce('afterRender', () => {
            Ember.$(document).off('mouseup');
            Ember.$(document).off('mousemove');
          });
        });
      });
    },

    resizeModal(e) {
      const coords = {
        x: e.pageX
      };
      this.get('onResizeModal')(coords);
    }

  });

  _exports.default = _default;
});