define("adept-iq/services/tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    tip: null,
    primaryActionText: null,
    secondaryActionText: null,
    primaryAction: null,
    secondaryAction: null,

    reset() {
      this.setProperties({
        tip: null,
        primaryActionText: null,
        secondaryActionText: null,
        primaryAction: null,
        secondaryAction: null
      });
    }

  });

  _exports.default = _default;
});