define("adept-iq/pods/dashboard/route", ["exports", "lodash", "moment", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _lodash, _moment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    maximizer: Ember.inject.service(),

    model(params) {
      return this.store.findAll('cs-config-permission').then(configPermissions => {
        this.set('workspace.configPermissions', configPermissions.get('firstObject.value'));
        return this.store.findRecord('dashboard', params.id);
      }).catch(() => {
        this.transitionTo('index');
      });
    },

    setupTimelinePermission(model) {
      const isFullAccess = this.store.peekAll('cs-config-permission').get('firstObject.isFullAccessTimeline');

      if (!isFullAccess) {
        model.set('startDate', (0, _moment.default)().hours(0).minutes(1).toDate());
        model.set('endDate', (0, _moment.default)().hours(23).minutes(59).toDate());
        const referenceDate = (0, _moment.default)().startOf('day').toDate();
        model.set('referenceDate', referenceDate);
      }
    },

    afterModel(model) {
      this.setupTimelinePermission(model);
      localStorage.setItem('lastDashboardId', model.get('id'));
      const userDash = localStorage.getItem('userDash');
      const currentUser = localStorage.getItem('currentUser');
      const arr = userDash.split(',');

      const user = _lodash.default.find(arr, users => {
        return users.split('*')[0] === currentUser;
      });

      arr.splice(arr.indexOf(user), 1);
      user.split('*');
      arr.push(`${currentUser}*${model.get('id')}`);
      localStorage.setItem('userDash', arr); // wait for 1 ms. This is enough to eliminate exceptions
      // when switching dashboards.

      Ember.run.next(() => {
        const configPermissions = this.get('workspace.configPermissions'); // if (configPermissions) {

        this.get('workspace').set('dashboardModel', model); // }

        this.store.findAll('cs-config-permission').then(currConfigPermissions => {
          this.set('workspace.configPermissions', currConfigPermissions.get('firstObject.value'));
          this.get('workspace').set('dashboardModel', model);
        });
        const maximizer = this.get('maximizer');

        if (maximizer.get('checkedItems')) {
          maximizer.set('checkedItems', null);
          return;
        } // clear out any checked items


        this.get('activeContext').reset();
      });
    }

  });

  _exports.default = _default;
});