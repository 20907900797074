define("adept-iq/pods/components/form-components/specialtext/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U2xr0Qh9",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"moveData\",[23,[\"record\"]]],null]],[12,\"accesskey\",[23,[\"accountPickups\",\"accessKey\"]]],[12,\"title\",[28,[\"Swap Addresses  \",[21,\"altSwipeAddressKey\"]]]],[11,\"class\",\"multitype-button btn btn-outline-secondary\"],[9],[0,\"\\n          \"],[7,\"i\"],[11,\"class\",\"fa fa-arrow-up\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n \"],[10],[0,\"        \\n \"],[1,[21,\"value\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/specialtext/template.hbs"
    }
  });

  _exports.default = _default;
});