define("adept-iq/pods/components/notification-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JW6ZmVTj",
    "block": "{\"symbols\":[\"component\",\"flash\"],\"statements\":[[4,\"if\",[[23,[\"errorMessage\",\"topMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"notification-bar\"],[9],[0,\"\\n\"],[4,\"flash-message\",null,[[\"flash\"],[[23,[\"errorMessage\",\"topMessage\"]]]],{\"statements\":[[0,\"    \\n      \"],[7,\"div\"],[12,\"style\",[28,[[22,2,[\"style\"]],\";text-align: left; padding: 0 5px;\"]]],[9],[0,\"\\n      \"],[1,[22,2,[\"message\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/notification-bar/template.hbs"
    }
  });

  _exports.default = _default;
});